import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Comercializadora } from '../../components'
import * as comercializadoraActionCreators from '../../redux/modules/comercializadora'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class ComercializadoraContainer extends Component {

  static propTypes = {
    fetchMultipleComercializadora: PropTypes.func.isRequired,
    destroyComercializadora: PropTypes.func.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    multipleComercializadora: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleComercializadora()
  }

  handleRedirectToNew = () => {
    this.props.history.push('/comercializadoras/new')
  }

  handleShowResource = (id) => {
    this.props.history.push(`/comercializadoras/${id}`)
  }

  handleEditResource = (id) => {
    this.props.history.push(`/comercializadoras/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyComercializadora(id)
  }

  handleSort = column => () => this.props.updateSort('comercializadora', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('comercializadora', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleComercializadora.toList().toJSON(),
      'comercializadora',
      ['sigla', 'nome', 'cnpj', 'ccee', 'endereco', 'bairro', 'cidade', 'uf.nome', 'cep', 'banco', 'agencia', 'conta'],
    )
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <Comercializadora
        title='Comercializadoras'
        listPath='comercializadoras'
        isLoading={this.props.isLoading}
        multipleComercializadora={this.props.multipleComercializadora}
        onRedirectToNew={this.handleRedirectToNew}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV} />
    )
  }
}

function mapStateToProps ({comercializadora, status, sort, filter}) {
  const sortColumn = sort.getIn(['comercializadora', 'sortColumn'])
  const sortDirection = sort.getIn(['comercializadora', 'sortDirection'])
  const filterText = filter.getIn(['comercializadora', 'filterText']) || ''

  let map = filterMap(comercializadora, ['sigla', 'nome', 'cnpj', 'uf.nome', 'uf.sigla'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  const isLoading = status.getIn(['comercializadora', 'isLoading'])

  return {
    multipleComercializadora: map,
    isLoading,
    sortColumn,
    sortDirection,
    filterText,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...comercializadoraActionCreators,
    ...flashMessageAcionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ComercializadoraContainer)
