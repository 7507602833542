import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Enquadramento } from '../../components'
import * as enquadramentoActionCreators from '../../redux/modules/enquadramento'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class EnquadramentoContainer extends Component {

  static propTypes = {
    history: PropTypes.object,
    multipleEnquadramento: PropTypes.object,
    fetchMultipleEnquadramento: PropTypes.func,
    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    destroyEnquadramento: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleEnquadramento()
  }

  handleRedirectToNew = () => {
    this.props.history.push('/enquadramentos/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/enquadramentos/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyEnquadramento(id)
      ? this.props.showSuccessfulFlashMessage('', 'destroy')
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  handleSort = column => () => this.props.updateSort('enquadramento', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('enquadramento', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleEnquadramento.toList().toJSON(),
      'enquadramento',
      ['id', 'modalidade'],
    )
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <Enquadramento
        onRedirectToNew={this.handleRedirectToNew}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        multipleEnquadramento={this.props.multipleEnquadramento}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({ enquadramento, status, sort, filter }) {
  const sortColumn = sort.getIn(['enquadramento', 'sortColumn']) || 'nome'
  const sortDirection = sort.getIn(['enquadramento', 'sortDirection']) || 'ascending'
  const filterText = filter.getIn(['enquadramento', 'filterText']) || ''

  let map = filterMap(enquadramento, ['modalidade'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleEnquadramento: map,
    error: status.getIn(['enquadramento', 'error']),
    isLoading: status.getIn(['enquadramento', 'isLoading']),
    sortColumn,
    sortDirection,
    filterText,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...enquadramentoActionCreators,
    ...flashMessageAcionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EnquadramentoContainer)
