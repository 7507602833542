import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Header, Icon } from 'semantic-ui-react'

const Home = () => (
  <Grid padded>
    <Grid.Row>
      <Grid.Column width={16}>
        <Header as='h1' dividing>
          <Icon name='dashboard' />
          <Header.Content>Dashboard</Header.Content>
        </Header>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

Home.propTypes = {
  session: PropTypes.object,
}

export default Home