import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Field, formValueSelector } from 'redux-form/immutable'
import { Form, Grid, FormField, Segment } from 'semantic-ui-react'
import { DatePickerInput, SemanticDropdown, SemanticCheckbox, SemanticInput, SemanticInputMask } from '../FormComponents'
import { required, dateValidator, number, percentage } from '../../utils/validations'

import { mapToDropdown } from '../../utils/parse'
import { maskPercent, maskFloat, maskNumber } from '../../utils/masks'

const ParametroCalculoForm = (props) => {
  const classeConsumoDropdown = mapToDropdown(props.multipleClasseConsumo, e => e.get('id'), e => e.get('nome'))
  const concessionariaDropdown = mapToDropdown(props.multipleConcessionaria, e => e.get('id'), e => e.get('sigla'))
  const enquadramentoDropdown = mapToDropdown(props.multipleEnquadramento, e => e.get('id'), e => e.get('modalidade'))
  const geradorDropdown = mapToDropdown(props.multipleGerador, e => e.get('id'), e => `${e.get('tipo')} - ${e.get('marca')} - ${e.get('modelo')} - ${maskFloat.parse(e.get('potencia'))} kVA`)
  const subgrupoDropdown = mapToDropdown(props.multipleSubgrupo, e => e.get('id'), e => e.get('nome'))
  const tipoMercadoDropdown = mapToDropdown(props.multipleTipoMercado, e => e.get('id'), e => e.get('nome'))

  const { possuiGerador, geracaoDistribuida, } = props

  return (
    <div>
      <Segment vertical>
        <Form.Group widths='equal'>
          <FormField>
            <label htmlFor='enquadramento_id'>Enquadramento</label>
            <Field fluid button floating labeled search icon='object group' className='icon'
              name='enquadramento_id'
              placeholder='Selecione'
              options={enquadramentoDropdown}
              validate={[required]}
              component={SemanticDropdown}
              loading={props.loading}
            />
          </FormField>
          <FormField>
            <label htmlFor='subgrupo_id'>Subgrupo de Tensão</label>
            <Field fluid button floating labeled search icon='table' className='icon'
              name='subgrupo_id'
              placeholder='Selecione'
              options={subgrupoDropdown}
              validate={[required]}
              component={SemanticDropdown}
              loading={props.loading}
            />
          </FormField>
          <FormField>
            <label htmlFor='classe_consumo_id'>Classe de Consumo</label>
            <Field fluid button floating labeled search icon='block layout' className='icon'
              name='classe_consumo_id'
              placeholder='Selecione'
              options={classeConsumoDropdown}
              validate={[required]}
              component={SemanticDropdown}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group widths='equal'>
          <FormField>
            <label htmlFor='concessionaria_id'>Concessionária</label>
            <Field fluid button floating labeled search icon='building' className='icon'
              name='concessionaria_id'
              placeholder='Selecione'
              options={concessionariaDropdown}
              validate={[required]}
              component={SemanticDropdown}
              loading={props.loading}
            />
          </FormField>
          <FormField>
            <label htmlFor='nuc'>NUC (Número da Unidade Consumidora)</label>
            <Field
              name='nuc'
              placeholder='NUC'
              maxLength={20}
              validate={[number]}
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
          <FormField>
            <label htmlFor='data_contrato_concessionaria'>Data de Contrato c/ Concessionária</label>
            <Field
              name='data_contrato_concessionaria'
              placeholder='Data de Contrato c/ Concessionária'
              validate={[required, dateValidator]}
              component={DatePickerInput}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
      </Segment>
      <Segment vertical>
        <Grid divided columns={3}>
          <Grid.Column>
            <FormField>
              <label htmlFor='icms'>ICMS</label>
              <Field
                name='icms'
                label='%'
                placeholder={maskPercent.placeholder}
                mask={maskPercent.mask()}
                validate={[required, percentage]}
                component={SemanticInputMask}
                loading={props.loading}
              />
            </FormField>
            <FormField>
              <label htmlFor='icms_reduzido'>ICMS Reduzido</label>
              <Field
                name='icms_reduzido'
                label='%'
                placeholder={maskPercent.placeholder}
                mask={maskPercent.mask()}
                validate={[required, percentage]}
                component={SemanticInputMask}
                loading={props.loading}
              />
            </FormField>
            <FormField>
              <label htmlFor='icms_credito'>Crédito de ICMS</label>
              <Field
                name='icms_credito'
                label='%'
                placeholder={maskPercent.placeholder}
                mask={maskPercent.mask()}
                validate={[required, percentage]}
                component={SemanticInputMask}
                loading={props.loading}
              />
            </FormField>
            <FormField>
              <label htmlFor='perdas'>Perdas de Transformações</label>
              <Field
                name='perdas'
                label='%'
                placeholder={maskPercent.placeholder}
                mask={maskPercent.mask()}
                validate={[required, percentage]}
                component={SemanticInputMask}
                loading={props.loading}
              />
            </FormField>
            <FormField>
              <label htmlFor='meses_teste'>Meses em Período de Testes</label>
              <Field
                name='meses_teste'
                label='meses'
                placeholder={maskNumber.placeholder}
                mask={maskNumber.mask(1)}
                validate={[required]}
                component={SemanticInputMask}
                loading={props.loading}
              />
            </FormField>
          </Grid.Column>
          <Grid.Column>
            <FormField>
              <Field
                name='liminar_demanda'
                id='liminar_demanda'
                label='Possui liminar de demanda'
                component={SemanticCheckbox}
                loading={props.loading}
              />
            </FormField>
            <FormField>
              <Field
                name='devec'
                id='devec'
                label='Possui DEVEC/Substituição Tributária'
                component={SemanticCheckbox}
                loading={props.loading}
              />
            </FormField>
            <FormField>
              <Field
                name='possui_gerador'
                id='possui_gerador'
                label='Possui gerador'
                component={SemanticCheckbox}
                loading={props.loading}
              />
            </FormField>
            {possuiGerador && (
              <Grid>
                <Grid.Column width={1} style={{paddingBottom: '0'}}></Grid.Column>
                <Grid.Column width={15} style={{paddingBottom: '0'}}>
                  <FormField>
                    <label htmlFor='gerador_id'>Gerador</label>
                    <Field fluid button floating labeled search icon='rocket' className='icon'
                      name='gerador_id'
                      placeholder='Selecione'
                      options={geradorDropdown}
                      validate={[required]}
                      component={SemanticDropdown}
                      loading={props.loading}
                    />
                  </FormField>
                </Grid.Column>
                <Grid.Column width={1} style={{marginBottom: '1em'}}></Grid.Column>
                <Grid.Column width={15} style={{marginBottom: '1em'}}>
                  <FormField>
                    <label htmlFor='geradores'>Nº de Geradores</label>
                    <Field
                      name='geradores'
                      placeholder='0'
                      validate={[required, number]}
                      component={SemanticInput}
                      loading={props.loading}
                    />
                  </FormField>
                </Grid.Column>
              </Grid>
            )}
            <FormField>
              <Field
                name='geracao_distribuida'
                id='geracao_distribuida'
                label='Possui geração distribuída'
                component={SemanticCheckbox}
                loading={props.loading}
              />
            </FormField>
            {geracaoDistribuida && (
              <Grid>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={15}>
                  <FormField>
                    <label htmlFor='data_inicio_geracao_distribuida'>Data de Início (GD)</label>
                    <Field
                      name='data_inicio_geracao_distribuida'
                      placeholder='99/99/9999'
                      validate={[required, dateValidator]}
                      component={DatePickerInput}
                      loading={props.loading}
                    />
                  </FormField>
                </Grid.Column>
              </Grid>
            )}
          </Grid.Column>
          <Grid.Column>
            <FormField>
              <label htmlFor='tipo_mercado'>Tipo de Mercado</label>
              <Field fluid button floating labeled search icon='sort' className='icon'
                name='tipo_mercado'
                placeholder='Selecione'
                validate={[required]}
                options={tipoMercadoDropdown}
                component={SemanticDropdown}
                loading={props.loading}
              />
            </FormField>
          </Grid.Column>
        </Grid>
      </Segment>
    </div>
  )
}

function mapStateToProps (state, ownProps) {
  const selector = formValueSelector(ownProps.formName)

  const possuiGerador = selector(state, `${ownProps.storeName}.parametro_calculo.possui_gerador`)
  const geracaoDistribuida = selector(state, `${ownProps.storeName}.parametro_calculo.geracao_distribuida`)
  const tipoMercado = selector(state, `${ownProps.storeName}.parametro_calculo.tipo_mercado`)

  return {
    possuiGerador,
    geracaoDistribuida,
    tipoMercado
  }
}

ParametroCalculoForm.propTypes = {
  loading: PropTypes.bool,
  possuiGerador: PropTypes.bool,
  geracaoDistribuida: PropTypes.bool,
  tipoMercado: PropTypes.number,
  multipleClasseConsumo: PropTypes.object,
  multipleComercializadora: PropTypes.object,
  multipleConcessionaria: PropTypes.object,
  multipleEnquadramento: PropTypes.object,
  multipleGerador: PropTypes.object,
  multipleSubgrupo: PropTypes.object,
  multipleTipoMercado: PropTypes.object,
}

export default connect(mapStateToProps)(ParametroCalculoForm)
