import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { VigenciaTarifaForm } from '../../components'
import * as vigenciaTarifaActionCreators from '../../redux/modules/vigenciaTarifa'
import * as subgrupoActionCreators from '../../redux/modules/subgrupo'
import * as enquadramentoActionCreators from '../../redux/modules/enquadramento'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import moment from 'moment'
import { maskFloat } from '../../utils/masks'
import sortBy from 'sort-by'

const DECIMAL_DIGITS = 6
const parse = (value, multiplier = 1.0) =>
  maskFloat.parse((value * multiplier).toFixed(DECIMAL_DIGITS), DECIMAL_DIGITS)
const unmask = (value, multiplier = 1.0) =>
  maskFloat.unmask(value, DECIMAL_DIGITS) * multiplier

class VigenciaTarifaFormContainer extends Component {
  static propTypes = {
    concessionaria: PropTypes.object.isRequired,
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    fetchVigenciaTarifa: PropTypes.func.isRequired,
    createVigenciaTarifa: PropTypes.func.isRequired,
    updateVigenciaTarifa: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,

    fetchMultipleSubgrupo: PropTypes.func,
    fetchMultipleEnquadramento: PropTypes.func,
    multipleSubgrupo: PropTypes.object,
    multipleEnquadramento: PropTypes.object,
  }

  async componentDidMount() {
    await this.props.fetchMultipleSubgrupo()
    await this.props.fetchMultipleEnquadramento()

    let vigencia_tarifa = {
      concessionaria_id: this.props.concessionaria.id,
      starts_at: moment().startOf('month').format('DD/MM/YYYY'),
      valores_tarifas: [],
      valor_encargo: {},
    }

    if (this.props.id) {
      vigencia_tarifa = await this.props.fetchVigenciaTarifa(this.props.id)
      vigencia_tarifa.starts_at = moment(
        vigencia_tarifa.starts_at,
        'YYYY-MM-DD'
      ).format('DD/MM/YYYY')
      vigencia_tarifa.valores_tarifas = vigencia_tarifa.valores_tarifas.map(
        v => {
          v.tusd_kw_p = parse(v.tusd_kw_p)
          v.tusd_kwh_p = parse(v.tusd_kwh_p, 1000.0)
          v.te_kwh_p = parse(v.te_kwh_p, 1000.0)
          v.tusd_kw_fp = parse(v.tusd_kw_fp)
          v.tusd_kwh_fp = parse(v.tusd_kwh_fp, 1000.0)
          v.te_kwh_fp = parse(v.te_kwh_fp, 1000.0)
          v.tusd_kw_na = parse(v.tusd_kw_na)
          v.tusd_kwh_na = parse(v.tusd_kwh_na, 1000.0)
          v.te_kwh_na = parse(v.te_kwh_na, 1000.0)
          v.tusd_kw_int = parse(v.tusd_kw_int)
          v.tusd_kwh_int = parse(v.tusd_kwh_int, 1000.0)
          v.te_kwh_int = parse(v.te_kwh_int, 1000.0)
          v.tr_kwh = parse(v.tr_kwh, 1000.0)
          return v
        }
      )
    }

    const subgrupos = this.props.multipleSubgrupo.toList().toJSON()

    subgrupos.forEach(subgrupo => {
      let enquadramentos = subgrupo.enquadramentos
      enquadramentos.forEach(enquadramento => {
        let found = vigencia_tarifa.valores_tarifas.find(
          v =>
            v.subgrupo_id === subgrupo.id &&
            v.enquadramento_id === enquadramento.id
        )
        if (!found) {
          vigencia_tarifa.valores_tarifas.push({
            subgrupo_id: subgrupo.id,
            enquadramento_id: enquadramento.id,
            tusd_kw_p: '0,00',
            tusd_kwh_p: '0,00',
            te_kwh_p: '0,00',
            tusd_kw_fp: '0,00',
            tusd_kwh_fp: '0,00',
            te_kwh_fp: '0,00',
            tusd_kw_na: '0,00',
            tusd_kwh_na: '0,00',
            te_kwh_na: '0,00',
            tusd_kw_int: '0,00',
            tusd_kwh_int: '0,00',
            te_kwh_int: '0,00',
            tr_kwh: '0,00',
          })
        }
      })
    })

    vigencia_tarifa['valores_tarifas_attributes'] = vigencia_tarifa[
      'valores_tarifas'
    ].sort(sortBy('subgrupo_id', 'enquadramento_id'))
    delete vigencia_tarifa['valores_tarifas']

    vigencia_tarifa['valor_encargo_attributes'] =
      vigencia_tarifa['valor_encargo']

    if (vigencia_tarifa['valor_encargo_attributes']) {
      let valor_encargo = vigencia_tarifa['valor_encargo']

      vigencia_tarifa['valor_encargo_attributes'].conta_covid =
        valor_encargo?.conta_covid?.replace('.', ',') || 0
      vigencia_tarifa['valor_encargo_attributes'].escassez_hidrica =
        valor_encargo?.escassez_hidrica?.replace('.', ',') || 0
    }

    delete vigencia_tarifa['valor_encargo']

    this.props.initialize('VigenciaTarifaForm', { vigencia_tarifa })
  }

  handleSubmitResource = async vigenciaTarifa => {
    const concessionariaId = this.props.concessionaria.id

    let valor_encargo = vigenciaTarifa.getIn([
      'vigencia_tarifa',
      'valor_encargo_attributes',
    ])

    let parsed_valor_encargo = {
      conta_covid: valor_encargo?.get('conta_covid')
        ? maskFloat.unmask(valor_encargo.get('conta_covid'))
        : 0,
      escassez_hidrica: valor_encargo?.get('escassez_hidrica')
        ? maskFloat.unmask(valor_encargo.get('escassez_hidrica'))
        : 0,
    }

    let valores_tarifas = vigenciaTarifa
      .getIn(['vigencia_tarifa', 'valores_tarifas_attributes'])
      .map(v => {
        v = v.set('tusd_kw_p', unmask(v.get('tusd_kw_p')))
        v = v.set('tusd_kwh_p', unmask(v.get('tusd_kwh_p'), 1.0 / 1000.0))
        v = v.set('te_kwh_p', unmask(v.get('te_kwh_p'), 1.0 / 1000.0))
        v = v.set('tusd_kw_fp', unmask(v.get('tusd_kw_fp')))
        v = v.set('tusd_kwh_fp', unmask(v.get('tusd_kwh_fp'), 1.0 / 1000.0))
        v = v.set('te_kwh_fp', unmask(v.get('te_kwh_fp'), 1.0 / 1000.0))
        v = v.set('tusd_kw_na', unmask(v.get('tusd_kw_na')))
        v = v.set('tusd_kwh_na', unmask(v.get('tusd_kwh_na'), 1.0 / 1000.0))
        v = v.set('te_kwh_na', unmask(v.get('te_kwh_na'), 1.0 / 1000.0))
        v = v.set('tusd_kw_int', unmask(v.get('tusd_kw_int')))
        v = v.set('tusd_kwh_int', unmask(v.get('tusd_kwh_int'), 1.0 / 1000.0))
        v = v.set('te_kwh_int', unmask(v.get('te_kwh_int'), 1.0 / 1000.0))
        v = v.set('tr_kwh', unmask(v.get('tr_kwh'), 1.0 / 1000.0))
        return v
      })

    vigenciaTarifa = vigenciaTarifa.setIn(
      ['vigencia_tarifa', 'valores_tarifas_attributes'],
      valores_tarifas
    )

    vigenciaTarifa = vigenciaTarifa.setIn(
      ['vigencia_tarifa', 'valor_encargo_attributes'],
      parsed_valor_encargo
    )

    if (this.props.id) {
      if (
        await this.props.updateVigenciaTarifa(this.props.id, vigenciaTarifa)
      ) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/concessionarias/${concessionariaId}/tarifas`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (
        await this.props.createVigenciaTarifa(concessionariaId, vigenciaTarifa)
      ) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push(`/concessionarias/${concessionariaId}/tarifas`)
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount() {
    if (this.props.shouldHideFlashMessage) this.props.hideFlashMessage()
  }

  render() {
    return (
      <VigenciaTarifaForm
        concessionaria={this.props.concessionaria}
        onSubmit={vigenciaTarifa => this.handleSubmitResource(vigenciaTarifa)}
        isLoading={this.props.isLoading}
      />
    )
  }
}

function mapStateToProps(
  { vigenciaTarifa, subgrupo, enquadramento, status, flashMessage },
  { match }
) {
  const { id } = match.params

  const isLoading =
    status.getIn(['vigenciaTarifa', 'isLoading']) ||
    status.getIn(['subgrupo', 'isLoading']) ||
    status.getIn(['enquadramento', 'isLoading'])

  if (id) {
    return {
      id,
      vigenciaTarifa: vigenciaTarifa.get('id'),
      error: status.getIn(['vigenciaTarifa', 'error']),
      isLoading,
      shouldHideFlashMessage: flashMessage.get('isError'),
      multipleSubgrupo: subgrupo,
      multipleEnquadramento: enquadramento,
    }
  }
  return {
    error: status.getIn(['vigenciaTarifa', 'error']),
    isLoading,
    shouldHideFlashMessage: flashMessage.get('isError'),
    multipleSubgrupo: subgrupo,
    multipleEnquadramento: enquadramento,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...vigenciaTarifaActionCreators,
      ...subgrupoActionCreators,
      ...enquadramentoActionCreators,
      ...flashMessageAcionCreators,
      ...{ initialize },
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VigenciaTarifaFormContainer))
