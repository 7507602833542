import { Map } from 'immutable'

export const multipleCalculoCreditoSubvencao = Map({
  0: Map({
    id: 0,
    nome: 'N/A'
  }),
  1: Map({
    id: 1,
    nome: 'Subvenção sem tributos'
  }),
})
