import React from 'react'
import PropTypes from 'prop-types'

import TributoFatura from './TributoFatura'

const RenderTributosFatura = ({ fields, loading }) => {
  return (
    <React.Fragment>
      { fields.map((tributoFatura, index) => (
        <TributoFatura
          key={index}
          tributoFatura={tributoFatura}
          loading={loading}
        />
      ))}
    </React.Fragment>
  )
}

RenderTributosFatura.propTypes = {
  fields: PropTypes.object,
  loading: PropTypes.bool,
}

export default RenderTributosFatura
