import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ContatoShow } from '../../components'
import * as contatoActionCreators from '../../redux/modules/contato'

class ContatoShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    fetchContato: PropTypes.func,
    history: PropTypes.object,
    destroyContato: PropTypes.func,
  }

  state = {
    contato: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const contato = await this.props.fetchContato(this.props.id)
      this.setState({ contato })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/contatos/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/contatos/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyContato(id)
    this.props.history.push('/contatos')
  }

  render () {
    return (
      <ContatoShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        contato={this.state.contato} />
    )
  }
}

function mapStateToProps ({contato}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      contato: contato.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...contatoActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ContatoShowContainer)
