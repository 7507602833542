import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Form, Grid, Header, Icon } from 'semantic-ui-react'
import { FlashMessageContainer } from '../../containers'
import FormToolbar from '../../components/Toolbar/FormToolbar'

const CRUDForm = (props) => {
  const { pathname } = props.location

  return (
    <Form onSubmit={props.handleSubmit} loading={props.loading || props.isLoading}>
      <Grid padded={props.padded}>
        <Grid.Row>
          {!props.noTitle && (
            <Grid.Column width={16}>
              <Header as={props.titleAs} dividing>
                {props.icon && (<Icon name={props.icon} />)}
                <Header.Content>
                  {`${pathname.endsWith('new') ? 'Inclusão' : 'Alteração'} de ${props.title}`}
                </Header.Content>
              </Header>
            </Grid.Column>
          )}
          <Grid.Column width={16}>
            <FormToolbar loading={props.loading} noBackButton={props.noBackButton} />
          </Grid.Column>
          <Grid.Column width={16}>
            <FlashMessageContainer />
          </Grid.Column>
          <Grid.Column width={16}>
            {props.children}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  )
}

CRUDForm.defaultProps = {
  padded: true,
  titleAs: 'h1',
}

CRUDForm.propTypes = {
  title: PropTypes.string,
  titleAs: PropTypes.string,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  isLoading: PropTypes.bool,

  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  location: PropTypes.object.isRequired,

  noTitle: PropTypes.bool,
  noBackButton: PropTypes.bool,
  padded: PropTypes.bool,

  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
}

export default withRouter(CRUDForm)
