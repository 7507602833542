import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { TributoForm } from '../../components'
import * as tributoActionCreators from '../../redux/modules/tributo'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

class TributoFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    fetchTributo: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    updateTributo: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    createTributo: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    if (this.props.id) {
      const tributo = await this.props.fetchTributo(this.props.id)
      this.props.initialize('TributoForm', {tributo})
    }
  }

  handleSubmitResource = async (tributo) => {
    if (this.props.id) {
      if (await this.props.updateTributo(this.props.id, tributo)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push('/tributos')
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createTributo(tributo)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/tributos')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <TributoForm
        onSubmit={(tributo) => this.handleSubmitResource(tributo)}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({tributo, status, flashMessage}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      tributo: tributo.get('id'),
      error: status.getIn(['tributo', 'error']),
      isLoading: status.getIn(['tributo', 'isLoading']),
      shouldHideFlashMessage: flashMessage.get('isError'),
    }
  }
  return {
    error: status.getIn(['tributo', 'error']),
    isLoading: status.getIn(['tributo', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...tributoActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TributoFormContainer)
