import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, formValueSelector } from 'redux-form/immutable'

import { Grid, Form, FormField } from 'semantic-ui-react'
import { SemanticInputMask } from '../FormComponents'

import { required, percentage } from '../../utils/validations'
import { maskFloat } from '../../utils/masks'

const DECIMAL_DIGITS = 6

const selector = formValueSelector('FaturaPedagioForm')

const TributoFatura = (props) => {
  const { tributoFatura, loading, tributo_id, multipleTributo } = props
  const tributo_nome = multipleTributo.getIn([`${tributo_id}`, 'nome'])

  return (
    <Grid.Row columns='2'>
      <Grid.Column>
      </Grid.Column>
      <Grid.Column>
        <Form.Group>
          <FormField>
            <label htmlFor={`${tributoFatura}.aliquota`}>{tributo_nome}</label>
            <Field
              name={`${tributoFatura}.aliquota`}
              label='%'
              validate={[required, percentage]}
              component={SemanticInputMask}
              loading={loading}
              mask={maskFloat.mask(DECIMAL_DIGITS)}
              placeholder={maskFloat.placeholder}
            />
          </FormField>
        </Form.Group>
      </Grid.Column>
    </Grid.Row>
  )
}

const mapStateToProps = (state, props) => ({
  tributo_id: selector(state, `fatura_pedagio.${props.tributoFatura}.tributo_id`),
  multipleTributo: state.tributo,
})

TributoFatura.propTypes = {
  tributoFatura: PropTypes.string,
  loading: PropTypes.bool,
  tributo_id: PropTypes.number,
  multipleTributo: PropTypes.object,
  tributo_nome: PropTypes.string,
}

export default connect(mapStateToProps)(TributoFatura)
