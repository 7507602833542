const dev = {
  apiGateway: { URL: '/api' },
  environment: 'DEVELOPMENT',
}

const stage = {
  apiGateway: { URL: 'https://api.electricservice.com.br/api' },
  environment: 'STAGING',
}

const prod = {
  apiGateway: {
    URL: process.env.REACT_APP_PROD_API
      ? process.env.REACT_APP_PROD_API
      : 'https://api.electricservice.com.br/api',
  },
  environment: '',
}

const config =
  process.env.REACT_APP_STAGE === 'production'
    ? prod
    : process.env.NODE_ENV === 'production'
    ? stage
    : dev

export default {
  ...config,
}
