import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import RenderMeses from './RenderMeses'

const RenderForm = ({ fields, loading }) => {
  return (
    <Table.Body>
      {fields.map((mes, index) => (
        <RenderMeses
          key={index}
          mes={mes}
          loading={loading}
        />
      ))}
    </Table.Body>
  )
}

RenderForm.propTypes = {
  fields: PropTypes.object,
  loading: PropTypes.bool,
}

export default RenderForm
