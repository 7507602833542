import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { BandeiraShow } from '../../components'
import * as bandeiraActionCreators from '../../redux/modules/bandeira'

class BandeiraShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object,
    fetchBandeira: PropTypes.func,
    destroyBandeira: PropTypes.func,
  }

  state = {
    bandeira: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const bandeira = await this.props.fetchBandeira(this.props.id)
      this.setState({ bandeira })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/gestao_bandeiras/bandeiras/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/gestao_bandeiras/bandeiras/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyBandeira(id)
    this.props.history.push('/gestao_bandeiras/bandeiras')
  }

  render () {
    return (
      <BandeiraShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        bandeira={this.state.bandeira} />
    )
  }
}

function mapStateToProps ({bandeira}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      bandeira: bandeira.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...bandeiraActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BandeiraShowContainer)
