import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ContatoShow } from '../../components'
import * as contatoClienteActionCreators from '../../redux/modules/contatoCliente'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

class ContatoClienteShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    cliente: PropTypes.object.isRequired,
    fetchContatoCliente: PropTypes.func.isRequired,
    destroyContatoCliente: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
  }

  state = {
    contatoCliente: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const contatoCliente = await this.props.fetchContatoCliente(this.props.id)
      this.setState({ contatoCliente })
    }
  }

  handleEditResource = (clienteId, id) => {
    this.props.history.push(`/clientes/${clienteId}/contatos/${id}/edit`)
  }

  handleDeleteResource = async (clienteId, id) => {
    if (await this.props.destroyContatoCliente(id)) {
      this.props.showSuccessfulFlashMessage('', 'destroy')
      this.props.history.push(`/clientes/${clienteId}/contatos`)
    } else {
      this.props.showErrorFlashMessage('', 'destroy', this.props.error)
    }
  }

  render () {
    return (
      <ContatoShow noTitle
        cliente={this.props.cliente}
        onEditResource={() => this.handleEditResource(this.props.cliente.id, this.state.contatoCliente.id)}
        onDeleteResource={() => this.handleDeleteResource(this.props.cliente.id, this.state.contatoCliente.id)}
        contato={this.state.contatoCliente} />
    )
  }
}

function mapStateToProps ({contatoCliente}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      contatoCliente: contatoCliente.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...contatoClienteActionCreators,
    ...flashMessageActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContatoClienteShowContainer))
