import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { FaturaEnergiaForm } from '../../components'
import * as faturaEnergiaActionCreators from '../../redux/modules/faturaEnergia'
import * as contratoEnergiaInstalacaoActionCreators from '../../redux/modules/contratoEnergiaInstalacao'
import * as comercializadoraActionCreators from '../../redux/modules/comercializadora'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { mapToDropdown } from '../../utils/parse'
import { maskCurrency } from '../../utils/masks'

class FaturaEnergiaFormContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    initialize: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    contratoEnergia: PropTypes.object,

    fetchFaturaEnergia: PropTypes.func.isRequired,
    createFaturaEnergia: PropTypes.func.isRequired,
    updateFaturaEnergia: PropTypes.func.isRequired,

    multipleContratoEnergia: PropTypes.object,
    fetchMultipleContratoEnergia: PropTypes.func.isRequired,
    contratoEnergiaDropdown: PropTypes.array.isRequired,

    multipleComercializadora: PropTypes.object,
    fetchMultipleComercializadora: PropTypes.func.isRequired,
    comercializadoraDropdown: PropTypes.array.isRequired,

    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleContratoEnergia()
    await this.props.fetchMultipleComercializadora()
    if (this.props.id) {
      const fatura_energia = await this.props.fetchFaturaEnergia(this.props.id)
      fatura_energia.consumo = maskCurrency.parse(Number(fatura_energia.consumo))
      fatura_energia.perdas = maskCurrency.parse(Number(fatura_energia.perdas) * 100)
      fatura_energia.valor_total = maskCurrency.parse(Number(fatura_energia.valor_total))
      fatura_energia.multas = maskCurrency.parse(Number(fatura_energia.multas))
      fatura_energia.juros = maskCurrency.parse(Number(fatura_energia.juros))
      fatura_energia.correcao_monetaria = maskCurrency.parse(Number(fatura_energia.correcao_monetaria))

      fatura_energia.mcp_consumo_negociado = maskCurrency.parse(Number(fatura_energia.mcp_consumo_negociado))
      fatura_energia.mcp_spread = maskCurrency.parse(Number(fatura_energia.mcp_spread))
      fatura_energia.mcp_multas = maskCurrency.parse(Number(fatura_energia.mcp_multas))
      fatura_energia.mcp_juros = maskCurrency.parse(Number(fatura_energia.mcp_juros))
      fatura_energia.mcp_correcao_monetaria = maskCurrency.parse(Number(fatura_energia.mcp_correcao_monetaria))

      fatura_energia.outros_consumo_negociado = maskCurrency.parse(Number(fatura_energia.outros_consumo_negociado))
      fatura_energia.outros_spread = maskCurrency.parse(Number(fatura_energia.outros_spread))
      fatura_energia.outros_multas = maskCurrency.parse(Number(fatura_energia.outros_multas))
      fatura_energia.outros_juros = maskCurrency.parse(Number(fatura_energia.outros_juros))
      fatura_energia.outros_correcao_monetaria = maskCurrency.parse(Number(fatura_energia.outros_correcao_monetaria))
      this.props.initialize('FaturaEnergiaForm', {fatura_energia})
    }
  }

  handleSubmitResource = async (faturaEnergia) => {
    let consumo = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'consumo']))
    let perdas = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'perdas']))
    let valor_total = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'valor_total']))
    let multas = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'multas']))
    let juros = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'juros']))
    let correcao_monetaria = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'correcao_monetaria']))

    let mcp_consumo_negociado = 0.00
    let mcp_spread = 0.00
    let mcp_comercializadora_id = null
    let mcp_multas = 0.00
    let mcp_juros = 0.00
    let mcp_correcao_monetaria = 0.00

    let outros_consumo_negociado = 0.00
    let outros_spread = 0.00
    let outros_comercializadora_id = null
    let outros_multas = 0.00
    let outros_juros = 0.00
    let outros_correcao_monetaria = 0.00

    if (faturaEnergia.getIn(['fatura_energia', 'mcp'])) {
      mcp_consumo_negociado = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'mcp_consumo_negociado']))
      mcp_spread = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'mcp_spread']))
      mcp_comercializadora_id = faturaEnergia.getIn(['fatura_energia', 'mcp_comercializadora_id'])
      mcp_multas = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'mcp_multas']))
      mcp_juros = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'mcp_juros']))
      mcp_correcao_monetaria = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'mcp_correcao_monetaria']))
    }

    if (faturaEnergia.getIn(['fatura_energia', 'outros'])) {
      outros_consumo_negociado = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'outros_consumo_negociado']))
      outros_spread = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'outros_spread']))
      outros_comercializadora_id = faturaEnergia.getIn(['fatura_energia', 'outros_comercializadora_id'])
      outros_multas = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'outros_multas']))
      outros_juros = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'outros_juros']))
      outros_correcao_monetaria = maskCurrency.unmask(faturaEnergia.getIn(['fatura_energia', 'outros_correcao_monetaria']))
    }

    faturaEnergia = faturaEnergia
      .setIn(['fatura_energia', 'consumo'], consumo)
      .setIn(['fatura_energia', 'perdas'], perdas / 100)
      .setIn(['fatura_energia', 'valor_total'], valor_total)
      .setIn(['fatura_energia', 'multas'], multas)
      .setIn(['fatura_energia', 'juros'], juros)
      .setIn(['fatura_energia', 'correcao_monetaria'], correcao_monetaria)

      .setIn(['fatura_energia', 'mcp_consumo_negociado'], mcp_consumo_negociado)
      .setIn(['fatura_energia', 'mcp_spread'], mcp_spread)
      .setIn(['fatura_energia', 'mcp_comercializadora_id'], mcp_comercializadora_id)
      .setIn(['fatura_energia', 'mcp_multas'], mcp_multas)
      .setIn(['fatura_energia', 'mcp_juros'], mcp_juros)
      .setIn(['fatura_energia', 'mcp_correcao_monetaria'], mcp_correcao_monetaria)

      .setIn(['fatura_energia', 'outros_consumo_negociado'], outros_consumo_negociado)
      .setIn(['fatura_energia', 'outros_spread'], outros_spread)
      .setIn(['fatura_energia', 'outros_comercializadora_id'], outros_comercializadora_id)
      .setIn(['fatura_energia', 'outros_multas'], outros_multas)
      .setIn(['fatura_energia', 'outros_juros'], outros_juros)
      .setIn(['fatura_energia', 'outros_correcao_monetaria'], outros_correcao_monetaria)

    if (this.props.id) {
      if (await this.props.updateFaturaEnergia(this.props.id, faturaEnergia)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push('/faturas_energia')
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createFaturaEnergia(faturaEnergia)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/faturas_energia')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <FaturaEnergiaForm
        contratoEnergiaDropdown={this.props.contratoEnergiaDropdown}
        multipleContratoEnergia={this.props.multipleContratoEnergia}
        comercializadoraDropdown={this.props.comercializadoraDropdown}
        multipleComercializadora={this.props.multipleComercializadora}
        onSubmit={(faturaEnergia) => this.handleSubmitResource(faturaEnergia)}
        loading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({faturaEnergia, contratoEnergia, comercializadora, status, flashMessage}, {match}) {
  const isLoading = status.getIn(['faturaEnergia', 'isLoading'])
  const { id } = match.params

  const contratoEnergiaDropdown = mapToDropdown(
    contratoEnergia,
    e => e.get('id'),
    e => `${e.get('id')}`
  )

  const comercializadoraDropdown = mapToDropdown(
    comercializadora,
    e => e.get('id'),
    e => `${e.get('nome')}`
  )

  if (id) {
    return {
      id,
      faturaEnergia: faturaEnergia.get('id'),
      contratoEnergiaDropdown,
      comercializadoraDropdown,
      error: status.getIn(['faturaEnergia', 'error']),
      shouldHideFlashMessage: flashMessage.get('isError'),
      isLoading,
    }
  }
  return {
    contratoEnergiaDropdown,
    comercializadoraDropdown,
    error: status.getIn(['faturaEnergia', 'error']),
    shouldHideFlashMessage: flashMessage.get('isError'),
    isLoading,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...faturaEnergiaActionCreators,
    ...contratoEnergiaInstalacaoActionCreators,
    ...comercializadoraActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FaturaEnergiaFormContainer)
