import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import { withRouter } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import ComposicaoAnualChart from './Graficos/ComposicaoAnualChart'
import ParticipacaoImpostosChart from './Graficos/ParticipacaoImpostosChart'

import AnaliseContainer from '../../containers/Relatorio/AnaliseContainer'

const Custos = ({ graficos, match }) => {

  const print = match.url.endsWith('/print')
  const showAnimations = !print

  const composicao_anual = get(graficos, 'composicao_anual')
  const participacao_impostos_dados = get(graficos, 'participacao_impostos.dados')
  const participacao_impostos_extra = get(graficos, 'participacao_impostos.extra')

  return (
    <React.Fragment>
      <Grid.Row className='shrink'>
        <Grid.Column width={16}>
          <AnaliseContainer resetable
            field='texto_composicao'
            help='Composição dos Custos (12 meses)'
          />
        </Grid.Column>
        <Grid.Column width={16}>
          <ComposicaoAnualChart
            header='Composição dos Custos (12 meses)'
            data={composicao_anual}
            showAnimations={showAnimations}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className='shrink'>
        <Grid.Column width={16}>
          <AnaliseContainer resetable
            field='texto_custos'
            help='Valores dos Custos (12 meses)'
          />
        </Grid.Column>
        <Grid.Column width={16}>
          <ParticipacaoImpostosChart
            header='Valores dos Custos (12 meses)'
            data={participacao_impostos_dados}
            info={participacao_impostos_extra}
            showAnimations={showAnimations}
          />
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  )
}

Custos.propTypes = {
  graficos: PropTypes.object,
  match: PropTypes.object,
}

export default withRouter(Custos)
