import moment from 'moment'
import { maskCurrency } from './masks'

export const required = value => (value || value === 0 ? undefined : 'Não pode ficar em branco.')

export const hexColor = value =>
  value && !/^#(?:[0-9a-fA-F]{3}){1,2}$/i.test(value)
    ? 'Formato inválido de cor. Exemplo: #0099FF.'
    : undefined

export const number = value =>
  value && isNaN(Number(value)) ? 'Deve ser composto apenas por números.' : undefined

export const decimal = value =>
  value && isNaN(Number(value)) && /\d+\.\d+/.test(value)
    ? 'Deve ser decimal' : undefined

export const currency = value =>
  value && !/^[+-]?R\$\s\d{1,3}(?:\.?\d{3})*(,\d{2})?$/.test(value)
    ? 'Deve ser uma moeda' : undefined

export const exactLength = size => value =>
  value && value.length !== size ? `Deve conter exatamente ${size} dígitos.` : undefined

export const minMax = (min, max) => value =>
  value && (value < min || value > max)
    ? `Deve estar no intervalo [${min}, ${max}].`
    : undefined

export const percent = value =>
  value && (value < 0.0 || value > 100.0)
    ? 'Deve estar entre 0% e 100%.'
    : undefined

export const percentage = value => {
  value = maskCurrency.unmask(value)
  return value && (value < 0.0 || value > 100.0)
    ? 'Deve estar entre 0% e 100%.'
    : undefined
}

export const maxLength = max => value =>
  value && value.length > max ? `Deve conter no máximo ${max} caracteres.` : undefined

export const minLength = min => value =>
  value && value.length < min ? `Deve conter no mínimo ${min} caracteres.` : undefined

export const minValue = min => value =>
  value && value < min ? `Deve ser no mínimo ${min}.` : undefined

export const maxValue = max => value =>
  value && value > max ? `Deve ser no máximo ${max}.` : undefined

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Formato de e-mail inválido. Exemplo: nome@site.com.br.'
    : undefined

export const phone = value =>
  value && !/(\(\d{2}\))\s*(\d{4,5})-(\d{4})/i.test(value)
    ? 'Deve ser um formato de telefone válido.'
    : undefined

export const alpha = value =>
  value && /[^a-zA-Z]/i.test(value)
    ? 'Deve possuir somente letras.'
    : undefined

export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Deve possuir somente letras e números.'
    : undefined

export const fator = value => {
  value = maskCurrency.unmask(value)
  return value && (value < 0.0 || value > 1.0)
    ? 'Deve estar entre 0,0 e 1,0.'
    : undefined
}

// ref: https://github.com/tapajos/brazilian-rails/blob/master/brcpfcnpj/lib/brcpfcnpj/cpf_cnpj.rb
export const cpf = value =>
  value && !/^(\d{3}\.?\d{3}\.?\d{3})-?(\d{2})$/.test(value)
    ? 'Formado do CPF inválido.'
    : undefined

export const cnpj = value =>
  value && !/^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4})-?(\d{2})$/.test(value)
    ? 'Formato do CNPJ inválido.'
    : undefined

export const dateValidator = value =>
  !moment(value, 'DD/MM/YYYY').isValid()
    ? 'Data inválida.'
    : undefined

export const maxLengthEstado = maxLength(2)
export const maxLengthSigla = maxLength(32)
export const maxLengthNome = maxLength(32)
export const maxLengthDescricao = maxLength(140)
export const minValue0_0 = minValue(0.0)
