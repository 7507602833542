import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Resultado } from '../../components'
import * as resultadoActionCreators from '../../redux/modules/resultado'
import * as concessionariaActionCreators from '../../redux/modules/concessionaria'
import * as enquadramentoActionCreators from '../../redux/modules/enquadramento'
import * as subgrupoActionCreators from '../../redux/modules/subgrupo'
import * as classeConsumoActionCreators from '../../redux/modules/classeConsumo'
import * as faturaPedagioActionCreators from '../../redux/modules/faturaPedagio'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { multipleTipoMercado } from '../../utils/tipoMercado'
import { filterMap, sortMap } from '../../utils/mapTools'

class ResultadoContainer extends Component {

  static propTypes = {
    history: PropTypes.object,
    faturaPedagio: PropTypes.object,

    multipleResultado: PropTypes.object,
    fetchMultipleResultado: PropTypes.func,

    multipleConcessionaria: PropTypes.object,
    fetchMultipleConcessionaria: PropTypes.func,

    multipleEnquadramento: PropTypes.object,
    fetchMultipleEnquadramento: PropTypes.func,

    multipleSubgrupo: PropTypes.object,
    fetchMultipleSubgrupo: PropTypes.func,

    multipleClasseConsumo: PropTypes.object,
    fetchMultipleClasseConsumo: PropTypes.func,

    calculateFaturaPedagio: PropTypes.func,
    simulateFaturaPedagio: PropTypes.func,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,

    updateSort: PropTypes.func,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func,
    filterText: PropTypes.string,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    const { faturaPedagio } = this.props
    await Promise.all([
      this.props.fetchMultipleResultado(faturaPedagio.get('id')),
    ])
  }

  handleSort = column => () => this.props.updateSort('resultado', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('resultado', name, value)

  handleCalcular = async () => {
    this.props.hideFlashMessage()

    if (await this.props.calculateFaturaPedagio(this.props.faturaPedagio.get('id'))) {
      this.props.showSuccessfulFlashMessage('', 'calculate')
      await this.props.fetchMultipleResultado(this.props.faturaPedagio.get('id'))
    } else {
      this.props.showErrorFlashMessage('', 'calculate', this.props.error)
    }
  }

  handleSimular = async () => {
    this.props.hideFlashMessage()

    if (await this.props.simulateFaturaPedagio(this.props.faturaPedagio.get('id'))) {
      this.props.showSuccessfulFlashMessage('', 'simulate')
      await this.props.fetchMultipleResultado(this.props.faturaPedagio.get('id'))
    } else {
      this.props.showErrorFlashMessage('', 'simulate', this.props.error)
    }
  }

  handleShowResource = (faturaPedagioId) => {
    this.props.history.push(`/faturas_pedagio/${faturaPedagioId}/resultado`)
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <Resultado
        faturaPedagio={this.props.faturaPedagio}
        multipleResultado={this.props.multipleResultado}
        multipleTipoMercado={multipleTipoMercado}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onCalcular={this.handleCalcular}
        onSimular={this.handleSimular}
        isLoading={this.props.isLoading}
      />
    )
  }
}

function mapStateToProps ({ resultado, status, sort, filter }, {faturaPedagio}) {
  const sortColumn = sort.getIn(['resultado', 'sortColumn']) || 'id'
  const sortDirection = sort.getIn(['resultado', 'sortDirection']) || 'ascending'
  const filterText = filter.getIn(['resultado', 'filterText']) || ''

  let map = filterMap(resultado, [], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleResultado: map.filter(t => t.get('fatura_id') === faturaPedagio.get('id') && t.get('fatura_type') === 'FaturaPedagio'),
    error: status.getIn(['faturaPedagio', 'error']),
    isLoading: status.getIn(['resultado', 'isLoading']) || status.getIn(['faturaPedagio', 'isLoading']),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...resultadoActionCreators,
    ...faturaPedagioActionCreators,
    ...concessionariaActionCreators,
    ...enquadramentoActionCreators,
    ...subgrupoActionCreators,
    ...classeConsumoActionCreators,
    ...flashMessageActionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResultadoContainer))
