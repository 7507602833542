import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { VigenciaValorShow } from '../../components'
import * as vigenciaValorActionCreators from '../../redux/modules/vigenciaValor'

class VigenciaValorShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object,
    fetchVigenciaValor: PropTypes.func,
    destroyVigenciaValor: PropTypes.func,
  }

  state = {
    vigenciaValor: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const vigenciaValor = await this.props.fetchVigenciaValor(this.props.id)
      this.setState({ vigenciaValor })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/gestao_bandeiras/valores/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/gestao_bandeiras/valores/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyVigenciaValor(id)
    this.props.history.push('/gestao_bandeiras/valores')
  }

  render () {
    return (
      <VigenciaValorShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        vigenciaValor={this.state.vigenciaValor} />
    )
  }
}

function mapStateToProps ({vigenciaValor}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      vigenciaValor: vigenciaValor.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...vigenciaValorActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(VigenciaValorShowContainer)
