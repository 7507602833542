import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_GERADOR = 'LOADING_GERADOR'
export function loadingGerador () {
  return {
    type: LOADING_GERADOR,
  }
}

export const LOADING_GERADOR_SUCCESS = 'LOADING_GERADOR_SUCCESS'
export function loadingGeradorSuccess (gerador) {
  return {
    type: LOADING_GERADOR_SUCCESS,
    gerador,
  }
}

export const LOADING_GERADOR_FAILURE = 'LOADING_GERADOR_FAILURE'
export function loadingGeradorFailure (error) {
  return {
    type: LOADING_GERADOR_FAILURE,
    error,
  }
}

export const DESTROYING_GERADOR_SUCCESS = 'DESTROYING_GERADOR_SUCCESS'
export function destroyingGeradorSuccess (geradorId) {
  return {
    type: DESTROYING_GERADOR_SUCCESS,
    geradorId,
  }
}

const initialState = Map({})

export function fetchMultipleGerador () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingGerador())
      const multipleGerador = await callAPI('/geradores')
      dispatch(loadingGeradorSuccess(multipleGerador))
      return multipleGerador
    } catch (e) {
      dispatch(loadingGeradorFailure(e))
      return false
    }
  }
}

export function fetchGerador (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingGerador())
      const gerador = await callAPI(`/geradores/${id}`)
      dispatch(loadingGeradorSuccess(gerador))
      return gerador
    } catch (e) {
      dispatch(loadingGeradorFailure(e))
      return false
    }
  }
}

export function createGerador (gerador) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingGerador())
      const newGerador = await callAPI('/geradores', 'POST', gerador)
      dispatch(loadingGeradorSuccess(newGerador))
      return newGerador
    } catch (e) {
      dispatch(loadingGeradorFailure(e))
      return false
    }
  }
}

export function updateGerador (id, gerador) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingGerador())
      const newGerador = await callAPI(`/geradores/${id}`, 'PUT', gerador)
      dispatch(loadingGeradorSuccess(newGerador))
      return newGerador
    } catch (e) {
      dispatch(loadingGeradorFailure(e))
      return false
    }
  }
}

export function destroyGerador (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingGerador())
      await callAPI(`/geradores/${id}`, 'DELETE')
      dispatch(destroyingGeradorSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingGeradorFailure(e))
      return false
    }
  }
}

export default function gerador (state = initialState, action) {
  switch (action.type) {

    case LOADING_GERADOR_SUCCESS : {
      const { gerador } = action
      if (gerador.constructor === Array) {
        state = initialState
        gerador.forEach((g) => (
          state = state.merge({[g.id]: g})
        ))
        return state
      }
      return state.merge({[gerador.id]: gerador})
    }

    case DESTROYING_GERADOR_SUCCESS :
      return state.delete(action.geradorId.toString())

    default :
      return state
  }
}
