import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { ParametroConcessionariaForm } from '../../components'
import * as parametroConcessionariaActionCreators from '../../redux/modules/parametroConcessionaria'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { multipleBaseCalculo } from '../../utils/enums/baseCalculo'
import { multipleCalculoTributo } from '../../utils/enums/calculoTributo'
import { multipleCalculoBandeira } from '../../utils/enums/calculoBandeira'
import { multipleCalculoSubvencao } from '../../utils/enums/calculoSubvencao'
import { multipleCalculoCreditoSubvencao } from '../../utils/enums/calculoCreditoSubvencao'

import moment from 'moment'
import { maskPercent } from '../../utils/masks'

const DECIMAL_DIGITS = 6

class ParametroConcessionariaFormContainer extends Component {
  static propTypes = {
    createParametroConcessionaria: PropTypes.func.isRequired,
    fetchParametroConcessionaria: PropTypes.func.isRequired,
    updateParametroConcessionaria: PropTypes.func.isRequired,

    id: PropTypes.string,
    error: PropTypes.object,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,

    initialize: PropTypes.func.isRequired,
    concessionaria: PropTypes.object.isRequired,

    hideFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
  }

  async componentDidMount () {
    let parametro_concessionaria = {
      concessionaria_id: this.props.concessionaria.id,
      starts_at: moment().startOf('month').format('YYYY-MM-DD'),
    }

    if (this.props.id) {
      parametro_concessionaria = await this.props.fetchParametroConcessionaria(this.props.id)
    }

    parametro_concessionaria.starts_at = moment(parametro_concessionaria.starts_at, 'YYYY-MM-DD').format('DD/MM/YYYY')
    parametro_concessionaria.desconto_horario_reservado = maskPercent.parse(parametro_concessionaria.desconto_horario_reservado, DECIMAL_DIGITS)

    this.props.initialize('ParametroConcessionariaForm', { parametro_concessionaria })
  }

  handleSubmitResource = async (parametroConcessionaria) => {
    const concessionariaId = this.props.concessionaria.id

    let desconto = parametroConcessionaria.getIn(['parametro_concessionaria', 'desconto_horario_reservado']) 
    parametroConcessionaria = parametroConcessionaria.setIn(['parametro_concessionaria', 'desconto_horario_reservado'], maskPercent.unmask(desconto, DECIMAL_DIGITS))

    if (this.props.id) {
      if (await this.props.updateParametroConcessionaria(this.props.id, parametroConcessionaria)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/concessionarias/${concessionariaId}/parametros`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createParametroConcessionaria(concessionariaId, parametroConcessionaria)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push(`/concessionarias/${concessionariaId}/parametros`)
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <ParametroConcessionariaForm
        concessionaria={this.props.concessionaria}
        multipleBaseCalculo={multipleBaseCalculo}
        multipleCalculoTributo={multipleCalculoTributo}
        multipleCalculoBandeira={multipleCalculoBandeira}
        multipleCalculoSubvencao={multipleCalculoSubvencao}
        multipleCalculoCreditoSubvencao={multipleCalculoCreditoSubvencao}
        onSubmit={(parametroConcessionaria) => this.handleSubmitResource(parametroConcessionaria)}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({parametroConcessionaria, status, flashMessage}, {match}) {
  const { id } = match.params

  const isLoading = status.getIn(['parametroConcessionaria', 'isLoading'])

  if (id) {
    return {
      id,
      parametroConcessionaria: parametroConcessionaria.get('id'),
      error: status.getIn(['parametroConcessionaria', 'error']),
      isLoading,
      shouldHideFlashMessage: flashMessage.get('isError'),
    }
  }
  return {
    error: status.getIn(['parametroConcessionaria', 'error']),
    isLoading,
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...parametroConcessionariaActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ParametroConcessionariaFormContainer))
