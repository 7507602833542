import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { BandeiraOverview } from '../../components'

class BandeiraOverviewContainer extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,

    pane: PropTypes.string,
  }

  state = {
    pane: null,
  }

  updatePane = () => {
    const pane = this.getPane(this.props.match)
    if (pane) {
      this.setState({ pane })
    } else {
      this.props.history.push('/gestao_bandeiras/bandeiras')
      this.setState({ pane: 'bandeiras' })
    }
  }

  componentDidMount() {
    this.updatePane()
  }

  componentDidUpdate(prevProps) {
    if (this.props.match !== prevProps.match) {
      this.updatePane()
    }
  }

  getPane = (match) => {
    if (match.params.pane) return match.params.pane
    return null
  }

  render () {
    return (
      <BandeiraOverview
        pane={this.state.pane}
      />
    )
  }
}

export default withRouter(BandeiraOverviewContainer)
