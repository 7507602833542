import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FieldArray, formValueSelector } from 'redux-form/immutable'
import { Table } from 'semantic-ui-react'
import RenderFormValoresP from './RenderFormValoresP'
import RenderFormValoresFP from './RenderFormValoresFP'
import RenderFormValoresNA from './RenderFormValoresNA'

const selector = formValueSelector('ContratoDemandaInstalacaoForm')

let RenderMeses = ({ mes, ano, mes_numero, mes_nome }) =>
  <Table.Row>
    <Table.Cell textAlign='right'>
      {mes_nome}/{ano}
    </Table.Cell>

    <FieldArray
      mes={mes_numero}
      name={`${mes}.valores`}
      component={RenderFormValoresP}
    />

    <FieldArray
      mes={mes_numero}
      name={`${mes}.valores`}
      component={RenderFormValoresFP}
    />

    <FieldArray
      mes={mes_numero}
      name={`${mes}.valores`}
      component={RenderFormValoresNA}
    />
  </Table.Row>

RenderMeses = connect(
  (state, props) => {
    return ({
      ano: selector(state, `${props.mes}.ano`),
      mes_numero: selector(state, `${props.mes}.mes`),
      mes_nome: selector(state, `${props.mes}.mes_nome`),
    })
  }
)(RenderMeses)

RenderMeses.propTypes = {
  loading: PropTypes.bool,
  ano: PropTypes.string,
  mes: PropTypes.string,
  mes_nome: PropTypes.string,
}

export default RenderMeses
