import React from 'react'
import PropTypes from 'prop-types'
import { Header, Table } from 'semantic-ui-react'
import { CRUDShow } from '../CRUD'
import { maskFloat } from '../../utils/masks'
import { formatDate } from '../../utils/formats'
import { getPostos } from '../../utils/enums/tipoTarifa'

const DECIMAL_DIGITS = 6
const transform = (value, multiplier = 1.0) => (parseFloat(value) * multiplier).toFixed(DECIMAL_DIGITS)

const renderPosto = (values, classes, posto) => (
  <React.Fragment>
    <Table.Cell textAlign='center'>{posto.descricao}</Table.Cell>
    <Table.Cell className={classes[`tusd_kw_${posto.sigla}`]} textAlign='right'>{maskFloat.parse(values[`tusd_kw_${posto.sigla}`], DECIMAL_DIGITS)}</Table.Cell>
    <Table.Cell className={classes[`tusd_kwh_${posto.sigla}`]} textAlign='right'>{maskFloat.parse(values[`tusd_kwh_${posto.sigla}`], DECIMAL_DIGITS)}</Table.Cell>
    <Table.Cell className={classes[`te_kwh_${posto.sigla}`]} textAlign='right'>{maskFloat.parse(values[`te_kwh_${posto.sigla}`], DECIMAL_DIGITS)}</Table.Cell>
  </React.Fragment>
)

const VigenciaTarifaShow = (props) => {
  if (props.vigenciaTarifa) {
    return (
      <CRUDShow
        title={'Detalhe de Tarifa'}
        titleAs={'h3'}
        icon='money'
        padded={false}
        isLoading={props.isLoading}
        onEditResource={() => props.onEditResource(props.vigenciaTarifa.id)}
        onDeleteResource={() => props.onDeleteResource(props.vigenciaTarifa.id)}>
        <Header as='h2' block content={formatDate(props.vigenciaTarifa.starts_at)} subheader='Início da Vigência' />
        <Table celled className='tableTarifa'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign='center' width={2} rowSpan={2}>Subgrupo</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={1} rowSpan={2}>Enquadramento</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={1} rowSpan={2}>Posto</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={6} rowSpan={1} colSpan={2}>TUSD</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={3} rowSpan={1}>TE</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={3} rowSpan={1}>TR</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell textAlign='right'>R$/kW</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>R$/MWh</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>R$/MWh</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>R$/MWh</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {props.vigenciaTarifa.valores_tarifas.map((valorTarifa, index) => {
            const subgrupo = props.multipleSubgrupo.get(`${valorTarifa.subgrupo_id}`)
            const enquadramento = props.multipleEnquadramento.get(`${valorTarifa.enquadramento_id}`)
            const postos = getPostos(enquadramento, subgrupo)
            const totalPostos = postos.length

            const values = {
              tusd_kw_p: transform(valorTarifa.tusd_kw_p),
              tusd_kwh_p: transform(valorTarifa.tusd_kwh_p, 1000.0),
              te_kwh_p: transform(valorTarifa.te_kwh_p, 1000.0),
              tusd_kw_fp: transform(valorTarifa.tusd_kw_fp),
              tusd_kwh_fp: transform(valorTarifa.tusd_kwh_fp, 1000.0),
              te_kwh_fp: transform(valorTarifa.te_kwh_fp, 1000.0),
              tusd_kw_na: transform(valorTarifa.tusd_kw_na),
              tusd_kwh_na: transform(valorTarifa.tusd_kwh_na, 1000.0),
              te_kwh_na: transform(valorTarifa.te_kwh_na, 1000.0),
              tusd_kw_int: transform(valorTarifa.tusd_kw_int),
              tusd_kwh_int: transform(valorTarifa.tusd_kwh_int, 1000.0),
              te_kwh_int: transform(valorTarifa.te_kwh_int, 1000.0),
              tr_kwh: transform(valorTarifa.tr_kwh, 1000.0),
            }

            const classes = {
              tusd_kw_p: parseFloat(valorTarifa.tusd_kw_p) > 0.0 ? '' : 'fadeCell',
              tusd_kwh_p: parseFloat(valorTarifa.tusd_kwh_p) > 0.0 ? '' : 'fadeCell',
              te_kwh_p: parseFloat(valorTarifa.te_kwh_p) > 0.0 ? '' : 'fadeCell',
              tusd_kw_fp: parseFloat(valorTarifa.tusd_kw_fp) > 0.0 ? '' : 'fadeCell',
              tusd_kwh_fp: parseFloat(valorTarifa.tusd_kwh_fp) > 0.0 ? '' : 'fadeCell',
              te_kwh_fp: parseFloat(valorTarifa.te_kwh_fp) > 0.0 ? '' : 'fadeCell',
              tusd_kw_na: parseFloat(valorTarifa.tusd_kw_na) > 0.0 ? '' : 'fadeCell',
              tusd_kwh_na: parseFloat(valorTarifa.tusd_kwh_na) > 0.0 ? '' : 'fadeCell',
              te_kwh_na: parseFloat(valorTarifa.te_kwh_na) > 0.0 ? '' : 'fadeCell',
              tusd_kw_int: parseFloat(valorTarifa.tusd_kw_int) > 0.0 ? '' : 'fadeCell',
              tusd_kwh_int: parseFloat(valorTarifa.tusd_kwh_int) > 0.0 ? '' : 'fadeCell',
              te_kwh_int: parseFloat(valorTarifa.te_kwh_int) > 0.0 ? '' : 'fadeCell',
              tr_kwh: parseFloat(valorTarifa.tr_kwh) > 0.0 ? '' : 'fadeCell',
            }

            const shouldShow = Object.values(values).some(v => v > 0.0)

            if (!shouldShow || totalPostos === 0) return (null)

            if (totalPostos === 1) return (
              <Table.Body key={index}>
                <Table.Row>
                  <Table.Cell textAlign='center'>{ subgrupo.get('nome') }</Table.Cell>
                  <Table.Cell textAlign='center'>{ enquadramento.get('modalidade') }</Table.Cell>
                  {renderPosto(values, classes, postos[0])}
                  <Table.Cell className={classes.tr_kwh} textAlign='right'>{maskFloat.parse(values.tr_kwh, DECIMAL_DIGITS)}</Table.Cell>
                </Table.Row>
              </Table.Body>
            )

            return (
              <Table.Body key={index}>
                <Table.Row>
                  <Table.Cell rowSpan={totalPostos} textAlign='center'>{ subgrupo.get('nome') }</Table.Cell>
                  <Table.Cell rowSpan={totalPostos} textAlign='center'>{ enquadramento.get('modalidade') }</Table.Cell>
                  {renderPosto(values, classes, postos[0])}
                  <Table.Cell rowSpan={totalPostos} className={classes.tr_kwh} textAlign='right'>{maskFloat.parse(values.tr_kwh, DECIMAL_DIGITS)}</Table.Cell>
                </Table.Row>
                {postos.slice(1, postos.length).map((p, index) => {
                  return (
                    <Table.Row key={index}>{renderPosto(values, classes, p)}</Table.Row>
                  )
                })}
              </Table.Body>
            )
          })}
        </Table>
      </CRUDShow>
    )
  }

  return (null)
}

VigenciaTarifaShow.propTypes = {
  vigenciaTarifa: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  multipleSubgrupo: PropTypes.object,
  multipleEnquadramento: PropTypes.object,
  isLoading: PropTypes.bool,
}

export default VigenciaTarifaShow
