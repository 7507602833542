import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import ResultadoPrintContainer from "./containers/Resultado/ResultadoPrintContainer";
import {
  LoadingContainer,
  MenuContainer,
  HomeContainer,
  LoginContainer,
  ErrorContainer,
  Bandeira,
  ClasseConsumo,
  Cliente,
  Comercializadora,
  Concessionaria,
  Contato,
  Enquadramento,
  FonteEnergia,
  Funcao,
  Gerador,
  Icms,
  Instalacao,
  FaturaEnergia,
  PainelResumo,
  PldMedio,
  PldSemanal,
  Servico,
  Subgrupo,
  Submercado,
  Tributo,
  Uf,
  VigenciaBandeira,
} from "./containers";

import RelatorioByToken from "./containers/RelatorioByToken/RelatorioByToken";

import { TopNavbar } from "./components";

import { If, Then, Else } from "react-if";

import ScrollToTop from "./components/ScrollToTop/ScrollToTop.js";
import * as sessionActionCreators from "./redux/modules/session";

const AppBody = () => (
  <div style={{ minHeight: "100vh", background: "rgb(242, 242, 242)" }}>
    <MenuContainer />
    <div
      style={{
        marginTop: "0px",
        marginLeft: "260px",
        padding: "6px",
        background: "rgb(242, 242, 242)",
      }}
    >
      <TopNavbar />
      <Switch>
        <Route exact path="/" component={HomeContainer} />
        <Route exact path="/login" component={() => <Redirect to="/" />} />

        <Route
          exact
          path="/gestao_bandeiras/:pane?/:paneId?/:action?"
          component={Bandeira.Overview}
        />

        <Route exact path="/classes_consumo" component={ClasseConsumo.Index} />
        <Route
          exact
          path="/classes_consumo/new"
          component={ClasseConsumo.Form}
        />
        <Route
          exact
          path="/classes_consumo/:id/edit"
          component={ClasseConsumo.Form}
        />
        <Route
          exact
          path="/classes_consumo/:id"
          component={ClasseConsumo.Show}
        />

        <Route exact path="/clientes" component={Cliente.Index} />
        <Route exact path="/clientes/new" component={Cliente.Form} />
        <Route exact path="/clientes/:id/edit" component={Cliente.Form} />
        <Route exact path="/clientes/:id" component={Cliente.Show} />
        <Route
          exact
          path="/clientes/:id/:pane/:paneId?/:action?"
          component={Cliente.Show}
        />

        <Route
          exact
          path="/comercializadoras"
          component={Comercializadora.Index}
        />
        <Route
          exact
          path="/comercializadoras/new"
          component={Comercializadora.Form}
        />
        <Route
          exact
          path="/comercializadoras/:id/edit"
          component={Comercializadora.Form}
        />
        <Route
          exact
          path="/comercializadoras/:id"
          component={Comercializadora.Show}
        />
        <Route
          exact
          path="/comercializadoras/:id/:pane/:paneId?/:action?"
          component={Comercializadora.Show}
        />

        <Route exact path="/concessionarias" component={Concessionaria.Index} />
        <Route
          exact
          path="/concessionarias/new"
          component={Concessionaria.Form}
        />
        <Route
          exact
          path="/concessionarias/:id/edit"
          component={Concessionaria.Form}
        />
        <Route
          exact
          path="/concessionarias/:id"
          component={Concessionaria.Show}
        />
        <Route
          exact
          path="/concessionarias/:id/:pane/:paneId?/:action?"
          component={Concessionaria.Show}
        />

        <Route exact path="/contatos" component={Contato.Index} />
        <Route exact path="/contatos/new" component={Contato.Form} />
        <Route exact path="/contatos/:id/edit" component={Contato.Form} />
        <Route exact path="/contatos/:id" component={Contato.Show} />

        <Route exact path="/enquadramentos" component={Enquadramento.Index} />
        <Route
          exact
          path="/enquadramentos/new"
          component={Enquadramento.Form}
        />
        <Route
          exact
          path="/enquadramentos/:id/edit"
          component={Enquadramento.Form}
        />
        <Route
          exact
          path="/enquadramentos/:id"
          component={Enquadramento.Show}
        />
        <Route
          exact
          path="/enquadramentos/:id/:pane/:paneId?/:action?"
          component={Enquadramento.Show}
        />

        <Route exact path="/fontes_energia" component={FonteEnergia.Index} />
        <Route exact path="/fontes_energia/new" component={FonteEnergia.Form} />
        <Route
          exact
          path="/fontes_energia/:id/edit"
          component={FonteEnergia.Form}
        />
        <Route exact path="/fontes_energia/:id" component={FonteEnergia.Show} />

        <Route exact path="/geradores" component={Gerador.Index} />
        <Route exact path="/geradores/new" component={Gerador.Form} />
        <Route exact path="/geradores/:id/edit" component={Gerador.Form} />
        <Route exact path="/geradores/:id" component={Gerador.Show} />

        <Route exact path="/funcoes" component={Funcao.Index} />
        <Route exact path="/funcoes/new" component={Funcao.Form} />
        <Route exact path="/funcoes/:id/edit" component={Funcao.Form} />
        <Route exact path="/funcoes/:id" component={Funcao.Show} />

        <Route exact path="/icms" component={Icms.Index} />
        <Route exact path="/icms/new" component={Icms.Form} />
        <Route exact path="/icms/:id/edit" component={Icms.Form} />
        <Route exact path="/icms/:id" component={Icms.Show} />

        <Route exact path="/instalacoes" component={Instalacao.Index} />
        <Route exact path="/instalacoes/new" component={Instalacao.Form} />
        <Route exact path="/instalacoes/:id/edit" component={Instalacao.Form} />
        <Route exact path="/instalacoes/:id" component={Instalacao.Show} />
        <Route
          exact
          path="/instalacoes/:id/:pane/:paneId?/:subPane?/:subPaneId?/:action?"
          component={Instalacao.Show}
        />
        <Route
          exact
          path="/instalacoes/:id/:pane/:paneId?/:action?"
          component={Instalacao.Show}
        />

        <Route exact path="/indicativos/medios" component={PldMedio.Index} />
        <Route
          exact
          path="/indicativos/medios/edit"
          component={PldMedio.Form}
        />

        <Route
          exact
          path="/indicativos/semanais"
          component={PldSemanal.Index}
        />
        <Route
          exact
          path="/indicativos/semanais/edit"
          component={PldSemanal.Form}
        />
        <Route
          exact
          path="/indicativos/semanais/upload"
          component={PldSemanal.Import}
        />

        <Route exact path="/faturas_energia" component={FaturaEnergia.Index} />
        <Route
          exact
          path="/faturas_energia/new"
          component={FaturaEnergia.Form}
        />
        <Route
          exact
          path="/faturas_energia/:id/edit"
          component={FaturaEnergia.Form}
        />
        <Route
          exact
          path="/faturas_energia/:id"
          component={FaturaEnergia.Show}
        />

        <Route exact path="/resumos" component={PainelResumo.Index} />
        <Route exact path="/servicos" component={Servico.Index} />
        <Route exact path="/servicos/new" component={Servico.Form} />
        <Route exact path="/servicos/:id/edit" component={Servico.Form} />
        <Route exact path="/servicos/:id" component={Servico.Show} />

        <Route exact path="/subgrupos" component={Subgrupo.Index} />
        <Route exact path="/subgrupos/new" component={Subgrupo.Form} />
        <Route exact path="/subgrupos/:id/edit" component={Subgrupo.Form} />
        <Route exact path="/subgrupos/:id" component={Subgrupo.Show} />

        <Route exact path="/submercados" component={Submercado.Index} />
        <Route exact path="/submercados/new" component={Submercado.Form} />
        <Route exact path="/submercados/:id/edit" component={Submercado.Form} />
        <Route exact path="/submercados/:id" component={Submercado.Show} />

        <Route exact path="/tributos" component={Tributo.Index} />
        <Route exact path="/tributos/new" component={Tributo.Form} />
        <Route exact path="/tributos/:id/edit" component={Tributo.Form} />
        <Route exact path="/tributos/:id" component={Tributo.Show} />

        <Route exact path="/uf" component={Uf.Index} />
        <Route exact path="/uf/new" component={Uf.Form} />
        <Route exact path="/uf/:id/edit" component={Uf.Form} />
        <Route exact path="/uf/:id" component={Uf.Show} />

        <Route
          exact
          path="/vigencias_bandeira"
          component={VigenciaBandeira.Index}
        />
        <Route
          exact
          path="/vigencias_bandeira/new"
          component={VigenciaBandeira.Form}
        />
        <Route
          exact
          path="/vigencias_bandeira/:id/edit"
          component={VigenciaBandeira.Form}
        />
        <Route
          exact
          path="/vigencias_bandeira/:id"
          component={VigenciaBandeira.Show}
        />

        <Route component={() => <ErrorContainer type={404} />} />
      </Switch>
    </div>
  </div>
);

class App extends Component {
  static propTypes = {
    loadUser: PropTypes.func.isRequired,
    isAuthenticating: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isAuthenticating: true,
  };

  async componentDidMount() {
    if (localStorage.jwt) {
      const { payload } = JSON.parse(localStorage.jwt);
      await this.props.loadUser(payload.sub);
    }
  }

  render() {
    const { isAuthenticating, isAuthenticated } = this.props;

    return (
      <Router>
        <ScrollToTop>
          <If condition={isAuthenticating}>
            <Then>
              <Route component={LoadingContainer} />
            </Then>
            <Else>
              <If condition={isAuthenticated}>
                <Then>
                  <If condition={isClient()}>
                    <Then>
                      <Switch>
                        <Route exact path="/" component={AppBody} />
                        <Route
                          exact
                          path="/relatorio/:token"
                          component={RelatorioByToken}
                        />
                        <Route component={() => <Redirect to="/" />} />
                      </Switch>
                    </Then>
                    <Else>
                      <Switch>
                        <Route
                          exact
                          path="/relatorio/:token"
                          component={RelatorioByToken}
                        />
                        <Route
                          exact
                          path="/instalacoes/:id/relatorios/:paneId/print"
                          render={({ match }) => (
                            <Instalacao.Show match={match} hideMenu />
                          )}
                        />
                        <Route
                          exact
                          path="/instalacoes/:instalacao_id/pedagio/:fatura_id/resultado/print"
                          component={ResultadoPrintContainer}
                        />
                        <Route path="/" component={AppBody} />
                      </Switch>
                    </Else>
                  </If>
                </Then>
                <Else>
                  <Switch>
                    <Route
                      exact
                      path="/relatorio/:token"
                      component={RelatorioByToken}
                    />
                    <Route
                      exact
                      path="/"
                      component={() => <Redirect to="/login" />}
                    />
                    <Route exact path="/login" component={LoginContainer} />
                    <Route component={() => <LoginContainer/>} />
                  </Switch>
                </Else>
              </If>
            </Else>
          </If>
        </ScrollToTop>
      </Router>
    );
  }
}

function mapStateToProps({ status }) {
  return {
    isAuthenticated: status.getIn(["session", "isAuthenticated"]),
    isAuthenticating: status.getIn(["session", "isAuthenticating"]),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(sessionActionCreators, dispatch);
}

function isClient() {
  if (localStorage.jwt) {
    const { payload } = JSON.parse(localStorage.jwt);
    return payload.roles.includes("cliente");
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
