import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FieldArray, FormSection } from 'redux-form/immutable'
import { Form, FormField, Divider, Table } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticDropdown, DatePickerInput } from '../FormComponents'

import { required, dateValidator } from '../../utils/validations'

import RenderValoresEnergia from './RenderValoresEnergia'

function anosOptions() {
  let arr = []
  const currentYear = (new Date()).getFullYear()
  for (let year = currentYear; year >= 2003; year--) {
    arr.push({
      key: `${year}`,
      text: `${year}`,
      value: year
    })
  }
  return arr
}

const
  mesOptions = [
    { key: 'jan', text: 'Janeiro', value: 1 },
    { key: 'fev', text: 'Fevereiro', value: 2 },
    { key: 'mar', text: 'Março', value: 3 },
    { key: 'abr', text: 'Abril', value: 4 },
    { key: 'mai', text: 'Maio', value: 5 },
    { key: 'jun', text: 'Junho', value: 6 },
    { key: 'jul', text: 'Julho', value: 7 },
    { key: 'ago', text: 'Agosto', value: 8 },
    { key: 'set', text: 'Setembro', value: 9 },
    { key: 'out', text: 'Outubro', value: 10 },
    { key: 'nov', text: 'Novembro', value: 11 },
    { key: 'dez', text: 'Dezembro', value: 12 },
  ]

const diasOptions = Array.from(Array(15).keys(), v => ({
  key: `${v + 1}`,
  text: `${v + 1}`,
  value: v + 1
}))

const
  indexadorOptions = [
    { key: 'igpm', text: 'IGPM', value: 1 },
    { key: 'ipca', text: 'IPCA', value: 2 },
  ]

const ContratoEnergiaInstalacaoForm = (props) => (
  <CRUDForm
    title='Contrato de Energia'
    titleAs='h3'
    icon='file text'
    padded={false}
    {...props}>
    <FormSection name='contrato_energia'>
      <Divider section horizontal>Dados Principais</Divider>

      <Form.Group>
        <FormField width={4}>
          <label htmlFor='ano_inicial'>Ano Início</label>
          <Field fluid button floating labeled search icon='calendar' className='icon'
            name='ano_inicial'
            options={anosOptions()}
            validate={[required]}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
        <FormField width={4}>
          <label htmlFor='mes_inicial'>Mês Início</label>
          <Field fluid button floating labeled search icon='calendar' className='icon'
            name='mes_inicial'
            options={mesOptions}
            validate={[required]}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
        <FormField width={4}>
          <label htmlFor='ano_final'>Ano Fim</label>
          <Field fluid button floating labeled search icon='calendar' className='icon'
            name='ano_final'
            options={anosOptions()}
            validate={[required]}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
        <FormField width={4}>
          <label htmlFor='mes_final'>Mês Fim</label>
          <Field fluid button floating labeled search icon='calendar' className='icon'
            name='mes_final'
            options={mesOptions}
            validate={[required]}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>

      <Form.Group>
        <FormField width={8}>
          <label htmlFor='user_id'>Comercializadora</label>
          <Field fluid button floating labeled search icon='shopping cart' className='icon'
            name='comercializadora_id'
            options={props.comercializadoraDropdown}
            validate={[required]}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
        <FormField width={8}>
          <label htmlFor='user_id'>Fonte de Energia</label>
          <Field fluid button floating labeled search icon='lightning' className='icon'
            name='fonte_energia_id'
            options={props.fonteEnergiaDropdown}
            validate={[required]}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>

      <Form.Group>
        <FormField width={4}>
          <label htmlFor='indexador'>Indexador</label>
          <Field fluid button floating labeled search icon='lightning' className='icon'
            name='indexador'
            options={indexadorOptions}
            validate={[required]}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
        <FormField width={4}>
          <label htmlFor='database'>Data Base</label>
          <Field
            name='database'
            placeholder='Data Base'
            validate={[required, dateValidator]}
            component={DatePickerInput}
            loading={props.loading}
          />
        </FormField>
        <FormField width={4}>
          <label htmlFor='venc_fatura'>Vencimento Fatura</label>
          <Field fluid button floating labeled search icon='calendar' className='icon'
            name='venc_fatura'
            options={diasOptions}
            validate={[required]}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
        <FormField width={4}>
          <label htmlFor='envio_medicao'>Envio Medição</label>
          <Field fluid button floating labeled search icon='calendar' className='icon'
            name='envio_medicao'
            options={diasOptions}
            validate={[required]}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>

      <Divider section horizontal>Valores do Contrato</Divider>

      <Form.Group>
        <Form.Field width={16}>
          <Table celled striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1} textAlign='right'>Valor</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='right'>Proinfa</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='right'>Preço</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='right'>Modulação</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='right'>Sazonalização</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='right'>Flexibilidade</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <FieldArray
              name='valores_energia_attributes'
              component={RenderValoresEnergia}
            />
          </Table>
        </Form.Field>
      </Form.Group>
    </FormSection>
  </CRUDForm>
)

ContratoEnergiaInstalacaoForm.propTypes = {
  comercializadoraDropdown: PropTypes.array,
  fonteEnergiaDropdown: PropTypes.array,

  isLoading: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  history: PropTypes.object,

  year: PropTypes.number,
  years: PropTypes.array,
  month: PropTypes.number,
  months: PropTypes.array,
}

export default reduxForm({form: 'ContratoEnergiaInstalacaoForm'})(ContratoEnergiaInstalacaoForm)
