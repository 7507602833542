import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { initialize } from 'redux-form/immutable'
import { change } from 'redux-form/immutable'
import { bindActionCreators, compose } from 'redux'

import Recados from '../../components/Relatorio/Recados'
import * as relatorioActionCreators from '../../redux/modules/relatorio'
import withViewMode from '../../utils/withViewMode'

class RecadosContainer extends Component {
  state = {
    editMode: false
  }

  static propTypes = {
    id: PropTypes.number,
    isLoading: PropTypes.bool,
    print: PropTypes.bool,

    recados: PropTypes.object,
    gestor: PropTypes.object,
    match: PropTypes.object,

    initialize: PropTypes.func,
    change: PropTypes.func,
    updateRelatorio: PropTypes.func,
  }

  async componentDidMount () {
    const relatorio = { recados: this.props.recados }
    this.props.initialize('RecadosForm', { relatorio })
  }

  componentDidUpdate = async () => {
    const relatorio = { recados: this.props.recados }
    this.props.initialize('RecadosForm', { relatorio })
  }

  handleToggleMode = () => this.setState({ editMode: !this.state.editMode })

  handleSubmitResource = async (resource) => {
    const { match } = this.props
    const id = match && match.params && match.params.id

    const original = resource.getIn(['relatorio', 'recados'])
    const sorted = original.sortBy(r => -r.get('tipo'))
    resource = resource.setIn(['relatorio', 'recados'], sorted)

    if (await this.props.updateRelatorio(id, resource)) {
      this.handleToggleMode()
    } else {
      alert('Não foi possível salvar. Tente novamente.')
    }
  }

  render() {
    const { recados, gestor, match } = this.props

    const id = match && match.params && match.params.id
    const print = match.url.endsWith('/print')

    return (
      <Recados
        id={id}
        gestor={gestor}
        recados={recados}
        print={print}
        isEditMode={this.state.editMode}
        onToggleMode={this.handleToggleMode}
        onSubmit={resource => this.handleSubmitResource(resource)}
        isLoading={this.props.isLoading}
      />
    )
  }
}

const mapStateToProps = ({ relatorio, status }, { relatorio: { id } }) => {
  return {
    isLoading: status.getIn(['relatorio', 'isLoading']),
    recados: relatorio.getIn([`${id}`, 'recados'])
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...relatorioActionCreators,
    ...{change},
    ...{initialize},
  }, dispatch)
}

export default compose(
  withViewMode,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(RecadosContainer)
