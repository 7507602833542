import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_EXTRA_PEDAGIO = 'LOADING_EXTRA_PEDAGIO'
export function loadingExtraPedagio () {
  return {
    type: LOADING_EXTRA_PEDAGIO,
  }
}

export const LOADING_EXTRA_PEDAGIO_SUCCESS = 'LOADING_EXTRA_PEDAGIO_SUCCESS'
export function loadingExtraPedagioSuccess (extraPedagio) {
  return {
    type: LOADING_EXTRA_PEDAGIO_SUCCESS,
    extraPedagio,
  }
}

export const LOADING_EXTRA_PEDAGIO_FAILURE = 'LOADING_EXTRA_PEDAGIO_FAILURE'
export function loadingExtraPedagioFailure (error) {
  return {
    type: LOADING_EXTRA_PEDAGIO_FAILURE,
    error,
  }
}

export const DESTROYING_EXTRA_PEDAGIO_SUCCESS = 'DESTROYING_EXTRA_PEDAGIO_SUCCESS'
export function destroyingExtraPedagioSuccess (extraPedagioId) {
  return {
    type: DESTROYING_EXTRA_PEDAGIO_SUCCESS,
    extraPedagioId,
  }
}

const initialState = Map({})

export function fetchMultipleExtraPedagio (faturaPedagioId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingExtraPedagio())
      const multipleExtraPedagio = await callAPI(`/faturas_pedagio/${faturaPedagioId}/extras_pedagio`)
      dispatch(loadingExtraPedagioSuccess(multipleExtraPedagio))
      return multipleExtraPedagio
    } catch (e) {
      dispatch(loadingExtraPedagioFailure(e))
      return false
    }
  }
}

export function fetchExtraPedagio (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingExtraPedagio())
      const extraPedagio = await callAPI(`/extras_pedagio/${id}`)
      dispatch(loadingExtraPedagioSuccess(extraPedagio))
      return extraPedagio
    } catch (e) {
      dispatch(loadingExtraPedagioFailure(e))
      return false
    }
  }
}

export function createExtraPedagio (faturaPedagioId, extraPedagio) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingExtraPedagio())
      const newExtraPedagio = await callAPI(`/faturas_pedagio/${faturaPedagioId}/extras_pedagio`, 'POST', extraPedagio)
      dispatch(loadingExtraPedagioSuccess(newExtraPedagio))
      return newExtraPedagio
    } catch (e) {
      dispatch(loadingExtraPedagioFailure(e))
      return false
    }
  }
}

export function updateExtraPedagio (id, extraPedagio) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingExtraPedagio())
      const newExtraPedagio = await callAPI(`/extras_pedagio/${id}`, 'PUT', extraPedagio)
      dispatch(loadingExtraPedagioSuccess(newExtraPedagio))
      return newExtraPedagio
    } catch (e) {
      dispatch(loadingExtraPedagioFailure(e))
      return false
    }
  }
}

export function destroyExtraPedagio (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingExtraPedagio())
      await callAPI(`/extras_pedagio/${id}`, 'DELETE')
      dispatch(destroyingExtraPedagioSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingExtraPedagioFailure(e))
      return false
    }
  }
}

export default function extraPedagio (state = initialState, action) {
  switch (action.type) {

    case LOADING_EXTRA_PEDAGIO_SUCCESS : {

      const { extraPedagio } = action
      if (extraPedagio.constructor === Array) {
        state = initialState
        extraPedagio.forEach((singleExtraPedagio) => (
          state = state.merge({[singleExtraPedagio.id]: singleExtraPedagio})
        ))
        return state
      }
      return state.merge({[extraPedagio.id]: extraPedagio})
    }

    case DESTROYING_EXTRA_PEDAGIO_SUCCESS :
      return state.delete(action.extraPedagioId.toString())

    default :
      return state
  }
}
