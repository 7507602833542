import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_SERVICO_INSTALACAO = 'LOADING_SERVICO_INSTALACAO'
export function loadingServicoInstalacao () {
  return {
    type: LOADING_SERVICO_INSTALACAO,
  }
}

export const LOADING_SERVICO_INSTALACAO_SUCCESS = 'LOADING_SERVICO_INSTALACAO_SUCCESS'
export function loadingServicoInstalacaoSuccess (servicoInstalacao) {
  return {
    type: LOADING_SERVICO_INSTALACAO_SUCCESS,
    servicoInstalacao,
  }
}

export const LOADING_SERVICO_INSTALACAO_FAILURE = 'LOADING_SERVICO_INSTALACAO_FAILURE'
export function loadingServicoInstalacaoFailure (error) {
  return {
    type: LOADING_SERVICO_INSTALACAO_FAILURE,
    error,
  }
}

export const DESTROYING_SERVICO_INSTALACAO_SUCCESS = 'DESTROYING_SERVICO_INSTALACAO_SUCCESS'
export function destroyingServicoInstalacaoSuccess (servicoInstalacaoId) {
  return {
    type: DESTROYING_SERVICO_INSTALACAO_SUCCESS,
    servicoInstalacaoId,
  }
}

const initialState = Map({})

export function fetchMultipleServicoInstalacao (instalacaoId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingServicoInstalacao())
      const multipleServicoInstalacao = await callAPI(`/instalacoes/${instalacaoId}/servicos_prestados`)
      dispatch(loadingServicoInstalacaoSuccess(multipleServicoInstalacao))
      return multipleServicoInstalacao
    } catch (e) {
      dispatch(loadingServicoInstalacaoFailure(e))
      return false
    }
  }
}

export function fetchServicoInstalacao (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingServicoInstalacao())
      const servicoInstalacao = await callAPI(`/servicos_prestados/${id}`)
      dispatch(loadingServicoInstalacaoSuccess(servicoInstalacao))
      return servicoInstalacao
    } catch (e) {
      dispatch(loadingServicoInstalacaoFailure(e))
      return false
    }
  }
}

export function createServicoInstalacao (instalacaoId, servicoInstalacao) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingServicoInstalacao())
      const newServicoInstalacao = await callAPI(`/instalacoes/${instalacaoId}/servicos_prestados`, 'POST', servicoInstalacao)
      dispatch(loadingServicoInstalacaoSuccess(newServicoInstalacao))
      return newServicoInstalacao
    } catch (e) {
      dispatch(loadingServicoInstalacaoFailure(e))
      return false
    }
  }
}

export function updateServicoInstalacao (id, servicoInstalacao) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingServicoInstalacao())
      const newServicoInstalacao = await callAPI(`/servicos_prestados/${id}`, 'PUT', servicoInstalacao)
      dispatch(loadingServicoInstalacaoSuccess(newServicoInstalacao))
      return newServicoInstalacao
    } catch (e) {
      dispatch(loadingServicoInstalacaoFailure(e))
      return false
    }
  }
}

export function destroyServicoInstalacao (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingServicoInstalacao())
      await callAPI(`/servicos_prestados/${id}`, 'DELETE')
      dispatch(destroyingServicoInstalacaoSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingServicoInstalacaoFailure(e))
      return false
    }
  }
}

export default function servicoInstalacao (state = initialState, action) {
  switch (action.type) {

    case LOADING_SERVICO_INSTALACAO_SUCCESS : {

      const { servicoInstalacao } = action
      if (servicoInstalacao.constructor === Array) {
        state = initialState
        servicoInstalacao.forEach((singleServicoInstalacao) => (
          state = state.merge({[singleServicoInstalacao.id]: singleServicoInstalacao})
        ))
        return state
      }
      return state.merge({[servicoInstalacao.id]: servicoInstalacao})
    }

    case DESTROYING_SERVICO_INSTALACAO_SUCCESS :
      return state.delete(action.servicoInstalacaoId.toString())

    default :
      return state
  }
}
