import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Submercado } from '../../components'
import * as submercadoActionCreators from '../../redux/modules/submercado'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class SubmercadoContainer extends Component {

  static propTypes = {
    history: PropTypes.object,
    multipleSubmercado: PropTypes.object,
    fetchMultipleSubmercado: PropTypes.func,
    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    destroySubmercado: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleSubmercado()
  }

  handleRedirectToNew = () => {
    this.props.history.push('/submercados/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/submercados/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroySubmercado(id)
      ? this.props.showSuccessfulFlashMessage('', 'destroy')
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  handleSort = column => () => this.props.updateSort('submercado', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('submercado', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleSubmercado.toList().toJSON(),
      'submercado',
      [ 'id', 'nome', 'descricao', 'uf.sigla', 'uf.nome' ],
      [ 'uf' ]
    )
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <Submercado
        onRedirectToNew={this.handleRedirectToNew}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        multipleSubmercado={this.props.multipleSubmercado}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({ submercado, status, sort, filter }) {
  const sortColumn = sort.getIn(['submercado', 'sortColumn']) || 'nome'
  const sortDirection = sort.getIn(['submercado', 'sortDirection']) || 'ascending'
  const filterText = filter.getIn(['submercado', 'filterText']) || ''

  let map = filterMap(submercado, ['nome', 'descricao'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleSubmercado: map,
    sortColumn,
    sortDirection,
    filterText,
    error: status.getIn(['submercado', 'error']),
    isLoading: status.getIn(['submercado', 'isLoading']),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...submercadoActionCreators,
    ...flashMessageAcionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmercadoContainer)
