import React from 'react'
import PropTypes from 'prop-types'
import ValorTarifa from './ValorTarifa'

const RenderValoresTarifas = ({ fields, loading }) => {
  return fields.map((valorTarifa, index) => (
    <ValorTarifa
      key={index}
      valorTarifa={valorTarifa}
      loading={loading}
    />
  ))
}

RenderValoresTarifas.propTypes = {
  fields: PropTypes.object,
  loading: PropTypes.bool,
}

export default RenderValoresTarifas
