import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'

const SubmercadoShow = (props) => {
  if (props.submercado) {
    return (
      <CRUDShow
        title={props.submercado.nome}
        icon='map'
        onEditResource={() => props.onEditResource(props.submercado.id)}
        onDeleteResource={() => props.onDeleteResource(props.submercado.id)}>
        <ul>
          <li>Nome: {props.submercado.nome}</li>
          <li>Descrição: {props.submercado.descricao}</li>
        </ul>
      </CRUDShow>
    )
  }

  return (null)
}

SubmercadoShow.propTypes = {
  submercado: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
}

export default SubmercadoShow
