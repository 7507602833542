import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_SERVICO = 'LOADING_SERVICO'
export function loadingServico () {
  return {
    type: LOADING_SERVICO,
  }
}

export const LOADING_SERVICO_SUCCESS = 'LOADING_SERVICO_SUCCESS'
export function loadingServicoSuccess (servico) {
  return {
    type: LOADING_SERVICO_SUCCESS,
    servico,
  }
}

export const LOADING_SERVICO_FAILURE = 'LOADING_SERVICO_FAILURE'
export function loadingServicoFailure (error) {
  return {
    type: LOADING_SERVICO_FAILURE,
    error,
  }
}

export const DESTROYING_SERVICO_SUCCESS = 'DESTROYING_SERVICO_SUCCESS'
export function destroyingServicoSuccess (servicoId) {
  return {
    type: DESTROYING_SERVICO_SUCCESS,
    servicoId,
  }
}

const initialState = Map({})

export function fetchMultipleServico () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingServico())
      const multipleServico = await callAPI('/servicos')
      dispatch(loadingServicoSuccess(multipleServico))
      return multipleServico
    } catch (e) {
      dispatch(loadingServicoFailure(e))
      return false
    }
  }
}

export function fetchServico (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingServico())
      const servico = await callAPI(`/servicos/${id}`)
      dispatch(loadingServicoSuccess(servico))
      return servico
    } catch (e) {
      dispatch(loadingServicoFailure(e))
      return false
    }
  }
}

export function createServico (servico) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingServico())
      const newServico = await callAPI('/servicos', 'POST', servico)
      dispatch(loadingServicoSuccess(newServico))
      return newServico
    } catch (e) {
      dispatch(loadingServicoFailure(e))
      return false
    }
  }
}

export function updateServico (id, servico) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingServico())
      const newServico = await callAPI(`/servicos/${id}`, 'PUT', servico)
      dispatch(loadingServicoSuccess(newServico))
      return newServico
    } catch (e) {
      dispatch(loadingServicoFailure(e))
      return false
    }
  }
}

export function destroyServico (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingServico())
      await callAPI(`/servicos/${id}`, 'DELETE')
      dispatch(destroyingServicoSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingServicoFailure(e))
      return false
    }
  }
}

export default function servico (state = initialState, action) {
  switch (action.type) {

    case LOADING_SERVICO_SUCCESS : {
      const { servico } = action
      if (servico.constructor === Array) {
        state = initialState
        servico.forEach((b) => (
          state = state.merge({[b.id]: b})
        ))
        return state
      }
      return state.merge({[servico.id]: servico})
    }

    case DESTROYING_SERVICO_SUCCESS :
      return state.delete(action.servicoId.toString())

    default :
      return state
  }
}
