import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { IcmsShow } from '../../components'
import * as icmsActionCreators from '../../redux/modules/icms'

class IcmsShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    fetchIcms: PropTypes.func,
    history: PropTypes.object,
    destroyIcms: PropTypes.func,
  }

  state = {
    icms: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const icms = await this.props.fetchIcms(this.props.id)
      this.setState({ icms })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/icms/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/icms/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyIcms(id)
    this.props.history.push('/icms')
  }

  render () {
    return (
      <IcmsShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        icms={this.state.icms} />
    )
  }
}

function mapStateToProps ({icms}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      icms: icms.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...icmsActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(IcmsShowContainer)
