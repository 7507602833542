import React from 'react'

import logo from '../../img/logo.svg'
import { Image } from 'semantic-ui-react'

const PrintableHeader = () => <Image src={logo} floated='right' style={{
  width: '360px',
}} />

export default PrintableHeader
