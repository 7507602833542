import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Container, Icon, Table } from 'semantic-ui-react'

import { capitalize, formatAsCurrency } from './utils/ChartUtils'

const TabelaUFER = ({ data }) => {
  if (!data) return null

  const getIconName = value => {
    if (value > 0) return <Icon name='arrow up' color='red' />
    if (value < 0) return <Icon name='arrow down' color='green' />
    return null
  }

  let sumUfer_p = 0
  let sumUfer_fp = 0
  let sumDmcr_p = 0
  let sumDmcr_fp = 0

  const preparedData = data.map((d, i) => {
    const prepared = Object.assign({}, d)
    prepared._labelFaturaMes = capitalize(
      moment({ y: d.ano, M: d.mes - 1 }).format('MMM/YY')
    )

    // prevenir erro nos casos onde não foi gerado com dmcr_na
    if (!d.dmcr_na) d.dmcr_na = 0

    prepared._ufer_p = formatAsCurrency(d.ufer_p)
    prepared._ufer_fp = formatAsCurrency(d.ufer_fp)
    prepared._dmcr_p = formatAsCurrency(d.dmcr_p)
    prepared._dmcr_fp = formatAsCurrency(d.dmcr_fp)
    prepared._dmcr_na = formatAsCurrency(d.dmcr_na)
    prepared._sum = formatAsCurrency(
      d.ufer_p + d.ufer_fp + d.dmcr_p + d.dmcr_fp + d.dmcr_na
    )

    sumUfer_p += d.ufer_p
    sumUfer_fp += d.ufer_fp
    sumDmcr_p += d.dmcr_p
    sumDmcr_fp += d.dmcr_na && d.dmcr_na > 0 ? d.dmcr_na : d.dmcr_fp

    if (i < data.length - 1) {
      prepared._ufer_p_icon = getIconName(data[i].ufer_p - data[i + 1].ufer_p)
      prepared._ufer_fp_icon = getIconName(
        data[i].ufer_fp - data[i + 1].ufer_fp
      )
      prepared._dmcr_p_icon = getIconName(data[i].dmcr_p - data[i + 1].dmcr_p)
      prepared._dmcr_fp_icon = getIconName(
        data[i].dmcr_fp - data[i + 1].dmcr_fp
      )
      prepared._dmcr_na_icon = getIconName(
        data[i].dmcr_na - data[i + 1].dmcr_na
      )
    }

    return prepared
  })

  const rows = preparedData.map((d, i) => {
    const columns = [
      <Table.Cell key={`${i}_labelMes`}>{d._labelFaturaMes}</Table.Cell>,
      <Table.Cell key={`${i}_ufer`}>
        {d._ufer_p} {d._ufer_p_icon}
      </Table.Cell>,
      <Table.Cell key={`${i}_ufer`}>
        {d._ufer_fp} {d._ufer_fp_icon}
      </Table.Cell>,
      <Table.Cell key={`${i}_dmcr_p`}>
        {d._dmcr_p} {d._dmcr_p_icon}
      </Table.Cell>,
      d._dmcr_fp === 'R$ 0,00' ? (
        <Table.Cell key={`${i}_dmcr_na`}>
          {d._dmcr_na} {d._dmcr_na_icon}
        </Table.Cell>
      ) : (
        <Table.Cell key={`${i}_dmcr_fp`}>
          {d._dmcr_fp} {d._dmcr_fp_icon}
        </Table.Cell>
      ),
      <Table.Cell key={`${i}_sum`}>
        <strong>{d._sum}</strong>
      </Table.Cell>,
    ]

    return <Table.Row key={i}>{columns}</Table.Row>
  })
  return (
    <Container fluid>
      <Table celled className='tabela-ufer'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan={2}>Período</Table.HeaderCell>
            <Table.HeaderCell colSpan={2}>
              Energia Reativa Excedente
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={2}>
              Demanda Reativa Excedente
            </Table.HeaderCell>
            <Table.HeaderCell rowSpan={2}>Total/Mês</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Ponta</Table.HeaderCell>
            <Table.HeaderCell>Fora Ponta</Table.HeaderCell>
            <Table.HeaderCell>Ponta</Table.HeaderCell>
            <Table.HeaderCell>Fora Ponta</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{rows}</Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              <strong>Total/12 Meses</strong>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <strong>{formatAsCurrency(sumUfer_p)}</strong>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <strong>{formatAsCurrency(sumUfer_fp)}</strong>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <strong>{formatAsCurrency(sumDmcr_p)}</strong>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <strong>{formatAsCurrency(sumDmcr_fp)}</strong>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <strong>
                {formatAsCurrency(
                  sumUfer_p + sumUfer_fp + sumDmcr_p + sumDmcr_fp
                )}
              </strong>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Container>
  )
}

TabelaUFER.propTypes = {
  data: PropTypes.array,
}

export default TabelaUFER
