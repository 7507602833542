import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Instalacao } from '../../components'
import * as instalacaoActionCreators from '../../redux/modules/instalacao'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class InstalacaoContainer extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,

    multipleInstalacao: PropTypes.object.isRequired,
    fetchMultipleInstalacao: PropTypes.func.isRequired,
    destroyInstalacao: PropTypes.func.isRequired,

    hideFlashMessage: PropTypes.func.isRequired,

    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string,
    filterCheckbox: PropTypes.bool,
    apenasAtivos: PropTypes.bool,
    tipoMercado: PropTypes.string,
  }

  async componentDidMount() {
    this.refreshInstalacoes()
  }

  async componentDidUpdate(prevProps) {
    const shouldRefresh =
      prevProps.filterCheckbox !== this.props.filterCheckbox ||
      prevProps.apenasAtivos !== this.props.apenasAtivos ||
      prevProps.tipoMercado !== this.props.tipoMercado

    if (!shouldRefresh) return
    this.refreshInstalacoes()
  }

  refreshInstalacoes = async () => {
    const { filterCheckbox, apenasAtivos, tipoMercado } = this.props
    const userId = filterCheckbox ? JSON.parse(localStorage.getItem('jwt')).payload.sub : ''
    await this.props.fetchMultipleInstalacao(userId, apenasAtivos, tipoMercado)
  }

  handleRedirectToNew = () => {
    this.props.history.push('/instalacoes/new')
  }

  handleShowResource = (id) => {
    this.props.history.push(`/instalacoes/${id}`)
  }

  handleEditResource = (id) => {
    this.props.history.push(`/instalacoes/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyInstalacao(id)
  }

  handleSort = column => () => this.props.updateSort('instalacao', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('instalacao', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleInstalacao.toList().toJSON(),
      'instalacao',
      ['id', 'apelido', 'nome', 'cnpj'],
    )
  }

  componentWillUnmount() {
    this.props.hideFlashMessage()
  }

  render() {
    return (
      <Instalacao
        title='Instalações'
        listPath='instalacoes'
        isLoading={this.props.isLoading}
        multipleInstalacao={this.props.multipleInstalacao}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        filterCheckbox={this.props.filterCheckbox}
        apenasAtivos={this.props.apenasAtivos}
        tipoMercado={this.props.tipoMercado}
        onExportToCSV={this.handleExportToCSV} />
    )
  }
}

function mapStateToProps({ instalacao, status, sort, filter }) {
  const sortColumn = sort.getIn(['instalacao', 'sortColumn'])
  const sortDirection = sort.getIn(['instalacao', 'sortDirection'])
  const filterText = filter.getIn(['instalacao', 'filterText']) || ''

  const filterCheckbox = filter.getIn(['instalacao', 'checked'], true)
  const apenasAtivos = filter.getIn(['instalacao', 'apenasAtivos'], true)
  const tipoMercado = filter.getIn(['instalacao', 'tipoMercado'], 'ambos')
  const map = sortMap(filterMap(instalacao, ['apelido', 'nome', 'cnpj', 'uf.sigla', 'uf.nome', 'modalidade', 'subgrupo', 'tipo_mercado'], filterText), sortColumn, sortDirection)

  return {
    multipleInstalacao: map,
    sortColumn,
    sortDirection,
    filterText,
    filterCheckbox,
    apenasAtivos,
    tipoMercado,
    isLoading: status.getIn(['instalacao', 'isLoading']),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...instalacaoActionCreators,
    ...flashMessageAcionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InstalacaoContainer)
