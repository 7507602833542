const RASCUNHO = 'rascunho'
const INVALIDA = 'invalida'
const VALIDA = 'valida'
const CONSOLIDADA = 'consolidada'
const HISTORICA = 'historica'

export const getColor = (state) => {
  switch(state) {
    case RASCUNHO: return 'grey'
    case INVALIDA: return 'red'
    case VALIDA: return 'green'
    case CONSOLIDADA: return 'blue'
    case HISTORICA: return 'black'
    default: return 'grey'
  }
}

export const getIcon = (state) => {
  switch(state) {
    case RASCUNHO: return 'pencil'
    case INVALIDA: return 'warning sign'
    case VALIDA: return 'check'
    case CONSOLIDADA: return 'chart line'
    case HISTORICA: return 'history'
    default: return ''
  }
}

export const getText = (state) => {
  switch(state) {
    case RASCUNHO: return 'Rascunho'
    case INVALIDA: return 'Inválida'
    case VALIDA: return 'Válida'
    case CONSOLIDADA: return 'Consolidada'
    case HISTORICA: return 'Histórica'
    default: return 'ERRO'
  }
}
