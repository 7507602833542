import { Map } from 'immutable'

export const multipleCalculoBandeira = Map({
  0: Map({
    id: 0,
    nome: 'Proporcional ao consumo'
  }),
  1: Map({
    id: 1,
    nome: 'Proporcional ao número de dias'
  }),
})
