import config from './config'

const api = config.apiGateway.URL

export const login = (email, password) =>
  fetch(`${api}/user_token`, authHeaders(email, password))
    .then(handleErrors)
    .then(res => res.json())
    .then(json => decodeAndStoreJwt(json.jwt))
    .then(payload => getUser(payload.sub))

export const getUser = (userId) =>
  fetch(`${api}/users/${userId}`, defaultHeaders())
    .then(handleErrors)
    .then(res => res.json())

export const getBandeiras = () =>
  fetch(`${api}/bandeiras`, defaultHeaders())
    .then(handleErrors)
    .then(res => res.json())

function authHeaders(email, password) {
  return {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ auth: { email, password } })
  }
}

function defaultHeaders() {
  return {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ' ${getRawToken()}`
    }
  }
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText)
  }
  return response
}

function getRawToken() {
  if (!localStorage.jwt)
    return null

  let token = JSON.parse(localStorage.jwt)
  return token.raw
}

function decodeAndStoreJwt(rawToken) {
  let token = {
    raw: rawToken,
    header: JSON.parse(window.atob(rawToken.split('.')[0])),
    payload: JSON.parse(window.atob(rawToken.split('.')[1]))
  }

  localStorage.setItem('jwt', JSON.stringify(token))
  return token.payload
}

async function parseResponse (response) {
  if (response.status === 204) {
    return true
  } else {
    const json = await response.json()
    if (response.ok)
      return json
    else
      return Promise.reject(json)
  }
}

export async function callAPI (endpoint, method = 'GET', body = null) {
  let headers = {...{method}, ...defaultHeaders()}
  if (body) { headers.body = JSON.stringify(body) }
  const response = await fetch(`${api}${endpoint}`, headers)
  return await parseResponse(response)
}
