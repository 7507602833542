import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput, SemanticDropdown } from '../FormComponents'

import { required } from '../../utils/validations'

const FonteComercializadoraForm = (props) => (
  <CRUDForm
    title='Fontes de Energia'
    titleAs='h3'
    icon='lightning'
    padded={false}
    {...props}>
    <FormSection name='fonte_comercializadora'>
      <Form.Group>
        <FormField width={6}>
          <label>Fonte de Energia</label>
          <Field fluid button floating labeled icon='lightning' className='icon'
            autoFocus
            name='fonte_energia_id'
            validate={[required]}
            options={props.fonteEnergiaDropdown}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
        <FormField width={4}>
          <label>Código CCEE</label>
          <Field
            name='codigo_ccee'
            placeholder='Código CCEE'
            validate={[required]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>
    </FormSection>
  </CRUDForm>
)

FonteComercializadoraForm.propTypes = {
  fonteEnergiaDropdown: PropTypes.array.isRequired,
  loading: PropTypes.bool
}

export default reduxForm({form: 'FonteComercializadoraForm'})(FonteComercializadoraForm)
