import React, { Component } from 'react'

import { withRouter } from 'react-router-dom'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as resultadoActionCreators from '../../redux/modules/resultado'
import * as faturaPedagioActionCreators from '../../redux/modules/faturaPedagio'

import ResultadoPrint from '../../components/Resultado/ResultadoPrint'

class ResultadoPrintContainer extends Component {
  async componentDidMount () {
    const { match: { params: { fatura_id: faturaPedagioId } = {}} = {}} = this.props
    await Promise.all([
      this.props.fetchFaturaPedagio(faturaPedagioId),
      this.props.fetchResultado(faturaPedagioId),
    ])
  }

  render () {
    const { faturaPedagio, resultado } = this.props
    return (
      <ResultadoPrint faturaPedagio={faturaPedagio} resultado={resultado} />
    )
  }
}

const mapStateToProps = ({ instalacao, resultado, faturaPedagio, status }, ownProps) => {

  const isLoading = status.getIn(['resultado', 'isLoading']) || status.getIn(['faturaPedagio', 'isLoading'])

  const { match: { params: { fatura_id: faturaPedagioId } = {}} = {}} = ownProps
  if (!faturaPedagioId || !resultado) return { isLoading }

  return {
    faturaPedagio: faturaPedagio.get(faturaPedagioId),
    resultado: resultado.get(faturaPedagioId),
    isLoading,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...resultadoActionCreators,
  ...faturaPedagioActionCreators,
}, dispatch)

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ResultadoPrintContainer)
