import { Map } from 'immutable'

export const multipleCalculoSubvencao = Map({
  0: Map({
    id: 0,
    nome: 'N/A'
  }),
  1: Map({
    id: 1,
    nome: 'Calcular'
  }),
})
