import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput } from '../FormComponents'

import { required, maxLengthNome, maxLengthDescricao } from '../../utils/validations'

const ServicoForm = (props) => (
  <CRUDForm
    title='Serviço Prestado'
    icon='wrench'
    {...props}>
    <FormSection name='servico'>
      <Form.Group>
        <FormField width={6}>
          <label>Nome</label>
          <Field
            autoFocus
            name='nome'
            placeholder='Nome'
            validate={[required, maxLengthNome]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField width={10}>
          <label>Descrição</label>
          <Field
            name='descricao'
            placeholder='Descrição'
            validate={[required, maxLengthDescricao]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>
    </FormSection>
  </CRUDForm>
)

ServicoForm.propTypes = {
  loading: PropTypes.bool
}

export default reduxForm({form: 'ServicoForm'})(ServicoForm)
