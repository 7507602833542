import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { Label, Message, Icon, Popup } from 'semantic-ui-react'
import FaturaStateLabel from '../FaturaPedagio/FaturaStateLabel'

const CustomPopup = (props) =>
  <Popup inverted size='mini' position='bottom center'
    trigger={props.url
      ? <Label as={Link} to={props.url} target='_blank'>{props.label}</Label>
      : <Label>{props.label}</Label>}
    header={props.header}
  />

CustomPopup.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  header: PropTypes.string,
}

const ResumoFatura = (props) => {
  const { ano, mes, fatura } = props

  if (!fatura) return (
    <Message
      header='Atenção'
      icon='warning circle'
      content={`Não foi localizada fatura válida da instalação para ${mes}/${ano}.`}
    />
  )

  return (
    <Message info icon>
      <Icon name='calculator' />
      <Message.Content>
        <Message.Header>Resumo da Fatura</Message.Header>
        <div style={{marginTop: '1em'}}>
          <CustomPopup
            url={`/instalacoes/${fatura.get('instalacao_id')}/pedagio/${fatura.get('id')}`}
            label={`#${fatura.get('id')}`}
            header='Fatura'
          />

          <CustomPopup
            label={`De ${fatura.get('data_inicial')} a ${fatura.get('data_final')}`}
            header='Período de Medição'
          />

          <FaturaStateLabel state={fatura.get('aasm_state')} />
        </div>
      </Message.Content>
    </Message>
  )
}

ResumoFatura.propTypes = {
  ano: PropTypes.number,
  mes: PropTypes.number,
  fatura: PropTypes.object,
}

export default ResumoFatura
