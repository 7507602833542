import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import { withRouter } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import HistoricoConsumoChart from './Graficos/HistoricoConsumoChart'
import ConsumoTotalChart from './Graficos/ConsumoTotalChart'
import MegawattMedioChart from './Graficos/MegawattMedioChart'

import AnaliseContainer from '../../containers/Relatorio/AnaliseContainer'

const Consumo = ({ total, historico, match }) => {

  const print = match.url.endsWith('/print')
  const showAnimations = !print

  const historico_p = get(historico, 'ponta')
  const historico_fp = get(historico, 'fora_ponta')
  const historico_int = get(historico, 'intermediario')
  const historico_na = get(historico, 'na')

  const consumo_total = get(total, 'consumo')
  const megawatt_medio = get(total, 'megawatt_medio')

  return (
    <React.Fragment>
      {historico_p && (
        <Grid.Row className='shrink'>
          <Grid.Column width={16}>
            <AnaliseContainer resetable
              field='texto_consumo_p'
              help='Histórico - Consumo Ponta (36 meses)'
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <HistoricoConsumoChart
              data={historico_p}
              showAnimations={showAnimations}
            />
          </Grid.Column>
        </Grid.Row>
      )}

      {historico_fp && (
        <Grid.Row className='shrink'>
          <Grid.Column width={16}>
            <AnaliseContainer resetable
              field='texto_consumo_fp'
              help='Histórico - Consumo Fora-Ponta (36 meses)'
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <HistoricoConsumoChart
              data={historico_fp}
              showAnimations={showAnimations}
            />
          </Grid.Column>
        </Grid.Row>
      )}

      {historico_int && (
        <Grid.Row className='shrink'>
          <Grid.Column width={16}>
            <AnaliseContainer resetable
              field='texto_consumo_int'
              help='Histórico - Consumo Intermediário (36 meses)'
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <HistoricoConsumoChart
              data={historico_int}
              showAnimations={showAnimations}
            />
          </Grid.Column>
        </Grid.Row>
      )}

      {historico_na && (
        <Grid.Row className='shrink'>
          <Grid.Column width={16}>
            <AnaliseContainer resetable
              field='texto_consumo_na'
              help='Histórico - Consumo (36 meses)'
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <HistoricoConsumoChart
              data={historico_na}
              showAnimations={showAnimations}
            />
          </Grid.Column>
        </Grid.Row>
      )}

      <Grid.Row className='shrink'>
        <Grid.Column width={8}>
          <ConsumoTotalChart
            header='Consumo Total (36 meses)'
            data={consumo_total}
            showAnimations={showAnimations}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <MegawattMedioChart
            header='Megawatt-médio (36 meses)'
            data={megawatt_medio}
            showAnimations={showAnimations}
          />
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  )
}

Consumo.propTypes = {
  analise: PropTypes.string,
  total: PropTypes.object,
  match: PropTypes.object,
  historico: PropTypes.object,
}

export default withRouter(Consumo)
