import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const regex = {
  hex: /[0-9a-fA-F]/,
}

// CNPJ follows format 00.000.000/0000-00
export const maskCNPJ = {
  mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  placeholder: '__.___.___/____-__',
}

// CPF follows format 00.000.000/0000-00
export const maskCPF = {
  mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  placeholder: '___.___.___-__',
}

// Color follows format #000000
export const maskColor = {
  mask: ['#', regex.hex, regex.hex, regex.hex, regex.hex, regex.hex, regex.hex],
  placeholder: '#RRGGBB',
}

// Currency for Real follows the format R$ 0.000,00
export const maskCurrency = {
  mask: (intLimit = null, prefix = '') => (rawValue) => {
    const numberMask = createNumberMask({
      prefix: prefix,
      integerLimit: intLimit,
      thousandsSeparatorSymbol: '.',
      allowDecimal: true,
      decimalSymbol: ',',
    })
    return (rawValue && typeof rawValue === 'string') ? numberMask(rawValue) : []
  },
  unmask: (value) => {
    // remove non-number characters, remove thousand separator, change decimal symbol
    return parseFloat(value.replace(/[^0-9.,-]*/, '').replace('.', '').replace(',', '.'))
  },
  parse: (floatValue, decimals = 2) => {
    // change decimal symbol, for every 3 numbers, add a thousand separator
    const floatAsString = parseFloat(floatValue).toFixed(decimals).toString()
    return floatAsString.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  },
  placeholder: '0,00',
}

export const maskFloat = {
  mask: (decimalLimit = 2, allowNegative = false) => (rawValue) => {
    const numberMask = createNumberMask({
      decimalLimit,
      allowDecimal: true,
      requireDecimal: true,
      decimalSymbol: ',',
      thousandsSeparatorSymbol: '.',
      prefix: '',
      allowNegative,
    })
    return (rawValue && typeof rawValue === 'string') ? numberMask(rawValue) : []
  },
  unmask: (value, decimalLimit = 2) => {
    return parseFloat(parseFloat(value.replace(/[^0-9.,-]*/g, '').replace(/\./g, '').replace(',', '.')).toFixed(decimalLimit))
  },
  parse: (floatValue, decimalLimit = 2) => {
    let [ integerPart, decimalPart ] = String(floatValue).split('.')
    integerPart = integerPart ? integerPart : '0'
    decimalPart = decimalPart ? decimalPart : '0'
    return `${integerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}${decimalLimit > 0 ? ',' + pad(decimalPart, decimalLimit): ''}`
  },
  placeholder: '0,00',
}

export const maskPercent = {
  mask: (decimalLimit = 4) => (rawValue) => {
    const numberMask = createNumberMask({
      decimalLimit,
      allowDecimal: true,
      requireDecimal: true,
      decimalSymbol: ',',
      thousandsSeparatorSymbol: '.',
      prefix: '',
    })
    return (rawValue && typeof rawValue === 'string') ? numberMask(rawValue) : []
  },
  unmask: (value, decimalLimit = 4) => {
    let floatValue = parseFloat(value.replace(/[^0-9.,-]*/, '').replace('.', '').replace(',', '.'))
    floatValue /= 100
    floatValue = floatValue.toFixed(decimalLimit + 2)
    return parseFloat(floatValue)
  },
  parse: (floatValue, decimalLimit = 4) => {
    floatValue *= 100
    floatValue = parseFloat(floatValue.toFixed(decimalLimit))
    let [ integerPart, decimalPart ] = String(floatValue).split('.')
    integerPart = integerPart ? integerPart : '0'
    decimalPart = decimalPart ? decimalPart : '0'
    return `${integerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')},${pad(decimalPart, decimalLimit)}`
  },
  placeholder: '0,00',
}

export const maskYear = {
  mask: [/\d/, /\d/, /\d/, /\d/],
  placeholder: '' + (new Date()).getFullYear(),
}

export const maskNumber = {
  mask: (size = 4) => {
    const arr = []
    let i = 0
    while (i < size) {
      arr[i] = /\d/
      i++
    }
    return arr
  },
}

export const maskPhone = {
  mask: (numberInput) => {
    let numbers = numberInput.match(/\d/g)
    let numberslength = 0

    if (numbers)
      numberslength = numbers.join('').length

    if (numberslength > 10) {
      return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    } else {
      return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }
  },
  placeholder: '(00) 00000-0000',
}

const pad = (number, size = 2) => {
  let s = String(number)
  while (s.length < (size || 2)) { s = `${s}0`}
  return s
}
