import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ContatoShow } from '../../components'
import * as contatoInstalacaoActionCreators from '../../redux/modules/contatoInstalacao'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

class ContatoInstalacaoShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    instalacao: PropTypes.object.isRequired,
    fetchContatoInstalacao: PropTypes.func.isRequired,
    destroyContatoInstalacao: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
  }

  state = {
    contatoInstalacao: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const contatoInstalacao = await this.props.fetchContatoInstalacao(this.props.id)
      this.setState({ contatoInstalacao })
    }
  }

  handleEditResource = (instalacaoId, id) => {
    this.props.history.push(`/instalacoes/${instalacaoId}/contatos/${id}/edit`)
  }

  handleDeleteResource = async (instalacaoId, id) => {
    if (await this.props.destroyContatoInstalacao(id)) {
      this.props.showSuccessfulFlashMessage('', 'destroy')
      this.props.history.push(`/instalacoes/${instalacaoId}/contatos`)
    } else {
      this.props.showErrorFlashMessage('', 'destroy', this.props.error)
    }
  }

  render () {
    return (
      <ContatoShow noTitle
        instalacao={this.props.instalacao}
        onEditResource={() => this.handleEditResource(this.props.instalacao.id, this.state.contatoInstalacao.id)}
        onDeleteResource={() => this.handleDeleteResource(this.props.instalacao.id, this.state.contatoInstalacao.id)}
        contato={this.state.contatoInstalacao} />
    )
  }
}

function mapStateToProps ({contatoInstalacao}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      contatoInstalacao: contatoInstalacao.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...contatoInstalacaoActionCreators,
    ...flashMessageActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContatoInstalacaoShowContainer))
