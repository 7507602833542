import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'

const ContatoShow = (props) => {
  if (props.contato) {
    return (
      <CRUDShow
        noTitle={props.noTitle ? props.noTitle : undefined}
        title={props.contato.nome}
        icon='users'
        padded={false}
        onEditResource={props.onEditResource}
        onDeleteResource={props.onDeleteResource}>
        <ul>
          <li>Nome: {props.contato.nome}</li>
          <li>Função: {props.contato.funcao.nome}</li>
          <li>Email: {props.contato.email}</li>
          <li>Telefone: {props.contato.telefone}</li>
          <li>Celular: {props.contato.celular}</li>
          <li>Observações: {props.contato.observacoes}</li>
        </ul>
      </CRUDShow>
    )
  }

  return (null)
}

ContatoShow.propTypes = {
  contato: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  noTitle: PropTypes.bool,
}

export default ContatoShow
