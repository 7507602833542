import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_TRIBUTO = 'LOADING_TRIBUTO'
export function loadingTributo () {
  return {
    type: LOADING_TRIBUTO,
  }
}

export const LOADING_TRIBUTO_SUCCESS = 'LOADING_TRIBUTO_SUCCESS'
export function loadingTributoSuccess (tributo) {
  return {
    type: LOADING_TRIBUTO_SUCCESS,
    tributo,
  }
}

export const LOADING_TRIBUTO_FAILURE = 'LOADING_TRIBUTO_FAILURE'
export function loadingTributoFailure (error) {
  return {
    type: LOADING_TRIBUTO_FAILURE,
    error,
  }
}

export const DESTROYING_TRIBUTO_SUCCESS = 'DESTROYING_TRIBUTO_SUCCESS'
export function destroyingTributoSuccess (tributoId) {
  return {
    type: DESTROYING_TRIBUTO_SUCCESS,
    tributoId,
  }
}

const initialState = Map({})

export function fetchMultipleTributo () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingTributo())
      const multipleTributo = await callAPI('/cadastros/tributos')
      dispatch(loadingTributoSuccess(multipleTributo))
      return multipleTributo
    } catch (e) {
      dispatch(loadingTributoFailure(e))
      return false
    }
  }
}

export function fetchTributo (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingTributo())
      const tributo = await callAPI(`/cadastros/tributos/${id}`)
      dispatch(loadingTributoSuccess(tributo))
      return tributo
    } catch (e) {
      dispatch(loadingTributoFailure(e))
      return false
    }
  }
}

export function createTributo (tributo) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingTributo())
      const newTributo = await callAPI('/cadastros/tributos', 'POST', tributo)
      dispatch(loadingTributoSuccess(newTributo))
      return newTributo
    } catch (e) {
      dispatch(loadingTributoFailure(e))
      return false
    }
  }
}

export function updateTributo (id, tributo) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingTributo())
      const newTributo = await callAPI(`/cadastros/tributos/${id}`, 'PUT', tributo)
      dispatch(loadingTributoSuccess(newTributo))
      return newTributo
    } catch (e) {
      dispatch(loadingTributoFailure(e))
      return false
    }
  }
}

export function destroyTributo (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingTributo())
      await callAPI(`/cadastros/tributos/${id}`, 'DELETE')
      dispatch(destroyingTributoSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingTributoFailure(e))
      return false
    }
  }
}

export default function tributo (state = initialState, action) {
  switch (action.type) {

    case LOADING_TRIBUTO_SUCCESS : {
      const { tributo } = action
      if (tributo.constructor === Array) {
        state = initialState
        tributo.forEach(t => (
          state = state.merge({[t.id]: t})
        ))
        return state
      }
      return state.merge({[tributo.id]: tributo})
    }

    case DESTROYING_TRIBUTO_SUCCESS :
      return state.delete(action.tributoId.toString())

    default :
      return state
  }
}
