import React from 'react'

import { Popup, Table } from 'semantic-ui-react'

import get from 'lodash.get'

import { maskFloat } from '../../utils/masks'

import comparar from './utils/comparar'

const isPrimitive = test => test !== Object(test)

const isObject = obj => {
  return obj === Object(obj) && Object.prototype.toString.call(obj) !== '[obj Array]'
}

const depthOf = obj => {
  let level = 1
  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) continue
    if (isObject(obj[key])) {
      let depth = depthOf(obj[key]) + 1
      level = Math.max(depth, level)
    }
  }
  return level
}

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const prettify = str => capitalize(str.replace(/_/gi, '-'))

const label = key => ({
  tusd: 'TUSD',
  te: 'TE',
  int: 'Intermediário',
  na: 'NA',
  dmcr: 'DMCR',
  ufer: 'UFER',
  isenta: 'Isenta de ICMS',
})[key] || prettify(key)

const parse = obj => {
  const entries = Object.entries(obj)

  if (depthOf(obj) === 2) {
    return entries.map(([, value]) => {
      return isPrimitive(value)
        ? value
        : (isObject(value) && parseFloat(value.valor))
          ? value
          : null
    })
  }

  return entries
    .map(([key, value]) => [key, parse(value)])
    .reduce((o, [key, value]) => {
      const isEmptyArray = Array.isArray(value) && value.every(v => !v)
      const isEmptyObject = isObject(value) && Object.keys(value).length === 0
      if (isEmptyArray || isEmptyObject) return o
      o[key] = value
      return o
    }, {})
}

const getComponent = (children, key) => Array.isArray(children) ? children.filter(comp => comp.key === key) : children

const Detalhe = ({ children, component: Component }) => {
  const popup = children && getComponent(children, 'popup')
  const content = children && getComponent(children, 'content')

  if (popup) return (
    <Popup wide='very' position='top right' flowing hoverable style={{ fontFamily: 'monospace' }}
      trigger={<Component textAlign='right' style={{ fontFamily: 'monospace' }}>{content}</Component>}>
      {popup}
    </Popup>
  )

  return content
}

const getValues = Object.values
const parseValues = x => x.map(r => r.map(s => s ? parseFloat(s.valor) : 0.0))
const reduceValues = x => x.reduce((r, a) => r.map((b, i) => a[i] + b))

const render = (obj, simulado = true) => {
  const entries = Object.entries(obj)

  if (depthOf(obj) === 3) {
    return entries.map(([key, value]) => (
      <Table.Row key={key}>
        <Table.Cell textAlign='right'>{label(key)}</Table.Cell>
        {render(value, simulado)}
      </Table.Row>
    ))
  }

  if (depthOf(obj) === 4) {
    return entries.map(([key, value]) => {
      const subtotal = reduceValues(parseValues(getValues(value)))
      return (
        <React.Fragment key={key}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign='right'>{label(key)}</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'></Table.HeaderCell>
              <Table.HeaderCell textAlign='right'></Table.HeaderCell>
              {simulado && (
                <>
                  <Table.HeaderCell textAlign='right'></Table.HeaderCell>
                  <Table.HeaderCell textAlign='right'></Table.HeaderCell>
                </>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {render(value, simulado)}
            <Table.Row key={key}>
              <Table.Cell textAlign='right'><em>Subtotal</em></Table.Cell>
              {subtotal.map((s, i) => (
                <Detalhe key={i} component={Table.Cell}>
                  <React.Fragment key='content'>
                    {maskFloat.parse(s.toFixed(2))}
                  </React.Fragment>
                  <React.Fragment key='popup'>
                    R$ {maskFloat.parse(s.toFixed(2))}
                  </React.Fragment>
                </Detalhe>
              ))}
            </Table.Row>
          </Table.Body>
        </React.Fragment>
      )
    })
  }

  if (depthOf(obj) === 5) {
    return entries.map(([key, value]) => {
      const total = reduceValues(getValues(value).map(v => reduceValues(parseValues(getValues(v)))))

      const colSpan = simulado ? 3 : 6

      return (
        <Table key={key} celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={4} style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)' }} textAlign='right'>{label(key)}</Table.HeaderCell>
              <Table.HeaderCell width={colSpan} style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)' }} textAlign='right'>Sem Impostos</Table.HeaderCell>
              <Table.HeaderCell width={colSpan} style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)' }} textAlign='right'>Com Impostos</Table.HeaderCell>
              {simulado && (<>
                <Table.HeaderCell width={colSpan} style={{ backgroundColor: '#bdf2bd' }} textAlign='right'>Sem Impostos</Table.HeaderCell>
                <Table.HeaderCell width={colSpan} style={{ backgroundColor: '#bdf2bd' }} textAlign='right'>Com Impostos</Table.HeaderCell>
              </>)}
            </Table.Row>
          </Table.Header>
          {render(value, simulado)}
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign='right'>Total</Table.HeaderCell>
              {total.map((s, i) => (
                <Detalhe key={i} component={Table.HeaderCell}>
                  <React.Fragment key='content'>
                    {maskFloat.parse(s.toFixed(2))}
                  </React.Fragment>
                  <React.Fragment key='popup'>
                    R$ {maskFloat.parse(s.toFixed(2))}
                  </React.Fragment>
                </Detalhe>
              ))}
            </Table.Row>
          </Table.Header>
        </Table>
      )
    })
  }

  return entries.map(([key, value]) => {
    const valor = get(value, 'valor', 0.0)
    if (isNaN(parseFloat(valor)) || valor === 'NA') {
      return (
        <Detalhe key={key} component={Table.Cell}>
          <React.Fragment key='content'>
            <em>N/A</em>
          </React.Fragment>
        </Detalhe>
      )
    }
    const medicao = get(value, 'medicao', 0.0)
    const tarifa = get(value, 'tarifa', 0.0)
    const unidade = get(value, 'unidade', '')

    return (
      <Detalhe key={key} component={Table.Cell}>
        <React.Fragment key='content'>
          {maskFloat.parse(valor)}
        </React.Fragment>
        <React.Fragment key='popup'>
          {!!medicao && <>{maskFloat.parse(medicao)}{' '}{unidade}{' '}×{' '}</>}
          {!!tarifa && <>{maskFloat.parse(tarifa)}{' '}R$/{unidade}{' '}={' '}</>}
          R$ {maskFloat.parse(valor)}
        </React.Fragment>
      </Detalhe>
    )
  })
}

const Comparacao = ({ realizado, simulado }) => {
  const comparacao = comparar(realizado, simulado)
  return render(parse(comparacao), simulado)
}

export default Comparacao
