import { Map } from 'immutable'

export const multipleBaseCalculo = Map({
  0: Map({
    id: 0,
    nome: 'Somente itens com tarifa'
  }),
  1: Map({
    id: 1,
    nome: 'Itens com tarifa e encargos de conexão'
  }),
})
