import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import { FaturaPedagio } from '../../components'
import * as faturaPedagioActionCreators from '../../redux/modules/faturaPedagio'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'

class FaturaPedagioContainer extends Component {

  static propTypes = {
    instalacao: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,

    fetchMultipleFaturaPedagio: PropTypes.func.isRequired,
    multipleFaturaPedagio: PropTypes.object.isRequired,
    destroyFaturaPedagio: PropTypes.func.isRequired,

    hideFlashMessage: PropTypes.func.isRequired,
    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string,
    filterYear: PropTypes.number,
  }

  async refreshData() {
    await this.props.fetchMultipleFaturaPedagio(this.props.instalacao.id, this.props.filterYear)
  }

  async componentDidMount () {
    this.refreshData()
  }

  async componentDidUpdate(prevProps) {
    const shouldUpdate = prevProps.filterYear !== this.props.filterYear

    if (shouldUpdate) {
      this.refreshData()
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push(`/instalacoes/${this.props.instalacao.id}/pedagio/new`)
  }

  handleShowResource = (id) => {
    this.props.history.push(`/faturas/pedagio/${id}`)
  }

  handleEditResource = (id) => {
    this.props.history.push(`/faturas/pedagio/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyFaturaPedagio(id)
  }

  handleSort = column => () => this.props.updateSort('faturaPedagio', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('faturaPedagio', name, value)

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <FaturaPedagio
        title='Faturas de Pedágio'
        listPath='faturas_pedagio'
        instalacao={this.props.instalacao}
        multipleFaturaPedagio={this.props.multipleFaturaPedagio}
        onRedirectToNew={this.handleRedirectToNew}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        filterYear={this.props.filterYear}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({faturaPedagio, status, sort, filter}, {instalacao}) {
  const sortColumn = sort.getIn(['faturaPedagio', 'sortColumn'])
  const sortDirection = sort.getIn(['faturaPedagio', 'sortDirection'])
  const filterText = filter.getIn(['faturaPedagio', 'filterText']) || ''
  const filterYear = filter.getIn(['faturaPedagio', 'year']) || new Date().getFullYear()

  let map = filterMap(faturaPedagio, ['instalacao.nome', 'ano', 'mes', 'data_inicial', 'data_final'], filterText)
  map = sortMap(map, (a, b) => a.get('ano') - b.get('ano') || a.get('mes') - b.get('mes'), 'descending')

  return {
    isLoading: status.getIn(['faturaPedagio', 'isLoading']),
    multipleFaturaPedagio: map.filter(t => t.get('instalacao_id') === instalacao.id),
    sortColumn,
    sortDirection,
    filterText,
    filterYear,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...faturaPedagioActionCreators,
    ...flashMessageAcionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FaturaPedagioContainer))
