import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'

const BandeiraShow = (props) => {
  if (props.bandeira) {
    return (
      <CRUDShow
        title={props.bandeira.nome}
        icon='flag'
        padded={false}
        onEditResource={() => props.onEditResource(props.bandeira.id)}
        onDeleteResource={() => props.onDeleteResource(props.bandeira.id)}>
        <ul>
          <li>Nome: {props.bandeira.nome}</li>
          <li>Descrição: {props.bandeira.descricao}</li>
          <li>Cor: {props.bandeira.cor}</li>
          <li>Acréscimo: {props.bandeira.acrescimo}</li>
        </ul>
      </CRUDShow>
    )
  }

  return (null)
}

BandeiraShow.propTypes = {
  bandeira: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
}

export default BandeiraShow
