import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import moment from 'moment'
import { maskFloat, maskPercent } from '../../utils/masks'
import { multipleTipoMercado } from '../../utils/tipoMercado'

import { CenarioForm } from '../../components'
import * as cenarioActionCreators from '../../redux/modules/cenario'
import * as classeConsumoActionCreators from '../../redux/modules/classeConsumo'
import * as comercializadoraActionCreators from '../../redux/modules/comercializadora'
import * as concessionariaActionCreators from '../../redux/modules/concessionaria'
import * as enquadramentoActionCreators from '../../redux/modules/enquadramento'
import * as geradorActionCreators from '../../redux/modules/gerador'
import * as subgrupoActionCreators from '../../redux/modules/subgrupo'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

const percentFields = [
  'icms',
  'icms_reduzido',
  'icms_credito',
  'perdas',
]

const floatFields = [
  'contrato_demanda_p',
  'contrato_demanda_fp',
  'contrato_demanda_na',
  'contrato_demanda_antigo_p',
  'contrato_demanda_antigo_fp',
  'contrato_demanda_antigo_na',
]

const dateFields = [
  'data_contrato_concessionaria',
  'data_inicio_geracao_distribuida',
  'data_migracao_livre',
]

class CenarioFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    instalacao: PropTypes.object.isRequired,

    initialize: PropTypes.func.isRequired,

    fetchCenario: PropTypes.func.isRequired,
    createCenario: PropTypes.func.isRequired,
    updateCenario: PropTypes.func.isRequired,

    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.object,

    fetchMultipleClasseConsumo: PropTypes.func,
    fetchMultipleComercializadora: PropTypes.func,
    fetchMultipleConcessionaria: PropTypes.func,
    fetchMultipleEnquadramento: PropTypes.func,
    fetchMultipleGerador: PropTypes.func,
    fetchMultipleSubgrupo: PropTypes.func,

    multipleClasseConsumo: PropTypes.object,
    multipleComercializadora: PropTypes.object,
    multipleConcessionaria: PropTypes.object,
    multipleEnquadramento: PropTypes.object,
    multipleGerador: PropTypes.object,
    multipleSubgrupo: PropTypes.object,
    multipleTipoMercado: PropTypes.object,
  }

  async componentDidMount () {
    await Promise.all([
      this.props.fetchMultipleClasseConsumo(),
      this.props.fetchMultipleComercializadora(),
      this.props.fetchMultipleConcessionaria(),
      this.props.fetchMultipleEnquadramento(),
      this.props.fetchMultipleGerador(),
      this.props.fetchMultipleSubgrupo(),
    ])

    let cenario = {
      instalacao_id: this.props.instalacao.id,
      parametro_calculo: {
        data_contrato_concessionaria: moment().startOf('month').format('YYYY-MM-DD'),
        data_inicio_geracao_distribuida: moment().startOf('month').format('YYYY-MM-DD'),
        data_migracao_livre: moment().startOf('month').format('YYYY-MM-DD'),
        icms: 0.0,
        icms_reduzido: 0.0,
        icms_credito: 0.0,
        perdas: 0.0,
        tipo: 'simulacao',
        possui_contrato_demanda: false,
        contrato_demanda_p: 0.0,
        contrato_demanda_fp: 0.0,
        contrato_demanda_na: 0.0,
        possui_periodo_testes_p: false,
        possui_periodo_testes_fp: false,
        possui_periodo_testes_na: false,
        contrato_demanda_antigo_p: 0.0,
        contrato_demanda_antigo_fp: 0.0,
        contrato_demanda_antigo_na: 0.0,
      }
    }

    if (this.props.id) {
      cenario = await this.props.fetchCenario(this.props.id)
    }

    const { parametro_calculo } = cenario

    dateFields.forEach(param => {
      parametro_calculo[param] = moment(parametro_calculo[param], 'YYYY-MM-DD').format('DD/MM/YYYY')
    })

    percentFields.forEach(param => {
      parametro_calculo[param] = maskPercent.parse(parametro_calculo[param])
    })

    floatFields.forEach(param => {
      parametro_calculo[param] = maskFloat.parse(parametro_calculo[param])
    })

    const postos = ['contrato_p', 'contrato_fp', 'contrato_na']

    postos.forEach(posto => {
      if (Array.isArray(parametro_calculo[posto]))
        parametro_calculo[posto] = parametro_calculo[posto].map(value => maskFloat.parse(value))
      else
        parametro_calculo[posto] = Array.apply(0, Array(12)).map(() => '0,00')
    })

    this.props.initialize('CenarioForm', { cenario })
  }

  handleSubmitResource = async (cenario) => {

    const instalacao_id = this.props.instalacao.id

    percentFields.forEach(param => {
      let value = maskPercent.unmask(cenario.getIn(['cenario', 'parametro_calculo', param]))
      cenario = cenario.setIn(['cenario', 'parametro_calculo', param], value)
    })

    floatFields.forEach(param => {
      let value = maskFloat.unmask(cenario.getIn(['cenario', 'parametro_calculo', param]))
      cenario = cenario.setIn(['cenario', 'parametro_calculo', param], value)
    })

    let parametros = cenario.getIn(['cenario', 'parametro_calculo']);

    ['contrato_p', 'contrato_fp', 'contrato_na'].forEach(posto => {
      parametros = parametros.set(posto, parametros.get(posto).map(v => maskFloat.unmask(v)))
    })

    cenario = cenario
      .setIn(['cenario', 'parametro_calculo_attributes'], parametros)
      .deleteIn(['cenario', 'parametro_calculo'])

    if (this.props.id) {
      if (await this.props.updateCenario(this.props.id, cenario)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/instalacoes/${instalacao_id}/cenarios`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createCenario(instalacao_id, cenario)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push(`/instalacoes/${instalacao_id}/cenarios`)
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <CenarioForm
        instalacao={this.props.instalacao}
        onSubmit={(cenario) => this.handleSubmitResource(cenario)}
        isLoading={this.props.isLoading}
        multipleClasseConsumo={this.props.multipleClasseConsumo}
        multipleComercializadora={this.props.multipleComercializadora}
        multipleConcessionaria={this.props.multipleConcessionaria}
        multipleEnquadramento={this.props.multipleEnquadramento}
        multipleGerador={this.props.multipleGerador}
        multipleSubgrupo={this.props.multipleSubgrupo}
        multipleTipoMercado={this.props.multipleTipoMercado}
      />
    )
  }
}

function mapStateToProps ({cenario, subgrupo, enquadramento, classeConsumo, concessionaria, gerador, comercializadora, status, flashMessage}, {match}) {
  const { id } = match.params

  const isLoading = status.getIn(['cenario', 'isLoading'])
    || status.getIn(['classeConsumo', 'isLoading'])
    || status.getIn(['comercializadora', 'isLoading'])
    || status.getIn(['concessionaria', 'isLoading'])
    || status.getIn(['enquadramento', 'isLoading'])
    || status.getIn(['gerador', 'isLoading'])
    || status.getIn(['subgrupo', 'isLoading'])

  if (id) {
    return {
      id,
      cenario: cenario.get('id'),
      error: status.getIn(['cenario', 'error']),
      isLoading,
      shouldHideFlashMessage: flashMessage.get('isError'),
      multipleClasseConsumo: classeConsumo,
      multipleComercializadora: comercializadora,
      multipleConcessionaria: concessionaria,
      multipleEnquadramento: enquadramento,
      multipleGerador: gerador,
      multipleSubgrupo: subgrupo,
      multipleTipoMercado,
    }
  }
  return {
    error: status.getIn(['cenario', 'error']),
    isLoading,
    shouldHideFlashMessage: flashMessage.get('isError'),
    multipleClasseConsumo: classeConsumo,
    multipleComercializadora: comercializadora,
    multipleConcessionaria: concessionaria,
    multipleEnquadramento: enquadramento,
    multipleGerador: gerador,
    multipleSubgrupo: subgrupo,
    multipleTipoMercado,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...cenarioActionCreators,
    ...classeConsumoActionCreators,
    ...comercializadoraActionCreators,
    ...concessionariaActionCreators,
    ...enquadramentoActionCreators,
    ...geradorActionCreators,
    ...subgrupoActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CenarioFormContainer))
