import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, FieldArray } from 'redux-form/immutable'
import { Button, Dropdown, Form, Grid, Header, Icon, Menu, Table } from 'semantic-ui-react'
import RenderForm from './RenderForm'
import { FlashMessageContainer } from '../../containers'

const PldSemanalForm = (props) => {
  return (
    <Form onSubmit={props.handleSubmit} loading={props.loading || props.isLoading}>
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as='h1' dividing>
              <Icon name='calendar outline' />
              <Header.Content>
                Alteração de PLD - Preços Semanais (R$/MWh)
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={16}>
            <Menu secondary>
              <Menu.Menu position='left'>
                <Form as='div'>
                  <Form.Group inline>
                    <Form.Field>
                      <label>Ano</label>
                      <Dropdown compact
                        onChange={(event, data) => props.handleChange({
                          target: {
                            name: 'year',
                            value: data.value
                          }
                        })}
                        label='Ano'
                        options={props.years}
                        placeholder='Ano'
                        selection
                        value={props.year}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Mês</label>
                      <Dropdown compact
                        onChange={(event, data) => props.handleChange({
                          target: {
                            name: 'month',
                            value: data.value
                          }
                        })}
                        label='Mês'
                        options={props.months}
                        placeholder='Mês'
                        selection
                        value={props.month}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Semana Operativa</label>
                      <Dropdown compact
                        onChange={(event, data) => props.handleChange({
                          target: {
                            name: 'week',
                            value: data.value
                          }
                        })}
                        options={props.weeks}
                        placeholder='Semana'
                        selection
                        value={props.week}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Menu.Menu>
              <Menu.Menu position='right'>
                <Menu.Item fitted>
                  <Button icon
                    type='button'
                    labelPosition='left'
                    disabled={props.loading}
                    loading={props.loading}
                    onClick={(e) => {
                      e.preventDefault()
                      props.history.goBack()
                    }}>
                    <Icon name='arrow left' />{' '}Voltar
                  </Button>
                </Menu.Item>
                <Menu.Item fitted>
                  <Button icon
                    type='submit'
                    color='green'
                    disabled={props.loading}
                    loading={props.loading}
                    labelPosition='left'>
                    <Icon name='check' />{' '}Salvar
                  </Button>
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </Grid.Column>
          <Grid.Column width={16}>
            <FlashMessageContainer />
          </Grid.Column>
          <Grid.Column width={16}>
            <Table celled striped selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign='right'>Submercado</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Patamar Pesado</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Patamar Médio</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Patamar Leve</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <FieldArray
                name='pldSemanal'
                component={RenderForm}
                loading={props.isLoading}
              />
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  )
}

PldSemanalForm.propTypes = {
  isLoading: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  history: PropTypes.object,

  year: PropTypes.number,
  month: PropTypes.number,
  week: PropTypes.number,
  years: PropTypes.array,
  months: PropTypes.array,
  weeks: PropTypes.array,
}

export default reduxForm({form: 'PldSemanalForm'})(PldSemanalForm)
