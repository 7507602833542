import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_RESULTADO = 'LOADING_RESULTADO'
export function loadingResultado () {
  return {
    type: LOADING_RESULTADO,
  }
}

export const LOADING_RESULTADO_SUCCESS = 'LOADING_RESULTADO_SUCCESS'
export function loadingResultadoSuccess (resultado) {
  return {
    type: LOADING_RESULTADO_SUCCESS,
    resultado,
  }
}

export const LOADING_RESULTADO_FAILURE = 'LOADING_RESULTADO_FAILURE'
export function loadingResultadoFailure (error) {
  return {
    type: LOADING_RESULTADO_FAILURE,
    error,
  }
}

const initialState = Map({})

export function fetchResultado (faturaPedagioId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingResultado())
      const resultado = await callAPI(`/faturas_pedagio/${faturaPedagioId}/resultado`)
      dispatch(loadingResultadoSuccess(resultado))
      return resultado
    } catch (e) {
      dispatch(loadingResultadoFailure(e))
      return false
    }
  }
}

export default function resultado (state = initialState, action) {
  switch (action.type) {

    case LOADING_RESULTADO_SUCCESS : {

      const { resultado } = action
      if (resultado.constructor === Array) {
        state = initialState
        resultado.forEach((singleResultado) => (
          state = state.merge({[singleResultado.fatura_id]: singleResultado})
        ))
        return state
      }
      return state.merge({[resultado.fatura_id]: resultado})
    }

    default :
      return state
  }
}
