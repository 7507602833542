import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_CONTATO_INSTALACAO = 'LOADING_CONTATO_INSTALACAO'
export function loadingContatoInstalacao () {
  return {
    type: LOADING_CONTATO_INSTALACAO,
  }
}

export const LOADING_CONTATO_INSTALACAO_SUCCESS = 'LOADING_CONTATO_INSTALACAO_SUCCESS'
export function loadingContatoInstalacaoSuccess (contatoInstalacao) {
  return {
    type: LOADING_CONTATO_INSTALACAO_SUCCESS,
    contatoInstalacao,
  }
}

export const LOADING_CONTATO_INSTALACAO_FAILURE = 'LOADING_CONTATO_INSTALACAO_FAILURE'
export function loadingContatoInstalacaoFailure (error) {
  return {
    type: LOADING_CONTATO_INSTALACAO_FAILURE,
    error,
  }
}

export const DESTROYING_CONTATO_INSTALACAO_SUCCESS = 'DESTROYING_CONTATO_INSTALACAO_SUCCESS'
export function destroyingContatoInstalacaoSuccess (contatoInstalacaoId) {
  return {
    type: DESTROYING_CONTATO_INSTALACAO_SUCCESS,
    contatoInstalacaoId,
  }
}

const initialState = Map({})

export function fetchMultipleContatoInstalacao (instalacaoId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoInstalacao())
      const multipleContatoInstalacao = await callAPI(`/instalacoes/${instalacaoId}/contatos`)
      dispatch(loadingContatoInstalacaoSuccess(multipleContatoInstalacao))
      return multipleContatoInstalacao
    } catch (e) {
      dispatch(loadingContatoInstalacaoFailure(e))
      return false
    }
  }
}

export function fetchContatoInstalacao (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoInstalacao())
      const contatoInstalacao = await callAPI(`/contatos/${id}`)
      dispatch(loadingContatoInstalacaoSuccess(contatoInstalacao))
      return contatoInstalacao
    } catch (e) {
      dispatch(loadingContatoInstalacaoFailure(e))
      return false
    }
  }
}

export function createContatoInstalacao (instalacaoId, contatoInstalacao) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoInstalacao())
      const newContatoInstalacao = await callAPI(`/instalacoes/${instalacaoId}/contatos`, 'POST', contatoInstalacao)
      dispatch(loadingContatoInstalacaoSuccess(newContatoInstalacao))
      return newContatoInstalacao
    } catch (e) {
      dispatch(loadingContatoInstalacaoFailure(e))
      return false
    }
  }
}

export function updateContatoInstalacao (id, contatoInstalacao) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoInstalacao())
      const newContatoInstalacao = await callAPI(`/contatos/${id}`, 'PUT', contatoInstalacao)
      dispatch(loadingContatoInstalacaoSuccess(newContatoInstalacao))
      return newContatoInstalacao
    } catch (e) {
      dispatch(loadingContatoInstalacaoFailure(e))
      return false
    }
  }
}

export function destroyContatoInstalacao (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoInstalacao())
      await callAPI(`/contatos/${id}`, 'DELETE')
      dispatch(destroyingContatoInstalacaoSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingContatoInstalacaoFailure(e))
      return false
    }
  }
}

export default function contatoInstalacao (state = initialState, action) {
  switch (action.type) {

    case LOADING_CONTATO_INSTALACAO_SUCCESS : {
      const { contatoInstalacao } = action
      if (contatoInstalacao.constructor === Array) {
        state = initialState
        contatoInstalacao.forEach((c) => (
          state = state.merge({[c.id]: c})
        ))
        return state
      }
      return state.merge({[contatoInstalacao.id]: contatoInstalacao})
    }

    case DESTROYING_CONTATO_INSTALACAO_SUCCESS :
      return state.delete(action.contatoInstalacaoId.toString())

    default :
      return state
  }
}
