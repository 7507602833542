import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { FonteComercializadora } from '../../../containers'

const Fonte = (props) => {
  const { comercializadora } = props
  return (
    <div>
      <Switch>
        <Route
          exact path='/comercializadoras/:comercializadoraId/fontes_comercializadoras'
          render={({match}) => (
            <FonteComercializadora.Index
              comercializadora={comercializadora}
              match={match}
            />
          )}>
        </Route>
        <Route
          exact path='/comercializadoras/:comercializadoraId/fontes_comercializadoras/new'
          render={({match}) => (
            <FonteComercializadora.Form
              comercializadora={comercializadora}
              match={match}
            />
          )}
        />
        <Route
          exact path='/comercializadoras/:comercializadoraId/fontes_comercializadoras/:id/edit'
          render={({match}) => (
            <FonteComercializadora.Form
              comercializadora={comercializadora}
              match={match}
            />
          )}
        />
        <Route
          exact path='/comercializadoras/:comercializadoraId/fontes_comercializadoras/:id'
          render={({match}) => (
            <FonteComercializadora.Show
              comercializadora={comercializadora}
              match={match}
            />
          )}
        />
      </Switch>
    </div>
  )
}

Fonte.propTypes = {
  comercializadora: PropTypes.object.isRequired,
}

export default Fonte
