import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { ExtraPedagioForm } from '../../components'
import * as extraPedagioActionCreators from '../../redux/modules/extraPedagio'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

import { maskCurrency } from '../../utils/masks'

class ExtraPedagioFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    initialize: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    faturaPedagio: PropTypes.object,

    fetchExtraPedagio: PropTypes.func.isRequired,
    createExtraPedagio: PropTypes.func.isRequired,
    updateExtraPedagio: PropTypes.func.isRequired,

    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    if (this.props.id) {
      const extra_pedagio = await this.props.fetchExtraPedagio(this.props.id)
      extra_pedagio.valor = maskCurrency.parse(Number(extra_pedagio.valor))
      this.props.initialize('ExtraPedagioForm', {extra_pedagio})
    } else {
      this.props.initialize('ExtraPedagioForm', {
        extra_pedagio: {
          fatura_pedagio_id: this.props.faturaPedagio.id
        }
      })
    }
  }

  handleSubmitResource = async (extraPedagio) => {
    const faturaPedagioId = this.props.faturaPedagio.id

    let valor = maskCurrency.unmask(extraPedagio.getIn(['extra_pedagio', 'valor']))
    extraPedagio = extraPedagio.setIn(['extra_pedagio', 'valor'], valor)

    if (this.props.id) {
      if (await this.props.updateExtraPedagio(this.props.id, extraPedagio)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/faturas/pedagio/${faturaPedagioId}/extras`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createExtraPedagio(faturaPedagioId, extraPedagio)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push(`/faturas/pedagio/${faturaPedagioId}/extras`)
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <ExtraPedagioForm
        faturaPedagio={this.props.faturaPedagio}
        onSubmit={(extraPedagio) => this.handleSubmitResource(extraPedagio)}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({extraPedagio, status, flashMessage}, {match}) {
  const { id } = match.params

  if (id) {
    return {
      id,
      extraPedagio: extraPedagio.get('id'),
      error: status.getIn(['extraPedagio', 'error']),
      isLoading: status.getIn(['extraPedagio', 'isLoading']),
      shouldHideFlashMessage: flashMessage.get('isError'),
    }
  }
  return {
    error: status.getIn(['extraPedagio', 'error']),
    isLoading: status.getIn(['extraPedagio', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...extraPedagioActionCreators,
    ...flashMessageActionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExtraPedagioFormContainer))
