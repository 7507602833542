import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Home } from '../../components'
import { connect } from 'react-redux'

class HomeContainer extends Component {

  static propTypes = {
    session: PropTypes.object,
  }

  render () {
    return (
      <Home session={this.props.session} />
    )
  }
}

function mapStateToProps ({ session }) {
  return { session }
}

export default connect(mapStateToProps)(HomeContainer)
