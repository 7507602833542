import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_CONTRATO_ENERGIA_INSTALACAO = 'LOADING_CONTRATO_ENERGIA_INSTALACAO'
export function loadingContratoEnergiaInstalacao () {
  return {
    type: LOADING_CONTRATO_ENERGIA_INSTALACAO,
  }
}

export const LOADING_CONTRATO_ENERGIA_INSTALACAO_SUCCESS = 'LOADING_CONTRATO_ENERGIA_INSTALACAO_SUCCESS'
export function loadingContratoEnergiaInstalacaoSuccess (contratoEnergiaInstalacao) {
  return {
    type: LOADING_CONTRATO_ENERGIA_INSTALACAO_SUCCESS,
    contratoEnergiaInstalacao,
  }
}

export const LOADING_CONTRATO_ENERGIA_INSTALACAO_FAILURE = 'LOADING_CONTRATO_ENERGIA_INSTALACAO_FAILURE'
export function loadingContratoEnergiaInstalacaoFailure (error) {
  return {
    type: LOADING_CONTRATO_ENERGIA_INSTALACAO_FAILURE,
    error,
  }
}

export const DESTROYING_CONTRATO_ENERGIA_INSTALACAO_SUCCESS = 'DESTROYING_CONTRATO_ENERGIA_INSTALACAO_SUCCESS'
export function destroyingContratoEnergiaInstalacaoSuccess (contratoEnergiaInstalacaoId) {
  return {
    type: DESTROYING_CONTRATO_ENERGIA_INSTALACAO_SUCCESS,
    contratoEnergiaInstalacaoId,
  }
}

const initialState = Map({})

export function fetchMultipleContratoEnergiaInstalacao (instalacaoId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContratoEnergiaInstalacao())
      const multipleContratoEnergiaInstalacao = await callAPI(`/instalacoes/${instalacaoId}/contratos_energia`)
      dispatch(loadingContratoEnergiaInstalacaoSuccess(multipleContratoEnergiaInstalacao))
      return multipleContratoEnergiaInstalacao
    } catch (e) {
      dispatch(loadingContratoEnergiaInstalacaoFailure(e))
      return false
    }
  }
}

export function fetchContratoEnergiaInstalacao (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContratoEnergiaInstalacao())
      const contratoEnergiaInstalacao = await callAPI(`/contratos_energia/${id}`)
      dispatch(loadingContratoEnergiaInstalacaoSuccess(contratoEnergiaInstalacao))
      return contratoEnergiaInstalacao
    } catch (e) {
      dispatch(loadingContratoEnergiaInstalacaoFailure(e))
      return false
    }
  }
}

export function createContratoEnergiaInstalacao (instalacaoId, contratoEnergiaInstalacao) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContratoEnergiaInstalacao())
      const newContratoEnergiaInstalacao = await callAPI(`/instalacoes/${instalacaoId}/contratos_energia`, 'POST', contratoEnergiaInstalacao)
      dispatch(loadingContratoEnergiaInstalacaoSuccess(newContratoEnergiaInstalacao))
      return newContratoEnergiaInstalacao
    } catch (e) {
      dispatch(loadingContratoEnergiaInstalacaoFailure(e))
      return false
    }
  }
}

export function updateContratoEnergiaInstalacao (id, contratoEnergiaInstalacao) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContratoEnergiaInstalacao())
      const newContratoEnergiaInstalacao = await callAPI(`/contratos_energia/${id}`, 'PUT', contratoEnergiaInstalacao)
      dispatch(loadingContratoEnergiaInstalacaoSuccess(newContratoEnergiaInstalacao))
      return newContratoEnergiaInstalacao
    } catch (e) {
      dispatch(loadingContratoEnergiaInstalacaoFailure(e))
      return false
    }
  }
}

export function destroyContratoEnergiaInstalacao (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContratoEnergiaInstalacao())
      await callAPI(`/contratos_energia/${id}`, 'DELETE')
      dispatch(destroyingContratoEnergiaInstalacaoSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingContratoEnergiaInstalacaoFailure(e))
      return false
    }
  }
}

export default function contratoEnergiaInstalacao (state = initialState, action) {
  switch (action.type) {

    case LOADING_CONTRATO_ENERGIA_INSTALACAO_SUCCESS : {
      const { contratoEnergiaInstalacao } = action
      if (contratoEnergiaInstalacao.constructor === Array) {
        state = initialState
        contratoEnergiaInstalacao.forEach((c) => (
          state = state.merge({[c.id]: c})
        ))
        return state
      }
      return state.merge({[contratoEnergiaInstalacao.id]: contratoEnergiaInstalacao})
    }

    case DESTROYING_CONTRATO_ENERGIA_INSTALACAO_SUCCESS :
      return state.delete(action.contratoEnergiaInstalacaoId.toString())

    default :
      return state
  }
}
