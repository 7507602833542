import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { IcmsForm } from '../../components'
import * as icmsActionCreators from '../../redux/modules/icms'
import * as ufActionCreators from '../../redux/modules/uf'
import * as classeConsumoActionCreators from '../../redux/modules/classeConsumo'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { mapToDropdown } from '../../utils/parse'
import { maskCurrency } from '../../utils/masks'

class IcmsFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    initialize: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,

    fetchIcms: PropTypes.func.isRequired,
    createIcms: PropTypes.func.isRequired,
    updateIcms: PropTypes.func.isRequired,

    ufDropdown: PropTypes.array.isRequired,
    multipleUf: PropTypes.object.isRequired,
    fetchMultipleUf: PropTypes.func.isRequired,

    classeConsumoDropdown: PropTypes.array.isRequired,
    multipleClasseConsumo: PropTypes.object.isRequired,
    fetchMultipleClasseConsumo: PropTypes.func.isRequired,

    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,

    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  static defaultProps = {
    multipleUf: {},
    multipleClasseConsumo: {},
  }

  async componentDidMount () {
    await this.props.fetchMultipleUf()
    await this.props.fetchMultipleClasseConsumo()
    if (this.props.id) {
      const icms = await this.props.fetchIcms(this.props.id)
      icms.uf_id = icms.uf.id
      icms.classe_consumo_id = icms.classe_consumo.id
      icms.aliquota = maskCurrency.parse(Number(icms.aliquota) * 100)
      this.props.initialize('IcmsForm', {icms})
    }
  }

  handleSubmitResource = async (icms) => {
    let aliquota = maskCurrency.unmask(icms.getIn(['icms', 'aliquota']))
    icms = icms.setIn(['icms', 'aliquota'], aliquota / 100)

    if (this.props.id) {
      if (await this.props.updateIcms(this.props.id, icms)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push('/icms')
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createIcms(icms)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/icms')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <IcmsForm
        ufDropdown={this.props.ufDropdown}
        classeConsumoDropdown={this.props.classeConsumoDropdown}
        multipleUf={this.props.multipleUf}
        multipleClasseConsumo={this.props.multipleClasseConsumo}
        onSubmit={(icms) => this.handleSubmitResource(icms)}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({icms, uf, classeConsumo, status, flashMessage}, {match}) {
  const { id } = match.params
  const ufDropdown = mapToDropdown(
    uf,
    e => e.get('id'),
    e => `${e.get('sigla')} - ${e.get('nome')}`
  )

  const classeConsumoDropdown = mapToDropdown(
    classeConsumo,
    e => e.get('id'),
    e => e.get('nome')
  )

  if (id) {
    return {
      id,
      icms: icms.get('id'),
      ufDropdown,
      classeConsumoDropdown,
      error: status.getIn(['icms', 'error']),
      isLoading: status.getIn(['icms', 'isLoading']),
      shouldHideFlashMessage: flashMessage.get('isError'),
    }
  }
  return {
    ufDropdown,
    classeConsumoDropdown,
    error: status.getIn(['icms', 'error']),
    isLoading: status.getIn(['icms', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...icmsActionCreators,
    ...ufActionCreators,
    ...classeConsumoActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(IcmsFormContainer)
