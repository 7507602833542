import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { RelatorioShow } from '../../components'
import * as relatorioActionCreators from '../../redux/modules/relatorio'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

class RelatorioShowContainer extends Component {

  static propTypes = {
    instalacao: PropTypes.object.isRequired,

    id: PropTypes.string,
    history: PropTypes.object,
    fetchRelatorio: PropTypes.func,
    destroyRelatorio: PropTypes.func,
    isLoading: PropTypes.bool,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,

    autoPrint: PropTypes.bool,
  }

  state = {
    relatorio: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const relatorio = await this.props.fetchRelatorio(this.props.id)
      this.setState({ relatorio })
    }
  }

  handleEditResource = (instalacaoId, id) => {
    this.props.history.push(`/instalacoes/${instalacaoId}/relatorios/${id}/edit`)
  }

  handleDeleteResource = async (instalacaoId, id) => {
    if (await this.props.destroyRelatorio(id)) {
      this.props.showSuccessfulFlashMessage('', 'destroy')
      this.props.history.push(`/instalacoes/${instalacaoId}/relatorios`)
    } else {
      this.props.showErrorFlashMessage('', 'destroy', this.props.error)
    }
  }

  handlePrintResource = (instalacaoId, id) => {
    this.props.history.push(`/instalacoes/${instalacaoId}/relatorios/${id}/print`)
  }

  render () {
    return (
      <RelatorioShow
        isLoading={this.props.isLoading}
        instalacao={this.props.instalacao}
        onEditResource={() => this.handleEditResource(this.props.instalacao.id, this.state.relatorio.id)}
        onDeleteResource={() => this.handleDeleteResource(this.props.instalacao.id, this.state.relatorio.id)}
        onPrintResource={() => this.handlePrintResource(this.props.instalacao.id, this.state.relatorio.id)}
        relatorio={this.state.relatorio}
        autoPrint={this.props.autoPrint} />
    )
  }
}

function mapStateToProps ({relatorio, status}, {match}) {
  const { id } = match.params
  const isLoading = status.getIn(['relatorio', 'isLoading'])
  if (id) {
    return {
      id,
      relatorio: relatorio.get('id'),
      isLoading,
    }
  }
  return {
    isLoading,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...relatorioActionCreators,
    ...flashMessageActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RelatorioShowContainer))
