import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Header, Icon, Message, Table } from 'semantic-ui-react'
import { maskCurrency } from '../../utils/masks'

const PldSemanalImport = (props) => {
  return (
    <Grid padded verticalAlign='middle' columns={4} centered>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header as='h1' dividing>
            <Icon name='calendar outline' />
            <Header.Content>
              Importação de PLD - Preços Semanais (R$/MWh)
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={16}>
          {props.pldSemanal && props.pldSemanal.length && (
            <div>
              <Message warning>
                <Message.Header>As informações abaixo serão importadas.</Message.Header>
                <p><strong>Atenção: </strong>Dados existentes serão sobrescritos. Deseja realmente continuar?</p>
                <Button color='green' onClick={props.handleSubmit}>Sim, continuar a importação</Button>
                <Button onClick={props.onDiscard}>Não, cancelar a operação</Button>
              </Message>

              <Table celled striped selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign='center'>Ano</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Mês</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Semana</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Submercado</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Patamar Pesado (R$/MWh)</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Patamar Médio (R$/MWh)</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Patamar Leve (R$/MWh)</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {props.pldSemanal.map((pld, index) => (
                    <Table.Row key={index}>
                      <Table.Cell textAlign='center'>{pld['ano']}</Table.Cell>
                      <Table.Cell textAlign='center'>{pld['mes']}</Table.Cell>
                      <Table.Cell textAlign='center'>{pld['semana']}</Table.Cell>
                      <Table.Cell textAlign='center'>{pld['submercado_id']}</Table.Cell>
                      <Table.Cell textAlign='right'>{maskCurrency.parse(pld['patamar_pesado'])}</Table.Cell>
                      <Table.Cell textAlign='right'>{maskCurrency.parse(pld['patamar_medio'])}</Table.Cell>
                      <Table.Cell textAlign='right'>{maskCurrency.parse(pld['patamar_leve'])}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

PldSemanalImport.propTypes = {
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  pldSemanal: PropTypes.array,
  onDiscard: PropTypes.func,
  onSelectFile: PropTypes.func,
  loading: PropTypes.bool,
}

export default PldSemanalImport
