import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_VIGENCIA_TARIFA = 'LOADING_VIGENCIA_TARIFA'
export function loadingVigenciaTarifa () {
  return {
    type: LOADING_VIGENCIA_TARIFA,
  }
}

export const LOADING_VIGENCIA_TARIFA_SUCCESS = 'LOADING_VIGENCIA_TARIFA_SUCCESS'
export function loadingVigenciaTarifaSuccess (vigenciaTarifa) {
  return {
    type: LOADING_VIGENCIA_TARIFA_SUCCESS,
    vigenciaTarifa,
  }
}

export const LOADING_VIGENCIA_TARIFA_FAILURE = 'LOADING_VIGENCIA_TARIFA_FAILURE'
export function loadingVigenciaTarifaFailure (error) {
  return {
    type: LOADING_VIGENCIA_TARIFA_FAILURE,
    error,
  }
}

export const DESTROYING_VIGENCIA_TARIFA_SUCCESS = 'DESTROYING_VIGENCIA_TARIFA_SUCCESS'
export function destroyingVigenciaTarifaSuccess (vigenciaTarifaId) {
  return {
    type: DESTROYING_VIGENCIA_TARIFA_SUCCESS,
    vigenciaTarifaId,
  }
}

const initialState = Map({})

export function fetchMultipleVigenciaTarifa (concessionariaId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaTarifa())
      const multipleVigenciaTarifa = await callAPI(`/concessionarias/${concessionariaId}/tarifas`)
      dispatch(loadingVigenciaTarifaSuccess(multipleVigenciaTarifa))
      return multipleVigenciaTarifa
    } catch (e) {
      dispatch(loadingVigenciaTarifaFailure(e))
      return false
    }
  }
}

export function fetchVigenciaTarifa (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaTarifa())
      const vigenciaTarifa = await callAPI(`/tarifas/${id}`)
      dispatch(loadingVigenciaTarifaSuccess(vigenciaTarifa))
      return vigenciaTarifa
    } catch (e) {
      dispatch(loadingVigenciaTarifaFailure(e))
      return false
    }
  }
}

export function createVigenciaTarifa (concessionariaId, vigenciaTarifa) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaTarifa())
      const newVigenciaTarifa = await callAPI(`/concessionarias/${concessionariaId}/tarifas`, 'POST', vigenciaTarifa)
      dispatch(loadingVigenciaTarifaSuccess(newVigenciaTarifa))
      return newVigenciaTarifa
    } catch (e) {
      dispatch(loadingVigenciaTarifaFailure(e))
      return false
    }
  }
}

export function updateVigenciaTarifa (id, vigenciaTarifa) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaTarifa())
      const newVigenciaTarifa = await callAPI(`/tarifas/${id}`, 'PUT', vigenciaTarifa)
      dispatch(loadingVigenciaTarifaSuccess(newVigenciaTarifa))
      return newVigenciaTarifa
    } catch (e) {
      dispatch(loadingVigenciaTarifaFailure(e))
      return false
    }
  }
}

export function destroyVigenciaTarifa (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaTarifa())
      await callAPI(`/tarifas/${id}`, 'DELETE')
      dispatch(destroyingVigenciaTarifaSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingVigenciaTarifaFailure(e))
      return false
    }
  }
}

export default function vigenciaTarifa (state = initialState, action) {
  switch (action.type) {

    case LOADING_VIGENCIA_TARIFA_SUCCESS : {
      const { vigenciaTarifa } = action
      if (vigenciaTarifa.constructor === Array) {
        state = initialState
        vigenciaTarifa.forEach((vigencia) => (
          state = state.merge({[vigencia.id]: vigencia})
        ))
        return state
      }
      return state.merge({[vigenciaTarifa.id]: vigenciaTarifa})
    }

    case DESTROYING_VIGENCIA_TARIFA_SUCCESS :
      return state.delete(action.vigenciaTarifaId.toString())

    default :
      return state
  }
}
