import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class ResourceBreadcrumb extends Component {

  static propTypes = {
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    prefix: PropTypes.string,
  }

  static defaultProps = {
    prefix: '',
  }

  render() {
    return (`${this.props.prefix}${this.props.label}`)
  }
}

function mapStateToProps (state, ownProps) {
  const { id } = ownProps.match.params

  if (!id) {
    return { label: '' }
  }

  const collection = state[ownProps.resourceName]

  let found = collection.find(c => c.get('id') === parseInt(id, 10))
  if (!found) {
    return { label: '' }
  }

  return { label: found.get(ownProps.fieldName), prefix: ownProps.fieldName === 'id' ? '#' : '' }
}

export default connect(mapStateToProps)(ResourceBreadcrumb)
