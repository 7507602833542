import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Menu } from '../../components'
import { connect } from 'react-redux'

class MenuContainer extends Component {
  static propTypes = {
    session: PropTypes.object,
  }

  render () {
    return (
      <Menu session={this.props.session} />
    )
  }
}

function mapStateToProps ({ session }) {
  return { session }
}

export default connect(mapStateToProps)(MenuContainer)
