import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { VigenciaBandeira } from '../../../containers'

const VigenciasRouter = () => (
  <Switch>
    <Route exact path='/gestao_bandeiras/vigencias' component={VigenciaBandeira.Index} />
    <Route exact path='/gestao_bandeiras/vigencias/new' component={VigenciaBandeira.Form} />
    <Route exact path='/gestao_bandeiras/vigencias/:id/edit' component={VigenciaBandeira.Form} />
    <Route exact path='/gestao_bandeiras/vigencias/:id' component={VigenciaBandeira.Show} />
  </Switch>
)

export default VigenciasRouter
