import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { GeradorForm } from '../../components'
import * as geradorActionCreators from '../../redux/modules/gerador'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import { maskCurrency } from '../../utils/masks'

class GeradorFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    fetchGerador: PropTypes.func.isRequired,
    createGerador: PropTypes.func.isRequired,
    updateGerador: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    if (this.props.id) {
      const gerador = await this.props.fetchGerador(this.props.id)
      gerador.eficiencia = maskCurrency.parse(Number(gerador.eficiencia))
      gerador.potencia = maskCurrency.parse(Number(gerador.potencia))
      gerador.potencia_ativa = maskCurrency.parse(Number(gerador.potencia_ativa))
      gerador.potencia_cv = maskCurrency.parse(Number(gerador.potencia_cv))
      gerador.consumo_combustivel = maskCurrency.parse(Number(gerador.consumo_combustivel))
      this.props.initialize('GeradorForm', {gerador})
    }
  }

  handleSubmitResource = async (gerador) => {
    let eficiencia = maskCurrency.unmask(gerador.getIn(['gerador', 'eficiencia']))
    gerador = gerador.setIn(['gerador', 'eficiencia'], eficiencia)

    let potencia = maskCurrency.unmask(gerador.getIn(['gerador', 'potencia']))
    gerador = gerador.setIn(['gerador', 'potencia'], potencia)

    let potencia_ativa = maskCurrency.unmask(gerador.getIn(['gerador', 'potencia_ativa']))
    gerador = gerador.setIn(['gerador', 'potencia_ativa'], potencia_ativa)

    let potencia_cv = maskCurrency.unmask(gerador.getIn(['gerador', 'potencia_cv']))
    gerador = gerador.setIn(['gerador', 'potencia_cv'], potencia_cv)

    let consumo_combustivel = maskCurrency.unmask(gerador.getIn(['gerador', 'consumo_combustivel']))
    gerador = gerador.setIn(['gerador', 'consumo_combustivel'], consumo_combustivel)

    if (this.props.id) {
      if (await this.props.updateGerador(this.props.id, gerador)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push('/geradores')
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createGerador(gerador)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/geradores')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <GeradorForm
        onSubmit={(gerador) => this.handleSubmitResource(gerador)}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({gerador, status, flashMessage}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      gerador: gerador.get('id'),
      error: status.getIn(['gerador', 'error']),
      isLoading: status.getIn(['gerador', 'isLoading']),
      shouldHideFlashMessage: flashMessage.get('isError'),
    }
  }
  return {
    error: status.getIn(['gerador', 'error']),
    isLoading: status.getIn(['gerador', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...geradorActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(GeradorFormContainer)
