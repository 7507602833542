import React, { Component } from "react";
import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { initialize } from "redux-form";
import { change, formValueSelector } from "redux-form/immutable";

import * as calculadoraConsumoActionCreators from "../../redux/modules/calculadoraConsumo";

import { maskFloat } from "../../utils/masks";
import CalculadoraConsumo from "../../components/CalculadoraConsumo/CalculadoraConsumo";

const selector = formValueSelector("CalculadoraConsumo");

class CalculadoraConsumoContainer extends Component {
  static propTypes = {
    concessionariaId: PropTypes.number,
    data: PropTypes.string,
    dataTributos: PropTypes.string,
    field: PropTypes.string,
    otherField: PropTypes.string,
    change: PropTypes.func,
    initialize: PropTypes.func,
    consumo: PropTypes.number,
    consumoTotal: PropTypes.number,
    bandeira: PropTypes.object,
    tributos: PropTypes.object,
    parametrosConcessionaria: PropTypes.object,
    icms: PropTypes.number,
    fetchCalculadoraConsumo: PropTypes.func,
  };

  state = {
    isOpen: false,
    bandeira: {},
    tributos: [],
  };

  async componentDidMount() {
    this.props.initialize("CalculadoraConsumo", {
      valor: "0,00",
    });

    await this.props.fetchCalculadoraConsumo(
      this.props.concessionariaId,
      this.props.data,
      this.props.dataTributos
    );
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      await this.props.fetchCalculadoraConsumo(
        this.props.concessionariaId,
        this.props.data,
        this.props.dataTributos
      );
    }
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleSelectValue = (value) => {
    const { change, field, otherField } = this.props;

    const maskedValue = value;
    if (!maskedValue) return;

    const consumo = Math.round(100 * maskFloat.unmask(maskedValue)) / 100;
    change(
      "FaturaPedagioForm",
      `fatura_pedagio.${field}`,
      maskFloat.parse(consumo)
    );

    if (otherField) {
      const consumoTotal = maskFloat.unmask(this.props.consumoTotal).toFixed(2);
      const other = Math.round(100 * (consumoTotal - consumo)) / 100;
      change(
        "FaturaPedagioForm",
        `fatura_pedagio.${otherField}`,
        maskFloat.parse(other)
      );
    }

    this.setState({ isOpen: false });
  };

  render() {
    return (
      <CalculadoraConsumo
        isOpen={this.state.isOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        onSelectValue={this.handleSelectValue}
        bandeira={this.props.bandeira}
        tributos={this.props.tributos}
        icms={this.props.icms}
        consumo={this.props.consumo}
      />
    );
  }
}

const calcTributos = (tributos, icms, separar_icms) =>
  tributos.reduce((acc, curr) => acc + parseFloat(curr.get("aliquota")), 0.0) +
  (separar_icms ? 0 : parseFloat(icms));

const totalConsumo = (base, valor, totalTributos, icms, separar_icms) =>
  base > 0
    ? (valor * (1 - totalTributos) * (1 - (separar_icms ? icms : 0))) / base
    : 0.0;

const calcularConsumo = (valor, bandeira, tributos, icms, parametroCon) => {
  if (!bandeira || !tributos) return "0,00";
  const separar_icms = parametroCon?.separar_icms_calculo || false;

  const totalTributos = calcTributos(tributos, icms, separar_icms);
  const base = parseFloat(bandeira.get("valor"));
  const consumo = totalConsumo(base, valor, totalTributos, icms, separar_icms);
  return maskFloat.parse(consumo.toFixed(2));
};

function mapStateToProps(state, ownProps) {
  const { calculadoraConsumo } = state;

  const bandeira = calculadoraConsumo.getIn([ownProps.data, "bandeira"]);
  const tributos = calculadoraConsumo.getIn([ownProps.data, "tributos"]);
  const icms = ownProps.icms;
  const parametrosConcessionaria = calculadoraConsumo
    .getIn([ownProps.data, "parametrosConcessionaria"])
    ?.toList()
    .toJS()
    .shift();

  const valor = selector(state, "valor");
  const consumo = calcularConsumo(
    valor ? maskFloat.unmask(valor) : 0.0,
    bandeira,
    tributos,
    icms,
    parametrosConcessionaria
  );

  return {
    consumo,
    bandeira,
    tributos,
    parametrosConcessionaria,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...calculadoraConsumoActionCreators,
      ...{ initialize },
      ...{ change },
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculadoraConsumoContainer);
