import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput, SemanticDropdown, DatePickerInput } from '../FormComponents'

import { required, maxLengthDescricao, dateValidator } from '../../utils/validations'

const ServicoInstalacaoForm = (props) => (
  <CRUDForm
    title='Serviço'
    titleAs='h3'
    icon='wrench'
    padded={false}
    {...props}>
    <FormSection name='servico_prestado'>
      <Form.Group>
        <FormField width={6}>
          <label>Serviço</label>
          <Field fluid button floating labeled icon='percent' className='icon'
            autoFocus
            name='servico_id'
            validate={[required]}
            options={props.servicoDropdown}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
        <FormField width={2}>
          <label>Executado Em</label>
          <Field
            name='executado_em'
            placeholder='Executado Em'
            validate={[required, dateValidator]}
            component={DatePickerInput}
            selectsStart
            loading={props.loading}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField width={8}>
          <label>Descrição</label>
          <Field
            name='descricao'
            placeholder='Descrição'
            validate={[required, maxLengthDescricao]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>
    </FormSection>
  </CRUDForm>
)

ServicoInstalacaoForm.propTypes = {
  servicoDropdown: PropTypes.array.isRequired,
  loading: PropTypes.bool
}

export default reduxForm({form: 'ServicoInstalacaoForm'})(ServicoInstalacaoForm)
