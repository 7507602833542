import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import get from 'lodash.get'
import { Container, Header } from 'semantic-ui-react'
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
} from 'recharts'
import {
  COLORS,
  capitalize,
  formatAsLocalizedNumber,
  formatAsCurrency,
  formatAsRoundedPercentageCalculated,
} from './utils/ChartUtils'

const fieldsNameMapping = {
  reais_por_producao: 'R$/Caixa Unitária',
  variacao_producao: 'Variação Produção Ano Atual x Ano Anterior',
  variacao_consumo: 'Variação Consumo Atual x Ano Anterior',
}

const DadosPersonalizadosChart = ({ header, subheader, data, height, showAnimations }) => {
  if (!data) return null
    
  const preparedData = data.dados.map(d => {
    const prepared = Object.assign({}, d)
    prepared._labelFaturaMes = capitalize(moment({y: d.ano, M: d.mes - 1}).format('MMM/YYYY'))
    prepared._parsedVariacaoConsumo = get(prepared, 'variacao_consumo', 0) * 100
    prepared._parsedVariacaoProducao = get(prepared, 'variacao_producao', 0) * 100
    return prepared
  })

  return (
    <Container fluid>
      <Header as='h3' dividing>
        <Header.Content>
          {header}
          {subheader && <Header.Subheader>{subheader}</Header.Subheader>}
        </Header.Content>
      </Header>
      <ResponsiveContainer height={height}>
        <ComposedChart data={preparedData}>
          <XAxis dataKey="_labelFaturaMes" type="category" interval={0}/>
          <YAxis
            yAxisId="left"
            type="number"
            tickFormatter={formatAsCurrency}
            width={50}
          />
          <YAxis
            yAxisId="right"
            type="number"
            tickFormatter={value => `${value}%`}
            stroke={COLORS.DARK.DEFAULT}
            orientation="right"
            width={40}
          />
          <Tooltip formatter={value => `${formatAsLocalizedNumber(value)} `}/>
          <Legend/>
          <Bar
            dataKey="reais_por_producao"
            yAxisId="left"
            fill={COLORS.LIGHT.NEUTRAL}
            legendType="square"
            name={fieldsNameMapping['reais_por_producao']}
            isAnimationActive={showAnimations}
          >
            <LabelList
              dataKey="reais_por_producao"
              position="top"
              formatter={formatAsCurrency}/>
          </Bar>
          <Line
            dataKey="_parsedVariacaoConsumo"
            yAxisId="right"
            stroke={COLORS.PRIMARY}
            legendType="line"
            name={fieldsNameMapping['variacao_producao']}
            unit=" %"
            isAnimationActive={showAnimations}
          >
            <LabelList
              dataKey="_parsedVariacaoConsumo"
              position="bottom"
              offset={10}
              fill={COLORS.PRIMARY}
              fontSize={12}
              formatter={formatAsRoundedPercentageCalculated}/>
          </Line>
          <Line
            dataKey="_parsedVariacaoProducao"
            yAxisId="right"
            stroke={COLORS.BOLD}
            legendType="line"
            name={fieldsNameMapping['variacao_consumo']}
            unit=" %"
            isAnimationActive={showAnimations}
          >
            <LabelList
              dataKey="_parsedVariacaoProducao"
              position="right"
              offset={10}
              fill={COLORS.BOLD}
              strokeWidth={0.2}
              fontSize={12}
              formatter={formatAsRoundedPercentageCalculated}/>
          </Line>
        </ComposedChart>
      </ResponsiveContainer>
    </Container>
  )
}

DadosPersonalizadosChart.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  data: PropTypes.object,
  height: PropTypes.number,
  showAnimations: PropTypes.bool,
}

DadosPersonalizadosChart.defaultProps = {
  header: '',
  subheader: '',
  height: 350,
  showAnimations: true,
}

export default DadosPersonalizadosChart
