import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { ServicoForm } from '../../components'
import * as servicoActionCreators from '../../redux/modules/servico'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

class ServicoFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    fetchServico: PropTypes.func.isRequired,
    createServico: PropTypes.func.isRequired,
    updateServico: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    if (this.props.id) {
      const servico = await this.props.fetchServico(this.props.id)
      this.props.initialize('ServicoForm', {servico})
    }
  }

  handleSubmitResource = async (servico) => {
    if (this.props.id) {
      if (await this.props.updateServico(this.props.id, servico)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push('/servicos')
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createServico(servico)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/servicos')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <ServicoForm
        onSubmit={(servico) => this.handleSubmitResource(servico)}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({servico, status, flashMessage}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      servico: servico.get('id'),
      error: status.getIn(['servico', 'error']),
      isLoading: status.getIn(['servico', 'isLoading']),
      shouldHideFlashMessage: flashMessage.get('isError'),
    }
  }
  return {
    error: status.getIn(['servico', 'error']),
    isLoading: status.getIn(['servico', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...servicoActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicoFormContainer)
