import React from 'react'
import { List } from 'semantic-ui-react'

const PiorStateReport = props => {

  const getStateData = state =>
  ({
    none: {
      icon: 'ban',
      label: 'Relatório Ausente',
      color: 'red'
    },
    done: {
      icon: 'check',
      label: 'Relatório Concluído',
      color: 'green'
    },
  }[state])

  const stateData = getStateData(props.state_report)
  return (
    <List.Item icon={stateData.icon} content={stateData.label} style={{color: stateData.color}}/>
  )
}

export default PiorStateReport
