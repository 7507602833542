import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { ContatoInstalacao } from '../../../containers'

const Contato = (props) => {
  const { instalacao } = props
  return (
    <div>
      <Switch>
        <Route
          exact path='/instalacoes/:instalacaoId/contatos'
          render={({match}) => (
            <ContatoInstalacao.Index
              instalacao={instalacao}
              match={match}
            />
          )}>
        </Route>
        <Route
          exact path='/instalacoes/:instalacaoId/contatos/new'
          render={({match}) => (
            <ContatoInstalacao.Form
              instalacao={instalacao}
              match={match}
            />
          )}
        />
        <Route
          exact path='/instalacoes/:instalacaoId/contatos/:id/edit'
          render={({match}) => (
            <ContatoInstalacao.Form
              instalacao={instalacao}
              match={match}
            />
          )}
        />
        <Route
          exact path='/instalacoes/:instalacaoId/contatos/:id'
          render={({match}) => (
            <ContatoInstalacao.Show
              instalacao={instalacao}
              match={match}
            />
          )}
        />
      </Switch>
    </div>
  )
}

Contato.propTypes = {
  instalacao: PropTypes.object.isRequired,
}

export default Contato
