import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'

const GeradorShow = (props) => {
  if (props.gerador) {
    return (
      <CRUDShow
        title={props.gerador.nome}
        icon='rocket'
        onEditResource={() => props.onEditResource(props.gerador.id)}
        onDeleteResource={() => props.onDeleteResource(props.gerador.id)}>
        <ul>
          <li>Nome: {props.gerador.nome}</li>
          <li>Marca: {props.gerador.marca}</li>
          <li>Modelo: {props.gerador.modelo}</li>
          <li>Eficiência: {props.gerador.eficiencia}</li>
          <li>Potência: {props.gerador.potencia}</li>
          <li>Potência Ativa: {props.gerador.potencia_ativa}</li>
          <li>Potência cv: {props.gerador.potencia_cv}</li>
          <li>Combustível: {props.gerador.combustivel}</li>
          <li>Unidade combustível: {props.gerador.unidade_combustivel}</li>
          <li>Consumo Combustível/Hora: {props.gerador.consumo_combustivel}</li>
        </ul>
      </CRUDShow>
    )
  }

  return (null)
}

GeradorShow.propTypes = {
  gerador: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
}

export default GeradorShow
