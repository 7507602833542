import React from 'react'
import PropTypes from 'prop-types'

import { reduxForm, Field } from 'redux-form/immutable'

import { maskFloat, maskPercent } from '../../utils/masks'
import { Button, Form, FormField, Grid, Header, Icon, Popup, Table } from 'semantic-ui-react'
import { SemanticInputMask } from '../FormComponents'

const Botao = <Form.Button label='&nbsp;' type='button' tabIndex='-1'
  floated='right' icon color='blue' onClick={() => { }}>
  <Icon name='calculator' />
</Form.Button>

const CalculadoraConsumo = ({ bandeira, tributos, isOpen, onSelectValue, onClose, onOpen, consumo, icms }) => {

  if (!bandeira || !tributos) return (null)

  return (
    <Popup flowing hideOnScroll
      open={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      trigger={Botao}
      on='click'
      position='top right'>
      <Grid centered divided columns={3} style={{ width: '600px' }}>
        <Grid.Column textAlign='center'>
          <Header as='h3'>Bandeira</Header>
          <Header as='h2' icon>
            <Icon name='flag' size='huge' style={{ color: bandeira.get('cor') }} />
            {bandeira.get('descricao')}
            <Header.Subheader>{maskFloat.parse(bandeira.get('valor'))} R$/kWh</Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Header as='h3'>Tributos</Header>
          <Table compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Tributo</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>Alíquota</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>ICMS</Table.Cell>
                <Table.Cell textAlign='right'>{maskPercent.parse(icms, 2)}%</Table.Cell>
              </Table.Row>
              {tributos.map((tributo, index) =>
                <Table.Row key={index}>
                  <Table.Cell>{tributo.get('descricao')}</Table.Cell>
                  <Table.Cell textAlign='right'>{maskPercent.parse(tributo.get('aliquota'), 2)}%</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column>
          <Header as='h3' textAlign='center'>Cálculo do Consumo</Header>
          <Form>
            <FormField>
              <label htmlFor='valor'>Valor Informado na Fatura</label>
              <Field
                name='valor'
                label='R$'
                style={{ width: '80%' }}
                component={SemanticInputMask}
                mask={maskFloat.mask()}
                placeholder={maskFloat.placeholder}
              />
            </FormField>
            <Button fluid color='blue' onClick={() => onSelectValue(consumo)}>Utilizar {consumo} kWh</Button>
          </Form>
        </Grid.Column>
      </Grid>
    </Popup>
  )
}

CalculadoraConsumo.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onSelectValue: PropTypes.func,
  onSubmit: PropTypes.func,
  bandeira: PropTypes.object,
  tributos: PropTypes.array,
  icms: PropTypes.string,
  consumo: PropTypes.number,
}

export default reduxForm({ form: 'CalculadoraConsumo' })(CalculadoraConsumo)
