import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_CONTATO = 'LOADING_CONTATO'
export function loadingContato () {
  return {
    type: LOADING_CONTATO,
  }
}

export const LOADING_CONTATO_SUCCESS = 'LOADING_CONTATO_SUCCESS'
export function loadingContatoSuccess (contato) {
  return {
    type: LOADING_CONTATO_SUCCESS,
    contato,
  }
}

export const LOADING_CONTATO_FAILURE = 'LOADING_CONTATO_FAILURE'
export function loadingContatoFailure (error) {
  return {
    type: LOADING_CONTATO_FAILURE,
    error,
  }
}

export const DESTROYING_CONTATO_SUCCESS = 'DESTROYING_CONTATO_SUCCESS'
export function destroyingContatoSuccess (contatoId) {
  return {
    type: DESTROYING_CONTATO_SUCCESS,
    contatoId,
  }
}

const initialState = Map({})

export function fetchMultipleContato () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContato())
      const multipleContato = await callAPI('/contatos')
      dispatch(loadingContatoSuccess(multipleContato))
      return multipleContato
    } catch (e) {
      dispatch(loadingContatoFailure(e))
      return false
    }
  }
}

export function fetchContato (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContato())
      const contato = await callAPI(`/contatos/${id}`)
      dispatch(loadingContatoSuccess(contato))
      return contato
    } catch (e) {
      dispatch(loadingContatoFailure(e))
      return false
    }
  }
}

export function createContato (contato) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContato())
      const newContato = await callAPI('/contatos', 'POST', contato)
      dispatch(loadingContatoSuccess(newContato))
      return newContato
    } catch (e) {
      dispatch(loadingContatoFailure(e))
      return false
    }
  }
}

export function updateContato (id, contato) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContato())
      const newContato = await callAPI(`/contatos/${id}`, 'PUT', contato)
      dispatch(loadingContatoSuccess(newContato))
      return newContato
    } catch (e) {
      dispatch(loadingContatoFailure(e))
      return false
    }
  }
}

export function destroyContato (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContato())
      await callAPI(`/contatos/${id}`, 'DELETE')
      dispatch(destroyingContatoSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingContatoFailure(e))
      return false
    }
  }
}

export default function contato (state = initialState, action) {
  switch (action.type) {

    case LOADING_CONTATO_SUCCESS : {
      const { contato } = action
      if (contato.constructor === Array) {
        state = initialState
        contato.forEach((c) => (
          state = state.merge({[c.id]: c})
        ))
        return state
      }
      return state.merge({[contato.id]: contato})
    }

    case DESTROYING_CONTATO_SUCCESS :
      return state.delete(action.contatoId.toString())

    default :
      return state
  }
}
