import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as pldMedioActionCreators from '../../redux/modules/pldMedio'
import * as pldMedioSelectionActionCreators from '../../redux/modules/pldMedioSelection'
import * as submercadoActionCreators from '../../redux/modules/submercado'
import * as dataImporterActionCreators from '../../redux/modules/dataImporter'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { PldMedio } from '../../components/PldMedio'
import { arrayToCSV } from '../../utils/arrayToCSV'
import { maskCurrency } from '../../utils/masks'

class PldMedioContainer extends Component {

  static propTypes = {
    history: PropTypes.object,

    multiplePldMedio: PropTypes.object,
    fetchMultiplePldMedio: PropTypes.func,

    multipleSubmercado: PropTypes.object,
    fetchMultipleSubmercado: PropTypes.func,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,

    isLoading: PropTypes.bool,

    updatePldMedioSelection: PropTypes.func,
    updateImportData: PropTypes.func,

    year: PropTypes.number,
    years: PropTypes.array,
  }

  async componentDidMount () {
    this.props.fetchMultipleSubmercado()
    this.fetchData()
  }

  componentDidUpdate (prevProps) {
    const { year } = this.props
    if (prevProps.year !== year) {
      this.fetchData()
    }
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  fetchData = async () => {
    await this.props.fetchMultiplePldMedio(
      this.props.year,
    )
  }

  handleChange = (event) => {
    this.props.updatePldMedioSelection(
      event.target.name,
      event.target.value,
    )
  }

  handleEditResource = () => {
    this.props.history.push('/indicativos/medios/edit')
  }

  parserCallback = (parsedData) => {
    return parsedData.data.reduce((acc, curr) => {
      acc = acc.concat(this.getSubmercadoFromRow(curr, 'N'))
      acc = acc.concat(this.getSubmercadoFromRow(curr, 'NE'))
      acc = acc.concat(this.getSubmercadoFromRow(curr, 'S'))
      acc = acc.concat(this.getSubmercadoFromRow(curr, 'SE'))
      return acc
    }, [])
  }

  getSubmercadoFromRow = (row, submercado) => {
    return {
      ano: row['Ano'],
      mes: row['Mês'],
      semana: row['Semana'],
      submercado_id: this.props.multipleSubmercado.find(s => s.get('nome') === submercado).get('id'),
      patamar_pesado: maskCurrency.unmask(row[`Pesado ${submercado}`]),
      patamar_medio: maskCurrency.unmask(row[`Médio ${submercado}`]),
      patamar_leve: maskCurrency.unmask(row[`Leve ${submercado}`]),
    }
  }

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multiplePldMedio.toList().toJSON(),
      'pldMedio',
      ['ano', 'mes', 'submercado.nome', 'preco']
    )
  }

  render () {
    return (
      <PldMedio
        year={this.props.year}
        years={this.props.years}
        isLoading={this.props.isLoading}
        multiplePldMedio={this.props.multiplePldMedio}
        multipleSubmercado={this.props.multipleSubmercado}
        onChange={this.handleChange}
        onEditResource={this.handleEditResource}
        onExportToCSV={this.handleExportToCSV}
      />
    )
  }
}

function mapStateToProps ({ pldMedio, pldMedioSelection, submercado, status, flashMessage }) {
  return {
    multiplePldMedio: pldMedio,
    error: status.getIn(['pldMedio', 'error']),
    isLoading: status.getIn(['pldMedio', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
    multipleSubmercado: submercado,

    year: pldMedioSelection.get('year'),
    years: pldMedioSelection.get('years'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...pldMedioActionCreators,
    ...pldMedioSelectionActionCreators,
    ...submercadoActionCreators,
    ...dataImporterActionCreators,
    ...flashMessageAcionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PldMedioContainer)
