import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, FieldArray, formValueSelector } from 'redux-form/immutable'
import { Button, Dropdown, Form, Grid, Header, Icon, Menu, Table } from 'semantic-ui-react'
import { FlashMessageContainer } from '../../containers'
import RenderForm from './RenderForm'

const selector = formValueSelector('PldMedioForm')

let PldMedioForm = (props) => {
  return (
    <Form onSubmit={props.handleSubmit} loading={props.loading || props.isLoading}>
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as='h1' dividing>
              <Icon name='calendar outline' />
              <Header.Content>
                Alteração de PLD - Preço Médio (R$/MWh)
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={16}>
            <Menu secondary>
              <Menu.Menu position='left'>
                <Menu.Item fitted>
                  <Form as='div'>
                    <Form.Group inline>
                      <Form.Field>
                        <label>Ano</label>
                        <Dropdown compact
                          onChange={(event, data) => props.handleChange({
                            target: {
                              name: 'year',
                              value: data.value
                            }
                          })}
                          label='Ano'
                          options={props.years}
                          placeholder='Ano'
                          selection
                          value={props.year}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Menu.Item>
              </Menu.Menu>
              <Menu.Menu position='right'>
                <Menu.Item fitted>
                  <Button icon
                    type='button'
                    labelPosition='left'
                    disabled={props.loading}
                    loading={props.loading}
                    onClick={(e) => {
                      e.preventDefault()
                      props.history.goBack()
                    }}>
                    <Icon name='arrow left' />{' '}Voltar
                  </Button>
                </Menu.Item>
                <Menu.Item fitted>
                  <Button icon
                    type='submit'
                    color='green'
                    disabled={props.loading}
                    loading={props.loading}
                    labelPosition='left'>
                    <Icon name='check' />{' '}Salvar
                  </Button>
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </Grid.Column>
          <Grid.Column width={16} style={{ marginBottom: '1em' }}>
            <FlashMessageContainer />
          </Grid.Column>
          <Grid.Column width={16}>
            <Table celled striped selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1} textAlign='right'>Período/Submercado</Table.HeaderCell>
                  {props.submercados.map((submercado, index) => (
                    <Table.HeaderCell key={index} width={1} textAlign='right'>{submercado.descricao}</Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>

              <FieldArray
                name='pldMedio'
                component={RenderForm}
                loading={props.isLoading}
              />
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  )
}

PldMedioForm = connect(
  (state) => {
    let pldMedio = selector(state, 'pldMedio')
    let submercados = []

    if (pldMedio) {
      submercados = pldMedio.first().get('precos').reduce((acc, curr) => {
        acc = acc.concat({
          id: curr.getIn(['submercado', 'id']),
          nome: curr.getIn(['submercado', 'nome']),
          descricao: curr.getIn(['submercado', 'descricao']),
        })
        return acc
      }, [])
    }

    return ({ submercados })
  }
)(PldMedioForm)

PldMedioForm.propTypes = {
  isLoading: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  history: PropTypes.object,

  year: PropTypes.number,
  years: PropTypes.array,
  precos: PropTypes.array,
  submercados: PropTypes.array,
}

export default reduxForm({form: 'PldMedioForm'})(PldMedioForm)
