import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_VIGENCIA_PARAMETRO = 'LOADING_VIGENCIA_PARAMETRO'
export function loadingVigenciaParametro () {
  return {
    type: LOADING_VIGENCIA_PARAMETRO,
  }
}

export const LOADING_VIGENCIA_PARAMETRO_SUCCESS = 'LOADING_VIGENCIA_PARAMETRO_SUCCESS'
export function loadingVigenciaParametroSuccess (vigenciaParametro) {
  return {
    type: LOADING_VIGENCIA_PARAMETRO_SUCCESS,
    vigenciaParametro,
  }
}

export const LOADING_VIGENCIA_PARAMETRO_FAILURE = 'LOADING_VIGENCIA_PARAMETRO_FAILURE'
export function loadingVigenciaParametroFailure (error) {
  return {
    type: LOADING_VIGENCIA_PARAMETRO_FAILURE,
    error,
  }
}

export const DESTROYING_VIGENCIA_PARAMETRO_SUCCESS = 'DESTROYING_VIGENCIA_PARAMETRO_SUCCESS'
export function destroyingVigenciaParametroSuccess (vigenciaParametroId) {
  return {
    type: DESTROYING_VIGENCIA_PARAMETRO_SUCCESS,
    vigenciaParametroId,
  }
}

const initialState = Map({})

export function fetchMultipleVigenciaParametro (instalacaoId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaParametro())
      const multipleVigenciaParametro = await callAPI(`/instalacoes/${instalacaoId}/parametros`)
      dispatch(loadingVigenciaParametroSuccess(multipleVigenciaParametro))
      return multipleVigenciaParametro
    } catch (e) {
      dispatch(loadingVigenciaParametroFailure(e))
      return false
    }
  }
}

export function fetchLastVigenciaParametro (instalacaoId, data = '') {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaParametro())
      const vigenciaParametro = await callAPI(`/instalacoes/${instalacaoId}/parametros/last?data=${data}`)
      dispatch(loadingVigenciaParametroSuccess(vigenciaParametro))
      return vigenciaParametro
    } catch (e) {
      dispatch(loadingVigenciaParametroFailure(e))
      return false
    }
  }
}

export function fetchVigenciaParametro (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaParametro())
      const vigenciaParametro = await callAPI(`/parametros/${id}`)
      dispatch(loadingVigenciaParametroSuccess(vigenciaParametro))
      return vigenciaParametro
    } catch (e) {
      dispatch(loadingVigenciaParametroFailure(e))
      return false
    }
  }
}

export function createVigenciaParametro (instalacaoId, vigenciaParametro) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaParametro())
      const newVigenciaParametro = await callAPI(`/instalacoes/${instalacaoId}/parametros`, 'POST', vigenciaParametro)
      dispatch(loadingVigenciaParametroSuccess(newVigenciaParametro))
      return newVigenciaParametro
    } catch (e) {
      dispatch(loadingVigenciaParametroFailure(e))
      return false
    }
  }
}

export function updateVigenciaParametro (id, vigenciaParametro) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaParametro())
      const newVigenciaParametro = await callAPI(`/parametros/${id}`, 'PUT', vigenciaParametro)
      dispatch(loadingVigenciaParametroSuccess(newVigenciaParametro))
      return newVigenciaParametro
    } catch (e) {
      dispatch(loadingVigenciaParametroFailure(e))
      return false
    }
  }
}

export function destroyVigenciaParametro (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaParametro())
      await callAPI(`/parametros/${id}`, 'DELETE')
      dispatch(destroyingVigenciaParametroSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingVigenciaParametroFailure(e))
      return false
    }
  }
}

export default function vigenciaParametro (state = initialState, action) {
  switch (action.type) {

    case LOADING_VIGENCIA_PARAMETRO_SUCCESS : {
      const { vigenciaParametro } = action
      if (vigenciaParametro.constructor === Array) {
        state = initialState
        vigenciaParametro.forEach((vigencia) => (
          state = state.merge({[vigencia.id]: vigencia})
        ))
        return state
      }
      return state.merge({[vigenciaParametro.id]: vigenciaParametro})
    }

    case DESTROYING_VIGENCIA_PARAMETRO_SUCCESS :
      return state.delete(action.vigenciaParametroId.toString())

    default :
      return state
  }
}
