import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Login } from '../../components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as sessionActionCreators from '../../redux/modules/session'

class LoginContainer extends Component {

  static propTypes = {
    createSession: PropTypes.func,
    history: PropTypes.object,
    session: PropTypes.object,
    isAuthenticated: PropTypes.bool,
  }

  login = async (credentials) => {
    const email = credentials.get('email')
    const password = credentials.get('password')
    if (await this.props.createSession(email, password)) {
      this.props.history.push('/')
    }
  }

  render () {
    return (
      <Login
        session={this.props.session}
        isAuthenticated={this.props.isAuthenticated}
        onSubmit={(credentials) => this.login(credentials)} />
    )
  }
}

function mapStateToProps ({ session, status }) {
  return {
    session,
    isAuthenticated: status.getIn(['session', 'isAuthenticated']),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(sessionActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
