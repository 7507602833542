import React from 'react'
import PropTypes from 'prop-types'

import { Label, Popup } from 'semantic-ui-react'
import moment from 'moment'

const Atrasada = ({ atrasada, ano, mes, data }) => {
  if (!atrasada) return (null)

  const dias = moment().diff(moment(data), 'days')

  if (dias && dias > 30) {
    return (
      <Popup wide='very' position='top right' flowing hoverable
        trigger={<Label size='small' color='yellow'>ATRASADA</Label>}>
        <span>A instalação está atrasada {dias} dia{dias > 1 ? 's' : '0'} desde a última fatura cadastrada ({ano}/{mes}).</span>
      </Popup>
    )
  }

  return (
    <Popup wide='very' position='top right' flowing hoverable
      trigger={<Label size='small' color='yellow'>ATRASADA</Label>}>
      <span>A instalação não possui nenhuma fatura cadastrada.</span>
    </Popup>
  )
}

Atrasada.propTypes = {
  atrasada: PropTypes.bool,
  ano: PropTypes.number,
  mes: PropTypes.number,
  data: PropTypes.string,
}

export default Atrasada
