import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Container, Divider } from 'semantic-ui-react'
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts'
import {
  COLORS,
  capitalize,
  formatAsUnitDecimals,
} from './utils/ChartUtils'

const fieldsNameMapping = {
  atual: {
    label: '12 meses',
    unit: ' MWm',
  },
  passado: {
    label: '24 meses',
    unit: ' MWm',
  },
  retrasado: {
    label: '36 meses',
    unit: ' MWm',
  },
}

const areaOpacity = 0.2

const MegawattMedioChart = ({ header, data, height, showAnimations }) => {
  if (!data) return null

  let ano
  const preparedData = data.reverse().map(d => {
    if (!ano || d.ano > ano) ano = d.ano
    const prepared = Object.assign({}, d)
    prepared._labelFaturaMes = capitalize(moment({y: d.ano, M: d.mes - 1}).format('MMM'))
    return prepared
  })

  ano = ano || moment().year()

  const temPassado = preparedData.some(r => r.passado > 0.0)
  const temRetrasado = preparedData.some(r => r.retrasado > 0.0)

  return (
    <Container fluid>
      <Divider horizontal>{header}</Divider>
      <ResponsiveContainer height={height}>
        <AreaChart data={preparedData}>
          <XAxis dataKey="_labelFaturaMes" type="category" interval={0} tickMargin={5} padding={{right: 10}}/>
          <YAxis
            domain={[0, 'dataMax']}
            padding={{top: 10}}
            tickFormatter={value => formatAsUnitDecimals(' MWm', 3)(value)}
            type="number" />
          <Tooltip isAnimationActive={false} formatter={formatAsUnitDecimals(' MWm', 3)} />
          <Legend/>
          <Area
            dataKey="atual"
            fill={COLORS.DARK.DEFAULT}
            fillOpacity={areaOpacity}
            legendType="line"
            name={fieldsNameMapping['atual'].label}
            stroke={COLORS.DARK.DEFAULT}
            strokeWidth={3}
            type="monotone"
            isAnimationActive={showAnimations}
          />
          {temPassado && (
            <Area
              dataKey="passado"
              fill={COLORS.DEFAULT}
              fillOpacity={areaOpacity}
              legendType="line"
              name={fieldsNameMapping['passado'].label}
              stroke={COLORS.DEFAULT}
              strokeWidth={2}
              strokeDasharray="6"
              type="monotone"
              isAnimationActive={showAnimations}
            />
          )}
          {temRetrasado && (
            <Area
              dataKey="retrasado"
              fill={COLORS.LIGHT.DEFAULT}
              fillOpacity={areaOpacity}
              legendType="line"
              name={fieldsNameMapping['retrasado'].label}
              stroke={COLORS.LIGHT.DEFAULT}
              strokeWidth={1}
              strokeDasharray="3"
              type="monotone"
              isAnimationActive={showAnimations}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </Container>
  )
}

MegawattMedioChart.propTypes = {
  header: PropTypes.string,
  data: PropTypes.array,
  height: PropTypes.number,
  showAnimations: PropTypes.bool,
}

MegawattMedioChart.defaultProps = {
  header: '',
  height: 350,
  showAnimations: true,
}

export default MegawattMedioChart
