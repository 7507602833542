import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { Cenario } from '../../../containers'

const Cenarios = (props) => {
  const { instalacao } = props
  return (
    <div>
      <Switch>
        <Route exact path='/instalacoes/:instalacaoId/cenarios'
          render={({match}) => (
            <Cenario.Index
              instalacao={instalacao}
              match={match}
            />
          )}>
        </Route>
        <Route exact path='/instalacoes/:instalacaoId/cenarios/new'
          render={({match}) => (
            <Cenario.Form
              instalacao={instalacao}
              match={match}
            />
          )}
        />
        <Route exact path='/instalacoes/:instalacaoId/cenarios/:id/edit'
          render={({match}) => (
            <Cenario.Form
              instalacao={instalacao}
              match={match}
            />
          )}
        />
        <Route exact path='/instalacoes/:instalacaoId/cenarios/:id'
          render={({match}) => (
            <Cenario.Show
              instalacao={instalacao}
              match={match}
            />
          )}
        />
      </Switch>
    </div>
  )
}

Cenarios.propTypes = {
  instalacao: PropTypes.object.isRequired,
}

export default Cenarios
