import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { VigenciaParametro } from '../../components'
import * as vigenciaParametroActionCreators from '../../redux/modules/vigenciaParametro'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class VigenciaParametroContainer extends Component {

  static propTypes = {
    destroyVigenciaParametro: PropTypes.func,
    error: PropTypes.array,
    fetchMultipleVigenciaParametro: PropTypes.func,
    filterText: PropTypes.string,
    hideFlashMessage: PropTypes.func,
    history: PropTypes.object,
    instalacao: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    multipleVigenciaParametro: PropTypes.object,
    showErrorFlashMessage: PropTypes.func,
    showSuccessfulFlashMessage: PropTypes.func,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    updateSort: PropTypes.func.isRequired,
  }

  async componentDidMount () {
    const { instalacao } = this.props
    await Promise.all([
      this.props.fetchMultipleVigenciaParametro(instalacao.id),
    ])
  }

  handleRedirectToNew = (instalacaoId) => {
    this.props.history.push(`/instalacoes/${instalacaoId}/parametros/new`)
  }

  handleEditResource = (instalacaoId, id) => {
    this.props.history.push(`/instalacoes/${instalacaoId}/parametros/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyVigenciaParametro(id)
      ? this.props.showSuccessfulFlashMessage('', 'destroy')
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  handleSort = column => () => this.props.updateSort('vigenciaParametro', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('vigenciaParametro', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleVigenciaParametro.toList().toJSON(),
      'vigenciaParametro',
      [
        'id'
      ]
    )
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <VigenciaParametro
        instalacao={this.props.instalacao}
        onRedirectToNew={() => this.handleRedirectToNew(this.props.instalacao.id)}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        multipleVigenciaParametro={this.props.multipleVigenciaParametro}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV}
        isLoading={this.props.isLoading}
      />
    )
  }
}

function mapStateToProps ({vigenciaParametro, status, sort, filter}, {instalacao}) {
  const sortColumn = sort.getIn(['vigenciaParametro', 'sortColumn']) || 'starts_at'
  const sortDirection = sort.getIn(['vigenciaParametro', 'sortDirection']) || 'ascending'
  const filterText = filter.getIn(['vigenciaParametro', 'filterText']) || ''

  let map = filterMap(vigenciaParametro, ['starts_at'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleVigenciaParametro: map.filter(t => t.get('instalacao_id') === instalacao.id),
    error: status.getIn(['vigenciaParametro', 'error']),
    isLoading: status.getIn(['vigenciaParametro', 'isLoading']),
    sortColumn,
    sortDirection,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...flashMessageAcionCreators,
    ...vigenciaParametroActionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VigenciaParametroContainer))
