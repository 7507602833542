import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Header, List, Segment } from 'semantic-ui-react'
import { CRUDShow } from '../CRUD'
import { formatDate } from '../../utils/formats'

import { multipleBaseCalculo } from '../../utils/enums/baseCalculo'

const ParametroConcessionariaShow = (props) => {
  if (!props.parametroConcessionaria) return (null)

  const { parametroConcessionaria } = props
  if (parametroConcessionaria) {
    return (
      <CRUDShow
        title={'Detalhe de Parâmetros da Concessionária'}
        titleAs={'h3'}
        icon='cogs'
        padded={false}
        isLoading={props.isLoading}
        onEditResource={() => props.onEditResource(props.parametroConcessionaria.id)}
        onDeleteResource={() => props.onDeleteResource(props.parametroConcessionaria.id)}>
        <Header as='h2' block content={formatDate(props.parametroConcessionaria.starts_at)} subheader='Início da Vigência' />
        <Segment className='customSegment'>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <List relaxed size='small' className='customList'>
                  <List.Item>
                    <List.Icon size='large' />
                    <List.Content>
                      <List.Header>Casas Decimais</List.Header>
                      <List.Description>Durante o cálculo de faturas, utiliza <strong>{props.parametroConcessionaria.decimal_places}</strong> casas após a vírgula nos arredondamentos.</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon size='large' />
                    <List.Content>
                      <List.Header>Base de Cálculo ICMS</List.Header>
                      <List.Description>{multipleBaseCalculo.getIn([`${props.parametroConcessionaria.base_calculo_icms}`, 'nome'])}.</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon size='large' />
                    <List.Content>
                      <List.Header>Base de Cálculo PIS/COFINS</List.Header>
                      <List.Description>{multipleBaseCalculo.getIn([`${props.parametroConcessionaria.base_calculo_pis_cofins}`, 'nome'])}.</List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </CRUDShow>
    )
  }
}

ParametroConcessionariaShow.propTypes = {
  parametroConcessionaria: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default ParametroConcessionariaShow
