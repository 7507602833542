import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { ContatoForm } from '../../components'
import * as contatoInstalacaoActionCreators from '../../redux/modules/contatoInstalacao'
import * as funcaoActionCreators from '../../redux/modules/funcao'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

import { mapToDropdown } from '../../utils/parse'

class ContatoInstalacaoFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    initialize: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    instalacao: PropTypes.object,

    fetchContatoInstalacao: PropTypes.func.isRequired,
    createContatoInstalacao: PropTypes.func.isRequired,
    updateContatoInstalacao: PropTypes.func.isRequired,

    funcaoDropdown: PropTypes.array.isRequired,
    fetchMultipleFuncao: PropTypes.func.isRequired,

    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,

    error: PropTypes.object,
  }

  async componentDidMount () {
    await this.props.fetchMultipleFuncao()
    if (this.props.id) {
      const contato = await this.props.fetchContatoInstalacao(this.props.id)
      this.props.initialize('ContatoForm', { contato })
    }
  }

  handleUpdate = async (contatoInstalacao) => {
    const instalacaoId = this.props.instalacao.id

    if (await this.props.updateContatoInstalacao(this.props.id, contatoInstalacao)) {
      this.props.showSuccessfulFlashMessage('', 'update')
      this.props.history.push(`/instalacoes/${instalacaoId}/contatos`)
    } else {
      this.props.showErrorFlashMessage('', 'update', this.props.error)
    }
  }

  handleCreate = async (contatoInstalacao) => {
    const instalacaoId = this.props.instalacao.id

    if (await this.props.createContatoInstalacao(instalacaoId, contatoInstalacao)) {
      this.props.showSuccessfulFlashMessage('', 'create')
      this.props.history.push(`/instalacoes/${instalacaoId}/contatos`)
    } else {
      this.props.showErrorFlashMessage('', 'create', this.props.error)
    }
  }

  handleSubmitResource = async (contatoInstalacao) => {
    if (this.props.id) {
      this.handleUpdate(contatoInstalacao)
    } else {
      this.handleCreate(contatoInstalacao)
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <ContatoForm titleAs='h3' noPadded
        funcaoDropdown={this.props.funcaoDropdown}
        onSubmit={(contatoInstalacao) => this.handleSubmitResource(contatoInstalacao)} />
    )
  }
}

function mapStateToProps ({contatoInstalacao, funcao, status, flashMessage}, {match}) {
  const { id } = match.params

  const funcaoDropdown = mapToDropdown(
    funcao,
    e => e.get('id'),
    e => e.get('nome')
  )

  if (id) {
    return {
      id,
      contatoInstalacao: contatoInstalacao.get('id'),
      funcaoDropdown,
      error: status.getIn(['contatoInstalacao', 'error']),
      shouldHideFlashMessage: flashMessage.get('isError'),
    }
  }
  return {
    funcaoDropdown,
    error: status.getIn(['contatoInstalacao', 'error']),
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...contatoInstalacaoActionCreators,
    ...funcaoActionCreators,
    ...flashMessageActionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContatoInstalacaoFormContainer))
