import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { BandeiraForm } from '../../components'
import * as bandeiraActionCreators from '../../redux/modules/bandeira'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

class BandeiraFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    fetchBandeira: PropTypes.func.isRequired,
    createBandeira: PropTypes.func.isRequired,
    updateBandeira: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    if (this.props.id) {
      const bandeira = await this.props.fetchBandeira(this.props.id)
      this.props.initialize('BandeiraForm', {bandeira})
    }
  }

  handleSubmitResource = async (bandeira) => {
    if (this.props.id) {
      if (await this.props.updateBandeira(this.props.id, bandeira)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push('/gestao_bandeiras/bandeiras')
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createBandeira(bandeira)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/gestao_bandeiras/bandeiras')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <BandeiraForm
        onSubmit={(bandeira) => this.handleSubmitResource(bandeira)}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({bandeira, status, flashMessage}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      bandeira: bandeira.get('id'),
      error: status.getIn(['bandeira', 'error']),
      isLoading: status.getIn(['bandeira', 'isLoading']),
      shouldHideFlashMessage: flashMessage.get('isError'),
    }
  }
  return {
    error: status.getIn(['bandeira', 'error']),
    isLoading: status.getIn(['bandeira', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...bandeiraActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BandeiraFormContainer)
