import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import moment from 'moment'
import { Container, Divider } from 'semantic-ui-react'
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
} from 'recharts'
import {
  COLORS,
  capitalize,
  formatAsLocalizedNumber,
  formatAsCurrency,
  formatAsIndicador,
  formatAsPercentageCalculated,
} from './utils/ChartUtils'
import CustomLineDotLabelBox from './utils/CustomLineDotLabelBox'
import LabelListInsideBottomCenterLabel from './utils/LabelListInsideBottomCenterLabel'

import ComentarioSimulacaoContainer from '../../../containers/Relatorio/ComentarioSimulacaoContainer'

const fieldsNameMapping = {
  total_simulado: 'Total Simulado',
  total_realizado: 'Total Realizado',
  percentual: 'Vantagem obtida',
}

const fieldFormatterMapping = {
  total_simulado: formatAsCurrency,
  total_realizado: formatAsCurrency,
  percentual: formatAsPercentageCalculated,
}

const SimulacaoChart = ({ id, cenarioId, header, data, height, print }) => {

  const showAnimations = !print
  const preparedData = data.map(d => {
    const prepared = {}
    prepared.total_simulado = get(d, 'total_simulado', 0)
    prepared.total_realizado = get(d, 'total_realizado', 0)
    prepared._labelFaturaMes = capitalize(moment({y: d.ano, M: d.mes - 1}).format('MMM/YY'))
    prepared._parsedPercentual = prepared.total_realizado ? (prepared.total_simulado / prepared.total_realizado - 1) * 100 : 0.0
    return prepared
  })

  return (
    <Container fluid>
      <Divider horizontal>{header}</Divider>
      <ComentarioSimulacaoContainer
        id={id}
        cenarioId={cenarioId}
        print={print}
      />
      <ResponsiveContainer height={height}>
        <ComposedChart data={preparedData} barGap={0}>
          <XAxis dataKey="_labelFaturaMes" type="category" interval={0} tickLine={false}/>
          <YAxis
            yAxisId="left"
            type="number"
            tickFormatter={formatAsIndicador('R$ ', true)}
            padding={{ top: 10 }}
            width={100}
          />
          <YAxis
            yAxisId="right"
            type="number"
            tickFormatter={value => formatAsPercentageCalculated(value)}
            stroke={COLORS.DARK.DEFAULT}
            orientation="right"
            width={40}
            domain={['dataMin - 3', 'dataMax + 3']}
            padding={{ bottom: 40 }}
          />
          <Tooltip />
          <Legend/>
          <Bar
            dataKey="total_realizado"
            yAxisId="left"
            fill={COLORS.ELECTRIC.LIGHTER.BASE}
            legendType="square"
            name={fieldsNameMapping['total_realizado']}
            formatter={fieldFormatterMapping['total_realizado']}
            isAnimationActive={showAnimations}
          >
            <LabelList dataKey="total_realizado" position="center" angle={-90} formatter={formatAsLocalizedNumber} content={LabelListInsideBottomCenterLabel}/>
          </Bar>
          <Bar
            dataKey="total_simulado"
            yAxisId="left"
            fill={COLORS.ELECTRIC.LIGHT.BASE}
            legendType="square"
            name={fieldsNameMapping['total_simulado']}
            formatter={fieldFormatterMapping['total_simulado']}
            isAnimationActive={showAnimations}
          >
            <LabelList className='inverted' dataKey="total_simulado" position="center" angle={-90} formatter={formatAsLocalizedNumber} content={LabelListInsideBottomCenterLabel}/>
          </Bar>
          <Line
            type="linear"
            dataKey="_parsedPercentual"
            yAxisId="right"
            stroke={COLORS.ELECTRIC.GREEN}
            legendType="line"
            name={fieldsNameMapping['percentual']}
            formatter={fieldFormatterMapping['percentual']}
            dot={<CustomLineDotLabelBox formatter={formatAsPercentageCalculated} fill="#fff" negativeStroke={COLORS.NEUTRAL} positiveStroke={COLORS.ELECTRIC.GREEN} />}
            activeDot={false}
            isAnimationActive={showAnimations}
            strokeWidth={0}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Container>
  )
}

SimulacaoChart.propTypes = {
  id: PropTypes.number,
  header: PropTypes.string,
  data: PropTypes.object,
  height: PropTypes.number,
  showAnimations: PropTypes.bool,
}

SimulacaoChart.defaultProps = {
  header: '',
  data: {},
  height: 350,
  showAnimations: true,
}

export default SimulacaoChart
