import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { CRUDShow } from '../CRUD'
import { Grid, Icon, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { BandeirasRouter, VigenciasRouter, ValoresRouter } from './routers'

const BandeiraOverview = (props) => {
  const { pane } = props
  return (
    <CRUDShow noToolbar noMessage title='Gestão de Bandeiras' icon='flag'>
      <Grid>
        <Grid.Column width={3}>
          <Menu secondary vertical fluid size='huge'>
            <Menu.Item as={Link}
              active={pane === 'bandeiras'}
              to='/gestao_bandeiras/bandeiras'
              key='bandeiras'>
              <Icon name='flag' />
              Bandeiras
            </Menu.Item>
            <Menu.Item as={Link}
              active={pane === 'vigencias'}
              to='/gestao_bandeiras/vigencias'
              key='vigencias'>
              <Icon name='calendar' />
              Vigências
            </Menu.Item>
            <Menu.Item as={Link}
              active={pane === 'valores'}
              to='/gestao_bandeiras/valores'
              key='valores'>
              <Icon name='money' />
              Valores
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column width={13}>
          <Switch>
            <Route exact path='/gestao_bandeiras/bandeiras/:id?/:action?' render={BandeirasRouter} />
            <Route exact path='/gestao_bandeiras/vigencias/:id?/:action?' render={VigenciasRouter} />
            <Route exact path='/gestao_bandeiras/valores/:id?/:action?' render={ValoresRouter} />
          </Switch>
        </Grid.Column>
      </Grid>
    </CRUDShow>
  )
}

BandeiraOverview.propTypes = {
  pane: PropTypes.string,
}

export default BandeiraOverview
