import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form/immutable'
import { change } from 'redux-form/immutable'
import { bindActionCreators, compose } from 'redux'
import { withRouter } from 'react-router-dom'
import withViewMode from '../../utils/withViewMode'

import Analise from '../../components/Relatorio/Graficos/Analise'
import * as relatorioActionCreators from '../../redux/modules/relatorio'

class AnaliseContainer extends Component {
  state = {
    editMode: false
  }

  static propTypes = {
    id: PropTypes.number,
    field: PropTypes.string,
    help: PropTypes.string,
    isLoading: PropTypes.bool,
    analise: PropTypes.string,
    print: PropTypes.bool,
    resetable: PropTypes.bool,

    match: PropTypes.object,

    initialize: PropTypes.func,
    change: PropTypes.func,
    updateRelatorio: PropTypes.func,
    resetRelatorio: PropTypes.func,
  }

  async componentDidMount () {

    const { field, analise } = this.props

    const relatorio = {
      [field]: analise
    }

    this.props.initialize(`Analise_${field}`, { relatorio })
  }


  handleToggleMode = () => {
    this.setState({ editMode: !this.state.editMode })
  }

  handleSubmitResource = async (resource) => {
    const { match } = this.props
    const id = match && match.params && match.params.id
    if (await this.props.updateRelatorio(id, resource)) {
      this.handleToggleMode()
    } else {
      alert('Não foi possível salvar. Tente novamente.')
    }
  }

  handleResetResource = async () => {
    const { match } = this.props
    const id = match && match.params && match.params.id

    const resource = await this.props.resetRelatorio(id, this.props.field)
    if (resource) {
      this.handleToggleMode()
      this.props.change(`Analise_${this.props.field}`, `relatorio.${this.props.field}`, resource[this.props.field])
    } else {
      alert('Não foi possível salvar. Tente novamente.')
    }
  }

  render() {
    const { field, help, resetable, match, analise } = this.props
    const print = match.url.endsWith('/print')

    return (
      <Analise
        analise={analise}
        form={`Analise_${field}`}
        field={field}
        help={help}
        print={print}
        isEditMode={this.state.editMode}
        onToggleMode={this.handleToggleMode}
        onSubmit={resource => this.handleSubmitResource(resource)}
        onReset={resetable ? () => this.handleResetResource() : null}
        isLoading={this.props.isLoading}
      />
    )
  }
}

const mapStateToProps = ({ relatorio }, { relatorio: { id }, field }) => {
  return {
    analise: relatorio && relatorio.getIn && relatorio.getIn([`${id}`, field]),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...relatorioActionCreators,
    ...{change},
    ...{initialize},
  }, dispatch)
}

export default compose(
  withViewMode,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AnaliseContainer)
