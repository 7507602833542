import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField, Table } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput, SemanticDropdown } from '../FormComponents'

import { required, maxLengthNome } from '../../utils/validations'

import { mapToDropdown } from '../../utils/parse'

const EnquadramentoForm = (props) => {
  const tipoDemandaDropdown = mapToDropdown(props.multipleTipoDemanda, e => e.get('id'), e => e.get('nome'))
  const tipoTarifaDropdown = mapToDropdown(props.multipleTipoTarifa, e => e.get('id'), e => e.get('nome'))

  return (
    <CRUDForm
      title='Enquadramento'
      icon='object group'
      {...props}>
      <FormSection name='enquadramento'>
        <Form.Group>
          <FormField width={6}>
            <label htmlFor='modalidade'>Modalidade</label>
            <Field
              autoFocus
              name='modalidade'
              placeholder='Modalidade'
              validate={[required, maxLengthNome]}
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign='right'>Grupo</Table.HeaderCell>
              <Table.HeaderCell>Tarifa de Consumo</Table.HeaderCell>
              <Table.HeaderCell>Tarifa de Demanda</Table.HeaderCell>
              <Table.HeaderCell>Leitura de Demanda</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell textAlign='right'>A</Table.Cell>
              <Table.Cell><FormField width={16}>
                <Field fluid button floating labeled search icon='sort' className='icon'
                  name='consumo_grupo_a'
                  placeholder='Selecione'
                  validate={[required]}
                  options={tipoTarifaDropdown}
                  component={SemanticDropdown}
                  loading={props.loading}
                />
              </FormField></Table.Cell>
              <Table.Cell><FormField width={16}>
                <Field fluid button floating labeled search icon='sort' className='icon'
                  name='demanda_grupo_a'
                  placeholder='Selecione'
                  validate={[required]}
                  options={tipoTarifaDropdown}
                  component={SemanticDropdown}
                  loading={props.loading}
                />
              </FormField></Table.Cell>
              <Table.Cell><FormField width={16}>
                <Field fluid button floating labeled search icon='sort' className='icon'
                  name='tipo_demanda_a'
                  placeholder='Selecione'
                  validate={[required]}
                  options={tipoDemandaDropdown}
                  component={SemanticDropdown}
                  loading={props.loading}
                />
              </FormField></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell textAlign='right'>B</Table.Cell>
              <Table.Cell><FormField width={16}>
                <Field fluid button floating labeled search icon='sort' className='icon'
                  name='consumo_grupo_b'
                  placeholder='Selecione'
                  validate={[required]}
                  options={tipoTarifaDropdown}
                  component={SemanticDropdown}
                  loading={props.loading}
                />
              </FormField></Table.Cell>
              <Table.Cell><FormField width={16}>
                <Field fluid button floating labeled search icon='sort' className='icon'
                  name='demanda_grupo_b'
                  placeholder='Selecione'
                  validate={[required]}
                  options={tipoTarifaDropdown}
                  component={SemanticDropdown}
                  loading={props.loading}
                />
              </FormField></Table.Cell>
              <Table.Cell><FormField width={16}>
                <Field fluid button floating labeled search icon='sort' className='icon'
                  name='tipo_demanda_b'
                  placeholder='Selecione'
                  validate={[required]}
                  options={tipoDemandaDropdown}
                  component={SemanticDropdown}
                  loading={props.loading}
                />
              </FormField></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </FormSection>
    </CRUDForm>
  )
}

EnquadramentoForm.propTypes = {
  loading: PropTypes.bool,
  multipleTipoTarifa: PropTypes.object,
  multipleTipoDemanda: PropTypes.object,
}

export default reduxForm({form: 'EnquadramentoForm'})(EnquadramentoForm)
