import React from 'react'
import PropTypes from 'prop-types'
import { Table, Label, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { CRUDIndex } from '../CRUD'
import { EditDeleteButtonContainer } from '../../containers'
import { formatDate } from '../../utils/formats'

import { multipleBaseCalculo } from '../../utils/enums/baseCalculo'

const ParametroConcessionaria = props => {
  return (
    <CRUDIndex
      noTitle
      padded={false}
      onRedirectToNew={props.onRedirectToNew}
      onExportToCSV={props.onExportToCSV}
      filterText={props.filterText}
      handleFilter={props.handleFilter}>
      <Table sortable celled striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              width={1}
              textAlign='center'
              onClick={props.handleSort('id')}
              sorted={props.handleSorted('id')}>
              #
            </Table.HeaderCell>
            <Table.HeaderCell
              onClick={props.handleSort('starts_at')}
              sorted={props.handleSorted('starts_at')}>
              Início da Vigência
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              onClick={props.handleSort('decimal_places')}
              sorted={props.handleSorted('decimal_places')}>
              Casas Decimais
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>Base Cálculo ICMS</Table.HeaderCell>
            <Table.HeaderCell width={3}>
              Base Cálculo PIS/COFINS
            </Table.HeaderCell>
            <Table.HeaderCell width={1}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {props.isLoading && (
            <Table.Row>
              <Table.Cell colSpan={4}>
                <Loader active={props.isLoading} inline='centered' />
              </Table.Cell>
            </Table.Row>
          )}

          {props.multipleParametroConcessionaria
            .valueSeq()
            .map((parametroConcessionaria, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell textAlign='center'>
                    <Label
                      as={Link}
                      to={`/concessionarias/${parametroConcessionaria.getIn([
                        'concessionaria',
                        'id',
                      ])}/parametros/${parametroConcessionaria.get('id')}`}>
                      {parametroConcessionaria.get('id')}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    {formatDate(parametroConcessionaria.get('starts_at'))}
                  </Table.Cell>
                  <Table.Cell>
                    {parametroConcessionaria.get('decimal_places')}
                  </Table.Cell>
                  <Table.Cell>
                    {multipleBaseCalculo.getIn([
                      `${parametroConcessionaria.get('base_calculo_icms')}`,
                      'nome',
                    ])}
                  </Table.Cell>
                  <Table.Cell>
                    {multipleBaseCalculo.getIn([
                      `${parametroConcessionaria.get(
                        'base_calculo_pis_cofins'
                      )}`,
                      'nome',
                    ])}
                  </Table.Cell>
                  <Table.Cell>
                    <EditDeleteButtonContainer
                      onEditResource={() =>
                        props.onEditResource(
                          parametroConcessionaria.getIn([
                            'concessionaria',
                            'id',
                          ]),
                          parametroConcessionaria.get('id')
                        )
                      }
                      onDeleteResource={() =>
                        props.onDeleteResource(
                          parametroConcessionaria.get('id')
                        )
                      }
                      onCopyResource={() =>
                        props.onCopyResource(parametroConcessionaria.get('id'))
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
    </CRUDIndex>
  )
}

ParametroConcessionaria.propTypes = {
  onRedirectToNew: PropTypes.func,
  multipleParametroConcessionaria: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  onCopyResource: PropTypes.func,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default ParametroConcessionaria
