import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { PldMedioForm } from '../../components'
import * as pldMedioActionCreators from '../../redux/modules/pldMedio'
import * as pldMedioSelectionActionCreators from '../../redux/modules/pldMedioSelection'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { maskCurrency } from '../../utils/masks'

class PldMedioFormContainer extends Component {

  static propTypes = {
    history: PropTypes.object,
    fetchMultiplePldMedio: PropTypes.func,
    updatePldMedio: PropTypes.func,
    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
    initialize: PropTypes.func,
    updatePldMedioSelection: PropTypes.func,

    year: PropTypes.number,
    years: PropTypes.array,
  }

  async componentDidMount () {
    this.fetchData()
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  componentDidUpdate (prevProps) {
    const { year } = this.props
    if (prevProps.year !== year) {
      this.fetchData()
    }
  }

  handleChange = (event) => {
    this.props.updatePldMedioSelection(
      event.target.name,
      event.target.value,
    )
  }

  handleSubmitResource = async (pldMedio) => {
    let batch = []

    pldMedio.first().valueSeq().forEach(pld => {
      pld.get('precos').valueSeq().forEach(preco => {
        let p = {}
        p.pld_medio = {
          ano: pld.get('ano'),
          mes: pld.get('mes'),
          submercado_id: preco.getIn(['submercado', 'id']),
          preco: maskCurrency.unmask(preco.get('preco')),
        }
        batch.push(p)
      })
    })

    if (await this.props.updatePldMedio({ batch })) {
      this.props.showSuccessfulFlashMessage('', 'update')
      this.props.history.push('/indicativos/medios')
    } else {
      this.props.showErrorFlashMessage('', 'update', this.props.error)
    }
  }

  fetchData = async () => {
    let pldMedio = await this.props.fetchMultiplePldMedio(this.props.year)

    pldMedio = pldMedio.reduce((acc, curr) => {
      let month = curr.mes - 1
      if (!acc[month]) acc[month] = {
        ano: curr.ano,
        mes: curr.mes,
        mes_nome: curr.mes_nome,
        precos: []
      }
      acc[month].precos.push({
        submercado: curr.submercado,
        preco: maskCurrency.parse(curr.preco),
      })
      return acc
    }, [])

    this.props.initialize('PldMedioForm', { pldMedio })
  }

  render () {
    return (
      <PldMedioForm
        year={this.props.year}
        years={this.props.years}
        isLoading={this.props.isLoading}
        history={this.props.history}
        handleChange={this.handleChange}
        onSubmit={(pldSemanal) => this.handleSubmitResource(pldSemanal)}
      />
    )
  }
}

function mapStateToProps ({ pldMedioSelection, status, flashMessage }) {
  return {
    error: status.getIn(['pldMedio', 'error']),
    isLoading: status.getIn(['pldMedio', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),

    year: pldMedioSelection.get('year'),
    years: pldMedioSelection.get('years'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...pldMedioActionCreators,
    ...pldMedioSelectionActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PldMedioFormContainer)
