import React from 'react'
import PropTypes from 'prop-types'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

const EditDeleteButton = props => {
  if (
    !props.onEditResource &&
    !props.onDeleteResource &&
    !props.onPrintResource &&
    !props.onCopyResource
  ) {
    return null
  }

  return (
    <div>
      <Button.Group>
        {props.onCopyResource && (
          <Button
            icon
            size='small'
            data-tooltip='Criar uma cópia'
            data-position='left center'
            onClick={() => props.onCopyResource()}>
            <Icon name='clone' />
          </Button>
        )}
        {props.onEditResource && (
          <Button
            icon
            size='small'
            data-tooltip='Editar'
            data-position='left center'
            onClick={() => props.onEditResource()}>
            <Icon name='edit' />
          </Button>
        )}
        {props.onDeleteResource && (
          <Button
            icon
            size='small'
            data-tooltip='Excluir'
            data-position='left center'
            onClick={() => props.show()}>
            <Icon name='delete' />
          </Button>
        )}
        {props.onPrintResource && (
          <Button icon size='small' onClick={() => props.onPrintResource()}>
            <Icon name='print' />
          </Button>
        )}
        {props.customButtonGroupItems &&
          props.customButtonGroupItems.map(item => item())}
      </Button.Group>

      {props.onDeleteResource && (
        <Modal dimmer='blurring' basic size='tiny' open={props.open}>
          <Header as='h1' icon='warning' content='Atenção' />
          <Modal.Content>
            <p>
              Deseja realmente excluir este registro? Esta operação não poderá
              ser desfeita.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button inverted onClick={() => props.handleCancel()}>
              <Icon name='remove' /> Não, desejo manter o registro
            </Button>
            <Button inverted color='red' onClick={() => props.handleConfirm()}>
              <Icon name='checkmark' /> Sim, desejo realmente excluir
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  )
}

EditDeleteButton.propTypes = {
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  onPrintResource: PropTypes.func,
  show: PropTypes.func,
  open: PropTypes.bool,
  handleConfirm: PropTypes.func,
  handleCancel: PropTypes.func,
  customButtonGroupItems: PropTypes.array,
}

export default EditDeleteButton
