import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Header, Message } from 'semantic-ui-react'
import { CRUDShow } from '../../CRUD'
import { maskFloat, maskPercent } from '../../../utils/masks'

import moment from 'moment'

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const MiniGrid = ({ fields }) => (
  <Grid>
    <Grid.Row className='collapsed'>
      {fields.map((field, index) => (
        <React.Fragment key={index}>
          <Grid.Column width='6' className='mini-grid-description' textAlign='right'>{field.label}:</Grid.Column>
          <Grid.Column width='10' className='mini-grid-value monospaced'>{field.value}</Grid.Column>
        </React.Fragment>
      ))}
    </Grid.Row>
  </Grid>
)

MiniGrid.propTypes = {
  fields: PropTypes.array,
}

const MiniGrid2 = ({ data, fields }) => {
  return (
    <Grid>
      <Grid.Row className='collapsed'>
        {fields.map((field, index) => {
          const value = data.get(field.field)
          if (!value) return (null)

          return (
            <React.Fragment key={index}>
              <Grid.Column width='6' className='mini-grid-description' textAlign='right'>{field.label}:</Grid.Column>
              <Grid.Column width='10' className='mini-grid-value monospaced'>{field.formatter(data.get(field.field))}</Grid.Column>
            </React.Fragment>
          )
        })}
      </Grid.Row>
    </Grid>
  )
}

MiniGrid2.propTypes = {
  data: PropTypes.object,
  fields: PropTypes.array,
}

const formatters = {
  kWh: (value) => `${maskFloat.parse(value)} kWh`,
  kW: (value) => `${maskFloat.parse(value)} kW`,
  kvar: (value) => `${maskFloat.parse(value)} kvar`,
}

const fields = {
  consumo: [
    { field: 'consumo_p', label: 'Consumo Ponta', formatter: formatters.kWh },
    { field: 'consumo_fp', label: 'Consumo Fora-Ponta', formatter: formatters.kWh },
    { field: 'consumo_na', label: 'Consumo', formatter: formatters.kWh },
    { field: 'consumo_int', label: 'Consumo Intermediário', formatter: formatters.kWh }
  ],
  demanda: [
    { field: 'demanda_p', label: 'Demanda Ponta', formatter: formatters.kW },
    { field: 'demanda_fp', label: 'Demanda Fora-Ponta', formatter: formatters.kW },
    { field: 'demanda_na', label: 'Demanda', formatter: formatters.kW },
  ],
  dmcr: [
    { field: 'dmcr_p', label: 'DMCR Ponta', formatter: formatters.kvar },
    { field: 'dmcr_fp', label: 'DMCR Fora-Ponta', formatter: formatters.kvar },
    { field: 'dmcr_na', label: 'DMCR', formatter: formatters.kvar },
  ],
}

const Informacoes = (props) => {
  const { faturaPedagio, multipleTributo } = props

  const dataInicial = moment(faturaPedagio.get('data_inicial'), 'DD/MM/YYYY')
  const dataFinal = moment(faturaPedagio.get('data_final'), 'DD/MM/YYYY')
  const mesmoMes = dataInicial.isSame(dataFinal, 'month')

  const consumoPonta = parseFloat(faturaPedagio.get('consumo_p')) || 0.00
  const consumoForaPonta = parseFloat(faturaPedagio.get('consumo_fp')) || 0.00
  const consumoNA = parseFloat(faturaPedagio.get('consumo_na')) || 0.00
  const consumoIntermediario = parseFloat(faturaPedagio.get('consumo_int')) || 0.00
  const consumoTotal = parseFloat((consumoPonta + consumoForaPonta + consumoNA + consumoIntermediario).toFixed(2))

  const tributosFatura = faturaPedagio.get('possui_tributos', false)
    ? faturaPedagio.get('tributos_fatura').valueSeq().map(tributoFatura => {
      const tributo_id = `${tributoFatura.get('tributo_id')}`
      const label = multipleTributo.getIn([tributo_id, 'nome'], tributo_id)
      const value = `${maskPercent.parse(tributoFatura.get('aliquota'))}%`
      return { label, value }
    })
    : []

  return (
    <CRUDShow noTitle noToolbar padded={false}>
      {faturaPedagio.get('observacoes') && (
        <Message warning>
          <Message.Header>Observações</Message.Header>
          <p>{faturaPedagio.get('observacoes')}</p>
        </Message>
      )}
      <Grid celled>
        <Grid.Row columns={1}>
          <Grid.Column className='customHeaderColumn'>
            <Header as='h3'>Competência</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <MiniGrid fields={[
              { label: 'Ano', value: `${faturaPedagio.get('ano')}`},
              { label: 'Mês', value: `${faturaPedagio.get('mes')}`},
            ]} />
          </Grid.Column>
          <Grid.Column>
            <MiniGrid fields={[
              { label: 'Início da Medição', value: `${faturaPedagio.get('data_inicial')}`},
              { label: 'Fim da Medição', value: `${faturaPedagio.get('data_final')}`},
            ]} />
          </Grid.Column>
        </Grid.Row>

        {faturaPedagio.get('possui_tributos', false) && (
          <React.Fragment>
            <Grid.Row columns={1}>
              <Grid.Column className='customHeaderColumn'>
                <Header as='h3'>Tributos (específicos para esta fatura)</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <MiniGrid fields={tributosFatura} />
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        )}

        <Grid.Row columns={1}>
          <Grid.Column className='customHeaderColumn'>
            <Header as='h3'>Total</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <MiniGrid fields={[
              { label: 'Valor Total da Fatura', value: `R$ ${maskFloat.parse(faturaPedagio.get('valor_total'))}`},
            ]} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column className='customHeaderColumn'>
            <Header as='h3'>Consumo</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <MiniGrid2 data={faturaPedagio} fields={fields.consumo} />
          </Grid.Column>
          <Grid.Column>
            {mesmoMes ? (
              <MiniGrid fields={[
                { label: capitalize(dataFinal.format('MMMM/YYYY')), value: `${maskFloat.parse(faturaPedagio.get('consumo_mes_final'))} kWh`},
                { label: 'Consumo Total', value: `${maskFloat.parse(consumoTotal)} kWh`},
              ]} />
            ) : (
              <MiniGrid fields={[
                { label: capitalize(dataInicial.format('MMMM/YYYY')), value: `${maskFloat.parse(faturaPedagio.get('consumo_mes_inicial'))} kWh`},
                { label: capitalize(dataFinal.format('MMMM/YYYY')), value: `${maskFloat.parse(faturaPedagio.get('consumo_mes_final'))} kWh`},
                { label: 'Consumo Total', value: `${maskFloat.parse(consumoTotal)} kWh`},
              ]} />
            )}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column className='customHeaderColumn'>
            <Header as='h3'>Demanda</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <MiniGrid2 data={faturaPedagio} fields={fields.demanda} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column className='customHeaderColumn'>
            <Header as='h3'>Reativo</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <MiniGrid fields={[
              { label: 'UFER Ponta', value: `${maskFloat.parse(faturaPedagio.get('ufer_p'))} kvarh`},
              { label: 'UFER Fora Ponta', value: `${maskFloat.parse(faturaPedagio.get('ufer_fp'))} kvarh`},
            ]} />
          </Grid.Column>
          <Grid.Column>
            <MiniGrid2 data={faturaPedagio} fields={fields.dmcr} />
          </Grid.Column>
        </Grid.Row>

        {faturaPedagio.get('consumo_mensal_combustivel') && (
          <React.Fragment>
            <Grid.Row columns={1}>
              <Grid.Column className='customHeaderColumn'>
                <Header as='h3'>Gerador</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <MiniGrid fields={[
                  { label: 'Consumo Mensal', value: `R$ ${maskFloat.parse(faturaPedagio.get('consumo_mensal_combustivel'))}`},
                  { label: 'Custo Mensal', value: `R$ ${maskFloat.parse(faturaPedagio.get('custo_total_combustivel'))}`},
                ]} />
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        )}

        <Grid.Row columns={1}>
          <Grid.Column className='customHeaderColumn'>
            <Header as='h3'>Outros</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <MiniGrid fields={[
              { label: 'Multas', value: `R$ ${maskFloat.parse(faturaPedagio.get('multas'))}`},
              { label: 'Juros', value: `R$ ${maskFloat.parse(faturaPedagio.get('juros'))}`},
              { label: 'Correção Monetária', value: `R$ ${maskFloat.parse(faturaPedagio.get('correcao_monetaria'))}`},
            ]} />
          </Grid.Column>
          <Grid.Column>
            <MiniGrid fields={[
              { label: 'Iluminação Pública', value: `R$ ${maskFloat.parse(faturaPedagio.get('iluminacao_publica'))}`},
              { label: 'Encargos de Conexão', value: `R$ ${maskFloat.parse(faturaPedagio.get('encargos_conexao'))}`},
            ]} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column className='customHeaderColumn'>
            <Header as='h3'>Extras</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <MiniGrid fields={
              faturaPedagio.get('extras_pedagio_attributes').map((extra) => {
                return {
                  label: extra.get('descricao'),
                  value: `R$ ${maskFloat.parse(extra.get('valor'))}`
                }
              })
            } />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </CRUDShow>
  )
}

Informacoes.propTypes = {
  faturaPedagio: PropTypes.object,
  instalacao: PropTypes.object,
  onEditResource: PropTypes.func.isRequired,
  onDeleteResource: PropTypes.func.isRequired,
  multipleTributo: PropTypes.object,
}

export default Informacoes
