import React from 'react'
import { Select, Button, Dropdown } from 'semantic-ui-react'
import * as resumoActionCreators from '../../redux/modules/painelResumo'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classes from './PainelResumoFilter.module.css'
import PainelResumoContext from './painel-resumo-context'

const PainelResumoFilter = props => {
  const anoAtual = new Date().getFullYear()
  const [clienteValue, setClienteValue] = React.useState()
  const ctx = React.useContext(PainelResumoContext)

  let optionsMes = []
  let optionsAno = []
  const mercados = [
    {
      key: 'ambos',
      value: 'ambos',
      text: 'Ambos mercados',
    },
    {
      key: 'cativo',
      value: 'cativo',
      text: 'Apenas Cativo',
    },
    {
      key: 'livre',
      value: 'livre',
      text: 'Apenas Livre',
    },
  ]

  React.useEffect(() => {
    ctx.setSelectedCard(-1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clienteValue, ctx.mes, ctx.ano, ctx.mercado])

  for (var i = 1; i <= 12; i++) optionsMes.push({ key: i, value: i, text: i })
  for (var j = anoAtual; j >= anoAtual - 40; j--)
    optionsAno.push({ key: j, value: j, text: j })

  const handleSubmit = async () => {
    const objSubmit = {
      cliente: clienteValue,
      periodo: `${ctx.ano}-${ctx.mes}-01`,
      tipo_mercado: ctx.mercado,
    }
    if (!clienteValue) delete objSubmit.cliente
    ctx.setIsLoading(true)
    ctx.setClientesFiltrados(await props.fetchResumos(objSubmit))
    ctx.setIsLoading(false)
  }

  return (
    <>
      <Dropdown
        className={classes.selectDropdown}
        placeholder='Cliente'
        search
        selection
        options={props.clientes}
        onChange={(e, d) => setClienteValue(d.value)}
      />
      <Select
        className={classes.selectDropdown}
        placeholder='Mercado'
        options={mercados}
        onChange={(e, d) => ctx.setMercado(d.value)}
      />
      <Select
        className={classes.selectDropdown}
        placeholder='Mês'
        compact
        options={optionsMes}
        onChange={(e, d) => ctx.setMes(d.value)}
      />
      <Select
        className={classes.selectDropdown}
        placeholder='Ano'
        compact
        options={optionsAno}
        onChange={(e, d) => ctx.setAno(d.value)}
      />
      <Button
        className={classes.selectDropdown}
        onClick={handleSubmit}
        icon='search'
        primary
      />
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...resumoActionCreators,
    },
    dispatch
  )
}

export default connect(null, mapDispatchToProps)(PainelResumoFilter)
