import '../../css/print.css'

import React from 'react'
import PropTypes from 'prop-types'

import PrintableHeader from '../Relatorio/PrintableHeader'
import Cabecalho from '../Relatorio/Graficos/Cabecalho'

import { Grid, Icon, Loader } from 'semantic-ui-react'
import TotalTable from './TotalTable'
import ParametrosTecnicos from './ParametrosTecnicos'
import DataTable from './DataTable'
import SimpleTable from './SimpleTable'
import Gerador from './Gerador'
import Posto from './utils/Posto'

import Comparacao from './Comparacao'

const fieldsBandeira = [
  {
    descricao: 'Mês Inicial',
    campos: [
      { campo: 'consumo_mes_inicial', descricao: 'Bandeira Inicial', medicao: 'consumo_mes_inicial', unidade: 'kWh' }
    ],
  },
  {
    descricao: 'Mês Final',
    campos: [
      { campo: 'consumo_mes_final', descricao: 'Bandeira Final', medicao: 'consumo_mes_final', unidade: 'kWh' }
    ],
  },
]

const fieldsOutros = [
  {
    field: 'iluminacao_publica',
    descricao: 'Iluminação Pública',
  },
  {
    field: 'encargos_conexao',
    descricao: 'Encargos de Conexão',
  },
  {
    field: 'multas',
    descricao: 'Multas',
  },
  {
    field: 'juros',
    descricao: 'Juros',
  },
  {
    field: 'correcao_monetaria',
    descricao: 'Correção Monetária',
  },
]

const ResultadoPrint = props => {
  const { resultado, faturaPedagio } = props

  if ([
    resultado,
    faturaPedagio,
  ].some(v => !v)) return (<Loader inline='centered' active={props.isLoading} />)

  const resultadoRealizado = resultado.get('data')

  const tarifas = resultado.getIn(['data', 'tarifas'])
  const medicoes = resultado.getIn(['data', 'medicoes'])
  const valores = resultado.getIn(['data', 'valores'])

  const fixos = resultado.getIn(['data', 'fixos'])
  const extras = resultado.getIn(['data', 'extras'])

  const gerador = resultado.getIn(['data', 'gerador'])

  // const impostos = resultado.getIn(['data', 'impostos'])
  const infoDemanda = medicoes.getIn(['info_demanda'])

  const meta = resultado.getIn(['data', 'meta'])
  const total = resultado.getIn(['data', 'total'])

  const bandeiraInicial = meta.getIn(['bandeira', 'inicial'])
  const bandeiraFinal = meta.getIn(['bandeira', 'final'])

  if (bandeiraInicial) {
    fieldsBandeira[0].campos[0].descricao = <span>{bandeiraInicial.get('bandeira')}{' '}<Icon name='flag' style={{color: bandeiraInicial.get('cor')}} /></span>
  }

  if (bandeiraFinal) {
    fieldsBandeira[1].campos[0].descricao = <span>{bandeiraFinal.get('bandeira')}{' '}<Icon name='flag' style={{color: bandeiraFinal.get('cor')}} /></span>
  }

  const cabecalho = {
    nuc: meta.get('nuc'),
    cliente: meta.get('cliente').toJS(),
    instalacao: meta.get('instalacao').toJS(),
    concessionaria: meta.get('concessionaria').toJS(),
    enquadramento: meta.get('enquadramento').toJS(),
    subgrupo: meta.get('subgrupo').toJS(),
    vigencia: {
      mes: meta.getIn(['fatura', 'mes']),
      ano: meta.getIn(['fatura', 'ano']),
    },
    periodo: meta.getIn(['fatura', 'periodo']).toJS(),
    mercado: resultado.getIn(['parametro_calculo', 'tipo_mercado']) === 1 ? 'Cativo' : 'Livre'
  }

  return (
    <div className='printable'>
      <Grid padded style={{backgroundColor: '#fff'}}>
        <Grid.Row className="print-only printableHeader" verticalAlign='top'>
          <Grid.Column floated='right' width={16}>
            <PrintableHeader />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className='cabecalho'>
          <Cabecalho data={cabecalho} fatura={true} />
        </Grid.Row>
      </Grid>
      <ParametrosTecnicos color='rgba(0, 0, 0, 0.15)' modalidade={meta.getIn(['enquadramento', 'modalidade'])} medicoes={medicoes} infoDemanda={infoDemanda} />
      <TotalTable title='Total da Fatura' color='rgba(0, 0, 0, 0.15)' total={total} meta={meta} />
      <Comparacao realizado={resultadoRealizado} simulado={null} detail={true} />
      <DataTable title='Bandeiras' color='rgba(0, 0, 0, 0.15)' data={fieldsBandeira.map(field => Posto(field, valores, tarifas, medicoes, false))} />
      <SimpleTable title='Outros' color='rgba(0, 0, 0, 0.15)' data={fieldsOutros
        .filter(field => parseFloat(fixos.get(field.field)))
        .map(field => {
          return {
            descricao: field.descricao,
            valor: fixos.get(field.field),
          }
        })
        .concat(extras.valueSeq().map(extra => {
          return {
            descricao: extra.get('descricao'),
            valor: extra.get('valor')
          }
        }).toArray())} />
      <Gerador title='Gerador' color='rgba(0, 0, 0, 0.15)' data={gerador} />
    </div>
  )
}

ResultadoPrint.propTypes = {
  resultado: PropTypes.object,
  faturaPedagio: PropTypes.object,
  simulacao: PropTypes.object,
  isLoading: PropTypes.bool,
  onSimular: PropTypes.func,
  multipleCenario: PropTypes.object,
}

export default ResultadoPrint
