import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import get from 'lodash/get'
import { Container } from 'semantic-ui-react'
import { ResponsiveBar } from '@nivo/bar'

import {
  COLORS,
  capitalize,
  formatAsLocalizedInteger,
  formatAsCurrency,
  formatAsIndicador,
} from './utils/ChartUtils'

const fieldMapping = [
  {
    id: 'Consumo P',
    field: 'consumo_p.valor',
    color: COLORS.ELECTRIC.BLUE,
  },
  {
    id: 'Consumo FP',
    field: 'consumo_fp.valor',
    color: COLORS.ELECTRIC.LIGHT.BLUE,
  },
  {
    id: 'Consumo INT',
    field: 'consumo_int.valor',
    color: COLORS.ELECTRIC.LIGHTER.BLUE,
  },
  {
    id: 'Consumo',
    field: 'consumo_na.valor',
    color: COLORS.ELECTRIC.BLUE,
  },
  {
    id: 'Demanda P',
    field: 'demanda_p.valor',
    color: COLORS.ELECTRIC.GREEN,
  },
  {
    id: 'Demanda FP',
    field: 'demanda_fp.valor',
    color: COLORS.ELECTRIC.LIGHT.GREEN,
  },
  {
    id: 'Demanda',
    field: 'demanda_na.valor',
    color: COLORS.ELECTRIC.GREEN,
  },
  {
    id: 'UFER',
    field: 'ufer.valor',
    color: COLORS.ELECTRIC.LIGHT.RED,
  },
  {
    id: 'Outros',
    field: 'outros.valor',
    color: COLORS.ELECTRIC.LIGHT.BASE,
  },
]

const arrayToObject = (array, keyField) => array.reduce((obj, item) => {
  obj[item[keyField]] = item
  return obj
}, {})

// Calcula uma quantidade de 'passos' baseado numa escala logarítmica do valor
// máximo do chart. Isso é utilizado para aumentar proporcionalmente left-padding
// conforme os valores da escala aumentarem, de modo a não ocorrer overflow.
const steps = val => {
  const log = Math.log(val) / Math.log(100)
  return parseInt(log)
}

const ImpostosChart = ({ data, height, showAnimations }) => {
  if (!data) return null

  const preparedData = data.map(datum => {
    const calculated = arrayToObject(
      Object.keys(datum.valores).map(
        key => {
          return {
            key,
            valor: datum.valores[key][0],
          }
        }
      ), 'key')

    calculated['ano'] = datum.ano
    calculated['mes'] = datum.mes

    return calculated
  })

  const chartKeys = []

  const chartData = preparedData.map(fatura => {
    return fieldMapping.reduce((acc, curr) => {
      const id = curr.id
      const value = get(fatura, curr.field, 0.0)

      if (!chartKeys.includes(id) && value) chartKeys.push(id)

      acc[id] = value
      acc[`${id}Color`] = curr.color

      return acc
    }, {
      periodo: capitalize(moment({y: fatura.ano, M: fatura.mes - 1}).format('MMM/YY')),
    })
  })

  const max = preparedData.reduce((stored, current) => {
    const { ano, mes, ...newObject } = current // eslint-disable-line no-unused-vars
    const currentValue = Object.values(newObject).reduce((acc, curr) => acc + curr.valor, 0.0)
    return Math.max(stored, currentValue)
  }, Number.MIN_VALUE)

  return (
    <Container fluid>
      <div style={{height: `${height}px`}}>
        <ResponsiveBar
          data={chartData}
          keys={[
            'Consumo P',
            'Consumo FP',
            'Consumo INT',
            'Consumo',
            'Demanda P',
            'Demanda FP',
            'Demanda',
            'UFER',
            'Outros'
          ]}
          indexBy='periodo'
          margin={{
            'top': 20,
            'right': 130,
            'bottom': 30,
            'left': 60 + steps(max) * 10
          }}
          padding={0.1}
          colorBy={({ id, data }) => data[`${id}Color`]}
          borderColor='inherit:darker(1.6)'
          axisBottom={{
            'orient': 'bottom',
            'tickSize': 5,
            'tickPadding': 5,
            'tickRotation': 0,
            'legend': '',
            'legendPosition': 'center',
            'legendOffset': 36
          }}
          axisLeft={{
            'orient': 'left',
            'tickSize': 5,
            'tickPadding': 5,
            'tickRotation': 0,
            'legend': '',
            'legendPosition': 'center',
            'legendOffset': -40,
            format: e => formatAsIndicador('R$ ', true)(e)
          }}
          enableGridX={false}
          enableGridY={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor='#FFF'
          labelFormat={value => formatAsLocalizedInteger(value)}
          tooltipFormat={value => formatAsCurrency(value)}
          animate={showAnimations}
          motionStiffness={90}
          motionDamping={15}
          legends={[
            {
              'dataFrom': 'keys',
              'anchor': 'bottom-right',
              'direction': 'column',
              'justify': false,
              'translateX': 120,
              'translateY': 0,
              'itemsSpacing': 2,
              'itemWidth': 100,
              'itemHeight': 20,
              'itemDirection': 'right-to-left',
              'itemOpacity': 0.85,
              'symbolSize': 20,
              'itemTextColor': COLORS.ELECTRIC.LIGHT.BASE,
              'effects': [
                {
                  'on': 'hover',
                  'style': {
                    'itemOpacity': 1
                  }
                }
              ]
            }
          ]}
          theme={{
            labels: {
              text: {
                fontWeight: 'bold',
              }
            },
            axis: {
              domain: {
                line: {
                  strokeWidth: 0,
                },
              },
              ticks: {
                line: {
                  stroke: COLORS.ELECTRIC.LIGHT.BASE,
                },
                text: {
                  fill: COLORS.ELECTRIC.LIGHT.BASE,
                }
              },
            },
            dots: {
              text: {
                fill: COLORS.ELECTRIC.LIGHT.BASE,
              }
            }
          }}
        />
      </div>
    </Container>
  )
}

ImpostosChart.propTypes = {
  data: PropTypes.array,
  info: PropTypes.object,
  height: PropTypes.number,
  showAnimations: PropTypes.bool,
}

ImpostosChart.defaultProps = {
  info: {},
  height: 350,
  showAnimations: true,
}

export default ImpostosChart
