import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_UNIDADE = 'LOADING_UNIDADE'
export function loadingUnidade () {
  return {
    type: LOADING_UNIDADE,
  }
}

export const LOADING_UNIDADE_SUCCESS = 'LOADING_UNIDADE_SUCCESS'
export function loadingUnidadeSuccess (unidade) {
  return {
    type: LOADING_UNIDADE_SUCCESS,
    unidade,
  }
}

export const LOADING_UNIDADE_FAILURE = 'LOADING_UNIDADE_FAILURE'
export function loadingUnidadeFailure (error) {
  return {
    type: LOADING_UNIDADE_FAILURE,
    error,
  }
}

const initialState = Map({})

export function fetchMultipleUnidade () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingUnidade())
      const multipleUnidade = await callAPI('/acl/unidades')
      dispatch(loadingUnidadeSuccess(multipleUnidade))
      return multipleUnidade
    } catch (e) {
      dispatch(loadingUnidadeFailure(e))
      return false
    }
  }
}

export default function unidade (state = initialState, action) {
  switch (action.type) {

    case LOADING_UNIDADE_SUCCESS : {
      const { unidade } = action
      if (unidade.constructor === Array) {
        state = initialState
        unidade.forEach((singleUnidade) => (
          state = state.merge({[singleUnidade.id]: singleUnidade})
        ))
        return state
      }
      return state.merge({[unidade.id]: unidade})
    }

    default :
      return state
  }
}
