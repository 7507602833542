import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ContratoEnergiaInstalacao } from '../../components'
import * as contratoEnergiaInstalacaoActionCreators from '../../redux/modules/contratoEnergiaInstalacao'
import * as comercializadoraActionCreators from '../../redux/modules/comercializadora'
import * as fonteEnergiaActionCreators from '../../redux/modules/fonteEnergia'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class ContratoEnergiaInstalacaoContainer extends Component {

  static propTypes = {
    instalacao: PropTypes.object.isRequired,
    comercializadora: PropTypes.object.isRequired,
    fonte_energia: PropTypes.object.isRequired,
    history: PropTypes.object,

    multipleContratoEnergiaInstalacao: PropTypes.object,
    fetchMultipleContratoEnergiaInstalacao: PropTypes.func,
    destroyContratoEnergiaInstalacao: PropTypes.func,

    multipleComercializadora: PropTypes.object,
    fetchMultipleComercializadora: PropTypes.func,

    multipleFonteEnergia: PropTypes.object,
    fetchMultipleFonteEnergia: PropTypes.func,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,

    error: PropTypes.array,
    isLoading: PropTypes.bool,
    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string,
  }

  async componentDidMount () {
    const { instalacao } = this.props
    await Promise.all([
      this.props.fetchMultipleContratoEnergiaInstalacao(instalacao.id),
      this.props.fetchMultipleComercializadora(),
      this.props.fetchMultipleFonteEnergia(),
    ])
  }

  handleRedirectToNew = (instalacaoId) => {
    this.props.history.push(`/instalacoes/${instalacaoId}/contratos_energia/new`)
  }

  handleEditResource = (instalacaoId, id) => {
    this.props.history.push(`/instalacoes/${instalacaoId}/contratos_energia/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyContratoEnergiaInstalacao(id)
      ? this.props.showSuccessfulFlashMessage('', 'destroy')
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  handleSort = column => () => this.props.updateSort('contratoEnergiaInstalacao', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('contratoEnergiaInstalacao', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleContratoEnergiaInstalacao.toList().toJSON(),
      'contratoEnergiaInstalacao',
      [
        'id',
        'ano_inicial',
        'mes_inicial',
        'ano_final',
        'mes_final',
      ]
    )
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <ContratoEnergiaInstalacao
        instalacao={this.props.instalacao}
        onRedirectToNew={() => this.handleRedirectToNew(this.props.instalacao.id)}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        multipleContratoEnergiaInstalacao={this.props.multipleContratoEnergiaInstalacao}
        multipleComercializadora={this.props.multipleComercializadora}
        multipleFonteEnergia={this.props.multipleFonteEnergia}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV}
        isLoading={this.props.isLoading}
      />
    )
  }
}

function mapStateToProps ({contratoEnergiaInstalacao, comercializadora, fonteEnergia, status, sort, filter}, {instalacao}) {
  const sortColumn = sort.getIn(['contratoEnergiaInstalacao', 'sortColumn']) || 'id'
  const sortDirection = sort.getIn(['contratoEnergiaInstalacao', 'sortDirection']) || 'ascending'
  const filterText = filter.getIn(['contratoEnergiaInstalacao', 'filterText']) || ''

  let map = filterMap(contratoEnergiaInstalacao, ['ano_inicial', 'ano_final'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleContratoEnergiaInstalacao: map.filter(t => t.get('instalacao_id') === instalacao.id),
    multipleComercializadora: comercializadora,
    multipleFonteEnergia: fonteEnergia,
    error: status.getIn(['contratoEnergiaInstalacao', 'error']),
    isLoading: status.getIn(['contratoEnergiaInstalacao', 'isLoading']) || status.getIn(['subgrupo', 'isLoading']),
    sortColumn,
    sortDirection,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...flashMessageAcionCreators,
    ...contratoEnergiaInstalacaoActionCreators,
    ...comercializadoraActionCreators,
    ...fonteEnergiaActionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContratoEnergiaInstalacaoContainer))
