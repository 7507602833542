import React from 'react'
import PropTypes from 'prop-types'

import { Divider, Grid, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { CRUDShow } from '../../CRUD'

const Informacoes = (props) => {
  const { instalacao, uf } = props
  return (
    <CRUDShow noTitle padded={false}
      onEditResource={() => props.onEditResource(instalacao.id)}
      onDeleteResource={() => props.onDeleteResource(instalacao.id)}>
      <Grid columns='equal'>
        <Grid.Column width={16}>
          <Divider horizontal>Dados Principais</Divider>
        </Grid.Column>

        <Grid.Row>
          <Grid.Column textAlign='right' width={2}>
            <strong>Apelido</strong>
          </Grid.Column>
          <Grid.Column width={2}>
            {instalacao.apelido}
          </Grid.Column>

          <Grid.Column textAlign='right' width={2}>
            <strong>Nome/Razão Social</strong>
          </Grid.Column>
          <Grid.Column width={2}>
            {instalacao.nome}
          </Grid.Column>

          <Grid.Column textAlign='right' width={2}>
            <strong>Ativo:</strong>
          </Grid.Column>
          <Grid.Column width={2}>
            {instalacao.ativo ? "Sim" : "Não"}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column textAlign='right' width={2}>
            <strong>CNPJ</strong>
          </Grid.Column>
          <Grid.Column width={2}>
            {instalacao.cnpj}
          </Grid.Column>

          <Grid.Column textAlign='right' width={2}>
            <strong>Insc. Municipal</strong>
          </Grid.Column>
          <Grid.Column width={2}>
            {instalacao.ins_municipal}
          </Grid.Column>

          <Grid.Column textAlign='right' width={2}>
            <strong>Insc. Estadual</strong>
          </Grid.Column>
          <Grid.Column width={2}>
            {instalacao.ins_estadual}
          </Grid.Column>

          {instalacao.unidade && (
            <>
              <Grid.Column textAlign='right' width={2}>
                <strong>Unidade ACL</strong>
              </Grid.Column>
              <Grid.Column width={2}>
                <div className='labelWrapper'>
                  <Label as='a' href={`${window.location.origin}/app/agentes/unidades/${instalacao.unidade.id}`}>
                    {instalacao.unidade.apelido}
                  </Label>
                </div>
              </Grid.Column>
            </>
          )}
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Divider horizontal>Endereço</Divider>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column textAlign='right' width={2}>
            <strong>Endereço</strong>
          </Grid.Column>
          <Grid.Column width={9}>
            {instalacao.endereco}
          </Grid.Column>

          <Grid.Column textAlign='right' width={2}>
            <strong>CEP</strong>
          </Grid.Column>
          <Grid.Column width={3}>
            {instalacao.cep}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column textAlign='right' width={2}>
            <strong>Bairro</strong>
          </Grid.Column>
          <Grid.Column width={4}>
            {instalacao.bairro}
          </Grid.Column>

          <Grid.Column textAlign='right' width={2}>
            <strong>Cidade</strong>
          </Grid.Column>
          <Grid.Column width={4}>
            {instalacao.cidade}
          </Grid.Column>

          <Grid.Column textAlign='right' width={1}>
            <strong>UF</strong>
          </Grid.Column>
          <Grid.Column width={3}>
            <Label as={Link} to={`/uf/${uf.id}`}>
              {uf.sigla}
            </Label>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Divider horizontal>Configuração de Simulação</Divider>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column textAlign='right' width={2}>
            <strong>Cenários de Cálculo</strong>
          </Grid.Column>
          <Grid.Column width={2}>
            <div className='labelListWrapper'>
              {instalacao.cenarios.map((cenario, index) => (
                <div key={index} className='labelWrapper'>
                  <Label key={index} as={Link} to={`/cadastros/cenarios/${cenario.id}`}>
                    {cenario.nome}
                  </Label>
                </div>
              ))}
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Divider horizontal>Dados Bancários</Divider>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column textAlign='right' width={2}>
            <strong>Banco</strong>
          </Grid.Column>
          <Grid.Column width={2}>
            {instalacao.banco}
          </Grid.Column>

          <Grid.Column textAlign='right' width={3}>
            <strong>Agência</strong>
          </Grid.Column>
          <Grid.Column width={4}>
            {instalacao.agencia}
          </Grid.Column>

          <Grid.Column textAlign='right' width={1}>
            <strong>Conta</strong>
          </Grid.Column>
          <Grid.Column width={4}>
            {instalacao.conta}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Divider horizontal>Dados Adicionais</Divider>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column textAlign='right' width={2}>
            <em>Criado em</em><br />
            <em>Atualizado em</em>
          </Grid.Column>
          <Grid.Column>
            {instalacao.created_at}<br/>
            {instalacao.updated_at}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </CRUDShow>
  )
}

Informacoes.propTypes = {
  instalacao: PropTypes.object,
  uf: PropTypes.object,
  onEditResource: PropTypes.func.isRequired,
  onDeleteResource: PropTypes.func.isRequired
}

export default Informacoes
