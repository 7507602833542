import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ServicoInstalacaoShow } from '../../components'
import * as servicoInstalacaoActionCreators from '../../redux/modules/servicoInstalacao'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

class ServicoInstalacaoShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    instalacao: PropTypes.object.isRequired,

    fetchServicoInstalacao: PropTypes.func.isRequired,
    destroyServicoInstalacao: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
  }

  state = {
    servicoInstalacao: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const servicoInstalacao = await this.props.fetchServicoInstalacao(this.props.id)
      this.setState({ servicoInstalacao })
    }
  }

  handleEditResource = (instalacaoId, id) => {
    this.props.history.push(`/instalacoes/${instalacaoId}/servicos/${id}/edit`)
  }

  handleDeleteResource = async (instalacaoId, id) => {
    if (await this.props.destroyServicoInstalacao(id)) {
      this.props.showSuccessfulFlashMessage('', 'destroy')
      this.props.history.push(`/instalacoes/${instalacaoId}/servicos`)
    } else {
      this.props.showErrorFlashMessage('', 'destroy', this.props.error)
    }
  }

  render () {
    return (
      <ServicoInstalacaoShow
        instalacao={this.props.instalacao}
        servicoInstalacao={this.state.servicoInstalacao}
        onEditResource={() => this.handleEditResource(this.props.instalacao.id, this.state.servicoInstalacao.id)}
        onDeleteResource={() => this.handleDeleteResource(this.props.instalacao.id, this.state.servicoInstalacao.id)} />
    )
  }
}

function mapStateToProps ({servicoInstalacao}, {match}) {
  const { id } = match.params

  if (id) {
    return {
      id,
      servicoInstalacao: servicoInstalacao.get('id'),
    }
  }

  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...servicoInstalacaoActionCreators,
    ...flashMessageActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServicoInstalacaoShowContainer))
