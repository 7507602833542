import React from 'react'
import PropTypes from 'prop-types'

import { reduxForm, FormSection, FieldArray } from 'redux-form/immutable'
import { Form } from 'semantic-ui-react'

import RenderRecados from './RenderRecados'

const RecadosForm = ({ isLoading, onToggleMode, handleSubmit }) => {
  return (
    <Form onSubmit={handleSubmit} loading={isLoading}>
      <FormSection name='relatorio'>
        <FieldArray
          name='recados'
          component={RenderRecados}
          isLoading={isLoading}
          onToggleMode={onToggleMode}
        />
      </FormSection>
    </Form>
  )
}

RecadosForm.propTypes = {
  isLoading: PropTypes.bool,
  onToggleMode: PropTypes.func,
  handleSubmit: PropTypes.func,
}

export default reduxForm({form: 'RecadosForm'})(RecadosForm)
