import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_FATURA_PEDAGIO = 'LOADING_FATURA_PEDAGIO'
export function loadingFaturaPedagio () {
  return {
    type: LOADING_FATURA_PEDAGIO,
  }
}

export const LOADING_FATURA_PEDAGIO_SUCCESS = 'LOADING_FATURA_PEDAGIO_SUCCESS'
export function loadingFaturaPedagioSuccess (faturaPedagio) {
  return {
    type: LOADING_FATURA_PEDAGIO_SUCCESS,
    faturaPedagio,
  }
}

export const LOADING_FATURA_PEDAGIO_FAILURE = 'LOADING_FATURA_PEDAGIO_FAILURE'
export function loadingFaturaPedagioFailure (error) {
  return {
    type: LOADING_FATURA_PEDAGIO_FAILURE,
    error,
  }
}

export const CALCULATING_FATURA_PEDAGIO_FAILURE = 'CALCULATING_FATURA_PEDAGIO_FAILURE'
export function calculatingFaturaPedagioFailure (faturaPedagioId, error) {
  return {
    type: CALCULATING_FATURA_PEDAGIO_FAILURE,
    faturaPedagioId: faturaPedagioId,
    error: error,
  }
}

export const DESTROYING_FATURA_PEDAGIO_SUCCESS = 'DESTROYING_FATURA_PEDAGIO_SUCCESS'
export function destroyingFaturaPedagioSuccess (faturaPedagioId) {
  return {
    type: DESTROYING_FATURA_PEDAGIO_SUCCESS,
    faturaPedagioId,
  }
}

const initialState = Map({})

export function downloadFaturaPedagio (instalacaoId, ano_inicial = '', mes_inicial = '', ano_final = '', mes_final = '') {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFaturaPedagio())
      const params = `ano_inicial=${ano_inicial}&mes_inicial=${mes_inicial}&ano_final=${ano_final}&mes_final=${mes_final}`
      const multipleFaturaPedagio = await callAPI(`/instalacoes/${instalacaoId}/faturas_pedagio/download?${params}`)
      dispatch(loadingFaturaPedagioSuccess({}))
      return multipleFaturaPedagio
    } catch (e) {
      dispatch(loadingFaturaPedagioFailure(e))
      return false
    }
  }
}

export function fetchMultipleFaturaPedagio (instalacaoId, ano = '', mes = '', state = '') {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFaturaPedagio())
      const multipleFaturaPedagio = await callAPI(`/instalacoes/${instalacaoId}/faturas_pedagio?ano=${ano}&mes=${mes}&state=${state}`)
      dispatch(loadingFaturaPedagioSuccess(multipleFaturaPedagio))
      return multipleFaturaPedagio
    } catch (e) {
      dispatch(loadingFaturaPedagioFailure(e))
      return false
    }
  }
}

export function fetchFaturaPedagio (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFaturaPedagio())
      const faturaPedagio = await callAPI(`/faturas_pedagio/${id}`)
      dispatch(loadingFaturaPedagioSuccess(faturaPedagio))
      return faturaPedagio
    } catch (e) {
      dispatch(loadingFaturaPedagioFailure(e))
      return false
    }
  }
}

export function createFaturaPedagio (instalacaoId, faturaPedagio) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFaturaPedagio())
      const newFaturaPedagio = await callAPI(`/instalacoes/${instalacaoId}/faturas_pedagio`, 'POST', faturaPedagio)
      dispatch(loadingFaturaPedagioSuccess(newFaturaPedagio))
      return newFaturaPedagio
    } catch (e) {
      dispatch(loadingFaturaPedagioFailure(e))
      return false
    }
  }
}

export function updateFaturaPedagio (id, faturaPedagio) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFaturaPedagio())
      const newFaturaPedagio = await callAPI(`/faturas_pedagio/${id}`, 'PUT', faturaPedagio)
      dispatch(loadingFaturaPedagioSuccess(newFaturaPedagio))
      return newFaturaPedagio
    } catch (e) {
      dispatch(loadingFaturaPedagioFailure(e))
      return false
    }
  }
}

export function destroyFaturaPedagio (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFaturaPedagio())
      await callAPI(`/faturas_pedagio/${id}`, 'DELETE')
      dispatch(destroyingFaturaPedagioSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingFaturaPedagioFailure(e))
      return false
    }
  }
}

export function calculateFaturaPedagio (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFaturaPedagio())
      const newFaturaPedagio = await callAPI(`/faturas_pedagio/${id}/calculate`, 'POST', faturaPedagio)
      dispatch(loadingFaturaPedagioSuccess(newFaturaPedagio))
      return newFaturaPedagio
    } catch (e) {
      dispatch(calculatingFaturaPedagioFailure(id, e))
      return false
    }
  }
}

export default function faturaPedagio (state = initialState, action) {
  switch (action.type) {

    case LOADING_FATURA_PEDAGIO_SUCCESS : {
      const { faturaPedagio } = action
      if (faturaPedagio.constructor === Array) {
        state = initialState
        faturaPedagio.forEach((singleFaturaPedagio) => (
          state = state.merge({[singleFaturaPedagio.id]: singleFaturaPedagio})
        ))
        return state
      }
      return state.merge({[faturaPedagio.id]: faturaPedagio})
    }

    case DESTROYING_FATURA_PEDAGIO_SUCCESS :
      return state.delete(action.faturaPedagioId.toString())

    case CALCULATING_FATURA_PEDAGIO_FAILURE: {
      return state
    }

    default :
      return state
  }
}
