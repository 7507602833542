import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInputMask, SemanticDropdown } from '../FormComponents'

import { required, percentage } from '../../utils/validations'
import { maskCurrency } from '../../utils/masks'

const IcmsForm = (props) => (
  <CRUDForm
    title='Alíquota de ICMS'
    icon='percent'
    {...props}>
    <FormSection name='icms'>
      <Form.Group>
        <FormField width={4}>
          <label htmlFor='uf_id'>UF</label>
          <Field fluid button floating labeled search icon='marker' className='icon'
            autoFocus
            name='uf_id'
            validate={[required]}
            options={props.ufDropdown}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>

        <FormField width={4}>
          <label htmlFor='classe_consumo_id'>Classe de Consumo</label>
          <Field fluid button floating labeled search icon='block layout' className='icon'
            name='classe_consumo_id'
            validate={[required]}
            options={props.classeConsumoDropdown}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>

        <FormField width={3}>
          <label htmlFor='aliquota'>Alíquota</label>
          <Field
            name='aliquota'
            label='%'
            validate={[required, percentage]}
            component={SemanticInputMask}
            loading={props.loading}
            mask={maskCurrency.mask()}
            placeholder={maskCurrency.placeholder}
          />
        </FormField>
      </Form.Group>
    </FormSection>
  </CRUDForm>
)

IcmsForm.propTypes = {
  ufDropdown: PropTypes.array,
  classeConsumoDropdown: PropTypes.array,
  loading: PropTypes.bool
}

export default reduxForm({form: 'IcmsForm'})(IcmsForm)
