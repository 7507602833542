import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { ContratoDemandaInstalacaoForm } from '../../components'
import * as contratoDemandaInstalacaoActionCreators from '../../redux/modules/contratoDemandaInstalacao'
import * as contratoDemandaInstalacaoSelectionActionCreators from '../../redux/modules/contratoDemandaInstalacaoSelection'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { maskCurrency } from '../../utils/masks'

class ContratoDemandaInstalacaoFormContainer extends Component {

  static propTypes = {
    instalacao: PropTypes.object.isRequired,
    history: PropTypes.object,

    fetchMultipleContratoDemandaInstalacao: PropTypes.func,
    updateContratoDemandaInstalacao: PropTypes.func,

    updateContratoDemandaInstalacaoSelection: PropTypes.func,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    shouldHideFlashMessage: PropTypes.bool.isRequired,

    error: PropTypes.array,
    isLoading: PropTypes.bool,
    initialize: PropTypes.func,

    year: PropTypes.number,
    years: PropTypes.array,
  }

  async componentDidMount () {
    this.fetchData()
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  componentDidUpdate (prevProps) {
    const { year } = this.props
    if (prevProps.year !== year) {
      this.fetchData()
    }
  }

  handleChange = (event) => {
    this.props.updateContratoDemandaInstalacaoSelection(
      event.target.name,
      event.target.value,
    )
  }

  handleSubmitResource = async (contratoDemandaInstalacao) => {
    let batch = []

    contratoDemandaInstalacao.first().valueSeq().forEach(contrato => {
      contrato.get('valores').valueSeq().forEach(valor => {
        let p = {}
        p.contrato_demanda = {
          instalacao: this.props.instalacao,
          instalacao_id: this.props.instalacao.id,
          ano: contrato.get('ano'),
          mes: contrato.get('mes'),
          valor_p: maskCurrency.unmask(valor.get('valor_p')),
          valor_fp: maskCurrency.unmask(valor.get('valor_fp')),
          valor_na: maskCurrency.unmask(valor.get('valor_na')),
        }
        batch.push(p)
      })
    })

    if (await this.props.updateContratoDemandaInstalacao(this.props.instalacao.id, { batch })) {
      let instalacaoId = this.props.instalacao.id
      this.props.showSuccessfulFlashMessage('', 'update')
      this.props.history.push(`/instalacoes/${instalacaoId}/contratos_demanda`)
    } else {
      this.props.showErrorFlashMessage('', 'update', this.props.error)
    }
  }

  fetchData = async () => {
    let contratoDemandaInstalacao = await this.props.fetchMultipleContratoDemandaInstalacao(this.props.instalacao.id, this.props.year)

    contratoDemandaInstalacao = contratoDemandaInstalacao.reduce((acc, curr) => {
      let month = curr.mes - 1
      if (!acc[month]) acc[month] = {
        ano: curr.ano,
        mes: curr.mes,
        mes_nome: curr.mes_nome,
        valores: [],
      }
      acc[month].valores.push({
        valor_p: maskCurrency.parse(curr.valor_p),
        valor_fp: maskCurrency.parse(curr.valor_fp),
        valor_na: maskCurrency.parse(curr.valor_na),
      })
      return acc
    }, [])

    this.props.initialize('ContratoDemandaInstalacaoForm', { contratoDemandaInstalacao })
  }

  render () {
    return (
      <ContratoDemandaInstalacaoForm
        year={this.props.year}
        years={this.props.years}
        isLoading={this.props.isLoading}
        history={this.props.history}
        handleChange={this.handleChange}
        onSubmit={(contratoDemandaInstalacao) => this.handleSubmitResource(contratoDemandaInstalacao)}
      />
    )
  }
}

function mapStateToProps ({ contratoDemandaInstalacaoSelection, status, flashMessage }) {
  return {
    error: status.getIn(['contratoDemandaInstalacao', 'error']),
    isLoading: status.getIn(['contratoDemandaInstalacao', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),

    year: contratoDemandaInstalacaoSelection.get('year'),
    years: contratoDemandaInstalacaoSelection.get('years'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...contratoDemandaInstalacaoActionCreators,
    ...contratoDemandaInstalacaoSelectionActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContratoDemandaInstalacaoFormContainer))
