import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Form, Grid, Header, Icon, Loader, Menu, Table } from 'semantic-ui-react'
import { FlashMessageContainer } from '../../containers'
import { maskCurrency } from '../../utils/masks'
import sortBy from 'sort-by'

const PldMedio = (props) => {
  let collection = props.multiplePldMedio.reduce((acc, curr) => {
    let month = curr.get('mes') - 1
    if (!acc[month]) acc[month] = {
      ano: curr.get('ano'),
      mes: curr.get('mes'),
      mes_nome: curr.get('mes_nome'),
      precos: []
    }
    acc[month].precos.push({
      submercado: {
        id: curr.getIn(['submercado', 'id']),
        nome: curr.getIn(['submercado', 'nome']),
      },
      preco: maskCurrency.parse(curr.get('preco')),
    })
    return acc
  }, [])

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header as='h1' dividing>
            <Icon name='calendar outline' />
            <Header.Content>
              PLD - Preço Médio (R$/MWh)
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={16}>
          <Menu secondary>
            <Menu.Menu position='left'>
              <Menu.Item fitted>
                <Form as='div'>
                  <Form.Group inline className='customFormGroup'>
                    <Form.Field>
                      <label>Ano</label>
                      <Dropdown compact
                        onChange={(event, data) => props.onChange({
                          target: {
                            name: 'year',
                            value: data.value
                          }
                        })}
                        label='Ano'
                        options={props.years}
                        placeholder='Ano'
                        selection
                        value={props.year}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu position='right'>
              <Menu.Item fitted>
                <Button.Group>
                  <Button onClick={props.onEditResource} color='yellow' icon
                    labelPosition='left'>
                    <Icon name='edit' />{' '}Editar
                  </Button>
                </Button.Group>
              </Menu.Item>
            </Menu.Menu>
            <Menu.Item fitted>
              <Button onClick={props.onExportToCSV} animated='vertical'>
                <Button.Content hidden>CSV</Button.Content>
                <Button.Content visible>
                  <Icon name='download' />
                </Button.Content>
              </Button>
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column width={16} style={{ marginBottom: '1em' }}>
          <FlashMessageContainer />
        </Grid.Column>
        <Grid.Column width={16}>
          <Table celled striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1} textAlign='right'>Período/Submercado</Table.HeaderCell>
                {props.multipleSubmercado.valueSeq().map((submercado, index) => (
                  <Table.HeaderCell key={index} width={1} textAlign='right'>{submercado.get('descricao')} (R$/MWh)</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {props.isLoading && (
                <Table.Row>
                  <Table.Cell colSpan={1 + props.multipleSubmercado.size}>
                    <Loader active={props.isLoading} inline='centered' />
                  </Table.Cell>
                </Table.Row>
              )}
              {collection.map((pldMedio, index) => (
                <Table.Row key={index} textAlign='right'>
                  <Table.Cell>
                    {pldMedio.mes_nome}/{pldMedio.ano}
                  </Table.Cell>
                  {pldMedio.precos.sort(sortBy('submercado.id')).map((preco, index) => (
                    <Table.Cell key={index} textAlign='right'>
                      {preco.preco}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

PldMedio.propTypes = {
  isLoading: PropTypes.bool,
  loading: PropTypes.bool,
  multiplePldMedio: PropTypes.object,
  multipleSubmercado: PropTypes.object,

  onEditResource: PropTypes.func,
  onExportToCSV: PropTypes.func,
  onUploadCSV: PropTypes.func,
  onChange: PropTypes.func,

  year: PropTypes.number,
  month: PropTypes.number,
  week: PropTypes.number,
  years: PropTypes.array,
  months: PropTypes.array,
  weeks: PropTypes.array,
}

export default PldMedio
