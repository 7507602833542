import React, { Component } from 'react'
import { Loading } from '../../components'

class LoadingContainer extends Component {
  render () {
    return (
      <Loading />
    )
  }
}

export default LoadingContainer
