import React from 'react'

import { Map } from 'immutable'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, Field, submit } from 'redux-form/immutable'
import { Modal, Button, Icon, Form, FormField, Grid } from 'semantic-ui-react'

import { SemanticDropdown } from '../FormComponents'
import { required } from '../../utils/validations'

import moment from 'moment'
import 'moment/locale/pt-br'

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const today = new Date()

const anos = Array.apply(0, Array(10)).map((_, index) => {
  const year = index + new Date().getFullYear() - 5
  return {
    key: year,
    text: year,
    value: year,
  }
})

const meses = Array.apply(0, Array(12)).map((_, index) => ({
  key: moment().month(index).format('MMM'),
  text: capitalize(moment().month(index).format('MMMM')),
  value: index + 1
}))

const ExportToCSV = ({ dispatch, handleSubmit, loading, error }) => {
  const button = (
    <Button key='export-data' animated='vertical'>
      <Button.Content hidden>CSV</Button.Content>
      <Button.Content visible>
        <Icon name='download' />
      </Button.Content>
    </Button>
  )

  return (
    <Modal size='mini' trigger={button}>
      <Modal.Header>Selecione o período para exportação</Modal.Header>
      <Modal.Content className='export-to-csv-modal-content'>
        <Form onSubmit={handleSubmit} loading={loading}>
          <Grid className='export-to-csv-grid'>
            <Grid.Row columns={2}>
              <Grid.Column className='export-to-csv-column'>
                <FormField>
                  <label htmlFor='ano_inicial'>De...</label>
                  <Field fluid button floating labeled search icon='calendar' className='icon'
                    name='ano_inicial'
                    options={anos}
                    validate={[required]}
                    component={SemanticDropdown}
                    loading={loading}
                  />
                </FormField>
              </Grid.Column>
              <Grid.Column className='export-to-csv-column'>
                <FormField>
                  <label htmlFor='mes_inicial'>&nbsp;</label>
                  <Field fluid button floating labeled search icon='calendar' className='icon'
                    name='mes_inicial'
                    options={meses}
                    validate={[required]}
                    component={SemanticDropdown}
                    loading={loading}
                  />
                </FormField>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
              <Grid.Column className='export-to-csv-column'>
                <FormField>
                  <label htmlFor='ano_final'>Até...</label>
                  <Field fluid button floating labeled search icon='calendar' className='icon'
                    name='ano_final'
                    options={anos}
                    validate={[required]}
                    component={SemanticDropdown}
                    loading={loading}
                  />
                </FormField>
              </Grid.Column>
              <Grid.Column className='export-to-csv-column'>
                <FormField>
                  <label htmlFor='mes_final'>&nbsp;</label>
                  <Field fluid button floating labeled search icon='calendar' className='icon'
                    name='mes_final'
                    options={meses}
                    validate={[required]}
                    component={SemanticDropdown}
                    loading={loading}
                  />
                </FormField>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type='button' disabled={loading || error} loading={loading} onClick={() => dispatch(submit('ExportToCSVForm'))}>
          <Icon name='download' /> Baixar Arquivo CSV
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const validate = values => {
  const errors = {}

  if (Map.isMap(values)) values = values.toJS()
  if (values.ano_final < values.ano_inicial) {
    errors.ano_final = `Deve ser maior ou igual a ${values.ano_inicial}.`
  }

  if (values.ano_inicial === values.ano_final && values.mes_final < values.mes_inicial) {
    errors.mes_final = `Deve ser maior ou igual a ${meses[values.mes_inicial - 1].text}.`
  }

  return errors
}

export default compose(
  connect(),
  reduxForm({
    form: 'ExportToCSVForm',
    initialValues: {
      ano_inicial: today.getFullYear(),
      mes_inicial: today.getMonth() + 1,
      ano_final: today.getFullYear(),
      mes_final: today.getMonth() + 1,
    },
    validate,
  })
)(ExportToCSV)
