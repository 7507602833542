import { Map, fromJS } from "immutable";
import { callAPI } from "../../utils/API";

export const LOADING_CALCULADORA_CONSUMO = "LOADING_CALCULADORA_CONSUMO";
export function loadingCalculadoraConsumo() {
  return {
    type: LOADING_CALCULADORA_CONSUMO,
  };
}

export const LOADING_CALCULADORA_CONSUMO_SUCCESS =
  "LOADING_CALCULADORA_CONSUMO_SUCCESS";
export function loadingCalculadoraConsumoSuccess(calculadoraConsumo, data) {
  return {
    type: LOADING_CALCULADORA_CONSUMO_SUCCESS,
    calculadoraConsumo,
    data,
  };
}

export const LOADING_CALCULADORA_CONSUMO_FAILURE =
  "LOADING_CALCULADORA_CONSUMO_FAILURE";
export function loadingCalculadoraConsumoFailure(error) {
  return {
    type: LOADING_CALCULADORA_CONSUMO_FAILURE,
    error,
  };
}

const initialState = Map({});

export function fetchCalculadoraConsumo(concessionariaId, data, dataTributos) {
  return async function (dispatch /* getState */) {
    try {
      dispatch(loadingCalculadoraConsumo());
      const vigenciaValor = await callAPI(
        `/gestao_bandeiras/valores/last?data=${data}`
      );
      const vigenciaBandeira = await callAPI(
        `/gestao_bandeiras/vigencias/last?data=${data}`
      );
      const vigenciaTributo = await callAPI(
        `/concessionarias/${concessionariaId}/tributos/last?data=${dataTributos}`
      );
      const parametrosConcessionaria = await callAPI(
        `/concessionarias/${concessionariaId}/parametros_concessionaria`
      );

      const calculadoraConsumo = montarCalculadoraConsumo(
        vigenciaValor,
        vigenciaBandeira,
        vigenciaTributo,
        parametrosConcessionaria,
        dataTributos
      );

      dispatch(loadingCalculadoraConsumoSuccess(calculadoraConsumo, data));
      return calculadoraConsumo;
    } catch (e) {
      dispatch(loadingCalculadoraConsumoFailure(e));
      return false;
    }
  };
}

export default function vigenciaBandeira(state = initialState, action) {
  switch (action.type) {
    case LOADING_CALCULADORA_CONSUMO_SUCCESS: {
      const { calculadoraConsumo, data } = action;
      return state.merge({ [data]: calculadoraConsumo });
    }

    default:
      return state;
  }
}

const montarCalculadoraConsumo = (
  vigenciaValor,
  vigenciaBandeira,
  vigenciaTributo,
  parametrosConcessionaria,
  dataTributos
) => {
  return fromJS({
    bandeira: {
      descricao: vigenciaBandeira.bandeira.nome,
      cor: vigenciaBandeira.bandeira.cor,
      valor: vigenciaValor.valores_bandeiras.find(
        (b) => b.bandeira_id === vigenciaBandeira.bandeira_id
      ).valor,
    },
    tributos: vigenciaTributo.aliquotas_tributos.map((aliquota) => {
      return { descricao: aliquota.tributo_nome, aliquota: aliquota.aliquota };
    }),
    parametrosConcessionaria: parametrosConcessionaria
      .filter((par) => new Date(par.starts_at) <= new Date(dataTributos))
      .sort((a, b) => new Date(a.starts_at) < new Date(b.starts_at)),
  });
};
