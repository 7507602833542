import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Container, Divider, Table } from 'semantic-ui-react'

import { capitalize, formatAsIndicador, formatAsIndCurrency, formatAsLocalizedNumberWithDecimals } from './utils/ChartUtils'

const [ AZUL, VERDE, CONVENCIONAL, BRANCA ] = Array.from(Array(4).keys())

const financeiros = [
  'reativo_p', 'reativo_fp', 'reativo_na', 'encargos_conexao', 'iluminacao_publica',
  'correcao_monetaria', 'juros', 'multas', 'creditos', 'debitos', 'bandeiras', 'total',
]

const modalidades = {
  [AZUL]: {
    tecnico: [
      'consumo_p', 'consumo_fp', 'consumo_total', 'mwm',
      'demanda_p', 'demanda_fp',
      'ultrapassagem_p', 'ultrapassagem_fp',
    ],
    financeiro: [
      'consumo_p', 'consumo_fp',
      'demanda_p', 'demanda_fp',
      'ultrapassagem_p', 'ultrapassagem_fp',
      ...financeiros
    ],
  },
  [VERDE]: {
    tecnico: [
      'consumo_p', 'consumo_fp', 'consumo_total',
      'demanda_p', 'demanda_fp', 'ultrapassagem_na',
    ],
    financeiro: [
      'consumo_p', 'consumo_fp',
      'demanda_na', 'ultrapassagem_na',
      ...financeiros,
    ],
  },
  [CONVENCIONAL]: {
    tecnico: [
      'consumo_na',
    ],
    financeiro: [
      'consumo_na',
      ...financeiros,
    ],
  },
  [BRANCA]: {
    tecnico: [
      'consumo_p', 'consumo_fp', 'consumo_int', 'consumo_total',
    ],
    financeiro: [
      'consumo_p', 'consumo_fp', 'consumo_int',
      ...financeiros,
    ],
  },
}

const fieldMapping = {
  consumo_p: {
    field: 'consumo_p',
    label: 'Consumo P',
    unit: 'kWh',
    formatter: formatAsIndicador(),
  },
  consumo_fp: {
    field: 'consumo_fp',
    label: 'Consumo FP',
    unit: 'kWh',
    formatter: formatAsIndicador(),
  },
  consumo_na: {
    field: 'consumo_na',
    label: 'Consumo',
    unit: 'kWh',
    formatter: formatAsIndicador(),
  },
  consumo_int: {
    field: 'consumo_int',
    label: 'Consumo Int.',
    unit: 'kWh',
    formatter: formatAsIndicador(),
  },
  consumo_total: {
    field: 'consumo_total',
    label: 'Consumo Total',
    unit: 'kWh',
    formatter: formatAsIndicador(),
  },
  mwm: {
    field: 'mwm',
    label: 'MWatt-médio',
    decimals: 3,
    formatter: formatAsLocalizedNumberWithDecimals(3),
  },
  demanda_p: {
    field: 'demanda_p',
    label: 'Demanda P',
    unit: 'kW',
    formatter: formatAsIndicador(),
  },
  demanda_fp: {
    field: 'demanda_fp',
    label: 'Demanda FP',
    unit: 'kW',
    formatter: formatAsIndicador(),
  },
  demanda_na: {
    field: 'demanda_na',
    label: 'Demanda',
    unit: 'kW',
    formatter: formatAsIndicador(),
  },
  ultrapassagem_p: {
    field: 'ultrapassagem_p',
    label: 'Ultrapassagem P',
    unit: 'kW',
    formatter: formatAsIndicador(),
  },
  ultrapassagem_fp: {
    field: 'ultrapassagem_fp',
    label: 'Ultrapassagem FP',
    unit: 'kW',
    formatter: formatAsIndicador(),
  },
  ultrapassagem_na: {
    field: 'ultrapassagem_na',
    label: 'Ultrapassagem',
    unit: 'kW',
    formatter: formatAsIndicador(),
  },
  encargos_conexao: {
    field: 'encargos_conexao',
    label: 'Encargos de Conexão',
  },
  iluminacao_publica: {
    field: 'iluminacao_publica',
    label: 'Iluminacao Pública',
  },
  reativo_p: {
    field: 'reativo_p',
    label: 'Reativo P',
  },
  reativo_fp: {
    field: 'reativo_fp',
    label: 'Reativo FP',
  },
  reativo_na: {
    field: 'reativo_na',
    label: 'Reativo',
  },
  correcao_monetaria: {
    field: 'correcao_monetaria',
    label: 'Correção Monetária',
  },
  juros: {
    field: 'juros',
    label: 'Juros',
  },
  multas: {
    field: 'multas',
    label: 'Multas',
  },
  creditos: {
    field: 'creditos',
    label: 'Créditos',
  },
  debitos: {
    field: 'debitos',
    label: 'Outros Débitos',
  },
  bandeiras: {
    field: 'bandeiras',
    label: 'Bandeiras',
  },
  total: {
    field: 'total',
    label: 'Total',
  },
}
const ResumoAnual = ({ data, enquadramento }) => {

  if (!data) return null

  const modalidade = enquadramento === 'Azul'
    ? AZUL
    : enquadramento === 'Verde'
      ? VERDE
      : enquadramento === 'Convencional'
        ? CONVENCIONAL
        : BRANCA

  const tables = [
    {
      key: 'tecnico',
      title: 'Resumo Anual - Dados Técnicos',
      fields: modalidades[modalidade].tecnico.map(f => fieldMapping[f]).filter(f => f),
    },
    {
      key: 'financeiro',
      title: 'Resumo Anual - Dados Financeiros',
      fields: modalidades[modalidade].financeiro.map(f => ({ ...fieldMapping[f], unit: null, formatter: formatAsIndCurrency })).filter(f => f),
    }
  ]

  return (
    <Container fluid>

      {tables.map((table, index) => {
        const tableKey = `resumo_${index}`
        return (
          <React.Fragment key={tableKey}>
            <Divider horizontal>{table.title}</Divider>
            <div style={{width: '100%'}}>
              <Table className='tabela-resumo' compact stripped basic='very' singleLine style={{ tableLayout: 'fixed', fontSize: '0.75em'}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    {data && data.length > 0 && data.map((d, i) => (
                      <Table.HeaderCell key={`${tableKey}_${i}`} textAlign='right'>
                        {capitalize(moment({y: d.ano, M: d.mes - 1}).format('MMM/YY'))}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {table.fields.map(field => (
                    <Table.Row key={`${tableKey}_${field.field}`}>
                      <Table.Cell textAlign='left'>{field.label}{field.unit ? ` (${field.unit})` : ''}</Table.Cell>
                      {data.map((d, i) => {
                        const value = d[table.key][field.field] || 0
                        const decimals = field.decimals || 0
                        const isZero = value.toFixed(decimals) === '0' || value.toFixed(decimals) === '-0'
                        const isNegative = value < 0.0
                        const formattedValue = isZero
                          ? field.formatter(0)
                          : isNegative
                            ? `(${field.formatter(Math.abs(value))})`
                            : field.formatter(value)
                        return (
                          <Table.Cell key={`${tableKey}_${field.field}_${i}`} style={{ color: isZero ? '#ccc' : null }}textAlign='right'>{formattedValue}</Table.Cell>
                        )
                      })}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </React.Fragment>
        )
      })}
    </Container>
  )
}

ResumoAnual.propTypes = {
  header: PropTypes.string,
  data: PropTypes.array,
  enquadramento: PropTypes.string,
}

ResumoAnual.defaultProps = {
  header: '',
}

export default ResumoAnual
