import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form/immutable'
import { Button, Form, Grid, Header, Image, Segment } from 'semantic-ui-react'
import logo from '../../img/logo.svg'

const Login = (props) => (
  <div>
    <div className='login-form'>
      {/*
        Heads up! The styles below are necessary for the correct render of this example.
        You can do same with CSS, the main idea is that all the elements up to the `Grid`
        below must have a height of 100%.
      */}
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}</style>
      <Grid
        textAlign='center'
        style={{ height: '100%' }}
        verticalAlign='middle'
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='blue' textAlign='center'>
            <Image src={logo} style={{ width: '450px' }} />
          </Header>
          <Form size='large' onSubmit={props.handleSubmit}>
            <Segment stacked>
              <Field
                autoFocus
                fluid
                icon='user'
                iconPosition='left'
                name='email'
                type='email'
                placeholder='E-mail'
                component={Form.Input}
              />

              <Field
                fluid
                icon='lock'
                iconPosition='left'
                name='password'
                type='password'
                placeholder='Senha'
                component={Form.Input}
              />

              <Button
                fluid
                color='green'
                size='large'
                disabled={props.submitting}>
                Entrar
              </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  </div>
)

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
}

export default reduxForm({form: 'LoginForm'})(Login)
