import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_CLIENTE = 'LOADING_CLIENTE'
export function loadingCliente () {
  return {
    type: LOADING_CLIENTE,
  }
}

export const LOADING_CLIENTE_SUCCESS = 'LOADING_CLIENTE_SUCCESS'
export function loadingClienteSuccess (cliente) {
  return {
    type: LOADING_CLIENTE_SUCCESS,
    cliente,
  }
}

export const LOADING_CLIENTE_FAILURE = 'LOADING_CLIENTE_FAILURE'
export function loadingClienteFailure (error) {
  return {
    type: LOADING_CLIENTE_FAILURE,
    error,
  }
}

export const DESTROYING_CLIENTE_SUCCESS = 'DESTROYING_CLIENTE_SUCCESS'
export function destroyingClienteSuccess (clienteId) {
  return {
    type: DESTROYING_CLIENTE_SUCCESS,
    clienteId,
  }
}

const initialState = Map({})

export function fetchMultipleCliente () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingCliente())
      const multipleCliente = await callAPI('/clientes')
      dispatch(loadingClienteSuccess(multipleCliente))
      return multipleCliente
    } catch (e) {
      dispatch(loadingClienteFailure(e))
      return false
    }
  }
}

export function fetchCliente (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingCliente())
      const cliente = await callAPI(`/clientes/${id}`)
      dispatch(loadingClienteSuccess(cliente))
      return cliente
    } catch (e) {
      dispatch(loadingClienteFailure(e))
      return false
    }
  }
}

export function createCliente (cliente) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingCliente())
      const newCliente = await callAPI('/clientes', 'POST', cliente)
      dispatch(loadingClienteSuccess(newCliente))
      return newCliente
    } catch (e) {
      dispatch(loadingClienteFailure(e))
      return false
    }
  }
}

export function updateCliente (id, cliente) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingCliente())
      const newCliente = await callAPI(`/clientes/${id}`, 'PUT', cliente)
      dispatch(loadingClienteSuccess(newCliente))
      return newCliente
    } catch (e) {
      dispatch(loadingClienteFailure(e))
      return false
    }
  }
}

export function destroyCliente (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingCliente())
      await callAPI(`/clientes/${id}`, 'DELETE')
      dispatch(destroyingClienteSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingClienteFailure(e))
      return false
    }
  }
}

export default function cliente (state = initialState, action) {
  switch (action.type) {

    case LOADING_CLIENTE_SUCCESS : {
      const { cliente } = action
      if (cliente.constructor === Array) {
        state = initialState
        cliente.forEach((singleCliente) => (
          state = state.merge({[singleCliente.id]: singleCliente})
        ))
        return state
      }
      return state.merge({[cliente.id]: cliente})
    }

    case DESTROYING_CLIENTE_SUCCESS :
      return state.delete(action.clienteId.toString())

    default :
      return state
  }
}
