import React from 'react'
import PropTypes from 'prop-types'
import { Container, Divider } from 'semantic-ui-react'

import { ResponsivePie } from '@nivo/pie'
import get from 'lodash.get'

import {
  COLORS,
  formatAsCurrency,
  formatAsIndicador,
  formatAsRoundedPercentage,
} from './utils/ChartUtils'

const fieldMapping = [
  {
    id: 'Consumo',
    field: 'consumo_na',
    color: COLORS.ELECTRIC.BLUE,
  },
  {
    id: 'Consumo P',
    field: 'consumo_p',
    color: COLORS.ELECTRIC.BLUE,
  },
  {
    id: 'Consumo FP',
    field: 'consumo_fp',
    color: COLORS.ELECTRIC.LIGHT.BLUE,
  },
  {
    id: 'Consumo INT',
    field: 'consumo_int',
    color: COLORS.ELECTRIC.LIGHTER.BLUE,
  },
  {
    id: 'Demanda',
    field: 'demanda_na',
    color: COLORS.ELECTRIC.GREEN,
  },
  {
    id: 'Demanda P',
    field: 'demanda_p',
    color: COLORS.ELECTRIC.GREEN,
  },
  {
    id: 'Demanda FP',
    field: 'demanda_fp',
    color: COLORS.ELECTRIC.LIGHT.GREEN,
  },
  {
    id: 'UFER',
    field: 'ufer',
    color: COLORS.ELECTRIC.LIGHT.RED,
  },
  {
    id: 'Outros',
    field: 'outros',
    color: COLORS.ELECTRIC.LIGHT.BASE,
  },
  {
    id: 'Gerador',
    field: 'gerador',
    color: COLORS.ELECTRIC.LIGHTER.BASE,
  },
]

const ComposicaoFaturaChart = ({ header, data, height, showAnimations }) => {
  if (!data) return null

  const chartData = fieldMapping.map(field => {
    return {
      id: field.id,
      label: field.id,
      value: get(data, `${field.field}[0]`, 0.0),
      percent: get(data, `${field.field}[1]`, 0.0),
      color: field.color
    }
  })

  return (
    <Container fluid>
      <Divider horizontal>{header}</Divider>
      <div style={{height: `${height}px`}}>
        <ResponsivePie
          data={chartData.filter(data => data.value)}
          sortByValue={false}
          innerRadius={0.6}
          padAngle={0.5}
          cornerRadius={5}
          colorBy={e => e.color || COLORS.ELECTRIC.LIGHT.BASE}
          borderColor='inherit:darker(0.2)'
          radialLabel={showAnimations
            ? d => (
              <tspan style={{fontWeight: 'normal'}}>{d.id}</tspan>
            )
            : d => (
              <React.Fragment>
                <tspan style={{fontWeight: 'normal'}}>{d.id}</tspan>
                <tspan> {formatAsIndicador('R$ ', true)(d.value)}</tspan>
              </React.Fragment>
            )
          }
          radialLabelsSkipAngle={3}
          radialLabelsTextXOffset={6}
          radialLabelsLinkOffset={0}
          radialLabelsLinkDiagonalLength={16}
          radialLabelsLinkHorizontalLength={24}
          radialLabelsLinkStrokeWidth={1}
          radialLabelsLinkColor='inherit'
          sliceLabel={e => formatAsRoundedPercentage(e.percent)}
          slicesLabelsSkipAngle={10}
          slicesLabelsTextColor={'#FFFFFF'}
          animate={showAnimations}
          motionStiffness={90}
          motionDamping={15}
          margin={{
            'top': 40,
            'right': 40,
            'bottom': 40,
            'left': 40
          }}
          tooltip='value'
          tooltipFormat={value => formatAsCurrency(value)}
          theme={{
            labels: {
              text: {
                fill: COLORS.ELECTRIC.LIGHT.BASE,
                fontWeight: 'bold',
              }
            }
          }}
        />
      </div>
    </Container>
  )
}

ComposicaoFaturaChart.propTypes = {
  header: PropTypes.string,
  data: PropTypes.object,
  height: PropTypes.number,
  showAnimations: PropTypes.bool,
}

ComposicaoFaturaChart.defaultProps = {
  header: '',
  height: 300,
  showAnimations: true,
}

export default ComposicaoFaturaChart
