import React from 'react'
import PropTypes from 'prop-types'
import { Header } from 'semantic-ui-react'
import { COLORS } from './utils/ChartUtils'

const Impostos = ({ impostos }) => {
  return (
    <Header as='h4' style={{
      marginTop: 0,
      textTransform: 'uppercase',
      color: impostos
        ? COLORS.ELECTRIC.LIGHT.GREEN
        : COLORS.ELECTRIC.LIGHT.RED
    }}>
      {impostos
        ? 'Valores com impostos'
        : 'Valores sem impostos'}
    </Header>
  )
}

Impostos.propTypes = {
  impostos: PropTypes.bool,
}

export default Impostos
