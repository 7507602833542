import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, Header, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { DatePickerInput } from '../FormComponents'
import { required, dateValidator } from '../../utils/validations'

import ParametroCalculoForm from '../../components/ParametroCalculoForm/ParametroCalculoForm'

const VigenciaParametroForm = props => {
  return (
    <CRUDForm
      title='Parâmetros Técnicos'
      titleAs='h3'
      icon='cogs'
      padded={false}
      {...props}>
      <FormSection name='vigencia_parametro'>
        <Form.Group>
          <FormField width={16}>
            <Header as='h3' block style={{ marginTop: '1em' }}>
              <label>Início da Vigência</label>
              <Field
                autoFocus
                name='starts_at'
                placeholder='Início da Vigência'
                validate={[required, dateValidator]}
                component={DatePickerInput}
                loading={props.loading}
              />
            </Header>
          </FormField>
        </Form.Group>
        <FormSection name='parametro_calculo'>
          <ParametroCalculoForm
            storeName='vigencia_parametro'
            formName='VigenciaParametroForm'
            multipleClasseConsumo={props.multipleClasseConsumo}
            multipleComercializadora={props.multipleComercializadora}
            multipleConcessionaria={props.multipleConcessionaria}
            multipleEnquadramento={props.multipleEnquadramento}
            multipleGerador={props.multipleGerador}
            multipleSubgrupo={props.multipleSubgrupo}
            multipleTipoMercado={props.multipleTipoMercado}
          />
        </FormSection>
      </FormSection>
    </CRUDForm>
  )
}

VigenciaParametroForm.propTypes = {
  loading: PropTypes.bool,
  multipleClasseConsumo: PropTypes.object,
  multipleComercializadora: PropTypes.object,
  multipleConcessionaria: PropTypes.object,
  multipleEnquadramento: PropTypes.object,
  multipleGerador: PropTypes.object,
  multipleSubgrupo: PropTypes.object,
  multipleTipoMercado: PropTypes.object,
}

export default reduxForm({ form: 'VigenciaParametroForm' })(
  VigenciaParametroForm
)
