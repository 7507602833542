import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ServicoShow } from '../../components'
import * as servicoActionCreators from '../../redux/modules/servico'

class ServicoShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    fetchServico: PropTypes.func,
    history: PropTypes.object,
    destroyServico: PropTypes.func,
  }

  state = {
    servico: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const servico = await this.props.fetchServico(this.props.id)
      this.setState({ servico })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/servicos/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/servicos/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyServico(id)
    this.props.history.push('/servicos')
  }

  render () {
    return (
      <ServicoShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        servico={this.state.servico} />
    )
  }
}

function mapStateToProps ({servico}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      servico: servico.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...servicoActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicoShowContainer)
