import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInputMask, SemanticDropdown, SemanticCheckbox } from '../FormComponents'
import { required } from '../../utils/validations'

import { maskYear } from '../../utils/masks'
import ResumoFatura from './ResumoFatura'

import { mapToDropdown } from '../../utils/parse'

import moment from 'moment'
import 'moment/locale/pt-br'

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const meses = Array.apply(0, Array(12)).map((_, index) => ({
  key: moment().month(index).format('MMM'),
  text: capitalize(moment().month(index).format('MMMM')),
  value: index + 1
}))

const RelatorioForm = (props) => {
  const cenarioDropdown = mapToDropdown(props.multipleCenario, e => e.get('id'), e => e.get('nome'))

  return (
    <CRUDForm
      title='Relatório'
      titleAs='h3'
      icon='chart line'
      padded={false}
      {...props}>
      <ResumoFatura fatura={props.fatura} ano={props.ano} mes={props.mes} />
      <FormSection name='relatorio'>
        <Form.Group>
          <FormField width={2}>
            <label htmlFor='ano'>Ano</label>
            <Field
              name='ano'
              validate={[required]}
              component={SemanticInputMask}
              loading={props.loading}
              mask={maskYear.mask}
              placeholder={maskYear.placeholder}
              debounce='1000'
            />
          </FormField>
          <FormField width={2}>
            <label htmlFor='mes'>Mês</label>
            <Field fluid button floating labeled search icon='calendar' className='icon'
              name='mes'
              options={meses}
              validate={[required]}
              component={SemanticDropdown}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group width={16}>
          <FormField width={4}>
            <label htmlFor='cenario_ids'>Cenários de Simulação</label>
            <Field fluid multiple search selection size='large'
              name='cenario_ids'
              placeholder='Cenários'
              validate={[]}
              options={cenarioDropdown}
              component={SemanticDropdown}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group width={16}>
          <FormField width={4}>
            <Field
              name='impostos'
              id='impostos'
              label='Utilizar valores com impostos'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group width={16}>
          <FormField width={4}>
            <Field
              name='exibir_reativos'
              id='exibir_reativos'
              label='Exibir tabela de reativos'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group width={16}>
          <FormField width={4}>
            <Field
              name='exibir_resumo'
              id='exibir_resumo'
              label='Exibir tabela de resumo'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
      </FormSection>
    </CRUDForm>
  )
}

RelatorioForm.propTypes = {
  loading: PropTypes.bool,
  fatura: PropTypes.object,
  ano: PropTypes.number,
  mes: PropTypes.number,
  multipleCenario: PropTypes.object,
}

export default reduxForm({form: 'RelatorioForm'})(RelatorioForm)
