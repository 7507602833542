import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import { Link } from 'react-router-dom'
import { CRUDShow } from '../CRUD'
import { Grid, Icon, Loader, Menu } from 'semantic-ui-react'
import { Informacoes, Contatos, Fontes } from './panes'

const ComercializadoraShow = (props) => {
  const { comercializadora, uf, pane } = props

  if (!comercializadora || !uf) return (
    <Loader active={props.isLoading}>Carregando...</Loader>
  )

  return (
    <CRUDShow noToolbar noMessage
      title={comercializadora.nome}
      icon='shopping cart'>
      <Grid>
        <Grid.Column width={3}>
          <Menu secondary vertical fluid size='huge'>
            <Menu.Item
              active={pane === 'informacoes'}
              as={Link}
              to={`/comercializadoras/${comercializadora.id}`}
              key='comercializadora'>
              <Icon name='building' />
              Informações
            </Menu.Item>
            <Menu.Item
              active={pane === 'fontes'}
              as={Link}
              to={`/comercializadoras/${comercializadora.id}/fontes_comercializadoras`}
              key='fontes'>
              <Icon name='lightning' />
              Fontes de Energia
            </Menu.Item>
            <Menu.Item
              active={pane === 'contatos'}
              as={Link}
              to={`/comercializadoras/${comercializadora.id}/contatos`}
              key='contatos'>
              <Icon name='users' />
              Contatos
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column width={13}>
          <Switch>
            <Route
              exact path='/comercializadoras/:id'
              render={() => (
                <Informacoes
                  comercializadora={comercializadora}
                  uf={uf}
                  onEditResource={props.onEditResource}
                  onDeleteResource={props.onDeleteResource}
                />
              )}
            />
            <Route
              exact path='/comercializadoras/:id/fontes_comercializadoras/:paneId?/:action?'
              render={() => (
                <Fontes
                  comercializadora={comercializadora} />
              )}
            />
            <Route
              exact path='/comercializadoras/:id/contatos/:paneId?/:action?'
              render={() => (
                <Contatos
                  comercializadora={comercializadora} />
              )}
            />
          </Switch>
        </Grid.Column>
      </Grid>
    </CRUDShow>
  )
}

ComercializadoraShow.propTypes = {
  comercializadora: PropTypes.object,
  isLoading: PropTypes.bool,
  uf: PropTypes.object,
  pane: PropTypes.string,
  onEditResource: PropTypes.func.isRequired,
  onDeleteResource: PropTypes.func.isRequired,
}

export default ComercializadoraShow
