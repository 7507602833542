import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form/immutable'
import { change } from 'redux-form/immutable'

import moment from 'moment'

import { RelatorioForm } from '../../components'
import * as relatorioActionCreators from '../../redux/modules/relatorio'
import * as cenarioActionCreators from '../../redux/modules/cenario'
import * as faturaPedagioActionCreators from '../../redux/modules/faturaPedagio'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

const selector = formValueSelector('RelatorioForm')

class RelatorioFormContainer extends Component {
  static propTypes = {
    createRelatorio: PropTypes.func.isRequired,
    error: PropTypes.object,
    fetchRelatorio: PropTypes.func.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    id: PropTypes.string,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    instalacao: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    updateRelatorio: PropTypes.func.isRequired,
    fatura: PropTypes.object,
    fetchMultipleFaturaPedagio: PropTypes.func,
    ano: PropTypes.number,
    mes: PropTypes.number,
    data: PropTypes.object,
    fetchMultipleCenario: PropTypes.func,
    multipleCenario: PropTypes.object,
  }

  async componentDidMount () {

    const { id, instalacao } = this.props

    let relatorio = {
      ano: moment().year(),
      mes: moment().month(),
      instalacao_id: instalacao.id,
      impostos: true,
      exibir_reativos: false,
      exibir_resumo: false,
    }

    if (id) {
      relatorio = await this.props.fetchRelatorio(this.props.id)
    }

    await this.props.fetchMultipleCenario(instalacao.id)

    this.props.initialize('RelatorioForm', { relatorio })
  }

  async componentDidUpdate(prevProps) {
    const { ano, mes, instalacao } = this.props

    if ((ano && moment(ano).isValid('year') && prevProps.ano !== ano) || (mes && moment(ano).isValid('month') && prevProps.mes !== mes)) {
      const fatura = await this.props.fetchMultipleFaturaPedagio(instalacao.id, ano, mes)
      this.props.change('RelatorioForm', 'relatorio.fatura_pedagio_id', fatura && fatura.length ? fatura[0].id : null)
    }
  }

  handleSubmitResource = async (relatorio) => {
    const instalacaoId = this.props.instalacao.id

    if (this.props.id) {
      if (await this.props.updateRelatorio(this.props.id, relatorio)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/instalacoes/${instalacaoId}/relatorios/${this.props.id}`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createRelatorio(instalacaoId, relatorio)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push(`/instalacoes/${instalacaoId}/relatorios`)
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <RelatorioForm
        instalacao={this.props.instalacao}
        ano={this.props.ano}
        mes={this.props.mes}
        fatura={this.props.fatura}
        data={this.props.data}
        onSubmit={(relatorio) => this.handleSubmitResource(relatorio)}
        isLoading={this.props.isLoading}
        multipleCenario={this.props.multipleCenario} />
    )
  }
}

function mapStateToProps ({relatorio, faturaPedagio, cenario, status, flashMessage, ...state}, {match, instalacao}) {
  const { id } = match.params
  const isLoading = status.getIn(['relatorio', 'isLoading'])

  const ano = selector(state, 'relatorio.ano')
  const mes = selector(state, 'relatorio.mes')
  const fatura_pedagio_id = selector(state, 'relatorio.fatura_pedagio_id')

  const fatura = faturaPedagio.get(`${fatura_pedagio_id}`)
  const data = relatorio.getIn([id, 'data'])

  const multipleCenario = cenario.filter(c => c.get('instalacao_id') === instalacao.id)

  if (id) {
    return {
      id,
      relatorio: relatorio.get('id'),
      data,
      fatura,
      ano,
      mes,
      error: status.getIn(['relatorio', 'error']),
      isLoading,
      shouldHideFlashMessage: flashMessage.get('isError'),
      multipleCenario,
    }
  }
  return {
    ano,
    mes,
    data,
    fatura,
    error: status.getIn(['relatorio', 'error']),
    isLoading,
    shouldHideFlashMessage: flashMessage.get('isError'),
    multipleCenario,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...relatorioActionCreators,
    ...cenarioActionCreators,
    ...faturaPedagioActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
    ...{change},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RelatorioFormContainer))
