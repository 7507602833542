import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Relatorio } from '../../components'
import * as relatorioActionCreators from '../../redux/modules/relatorio'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'

class RelatorioContainer extends Component {

  static propTypes = {
    destroyRelatorio: PropTypes.func,
    error: PropTypes.array,
    fetchMultipleRelatorio: PropTypes.func,
    filterText: PropTypes.string,
    hideFlashMessage: PropTypes.func,
    history: PropTypes.object,
    instalacao: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    multipleRelatorio: PropTypes.object,
    showErrorFlashMessage: PropTypes.func,
    showSuccessfulFlashMessage: PropTypes.func,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    updateSort: PropTypes.func.isRequired,
  }

  async componentDidMount () {
    const { instalacao } = this.props
    await Promise.all([
      this.props.fetchMultipleRelatorio(instalacao.id),
    ])
  }

  handleRedirectToNew = (instalacaoId) => {
    this.props.history.push(`/instalacoes/${instalacaoId}/relatorios/new`)
  }

  handleEditResource = (instalacaoId, id) => {
    this.props.history.push(`/instalacoes/${instalacaoId}/relatorios/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    if (await this.props.destroyRelatorio(id)) {
      this.props.showSuccessfulFlashMessage('', 'destroy')
      this.props.history.push(`/instalacoes/${this.props.instalacao.id}/relatorios`)
    } else {
      this.props.showErrorFlashMessage('', 'destroy', this.props.error)
    }

    await this.props.destroyRelatorio(id)
      ? this.props.showSuccessfulFlashMessage('', 'destroy')
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  handleSort = column => () => this.props.updateSort('relatorio', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('relatorio', name, value)

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <Relatorio
        instalacao={this.props.instalacao}
        onRedirectToNew={() => this.handleRedirectToNew(this.props.instalacao.id)}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        multipleRelatorio={this.props.multipleRelatorio}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        isLoading={this.props.isLoading}
      />
    )
  }
}

function mapStateToProps ({relatorio, status, sort, filter}, {instalacao}) {
  const sortColumn = sort.getIn(['relatorio', 'sortColumn']) || 'starts_at'
  const sortDirection = sort.getIn(['relatorio', 'sortDirection']) || 'ascending'
  const filterText = filter.getIn(['relatorio', 'filterText']) || ''

  let map = filterMap(relatorio, ['ano', 'mes'], filterText)
  map = sortMap(map, (a, b) => a.get('ano') - b.get('ano') || a.get('mes') - b.get('mes'), 'descending')

  return {
    multipleRelatorio: map.filter(t => t.get('instalacao_id') === instalacao.id),
    error: status.getIn(['relatorio', 'error']),
    isLoading: status.getIn(['relatorio', 'isLoading']),
    sortColumn,
    sortDirection,
    filterText,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...flashMessageAcionCreators,
    ...relatorioActionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RelatorioContainer))
