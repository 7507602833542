import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_PARAMETRO_CONCESSIONARIA =
  'LOADING_PARAMETRO_CONCESSIONARIA'
export function loadingParametroConcessionaria() {
  return {
    type: LOADING_PARAMETRO_CONCESSIONARIA,
  }
}

export const LOADING_PARAMETRO_CONCESSIONARIA_SUCCESS =
  'LOADING_PARAMETRO_CONCESSIONARIA_SUCCESS'
export function loadingParametroConcessionariaSuccess(parametroConcessionaria) {
  return {
    type: LOADING_PARAMETRO_CONCESSIONARIA_SUCCESS,
    parametroConcessionaria,
  }
}

export const LOADING_PARAMETRO_CONCESSIONARIA_FAILURE =
  'LOADING_PARAMETRO_CONCESSIONARIA_FAILURE'
export function loadingParametroConcessionariaFailure(error) {
  return {
    type: LOADING_PARAMETRO_CONCESSIONARIA_FAILURE,
    error,
  }
}

export const DESTROYING_PARAMETRO_CONCESSIONARIA_SUCCESS =
  'DESTROYING_PARAMETRO_CONCESSIONARIA_SUCCESS'
export function destroyingParametroConcessionariaSuccess(
  parametroConcessionariaId
) {
  return {
    type: DESTROYING_PARAMETRO_CONCESSIONARIA_SUCCESS,
    parametroConcessionariaId,
  }
}

export const COPYING_PARAMETRO_CONCESSIONARIA_SUCCESS =
  'COPYING_PARAMETRO_CONCESSIONARIA_SUCCESS'
export function copyingParametroConcessionariaSuccess(parametroConcessionaria) {
  return {
    type: COPYING_PARAMETRO_CONCESSIONARIA_SUCCESS,
    parametroConcessionaria,
  }
}

const initialState = Map({})

export function fetchMultipleParametroConcessionaria(concessionariaId) {
  return async function (dispatch /* getState */) {
    try {
      dispatch(loadingParametroConcessionaria())
      const multipleParametroConcessionaria = await callAPI(
        `/concessionarias/${concessionariaId}/parametros_concessionaria`
      )
      dispatch(
        loadingParametroConcessionariaSuccess(multipleParametroConcessionaria)
      )
      return multipleParametroConcessionaria
    } catch (e) {
      dispatch(loadingParametroConcessionariaFailure(e))
      return false
    }
  }
}

export function fetchParametroConcessionaria(id) {
  return async function (dispatch /* getState */) {
    try {
      dispatch(loadingParametroConcessionaria())
      const parametroConcessionaria = await callAPI(
        `/parametros_concessionaria/${id}`
      )
      dispatch(loadingParametroConcessionariaSuccess(parametroConcessionaria))
      return parametroConcessionaria
    } catch (e) {
      dispatch(loadingParametroConcessionariaFailure(e))
      return false
    }
  }
}

export function createParametroConcessionaria(
  concessionariaId,
  parametroConcessionaria
) {
  return async function (dispatch /* getState */) {
    try {
      dispatch(loadingParametroConcessionaria())
      const newParametroConcessionaria = await callAPI(
        `/concessionarias/${concessionariaId}/parametros_concessionaria`,
        'POST',
        parametroConcessionaria
      )
      dispatch(
        loadingParametroConcessionariaSuccess(newParametroConcessionaria)
      )
      return newParametroConcessionaria
    } catch (e) {
      dispatch(loadingParametroConcessionariaFailure(e))
      return false
    }
  }
}

export function updateParametroConcessionaria(id, parametroConcessionaria) {
  return async function (dispatch /* getState */) {
    try {
      dispatch(loadingParametroConcessionaria())
      const newParametroConcessionaria = await callAPI(
        `/parametros_concessionaria/${id}`,
        'PUT',
        parametroConcessionaria
      )
      dispatch(
        loadingParametroConcessionariaSuccess(newParametroConcessionaria)
      )
      return newParametroConcessionaria
    } catch (e) {
      dispatch(loadingParametroConcessionariaFailure(e))
      return false
    }
  }
}

export function destroyParametroConcessionaria(id) {
  return async function (dispatch /* getState */) {
    try {
      dispatch(loadingParametroConcessionaria())
      await callAPI(`/parametros_concessionaria/${id}`, 'DELETE')
      dispatch(destroyingParametroConcessionariaSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingParametroConcessionariaFailure(e))
      return false
    }
  }
}

export function copyParametroConcessionaria(id) {
  return async function (dispatch /* getState */) {
    try {
      dispatch(loadingParametroConcessionaria())
      const newParametroConcessionaria = await callAPI(
        `/parametros_concessionaria/${id}/copy`,
        'POST'
      )
      dispatch(
        copyingParametroConcessionariaSuccess(newParametroConcessionaria)
      )
      return newParametroConcessionaria
    } catch (e) {
      dispatch(loadingParametroConcessionariaFailure(e))
      return false
    }
  }
}

export default function parametroConcessionaria(state = initialState, action) {
  switch (action.type) {
    case LOADING_PARAMETRO_CONCESSIONARIA_SUCCESS: {
      const { parametroConcessionaria } = action
      if (parametroConcessionaria.constructor === Array) {
        state = initialState
        parametroConcessionaria.forEach(
          parametroConcessionaria =>
            (state = state.merge({
              [parametroConcessionaria.id]: parametroConcessionaria,
            }))
        )
        return state
      }
      return state.merge({
        [parametroConcessionaria.id]: parametroConcessionaria,
      })
    }

    case DESTROYING_PARAMETRO_CONCESSIONARIA_SUCCESS:
      return state.delete(action.parametroConcessionariaId.toString())

    case COPYING_PARAMETRO_CONCESSIONARIA_SUCCESS:
      const { parametroConcessionaria } = action
      return state.merge({
        [parametroConcessionaria]: parametroConcessionaria,
      })

    default:
      return state
  }
}
