import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_CENARIO = 'LOADING_CENARIO'
export function loadingCenario () {
  return {
    type: LOADING_CENARIO,
  }
}

export const LOADING_CENARIO_SUCCESS = 'LOADING_CENARIO_SUCCESS'
export function loadingCenarioSuccess (cenario) {
  return {
    type: LOADING_CENARIO_SUCCESS,
    cenario,
  }
}

export const LOADING_CENARIO_FAILURE = 'LOADING_CENARIO_FAILURE'
export function loadingCenarioFailure (error) {
  return {
    type: LOADING_CENARIO_FAILURE,
    error,
  }
}

export const DESTROYING_CENARIO_SUCCESS = 'DESTROYING_CENARIO_SUCCESS'
export function destroyingCenarioSuccess (cenarioId) {
  return {
    type: DESTROYING_CENARIO_SUCCESS,
    cenarioId,
  }
}

const initialState = Map({})

export function fetchMultipleCenario (instalacaoId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingCenario())
      const multipleCenario = await callAPI(`/instalacoes/${instalacaoId}/cenarios`)
      dispatch(loadingCenarioSuccess(multipleCenario))
      return multipleCenario
    } catch (e) {
      dispatch(loadingCenarioFailure(e))
      return false
    }
  }
}

export function fetchCenario (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingCenario())
      const cenario = await callAPI(`/cenarios/${id}`)
      dispatch(loadingCenarioSuccess(cenario))
      return cenario
    } catch (e) {
      dispatch(loadingCenarioFailure(e))
      return false
    }
  }
}

export function createCenario (instalacaoId, cenario) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingCenario())
      const newCenario = await callAPI(`/instalacoes/${instalacaoId}/cenarios`, 'POST', cenario)
      dispatch(loadingCenarioSuccess(newCenario))
      return newCenario
    } catch (e) {
      dispatch(loadingCenarioFailure(e))
      return false
    }
  }
}

export function updateCenario (id, cenario) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingCenario())
      const newCenario = await callAPI(`/cenarios/${id}`, 'PUT', cenario)
      dispatch(loadingCenarioSuccess(newCenario))
      return newCenario
    } catch (e) {
      dispatch(loadingCenarioFailure(e))
      return false
    }
  }
}

export function destroyCenario (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingCenario())
      await callAPI(`/cenarios/${id}`, 'DELETE')
      dispatch(destroyingCenarioSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingCenarioFailure(e))
      return false
    }
  }
}

export default function cenario (state = initialState, action) {
  switch (action.type) {

    case LOADING_CENARIO_SUCCESS : {
      const { cenario } = action
      if (cenario.constructor === Array) {
        state = initialState
        cenario.forEach((vigencia) => (
          state = state.merge({[vigencia.id]: vigencia})
        ))
        return state
      }
      return state.merge({[cenario.id]: cenario})
    }

    case DESTROYING_CENARIO_SUCCESS :
      return state.delete(action.cenarioId.toString())

    default :
      return state
  }
}
