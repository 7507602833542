import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { EnquadramentoForm } from '../../components'
import * as enquadramentoActionCreators from '../../redux/modules/enquadramento'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { multipleTipoDemanda } from '../../utils/enums/tipoDemanda'
import { multipleTipoTarifa } from '../../utils/enums/tipoTarifa'

class EnquadramentoFormContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    fetchEnquadramento: PropTypes.func.isRequired,
    createEnquadramento: PropTypes.func.isRequired,
    updateEnquadramento: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    if (this.props.id) {
      const enquadramento = await this.props.fetchEnquadramento(this.props.id)
      this.props.initialize('EnquadramentoForm', {enquadramento})
    }
  }

  handleSubmitResource = async (enquadramento) => {
    if (this.props.id) {
      if (await this.props.updateEnquadramento(this.props.id, enquadramento)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push('/enquadramentos')
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createEnquadramento(enquadramento)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/enquadramentos')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <EnquadramentoForm
        onSubmit={(enquadramento) => this.handleSubmitResource(enquadramento)}
        multipleTipoDemanda={multipleTipoDemanda}
        multipleTipoTarifa={multipleTipoTarifa}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({enquadramento, status, flashMessage}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      enquadramento: enquadramento.get('id'),
      error: status.getIn(['enquadramento', 'error']),
      isLoading: status.getIn(['enquadramento', 'isLoading']),
      shouldHideFlashMessage: flashMessage.get('isError'),
    }
  }
  return {
    error: status.getIn(['enquadramento', 'error']),
    isLoading: status.getIn(['enquadramento', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...enquadramentoActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EnquadramentoFormContainer)
