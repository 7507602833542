import React from 'react'
import PropTypes from 'prop-types'
import { Table, Loader, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { CRUDIndex } from '../CRUD'
import { EditDeleteButtonContainer } from '../../containers'
import { maskCurrency } from '../../utils/masks'

const Gerador = (props) => (
  <CRUDIndex
    title='Geradores'
    icon='rocket'
    onRedirectToNew={props.onRedirectToNew}
    onExportToCSV={props.onExportToCSV}
    filterText={props.filterText}
    handleFilter={props.handleFilter}
  >
    <Table sortable celled striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1} textAlign='center'
            onClick={props.handleSort('id')}
            sorted={props.handleSorted('id')}>#</Table.HeaderCell>
          <Table.HeaderCell width={2}
            onClick={props.handleSort('tipo')}
            sorted={props.handleSorted('tipo')}>Tipo</Table.HeaderCell>
          <Table.HeaderCell width={2}
            onClick={props.handleSort('marca')}
            sorted={props.handleSorted('marca')}>Marca</Table.HeaderCell>
          <Table.HeaderCell width={2}
            onClick={props.handleSort('modelo')}
            sorted={props.handleSorted('modelo')}>Modelo</Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign='right'
            onClick={props.handleSort('eficiencia')}
            sorted={props.handleSorted('eficiencia')}>Eficiência</Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign='right'
            onClick={props.handleSort('potencia')}
            sorted={props.handleSorted('potencia')}>Potência (kVA)</Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign='right'
            onClick={props.handleSort('potencia_ativa')}
            sorted={props.handleSorted('potencia_ativa')}>Potência (kW)</Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign='right'
            onClick={props.handleSort('potencia_cv')}
            sorted={props.handleSorted('potencia_cv')}>Potência (cv)</Table.HeaderCell>
          <Table.HeaderCell width={2}
            onClick={props.handleSort('combustivel')}
            sorted={props.handleSorted('combustivel')}>Combustível</Table.HeaderCell>
          <Table.HeaderCell width={2} textAlign='right'
            onClick={props.handleSort('consumo_combustivel')}
            sorted={props.handleSorted('consumo_combustivel')}>Consumo Combustível/Hora</Table.HeaderCell>
          <Table.HeaderCell width={1}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.isLoading && (
          <Table.Row>
            <Table.Cell colSpan='11'>
              <Loader active={props.isLoading} inline='centered' />
            </Table.Cell>
          </Table.Row>
        )}
        {props.multipleGerador.valueSeq().map((gerador, index) => (
          <Table.Row key={index}>
            <Table.Cell textAlign='center'>
              <Label as={Link} to={`/geradores/${gerador.get('id')}`}>{gerador.get('id')}</Label>
            </Table.Cell>
            <Table.Cell>{gerador.get('tipo')}</Table.Cell>
            <Table.Cell>{gerador.get('marca')}</Table.Cell>
            <Table.Cell>{gerador.get('modelo')}</Table.Cell>
            <Table.Cell textAlign='right'>{maskCurrency.parse(gerador.get('eficiencia'))}</Table.Cell>
            <Table.Cell textAlign='right'>{maskCurrency.parse(gerador.get('potencia'))}</Table.Cell>
            <Table.Cell textAlign='right'>{maskCurrency.parse(gerador.get('potencia_ativa'))}</Table.Cell>
            <Table.Cell textAlign='right'>{maskCurrency.parse(gerador.get('potencia_cv'))}</Table.Cell>
            <Table.Cell>{gerador.get('combustivel')} ({gerador.get('unidade_combustivel')})</Table.Cell>
            <Table.Cell textAlign='right'>{maskCurrency.parse(gerador.get('consumo_combustivel'))}</Table.Cell>
            <Table.Cell>
              <EditDeleteButtonContainer
                onEditResource={() => props.onEditResource(gerador.get('id'))}
                onDeleteResource={() => props.onDeleteResource(gerador.get('id'))} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </CRUDIndex>
)

Gerador.propTypes = {
  onRedirectToNew: PropTypes.func,
  multipleGerador: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default Gerador
