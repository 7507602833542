import React from 'react'
import { Dimmer, Header, Icon } from 'semantic-ui-react'

const errorMessage = type => {
  switch(type) {
    case 401: return 'Não autorizado'
    case 404: return 'Página não encontrada'
    case 500: return 'Erro interno do servidor'
    default:  return 'Erro genérico'
  }
}

const Error = (props) => {
  return (
    <Dimmer active>
      <Header as='h2' icon inverted>
        <Icon name='exclamation triangle' color='yellow' />
        Oops! Um erro ocorreu.
        <Header.Subheader>
          {errorMessage(props.type)}.
        </Header.Subheader>
      </Header>
    </Dimmer>
  )
}

export default Error
