import React from "react";

const PainelResumoContext = React.createContext({
    isLoading: false,
    selectedCard: -1,
    mercado: 'ambos',
    mes: new Date().getMonth()+1,
    ano: new Date().getFullYear(),
    clientesFiltrados: [],
    setIsLoading: () => {},
    setSelectedCard: () => {},
    setMercado: () => {},
    setMes: () => {},
    setAno: () => {},
    setClientesFiltrados: () => {},
})

export default PainelResumoContext
