import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { PldSemanalImport } from '../../components'
import * as pldSemanalActionCreators from '../../redux/modules/pldSemanal'
import * as dataImporterActionCreators from '../../redux/modules/dataImporter'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

class PldSemanalFormContainer extends Component {

  static propTypes = {
    updatePldSemanal: PropTypes.func,
    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
    initialize: PropTypes.func,
    history: PropTypes.object,
    importData: PropTypes.object,
    clearImportData: PropTypes.func,
    shouldHideFlashMessage: PropTypes.bool,
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }
  handleSubmit = async () => {
    let batch = []
    batch = this.props.importData.map(p => { return { 'pld_semanal': p }})
    if (await this.props.updatePldSemanal({ batch })) {
      this.props.clearImportData('pldSemanal')
      this.props.showSuccessfulFlashMessage('', 'update')
      this.props.history.push('/indicativos/semanais')
    } else {
      this.props.showErrorFlashMessage('', 'update', this.props.error)
    }
  }

  onDiscard = () => {
    this.props.clearImportData('pldSemanal')
    this.props.history.push('/indicativos/semanais')
  }

  render () {
    return (
      <PldSemanalImport
        isLoading={this.props.isLoading}
        pldSemanal={this.props.importData}
        onDiscard={this.onDiscard}
        handleSubmit={this.handleSubmit}
      />
    )
  }
}

function mapStateToProps ({ dataImporter, status, flashMessage }) {
  return {
    error: status.getIn(['pldSemanal', 'error']),
    isLoading: status.getIn(['pldSemanal', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
    importData: dataImporter.getIn(['pldSemanal', 'importData'])
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...pldSemanalActionCreators,
    ...dataImporterActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PldSemanalFormContainer)
