import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_FUNCAO = 'LOADING_FUNCAO'
export function loadingFuncao () {
  return {
    type: LOADING_FUNCAO,
  }
}

export const LOADING_FUNCAO_SUCCESS = 'LOADING_FUNCAO_SUCCESS'
export function loadingFuncaoSuccess (funcao) {
  return {
    type: LOADING_FUNCAO_SUCCESS,
    funcao,
  }
}

export const LOADING_FUNCAO_FAILURE = 'LOADING_FUNCAO_FAILURE'
export function loadingFuncaoFailure (error) {
  return {
    type: LOADING_FUNCAO_FAILURE,
    error,
  }
}

export const DESTROYING_FUNCAO_SUCCESS = 'DESTROYING_FUNCAO_SUCCESS'
export function destroyingFuncaoSuccess (funcaoId) {
  return {
    type: DESTROYING_FUNCAO_SUCCESS,
    funcaoId,
  }
}

const initialState = Map({})

export function fetchMultipleFuncao () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFuncao())
      const multipleFuncao = await callAPI('/funcoes')
      dispatch(loadingFuncaoSuccess(multipleFuncao))
      return multipleFuncao
    } catch (e) {
      dispatch(loadingFuncaoFailure(e))
      return false
    }
  }
}

export function fetchFuncao (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFuncao())
      const funcao = await callAPI(`/funcoes/${id}`)
      dispatch(loadingFuncaoSuccess(funcao))
      return funcao
    } catch (e) {
      dispatch(loadingFuncaoFailure(e))
      return false
    }
  }
}

export function createFuncao (funcao) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFuncao())
      const newFuncao = await callAPI('/funcoes', 'POST', funcao)
      dispatch(loadingFuncaoSuccess(newFuncao))
      return newFuncao
    } catch (e) {
      dispatch(loadingFuncaoFailure(e))
      return false
    }
  }
}

export function updateFuncao (id, funcao) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFuncao())
      const newFuncao = await callAPI(`/funcoes/${id}`, 'PUT', funcao)
      dispatch(loadingFuncaoSuccess(newFuncao))
      return newFuncao
    } catch (e) {
      dispatch(loadingFuncaoFailure(e))
      return false
    }
  }
}

export function destroyFuncao (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFuncao())
      await callAPI(`/funcoes/${id}`, 'DELETE')
      dispatch(destroyingFuncaoSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingFuncaoFailure(e))
      return false
    }
  }
}

export default function funcao (state = initialState, action) {
  switch (action.type) {

    case LOADING_FUNCAO_SUCCESS : {
      const { funcao } = action
      if (funcao.constructor === Array) {
        state = initialState
        funcao.forEach((b) => (
          state = state.merge({[b.id]: b})
        ))
        return state
      }
      return state.merge({[funcao.id]: funcao})
    }

    case DESTROYING_FUNCAO_SUCCESS :
      return state.delete(action.funcaoId.toString())

    default :
      return state
  }
}
