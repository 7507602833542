import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { FonteComercializadoraShow } from '../../components'
import * as fonteComercializadoraActionCreators from '../../redux/modules/fonteComercializadora'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

class FonteComercializadoraShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    comercializadora: PropTypes.object.isRequired,

    fetchFonteComercializadora: PropTypes.func.isRequired,
    destroyFonteComercializadora: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
  }

  state = {
    fonteComercializadora: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const fonteComercializadora = await this.props.fetchFonteComercializadora(this.props.id)
      this.setState({ fonteComercializadora })
    }
  }

  handleEditResource = (comercializadoraId, id) => {
    this.props.history.push(`/comercializadoras/${comercializadoraId}/fontes_comercializadoras/${id}/edit`)
  }

  handleDeleteResource = async (comercializadoraId, id) => {
    if (await this.props.destroyFonteComercializadora(id)) {
      this.props.showSuccessfulFlashMessage('', 'destroy')
      this.props.history.push(`/comercializadoras/${comercializadoraId}/fontes_comercializadoras`)
    } else {
      this.props.showErrorFlashMessage('', 'destroy', this.props.error)
    }
  }

  render () {
    return (
      <FonteComercializadoraShow noTitle
        comercializadora={this.props.comercializadora}
        fonteComercializadora={this.state.fonteComercializadora}
        onEditResource={() => this.handleEditResource(this.props.comercializadora.id, this.state.fonteComercializadora.id)}
        onDeleteResource={() => this.handleDeleteResource(this.props.comercializadora.id, this.state.fonteComercializadora.id)} />
    )
  }
}

function mapStateToProps ({fonteComercializadora}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      fonteComercializadora: fonteComercializadora.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...fonteComercializadoraActionCreators,
    ...flashMessageActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FonteComercializadoraShowContainer))
