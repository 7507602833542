import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { SubgrupoForm } from '../../components'
import * as subgrupoActionCreators from '../../redux/modules/subgrupo'
import * as enquadramentoActionCreators from '../../redux/modules/enquadramento'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { mapToDropdown, parseSubgrupo } from '../../utils/parse'
import { maskCurrency } from '../../utils/masks'

class SubgrupoFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    fetchSubgrupo: PropTypes.func.isRequired,
    createSubgrupo: PropTypes.func.isRequired,
    updateSubgrupo: PropTypes.func.isRequired,
    fetchMultipleEnquadramento: PropTypes.func.isRequired,
    multipleEnquadramento: PropTypes.object,
    enquadramentoDropdown: PropTypes.array.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleEnquadramento()
    if (this.props.id) {
      let subgrupo = await this.props.fetchSubgrupo(this.props.id)
      subgrupo = parseSubgrupo(subgrupo)
      subgrupo.fator_de_potencia = maskCurrency.parse(Number(subgrupo.fator_de_potencia))
      this.props.initialize('SubgrupoForm', {subgrupo})
    }
  }

  handleSubmitResource = async (subgrupo) => {
    let fator_de_potencia = maskCurrency.unmask(subgrupo.getIn(['subgrupo', 'fator_de_potencia']))
    subgrupo = subgrupo.setIn(['subgrupo', 'fator_de_potencia'], fator_de_potencia)

    if (this.props.id) {
      if (await this.props.updateSubgrupo(this.props.id, subgrupo)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push('/subgrupos')
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createSubgrupo(subgrupo)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/subgrupos')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <SubgrupoForm
        enquadramentoDropdown={this.props.enquadramentoDropdown}
        multipleEnquadramento={this.props.multipleEnquadramento}
        onSubmit={(subgrupo) => this.handleSubmitResource(subgrupo)}
        isLoading={this.props.isLoading} />
    )
  }

}

function mapStateToProps ({subgrupo, enquadramento, status, flashMessage}, {match}) {
  const { id } = match.params
  const enquadramentoDropdown = mapToDropdown(
    enquadramento,
    e => e.get('id'),
    e => e.get('modalidade')
  )
  const isLoading = status.getIn(['subgrupo', 'isLoading'])
    || status.getIn(['enquadramento', 'isLoading'])

  if (id) {
    return {
      id,
      subgrupo: subgrupo.get('id'),
      error: status.getIn(['subgrupo', 'error']),
      isLoading,
      shouldHideFlashMessage: flashMessage.get('isError'),
      enquadramentoDropdown,
    }
  }
  return {
    enquadramentoDropdown,
    error: status.getIn(['subgrupo', 'error']),
    isLoading: status.getIn(['subgrupo', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...subgrupoActionCreators,
    ...enquadramentoActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SubgrupoFormContainer)
