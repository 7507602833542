import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_CONTATO_COMERCIALIZADORA = 'LOADING_CONTATO_COMERCIALIZADORA'
export function loadingContatoComercializadora () {
  return {
    type: LOADING_CONTATO_COMERCIALIZADORA,
  }
}

export const LOADING_CONTATO_COMERCIALIZADORA_SUCCESS = 'LOADING_CONTATO_COMERCIALIZADORA_SUCCESS'
export function loadingContatoComercializadoraSuccess (contatoComercializadora) {
  return {
    type: LOADING_CONTATO_COMERCIALIZADORA_SUCCESS,
    contatoComercializadora,
  }
}

export const LOADING_CONTATO_COMERCIALIZADORA_FAILURE = 'LOADING_CONTATO_COMERCIALIZADORA_FAILURE'
export function loadingContatoComercializadoraFailure (error) {
  return {
    type: LOADING_CONTATO_COMERCIALIZADORA_FAILURE,
    error,
  }
}

export const DESTROYING_CONTATO_COMERCIALIZADORA_SUCCESS = 'DESTROYING_CONTATO_COMERCIALIZADORA_SUCCESS'
export function destroyingContatoComercializadoraSuccess (contatoComercializadoraId) {
  return {
    type: DESTROYING_CONTATO_COMERCIALIZADORA_SUCCESS,
    contatoComercializadoraId,
  }
}

const initialState = Map({})

export function fetchMultipleContatoComercializadora (comercializadoraId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoComercializadora())
      const multipleContatoComercializadora = await callAPI(`/comercializadoras/${comercializadoraId}/contatos`)
      dispatch(loadingContatoComercializadoraSuccess(multipleContatoComercializadora))
      return multipleContatoComercializadora
    } catch (e) {
      dispatch(loadingContatoComercializadoraFailure(e))
      return false
    }
  }
}

export function fetchContatoComercializadora (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoComercializadora())
      const contatoComercializadora = await callAPI(`/contatos/${id}`)
      dispatch(loadingContatoComercializadoraSuccess(contatoComercializadora))
      return contatoComercializadora
    } catch (e) {
      dispatch(loadingContatoComercializadoraFailure(e))
      return false
    }
  }
}

export function createContatoComercializadora (comercializadoraId, contatoComercializadora) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoComercializadora())
      const newContatoComercializadora = await callAPI(`/comercializadoras/${comercializadoraId}/contatos`, 'POST', contatoComercializadora)
      dispatch(loadingContatoComercializadoraSuccess(newContatoComercializadora))
      return newContatoComercializadora
    } catch (e) {
      dispatch(loadingContatoComercializadoraFailure(e))
      return false
    }
  }
}

export function updateContatoComercializadora (id, contatoComercializadora) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoComercializadora())
      const newContatoComercializadora = await callAPI(`/contatos/${id}`, 'PUT', contatoComercializadora)
      dispatch(loadingContatoComercializadoraSuccess(newContatoComercializadora))
      return newContatoComercializadora
    } catch (e) {
      dispatch(loadingContatoComercializadoraFailure(e))
      return false
    }
  }
}

export function destroyContatoComercializadora (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoComercializadora())
      await callAPI(`/contatos/${id}`, 'DELETE')
      dispatch(destroyingContatoComercializadoraSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingContatoComercializadoraFailure(e))
      return false
    }
  }
}

export default function contatoComercializadora (state = initialState, action) {
  switch (action.type) {

    case LOADING_CONTATO_COMERCIALIZADORA_SUCCESS : {
      const { contatoComercializadora } = action
      if (contatoComercializadora.constructor === Array) {
        state = initialState
        contatoComercializadora.forEach((c) => (
          state = state.merge({[c.id]: c})
        ))
        return state
      }
      return state.merge({[contatoComercializadora.id]: contatoComercializadora})
    }

    case DESTROYING_CONTATO_COMERCIALIZADORA_SUCCESS :
      return state.delete(action.contatoComercializadoraId.toString())

    default :
      return state
  }
}
