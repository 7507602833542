import React from 'react'
import PropTypes from 'prop-types'

import { Table } from 'semantic-ui-react'
import { maskFloat } from '../../utils/masks'

const fields = [
  {
    label: 'Custo Mensal',
    field: 'custo_total',
    format: (field, data) => `R$ ${maskFloat.parse(data.get(field))}`,
  },
  {
    label: 'Consumo Mensal',
    field: 'consumo_total',
    format: (field, data) => `${maskFloat.parse(data.get(field))} ${data.getIn(['gerador', 'unidade_combustivel'])}`,
  },
  {
    label: 'Custo Unitário',
    field: 'custo_unitario',
    format: (field, data) => `R$ ${maskFloat.parse(data.get(field))}/${data.getIn(['gerador', 'unidade_combustivel'])}`,
  },
  {
    label: 'Potência Total',
    field: 'potencia_total',
    format: (field, data) => `${maskFloat.parse(data.get(field))} kVA`,
  },
  {
    label: 'Consumo Horário',
    field: 'consumo_horario',
    format: (field, data) => `${maskFloat.parse(data.get(field))} ${data.getIn(['gerador', 'unidade_combustivel'])}/h`,
  },
  {
    label: 'Energia Gerada',
    field: 'energia_gerada',
    format: (field, data) => `${maskFloat.parse(data.get(field))} kWh`,
  },
]

const Gerador = ({ data, title, color }) => {
  if (!data) return (null)

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={4} style={{backgroundColor: color}} textAlign='right'>{title}</Table.HeaderCell>
          <Table.HeaderCell width={12} style={{backgroundColor: color}} textAlign='right'>Valor</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {fields.map((field, index) =>
          <Table.Row key={index}>
            <Table.Cell textAlign='right'>{field.label}</Table.Cell>
            <Table.Cell textAlign='right' style={{fontFamily: 'monospace'}}>
              {field.format(field.field, data)}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}

Gerador.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  data: PropTypes.object,
}

export default Gerador
