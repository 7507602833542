import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, formValueSelector } from 'redux-form/immutable'

import { Table } from 'semantic-ui-react'
import { SemanticInputMask } from '../FormComponents'

import { required } from '../../utils/validations'
import { maskFloat } from '../../utils/masks'
import { getPostos } from '../../utils/enums/tipoTarifa'

const selector = formValueSelector('VigenciaTarifaForm')
const DECIMAL_DIGITS = 6

const formPosto = (valorTarifa, loading, posto) => (
  <React.Fragment>
    <Table.Cell textAlign='center' rowSpan={1}>{posto.descricao}</Table.Cell>
    <Table.Cell textAlign='right' rowSpan={1}>
      <Field fluid
        name={`${valorTarifa}.tusd_kw_${posto.sigla}`}
        placeholder='0,000000'
        mask={maskFloat.mask(DECIMAL_DIGITS)}
        validate={[required]}
        component={SemanticInputMask}
        loading={loading}
        label='R$/kW'
      />
    </Table.Cell>
    <Table.Cell textAlign='right' rowSpan={1}>
      <Field fluid
        name={`${valorTarifa}.tusd_kwh_${posto.sigla}`}
        placeholder='0,000000'
        mask={maskFloat.mask(DECIMAL_DIGITS)}
        validate={[required]}
        component={SemanticInputMask}
        loading={loading}
        label='R$/MWh'
      />
    </Table.Cell>
    <Table.Cell textAlign='right' rowSpan={1}>
      <Field fluid
        name={`${valorTarifa}.te_kwh_${posto.sigla}`}
        placeholder='0,000000'
        mask={maskFloat.mask(DECIMAL_DIGITS)}
        validate={[required]}
        component={SemanticInputMask}
        loading={loading}
        label='R$/MWh'
      />
    </Table.Cell>
  </React.Fragment>
)

let ValorTarifa = ({ valorTarifa, loading, subgrupo_id, enquadramento_id, multipleSubgrupo, multipleEnquadramento }) => {
  const subgrupo = multipleSubgrupo.get(`${subgrupo_id}`)
  const enquadramento = multipleEnquadramento.get(`${enquadramento_id}`)
  const postos = getPostos(enquadramento, subgrupo)
  const totalPostos = postos.length

  if (totalPostos === 0) return (null)
  if (totalPostos === 1) return (
    <Table.Body>
      <Table.Row>
        <Table.Cell textAlign='center'>{ subgrupo.get('nome') }</Table.Cell>
        <Table.Cell textAlign='center'>{ enquadramento.get('modalidade') }</Table.Cell>
        {formPosto(valorTarifa, loading, postos[0])}
        <Table.Cell textAlign='right'>
          <Field fluid
            name={`${valorTarifa}.tr_kwh`}
            placeholder='0,000000'
            mask={maskFloat.mask(DECIMAL_DIGITS)}
            validate={[required]}
            component={SemanticInputMask}
            loading={loading}
            label='R$/MWh'
          />
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  )

  return (
    <Table.Body>
      <Table.Row>
        <Table.Cell textAlign='center' rowSpan={totalPostos}>{ subgrupo.get('nome') }</Table.Cell>
        <Table.Cell textAlign='center' rowSpan={totalPostos}>{ enquadramento.get('modalidade') }</Table.Cell>
        {formPosto(valorTarifa, loading, postos[0])}
        <Table.Cell textAlign='right' rowSpan={totalPostos}>
          <Field fluid
            name={`${valorTarifa}.tr_kwh`}
            placeholder='0,000000'
            mask={maskFloat.mask(DECIMAL_DIGITS)}
            validate={[required]}
            component={SemanticInputMask}
            loading={loading}
            label='R$/MWh'
          />
        </Table.Cell>
      </Table.Row>
      {postos.slice(1, postos.length).map((p, index) => {
        return (
          <Table.Row key={index}>{formPosto(valorTarifa, loading, p)}</Table.Row>
        )
      })}
    </Table.Body>
  )
}

ValorTarifa = connect(
  (state, props) => {
    return ({
      subgrupo_id: selector(state, `vigencia_tarifa.${props.valorTarifa}.subgrupo_id`),
      enquadramento_id: selector(state, `vigencia_tarifa.${props.valorTarifa}.enquadramento_id`)
    })
  }
)(ValorTarifa)

function mapStateToProps ({ subgrupo, enquadramento }) {
  return {
    multipleSubgrupo: subgrupo,
    multipleEnquadramento: enquadramento,
  }
}

ValorTarifa.propTypes = {
  valorTarifa: PropTypes.string,
  loading: PropTypes.bool,
  subgrupo_id: PropTypes.number,
  enquadramento_id: PropTypes.number,
  multipleSubgrupo: PropTypes.object,
  multipleEnquadramento: PropTypes.object,
}

export default connect(mapStateToProps)(ValorTarifa)
