import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { FonteComercializadoraForm } from '../../components'
import * as fonteComercializadoraActionCreators from '../../redux/modules/fonteComercializadora'
import * as fonteEnergiaActionCreators from '../../redux/modules/fonteEnergia'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

import { mapToDropdown } from '../../utils/parse'

class FonteComercializadoraFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    initialize: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    comercializadora: PropTypes.object,

    fetchFonteComercializadora: PropTypes.func.isRequired,
    createFonteComercializadora: PropTypes.func.isRequired,
    updateFonteComercializadora: PropTypes.func.isRequired,

    fonteEnergiaDropdown: PropTypes.array.isRequired,
    fetchMultipleFonteEnergia: PropTypes.func.isRequired,

    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleFonteEnergia()
    if (this.props.id) {
      const fonte_comercializadora = await this.props.fetchFonteComercializadora(this.props.id)
      this.props.initialize('FonteComercializadoraForm', { fonte_comercializadora })
    } else {
      this.props.initialize('FonteComercializadoraForm', {
        fonte_comercializadora: {
          comercializadora_id: this.props.comercializadora.id
        }
      })
    }
  }

  handleSubmitResource = async (fonteComercializadora) => {
    const comercializadoraId = this.props.comercializadora.id

    if (this.props.id) {
      if (await this.props.updateFonteComercializadora(this.props.id, fonteComercializadora)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/comercializadoras/${comercializadoraId}/fontes_comercializadoras`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createFonteComercializadora(comercializadoraId, fonteComercializadora)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push(`/comercializadoras/${comercializadoraId}/fontes_comercializadoras`)
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <FonteComercializadoraForm
        comercializadora={this.props.comercializadora}
        fonteEnergiaDropdown={this.props.fonteEnergiaDropdown}
        onSubmit={(fonteComercializadora) => this.handleSubmitResource(fonteComercializadora)}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({fonteComercializadora, fonteEnergia, status, flashMessage}, {match}) {
  const { id } = match.params

  const fonteEnergiaDropdown = mapToDropdown(
    fonteEnergia,
    e => e.get('id'),
    e => e.get('nome')
  )

  if (id) {
    return {
      id,
      fonteComercializadora: fonteComercializadora.get('id'),
      fonteEnergiaDropdown,
      error: status.getIn(['fonteComercializadora', 'error']),
      shouldHideFlashMessage: flashMessage.get('isError'),
    }
  }
  return {
    fonteEnergiaDropdown,
    error: status.getIn(['fonteComercializadora', 'error']),
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...fonteComercializadoraActionCreators,
    ...fonteEnergiaActionCreators,
    ...flashMessageActionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FonteComercializadoraFormContainer))
