import { fromJS } from 'immutable'

import {
  LOADING_UNIDADE,
  LOADING_UNIDADE_SUCCESS,
  LOADING_UNIDADE_FAILURE,
} from './unidade'

import {
  LOADING_ICMS,
  LOADING_ICMS_SUCCESS,
  LOADING_ICMS_FAILURE,
  DESTROYING_ICMS_SUCCESS,
} from './icms'

import {
  LOADING_CENARIO,
  LOADING_CENARIO_SUCCESS,
  LOADING_CENARIO_FAILURE,
  DESTROYING_CENARIO_SUCCESS,
} from './cenario'

import {
  LOADING_CALCULADORA_CONSUMO,
  LOADING_CALCULADORA_CONSUMO_SUCCESS,
  LOADING_CALCULADORA_CONSUMO_FAILURE,
} from './calculadoraConsumo'

import {
  LOADING_BANDEIRA,
  LOADING_BANDEIRA_SUCCESS,
  LOADING_BANDEIRA_FAILURE,
  DESTROYING_BANDEIRA_SUCCESS,
} from './bandeira'

import {
  LOADING_VIGENCIA_BANDEIRA,
  LOADING_VIGENCIA_BANDEIRA_SUCCESS,
  LOADING_VIGENCIA_BANDEIRA_FAILURE,
  DESTROYING_VIGENCIA_BANDEIRA_SUCCESS,
} from './vigenciaBandeira'

import {
  LOADING_VIGENCIA_PARAMETRO,
  LOADING_VIGENCIA_PARAMETRO_SUCCESS,
  LOADING_VIGENCIA_PARAMETRO_FAILURE,
  DESTROYING_VIGENCIA_PARAMETRO_SUCCESS,
} from './vigenciaParametro'

import {
  LOADING_RELATORIO,
  LOADING_RELATORIO_SUCCESS,
  LOADING_RELATORIO_FAILURE,
  DESTROYING_RELATORIO_SUCCESS,
} from './relatorio'

import {
  LOADING_VIGENCIA_TARIFA,
  LOADING_VIGENCIA_TARIFA_SUCCESS,
  LOADING_VIGENCIA_TARIFA_FAILURE,
  DESTROYING_VIGENCIA_TARIFA_SUCCESS,
} from './vigenciaTarifa'

import {
  LOADING_VIGENCIA_TRIBUTO,
  LOADING_VIGENCIA_TRIBUTO_SUCCESS,
  LOADING_VIGENCIA_TRIBUTO_FAILURE,
  DESTROYING_VIGENCIA_TRIBUTO_SUCCESS,
} from './vigenciaTributo'

import {
  LOADING_VIGENCIA_VALOR,
  LOADING_VIGENCIA_VALOR_SUCCESS,
  LOADING_VIGENCIA_VALOR_FAILURE,
  DESTROYING_VIGENCIA_VALOR_SUCCESS,
} from './vigenciaValor'

import {
  LOADING_SIMULATION,
  LOADING_SIMULATION_SUCCESS,
  LOADING_SIMULATION_FAILURE,
} from './simulation'

import {
  LOADING_CLASSE_CONSUMO,
  LOADING_CLASSE_CONSUMO_SUCCESS,
  LOADING_CLASSE_CONSUMO_FAILURE,
  DESTROYING_CLASSE_CONSUMO_SUCCESS,
} from './classeConsumo'

import {
  LOADING_CLIENTE,
  LOADING_CLIENTE_SUCCESS,
  LOADING_CLIENTE_FAILURE,
  DESTROYING_CLIENTE_SUCCESS,
} from './cliente'

import {
  LOADING_COMERCIALIZADORA,
  LOADING_COMERCIALIZADORA_SUCCESS,
  LOADING_COMERCIALIZADORA_FAILURE,
  DESTROYING_COMERCIALIZADORA_SUCCESS,
} from './comercializadora'

import {
  LOADING_FONTE_COMERCIALIZADORA,
  LOADING_FONTE_COMERCIALIZADORA_SUCCESS,
  LOADING_FONTE_COMERCIALIZADORA_FAILURE,
  DESTROYING_FONTE_COMERCIALIZADORA_SUCCESS,
} from './fonteComercializadora'

import {
  LOADING_CONTATO_CLIENTE,
  LOADING_CONTATO_CLIENTE_SUCCESS,
  LOADING_CONTATO_CLIENTE_FAILURE,
  DESTROYING_CONTATO_CLIENTE_SUCCESS,
} from './contatoCliente'

import {
  LOADING_CONTATO_COMERCIALIZADORA,
  LOADING_CONTATO_COMERCIALIZADORA_SUCCESS,
  LOADING_CONTATO_COMERCIALIZADORA_FAILURE,
  DESTROYING_CONTATO_COMERCIALIZADORA_SUCCESS,
} from './contatoComercializadora'

import {
  LOADING_CONTATO_CONCESSIONARIA,
  LOADING_CONTATO_CONCESSIONARIA_SUCCESS,
  LOADING_CONTATO_CONCESSIONARIA_FAILURE,
  DESTROYING_CONTATO_CONCESSIONARIA_SUCCESS,
} from './contatoConcessionaria'

import {
  LOADING_CONTATO_INSTALACAO,
  LOADING_CONTATO_INSTALACAO_SUCCESS,
  LOADING_CONTATO_INSTALACAO_FAILURE,
  DESTROYING_CONTATO_INSTALACAO_SUCCESS,
} from './contatoInstalacao'

import {
  LOADING_CONTRATO_DEMANDA_INSTALACAO,
  LOADING_CONTRATO_DEMANDA_INSTALACAO_SUCCESS,
  LOADING_CONTRATO_DEMANDA_INSTALACAO_FAILURE,
} from './contratoDemandaInstalacao'

import {
  LOADING_CONTRATO_ENERGIA_INSTALACAO,
  LOADING_CONTRATO_ENERGIA_INSTALACAO_SUCCESS,
  LOADING_CONTRATO_ENERGIA_INSTALACAO_FAILURE,
  DESTROYING_CONTRATO_ENERGIA_INSTALACAO_SUCCESS,
} from './contratoEnergiaInstalacao'

import {
  LOADING_VALOR_ENERGIA,
  LOADING_VALOR_ENERGIA_SUCCESS,
  LOADING_VALOR_ENERGIA_FAILURE,
  DESTROYING_VALOR_ENERGIA_SUCCESS,
} from './valorEnergia'

import {
  LOADING_FATURA_ENERGIA,
  LOADING_FATURA_ENERGIA_SUCCESS,
  LOADING_FATURA_ENERGIA_FAILURE,
  DESTROYING_FATURA_ENERGIA_SUCCESS,
} from './faturaEnergia'

import {
  LOADING_FATURA_PEDAGIO,
  LOADING_FATURA_PEDAGIO_SUCCESS,
  LOADING_FATURA_PEDAGIO_FAILURE,
  CALCULATING_FATURA_PEDAGIO_FAILURE,
  DESTROYING_FATURA_PEDAGIO_SUCCESS,
} from './faturaPedagio'

import {
  LOADING_EXTRA_PEDAGIO,
  LOADING_EXTRA_PEDAGIO_SUCCESS,
  LOADING_EXTRA_PEDAGIO_FAILURE,
  DESTROYING_EXTRA_PEDAGIO_SUCCESS,
} from './extraPedagio'

import {
  LOADING_RESULTADO,
  LOADING_RESULTADO_SUCCESS,
  LOADING_RESULTADO_FAILURE,
} from './resultado'

import {
  LOADING_CONTATO,
  LOADING_CONTATO_SUCCESS,
  LOADING_CONTATO_FAILURE,
  DESTROYING_CONTATO_SUCCESS,
} from './contato'

import {
  LOADING_CONCESSIONARIA,
  LOADING_CONCESSIONARIA_SUCCESS,
  LOADING_CONCESSIONARIA_FAILURE,
  DESTROYING_CONCESSIONARIA_SUCCESS,
} from './concessionaria'

import {
  LOADING_ENQUADRAMENTO,
  LOADING_ENQUADRAMENTO_SUCCESS,
  LOADING_ENQUADRAMENTO_FAILURE,
  DESTROYING_ENQUADRAMENTO_SUCCESS,
} from './enquadramento'

import {
  LOADING_PARAMETRO_CONCESSIONARIA,
  LOADING_PARAMETRO_CONCESSIONARIA_SUCCESS,
  LOADING_PARAMETRO_CONCESSIONARIA_FAILURE,
  DESTROYING_PARAMETRO_CONCESSIONARIA_SUCCESS,
} from './parametroConcessionaria'

import {
  LOADING_INSTALACAO,
  LOADING_INSTALACAO_SUCCESS,
  LOADING_INSTALACAO_FAILURE,
  DESTROYING_INSTALACAO_SUCCESS,
} from './instalacao'

import {
  LOADING_INSTALACAO_CLIENTE,
  LOADING_INSTALACAO_CLIENTE_SUCCESS,
  LOADING_INSTALACAO_CLIENTE_FAILURE,
  DESTROYING_INSTALACAO_CLIENTE_SUCCESS,
} from './instalacaoCliente'

import {
  LOADING_SUBGRUPO,
  LOADING_SUBGRUPO_SUCCESS,
  LOADING_SUBGRUPO_FAILURE,
  DESTROYING_SUBGRUPO_SUCCESS,
} from './subgrupo'

import {
  LOADING_SUBMERCADO,
  LOADING_SUBMERCADO_SUCCESS,
  LOADING_SUBMERCADO_FAILURE,
  DESTROYING_SUBMERCADO_SUCCESS,
} from './submercado'

import {
  LOADING_UF,
  LOADING_UF_SUCCESS,
  LOADING_UF_FAILURE,
  DESTROYING_UF_SUCCESS,
} from './uf'

import {
  LOADING_FONTE_ENERGIA,
  LOADING_FONTE_ENERGIA_SUCCESS,
  LOADING_FONTE_ENERGIA_FAILURE,
  DESTROYING_FONTE_ENERGIA_SUCCESS,
} from './fonteEnergia'

import {
  LOADING_GERADOR,
  LOADING_GERADOR_SUCCESS,
  LOADING_GERADOR_FAILURE,
  DESTROYING_GERADOR_SUCCESS,
} from './gerador'

import {
  LOADING_FUNCAO,
  LOADING_FUNCAO_SUCCESS,
  LOADING_FUNCAO_FAILURE,
  DESTROYING_FUNCAO_SUCCESS,
} from './funcao'

import {
  LOADING_PLD_SEMANAL,
  LOADING_PLD_SEMANAL_SUCCESS,
  LOADING_PLD_SEMANAL_FAILURE
} from './pldSemanal'

import {
  LOADING_PLD_MEDIO,
  LOADING_PLD_MEDIO_SUCCESS,
  LOADING_PLD_MEDIO_FAILURE
} from './pldMedio'

import {
  LOADING_SERVICO,
  LOADING_SERVICO_SUCCESS,
  LOADING_SERVICO_FAILURE,
  DESTROYING_SERVICO_SUCCESS,
} from './servico'

import {
  LOADING_SERVICO_INSTALACAO,
  LOADING_SERVICO_INSTALACAO_SUCCESS,
  LOADING_SERVICO_INSTALACAO_FAILURE,
  DESTROYING_SERVICO_INSTALACAO_SUCCESS,
} from './servicoInstalacao'

import {
  LOADING_TRIBUTO,
  LOADING_TRIBUTO_SUCCESS,
  LOADING_TRIBUTO_FAILURE,
  DESTROYING_TRIBUTO_SUCCESS,
} from './tributo'

import {
  LOADING_SESSION,
  LOADING_SESSION_SUCCESS,
  LOADING_SESSION_FAILURE,
  LOGOUT_SESSION
} from './session'

import {
  LOADING_USER,
  LOADING_USER_SUCCESS,
  LOADING_USER_FAILURE,
  DESTROYING_USER_SUCCESS,
} from './user'

const structure = {
  error: null,
  isLoading: false,
  lastUpdated: 0,
}

const initialState = fromJS({
  bandeira: structure,
  classeConsumo: structure,
  concessionaria: structure,
  enquadramento: structure,
  fonteEnergia: structure,
  servico: structure,
  subgrupo: structure,
  submercado: structure,
  uf: structure,
  session: { isAuthenticated: false, isAuthenticating: false, ...structure },
})

export default function status (state = initialState, action) {
  switch (action.type) {

    // ------------ ICMS

    case LOADING_ICMS :
      return state.mergeDeep({icms: {isLoading: true}})

    case DESTROYING_ICMS_SUCCESS :
    case LOADING_ICMS_SUCCESS :
      return state.mergeDeep({
        icms: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_ICMS_FAILURE :
      return state.mergeDeep({icms: {isLoading: false, error: action.error}})

    // ------------ CENARIO
    case LOADING_CENARIO :
      return state.mergeDeep({cenario: {isLoading: true}})

    case DESTROYING_CENARIO_SUCCESS :
    case LOADING_CENARIO_SUCCESS :
      return state.mergeDeep({
        cenario: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_CENARIO_FAILURE :
      return state.mergeDeep({cenario: {isLoading: false, error: action.error}})

    // ------------ CALCULADORA_CONSUMO
    case LOADING_CALCULADORA_CONSUMO :
      return state.mergeDeep({calculadoraConsumo: {isLoading: true}})

    case LOADING_CALCULADORA_CONSUMO_SUCCESS :
      return state.mergeDeep({
        calculadoraConsumo: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_CALCULADORA_CONSUMO_FAILURE :
      return state.mergeDeep({calculadoraConsumo: {isLoading: false, error: action.error}})

    // ------------ BANDEIRA
    case LOADING_BANDEIRA :
      return state.mergeDeep({bandeira: {isLoading: true}})

    case DESTROYING_BANDEIRA_SUCCESS :
    case LOADING_BANDEIRA_SUCCESS :
      return state.mergeDeep({
        bandeira: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_BANDEIRA_FAILURE :
      return state.mergeDeep({bandeira: {isLoading: false, error: action.error}})

    // ------------ VIGENCIA_BANDEIRA
    case LOADING_VIGENCIA_BANDEIRA :
      return state.mergeDeep({vigenciaBandeira: {isLoading: true}})

    case DESTROYING_VIGENCIA_BANDEIRA_SUCCESS :
    case LOADING_VIGENCIA_BANDEIRA_SUCCESS :
      return state.mergeDeep({
        vigenciaBandeira: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_VIGENCIA_BANDEIRA_FAILURE :
      return state.mergeDeep({vigenciaBandeira: {isLoading: false, error: action.error}})

    // ------------ VIGENCIA_PARAMETRO
    case LOADING_VIGENCIA_PARAMETRO :
      return state.mergeDeep({vigenciaParametro: {isLoading: true}})

    case DESTROYING_VIGENCIA_PARAMETRO_SUCCESS :
    case LOADING_VIGENCIA_PARAMETRO_SUCCESS :
      return state.mergeDeep({
        vigenciaParametro: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_VIGENCIA_PARAMETRO_FAILURE :
      return state.mergeDeep({vigenciaParametro: {isLoading: false, error: action.error}})

    // ------------ RELATORIO
    case LOADING_RELATORIO :
      return state.mergeDeep({relatorio: {isLoading: true}})

    case DESTROYING_RELATORIO_SUCCESS :
    case LOADING_RELATORIO_SUCCESS :
      return state.mergeDeep({
        relatorio: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_RELATORIO_FAILURE :
      return state.mergeDeep({relatorio: {isLoading: false, error: action.error}})

    // ------------ VIGENCIA_TARIFA
    case LOADING_VIGENCIA_TARIFA :
      return state.mergeDeep({vigenciaTarifa: {isLoading: true}})

    case DESTROYING_VIGENCIA_TARIFA_SUCCESS :
    case LOADING_VIGENCIA_TARIFA_SUCCESS :
      return state.mergeDeep({
        vigenciaTarifa: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_VIGENCIA_TARIFA_FAILURE :
      return state.mergeDeep({vigenciaTarifa: {isLoading: false, error: action.error}})

    // ------------ VIGENCIA_TRIBUTO
    case LOADING_VIGENCIA_TRIBUTO :
      return state.mergeDeep({vigenciaTributo: {isLoading: true}})

    case DESTROYING_VIGENCIA_TRIBUTO_SUCCESS :
    case LOADING_VIGENCIA_TRIBUTO_SUCCESS :
      return state.mergeDeep({
        vigenciaTributo: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_VIGENCIA_TRIBUTO_FAILURE :
      return state.mergeDeep({vigenciaTributo: {isLoading: false, error: action.error}})

    // ------------ VIGENCIA_VALOR
    case LOADING_VIGENCIA_VALOR :
      return state.mergeDeep({vigenciaValor: {isLoading: true}})

    case DESTROYING_VIGENCIA_VALOR_SUCCESS :
    case LOADING_VIGENCIA_VALOR_SUCCESS :
      return state.mergeDeep({
        vigenciaValor: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_VIGENCIA_VALOR_FAILURE :
      return state.mergeDeep({vigenciaValor: {isLoading: false, error: action.error}})

    // ------------ CLASSE_CONSUMO
    case LOADING_CLASSE_CONSUMO :
      return state.mergeDeep({classeConsumo: {isLoading: true}})

    case DESTROYING_CLASSE_CONSUMO_SUCCESS :
    case LOADING_CLASSE_CONSUMO_SUCCESS :
      return state.mergeDeep({
        classeConsumo: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_CLASSE_CONSUMO_FAILURE :
      return state.mergeDeep({classeConsumo: {isLoading: false, error: action.error}})

    // ------------ CLIENTE
    case LOADING_CLIENTE :
      return state.mergeDeep({cliente: {isLoading: true}})

    case DESTROYING_CLIENTE_SUCCESS :
    case LOADING_CLIENTE_SUCCESS :
      return state.mergeDeep({
        cliente: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_CLIENTE_FAILURE :
      return state.mergeDeep({cliente: {isLoading: false, error: action.error}})

    // ------------ COMERCIALIZADORA
    case LOADING_COMERCIALIZADORA :
      return state.mergeDeep({comercializadora: {isLoading: true}})

    case DESTROYING_COMERCIALIZADORA_SUCCESS :
    case LOADING_COMERCIALIZADORA_SUCCESS :
      return state.mergeDeep({
        comercializadora: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_COMERCIALIZADORA_FAILURE :
      return state.mergeDeep({comercializadora: {isLoading: false, error: action.error}})

    // ------------ FONTE_COMERCIALIZADORA
    case LOADING_FONTE_COMERCIALIZADORA :
      return state.mergeDeep({fonteComercializadora: {isLoading: true}})

    case DESTROYING_FONTE_COMERCIALIZADORA_SUCCESS :
    case LOADING_FONTE_COMERCIALIZADORA_SUCCESS :
      return state.mergeDeep({
        fonteComercializadora: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_FONTE_COMERCIALIZADORA_FAILURE :
      return state.mergeDeep({fonteComercializadora: {isLoading: false, error: action.error}})

    // ------------ CONTATO
    case LOADING_CONTATO :
      return state.mergeDeep({contato: {isLoading: true}})

    case DESTROYING_CONTATO_SUCCESS :
    case LOADING_CONTATO_SUCCESS :
      return state.mergeDeep({
        contato: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_CONTATO_FAILURE :
      return state.mergeDeep({contato: {isLoading: false, error: action.error}})

    // ------------ CONTATO_CLIENTE
    case LOADING_CONTATO_CLIENTE :
      return state.mergeDeep({contatoCliente: {isLoading: true}})

    case DESTROYING_CONTATO_CLIENTE_SUCCESS :
    case LOADING_CONTATO_CLIENTE_SUCCESS :
      return state.mergeDeep({
        contatoCliente: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_CONTATO_CLIENTE_FAILURE :
      return state.mergeDeep({contatoCliente: {isLoading: false, error: action.error}})

    // ------------ CONTATO_CONCESSIONARIA
    case LOADING_CONTATO_CONCESSIONARIA :
      return state.mergeDeep({contatoConcessionaria: {isLoading: true}})

    case DESTROYING_CONTATO_CONCESSIONARIA_SUCCESS :
    case LOADING_CONTATO_CONCESSIONARIA_SUCCESS :
      return state.mergeDeep({
        contatoConcessionaria: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_CONTATO_CONCESSIONARIA_FAILURE :
      return state.mergeDeep({contatoConcessionaria: {isLoading: false, error: action.error}})

    // ------------ CONTATO_INSTALACAO
    case LOADING_CONTATO_INSTALACAO :
      return state.mergeDeep({contatoInstalacao: {isLoading: true}})

    case DESTROYING_CONTATO_INSTALACAO_SUCCESS :
    case LOADING_CONTATO_INSTALACAO_SUCCESS :
      return state.mergeDeep({
        contatoInstalacao: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_CONTATO_INSTALACAO_FAILURE :
      return state.mergeDeep({contatoInstalacao: {isLoading: false, error: action.error}})

    // ------------ CONTRATO_DEMANDA_INSTALACAO
    case LOADING_CONTRATO_DEMANDA_INSTALACAO :
      return state.mergeDeep({contratoDemandaInstalacao: {isLoading: true}})

    case LOADING_CONTRATO_DEMANDA_INSTALACAO_SUCCESS :
      return state.mergeDeep({
        contratoDemandaInstalacao: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_CONTRATO_DEMANDA_INSTALACAO_FAILURE :
      return state.mergeDeep({ contratoDemandaInstalacao: { isLoading: false, error: action.error } })

    // ------------ CONTRATO_ENERGIA_INSTALACAO
    case LOADING_CONTRATO_ENERGIA_INSTALACAO :
      return state.mergeDeep({contratoEnergiaInstalacao: {isLoading: true}})

    case DESTROYING_CONTRATO_ENERGIA_INSTALACAO_SUCCESS :
    case LOADING_CONTRATO_ENERGIA_INSTALACAO_SUCCESS :
      return state.mergeDeep({
        contratoEnergiaInstalacao: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_CONTRATO_ENERGIA_INSTALACAO_FAILURE :
      return state.mergeDeep({contratoEnergiaInstalacao: {isLoading: false, error: action.error}})

    // ------------ VALOR_ENERGIA
    case LOADING_VALOR_ENERGIA :
      return state.mergeDeep({valorEnergia: {isLoading: true}})

    case DESTROYING_VALOR_ENERGIA_SUCCESS :
    case LOADING_VALOR_ENERGIA_SUCCESS :
      return state.mergeDeep({
        valorEnergia: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_VALOR_ENERGIA_FAILURE :
      return state.mergeDeep({valorEnergia: {isLoading: false, error: action.error}})

    // ------------ FATURA_ENERGIA
    case LOADING_FATURA_ENERGIA :
      return state.mergeDeep({faturaEnergia: {isLoading: true}})

    case DESTROYING_FATURA_ENERGIA_SUCCESS :
    case LOADING_FATURA_ENERGIA_SUCCESS :
      return state.mergeDeep({
        faturaEnergia: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_FATURA_ENERGIA_FAILURE :
      return state.mergeDeep({faturaEnergia: {isLoading: false, error: action.error}})

    // ------------ FATURA_PEDAGIO
    case LOADING_FATURA_PEDAGIO :
      return state.merge({faturaPedagio: {isLoading: true}})

    case DESTROYING_FATURA_PEDAGIO_SUCCESS :
    case LOADING_FATURA_PEDAGIO_SUCCESS :
      return state.merge({
        faturaPedagio: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_FATURA_PEDAGIO_FAILURE :
      return state.merge({faturaPedagio: {isLoading: false, error: action.error}})

    case CALCULATING_FATURA_PEDAGIO_FAILURE :
      return state.merge({faturaPedagio: {isLoading: false, error: action.error}})

    // ------------ EXTRA_PEDAGIO
    case LOADING_EXTRA_PEDAGIO :
      return state.mergeDeep({extraPedagio: {isLoading: true}})

    case DESTROYING_EXTRA_PEDAGIO_SUCCESS :
    case LOADING_EXTRA_PEDAGIO_SUCCESS :
      return state.mergeDeep({
        extraPedagio: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_EXTRA_PEDAGIO_FAILURE :
      return state.mergeDeep({extraPedagio: {isLoading: false, error: action.error}})

    // ------------ RESULTADO
    case LOADING_RESULTADO :
      return state.mergeDeep({resultado: {isLoading: true}})

    case LOADING_RESULTADO_SUCCESS :
      return state.mergeDeep({
        resultado: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_RESULTADO_FAILURE :
      return state.mergeDeep({resultado: {isLoading: false, error: action.error}})

    // ------------ CONTATO_COMERCIALIZADOR
    case LOADING_CONTATO_COMERCIALIZADORA :
      return state.mergeDeep({contatoComercializadora: {isLoading: true}})

    case DESTROYING_CONTATO_COMERCIALIZADORA_SUCCESS :
    case LOADING_CONTATO_COMERCIALIZADORA_SUCCESS :
      return state.mergeDeep({
        contatoComercializadora: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_CONTATO_COMERCIALIZADORA_FAILURE :
      return state.mergeDeep({contatoComercializadora: {isLoading: false, error: action.error}})

    // ------------ CONCESSIONARIA
    case LOADING_CONCESSIONARIA :
      return state.mergeDeep({concessionaria: {isLoading: true}})

    case DESTROYING_CONCESSIONARIA_SUCCESS :
    case LOADING_CONCESSIONARIA_SUCCESS :
      return state.mergeDeep({
        concessionaria: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_CONCESSIONARIA_FAILURE :
      return state.mergeDeep({concessionaria: {isLoading: false, error: action.error}})


    // ------------ INSTALACAO
    case LOADING_INSTALACAO :
      return state.mergeDeep({instalacao: {isLoading: true}})

    case DESTROYING_INSTALACAO_SUCCESS :
    case LOADING_INSTALACAO_SUCCESS :
      return state.mergeDeep({
        instalacao: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_INSTALACAO_FAILURE :
      return state.mergeDeep({instalacao: {isLoading: false, error: action.error}})

    // ------------ ENQUADRAMENTO
    case LOADING_ENQUADRAMENTO :
      return state.mergeDeep({enquadramento: {isLoading: true}})

    case DESTROYING_ENQUADRAMENTO_SUCCESS :
    case LOADING_ENQUADRAMENTO_SUCCESS :
      return state.mergeDeep({
        enquadramento: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_ENQUADRAMENTO_FAILURE :
      return state.mergeDeep({enquadramento: {isLoading: false, error: action.error}})

    // ------------ PARAMETRO_CONCESSIONARIA
    case LOADING_PARAMETRO_CONCESSIONARIA :
      return state.mergeDeep({parametroConcessionaria: {isLoading: true}})

    case DESTROYING_PARAMETRO_CONCESSIONARIA_SUCCESS :
    case LOADING_PARAMETRO_CONCESSIONARIA_SUCCESS :
      return state.mergeDeep({
        parametroConcessionaria: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_PARAMETRO_CONCESSIONARIA_FAILURE :
      return state.mergeDeep({parametroConcessionaria: {isLoading: false, error: action.error}})

    //------------ SUBGRUPO
    case LOADING_SUBGRUPO :
      return state.mergeDeep({subgrupo: {isLoading: true}})

    case DESTROYING_SUBGRUPO_SUCCESS :
    case LOADING_SUBGRUPO_SUCCESS :
      return state.mergeDeep({
        subgrupo: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_SUBGRUPO_FAILURE :
      return state.mergeDeep({subgrupo: {isLoading: false, error: action.error}})

    //------------ SUBMERCADO
    case LOADING_SUBMERCADO :
      return state.mergeDeep({submercado: {isLoading: true}})

    case DESTROYING_SUBMERCADO_SUCCESS :
    case LOADING_SUBMERCADO_SUCCESS :
      return state.mergeDeep({
        submercado: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_SUBMERCADO_FAILURE :
      return state.mergeDeep({submercado: {isLoading: false, error: action.error}})

    // ------------ UF
    case LOADING_UF :
      return state.mergeDeep({uf: {isLoading: true}})

    case DESTROYING_UF_SUCCESS :
    case LOADING_UF_SUCCESS :
      return state.mergeDeep({
        uf: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_UF_FAILURE :
      return state.mergeDeep({uf: {isLoading: false, error: action.error}})

    // ------------ FONTE_ENERGIA
    case LOADING_FONTE_ENERGIA :
      return state.mergeDeep({fonteEnergia: {isLoading: true}})

    case DESTROYING_FONTE_ENERGIA_SUCCESS :
    case LOADING_FONTE_ENERGIA_SUCCESS :
      return state.mergeDeep({
        fonteEnergia: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_FONTE_ENERGIA_FAILURE :
      return state.mergeDeep({fonteEnergia: {isLoading: false, error: action.error}})

    // ------------ GERADOR
    case LOADING_GERADOR :
      return state.mergeDeep({gerador: {isLoading: true}})

    case DESTROYING_GERADOR_SUCCESS :
    case LOADING_GERADOR_SUCCESS :
      return state.mergeDeep({
        gerador: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_GERADOR_FAILURE :
      return state.mergeDeep({gerador: {isLoading: false, error: action.error}})

    // ------------ FUNCAO
    case LOADING_FUNCAO :
      return state.mergeDeep({funcao: {isLoading: true}})

    case DESTROYING_FUNCAO_SUCCESS :
    case LOADING_FUNCAO_SUCCESS :
      return state.mergeDeep({
        funcao: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_FUNCAO_FAILURE :
      return state.mergeDeep({funcao: {isLoading: false, error: action.error}})

    // ------------ INSTALACAO_CLIENTE
    case LOADING_INSTALACAO_CLIENTE :
      return state.mergeDeep({instalacaoCliente: {isLoading: true}})

    case DESTROYING_INSTALACAO_CLIENTE_SUCCESS :
    case LOADING_INSTALACAO_CLIENTE_SUCCESS :
      return state.mergeDeep({
        instalacaoCliente: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_INSTALACAO_CLIENTE_FAILURE :
      return state.mergeDeep({instalacaoCliente: {isLoading: false, error: action.error}})

    // ------------ PLD_SEMANAL
    case LOADING_PLD_SEMANAL :
      return state.mergeDeep({pldSemanal: {isLoading: true}})

    case LOADING_PLD_SEMANAL_SUCCESS :
      return state.mergeDeep({
        pldSemanal: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_PLD_SEMANAL_FAILURE :
      return state.mergeDeep({ pldSemanal: { isLoading: false, error: action.error } })

    // ------------ PLD_MEDIO
    case LOADING_PLD_MEDIO :
      return state.mergeDeep({pldMedio: {isLoading: true}})

    case LOADING_PLD_MEDIO_SUCCESS :
      return state.mergeDeep({
        pldMedio: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_PLD_MEDIO_FAILURE :
      return state.mergeDeep({ pldMedio: { isLoading: false, error: action.error } })

    // ------------ SERVICO
    case LOADING_SERVICO :
      return state.mergeDeep({servico: {isLoading: true}})

    case DESTROYING_SERVICO_SUCCESS :
    case LOADING_SERVICO_SUCCESS :
      return state.mergeDeep({
        servico: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_SERVICO_FAILURE :
      return state.mergeDeep({servico: {isLoading: false, error: action.error}})

    // ------------ SERVICO_INSTALACAO
    case LOADING_SERVICO_INSTALACAO :
      return state.mergeDeep({servicoInstalacao: {isLoading: true}})

    case DESTROYING_SERVICO_INSTALACAO_SUCCESS :
    case LOADING_SERVICO_INSTALACAO_SUCCESS :
      return state.mergeDeep({
        servicoInstalacao: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_SERVICO_INSTALACAO_FAILURE :
      return state.mergeDeep({servicoInstalacao: {isLoading: false, error: action.error}})

    // ------------ TRIBUTO
    case LOADING_TRIBUTO :
      return state.mergeDeep({tributo: {isLoading: true}})

    case DESTROYING_TRIBUTO_SUCCESS :
    case LOADING_TRIBUTO_SUCCESS :
      return state.mergeDeep({
        tributo: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_TRIBUTO_FAILURE :
      return state.mergeDeep({tributo: {isLoading: false, error: action.error}})

    // ------------ UNIDADE
    case LOADING_UNIDADE :
      return state.mergeDeep({unidade: {isLoading: true}})

    case LOADING_UNIDADE_SUCCESS :
      return state.mergeDeep({
        unidade: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_UNIDADE_FAILURE :
      return state.mergeDeep({unidade: {isLoading: false, error: action.error}})

    // ------------ SIMULATION
    case LOADING_SIMULATION :
      return state.mergeDeep({tributo: {isLoading: true}})

    case LOADING_SIMULATION_SUCCESS :
      return state.mergeDeep({
        tributo: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_SIMULATION_FAILURE :
      return state.mergeDeep({tributo: {isLoading: false, error: action.error}})

    // ------------ SESSION
    case LOADING_SESSION :
      return state.mergeDeep({ session: { isAuthenticating: true, isLoading: true } })

    case LOADING_SESSION_SUCCESS :
      return state.mergeDeep({
        session: {
          isLoading: false,
          isAuthenticated: true,
          isAuthenticating: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_SESSION_FAILURE :
      return state.mergeDeep({
        session: {
          isAuthenticating: false,
          isAuthenticated: false,
          error: action.error,
          isLoading: false
        }
      })

    case LOGOUT_SESSION :
      return initialState

    // ------------ USER
    case LOADING_USER :
      return state.mergeDeep({user: {isLoading: true}})

    case DESTROYING_USER_SUCCESS :
    case LOADING_USER_SUCCESS :
      return state.mergeDeep({
        user: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime()
        }
      })

    case LOADING_USER_FAILURE :
      return state.mergeDeep({user: {isLoading: false, error: action.error}})

    default :
      return state
  }
}
