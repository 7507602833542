import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ResultadoShow } from '../../components'
import * as resultadoActionCreators from '../../redux/modules/resultado'
import * as faturaPedagioActionCreators from '../../redux/modules/faturaPedagio'
import * as cenarioActionCreators from '../../redux/modules/cenario'
import * as simulationActionCreators from '../../redux/modules/simulation'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

class ResultadoCompararContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    isLoading: PropTypes.bool,

    faturaPedagio: PropTypes.object,

    fetchResultado: PropTypes.func,
    resultado: PropTypes.object,

    fetchSubgrupo: PropTypes.func,
    subgrupo: PropTypes.object,

    fetchEnquadramento: PropTypes.func,
    enquadramento: PropTypes.object,

    hideFlashMessage: PropTypes.func,
    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,

    calculateFaturaPedagio: PropTypes.func,
    fetchMultipleResultado: PropTypes.func,
    fetchMultipleCenario: PropTypes.func,

    simulateFaturaPedagio: PropTypes.func,
  }

  state = {
    simulacao: null,
  }

  async componentDidMount () {
    await Promise.all([
      this.props.fetchResultado(this.props.faturaPedagio.get('id')),
      this.props.fetchMultipleCenario(this.props.faturaPedagio.get('instalacao_id')),
    ])
  }

  handleSimular = async (cenarioId) => {
    const simulacao = await this.props.simulateFaturaPedagio(this.props.faturaPedagio.get('id'), cenarioId)
    if (simulacao) this.setState({ simulacao })
  }

  render () {
    return (
      <ResultadoShow
        faturaPedagio={this.props.faturaPedagio}
        resultado={this.props.resultado}
        isLoading={this.props.isLoading}
        onSimular={this.handleSimular}
        simulacao={this.state.simulacao}
        multipleCenario={this.props.multipleCenario}
      />
    )
  }
}

function mapStateToProps ({ resultado, cenario, status }, { match }) {

  const isLoading = status.getIn(['resultado', 'isLoading'])

  const { faturaPedagioId } = match.params
  if (!faturaPedagioId) return { isLoading }

  resultado = resultado.get(faturaPedagioId)
  if (!resultado) return { faturaPedagioId, isLoading }

  return {
    faturaPedagioId,
    resultado,
    isLoading,
    multipleCenario: cenario.filter(v => `${v.get('instalacao_id')}` === match.params.instalacaoId),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...resultadoActionCreators,
    ...faturaPedagioActionCreators,
    ...cenarioActionCreators,
    ...simulationActionCreators,
    ...flashMessageActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResultadoCompararContainer))
