import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { FaturaEnergia } from '../../components'
import * as faturaEnergiaActionCreators from '../../redux/modules/faturaEnergia'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class FaturaEnergiaContainer extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,

    fetchMultipleFaturaEnergia: PropTypes.func.isRequired,
    multipleFaturaEnergia: PropTypes.object.isRequired,
    destroyFaturaEnergia: PropTypes.func.isRequired,

    hideFlashMessage: PropTypes.func.isRequired,
    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string
  }

  async componentDidMount () {
    await this.props.fetchMultipleFaturaEnergia()
  }

  handleRedirectToNew = () => {
    this.props.history.push('/faturas_energia/new')
  }

  handleShowResource = (id) => {
    this.props.history.push(`/faturas_energia/${id}`)
  }

  handleEditResource = (id) => {
    this.props.history.push(`/faturas_energia/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyFaturaEnergia(id)
  }

  handleSort = column => () => this.props.updateSort('faturaEnergia', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('faturaEnergia', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleFaturaEnergia.toList().toJSON(),
      'faturaEnergia',
      ['id', 'consumo', 'perdas', 'valor_total', 'multas', 'juros', 'correcao_monetaria', 'mcp', 'outros', 'tipo_operacao', 'consumo_negociado', 'spread', 'comercializadora_id'],
    )
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <FaturaEnergia
        title='Faturas de Energia'
        listPath='faturas_energia'
        multipleFaturaEnergia={this.props.multipleFaturaEnergia}
        onRedirectToNew={this.handleRedirectToNew}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV} />
    )
  }
}

function mapStateToProps ({faturaEnergia, sort, filter}) {
  const sortColumn = sort.getIn(['faturaEnergia', 'sortColumn'])
  const sortDirection = sort.getIn(['faturaEnergia', 'sortDirection'])
  const filterText = filter.getIn(['faturaEnergia', 'filterText']) || ''

  let map = filterMap(faturaEnergia, ['contrato_energia.id'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleFaturaEnergia: map,
    sortColumn,
    sortDirection,
    filterText,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...faturaEnergiaActionCreators,
    ...flashMessageAcionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FaturaEnergiaContainer)
