import React from 'react'
import PropTypes from 'prop-types'
import { Label, Table, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { CRUDIndex } from '../CRUD'
import { EditDeleteButtonContainer } from '../../containers'

const Submercado = (props) => (
  <CRUDIndex
    title='Submercados'
    icon='map'
    onRedirectToNew={props.onRedirectToNew}
    onExportToCSV={props.onExportToCSV}
    filterText={props.filterText}
    handleFilter={props.handleFilter}
  >
    <Table sortable celled striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1} textAlign='center'
            onClick={props.handleSort('id')}
            sorted={props.handleSorted('id')}>#</Table.HeaderCell>
          <Table.HeaderCell width={2}
            onClick={props.handleSort('nome')}
            sorted={props.handleSorted('nome')}>Nome</Table.HeaderCell>
          <Table.HeaderCell
            onClick={props.handleSort('descricao')}
            sorted={props.handleSorted('descricao')}>Descrição</Table.HeaderCell>
          <Table.HeaderCell>Unidades Federativas</Table.HeaderCell>
          <Table.HeaderCell width={1}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.isLoading && (
          <Table.Row>
            <Table.Cell colSpan='5'>
              <Loader active={props.isLoading} inline='centered' />
            </Table.Cell>
          </Table.Row>
        )}
        {props.multipleSubmercado.valueSeq().map((submercado, index) => (
          <Table.Row key={index}>
            <Table.Cell textAlign='center'>
              <Label as={Link} to={`/submercados/${submercado.get('id')}`}>{submercado.get('id')}</Label>
            </Table.Cell>
            <Table.Cell>{submercado.get('nome')}</Table.Cell>
            <Table.Cell>{submercado.get('descricao')}</Table.Cell>
            <Table.Cell>
              <div className='labelListWrapper'>
                {submercado.get('uf').map((uf, index) => (
                  <div key={index} className='labelWrapper'>
                    <Label key={index} as={Link} to={`/uf/${uf.get('id')}`}>
                      {uf.get('nome')}
                    </Label>
                  </div>
                ))}
              </div>
            </Table.Cell>
            <Table.Cell>
              <EditDeleteButtonContainer
                onEditResource={() => props.onEditResource(submercado.get('id'))}
                onDeleteResource={() => props.onDeleteResource(submercado.get('id'))} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </CRUDIndex>
)

Submercado.propTypes = {
  onRedirectToNew: PropTypes.func,
  multipleSubmercado: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default Submercado
