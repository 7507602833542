import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { ClasseConsumoForm } from '../../components'
import * as classeConsumoActionCreators from '../../redux/modules/classeConsumo'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

class ClasseConsumoFormContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    fetchClasseConsumo: PropTypes.func.isRequired,
    createClasseConsumo: PropTypes.func.isRequired,
    updateClasseConsumo: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    if (this.props.id) {
      const classe_consumo = await this.props.fetchClasseConsumo(this.props.id)
      this.props.initialize('ClasseConsumoForm', {classe_consumo})
    }
  }

  handleSubmitResource = async (classeConsumo) => {
    if (this.props.id) {
      if (await this.props.updateClasseConsumo(this.props.id, classeConsumo)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push('/classes_consumo')
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createClasseConsumo(classeConsumo)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/classes_consumo')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <ClasseConsumoForm
        onSubmit={(classeConsumo) => this.handleSubmitResource(classeConsumo)}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({classeConsumo, status, flashMessage}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      classeConsumo: classeConsumo.get('id'),
      error: status.getIn(['classeConsumo', 'error']),
      isLoading: status.getIn(['classeConsumo', 'isLoading']),
      shouldHideFlashMessage: flashMessage.get('isError'),
    }
  }
  return {
    error: status.getIn(['classeConsumo', 'error']),
    isLoading: status.getIn(['classeConsumo', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...classeConsumoActionCreators,
    ...{initialize},
    ...flashMessageAcionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ClasseConsumoFormContainer)
