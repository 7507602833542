import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_UF = 'LOADING_UF'
export function loadingUf () {
  return {
    type: LOADING_UF,
  }
}

export const LOADING_UF_SUCCESS = 'LOADING_UF_SUCCESS'
export function loadingUfSuccess (uf) {
  return {
    type: LOADING_UF_SUCCESS,
    uf,
  }
}

export const LOADING_UF_FAILURE = 'LOADING_UF_FAILURE'
export function loadingUfFailure (error) {
  return {
    type: LOADING_UF_FAILURE,
    error,
  }
}

export const DESTROYING_UF_SUCCESS = 'DESTROYING_UF_SUCCESS'
export function destroyingUfSuccess (ufId) {
  return {
    type: DESTROYING_UF_SUCCESS,
    ufId,
  }
}

const initialState = Map({})

export function fetchMultipleUf () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingUf())
      const multipleUf = await callAPI('/uf')
      dispatch(loadingUfSuccess(multipleUf))
      return multipleUf
    } catch (e) {
      dispatch(loadingUfFailure(e))
      return false
    }
  }
}

export function fetchUf (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingUf())
      const uf = await callAPI(`/uf/${id}`)
      dispatch(loadingUfSuccess(uf))
      return uf
    } catch (e) {
      dispatch(loadingUfFailure(e))
      return false
    }
  }
}

export function createUf (uf) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingUf())
      const newUf = await callAPI('/uf', 'POST', uf)
      dispatch(loadingUfSuccess(newUf))
      return newUf
    } catch (e) {
      dispatch(loadingUfFailure(e))
      return false
    }
  }
}

export function updateUf (id, uf) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingUf())
      const newUf = await callAPI(`/uf/${id}`, 'PUT', uf)
      dispatch(loadingUfSuccess(newUf))
      return newUf
    } catch (e) {
      dispatch(loadingUfFailure(e))
      return false
    }
  }
}

export function destroyUf (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingUf())
      await callAPI(`/uf/${id}`, 'DELETE')
      dispatch(destroyingUfSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingUfFailure(e))
      return false
    }
  }
}

export default function uf (state = initialState, action) {
  switch (action.type) {

    case LOADING_UF_SUCCESS : {
      const { uf } = action
      if (uf.constructor === Array) {
        state = initialState
        uf.forEach((singleUf) => (
          state = state.merge({[singleUf.id]: singleUf})
        ))
        return state
      }
      return state.merge({[uf.id]: uf})
    }

    case DESTROYING_UF_SUCCESS :
      return state.delete(action.ufId.toString())

    default :
      return state
  }
}
