import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { Relatorio } from '../../../containers'

const Relatorios = (props) => {
  const { instalacao, autoPrint } = props
  
  return (
    <div>
      <Switch>
        <Route exact path='/instalacoes/:instalacaoId/relatorios'
          render={({match}) => (
            <Relatorio.Index
              instalacao={instalacao}
              match={match}
            />
          )}>
        </Route>
        <Route exact path='/instalacoes/:instalacaoId/relatorios/new'
          render={({match}) => (
            <Relatorio.Form
              instalacao={instalacao}
              match={match}
            />
          )}
        />
        <Route exact path='/instalacoes/:instalacaoId/relatorios/:id/edit'
          render={({match}) => (
            <Relatorio.Form
              instalacao={instalacao}
              match={match}
            />
          )}
        />
        <Route exact path='/instalacoes/:instalacaoId/relatorios/:id'
          render={({match}) => (
            <Relatorio.Show
              instalacao={instalacao}
              match={match}
            />
          )}
        />
        <Route exact path='/instalacoes/:instalacaoId/relatorios/:id/print'
          render={({match}) => (
            <Relatorio.Show
              instalacao={instalacao}
              match={match}
              autoPrint={autoPrint}
            />
          )}
        />
      </Switch>
    </div>
  )
}

Relatorios.propTypes = {
  instalacao: PropTypes.object.isRequired,
  autoPrint:PropTypes.bool,
}

export default Relatorios
