import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Divider, Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticCheckbox, SemanticInput, SemanticInputMask, SemanticDropdown, SemanticDropdownText } from '../FormComponents'

import { required, cnpj, maxLengthDescricao } from '../../utils/validations'
import { maskCNPJ } from '../../utils/masks'

const diasOptions = Array.from(Array(31).keys(), v => ({
  key: `${v + 1}`,
  text: `${v + 1}`,
  value: v + 1
}))

const generateTimeOptions = () => {
  const times = []
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const formattedHour = hour.toString().padStart(2, '0')
      const formattedMinute = minute.toString().padStart(2, '0')
      const time = `${formattedHour}:${formattedMinute}`
      times.push({ key: time, text: time, value: time })
    }
  }
  return times
}

const timeOptions = generateTimeOptions()

const
  mesOptions = [
    { key: 'jan', text: 'Janeiro', value: 1 },
    { key: 'fev', text: 'Fevereiro', value: 2 },
    { key: 'mar', text: 'Março', value: 3 },
    { key: 'abr', text: 'Abril', value: 4 },
    { key: 'mai', text: 'Maio', value: 5 },
    { key: 'jun', text: 'Junho', value: 6 },
    { key: 'jul', text: 'Julho', value: 7 },
    { key: 'ago', text: 'Agosto', value: 8 },
    { key: 'set', text: 'Setembro', value: 9 },
    { key: 'out', text: 'Outubro', value: 10 },
    { key: 'nov', text: 'Novembro', value: 11 },
    { key: 'dez', text: 'Dezembro', value: 12 },
  ]

const Concessionaria = (props) => {
  return (
  <CRUDForm
    title='Concessionária'
    icon='building'
    {...props}>
    <FormSection name='concessionaria'>
      <Divider horizontal>Dados Gerais</Divider>
      <Form.Group>
        <FormField width={4}>
          <label htmlFor='sigla'>Sigla</label>
          <Field
            autoFocus
            name='sigla'
            placeholder='Sigla'
            validate={[required]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField width={12}>
          <label htmlFor='nome'>Nome</label>
          <Field
            name='nome'
            placeholder='Nome'
            validate={[required, maxLengthDescricao]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>
      <Form.Group widths='equal'>
      <FormField>
          <label htmlFor='cnpj'>CNPJ</label>
          <Field
            name='cnpj'
            placeholder={maskCNPJ.placeholder}
            validate={[required, cnpj]}
            component={SemanticInputMask}
            mask={maskCNPJ.mask}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <label htmlFor='uf_id'>Unidade Federativa</label>
          <Field fluid button floating labeled search icon='marker' className='icon'
            name='uf_id'
            options={props.ufDropdown}
            validate={[required]}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <label htmlFor='dia'>Dia de Reajuste das Tarifas</label>
          <Field fluid button floating labeled search icon='calendar' className='icon'
            name='dia'
            options={diasOptions}
            validate={[required]}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <label htmlFor='mes'>Mês de Reajuste das Tarifas</label>
          <Field fluid button floating labeled search icon='calendar' className='icon'
            name='mes'
            options={mesOptions}
            validate={[required]}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <label htmlFor='hora_p_inicio'>Hora Ponta Inicial</label>
          <Field fluid button floating labeled search icon='calendar' className='icon'
            name='hora_p_inicio'
            id='hora_p_inicio'
            options={timeOptions}
            validate={[required]}
            component={SemanticDropdownText}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>
      <Divider horizontal section>Parâmetros de Cálculo de Faturas</Divider>
      <Form.Group>
        <FormField>
          <Field
            name='rateio_ponderado_tributos'
            id='rateio_ponderado_tributos'
            label='Possui rateio ponderado de tributos'
            component={SemanticCheckbox}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <Field
            name='rateio_ponderado_icms'
            id='rateio_ponderado_icms'
            label='Possui rateio ponderado de ICMS'
            component={SemanticCheckbox}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>
    </FormSection>
  </CRUDForm>
)}

Concessionaria.propTypes = {
  ufDropdown: PropTypes.array,
  loading: PropTypes.bool
}

export default reduxForm({form: 'ConcessionariaForm'})(Concessionaria)
