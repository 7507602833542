import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

const PageLoader = props => {
  const { active, message = 'Carregando...' } = props

  return (
    <Dimmer active={active} inverted>
      <Loader inverted content={message} />
    </Dimmer>
  )
}

export default PageLoader
