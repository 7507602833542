import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_RELATORIO = 'LOADING_RELATORIO'
export function loadingRelatorio () {
  return {
    type: LOADING_RELATORIO,
  }
}

export const LOADING_RELATORIO_SUCCESS = 'LOADING_RELATORIO_SUCCESS'
export function loadingRelatorioSuccess (relatorio) {
  return {
    type: LOADING_RELATORIO_SUCCESS,
    relatorio,
  }
}

export const LOADING_RELATORIO_FAILURE = 'LOADING_RELATORIO_FAILURE'
export function loadingRelatorioFailure (error) {
  return {
    type: LOADING_RELATORIO_FAILURE,
    error,
  }
}

export const DESTROYING_RELATORIO_SUCCESS = 'DESTROYING_RELATORIO_SUCCESS'
export function destroyingRelatorioSuccess (relatorioId) {
  return {
    type: DESTROYING_RELATORIO_SUCCESS,
    relatorioId,
  }
}

const initialState = Map({})

export function fetchMultipleRelatorio (instalacaoId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingRelatorio())
      const multipleRelatorio = await callAPI(`/instalacoes/${instalacaoId}/relatorios`)
      dispatch(loadingRelatorioSuccess(multipleRelatorio))
      return multipleRelatorio
    } catch (e) {
      dispatch(loadingRelatorioFailure(e))
      return false
    }
  }
}

export function fetchLastRelatorio (instalacaoId, data = '') {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingRelatorio())
      const relatorio = await callAPI(`/instalacoes/${instalacaoId}/relatorios/last?data=${data}`)
      dispatch(loadingRelatorioSuccess(relatorio))
      return relatorio
    } catch (e) {
      dispatch(loadingRelatorioFailure(e))
      return false
    }
  }
}

export function fetchRelatorioByToken (token) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingRelatorio())
      const relatorio = await callAPI(`/relatorio/${token}`)
      dispatch(loadingRelatorioSuccess(relatorio))
      return relatorio
    } catch (e) {
      dispatch(loadingRelatorioFailure(e))
      return false
    }
  }
}

export function fetchRelatorio (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingRelatorio())
      const relatorio = await callAPI(`/relatorios/${id}`)
      dispatch(loadingRelatorioSuccess(relatorio))
      return relatorio
    } catch (e) {
      dispatch(loadingRelatorioFailure(e))
      return false
    }
  }
}

export function createRelatorio (instalacaoId, relatorio) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingRelatorio())
      const newRelatorio = await callAPI(`/instalacoes/${instalacaoId}/relatorios`, 'POST', relatorio)
      dispatch(loadingRelatorioSuccess(newRelatorio))
      return newRelatorio
    } catch (e) {
      dispatch(loadingRelatorioFailure(e))
      return false
    }
  }
}

export function updateRelatorio (id, relatorio) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingRelatorio())
      const newRelatorio = await callAPI(`/relatorios/${id}`, 'PUT', relatorio)
      dispatch(loadingRelatorioSuccess(newRelatorio))
      return newRelatorio
    } catch (e) {
      dispatch(loadingRelatorioFailure(e))
      return false
    }
  }
}

export function destroyRelatorio (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingRelatorio())
      await callAPI(`/relatorios/${id}`, 'DELETE')
      dispatch(destroyingRelatorioSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingRelatorioFailure(e))
      return false
    }
  }
}

export function resetRelatorio (id, field) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingRelatorio())
      const newRelatorio = await callAPI(`/relatorios/${id}/reset?field=${field}`, 'PUT')
      dispatch(loadingRelatorioSuccess(newRelatorio))
      return newRelatorio
    } catch (e) {
      dispatch(loadingRelatorioFailure(id, e))
      return false
    }
  }
}

export default function relatorio (state = initialState, action) {
  switch (action.type) {

    case LOADING_RELATORIO_SUCCESS : {
      const { relatorio } = action
      if (relatorio.constructor === Array) {
        state = initialState
        relatorio.forEach((vigencia) => (
          state = state.merge({[vigencia.id]: vigencia})
        ))
        return state
      }
      return state.merge({[relatorio.id]: relatorio})
    }

    case DESTROYING_RELATORIO_SUCCESS :
      return state.delete(action.relatorioId.toString())

    default :
      return state
  }
}
