import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import { withRouter } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import DemandaAnualChart from './Graficos/DemandaAnualChart'
import HistoricoDemandaChart from './Graficos/HistoricoDemandaChart'
import ParticipacaoDemandaChart from './Graficos/ParticipacaoDemandaChart'
import ParticipacaoTUSDChart from './Graficos/ParticipacaoTUSDChart'

import AnaliseContainer from '../../containers/Relatorio/AnaliseContainer'

const Demanda = ({ anual, historico, analise, match }) => {

  const print = match.url.endsWith('/print')
  const showAnimations = !print

  const anual_p = get(anual, 'ponta')
  const anual_fp = get(anual, 'fora_ponta')
  const anual_na = get(anual, 'na')
  const historico_p = get(historico, 'ponta')
  const historico_fp = get(historico, 'fora_ponta')
  const historico_na = get(historico, 'na')

  return (
    <React.Fragment>
      {historico_p && (
        <Grid.Row className='shrink'>
          <Grid.Column width={16}>
            <AnaliseContainer resetable
              field='texto_demanda_p'
              help='Histórico - Demanda Ponta (36 meses)'
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <HistoricoDemandaChart
              data={historico_p}
              showAnimations={showAnimations}
            />
          </Grid.Column>
        </Grid.Row>
      )}

      {historico_fp && (
        <Grid.Row className='shrink'>
          <Grid.Column width={16}>
            <AnaliseContainer resetable
              field='texto_demanda_fp'
              help='Histórico - Demanda Fora-Ponta (36 meses)'
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <HistoricoDemandaChart
              data={historico_fp}
              showAnimations={showAnimations}
            />
          </Grid.Column>
        </Grid.Row>
      )}

      {historico_na && (
        <Grid.Row className='shrink'>
          <Grid.Column width={16}>
            <HistoricoDemandaChart
              data={historico_na}
              showAnimations={showAnimations}
            />
          </Grid.Column>
        </Grid.Row>
      )}

      {(anual_p || anual_fp) && (
        <Grid.Row className='shrink' style={{paddingTop: 0}}>
          <Grid.Column width={16}>
            <AnaliseContainer resetable
              field='texto_demanda_anual'
              help='Demanda (12 meses)'
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <DemandaAnualChart
              header='Demanda - Horário Ponta'
              data={anual_p}
              showAnimations={showAnimations}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <DemandaAnualChart
              header='Demanda - Horário Fora-Ponta'
              data={anual_fp}
              showAnimations={showAnimations}
            />
          </Grid.Column>
        </Grid.Row>
      )}

      {anual_na && (
        <Grid.Row className='shrink'>
          <Grid.Column width={16}>
            <AnaliseContainer resetable
              field='texto_demanda_anual'
              help='Demanda (12 meses)'
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <DemandaAnualChart
              header=''
              data={anual_na}
              showAnimations={showAnimations}
            />
          </Grid.Column>
        </Grid.Row>
      )}

      {analise && (
        <Grid.Row className='shrink'>
          <Grid.Column width={16}>
            <AnaliseContainer resetable
              field='texto_participacao_demanda'
              help='Participação da Demanda no Custo Total'
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <ParticipacaoDemandaChart
              header='Participação da Demanda no Custo Total'
              data={analise}
              showAnimations={showAnimations}
            />
          </Grid.Column>
        </Grid.Row>
      )}

      {analise && (
        <Grid.Row className='shrink'>
          <Grid.Column width={16}>
            <AnaliseContainer resetable
              field='texto_participacao_tusd'
              help='Participação da TUSD no Custo Total'
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <ParticipacaoTUSDChart
              header='Participação da TUSD no Custo Total'
              data={analise}
              showAnimations={showAnimations}
            />
          </Grid.Column>
        </Grid.Row>
      )}
    </React.Fragment>
  )
}

Demanda.propTypes = {
  analise: PropTypes.string,
  anual: PropTypes.object,
  historico: PropTypes.object,
  match: PropTypes.object,
}

export default withRouter(Demanda)
