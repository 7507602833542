import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { VigenciaTributo } from '../../components'
import * as vigenciaTributoActionCreators from '../../redux/modules/vigenciaTributo'
import * as tributoActionCreators from '../../redux/modules/tributo'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'
import moment from 'moment'

class VigenciaTributoContainer extends Component {

  static propTypes = {
    concessionaria: PropTypes.object.isRequired,
    history: PropTypes.object,
    multipleVigenciaTributo: PropTypes.object,
    fetchMultipleVigenciaTributo: PropTypes.func,
    destroyVigenciaTributo: PropTypes.func,
    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string,

    fetchMultipleTributo: PropTypes.func,
    multipleTributo: PropTypes.object,
  }

  async componentDidMount () {
    const { concessionaria } = this.props
    await this.props.fetchMultipleVigenciaTributo(concessionaria.id)
    await this.props.fetchMultipleTributo()
  }

  handleRedirectToNew = (concessionariaId) => {
    this.props.history.push(`/concessionarias/${concessionariaId}/tributos/new`)
  }

  handleEditResource = (concessionariaId, id) => {
    this.props.history.push(`/concessionarias/${concessionariaId}/tributos/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyVigenciaTributo(id)
      ? this.props.showSuccessfulFlashMessage('', 'destroy')
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  handleSort = column => () => this.props.updateSort('vigenciaTributo', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('vigenciaTributo', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleVigenciaTributo.toList().toJSON(),
      'vigenciaTributo',
      [
        'id',
        {
          label: 'Início da Vigência',
          value: row => moment(row.starts_at, 'YYYY-MM-DD').format('DD/MM/YYYY')
        },
        {
          label: 'Tributo',
          value: 'aliquotas_tributos.tributo_nome'
        },
        {
          label: 'Alíquota',
          value: 'aliquotas_tributos.aliquota'
        }
      ],
      [ 'aliquotas_tributos' ]
    )
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <VigenciaTributo
        concessionaria={this.props.concessionaria}
        onRedirectToNew={() => this.handleRedirectToNew(this.props.concessionaria.id)}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        multipleVigenciaTributo={this.props.multipleVigenciaTributo}
        multipleTributo={this.props.multipleTributo}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV}
        isLoading={this.props.isLoading}
      />
    )
  }
}

function mapStateToProps ({vigenciaTributo, tributo, status, sort, filter}, {concessionaria}) {
  const sortColumn = sort.getIn(['vigenciaTributo', 'sortColumn']) || 'nome'
  const sortDirection = sort.getIn(['vigenciaTributo', 'sortDirection']) || 'ascending'
  const filterText = filter.getIn(['vigenciaTributo', 'filterText']) || ''

  let map = filterMap(vigenciaTributo, ['starts_at', 'bandeira.nome'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleVigenciaTributo: map.filter(t => t.get('concessionaria_id') === concessionaria.id),
    error: status.getIn(['vigenciaTributo', 'error']),
    isLoading: status.getIn(['vigenciaTributo', 'isLoading']) || status.getIn(['tributo', 'isLoading']),
    sortColumn,
    sortDirection,
    multipleTributo: tributo,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...flashMessageAcionCreators,
    ...vigenciaTributoActionCreators,
    ...tributoActionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VigenciaTributoContainer))
