import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import { Field } from 'redux-form/immutable'
import { SemanticInputMask } from '../FormComponents'
import { maskCurrency } from '../../utils/masks'
import { required } from '../../utils/validations'

const RenderFormPrecos = ({ fields }) => {
  return fields.map((preco, index) => (
    <Table.Cell key={index}>
      <Field fluid
        name={`${preco}.preco`}
        placeholder={maskCurrency.placeholder}
        mask={maskCurrency.mask()}
        validate={[required]}
        component={SemanticInputMask}
        label='R$/MWh'
      />
    </Table.Cell>
  ))
}

RenderFormPrecos.propTypes = {
  fields: PropTypes.object,
  loading: PropTypes.bool,
}

export default RenderFormPrecos
