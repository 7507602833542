import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Offline } from 'react-detect-offline'

import { Menu, Icon, List, Dropdown } from 'semantic-ui-react'

class UserMenu extends Component {
  static propTypes = {
    onLogout: PropTypes.func.isRequired,
    session: PropTypes.object.isRequired,
  }

  render() {
    const trigger = (
      <List horizontal>
        <List.Item>
          <Icon name='user circle' size='large' />
          <List.Content>
            <List.Header>{this.props.session.get('email')}</List.Header>
          </List.Content>
        </List.Item>
      </List>
    )

    const options = [
      { key: 'profile', text: 'Seu Perfil', icon: 'user' },
      { key: 'settings', text: 'Configurações', icon: 'settings' },
      { key: 'sign-out', text: 'Logout', icon: 'sign out', onClick: this.props.onLogout },
    ]

    const DropdownTrigger = () => (
      <Dropdown trigger={trigger} options={options} pointing='top right' />
    )

    return (
      <Menu.Menu position='right'>
        <Menu.Item>
          <Offline>
            <Icon size='large' name='unlinkify' color='red'/>
          </Offline>
        </Menu.Item>

        <Menu.Item>
          <DropdownTrigger />
        </Menu.Item>
      </Menu.Menu>
    )
  }
}

export default UserMenu
