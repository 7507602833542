import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'

const ServicoInstalacaoShow = (props) => {
  if (props.servicoInstalacao) {
    return (
      <CRUDShow noTitle padded={false}
        onEditResource={props.onEditResource}
        onDeleteResource={props.onDeleteResource}>
        <ul>
          <li>Serviço: {props.servicoInstalacao.servico.nome}</li>
          <li>Descrição: {props.servicoInstalacao.descricao}</li>
          <li>Executado Em: {props.servicoInstalacao.executadoem}</li>
        </ul>
      </CRUDShow>
    )
  }

  return (null)
}

ServicoInstalacaoShow.propTypes = {
  instalacao: PropTypes.object,
  servicoInstalacao: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
}

export default ServicoInstalacaoShow
