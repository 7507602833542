import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as faturaPedagioActionCreators from '../../redux/modules/faturaPedagio'
import ExportToCSV from '../../components/ExportToCSV/ExportToCSV'

import moment from 'moment'

import FileSaver from 'file-saver'

class ExportToCSVContainer extends Component {
  static propTypes = {
    downloadFaturaPedagio: PropTypes.func,
  }

  handleSubmit = async values => {
    const { match: { params: { instalacaoId }} = { params: { instalacaoId: '' } } } = this.props
    const data = await this.props.downloadFaturaPedagio(
      instalacaoId,
      ...['ano_inicial', 'mes_inicial', 'ano_final', 'mes_final'].map(f => values.get(f))
    )
    if (Array.isArray(data)) {
      const textData = data.map(d => Array.isArray(d) ? d.join(';') : d).join('\n')
      var blob = new Blob([textData], {type: 'text/plain;charset=utf-8'})
      FileSaver.saveAs(blob, `${instalacaoId}_${moment().format('YYYYMMDD-HHMMSS')}.csv`)
    }
  }

  render = () => (
    <ExportToCSV onSubmit={this.handleSubmit} />
  )
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...faturaPedagioActionCreators,
  }, dispatch)
}

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
)(ExportToCSVContainer)

// export default connect(null, mapDispatchToProps)(withRouter(ExportToCSVContainer))
