import sortBy from 'sort-by'

function toDropdownItem(element, keyCallback, textCallback) {
  return {
    key: keyCallback(element),
    text: textCallback(element),
    value: keyCallback(element),
  }
}

export function mapToDropdown (data, keyCallback, textCallback, allowEmpty = false, emptyText = 'Nenhum') {
  let result = []

  if (!data) return result

  switch(data.constructor) {
    case Object:
      result.push(toDropdownItem(data, keyCallback, textCallback))
      break

    default:
      data.map(element => result.push(toDropdownItem(element, keyCallback, textCallback)))
  }

  result = result.sort(sortBy('text'))

  if (allowEmpty) {
    result.unshift({
      key: '',
      text: emptyText,
      value: '',
    })
  }

  return result
}

export function parseSubgrupo (subgrupo) {
  let enquadramento_ids = []
  subgrupo.enquadramentos.forEach(e => enquadramento_ids.push(e.id))
  subgrupo.enquadramento_ids = enquadramento_ids
  delete subgrupo.enquadramentos
  return subgrupo
}
