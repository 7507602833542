import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FieldArray, FormSection } from 'redux-form/immutable'
import { Form, FormField, Table } from 'semantic-ui-react'

import RenderValoresBandeiras from './RenderValoresBandeiras'

import { CRUDForm } from '../CRUD'
import { DatePickerInput } from '../FormComponents'
import { required, dateValidator } from '../../utils/validations'

function VigenciaValorForm (props) {
  return (
    <CRUDForm
      title='Valores de Bandeiras'
      icon='money'
      padded={false}
      {...props}>
      <FormSection name='vigencia_valor'>
        <Form.Group>
          <FormField width={3}>
            <label>Início da Vigência</label>
            <Field autoFocus
              name='starts_at'
              placeholder='Início da Vigência'
              validate={[required, dateValidator]}
              component={DatePickerInput}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <Form.Field width={10}>
            <Table celled striped selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4} textAlign='right'>Bandeira</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign='center'>Cor</Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign='right'>Valor (R$/kWh)</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <FieldArray
                name='valores_bandeiras_attributes'
                component={RenderValoresBandeiras}
              />
            </Table>
          </Form.Field>
        </Form.Group>
      </FormSection>
    </CRUDForm>
  )
}

VigenciaValorForm.propTypes = {
  loading: PropTypes.bool,
  bandeiraDropdown: PropTypes.array,
}

export default reduxForm({form: 'VigenciaValorForm'})(VigenciaValorForm)
