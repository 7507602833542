import React from 'react'
import PropTypes from 'prop-types'
import { Card, List, Loader } from 'semantic-ui-react'
import { CRUDIndex } from '../CRUD'
import PainelResumoFilter from './PainelResumoFilter'
import ClienteResumoListState from './ClienteResumoListState'
import PiorStateReport from './PiorStateReport'
import { fetchResumoCliente, fetchResumoInstalacao } from '../../redux/modules/painelResumo'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PainelResumoContext from './painel-resumo-context'
import { arrayToCSV } from '../../utils/arrayToCSV'

const PainelResumo = (props) => {
  const [clienteBuscado, setClienteBuscado] = React.useState()
  const [listaClientes, setListaClientes] = React.useState([])
  const ctx = React.useContext(PainelResumoContext)

  React.useEffect(() => {
    setListaClientes([{key: 0, value: null, text: 'Todos'}].concat(props.multipleResumo.clientes
      .map(empresa => ({key: empresa.cliente.id, value: empresa.cliente.id, text: empresa.cliente.sigla}))))
  }, [props.multipleResumo.clientes])

  const handleCardClick = async (id) => {
    if(ctx.selectedCard !== id){
      ctx.setSelectedCard(id)
      ctx.setIsLoading(true)
      setClienteBuscado(await props.fetchResumoCliente({cliente: id, periodo: `${ctx.ano}-${ctx.mes}-01`, tipo_mercado: ctx.mercado}))
      ctx.setIsLoading(false)
    } else {
      ctx.setSelectedCard(-1)
      setClienteBuscado(null)
    }
  }

  const mapDadosToCSV = dados => {
    return dados.map(elem => ({
      ...elem,
      instalacao: elem.apelido,
      status_relatorio: elem.report.state === 'none' ? 'Ausente' : 'Concluído',
      data_criacao_relatorio: elem.report.data_criacao
    })).sort((a, b) => {
      return a.unidade > b.unidade ? 1 : -1
    })
  }

  const exportToCSVHandler = async () => {
    ctx.setIsLoading(true)
    props.fetchResumoInstalacao({tipo_mercado: ctx.mercado, periodo: `${ctx.ano}-${ctx.mes}-01`}).then(result => {
      arrayToCSV(
        mapDadosToCSV(result),
        'resumo',
        ['periodo', 'gestor', 'unidade', 'instalacao', 'status_relatorio', 'data_criacao_relatorio'],
      )
      ctx.setIsLoading(false)
    },
      error =>  ctx.setIsLoading(false)
    )
  }

  const listagem = ctx.clientesFiltrados.length > 0 ? ctx.clientesFiltrados : props.multipleResumo.clientes
  return (
    <>
      <CRUDIndex
        title={props.title}
        icon='clipboard outline'
        onExportToCSV={exportToCSVHandler}
      >
        <Loader inline='centered' active={ctx.isLoading} />
        <PainelResumoFilter clientes={listaClientes}/>
        <Card.Group style={{ marginTop: '0.5em' }}>
          {listagem.map((empresa, index) => {
            const identificador = empresa.cliente.id
            return (
            <Card
              key={index}
              onClick={() => handleCardClick(identificador)}
            >
              <Card.Content>
                <Card.Header>
                  {empresa.cliente.sigla}
                </Card.Header>
                <Card.Meta>
                  {empresa.cliente.nome}
                </Card.Meta>
                <Card.Description>
                  {ctx.selectedCard === identificador && (
                    <ClienteResumoListState relatorios={clienteBuscado}/>
                  )}
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <List>
                  <PiorStateReport state_report={empresa.state_report}/>
                </List>
              </Card.Content>
            </Card>
          )})}
        </Card.Group>
      </CRUDIndex>
    </>
)}

PainelResumo.propTypes = {
  title: PropTypes.string.isRequired,
  listPath: PropTypes.string.isRequired,
  multipleResumo: PropTypes.object.isRequired,
  onExportToCSV: PropTypes.func,
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    fetchResumoCliente,
    fetchResumoInstalacao,
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(PainelResumo) 
