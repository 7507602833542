import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { FaturaPedagioShow } from '../../components'
import * as faturaPedagioActionCreators from '../../redux/modules/faturaPedagio'
import * as resultadoActionCreators from '../../redux/modules/resultado'
import * as instalacaoActionCreators from '../../redux/modules/instalacao'
import * as tributoActionCreators from '../../redux/modules/tributo'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

class FaturaPedagioShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    instalacaoId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,

    faturaPedagio: PropTypes.object,
    instalacao: PropTypes.object,

    fetchFaturaPedagio: PropTypes.func.isRequired,
    destroyFaturaPedagio: PropTypes.func.isRequired,

    fetchInstalacao: PropTypes.func.isRequired,

    error: PropTypes.object,
    hideFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,

    calculateFaturaPedagio: PropTypes.func.isRequired,
    fetchResultado: PropTypes.func.isRequired,

    multipleTributo: PropTypes.object.isRequired,
    fetchMultipleTributo: PropTypes.func.isRequired,
  }

  state = {
    faturaPedagio: null
  }

  async componentDidMount () {
    if (this.props.id) {
      await Promise.all([
        this.props.fetchFaturaPedagio(this.props.id),
        this.props.fetchInstalacao(this.props.instalacaoId),
        this.props.fetchMultipleTributo(),
      ])
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push(`/instalacoes/${this.props.instalacaoId}/pedagio/new`)
  }

  handleEditResource = (id) => {
    this.props.history.push(`/instalacoes/${this.props.instalacaoId}/pedagio/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyFaturaPedagio(id)
      ? this.props.history.push(`/instalacoes/${this.props.instalacaoId}/pedagio`)
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  handleCalcular = async (id) => {
    this.props.hideFlashMessage()

    if (await this.props.calculateFaturaPedagio(id)) {
      this.props.showSuccessfulFlashMessage('', 'calculate')
      await this.props.fetchResultado(id)
    } else {
      this.props.showErrorFlashMessage('', 'calculate', this.props.error)
    }
  }

  getPane = (match) => {
    if (match.params.pane) return match.params.pane
    return 'informacoes'
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <FaturaPedagioShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        onCalcular={this.handleCalcular}
        faturaPedagio={this.props.faturaPedagio}
        instalacao={this.props.instalacao}
        pane={this.getPane(this.props.match)}
        multipleTributo={this.props.multipleTributo}
      />
    )
  }
}

function mapStateToProps ({faturaPedagio, tributo, status, instalacao}, {match}) {
  const { id, instalacaoId } = match.params
  if (id) {
    return {
      id,
      instalacaoId,
      error: status.getIn(['faturaPedagio', 'error']),
      faturaPedagio: faturaPedagio.get(id),
      instalacao: instalacao.get(instalacaoId),
      multipleTributo: tributo,
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...faturaPedagioActionCreators,
    ...resultadoActionCreators,
    ...instalacaoActionCreators,
    ...tributoActionCreators,
    ...flashMessageAcionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FaturaPedagioShowContainer))
