import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_FONTE_COMERCIALIZADORA = 'LOADING_FONTE_COMERCIALIZADORA'
export function loadingFonteComercializadora () {
  return {
    type: LOADING_FONTE_COMERCIALIZADORA,
  }
}

export const LOADING_FONTE_COMERCIALIZADORA_SUCCESS = 'LOADING_FONTE_COMERCIALIZADORA_SUCCESS'
export function loadingFonteComercializadoraSuccess (fonteComercializadora) {
  return {
    type: LOADING_FONTE_COMERCIALIZADORA_SUCCESS,
    fonteComercializadora,
  }
}

export const LOADING_FONTE_COMERCIALIZADORA_FAILURE = 'LOADING_FONTE_COMERCIALIZADORA_FAILURE'
export function loadingFonteComercializadoraFailure (error) {
  return {
    type: LOADING_FONTE_COMERCIALIZADORA_FAILURE,
    error,
  }
}

export const DESTROYING_FONTE_COMERCIALIZADORA_SUCCESS = 'DESTROYING_FONTE_COMERCIALIZADORA_SUCCESS'
export function destroyingFonteComercializadoraSuccess (fonteComercializadoraId) {
  return {
    type: DESTROYING_FONTE_COMERCIALIZADORA_SUCCESS,
    fonteComercializadoraId,
  }
}

const initialState = Map({})

export function fetchMultipleFonteComercializadora (comercializadoraId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFonteComercializadora())
      const multipleFonteComercializadora = await callAPI(`/comercializadoras/${comercializadoraId}/fontes_comercializadoras`)
      dispatch(loadingFonteComercializadoraSuccess(multipleFonteComercializadora))
      return multipleFonteComercializadora
    } catch (e) {
      dispatch(loadingFonteComercializadoraFailure(e))
      return false
    }
  }
}

export function fetchFonteComercializadora (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFonteComercializadora())
      const fonteComercializadora = await callAPI(`/fontes_comercializadoras/${id}`)
      dispatch(loadingFonteComercializadoraSuccess(fonteComercializadora))
      return fonteComercializadora
    } catch (e) {
      dispatch(loadingFonteComercializadoraFailure(e))
      return false
    }
  }
}

export function createFonteComercializadora (comercializadoraId, fonteComercializadora) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFonteComercializadora())
      const newFonteComercializadora = await callAPI(`/comercializadoras/${comercializadoraId}/fontes_comercializadoras`, 'POST', fonteComercializadora)
      dispatch(loadingFonteComercializadoraSuccess(newFonteComercializadora))
      return newFonteComercializadora
    } catch (e) {
      dispatch(loadingFonteComercializadoraFailure(e))
      return false
    }
  }
}

export function updateFonteComercializadora (id, fonteComercializadora) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFonteComercializadora())
      const newFonteComercializadora = await callAPI(`/fontes_comercializadoras/${id}`, 'PUT', fonteComercializadora)
      dispatch(loadingFonteComercializadoraSuccess(newFonteComercializadora))
      return newFonteComercializadora
    } catch (e) {
      dispatch(loadingFonteComercializadoraFailure(e))
      return false
    }
  }
}

export function destroyFonteComercializadora (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFonteComercializadora())
      await callAPI(`/fontes_comercializadoras/${id}`, 'DELETE')
      dispatch(destroyingFonteComercializadoraSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingFonteComercializadoraFailure(e))
      return false
    }
  }
}

export default function fonteComercializadora (state = initialState, action) {
  switch (action.type) {

    case LOADING_FONTE_COMERCIALIZADORA_SUCCESS : {

      const { fonteComercializadora } = action
      if (fonteComercializadora.constructor === Array) {
        state = initialState
        fonteComercializadora.forEach((singleFonteComercializadora) => (
          state = state.merge({[singleFonteComercializadora.id]: singleFonteComercializadora})
        ))
        return state
      }
      return state.merge({[fonteComercializadora.id]: fonteComercializadora})
    }

    case DESTROYING_FONTE_COMERCIALIZADORA_SUCCESS :
      return state.delete(action.fonteComercializadoraId.toString())

    default :
      return state
  }
}
