import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'

const FonteComercializadoraShow = (props) => {
  if (props.fonteComercializadora) {
    return (
      <CRUDShow noTitle padded={false}
        onEditResource={props.onEditResource}
        onDeleteResource={props.onDeleteResource}>
        <ul>
          <li>Fonte de Energia: {props.fonteComercializadora.fonte_energia.nome}</li>
          <li>Código CCEE: {props.fonteComercializadora.codigo_ccee}</li>
        </ul>
      </CRUDShow>
    )
  }

  return (null)
}

FonteComercializadoraShow.propTypes = {
  comercializadora: PropTypes.object,
  fonteComercializadora: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
}

export default FonteComercializadoraShow
