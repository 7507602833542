import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react'
import { CRUDShow } from '../CRUD'
import RenderRelatorio from './RenderRelatorio'
import { printPage } from '../../utils/print'

import viewMode from '../../utils/viewMode'

class RelatorioShow extends React.Component {
  // when component finished loading, print it
  componentWillReceiveProps(nextprops) {
    if (this.props.autoPrint && nextprops.isLoading === false && nextprops.relatorio !== null) {
      setTimeout(() => {
        window.requestAnimationFrame(() => {
          printPage()
        })
      }, 1500)
    }
  }

  render() {
    const {
      autoPrint,
      isLoading,
      onEditResource,
      onDeleteResource,
      onPrintResource,
      relatorio,
    } = this.props

    if (!relatorio) return (null)

    const { gestor } = relatorio

    return (
      <CRUDShow
        title={'Relatório'}
        titleAs={'h3'}
        icon='chart line'
        padded={false}
        isLoading={isLoading}
        onEditResource={autoPrint ? null : () => onEditResource(relatorio.id)}
        onDeleteResource={autoPrint ? null : () => onDeleteResource(relatorio.id)}
        onPrintResource={autoPrint ? null : () => onPrintResource(relatorio.id)}
        customButtonGroupItems={[
          () => (
            <Button icon as={Link} target='_blank' to={`/relatorio/${relatorio.raw_token}`}>
              <Icon name='share alternate' />
            </Button>
          )
        ]}
        sharePath={`/relatorios/${relatorio.raw_token}`}
        noTitle={autoPrint}
        noMessage={autoPrint}>

        <RenderRelatorio relatorio={relatorio} gestor={gestor} viewMode={autoPrint ? viewMode.PRINT : viewMode.EDIT} />

      </CRUDShow>
    )
  }
}

RelatorioShow.propTypes = {
  relatorio: PropTypes.object,
  autoPrint: PropTypes.bool,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  onPrintResource: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default RelatorioShow
