import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { InstalacaoCliente } from '../../../containers'

const Instalacao = (props) => {
  const { cliente } = props

  return (
    <div>
      <Switch>
        <Route
          exact path='/clientes/:clienteId/instalacoes'
          render={({match}) => (
            <InstalacaoCliente.Index
              cliente={cliente}
              match={match}
            />
          )}>
        </Route>
        <Route
          exact path='/clientes/:clienteId/instalacoes/new'
          render={({match}) => (
            <InstalacaoCliente.Form
              cliente={cliente}
              match={match}
            />
          )}
        />
      </Switch>
    </div>
  )
}

Instalacao.propTypes = {
  cliente: PropTypes.object.isRequired,
}

export default Instalacao
