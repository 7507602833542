import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_SUBMERCADO = 'LOADING_SUBMERCADO'
export function loadingSubmercado () {
  return {
    type: LOADING_SUBMERCADO,
  }
}

export const LOADING_SUBMERCADO_SUCCESS = 'LOADING_SUBMERCADO_SUCCESS'
export function loadingSubmercadoSuccess (submercado) {
  return {
    type: LOADING_SUBMERCADO_SUCCESS,
    submercado,
  }
}

export const LOADING_SUBMERCADO_FAILURE = 'LOADING_SUBMERCADO_FAILURE'
export function loadingSubmercadoFailure (error) {
  return {
    type: LOADING_SUBMERCADO_FAILURE,
    error,
  }
}

export const DESTROYING_SUBMERCADO_SUCCESS = 'DESTROYING_SUBMERCADO_SUCCESS'
export function destroyingSubmercadoSuccess (submercadoId) {
  return {
    type: DESTROYING_SUBMERCADO_SUCCESS,
    submercadoId,
  }
}

const initialState = Map({})

export function fetchMultipleSubmercado () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingSubmercado())
      const multipleSubmercado = await callAPI('/submercados')
      dispatch(loadingSubmercadoSuccess(multipleSubmercado))
      return multipleSubmercado
    } catch (e) {
      dispatch(loadingSubmercadoFailure(e))
      return false
    }
  }
}

export function fetchSubmercado (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingSubmercado())
      const submercado = await callAPI(`/submercados/${id}`)
      dispatch(loadingSubmercadoSuccess(submercado))
      return submercado
    } catch (e) {
      dispatch(loadingSubmercadoFailure(e))
      return false
    }
  }
}

export function createSubmercado (submercado) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingSubmercado())
      const newSubmercado = await callAPI('/submercados', 'POST', submercado)
      dispatch(loadingSubmercadoSuccess(newSubmercado))
      return newSubmercado
    } catch (e) {
      dispatch(loadingSubmercadoFailure(e))
      return false
    }
  }
}

export function updateSubmercado (id, submercado) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingSubmercado())
      const newSubmercado = await callAPI(`/submercados/${id}`, 'PUT', submercado)
      dispatch(loadingSubmercadoSuccess(newSubmercado))
      return newSubmercado
    } catch (e) {
      dispatch(loadingSubmercadoFailure(e))
      return false
    }
  }
}

export function destroySubmercado (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingSubmercado())
      await callAPI(`/submercados/${id}`, 'DELETE')
      dispatch(destroyingSubmercadoSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingSubmercadoFailure(e))
      return false
    }
  }
}

export default function submercado (state = initialState, action) {
  switch (action.type) {

    case LOADING_SUBMERCADO_SUCCESS : {

      const { submercado } = action
      if (submercado.constructor === Array) {
        state = initialState
        submercado.forEach((singleSubmercado) => (
          state = state.merge({[singleSubmercado.id]: singleSubmercado})
        ))
        return state
      }
      return state.merge({[submercado.id]: submercado})
    }

    case DESTROYING_SUBMERCADO_SUCCESS :
      return state.delete(action.submercadoId.toString())

    default :
      return state
  }
}
