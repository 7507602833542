import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { ContratoEnergiaInstalacao } from '../../../containers'

const ContratoEnergia = (props) => {
  const { instalacao } = props
  return (
    <div>
      <Switch>
        <Route
          exact path='/instalacoes/:instalacaoId/contratos_energia'
          render={({match}) => (
            <ContratoEnergiaInstalacao.Index
              instalacao={instalacao}
              match={match}
            />
          )}>
        </Route>
        <Route
          exact path='/instalacoes/:instalacaoId/contratos_energia/new'
          render={({match}) => (
            <ContratoEnergiaInstalacao.Form
              instalacao={instalacao}
              match={match}
            />
          )}
        />
      </Switch>
    </div>
  )
}

ContratoEnergia.propTypes = {
  instalacao: PropTypes.object.isRequired,
}

export default ContratoEnergia
