import { callAPI } from '../../utils/API'

export const LOADING_SIMULATION = 'LOADING_SIMULATION'
export function loadingSimulation () {
  return {
    type: LOADING_SIMULATION,
  }
}

export const LOADING_SIMULATION_SUCCESS = 'LOADING_SIMULATION_SUCCESS'
export function loadingSimulationSuccess () {
  return {
    type: LOADING_SIMULATION_SUCCESS,
  }
}

export const LOADING_SIMULATION_FAILURE = 'LOADING_SIMULATION_FAILURE'
export function loadingSimulationFailure (error) {
  return {
    type: LOADING_SIMULATION_FAILURE,
    error,
  }
}

export function simulateFaturaPedagio (id, cenarioId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingSimulation())
      const simulation = await callAPI(`/faturas_pedagio/${id}/simulate/${cenarioId}`)
      dispatch(loadingSimulationSuccess(simulation))
      return simulation
    } catch (e) {
      dispatch(loadingSimulationFailure(e))
      return false
    }
  }
}
