import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import { Link } from 'react-router-dom'
import { CRUDShow } from '../CRUD'
import { Button, Grid, Icon, Menu } from 'semantic-ui-react'
import { Informacoes, Resultados } from './panes'

import { getColor, getIcon, getText } from './StateUtils'

const FaturaPedagioShow = (props) => {
  const { faturaPedagio, instalacao, pane } = props

  if (faturaPedagio && instalacao) {
    const state = faturaPedagio.get('aasm_state')
    return (
      <CRUDShow noTitle noMessage padded={false}
        onEditResource={() => props.onEditResource(faturaPedagio.get('id'))}
        onDeleteResource={() => props.onDeleteResource(faturaPedagio.get('id'))}
        customToolbarItems={[
          () => (
            <Button color='blue' icon
              onClick={() => props.onCalcular(faturaPedagio.get('id'))}
              labelPosition='left'>
              <Icon name='calculator' />{' '}Calcular
            </Button>
          )
        ]}
        customButtonGroupItems={[
          () => (
            <Button icon as={Link} target='_blank' to={`/instalacoes/${instalacao.get('id')}/pedagio/${faturaPedagio.get('id')}/resultado/print`}>
              <Icon name='print' />
            </Button>
          )
        ]}>
        <Menu color={getColor(state)} inverted>
          <Menu.Item header>
            {faturaPedagio.get('mes')}/{faturaPedagio.get('ano')}
          </Menu.Item>
          <Menu.Item
            active={pane === 'informacoes'}
            as={Link}
            to={`/instalacoes/${instalacao.get('id')}/pedagio/${faturaPedagio.get('id')}`}
            key='faturaPedagio'>
            <Icon name='building' />
            Informações
          </Menu.Item>
          <Menu.Item
            active={pane === 'resultado'}
            as={Link}
            to={`/instalacoes/${instalacao.get('id')}/pedagio/${faturaPedagio.get('id')}/resultado`}
            key='resultado'>
            <Icon name='calculator' />
            Resultado
          </Menu.Item>
          <Menu.Menu inverted color={getColor(state)} position='right'>
            <Menu.Item active>
              <Icon name={getIcon(state)} />
              {getText(state)}
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Grid>
          <Grid.Column>
            <Switch>
              <Route
                exact path='/instalacoes/:instalacaoId/pedagio/:id'
                render={() => (
                  <Informacoes
                    faturaPedagio={faturaPedagio}
                    instalacao={instalacao}
                    onEditResource={props.onEditResource}
                    onDeleteResource={props.onDeleteResource}
                    multipleTributo={props.multipleTributo}
                  />
                )}
              />
              <Route
                exact path='/instalacoes/:instalacaoId/pedagio/:id/resultado'
                render={() => (
                  <Resultados
                    faturaPedagio={faturaPedagio} />
                )}
              />
            </Switch>
          </Grid.Column>
        </Grid>
      </CRUDShow>
    )
  }

  return (null)
}

FaturaPedagioShow.propTypes = {
  faturaPedagio: PropTypes.object,
  instalacao: PropTypes.object,
  pane: PropTypes.string,
  onEditResource: PropTypes.func.isRequired,
  onDeleteResource: PropTypes.func.isRequired,
  onCalcular: PropTypes.func.isRequired,
  multipleTributo: PropTypes.object,
}

export default FaturaPedagioShow
