import React from 'react'

import { Menu } from 'semantic-ui-react'

const Comparacao = ({ multipleCenario, onSimular }) => {
  return (
    <Menu>
      <Menu.Item header>
        Comparar com...
      </Menu.Item>
      {multipleCenario.valueSeq().map(cenario =>
        <Menu.Item
          key={cenario.get('id')}
          name={cenario.get('nome')}
          onClick={() => onSimular(cenario.get('id'))}>
          {cenario.get('nome')}
        </Menu.Item>
      )}
    </Menu>
  )
}

export default Comparacao
