import React from 'react'
import PropTypes from 'prop-types'
import { Table, Label, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { CRUDIndex } from '../CRUD'
import { EditDeleteButtonContainer } from '../../containers'
import { formatDate } from '../../utils/formats'
import { maskPercent } from '../../utils/masks'

const DECIMAL_DIGITS = 6

const VigenciaTributo = (props) => {
  const { multipleTributo } = props
  return (
    <CRUDIndex noTitle padded={false}
      onRedirectToNew={props.onRedirectToNew}
      onExportToCSV={props.onExportToCSV}
      filterText={props.filterText}
      handleFilter={props.handleFilter}
    >
      <Table sortable celled striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1} textAlign='center'
              onClick={props.handleSort('id')}
              sorted={props.handleSorted('id')}>#</Table.HeaderCell>
            <Table.HeaderCell
              onClick={props.handleSort('starts_at')}
              sorted={props.handleSorted('starts_at')}>Início da Vigência</Table.HeaderCell>
            {multipleTributo.valueSeq().map((tributo, index) => (
              <Table.HeaderCell key={index} textAlign='right'>{tributo.get('nome')} (%)</Table.HeaderCell>
            ))}
            <Table.HeaderCell width={1}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {props.isLoading && (
            <Table.Row>
              <Table.Cell colSpan={multipleTributo.size + 3}>
                <Loader active={props.isLoading} inline='centered' />
              </Table.Cell>
            </Table.Row>
          )}

          {props.multipleVigenciaTributo.valueSeq().map((vigenciaTributo, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell textAlign='center'>
                  <Label as={Link} to={`/concessionarias/${vigenciaTributo.getIn(['concessionaria', 'id'])}/tributos/${vigenciaTributo.get('id')}`}>{vigenciaTributo.get('id')}</Label>
                </Table.Cell>
                <Table.Cell>{formatDate(vigenciaTributo.get('starts_at'))}</Table.Cell>
                {multipleTributo.valueSeq().map((tributo, index) => {
                  const found = vigenciaTributo.get('aliquotas_tributos').find(v => v.get('tributo_id') === tributo.get('id'))
                  return (
                    <Table.Cell key={index} textAlign='right'>
                      {found ? maskPercent.parse(found.get('aliquota'), DECIMAL_DIGITS) : '0,000000'}
                    </Table.Cell>
                  )
                })}
                <Table.Cell>
                  <EditDeleteButtonContainer
                    onEditResource={() => props.onEditResource(vigenciaTributo.getIn(['concessionaria', 'id']), vigenciaTributo.get('id'))}
                    onDeleteResource={() => props.onDeleteResource(vigenciaTributo.get('id'))} />
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </CRUDIndex>
  )
}

VigenciaTributo.propTypes = {
  onRedirectToNew: PropTypes.func,
  multipleVigenciaTributo: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
  multipleTributo: PropTypes.object,
}

export default VigenciaTributo
