import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Container, Divider, Card } from 'semantic-ui-react'
import { ResponsiveContainer, ComposedChart, Bar, Line, XAxis, YAxis, Tooltip, Legend, LabelList } from 'recharts'

import { COLORS, capitalize, formatAsKW, formatAsIndicador   } from './utils/ChartUtils'

const fieldsNameMapping = {
  contrato: 'Contrato',
  lido: 'Leitura',
  ultrapassagem: 'Ultrapassagem',
  limite: 'Limite',
}

const contractSeriesId = 0
const measuredSeriesId = 1

const customFormatter = v =>
  v && v > 0.0
    ? formatAsIndicador()(v)
    : ''

const DemandaAnualChart = ({ header, data, height, showAnimations }) => {
  if (!data) return null

  const preparedData = data.map(d => {
    const prepared = Object.assign({}, d)
    prepared._labelFaturaMes = capitalize(moment({y: d.ano, M: d.mes - 1}).format('MMM'))

    const contrato = prepared.contrato
    const limite = prepared.limite
    const leitura = prepared.lido

    prepared.lido = leitura > limite ? contrato : leitura
    prepared.ultrapassagem = leitura > limite ? leitura - contrato : 0.0
    prepared.leitura_crua = leitura
    return prepared
  })

  return (
    <Container fluid>
      {header && <Divider horizontal>{header}</Divider>}
      <ResponsiveContainer height={height}>
        <ComposedChart data={preparedData}>
          <XAxis dataKey="_labelFaturaMes" xAxisId={contractSeriesId} type="category" interval={0} hide/>
          <XAxis dataKey="_labelFaturaMes" xAxisId={measuredSeriesId} type="category" interval={0} tickLine={false}/>
          <YAxis type="number" tickFormatter={formatAsKW} padding={{top: 10}} />
          <Tooltip content={<CustomTooltip />}/>
          <Legend/>
          <Bar
            dataKey="contrato"
            xAxisId={contractSeriesId}
            fill={COLORS.LIGHT.NEUTRAL}
            legendType="square"
            name={fieldsNameMapping['contrato']}
            isAnimationActive={showAnimations}
          />
          <Bar
            dataKey="lido"
            xAxisId={measuredSeriesId}
            stackId={measuredSeriesId}
            fill={COLORS.ELECTRIC.LIGHT.GREEN}
            legendType="square"
            name={fieldsNameMapping['lido']}
            isAnimationActive={showAnimations}>
            <LabelList className='color-white' position="insideBottom" dataKey="leitura_crua" formatter={customFormatter} />
          </Bar>
          <Bar
            dataKey="ultrapassagem"
            xAxisId={measuredSeriesId}
            stackId={measuredSeriesId}
            fill={COLORS.ELECTRIC.LIGHTER.RED}
            legendType="square"
            name={fieldsNameMapping['ultrapassagem']}
            isAnimationActive={showAnimations}>
            <LabelList className='color-red' position="top" dataKey="ultrapassagem" formatter={customFormatter} />
          </Bar>
          <Line
            type="step"
            dataKey="limite"
            xAxisId={contractSeriesId}
            stroke={COLORS.ELECTRIC.DARK.RED}
            legendType="line"
            name={fieldsNameMapping['limite']}
            dot={false}
            strokeWidth={1}
            isAnimationActive={showAnimations}
            strokeDasharray='2 3'
          />
          <Line
            type="step"
            dataKey="contrato"
            xAxisId={contractSeriesId}
            stroke={COLORS.DARK.NEUTRAL}
            legendType="line"
            name={fieldsNameMapping['contrato']}
            dot={false}
            strokeWidth={1}
            isAnimationActive={showAnimations}
          >
            <LabelList position='bottom' className='color-black' dataKey="contrato" formatter={customFormatter} />
          </Line>
        </ComposedChart>
      </ResponsiveContainer>
    </Container>
  )
}

DemandaAnualChart.propTypes = {
  header: PropTypes.string,
  data: PropTypes.array,
  height: PropTypes.number,
  showAnimations: PropTypes.bool,
}

DemandaAnualChart.defaultProps = {
  height: 350,
  showAnimations: true,
}

const CustomTooltip = ({ active, payload, label }) => {

  if (active && payload && payload.length) {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Description>
            <span>{label}</span>
            {payload.map(p => (
              <div key={p.dataKey}>
                <span style={{ color: p.color }}>
                  {p.name}:{' '}
                  {formatAsKW(
                    p.dataKey === 'lido'
                      ? p.payload['leitura_crua']
                      : p.payload[p.dataKey]
                  )}
                </span>
              </div>
            ))}
          </Card.Description>
        </Card.Content>
      </Card>
    )
  }

  return null
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string,
  payload: PropTypes.array,
}

export default DemandaAnualChart
