import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FieldArray, FormSection } from 'redux-form/immutable'
import { Input, Form, FormField, Header, Grid } from 'semantic-ui-react'
import { TextAreaField } from 'react-semantic-redux-form'

import { Message } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import {
  SemanticInputMask,
  SemanticDropdown,
  SemanticCheckbox,
  DatePickerInput,
} from '../FormComponents'

import RenderExtrasPedagio from './RenderExtrasPedagio'
import RenderTributosFatura from './RenderTributosFatura'
import ResumoParametros from './ResumoParametros'

import { required, dateValidator } from '../../utils/validations'
import { maskFloat, maskYear } from '../../utils/masks'

import { getPostos } from '../../utils/enums/tipoTarifa'
import { getPostos as getPostosDemanda } from '../../utils/enums/tipoDemanda'

import CalculadoraConsumoContainer from '../../containers/CalculadoraConsumo/CalculadoraConsumoContainer'

import moment from 'moment'
import 'moment/locale/pt-br'

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const meses = Array.apply(0, Array(12)).map((_, index) => ({
  key: moment().month(index).format('MMM'),
  text: capitalize(moment().month(index).format('MMMM')),
  value: index + 1,
}))

const [CONSUMO /*DEMANDA*/] = [1, 2]

let FaturaPedagioForm = props => {
  const parametroCalculo = props.lastVigenciaParametro
    ? props.lastVigenciaParametro.get('parametro_calculo')
    : undefined
  const {
    dataInicial = moment(),
    dataFinal = moment(),
    possuiTributos = false,
  } = props
  const mesmoMes = moment(dataInicial).isSame(moment(dataFinal), 'month')

  let postosConsumo = [],
    postosDemanda = [],
    concessionariaId = null,
    icms = '0.0',
    gerador

  if (parametroCalculo) {
    concessionariaId = parametroCalculo.get('concessionaria_id')
    icms = parametroCalculo.get('icms')
    const enquadramento = parametroCalculo.get('enquadramento')
    const subgrupo = parametroCalculo.get('subgrupo')
    postosConsumo = getPostos(enquadramento, subgrupo, CONSUMO)
    postosDemanda = getPostosDemanda(enquadramento, subgrupo)
    gerador = parametroCalculo.get('gerador')
  }

  const dataInicialFormatada = moment(dataInicial, 'DD/MM/YYYY').format(
    'YYYY-MM-DD'
  )
  const dataFinalFormatada = moment(dataFinal, 'DD/MM/YYYY').format(
    'YYYY-MM-DD'
  )

  const instalacao = props.lastVigenciaParametro
    ? props?.lastVigenciaParametro.get('instalacao_id')
    : ''

  return (
    <CRUDForm
      padded={false}
      title='Fatura de Pedágio'
      titleAs='h3'
      icon='dollar'
      {...props}>
      <FormSection name='fatura_pedagio'>
        <Grid celled>
          <Grid.Row columns={1}>
            <Grid.Column className='customHeaderColumn'>
              <Header as='h3'>Competência</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <FormField>
                <label htmlFor='ano'>Ano</label>
                <Field
                  name='ano'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskYear.mask}
                  placeholder={maskYear.placeholder}
                />
              </FormField>
              <FormField>
                <label htmlFor='mes'>Mês</label>
                <Field
                  fluid
                  button
                  floating
                  labeled
                  search
                  icon='calendar'
                  className='icon'
                  name='mes'
                  options={meses}
                  validate={[required]}
                  component={SemanticDropdown}
                  loading={props.loading}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column>
              <FormField>
                <label htmlFor='data_inicial'>Início da Medição</label>
                <Field
                  autoFocus
                  name='data_inicial'
                  placeholder='Data Inicial'
                  validate={[required, dateValidator]}
                  component={DatePickerInput}
                  selectsStart
                  loading={props.loading}
                />
              </FormField>
              <FormField>
                <label htmlFor='data_final'>Fim da Medição</label>
                <Field
                  autoFocus
                  name='data_final'
                  placeholder='Data Final'
                  validate={[required, dateValidator]}
                  component={DatePickerInput}
                  selectsStart
                  loading={props.loading}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <ResumoParametros
                vigenciaParametros={props.lastVigenciaParametro}
                dataFinal={dataFinal}
                instalacao={instalacao}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column width={8}>
              <FormField>
                <label htmlFor='observacoes'>Observações</label>
                <Field
                  name='observacoes'
                  component={TextAreaField}
                  loading={props.loading}
                  placeholder='...'
                  autoHeight
                />
              </FormField>
            </Grid.Column>
            <Grid.Column width={8}>
              <FormField>
                <Field
                  name='possui_tributos'
                  id='possui_tributos'
                  label='Tributos específicos da fatura'
                  component={SemanticCheckbox}
                  loading={props.loading}
                />
              </FormField>
              {possuiTributos && (
                <React.Fragment>
                  <Message
                    header='Atenção'
                    icon='warning circle'
                    content='Use esta opção se a concessionária utilizar alíquotas específicas por fatura. Em caso contrário, defina as alíquotas no cadastro de concessionárias.'
                  />

                  <FieldArray
                    name='tributos_fatura'
                    component={RenderTributosFatura}
                  />
                </React.Fragment>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column className='customHeaderColumn'>
              <Header as='h3'>Consumo</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              {postosConsumo.map((posto, index) => (
                <FormField key={index}>
                  <label>Consumo {posto.extenso}</label>
                  <Field
                    name={`consumo_${posto.sigla}`}
                    label='kWh'
                    validate={[required]}
                    component={SemanticInputMask}
                    loading={props.loading}
                    mask={maskFloat.mask()}
                    placeholder={maskFloat.placeholder}
                  />
                </FormField>
              ))}
            </Grid.Column>
            <Grid.Column>
              {!mesmoMes && (
                <Form.Group>
                  <FormField style={{ width: '100%' }}>
                    <label>
                      Consumo em{' '}
                      {capitalize(moment(dataInicial).format('MMMM/YYYY'))}
                    </label>
                    <Field
                      name='consumo_mes_inicial'
                      tabIndex='-1'
                      label='kWh'
                      validate={[required]}
                      component={SemanticInputMask}
                      loading={props.loading}
                      mask={maskFloat.mask()}
                      placeholder={maskFloat.placeholder}
                    />
                  </FormField>
                  <CalculadoraConsumoContainer
                    concessionariaId={concessionariaId}
                    data={dataInicialFormatada}
                    dataTributos={dataFinalFormatada}
                    icms={icms}
                    field='consumo_mes_inicial'
                    otherField='consumo_mes_final'
                    consumoTotal={props.consumoTotal}
                  />
                </Form.Group>
              )}
              <Form.Group>
                <FormField style={{ width: '100%' }}>
                  <label>
                    Consumo em{' '}
                    {capitalize(moment(dataFinal).format('MMMM/YYYY'))}
                  </label>
                  <Field
                    name='consumo_mes_final'
                    tabIndex='-1'
                    label='kWh'
                    validate={[required]}
                    component={SemanticInputMask}
                    loading={props.loading}
                    mask={maskFloat.mask()}
                    placeholder={maskFloat.placeholder}
                  />
                </FormField>
                <CalculadoraConsumoContainer
                  concessionariaId={concessionariaId}
                  data={dataFinalFormatada}
                  dataTributos={dataFinalFormatada}
                  icms={icms}
                  field='consumo_mes_final'
                  otherField={!mesmoMes ? 'consumo_mes_inicial' : null}
                  consumoTotal={props.consumoTotal}
                />
              </Form.Group>
              <FormField>
                <label>Consumo Total</label>
                <Input
                  readOnly
                  tabIndex='-1'
                  type='text'
                  label='kWh'
                  labelPosition='right'
                  value={props.consumoTotal}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>

          {!!postosDemanda.length && (
            <React.Fragment>
              <Grid.Row columns={1}>
                <Grid.Column className='customHeaderColumn'>
                  <Header as='h3'>Demanda</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  {postosDemanda.map((posto, index) => (
                    <FormField key={index}>
                      <label>Demanda {posto.extenso}</label>
                      <Field
                        name={`demanda_${posto.sigla}`}
                        label='kWh'
                        validate={[required]}
                        component={SemanticInputMask}
                        loading={props.loading}
                        mask={maskFloat.mask()}
                        placeholder={maskFloat.placeholder}
                      />
                    </FormField>
                  ))}
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          )}

          <Grid.Row columns={1}>
            <Grid.Column className='customHeaderColumn'>
              <Header as='h3'>Reativo</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <FormField>
                <label>UFER Ponta</label>
                <Field
                  name='ufer_p'
                  label='kWhr'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskFloat.mask()}
                  placeholder={maskFloat.placeholder}
                />
              </FormField>
              <FormField>
                <label>UFER Fora Ponta</label>
                <Field
                  name='ufer_fp'
                  label='kWhr'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskFloat.mask()}
                  placeholder={maskFloat.placeholder}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column>
              <FormField>
                <label>DMCR Ponta</label>
                <Field
                  name='dmcr_p'
                  label='kWr'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskFloat.mask()}
                  placeholder={maskFloat.placeholder}
                />
              </FormField>
              <FormField>
                <label>DMCR Fora Ponta</label>
                <Field
                  name='dmcr_fp'
                  label='kWr'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskFloat.mask()}
                  placeholder={maskFloat.placeholder}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>

          {parametroCalculo &&
            parametroCalculo.get('possui_gerador') &&
            gerador && (
              <React.Fragment>
                <Grid.Row columns={1}>
                  <Grid.Column className='customHeaderColumn'>
                    <Header as='h3'>Gerador</Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <FormField>
                      <label>Consumo Mensal</label>
                      <Field
                        name='consumo_mensal_combustivel'
                        label={gerador.get('unidade_combustivel')}
                        validate={[required]}
                        component={SemanticInputMask}
                        loading={props.loading}
                        mask={maskFloat.mask()}
                        placeholder={maskFloat.placeholder}
                      />
                    </FormField>
                  </Grid.Column>
                  <Grid.Column>
                    <FormField>
                      <label>Custo Mensal</label>
                      <Field
                        name='custo_total_combustivel'
                        label='R$'
                        validate={[required]}
                        component={SemanticInputMask}
                        loading={props.loading}
                        mask={maskFloat.mask()}
                        placeholder={maskFloat.placeholder}
                      />
                    </FormField>
                  </Grid.Column>
                </Grid.Row>
              </React.Fragment>
            )}

          <Grid.Row columns={1}>
            <Grid.Column className='customHeaderColumn'>
              <Header as='h3'>Outros</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <FormField>
                <label>Multas</label>
                <Field
                  name='multas'
                  label='R$'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskFloat.mask()}
                  placeholder={maskFloat.placeholder}
                />
              </FormField>
              <FormField>
                <label>Juros</label>
                <Field
                  name='juros'
                  label='R$'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskFloat.mask()}
                  placeholder={maskFloat.placeholder}
                />
              </FormField>
              <FormField>
                <label>Correção Monetária</label>
                <Field
                  name='correcao_monetaria'
                  label='R$'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskFloat.mask()}
                  placeholder={maskFloat.placeholder}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column>
              <FormField>
                <label>Iluminação Pública</label>
                <Field
                  name='iluminacao_publica'
                  label='R$'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskFloat.mask()}
                  placeholder={maskFloat.placeholder}
                />
              </FormField>
              <FormField>
                <label>Encargos de Conexão</label>
                <Field
                  name='encargos_conexao'
                  label='R$'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskFloat.mask()}
                  placeholder={maskFloat.placeholder}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>

          <FieldArray
            name='extras_pedagio_attributes'
            component={RenderExtrasPedagio}
          />

          <Grid.Row columns={1}>
            <Grid.Column className='customHeaderColumn'>
              <Header as='h3'>Total</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <FormField>
                <label>Valor Total da Fatura</label>
                <Field
                  name='valor_total'
                  label='R$'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskFloat.mask()}
                  placeholder={maskFloat.placeholder}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </FormSection>
    </CRUDForm>
  )
}

FaturaPedagioForm.propTypes = {
  lastVigenciaParametro: PropTypes.object,
  loading: PropTypes.bool,
  consumoTotal: PropTypes.string,
  dataInicial: PropTypes.object,
  dataFinal: PropTypes.object,
  possuiTributos: PropTypes.bool,
}

FaturaPedagioForm.defaultProps = {
  dataFinal: moment(),
}

const validate = values => {
  const errors = {
    fatura_pedagio: {},
  }

  // A validação abaixo está comentada pois o componente DatePickerInput ainda não suporta
  // a exibição de erros provenientes do redux-form.

  // const dataInicial = moment(values.getIn(['fatura_pedagio', 'data_inicial']))
  // const dataFinal = moment(values.getIn(['fatura_pedagio', 'data_final']))
  //
  // if (!dataInicial.isBefore(dataFinal, 'day')) {
  //   errors.fatura_pedagio.data_final = `Deve ser após ${dataInicial.format('DD/MM/YYYY')}`
  // }

  const consumoPonta = maskFloat.unmask(
    values.getIn(['fatura_pedagio', 'consumo_p']) || '0,00'
  )
  const consumoForaPonta = maskFloat.unmask(
    values.getIn(['fatura_pedagio', 'consumo_fp']) || '0,00'
  )
  const consumoNA = maskFloat.unmask(
    values.getIn(['fatura_pedagio', 'consumo_na']) || '0,00'
  )
  const consumoIntermediario = maskFloat.unmask(
    values.getIn(['fatura_pedagio', 'consumo_int']) || '0,00'
  )
  const consumoMesInicial = maskFloat.unmask(
    values.getIn(['fatura_pedagio', 'consumo_mes_inicial']) || '0,00'
  )
  const consumoMesFinal = maskFloat.unmask(
    values.getIn(['fatura_pedagio', 'consumo_mes_final']) || '0,00'
  )

  const consumoTotal = parseFloat(
    (
      consumoPonta +
      consumoForaPonta +
      consumoNA +
      consumoIntermediario
    ).toFixed(2)
  )
  const consumoTotalMensal = parseFloat(
    (consumoMesInicial + consumoMesFinal).toFixed(2)
  )

  if (consumoTotal !== consumoTotalMensal && consumoTotalMensal) {
    errors.fatura_pedagio.consumo_mes_inicial = `O consumo mensal deve totalizar ${maskFloat.parse(
      consumoPonta + consumoForaPonta + consumoNA + consumoIntermediario
    )} kWh`
    errors.fatura_pedagio.consumo_mes_final = `O consumo mensal deve totalizar ${maskFloat.parse(
      consumoPonta + consumoForaPonta + consumoNA + consumoIntermediario
    )} kWh`
  }

  return errors
}

export default reduxForm({ form: 'FaturaPedagioForm', validate })(
  FaturaPedagioForm
)
