import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form/immutable'

import { InstalacaoForm } from '../../components'
import * as instalacaoActionCreators from '../../redux/modules/instalacao'
import * as ufActionCreators from '../../redux/modules/uf'
import * as userActionCreators from '../../redux/modules/user'
import * as unidadeActionCreators from '../../redux/modules/unidade'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { mapToDropdown } from '../../utils/parse'

const selector = formValueSelector('InstalacaoForm')

class InstalacaoFormContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,

    fetchInstalacao: PropTypes.func.isRequired,
    createInstalacao: PropTypes.func.isRequired,
    updateInstalacao: PropTypes.func.isRequired,

    fetchMultipleUf: PropTypes.func.isRequired,
    ufDropdown: PropTypes.array.isRequired,

    fetchMultipleUnidade: PropTypes.func.isRequired,
    unidadeDropdown: PropTypes.array.isRequired,

    userDropdown: PropTypes.array.isRequired,
    fetchMultipleUser: PropTypes.func.isRequired,

    pessoa: PropTypes.number,
    pessoaDropdown: PropTypes.array.isRequired,

    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await Promise.all([
      this.props.fetchMultipleUf(),
      this.props.fetchMultipleUser('analista'),
      this.props.fetchMultipleUnidade('analista'),
    ])

    if (this.props.id) {
      const instalacao = await this.props.fetchInstalacao(this.props.id)
      this.props.initialize('InstalacaoForm', {instalacao})
    } else {
      this.props.initialize('InstalacaoForm', { instalacao: { pessoa: 0 } })
    }
  }

  handleSubmitResource = async (instalacao) => {
    if (this.props.id) {
      if (await this.props.updateInstalacao(this.props.id, instalacao)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/instalacoes/${this.props.id}`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createInstalacao(instalacao)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/instalacoes')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <InstalacaoForm
        pessoa={this.props.pessoa}
        pessoaDropdown={this.props.pessoaDropdown}
        ufDropdown={this.props.ufDropdown}
        userDropdown={this.props.userDropdown}
        unidadeDropdown={this.props.unidadeDropdown}
        onSubmit={(instalacao) => this.handleSubmitResource(instalacao)}
        loading={this.props.isLoading} />
    )
  }
}

function mapStateToProps (state, {match}) {
  const {instalacao, uf, user, unidade, status, flashMessage} = state
  const isLoading = status.getIn(['instalacao', 'isLoading'])
    || status.getIn(['uf', 'isLoading'])
    || status.getIn(['user', 'isLoading'])
    || status.getIn(['unidade', 'isLoading'])

  const { id } = match.params

  const ufDropdown = mapToDropdown(
    uf,
    e => e.get('id'),
    e => `${e.get('sigla')} - ${e.get('nome')}`
  )

  const unidadeDropdown = mapToDropdown(
    unidade,
    e => e.get('id'),
    e => `${e.get('apelido')}`,
    true,
    'Nenhuma'
  )

  const userDropdown = mapToDropdown(
    user,
    e => e.get('id'),
    e => e.get('email')
  )

  const pessoa = selector(state, 'instalacao.pessoa')

  const pessoaDropdown = [
    {
      key: 'juridica',
      value: 0,
      text: 'Pessoa Jurídica',
    },
    {
      key: 'fisica',
      value: 1,
      text: 'Pessoa Física',
    },
  ]

  if (id) {
    return {
      id,
      instalacao: instalacao.get('id'),
      unidadeDropdown,
      ufDropdown,
      userDropdown,
      pessoa,
      pessoaDropdown,
      error: status.getIn(['instalacao', 'error']),
      shouldHideFlashMessage: flashMessage.get('isError'),
      isLoading,
    }
  }
  return {
    unidadeDropdown,
    ufDropdown,
    userDropdown,
    pessoa,
    pessoaDropdown,
    error: status.getIn(['instalacao', 'error']),
    shouldHideFlashMessage: flashMessage.get('isError'),
    isLoading,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...instalacaoActionCreators,
    ...ufActionCreators,
    ...userActionCreators,
    ...unidadeActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InstalacaoFormContainer)
