import React from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Dropdown, Form, Grid, Header, Icon, Label, Loader, Menu, Popup, Table } from 'semantic-ui-react'
import { FlashMessageContainer } from '../../containers'
import { maskCurrency } from '../../utils/masks'

const CustomTableCell = (valor, teste = false) =>
  <Table.Cell textAlign='right' warning={teste}>
    {maskCurrency.parse(valor)}
  </Table.Cell>

const CustomPopup = (valor, teste) => {
  const labelAumento = parseFloat(teste.demanda) === 0
    ? 'Novo Contrato'
    : `+${maskCurrency.parse((valor / teste.demanda - 1) * 100)}%`
  return (
    <Popup trigger={CustomTableCell(valor, true)} wide='very' position='top right'>
      <Header as='h3'>Período de Testes</Header>
      <p>
        Contrato anterior: <strong>{maskCurrency.parse(teste.demanda)} kW</strong> (em {teste.mes_nome}/{teste.ano})
        <br />
        Contrato atual: <strong>{maskCurrency.parse(valor)} kW</strong>
      </p>
      <Container fluid textAlign='right'>
        <Label color='orange' size='huge'>{labelAumento}</Label>
      </Container>
    </Popup>
  )
}


const ContratoDemandaInstalacao = (props) => {
  const collection = props.multipleContratoDemandaInstalacao.reduce((acc, curr) => {
    let month = curr.get('mes') - 1
    if (!acc[month]) acc[month] = {
      ano: curr.get('ano'),
      mes: curr.get('mes'),
      mes_nome: curr.get('mes_nome'),
      valor_p: curr.get('valor_p'),
      teste_p: curr.get('teste_p').toJSON(),
      valor_fp: curr.get('valor_fp'),
      teste_fp: curr.get('teste_fp').toJSON(),
      valor_na: curr.get('valor_na'),
      teste_na: curr.get('teste_na').toJSON(),
    }

    return acc
  }, [])

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header as='h3' dividing>
            <Icon name='file text' />
            <Header.Content>
              Contrato de Demanda
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={16}>
          <Menu secondary>
            <Menu.Menu position='left'>
              <Menu.Item fitted>
                <Form as='div'>
                  <Form.Group inline className='customFormGroup'>
                    <Form.Field>
                      <label>Ano</label>
                      <Dropdown compact
                        onChange={(event, data) => props.onChange({
                          target: {
                            name: 'year',
                            value: data.value
                          }
                        })}
                        label='Ano'
                        options={props.years}
                        placeholder='Ano'
                        selection
                        value={props.year}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu position='right'>
              <Menu.Item fitted>
                <Button.Group>
                  <Button onClick={props.onEditResource} color='yellow' icon
                    labelPosition='left'>
                    <Icon name='edit' />{' '}Editar
                  </Button>
                </Button.Group>
              </Menu.Item>
            </Menu.Menu>
            <Menu.Item fitted>
              <Button onClick={props.onExportToCSV} animated='vertical'>
                <Button.Content hidden>CSV</Button.Content>
                <Button.Content visible>
                  <Icon name='download' />
                </Button.Content>
              </Button>
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column width={16} style={{ marginBottom: '1em' }}>
          <FlashMessageContainer />
        </Grid.Column>
        <Grid.Column width={16}>
          <Table celled striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1} textAlign='right'>Vigência</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='right'>Demanda Ponta (kW)</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='right'>Demanda Fora Ponta (kW)</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='right'>Demanda (kW)</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {props.isLoading && (
                <Table.Row>
                  <Table.Cell colSpan={3}>
                    <Loader active={props.isLoading} inline='centered' />
                  </Table.Cell>
                </Table.Row>
              )}
              {collection.map((contratoDemandaInstalacao, index) => (
                <Table.Row key={index} textAlign='right'>
                  <Table.Cell>
                    {contratoDemandaInstalacao.mes_nome}/{contratoDemandaInstalacao.ano}
                  </Table.Cell>
                  {contratoDemandaInstalacao.teste_p.teste
                    ? CustomPopup(contratoDemandaInstalacao.valor_p, contratoDemandaInstalacao.teste_p)
                    : CustomTableCell(contratoDemandaInstalacao.valor_p)}

                  {contratoDemandaInstalacao.teste_fp.teste
                    ? CustomPopup(contratoDemandaInstalacao.valor_fp, contratoDemandaInstalacao.teste_fp)
                    : CustomTableCell(contratoDemandaInstalacao.valor_fp)}

                  {contratoDemandaInstalacao.teste_na.teste
                    ? CustomPopup(contratoDemandaInstalacao.valor_na, contratoDemandaInstalacao.teste_na)
                    : CustomTableCell(contratoDemandaInstalacao.valor_na)}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

ContratoDemandaInstalacao.propTypes = {
  isLoading: PropTypes.bool,
  loading: PropTypes.bool,
  multipleContratoDemandaInstalacao: PropTypes.object,

  onEditResource: PropTypes.func,
  onExportToCSV: PropTypes.func,
  onUploadCSV: PropTypes.func,
  onChange: PropTypes.func,

  year: PropTypes.number,
  month: PropTypes.number,
  week: PropTypes.number,
  years: PropTypes.array,
  months: PropTypes.array,
  weeks: PropTypes.array,
}

export default ContratoDemandaInstalacao
