import React from 'react'
import PropTypes from 'prop-types'

import { Divider, Grid, List, Label, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { CRUDShow } from '../../CRUD'

function getMesNome (mes) {
  switch (mes) {
    case 1: return 'Janeiro'
    case 2: return 'Fevereiro'
    case 3: return 'Março'
    case 4: return 'Abril'
    case 5: return 'Maio'
    case 6: return 'Junho'
    case 7: return 'Julho'
    case 8: return 'Agosto'
    case 9: return 'Setembro'
    case 10: return 'Outubro'
    case 11: return 'Novembro'
    case 12: return 'Dezembro'
    default: return ''
  }
}

const Informacoes = (props) => {
  const { concessionaria, uf, isLoading } = props
  const { rateio_ponderado_tributos, rateio_ponderado_icms } = concessionaria

  return (
    <CRUDShow noTitle padded={false}
      onEditResource={() => props.onEditResource(concessionaria.id)}
      onDeleteResource={() => props.onDeleteResource(concessionaria.id)}>
      <Divider horizontal>Dados Gerais</Divider>
      {isLoading && (
        <Loader active={isLoading} inline='centered' />
      )}
      <Grid>
        <Grid.Column textAlign='right' width={3}>
          <strong>Sigla</strong>
        </Grid.Column>
        <Grid.Column width={3}>
          {concessionaria.sigla}
        </Grid.Column>

        <Grid.Column textAlign='right' width={3}>
          <strong>Nome</strong>
        </Grid.Column>
        <Grid.Column width={7}>
          {concessionaria.nome}
        </Grid.Column>

        <Grid.Column textAlign='right' width={3}>
          <strong>CNPJ</strong>
        </Grid.Column>
        <Grid.Column width={3}>
          {concessionaria.cnpj}
        </Grid.Column>

        <Grid.Column textAlign='right' width={3}>
          <strong>Unidade Federativa</strong>
        </Grid.Column>
        <Grid.Column  width={7}>
          <Label as={Link} to={`/uf/${uf.id}`}>
            {uf.nome}
          </Label>
        </Grid.Column>

        <Grid.Column textAlign='right' width={3}>
          <strong>Início Hora Ponta</strong>
        </Grid.Column>
        <Grid.Column width={3}>
          {concessionaria.hora_p_inicio}
        </Grid.Column>

        <Grid.Column textAlign='right' width={3}>
          <strong>Fim Hora Ponta</strong>
        </Grid.Column>
        <Grid.Column width={7}>
          {concessionaria.hora_p_fim}
        </Grid.Column>

        <Grid.Column textAlign='right' width={3}>
          <strong>Reajuste</strong>
        </Grid.Column>
        <Grid.Column width={13}>
          {concessionaria.dia}/{getMesNome(concessionaria.mes)}
        </Grid.Column>
      </Grid>
      <Divider horizontal>Parâmetros de Cálculo de Faturas</Divider>
      <Grid>
        <Grid.Column width={16}>
          <List relaxed>
            {rateio_ponderado_tributos && (
              <List.Item>
                <List.Icon name='thumbs up' color='green' size='large' verticalAlign='middle' />
                <List.Content>
                  <List.Header>Rateio ponderado de tributos</List.Header>
                  <List.Description>Realiza o rateio ponderado para o cálculo dos valores de tributos para consumo e demanda.</List.Description>
                </List.Content>
              </List.Item>
            )}
            {rateio_ponderado_icms && (
              <List.Item>
                <List.Icon name='thumbs up' color='green' size='large' verticalAlign='middle' />
                <List.Content>
                  <List.Header>Rateio ponderado de ICMS</List.Header>
                  <List.Description>Realiza o rateio ponderado para o cálculo do valor de ICMS para a demanda lida.</List.Description>
                </List.Content>
              </List.Item>
            )}
          </List>
        </Grid.Column>
      </Grid>
      <Divider horizontal>Outras Informações</Divider>
      <Grid>
        <Grid.Column textAlign='right' width={3}>
          <em>Criado em</em><br />
          <em>Atualizado em</em>
        </Grid.Column>
        <Grid.Column  width={13}>
          {concessionaria.created_at}<br/>
          {concessionaria.updated_at}
        </Grid.Column>
      </Grid>
    </CRUDShow>
  )
}

Informacoes.propTypes = {
  uf: PropTypes.object,
  concessionaria: PropTypes.object,
  onEditResource: PropTypes.func.isRequired,
  onDeleteResource: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

export default Informacoes
