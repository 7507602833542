import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import moment from 'moment'

import { ServicoInstalacaoForm } from '../../components'
import * as servicoInstalacaoActionCreators from '../../redux/modules/servicoInstalacao'
import * as servicoActionCreators from '../../redux/modules/servico'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

import { mapToDropdown } from '../../utils/parse'

class ServicoInstalacaoFormContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    initialize: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    instalacao: PropTypes.object,

    fetchServicoInstalacao: PropTypes.func.isRequired,
    createServicoInstalacao: PropTypes.func.isRequired,
    updateServicoInstalacao: PropTypes.func.isRequired,

    servicoDropdown: PropTypes.array.isRequired,
    fetchMultipleServico: PropTypes.func.isRequired,

    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleServico()
    if (this.props.id) {
      const servico_prestado = await this.props.fetchServicoInstalacao(this.props.id)
      this.props.initialize('ServicoInstalacaoForm', {servico_prestado})
    } else {
      this.props.initialize('ServicoInstalacaoForm', {
        servico_prestado: {
          instalacao_id: this.props.instalacao.id,
          executado_em: moment()
        }
      })
    }
  }

  handleSubmitResource = async (servicoInstalacao) => {
    const instalacaoId = this.props.instalacao.id

    if (this.props.id) {
      if (await this.props.updateServicoInstalacao(this.props.id, servicoInstalacao)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/instalacoes/${instalacaoId}/servicos`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createServicoInstalacao(instalacaoId, servicoInstalacao)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push(`/instalacoes/${instalacaoId}/servicos`)
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <ServicoInstalacaoForm
        instalacao={this.props.instalacao}
        servicoDropdown={this.props.servicoDropdown}
        onSubmit={(servicoInstalacao) => this.handleSubmitResource(servicoInstalacao)}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({servicoInstalacao, servico, status, flashMessage}, {match}) {
  const { id } = match.params

  const servicoDropdown = mapToDropdown(
    servico,
    e => e.get('id'),
    e => e.get('nome')
  )

  if (id) {
    return {
      id,
      servicoInstalacao: servicoInstalacao.get('id'),
      servicoDropdown,
      error: status.getIn(['servicoInstalacao', 'error']),
      isLoading: status.getIn(['servicoInstalacao', 'isLoading']),
      shouldHideFlashMessage: flashMessage.get('isError'),
    }
  }

  return {
    servicoDropdown,
    error: status.getIn(['servicoInstalacao', 'error']),
    isLoading: status.getIn(['servicoInstalacao', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...servicoInstalacaoActionCreators,
    ...servicoActionCreators,
    ...flashMessageActionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServicoInstalacaoFormContainer))
