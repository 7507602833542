import { Map } from 'immutable'

export const multipleCalculoTributo = Map({
  0: Map({
    id: 0,
    nome: 'Aplicação direta nas tarifas'
  }),
  1: Map({
    id: 1,
    nome: 'Impostos proporcionais'
  }),
})
