import React from 'react'
import PropTypes from 'prop-types'

import { Table } from 'semantic-ui-react'
import { maskFloat } from '../../utils/masks'

const SimpleTable = ({ data, title, color, total }) => {

  if (!data.length) return (null)

  let sum = 0.0

  const outros = data.map((outro, index) => {
    const valor = parseFloat(outro.valor)
    sum += parseFloat(outro.valor)

    if (!valor) return (null)

    return (
      <Table.Row key={index}>
        <Table.Cell textAlign='right'>{outro.descricao}</Table.Cell>
        <Table.Cell textAlign='right' style={{ fontFamily: 'monospace' }}>{maskFloat.parse(outro.valor)}</Table.Cell>
      </Table.Row>
    )
  })

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={4} style={{ backgroundColor: color }} textAlign='right'>{title}</Table.HeaderCell>
          <Table.HeaderCell width={12} style={{ backgroundColor: color }} textAlign='right'>Valor</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {outros}
      </Table.Body>
      {total && (
        <Table.Footer>
          <Table.Row className='pseudo-total'>
            <Table.HeaderCell textAlign='right'>Total</Table.HeaderCell>
            <Table.HeaderCell textAlign='right' style={{ fontFamily: 'monospace' }}>{maskFloat.parse(sum.toFixed(2))}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  )
}

SimpleTable.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  data: PropTypes.object,
  total: PropTypes.bool,
}

SimpleTable.defaultProps = {
  total: true,
}

export default SimpleTable
