import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import { withRouter } from 'react-router-dom'
import SimulacaoChart from './SimulacaoChart'

const Simulacoes = ({ data, match }) => {
  const id = match && match.params && match.params.id
  const print = match.url.endsWith('/print')
  return data.map((simulacao, index) => {
    return <SimulacaoChart
      id={id}
      cenarioId={get(simulacao, 'cenario.id')}
      key={index}
      header={get(simulacao, 'cenario.nome', '...')}
      subheader={get(simulacao, 'cenario.descricao', '...')}
      data={get(simulacao, 'dados', [])}
      print={print}
    />
  })
}

Simulacoes.propTypes = {
  data: PropTypes.object,
  match: PropTypes.object,
}

Simulacoes.defaultProps = {
  data: [],
}

export default withRouter(Simulacoes)
