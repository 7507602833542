import React from 'react'
import PropTypes from 'prop-types'
import { Header, Icon } from 'semantic-ui-react'

const Indicador = (props) => {
  const {
    title,
    value,
    valueFormatter,
    valueColor,
    icon,
    variation,
    variationValueFormatter,
    variationText,
    variationIcon,
    variationColor,
  } = props

  const valueColored = (typeof valueColor === 'function') ? valueColor(value) : valueColor
  const variationFormatted = variationValueFormatter(variation)
  const variationColorParsed = (typeof variationColor === 'function') ? variationColor(value, variation) : variationColor
  const variationStyle = {fontWeight: 'bold', marginRight: '0.3rem', color: variationColorParsed}
  const variationTextParsed = (typeof variationText === 'function') ? variationText(value, variation) : variationText

  // Mounts the icon to use as comparison (before the variation value)
  let varIcon = null
  const variationIconParsed = (typeof variationIcon === 'function') ? variationIcon(value, variation) : variationIcon
  if (variationIconParsed) {
    varIcon = (<Icon size='big' name={variationIconParsed} style={{color: variationColorParsed, marginRight: '0.15rem', lineHeight: '0.9'}} />)
  }

  return (
    <div className='indicador'>
      <Header as='h5' className='titulo-indicador base-color-light'>
        {title}
      </Header>
      <Header as='h2' style={{fontWeight: 'bold', margin: '0 0 0.5rem 0', fontSize: '2.2em', color: valueColored }}>
        {valueFormatter(value)}
      </Header>
      <div className='base-color-light' style={{minHeight: '26px'}}>
        {icon ? (<span style={{marginRight: '0.3rem'}}>{icon}</span>) : null}
        {varIcon}
        {variationFormatted ? (<span style={variationStyle}>{variationFormatted}</span>) : null}
      </div>
      <div className='base-color-light' style={{fontSize: '0.8em'}}>
        {variationTextParsed}
      </div>
    </div>
  )
}

Indicador.propTypes = {
  title: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  valueColor: PropTypes.func,
  valueFormatter: PropTypes.func,
  icon: PropTypes.node,
  variation: PropTypes.any,
  variationValueFormatter: PropTypes.func,
  variationText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  variationIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  variationColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

Indicador.defaultProps = {
  valueFormatter: a => a,
  icon: null,
  variation: null,
  variationValueFormatter: a => a,
  variationText: '',
  variationIcon: '',
  variationColor: '#6A4459',
}

export default Indicador
