import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { VigenciaTarifaShow } from '../../components'
import * as vigenciaTarifaActionCreators from '../../redux/modules/vigenciaTarifa'
import * as subgrupoActionCreators from '../../redux/modules/subgrupo'
import * as enquadramentoActionCreators from '../../redux/modules/enquadramento'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

class VigenciaTarifaShowContainer extends Component {

  static propTypes = {
    concessionaria: PropTypes.object.isRequired,

    id: PropTypes.string,
    history: PropTypes.object,
    fetchVigenciaTarifa: PropTypes.func,
    destroyVigenciaTarifa: PropTypes.func,
    isLoading: PropTypes.bool,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,

    fetchMultipleSubgrupo: PropTypes.func,
    fetchMultipleEnquadramento: PropTypes.func,
    multipleSubgrupo: PropTypes.object,
    multipleEnquadramento: PropTypes.object,
  }

  state = {
    vigenciaTarifa: null
  }

  async componentDidMount () {
    await Promise.all([
      this.props.fetchMultipleSubgrupo(),
      this.props.fetchMultipleEnquadramento(),
    ])

    if (this.props.id) {
      const vigenciaTarifa = await this.props.fetchVigenciaTarifa(this.props.id)
      this.setState({ vigenciaTarifa })
    }
  }

  handleEditResource = (concessionariaId, id) => {
    this.props.history.push(`/concessionarias/${concessionariaId}/tarifas/${id}/edit`)
  }

  handleDeleteResource = async (concessionariaId, id) => {
    if (await this.props.destroyVigenciaTarifa(id)) {
      this.props.showSuccessfulFlashMessage('', 'destroy')
      this.props.history.push(`/concessionarias/${concessionariaId}/tarifas`)
    } else {
      this.props.showErrorFlashMessage('', 'destroy', this.props.error)
    }
  }

  render () {
    return (
      <VigenciaTarifaShow
        isLoading={this.props.isLoading}
        concessionaria={this.props.concessionaria}
        multipleSubgrupo={this.props.multipleSubgrupo}
        multipleEnquadramento={this.props.multipleEnquadramento}
        onEditResource={() => this.handleEditResource(this.props.concessionaria.id, this.state.vigenciaTarifa.id)}
        onDeleteResource={() => this.handleDeleteResource(this.props.concessionaria.id, this.state.vigenciaTarifa.id)}
        vigenciaTarifa={this.state.vigenciaTarifa} />
    )
  }
}

function mapStateToProps ({vigenciaTarifa, subgrupo, enquadramento, status}, {match}) {
  const { id } = match.params
  const isLoading = status.getIn(['vigenciaTarifa', 'isLoading'])
                 || status.getIn(['subgrupo', 'isLoading'])
                 || status.getIn(['enquadramento', 'isLoading'])
  if (id) {
    return {
      id,
      vigenciaTarifa: vigenciaTarifa.get('id'),
      multipleSubgrupo: subgrupo,
      multipleEnquadramento: enquadramento,
      isLoading,
    }
  }
  return {
    multipleSubgrupo: subgrupo,
    multipleEnquadramento: enquadramento,
    isLoading,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...vigenciaTarifaActionCreators,
    ...subgrupoActionCreators,
    ...enquadramentoActionCreators,
    ...flashMessageActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VigenciaTarifaShowContainer))
