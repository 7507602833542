import React from 'react'
import PropTypes from 'prop-types'
import { Label, Table, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { CRUDIndex } from '../CRUD'
import { EditDeleteButtonContainer } from '../../containers'
import { maskCurrency } from '../../utils/masks'

const FaturaEnergia = (props) => (
  <CRUDIndex
    title='Faturas de Energia'
    icon='dollar'
    onRedirectToNew={props.onRedirectToNew}
    onExportToCSV={props.onExportToCSV}
    filterText={props.filterText}
    handleFilter={props.handleFilter}
  >
    <Table sortable celled striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1} textAlign='center'
            onClick={props.handleSort('id')}
            sorted={props.handleSorted('id')}>#</Table.HeaderCell>
          <Table.HeaderCell textAlign='center'
            onClick={props.handleSort('contrato_energia')}
            sorted={props.handleSorted('contrato_energia')}>Contrato</Table.HeaderCell>
          <Table.HeaderCell textAlign='center'
            onClick={props.handleSort('consumo')}
            sorted={props.handleSorted('consumo')}>Consumo (kWh)</Table.HeaderCell>
          <Table.HeaderCell textAlign='center'
            onClick={props.handleSort('perdas')}
            sorted={props.handleSorted('perdas')}>Perdas (%)</Table.HeaderCell>
          <Table.HeaderCell textAlign='center'
            onClick={props.handleSort('valor_total')}
            sorted={props.handleSorted('valor_total')}>Valor Total (R$)</Table.HeaderCell>
          <Table.HeaderCell width={1}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.isLoading && (
          <Table.Row>
            <Table.Cell colSpan='6'>
              <Loader active={props.isLoading} inline='centered' />
            </Table.Cell>
          </Table.Row>
        )}
        {props.multipleFaturaEnergia.valueSeq().map((faturaEnergia, index) => (
          <Table.Row key={index}>
            <Table.Cell textAlign='center'>
              <Link to={{
                pathname: `/faturas_energia/${faturaEnergia.get('id')}`
              }}>{faturaEnergia.get('id')}</Link>
            </Table.Cell>
            <Table.Cell textAlign='center'>
              <Label as={Link} to={`/contratos_energia/${faturaEnergia.getIn(['contrato_energia', 'id'])}`}>
                {faturaEnergia.getIn(['contrato_energia', 'id'])}
              </Label>
            </Table.Cell>
            <Table.Cell textAlign='center'>{maskCurrency.parse(faturaEnergia.get('consumo'))}</Table.Cell>
            <Table.Cell textAlign='center'>{maskCurrency.parse(faturaEnergia.get('perdas') * 100)}</Table.Cell>
            <Table.Cell textAlign='center'>{maskCurrency.parse(faturaEnergia.get('valor_total'))}</Table.Cell>
            <Table.Cell textAlign='right'>
              <EditDeleteButtonContainer
                onEditResource={() => props.onEditResource(faturaEnergia.get('id'))}
                onDeleteResource={() => props.onDeleteResource(faturaEnergia.get('id'))} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </CRUDIndex>
)

FaturaEnergia.propTypes = {
  multipleFaturaEnergia: PropTypes.object,
  onRedirectToNew: PropTypes.func,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default FaturaEnergia
