import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { Resultado } from '../../../containers'

const Resultados = (props) => {
  const { faturaPedagio } = props
  return (
    <div>
      <Switch>
        <Route
          exact path='/instalacoes/:instalacaoId/pedagio/:faturaPedagioId/resultado'
          render={({match}) => (
            <Resultado.Show
              faturaPedagio={faturaPedagio}
              match={match}
            />
          )}
        />
        <Route
          exact path='/instalacoes/:instalacaoId/pedagio/:faturaPedagioId/resultado/comparar/:cenarioId'
          render={({match}) => (
            <h1>olá</h1>
          )}
        />
      </Switch>
    </div>
  )
}

Resultados.propTypes = {
  faturaPedagio: PropTypes.object,
}

export default Resultados
