import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FieldArray, FormSection } from 'redux-form/immutable'
import { Form, Header, FormField, Table } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { DatePickerInput, SemanticInputMask } from '../FormComponents'
import { required, dateValidator } from '../../utils/validations'
import { maskFloat } from '../../utils/masks'
import RenderValoresTarifas from './RenderValoresTarifas'

function VigenciaTarifaForm(props) {
  return (
    <CRUDForm
      title='Tarifa'
      titleAs='h3'
      icon='money'
      padded={false}
      {...props}>
      <FormSection name='vigencia_tarifa'>
        <Form.Group>
          <FormField width={16}>
            <Header as='h3' block style={{ marginTop: '1em', display: 'flex' }}>
              <div style={{ width: '20%' }}>
                <label>Início da Vigência</label>
                <Field
                  autoFocus
                  name='starts_at'
                  placeholder='Início da Vigência'
                  validate={[required, dateValidator]}
                  component={DatePickerInput}
                  loading={props.loading}
                />
              </div>
              <div
                style={{ width: '15%', marginLeft: '2%', fontSize: '1.1rem' }}>
                <label>Conta COVID</label>
                <Field
                  fluid
                  name='valor_encargo_attributes.conta_covid'
                  placeholder='0,00'
                  mask={maskFloat.mask(2)}
                  component={SemanticInputMask}
                  loading={props.loading}
                  label='R$'
                />
              </div>
              <div
                style={{ width: '15%', marginLeft: '2%', fontSize: '1.1rem' }}>
                <label>Escassez Hídrica</label>
                <Field
                  fluid
                  name={'valor_encargo_attributes.escassez_hidrica'}
                  placeholder='0,00'
                  mask={maskFloat.mask(2)}
                  component={SemanticInputMask}
                  loading={props.loading}
                  label='R$'
                />
              </div>
            </Header>
          </FormField>
        </Form.Group>
        <Form.Group>
          <Form.Field width={16}>
            <Table celled className='tableTarifa'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign='center' width={2} rowSpan={2}>
                    Subgrupo
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign='center' width={1} rowSpan={2}>
                    Enquadramento
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign='center' width={1} rowSpan={2}>
                    Posto
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign='center'
                    width={6}
                    rowSpan={1}
                    colSpan={2}>
                    TUSD
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign='center' width={3} rowSpan={1}>
                    TE
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign='center' width={3} rowSpan={1}>
                    TR
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell textAlign='right'>R$/kW</Table.HeaderCell>
                  <Table.HeaderCell textAlign='right'>R$/MWh</Table.HeaderCell>
                  <Table.HeaderCell textAlign='right'>R$/MWh</Table.HeaderCell>
                  <Table.HeaderCell textAlign='right'>R$/MWh</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <FieldArray
                name='valores_tarifas_attributes'
                component={RenderValoresTarifas}
              />
            </Table>
          </Form.Field>
        </Form.Group>
      </FormSection>
    </CRUDForm>
  )
}

VigenciaTarifaForm.propTypes = {
  loading: PropTypes.bool,
}

export default reduxForm({ form: 'VigenciaTarifaForm' })(VigenciaTarifaForm)
