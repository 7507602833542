import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ParametroConcessionariaShow } from '../../components'
import * as parametroConcessionariaActionCreators from '../../redux/modules/parametroConcessionaria'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

class ParametroConcessionariaShowContainer extends Component {

  static propTypes = {
    concessionaria: PropTypes.object.isRequired,

    id: PropTypes.string,
    history: PropTypes.object,
    fetchParametroConcessionaria: PropTypes.func,
    destroyParametroConcessionaria: PropTypes.func,
    isLoading: PropTypes.bool,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
  }

  state = {
    parametroConcessionaria: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const parametroConcessionaria = await this.props.fetchParametroConcessionaria(this.props.id)
      this.setState({ parametroConcessionaria })
    }
  }

  handleEditResource = (concessionariaId, id) => {
    this.props.history.push(`/concessionarias/${concessionariaId}/parametros/${id}/edit`)
  }

  handleDeleteResource = async (concessionariaId, id) => {
    if (await this.props.destroyParametroConcessionaria(id)) {
      this.props.showSuccessfulFlashMessage('', 'destroy')
      this.props.history.push(`/concessionarias/${concessionariaId}/parametros`)
    } else {
      this.props.showErrorFlashMessage('', 'destroy', this.props.error)
    }
  }

  render () {
    return (
      <ParametroConcessionariaShow
        isLoading={this.props.isLoading}
        concessionaria={this.props.concessionaria}
        onEditResource={() => this.handleEditResource(this.props.concessionaria.id, this.state.parametroConcessionaria.id)}
        onDeleteResource={() => this.handleDeleteResource(this.props.concessionaria.id, this.state.parametroConcessionaria.id)}
        parametroConcessionaria={this.state.parametroConcessionaria} />
    )
  }
}

function mapStateToProps ({parametroConcessionaria, status}, {match}) {
  const { id } = match.params
  const isLoading = status.getIn(['parametroConcessionaria', 'isLoading'])
  if (id) {
    return {
      id,
      parametroConcessionaria: parametroConcessionaria.get('id'),
      isLoading,
    }
  }
  return {
    isLoading,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...parametroConcessionariaActionCreators,
    ...flashMessageActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ParametroConcessionariaShowContainer))
