import React from 'react'
import PropTypes from 'prop-types'
import { Label, Table, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { CRUDIndex } from '../CRUD'
import { EditDeleteButtonContainer } from '../../containers'
import { maskCurrency } from '../../utils/masks'

const ExtraPedagio = (props) => (
  <CRUDIndex noTitle padded={false}
    onRedirectToNew={props.onRedirectToNew}
    onExportToCSV={props.onExportToCSV}
    filterText={props.filterText}
    handleFilter={props.handleFilter}
  >
    <Table sortable celled striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign='center' width={1}
            onClick={props.handleSort('id')}
            sorted={props.handleSorted('id')}>#</Table.HeaderCell>
          <Table.HeaderCell
            onClick={props.handleSort('descricao')}
            sorted={props.handleSorted('descricao')}>Descrição</Table.HeaderCell>
          <Table.HeaderCell width={2}
            onClick={props.handleSort('valor')}
            sorted={props.handleSorted('valor')}>Valor</Table.HeaderCell>
          <Table.HeaderCell width={1}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.isLoading && (
          <Table.Row>
            <Table.Cell colSpan='7'>
              <Loader active={props.isLoading} inline='centered' />
            </Table.Cell>
          </Table.Row>
        )}
        {props.multipleExtraPedagio.valueSeq().map((extraPedagio, index) => (
          <Table.Row key={index}>
            <Table.Cell textAlign='center'>
              <Label as={Link} to={`/faturas/pedagio/${extraPedagio.getIn(['fatura_pedagio', 'id'])}/extras/${extraPedagio.get('id')}`}>{extraPedagio.get('id')}</Label>
            </Table.Cell>
            <Table.Cell>{extraPedagio.get('descricao')}</Table.Cell>
            <Table.Cell>{maskCurrency.parse(extraPedagio.get('valor'))}</Table.Cell>
            <Table.Cell>
              <EditDeleteButtonContainer
                onEditResource={() => props.onEditResource(props.faturaPedagio.id, extraPedagio.get('id'))}
                onDeleteResource={() => props.onDeleteResource(extraPedagio.get('id'))} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </CRUDIndex>
)

ExtraPedagio.propTypes = {
  faturaPedagio: PropTypes.object,
  multipleExtraPedagio: PropTypes.object,
  onRedirectToNew: PropTypes.func,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default ExtraPedagio
