import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditDeleteButton from '../../components/EditDeleteButton/EditDeleteButton'

class EditDeleteButtonContainer extends Component {
  static propTypes = {
    onCopyResource: PropTypes.func,
    onDeleteResource: PropTypes.func,
    onEditResource: PropTypes.func,
    onPrintResource: PropTypes.func,
    customButtonGroupItems: PropTypes.array,
  }

  state = { open: false }

  show = () => this.setState({ open: true })

  handleConfirm = () => {
    this.props.onDeleteResource()
    this.setState({ open: false })
  }

  handleCancel = () => this.setState({ open: false })

  render() {
    return (
      <EditDeleteButton
        open={this.state.open}
        show={this.show}
        handleConfirm={this.handleConfirm}
        handleCancel={this.handleCancel}
        onDeleteResource={this.props.onDeleteResource}
        onEditResource={this.props.onEditResource}
        onPrintResource={this.props.onPrintResource}
        onCopyResource={this.props.onCopyResource}
        customButtonGroupItems={this.props.customButtonGroupItems}
      />
    )
  }
}

export default EditDeleteButtonContainer
