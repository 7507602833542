import React from 'react'
import PropTypes from 'prop-types'

import { Table } from 'semantic-ui-react'
import { maskFloat } from '../../utils/masks'

const TotalTable = ({ title, color, total, simulado, meta }) => {

  const valorInformado = parseFloat(meta.getIn(['fatura', 'total']))
  const valorCalculado = parseFloat(total.getIn(['calculado', 'total']))
  const valorCru = parseFloat(total.getIn(['cru', 'total']))
  const valorSimuladoCalculado = simulado && simulado.getIn(['calculado', 'total'], 0.0)
  const valorSimuladoCru = simulado && simulado.getIn(['cru', 'total'], 0.0)
  const diferenca = parseFloat((valorInformado - valorCalculado).toFixed(2))

  const colSpan = simulado ? 3 : 6

  return (
    <React.Fragment>
      {simulado && (
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={4} style={{backgroundColor: color}} textAlign='right'></Table.HeaderCell>
              <Table.HeaderCell width={simulado ? 6 : 12} style={{backgroundColor: color}} textAlign='center'>Cenário Realizado</Table.HeaderCell>
              <Table.HeaderCell width={simulado ? 6 : 12} style={{backgroundColor: '#bdf2bd'}} textAlign='center'>Cenário Simulado</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        </Table>
      )}
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4} style={{backgroundColor: color}} textAlign='right'>{title}</Table.HeaderCell>
            <Table.HeaderCell width={colSpan} style={{backgroundColor: color}} textAlign='right'>Sem Impostos</Table.HeaderCell>
            <Table.HeaderCell width={colSpan} style={{backgroundColor: color}} textAlign='right'>Com Impostos</Table.HeaderCell>
            {simulado && (
              <>
                <Table.HeaderCell width={colSpan} style={{backgroundColor: '#bdf2bd'}} textAlign='right'>Sem Impostos</Table.HeaderCell>
                <Table.HeaderCell width={colSpan} style={{backgroundColor: '#bdf2bd'}} textAlign='right'>Com Impostos</Table.HeaderCell>
              </>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell textAlign='right'>Valor Informado</Table.Cell>
            <Table.Cell textAlign='right' style={{fontFamily: 'monospace'}}><em>N/A</em></Table.Cell>
            <Table.Cell textAlign='right' style={{fontFamily: 'monospace'}}>{maskFloat.parse(valorInformado)}</Table.Cell>
            {simulado && (
              <>
                <Table.Cell textAlign='right' style={{fontFamily: 'monospace'}}><em>N/A</em></Table.Cell>
                <Table.Cell textAlign='right' style={{fontFamily: 'monospace'}}><em>N/A</em></Table.Cell>
              </>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign='right'>Valor Calculado</Table.Cell>
            <Table.Cell textAlign='right' style={{fontFamily: 'monospace'}}>{maskFloat.parse(valorCru)}</Table.Cell>
            <Table.Cell textAlign='right' style={{fontFamily: 'monospace'}}>
              {maskFloat.parse(valorCalculado)}{diferenca ? (<span style={{color: 'red'}}> (<em>{maskFloat.parse(diferenca)}</em>)</span>) : (null)}
            </Table.Cell>
            {simulado && (
              <>
                <Table.Cell textAlign='right' style={{fontFamily: 'monospace'}}>{valorSimuladoCru ? maskFloat.parse(valorSimuladoCru) : <em>N/A</em>}</Table.Cell>
                <Table.Cell textAlign='right' style={{fontFamily: 'monospace'}}>{valorSimuladoCalculado ? maskFloat.parse(valorSimuladoCalculado) : <em>N/A</em>}</Table.Cell>
              </>
            )}
          </Table.Row>
        </Table.Body>
      </Table>
    </React.Fragment>
  )
}

TotalTable.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  total: PropTypes.object,
  meta: PropTypes.object,
  simulado: PropTypes.object,
}

export default TotalTable
