import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_CONTATO_CONCESSIONARIA = 'LOADING_CONTATO_CONCESSIONARIA'
export function loadingContatoConcessionaria () {
  return {
    type: LOADING_CONTATO_CONCESSIONARIA,
  }
}

export const LOADING_CONTATO_CONCESSIONARIA_SUCCESS = 'LOADING_CONTATO_CONCESSIONARIA_SUCCESS'
export function loadingContatoConcessionariaSuccess (contatoConcessionaria) {
  return {
    type: LOADING_CONTATO_CONCESSIONARIA_SUCCESS,
    contatoConcessionaria,
  }
}

export const LOADING_CONTATO_CONCESSIONARIA_FAILURE = 'LOADING_CONTATO_CONCESSIONARIA_FAILURE'
export function loadingContatoConcessionariaFailure (error) {
  return {
    type: LOADING_CONTATO_CONCESSIONARIA_FAILURE,
    error,
  }
}

export const DESTROYING_CONTATO_CONCESSIONARIA_SUCCESS = 'DESTROYING_CONTATO_CONCESSIONARIA_SUCCESS'
export function destroyingContatoConcessionariaSuccess (contatoConcessionariaId) {
  return {
    type: DESTROYING_CONTATO_CONCESSIONARIA_SUCCESS,
    contatoConcessionariaId,
  }
}

const initialState = Map({})

export function fetchMultipleContatoConcessionaria (concessionariaId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoConcessionaria())
      const multipleContatoConcessionaria = await callAPI(`/concessionarias/${concessionariaId}/contatos`)
      dispatch(loadingContatoConcessionariaSuccess(multipleContatoConcessionaria))
      return multipleContatoConcessionaria
    } catch (e) {
      dispatch(loadingContatoConcessionariaFailure(e))
      return false
    }
  }
}

export function fetchContatoConcessionaria (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoConcessionaria())
      const contatoConcessionaria = await callAPI(`/contatos/${id}`)
      dispatch(loadingContatoConcessionariaSuccess(contatoConcessionaria))
      return contatoConcessionaria
    } catch (e) {
      dispatch(loadingContatoConcessionariaFailure(e))
      return false
    }
  }
}

export function createContatoConcessionaria (concessionariaId, contatoConcessionaria) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoConcessionaria())
      const newContatoConcessionaria = await callAPI(`/concessionarias/${concessionariaId}/contatos`, 'POST', contatoConcessionaria)
      dispatch(loadingContatoConcessionariaSuccess(newContatoConcessionaria))
      return newContatoConcessionaria
    } catch (e) {
      dispatch(loadingContatoConcessionariaFailure(e))
      return false
    }
  }
}

export function updateContatoConcessionaria (id, contatoConcessionaria) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoConcessionaria())
      const newContatoConcessionaria = await callAPI(`/contatos/${id}`, 'PUT', contatoConcessionaria)
      dispatch(loadingContatoConcessionariaSuccess(newContatoConcessionaria))
      return newContatoConcessionaria
    } catch (e) {
      dispatch(loadingContatoConcessionariaFailure(e))
      return false
    }
  }
}

export function destroyContatoConcessionaria (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoConcessionaria())
      await callAPI(`/contatos/${id}`, 'DELETE')
      dispatch(destroyingContatoConcessionariaSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingContatoConcessionariaFailure(e))
      return false
    }
  }
}

export default function contatoConcessionaria (state = initialState, action) {
  switch (action.type) {

    case LOADING_CONTATO_CONCESSIONARIA_SUCCESS : {
      const { contatoConcessionaria } = action
      if (contatoConcessionaria.constructor === Array) {
        state = initialState
        contatoConcessionaria.forEach((c) => (
          state = state.merge({[c.id]: c})
        ))
        return state
      }
      return state.merge({[contatoConcessionaria.id]: contatoConcessionaria})
    }

    case DESTROYING_CONTATO_CONCESSIONARIA_SUCCESS :
      return state.delete(action.contatoConcessionariaId.toString())

    default :
      return state
  }
}
