import { Map } from 'immutable'

const [ P_FP, NA, NENHUM ] = [ 0, 1, 2 ]

export const multipleTipoDemanda = Map({
  [P_FP]: Map({
    id: P_FP,
    nome: 'Ponta e Fora-Ponta'
  }),
  [NA]: Map({
    id: NA,
    nome: 'Não diferencia horários'
  }),
  [NENHUM]: Map({
    id: NENHUM,
    nome: 'Não utilizada'
  }),
})

const getGrupo = (subgrupo) => {
  const [ ALTA_TENSAO ]  = [ 0, 1 ]
  const [ GRUPO_AT, GRUPO_BT ]  = [ 'a', 'b' ]
  return (parseInt(subgrupo.get('grupo'), 10) === ALTA_TENSAO) ? GRUPO_AT : GRUPO_BT
}

export const getPostos = (enquadramento, subgrupo) => {
  const grupo = getGrupo(subgrupo)

  const tipoEnquadramento = getTipoEnquadramento(enquadramento, grupo)
  const tipoDemanda = testarPostos(tipoEnquadramento)

  const postos = []

  if (tipoDemanda.P) postos.push({ sigla: 'p', descricao: 'P', extenso: 'Ponta' })
  if (tipoDemanda.FP) postos.push({ sigla: 'fp', descricao: 'FP', extenso: 'Fora-Ponta' })
  if (tipoDemanda.NA) postos.push({ sigla: 'na', descricao: 'NA', extenso: '' })

  return postos
}

const getTipoEnquadramento = (enquadramento, grupo) => enquadramento.get(`tipo_demanda_${grupo}`)

const testarPostos = (tipoEnquadramento) => {
  let ponta = false
  let foraPonta = false
  let naoAplica = false

  switch(tipoEnquadramento) {
    case P_FP:
      ponta = true
      foraPonta = true
      break
    case NA:
      naoAplica = true
      break
    default:
  }

  return {
    P: ponta,
    FP: foraPonta,
    NA: naoAplica,
  }
}
