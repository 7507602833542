export const sortMap = (map, sortFunction = '', sortDirection = 'ascending') => {
  let sortedMap
  switch(typeof sortFunction) {
    case 'function':
      sortedMap = map.sort(sortFunction)
      break

    default:
      sortedMap = map.sortBy(e => e.getIn(sortFunction.split('.')))
  }

  if (sortDirection === 'descending') return sortedMap.reverse()
  return sortedMap
}

export const filterMap = (map, columns, filterText) => {
  if (!filterText) return map
  return map.filter(element =>
    columns.some(field => {
      let e = element.getIn(field.split('.'))
      if (!e) return false
      return e.toString().match(new RegExp(filterText, 'i'))
    })
  )
}
