import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput, SemanticInputMask } from '../FormComponents'

import { required, maxLengthDescricao } from '../../utils/validations'
import { maskFloat } from '../../utils/masks'

const ExtraPedagioForm = (props) => (
  <CRUDForm
    title='Dados Extras'
    icon='users'
    padded={false}
    {...props}>
    <FormSection name='extra_pedagio'>
      <Form.Group widths='equal'>
        <FormField>
          <label>Descrição</label>
          <Field
            name='descricao'
            placeholder='Descrição'
            validate={[required, maxLengthDescricao]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <label>Valor</label>
          <Field
            name='valor'
            label='R$'
            validate={[required]}
            component={SemanticInputMask}
            loading={props.loading}
            mask={maskFloat.mask(2, true)}
            placeholder={maskFloat.placeholder}
          />
        </FormField>
      </Form.Group>
    </FormSection>
  </CRUDForm>
)

ExtraPedagioForm.propTypes = {
  loading: PropTypes.bool
}

export default reduxForm({form: 'ExtraPedagioForm'})(ExtraPedagioForm)
