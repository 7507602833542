import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Container, Divider } from 'semantic-ui-react'

import { ResponsiveRadar } from '@nivo/radar'

import {
  COLORS,
  capitalize,
  formatAsCurrency,
  formatAsPercentage,
} from './utils/ChartUtils'

const fieldMapping = [
  {
    id: 'Consumo P',
    field: 'consumo_p',
  },
  {
    id: 'Consumo FP',
    field: 'consumo_fp',
  },
  {
    id: 'Consumo INT',
    field: 'consumo_int',
  },
  {
    id: 'Consumo',
    field: 'consumo_na',
  },
  {
    id: 'Demanda P',
    field: 'demanda_p',
  },
  {
    id: 'Demanda FP',
    field: 'demanda_fp',
  },
  {
    id: 'Demanda',
    field: 'demanda_na',
  },
  {
    id: 'UFER',
    field: 'ufer',
  },
  {
    id: 'Outros',
    field: 'outros',
  },
  {
    id: 'Gerador',
    field: 'gerador',
  },
]

const FinanceiroChart = ({ header, data, height, showAnimations }) => {
  if (!data) return null

  const chartKeys = []

  const chartData = fieldMapping.map(
    field => data.reduce(
      (acc, fatura) => {
        const key = capitalize(moment({y: fatura.ano, M: fatura.mes - 1}).format('MMM/YY'))
        const value = fatura[field.field][0]

        if (!chartKeys.includes(key)) chartKeys.push(key)

        acc[key] = value
        return acc
      }, {
        item: field.id
      }
    )
  ).filter(item => chartKeys.some(key => item[key]))

  return (
    <Container fluid>
      <Divider horizontal>{header}</Divider>
      <div style={{height: `${height}px`}}>
        <ResponsiveRadar
          data={chartData}
          colors={[
            COLORS.ELECTRIC.BLUE,
            COLORS.ELECTRIC.LIGHTER.BASE,
          ]}
          keys={chartKeys}
          indexBy='item'
          maxValue='auto'
          margin={{
            'top': 40,
            'right': 60,
            'bottom': 40,
            'left': 60
          }}
          curve='catmullRomClosed'
          borderWidth={2}
          borderColor='inherit'
          gridLevels={4}
          gridShape='circular'
          gridLabelOffset={30}
          enableDots={true}
          enableDotLabel={false}
          colorBy='key'
          fillOpacity={0.1}
          animate={showAnimations}
          motionStiffness={90}
          motionDamping={15}
          isInteractive={true}
          tooltipFormat={value => formatAsCurrency(value)}
          gridLabel={({ id, anchor }) => {
            let percentage = 0.0

            const found = chartData.find(data => data.item === id)

            if (found) {
              const base = found[chartKeys[1]]
              const diff = found[chartKeys[0]] - base
              percentage = base === 0.0 ? 0.0 : diff / base
            }

            const sign = percentage > 0.0 ? '+' : ''
            const fill = percentage === 0.0
              ? COLORS.ELECTRIC.LIGHT.BASE
              : percentage > 0
                ? COLORS.ELECTRIC.RED
                : COLORS.ELECTRIC.GREEN

            return (
              <text textAnchor={anchor} style={{
                fill: COLORS.ELECTRIC.LIGHT.BASE,
                fontSize: '11px',
              }}>
                <tspan>{id}</tspan>
                {percentage !== 0.0 && (
                  <tspan style={{
                    fill,
                    fontWeight: 700,
                  }}> {sign}{formatAsPercentage(percentage)}</tspan>
                )}
              </text>
            )
          }}
          legends={[
            {
              'anchor': 'bottom-right',
              'direction': 'column',
              'translateX': 70,
              'translateY': 40,
              'itemHeight': 20,
              'itemWidth': 100,
              'itemOpacity': 0.85,
              'itemsSpacing': 2,
              'symbolSize': 20,
              'itemTextColor': COLORS.ELECTRIC.LIGHT.BASE,
              'effects': [
                {
                  'on': 'hover',
                  'style': {
                    'itemOpacity': 1
                  }
                }
              ]
            }
          ]}
        />
      </div>
    </Container>
  )
}

FinanceiroChart.propTypes = {
  header: PropTypes.string,
  data: PropTypes.array,
  height: PropTypes.number,
  showAnimations: PropTypes.bool,
}

FinanceiroChart.defaultProps = {
  header: PropTypes.string,
  height: 300,
  showAnimations: true,
}

export default FinanceiroChart
