import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput, ColorPickerInput } from '../FormComponents'

import { required, maxLengthNome, maxLengthDescricao, hexColor } from '../../utils/validations'
import { maskColor } from '../../utils/masks'

function BandeiraForm (props) {
  return (
    <CRUDForm
      title='Bandeira'
      icon='flag'
      padded={false}
      {...props}>
      <FormSection name='bandeira'>
        <Form.Group>
          <FormField width={6}>
            <label>Bandeira</label>
            <Field
              autoFocus
              name='nome'
              placeholder='Bandeira'
              validate={[required, maxLengthNome]}
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
          <FormField width={10}>
            <label>Descrição</label>
            <Field
              name='descricao'
              placeholder='Descrição'
              validate={[required, maxLengthDescricao]}
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={6}>
            <label htmlFor='cor'>Cor</label>
            <Field
              name='cor'
              placeholder={maskColor.placeholder}
              validate={[required, hexColor]}
              component={ColorPickerInput}
              mask={maskColor.mask}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
      </FormSection>
    </CRUDForm>
  )
}

BandeiraForm.propTypes = {
  loading: PropTypes.bool
}

export default reduxForm({form: 'BandeiraForm'})(BandeiraForm)
