import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Icon } from 'semantic-ui-react'

import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { TextAreaField } from 'react-semantic-redux-form'
import { Form, FormField } from 'semantic-ui-react'

import MarkdownRenderer from '../MarkdownRenderer'

import ViewModeContext from '../../../containers/ViewModeContext/ViewModeContext'
import ViewMode from '../../../utils/viewMode'

const renderMethod = (mode) => ({
  [ViewMode.SHOW]: renderShow,
  [ViewMode.PRINT]: renderShow,
  [ViewMode.EDIT]: renderEdit,
})[mode]

const renderShow = ({ analise }) => {
  return (<MarkdownRenderer source={analise} />)
}

const renderEdit = (props) => {
  const { analise, field, isLoading, isEditMode, onToggleMode, handleSubmit, help, onReset } = props

  if (!isEditMode) return (
    <React.Fragment>
      {renderShow({ analise })}
      <Button fluid
        color='yellow'
        onClick={onToggleMode}>
        <Icon name='edit' />Alterar {help || 'Texto'}
      </Button>
    </React.Fragment>
  )

  return (
    <Form onSubmit={handleSubmit} loading={isLoading}>
      <FormSection name='relatorio'>
        <Form.Group>
          <FormField width={16}>
            <Field
              name={field}
              component={TextAreaField}
              placeholder='...'
              autoHeight
            />
          </FormField>
        </Form.Group>
      </FormSection>
      <div style={{marginTop: '1em'}}>
        <Button
          color='default'
          onClick={onToggleMode}>
          <Icon name='cancel' /> Cancelar
        </Button>
        {onReset && typeof onReset === 'function' && (
          <Button color='default' type='button' disabled={isLoading} onClick={onReset}>
            <Icon name='undo' /> Redefinir
          </Button>
        )}
        <Button color='green' type='submit' disabled={isLoading}>
          <Icon name='checkmark' /> Salvar {help && ` - ${help}`}
        </Button>
      </div>
    </Form>
  )
}

const Analise = (props) => {
  const { viewMode } = useContext(ViewModeContext)

  return (
    <Container fluid>
      {renderMethod(viewMode)(props)}
    </Container>
  )
}

Analise.propTypes = {
  analise: PropTypes.string,
  help: PropTypes.string,
  isLoading: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
}

Analise.defaultProps = {
  analise: '',
}

export default reduxForm()(Analise)
