import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { Input, Label } from 'semantic-ui-react'
import { Button, Dropdown, Checkbox, Icon } from 'semantic-ui-react'
import MaskedInput, { conformToMask } from 'react-text-mask'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { ChromePicker } from 'react-color'

class ColorPickerInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
      color: props.input.value || '#000000',
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleColorChange = this.handleColorChange.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { input } = nextProps
    const { color } = this.state

    if (input.value && input.value !== color) {
      this.setState({
        color: input.value
      }, () => {
        input.onChange(input.value)
      })
    }
  }

  handleClick() {
    this.setState({
      show: !this.state.show
    })
  }

  handleClose() {
    this.setState({
      show: false
    })
  }

  handleColorChange(color) {
    const { input } = this.props
    this.setState({
      color: color.hex
    }, () => {
      input.onChange(color.hex)
    })
  }

  handleKeyUp(event) {
    if (event.which === 27 || event.which === 13) {
      event.preventDefault()
      event.stopPropagation()
      this.handleClose()
    }
  }

  render() {
    const { label, meta, placeholder, type, input } = this.props
    const { color, show } = this.state

    return (
      <div>
        <Input
          value={color}
          labelPosition={label ? 'right' : undefined}
          error={(meta.touched && meta.error) ? true : false}
          placeholder={placeholder}
          type={type ? type : 'text'}
          onClick={() => this.handleClick()}
          onKeyUp={(e) => this.handleKeyUp(e)}
          onChange={(v) => {
            input.onChange(v)
          }} />
        {show && (
          <ChromePicker
            color={color}
            disableAlpha
            onChangeComplete={this.handleColorChange} />
        )}
        {meta.touched && meta.error && (
          <Label pointing color='red'>
            {meta.error}
          </Label>
        )}
      </div>
    )
  }
}

ColorPickerInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  mask: PropTypes.array,
}

class DatePickerInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      startDate: moment()
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.input.value) {
      this.handleChange(moment(nextProps.input.value, 'DD/MM/YYYY'))
    }
  }

  handleChange(date) {
    const { input } = this.props

    this.setState({ startDate: date }, () => {
      input.onChange(date)
    })
  }

  render() {
    const { placeholder, selectsStart, selectsEnd } = this.props
    const { startDate } = this.state
    return (
      <DatePicker
        selected={startDate}
        onChange={this.handleChange}
        placeholderText={placeholder}
        locale="pt-br"
        selectsStart={selectsStart}
        selectsEnd={selectsEnd} />
    )
  }
}

DatePickerInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  selectsStart: PropTypes.bool,
  selectsEnd: PropTypes.bool,
}

class SemanticInputMask extends Component {
  constructor(props) {
    super(props)

    this.state = { input: props.input }
  }

  componentWillReceiveProps(nextProps) {
    const { mask } = this.props
    const { input } = this.state

    // puts the value to the mask format
    if (input.value !== nextProps.input.value) {
      let maskParsed = []
      if (typeof mask === 'function') {
        maskParsed = mask(nextProps.input.value)
      } else if (typeof mask === 'object') {
        maskParsed = mask
      }

      let inputConformed = null
      if (nextProps.input.value !== undefined && nextProps.input.value) {
        inputConformed = conformToMask('' + nextProps.input.value, maskParsed.filter(m => m !== '[]')).conformedValue
      }

      this.setState({
        input: {
          value: inputConformed,
        },
      }, () => {
        if (input.onChange)
          input.onChange(inputConformed)
      })
    }
  }

  render() {
    // Extract input and label from props to be used by the MaskedInput, if needed.
    const { input, label, ...otherProps } = this.props
    const { inputState = input } = this.state

    let maskedInputNode = null
    let additionalClassNames = null
    // If a mask was provided, use the input in the MaskedInput component
    if(otherProps.mask !== undefined) {
      let fakeLabel = null

      // if a label was provided, add a fake label to the input,
      //   as the Label component gets in conflict with the mask
      // Input must also get some classes to mimic the label correctly
      if (label !== undefined) {
        fakeLabel = (<div key="fakeLabel" className="ui label label">{label}</div>)
        additionalClassNames = 'right labeled'
      }

      // mount the maks component and the fake label together
      maskedInputNode = [
        (<MaskedInput
          tabIndex={otherProps.tabIndex}
          key="maskedInput"
          mask={otherProps.mask}
          placeholder={otherProps.placeholder}
          value={inputState.value}
          {...input} />),
        fakeLabel,
      ]
    } else {
      // Else put back the input and label to the props object
      otherProps.input = input
      otherProps.label = label
    }

    return (
      <div>
        <Input
          {...otherProps}
          labelPosition={otherProps.label ? 'right' : undefined}
          error={(otherProps.meta.touched && otherProps.meta.error) ? true : false}
          placeholder={otherProps.placeholder}
          type={otherProps.type ? otherProps.type : 'text'}
          className={additionalClassNames}
          onChange={(v) => input.onChange(v)}
        >
          {maskedInputNode}
        </Input>
        {otherProps.meta.touched && otherProps.meta.error && (
          <Label pointing color='red'>
            {otherProps.meta.error}
          </Label>
        )}
      </div>
    )
  }
}

SemanticInputMask.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  mask: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
  ]),
}

const SemanticInput = (props) => (
  <React.Fragment>
    <Input
      {...props}
      labelPosition={props.label ? 'right' : undefined}
      error={(props.meta.touched && props.meta.error) ? true : false}
      placeholder={props.placeholder}
      type={props.type ? props.type : 'text'}
      onChange={(v) => props.input.onChange(v)} />
    {props.meta.touched && props.meta.error && (
      <Label pointing color='red'>
        {props.meta.error}
      </Label>
    )}
  </React.Fragment>
)

SemanticInput.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
}

const SemanticCheckbox = (props) => (
  <div>
    <Checkbox
      checked={props.input.value ? true : false}
      onChange={(e, { checked }) => props.input.onChange(checked)}
      label={props.label ? props.label : ''}
    />
    {props.meta.touched && props.meta.error && (
      <Label pointing color='red'>
        {props.meta.error}
      </Label>
    )}
  </div>
)

SemanticCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
}

const SemanticUploadButton = (props) => {
  const { label = 'Upload' } = props
  let fileInput = null
  const uid = props.id || Math.random().toString(36).substring(7)

  return (
    <Button onClick={() => fileInput.click()} animated='vertical'>
      <Button.Content hidden>{label}</Button.Content>
      <Button.Content visible>
        <Icon name='upload' />
        <input type='file' id={uid}
          style={{display: 'none'}}
          onChange={() => { props.onUpload(fileInput.files[0]) }}
          ref={input => { fileInput = input }}
        />
      </Button.Content>
    </Button>
  )
}

SemanticUploadButton.propTypes = {
  label: PropTypes.string,
  onUpload: PropTypes.func,
  id: PropTypes.number,
}

const SemanticDropdown = (props) => {
  const defaultValue = props.multiple ? [] : ''
  return (
    <div>
      <Dropdown
        {...props}
        error={(props.meta.touched && props.meta.error) ? true : false}
        value={props.input.value.constructor === String ? defaultValue : getDropdownValue(props.input.value)}
        noResultsMessage='Nenhum resultado encontrado'
        onChange={(e, {value}) => props.input.onChange(value)}
      />
      {props.meta.touched && props.meta.error && (
        <Label pointing color='red'>
          {props.meta.error}
        </Label>
      )}
    </div>
  )
}

SemanticDropdown.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
  meta: PropTypes.object.isRequired,
}

const SemanticDropdownText = (props) => {
  return (
    <div>
      <Dropdown
        {...props}
        error={(props.meta.touched && props.meta.error) ? true : false}
        value={getDropdownValue(props.input.value)}
        noResultsMessage='Nenhum resultado encontrado'
        onChange={(e, {value}) => props.input.onChange(value)}
      />
      {props.meta.touched && props.meta.error && (
        <Label pointing color='red'>
          {props.meta.error}
        </Label>
      )}
    </div>
  )
}

SemanticDropdownText.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
  meta: PropTypes.object.isRequired,
}

function getDropdownValue (value) {
  return List.isList(value)
    ? value.valueSeq().toArray()
    : value
}

export {
  ColorPickerInput,
  DatePickerInput,
  SemanticInputMask,
  SemanticInput,
  SemanticCheckbox,
  SemanticDropdown,
  SemanticDropdownText,
  SemanticUploadButton,
}
