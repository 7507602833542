import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Bandeira } from '../../../containers'

const BandeirasRouter = () => (
  <Switch>
    <Route exact path='/gestao_bandeiras/bandeiras' component={Bandeira.Index} />
    <Route exact path='/gestao_bandeiras/bandeiras/new' component={Bandeira.Form} />
    <Route exact path='/gestao_bandeiras/bandeiras/:id/edit' component={Bandeira.Form} />
    <Route exact path='/gestao_bandeiras/bandeiras/:id' component={Bandeira.Show} />
  </Switch>
)

export default BandeirasRouter
