import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Label, Loader, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { CRUDIndex } from '../CRUD'

const Resultado = (props) => (
  <CRUDIndex noTitle padded={false}
    onExportToCSV={props.onExportToCSV}
    filterText={props.filterText}
    handleFilter={props.handleFilter}
    customToolbarItems={[
      () => (
        <Button color='green' icon
          onClick={props.onCalcular}
          labelPosition='left'>
          <Icon name='calculator' />{' '}Calcular
        </Button>
      ),
      () => (
        <Button color='yellow' icon
          onClick={props.onSimular}
          labelPosition='left'>
          <Icon name='lab' />{' '}Simular
        </Button>
      )
    ]}
  >
    <Table sortable celled striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign='center' width={1}
            onClick={props.handleSort('id')}
            sorted={props.handleSorted('id')}>#</Table.HeaderCell>
          <Table.HeaderCell width={3}
            onClick={props.handleSort('simulacao')}
            sorted={props.handleSorted('simulacao')}>Tipo</Table.HeaderCell>
          <Table.HeaderCell width={2}
            onClick={props.handleSort('parametro_calculo.enquadramento_id')}
            sorted={props.handleSorted('parametro_calculo.enquadramento_id')}>Enquadramento</Table.HeaderCell>
          <Table.HeaderCell width={2}
            onClick={props.handleSort('parametro_calculo.subgrupo_id')}
            sorted={props.handleSorted('parametro_calculo.subgrupo_id')}>Subgrupo</Table.HeaderCell>
          <Table.HeaderCell width={3}
            onClick={props.handleSort('parametro_calculo.classe_consumo_id')}
            sorted={props.handleSorted('parametro_calculo.classe_consumo_id')}>Classe de Consumo</Table.HeaderCell>
          <Table.HeaderCell width={2}
            onClick={props.handleSort('parametro_calculo.concessionaria_id')}
            sorted={props.handleSorted('parametro_calculo.concessionaria_id')}>Concessionária</Table.HeaderCell>
          <Table.HeaderCell width={3}
            onClick={props.handleSort('parametro_calculo.tipo_mercado')}
            sorted={props.handleSorted('parametro_calculo.tipo_mercado')}>Tipo de Mercado</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.isLoading && (
          <Table.Row>
            <Table.Cell colSpan='7'>
              <Loader active={props.isLoading} inline='centered' />
            </Table.Cell>
          </Table.Row>
        )}
        {props.multipleResultado.valueSeq().map((resultado, index) => {
          const simulacao = resultado.get('simulacao')
          const parametros = resultado.get('parametro_calculo')
          const concessionaria = resultado.getIn(['parametro_calculo', 'concessionaria'])
          const enquadramento = resultado.getIn(['parametro_calculo', 'enquadramento'])
          const subgrupo = resultado.getIn(['parametro_calculo', 'subgrupo'])
          const classeConsumo = resultado.getIn(['parametro_calculo', 'classe_consumo'])
          const tipo_mercado = props.multipleTipoMercado.get(`${parametros.get('tipo_mercado')}`)

          return (
            <Table.Row key={index}>
              <Table.Cell textAlign='center'>
                <Label as={Link} to={`/instalacoes/${props.faturaPedagio.get('instalacao_id')}/pedagio/${resultado.get('fatura_id')}/resultado`}>
                  {resultado.get('id')}
                </Label>
              </Table.Cell>
              <Table.Cell
                positive={!simulacao}
                warning={simulacao}>
                <Icon name={simulacao ? 'lab' : 'calculator'} />
                { simulacao ? 'Simulação' : 'Cálculo' }
              </Table.Cell>
              <Table.Cell>
                {enquadramento && (
                  <div className='labelWrapper'>
                    <Label as={Link} to={`/enquadramentos/${enquadramento.get('id')}`}>
                      {enquadramento.get('modalidade')}
                    </Label>
                  </div>
                )}
              </Table.Cell>
              <Table.Cell>
                {subgrupo && (
                  <div className='labelWrapper'>
                    <Label as={Link} to={`/subgrupos/${subgrupo.get('id')}`}>
                      {subgrupo.get('nome')}
                    </Label>
                  </div>
                )}
              </Table.Cell>
              <Table.Cell>
                {classeConsumo && (
                  <div className='labelWrapper'>
                    <Label as={Link} to={`/classes_consumo/${classeConsumo.get('id')}`}>
                      {classeConsumo.get('nome')}
                    </Label>
                  </div>
                )}
              </Table.Cell>
              <Table.Cell>
                {concessionaria && (
                  <div className='labelWrapper'>
                    <Label as={Link} to={`/concessionarias/${concessionaria.get('id')}`}>
                      {concessionaria.get('sigla')}
                    </Label>
                  </div>
                )}
              </Table.Cell>
              <Table.Cell>
                {tipo_mercado && (
                  tipo_mercado.get('nome')
                )}
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  </CRUDIndex>
)

Resultado.propTypes = {
  faturaPedagio: PropTypes.object,
  onCalcular: PropTypes.func,
  onSimular: PropTypes.func,
  multipleResultado: PropTypes.object,
  multipleTipoMercado: PropTypes.object,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default Resultado
