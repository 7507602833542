import React from 'react'
import PropTypes from 'prop-types'
import { Card, Grid, Label, Loader, Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { CRUDIndex } from '../CRUD'
import FaturaStateLabel from './FaturaStateLabel'
import { getColor } from './StateUtils'

import { maskFloat } from '../../utils/masks'

import ExportToCSVContainer from '../../containers/ExportToCSVContainer/ExportToCSVContainer'

const years = function() {
  const currentYear = new Date().getFullYear(), years = []
  let year = 2015

  while (year <= currentYear) {
    years.push({ key: year, value: year, text: year })
    year++
  }

  return years.reverse()
}()

const FaturaPedagio = (props) => {
  return (
    <CRUDIndex
      noTitle padded={false}
      title={props.title}
      icon='dollar'
      onRedirectToNew={props.onRedirectToNew}
      onExportToCSV={props.onExportToCSV}
      filterText={props.filterText}
      handleFilter={props.handleFilter}
      customToolbarItems={[ ExportToCSVContainer ]}
      customSearchItems={[
        (
          <Dropdown compact selection
            key='year'
            label='Ano'
            placeholder='Ano'
            options={years}
            value={props.filterYear}
            onChange={(event, { value }) => props.handleFilter('year', value)}
          />
        )
      ]}
    >
      <Loader inline='centered' active={props.isLoading} />

      <Card.Group style={{ marginTop: '0.5em' }} itemsPerRow={4}>
        {props.multipleFaturaPedagio.valueSeq().map((faturaPedagio, index) => {
          const valorInformado = parseFloat(faturaPedagio.getIn(['resultado', 'data', 'meta', 'fatura', 'total'], '0.0'))
          const valorCalculado = parseFloat(faturaPedagio.getIn(['resultado', 'data', 'total', 'calculado', 'total'], '0.0'))
          const diferenca = parseFloat((valorInformado - valorCalculado).toFixed(2))
          const extraLabel = diferenca ? `${diferenca > 0.0 ? '+' : ''}${maskFloat.parse(diferenca)}` : ''

          return (
            <Card
              key={index}
              as={Link}
              to={`/instalacoes/${props.instalacao.id}/pedagio/${faturaPedagio.get('id')}`}
              color={getColor(faturaPedagio.get('aasm_state'))}
            >
              <Card.Content>
                <Card.Header>
                  {faturaPedagio.get('mes')}/{faturaPedagio.get('ano')}
                </Card.Header>
                <Card.Meta>{faturaPedagio.get('data_inicial')} - {faturaPedagio.get('data_final')}</Card.Meta>
                {faturaPedagio.get('observacoes') && (
                  <Card.Description>{faturaPedagio.get('observacoes')}</Card.Description>
                )}
              </Card.Content>
              <Card.Content extra>
                <Grid>
                  <Grid.Column floated='left' width={8}>
                    {faturaPedagio.get('aasm_state') !== 'historica' && (
                      <Label size='medium'>
                        R$ {maskFloat.parse(valorInformado)}
                        {extraLabel && <Label.Detail>{extraLabel}</Label.Detail>}
                      </Label>
                    )}
                  </Grid.Column>
                  <Grid.Column floated='right' width={8} textAlign='right'>
                    <FaturaStateLabel state={faturaPedagio.get('aasm_state')} detail={extraLabel} />
                  </Grid.Column>
                </Grid>
              </Card.Content>
            </Card>
          )})}
      </Card.Group>
    </CRUDIndex>
  )
}

FaturaPedagio.propTypes = {
  title: PropTypes.string.isRequired,
  noTitle: PropTypes.bool,
  isLoading: PropTypes.bool,

  instalacao: PropTypes.object,

  onRedirectToNew: PropTypes.func,
  multipleFaturaPedagio: PropTypes.object.isRequired,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  filterYear: PropTypes.number,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
}

export default FaturaPedagio
