import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_BANDEIRA = 'LOADING_BANDEIRA'
export function loadingBandeira () {
  return {
    type: LOADING_BANDEIRA,
  }
}

export const LOADING_BANDEIRA_SUCCESS = 'LOADING_BANDEIRA_SUCCESS'
export function loadingBandeiraSuccess (bandeira) {
  return {
    type: LOADING_BANDEIRA_SUCCESS,
    bandeira,
  }
}

export const LOADING_BANDEIRA_FAILURE = 'LOADING_BANDEIRA_FAILURE'
export function loadingBandeiraFailure (error) {
  return {
    type: LOADING_BANDEIRA_FAILURE,
    error,
  }
}

export const DESTROYING_BANDEIRA_SUCCESS = 'DESTROYING_BANDEIRA_SUCCESS'
export function destroyingBandeiraSuccess (bandeiraId) {
  return {
    type: DESTROYING_BANDEIRA_SUCCESS,
    bandeiraId,
  }
}

const initialState = Map({})

export function fetchMultipleBandeira () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingBandeira())
      const multipleBandeira = await callAPI('/gestao_bandeiras/bandeiras')
      dispatch(loadingBandeiraSuccess(multipleBandeira))
      return multipleBandeira
    } catch (e) {
      dispatch(loadingBandeiraFailure(e))
      return false
    }
  }
}

export function fetchBandeira (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingBandeira())
      const bandeira = await callAPI(`/gestao_bandeiras/bandeiras/${id}`)
      dispatch(loadingBandeiraSuccess(bandeira))
      return bandeira
    } catch (e) {
      dispatch(loadingBandeiraFailure(e))
      return false
    }
  }
}

export function createBandeira (bandeira) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingBandeira())
      const newBandeira = await callAPI('/gestao_bandeiras/bandeiras', 'POST', bandeira)
      dispatch(loadingBandeiraSuccess(newBandeira))
      return newBandeira
    } catch (e) {
      dispatch(loadingBandeiraFailure(e))
      return false
    }
  }
}

export function updateBandeira (id, bandeira) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingBandeira())
      const newBandeira = await callAPI(`/gestao_bandeiras/bandeiras/${id}`, 'PUT', bandeira)
      dispatch(loadingBandeiraSuccess(newBandeira))
      return newBandeira
    } catch (e) {
      dispatch(loadingBandeiraFailure(e))
      return false
    }
  }
}

export function destroyBandeira (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingBandeira())
      await callAPI(`/gestao_bandeiras/bandeiras/${id}`, 'DELETE')
      dispatch(destroyingBandeiraSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingBandeiraFailure(e))
      return false
    }
  }
}

export default function bandeira (state = initialState, action) {
  switch (action.type) {

    case LOADING_BANDEIRA_SUCCESS : {
      const { bandeira } = action
      if (bandeira.constructor === Array) {
        state = initialState
        bandeira.forEach((b) => (
          state = state.merge({[b.id]: b})
        ))
        return state
      }
      return state.merge({[bandeira.id]: bandeira})
    }

    case DESTROYING_BANDEIRA_SUCCESS :
      return state.delete(action.bandeiraId.toString())

    default :
      return state
  }
}
