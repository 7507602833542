import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'
import { formatDate } from '../../utils/formats'
import { maskCurrency } from '../../utils/masks'

const VigenciaValorShow = (props) => {
  if (props.vigenciaValor) {
    return (
      <CRUDShow
        title={props.vigenciaValor.id}
        icon='money'
        padded={false}
        onEditResource={() => props.onEditResource(props.vigenciaValor.id)}
        onDeleteResource={() => props.onDeleteResource(props.vigenciaValor.id)}>
        <ul>
          <li>Início da Vigência: {formatDate(props.vigenciaValor.starts_at)}</li>
          <li>
            Valores:
            <ul>
              {props.vigenciaValor.valores_bandeiras.map((valor, index) =>
                <li key={index}>
                  {valor.bandeira_nome}: {maskCurrency.parse(valor.valor)} R$/kWh
                </li>
              )}
            </ul>
          </li>
        </ul>
      </CRUDShow>
    )
  }

  return (null)
}

VigenciaValorShow.propTypes = {
  vigenciaValor: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
}

export default VigenciaValorShow
