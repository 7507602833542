import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'
import { formatDate } from '../../utils/formats'

const VigenciaTributoShow = (props) => {
  if (props.vigenciaTributo) {
    return (
      <CRUDShow
        title={props.vigenciaTributo.id}
        titleAs='h3'
        icon='calendar'
        padded={false}
        onEditResource={() => props.onEditResource(props.vigenciaTributo.id)}
        onDeleteResource={() => props.onDeleteResource(props.vigenciaTributo.id)}>
        <ul>
          <li>Início da Vigência: {formatDate(props.vigenciaTributo.starts_at)}</li>
        </ul>
      </CRUDShow>
    )
  }

  return (null)
}

VigenciaTributoShow.propTypes = {
  vigenciaTributo: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
}

export default VigenciaTributoShow
