import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import { Link } from 'react-router-dom'
import { CRUDShow } from '../CRUD'
import { Grid, Icon, Menu, Loader } from 'semantic-ui-react'
import { Informacoes, Parametros, Cenarios, Contatos, ContratosDemanda, ContratosEnergia, Servicos, FaturasPedagio, FaturasEnergia, Relatorios, } from './panes'

const InstalacaoShow = (props) => {
  const { instalacao, uf, pane, hideMenu } = props

  if (!instalacao || !uf) return (
    <Loader active={props.isLoading}>Carregando...</Loader>
  )

  return (
    <CRUDShow noToolbar noMessage noTitle={hideMenu}
      title={instalacao.apelido}
      subtitle={instalacao.nome}>

      {hideMenu ? null : (
        <Menu fluid size='medium'>
          <Menu.Item
            active={pane === 'informacoes'}
            as={Link}
            to={`/instalacoes/${instalacao.id}`}
            key='instalacao'>
            <Icon name='factory' />
            Informações
          </Menu.Item>
          <Menu.Item
            active={pane === 'parametros'}
            as={Link}
            to={`/instalacoes/${instalacao.id}/parametros`}
            key='parametros'>
            <Icon name='cogs' />
            Parâmetros
          </Menu.Item>
          <Menu.Item
            active={pane === 'relatorios'}
            as={Link}
            to={`/instalacoes/${instalacao.id}/relatorios`}
            key='relatorios'>
            <Icon name='chart line' />
            Relatórios
          </Menu.Item>
          <Menu.Item
            active={pane === 'pedagio'}
            as={Link}
            to={`/instalacoes/${instalacao.id}/pedagio`}
            key='faturas_pedagio'>
            <Icon name='file text' />
            Faturas
          </Menu.Item>
          <Menu.Menu position='right'>
            <Menu.Item
              active={pane === 'cenarios'}
              as={Link}
              to={`/instalacoes/${instalacao.id}/cenarios`}
              key='cenarios'>
              <Icon name='lab' />
              Cenários
            </Menu.Item>
            <Menu.Item
              active={pane === 'contratos_demanda'}
              as={Link}
              to={`/instalacoes/${instalacao.id}/contratos_demanda`}
              key='contratos_demanda'>
              <Icon name='file text' />
              Contratos
            </Menu.Item>
            <Menu.Item
              active={pane === 'servicos'}
              as={Link}
              to={`/instalacoes/${instalacao.id}/servicos`}
              key='servicos'>
              <Icon name='wrench' />
              Serviços
            </Menu.Item>
            <Menu.Item
              active={pane === 'contatos'}
              as={Link}
              to={`/instalacoes/${instalacao.id}/contatos`}
              key='contatos'>
              <Icon name='users' />
              Contatos
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      )}

      <Grid>
        <Grid.Column width='16'>
          <Switch>
            <Route exact
              path='/instalacoes/:id'
              render={() => (
                <Informacoes
                  instalacao={instalacao}
                  uf={uf}
                  onEditResource={props.onEditResource}
                  onDeleteResource={props.onDeleteResource}
                />
              )}
            />
            <Route exact
              path='/instalacoes/:id/parametros/:paneId?/:action?'
              render={() => <Parametros instalacao={instalacao} /> }
            />
            <Route exact
              path='/instalacoes/:id/cenarios/:paneId?/:action?'
              render={() => <Cenarios instalacao={instalacao} /> }
            />
            <Route exact
              path='/instalacoes/:id/relatorios/:paneId?/:action?'
              render={() => <Relatorios instalacao={instalacao} autoPrint={hideMenu} /> }
            />
            <Route exact
              path='/instalacoes/:id/contatos/:paneId?/:action?'
              render={() => <Contatos instalacao={instalacao} /> }
            />
            <Route exact
              path='/instalacoes/:id/contratos_demanda/:paneId?/:action?'
              render={() => <ContratosDemanda instalacao={instalacao} /> }
            />
            <Route exact
              path='/instalacoes/:id/contratos_energia/:paneId?/:action?'
              render={() => <ContratosEnergia instalacao={instalacao} /> }
            />
            <Route exact
              path='/instalacoes/:id/servicos/:paneId?/:action?'
              render={() => <Servicos instalacao={instalacao} /> }
            />
            <Route
              path='/instalacoes/:id/pedagio/:paneId?/resultado'
              render={() => <FaturasPedagio instalacao={instalacao} /> }
            />
            <Route exact
              path='/instalacoes/:id/pedagio/:paneId?/:action?'
              render={() => <FaturasPedagio instalacao={instalacao} /> }
            />
            <Route exact
              path='/instalacoes/:id/energia/:paneId?/:action?'
              render={() => <FaturasEnergia instalacao={instalacao} /> }
            />
          </Switch>
        </Grid.Column>
      </Grid>
    </CRUDShow>
  )
}

InstalacaoShow.propTypes = {
  instalacao: PropTypes.object,
  uf: PropTypes.object,
  enquadramento: PropTypes.object,
  subgrupo: PropTypes.object,
  classeConsumo: PropTypes.object,
  concessionaria: PropTypes.object,
  pane: PropTypes.string,
  onEditResource: PropTypes.func.isRequired,
  onDeleteResource: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  hideMenu: PropTypes.bool,
}

export default InstalacaoShow
