import React from 'react'
import PropTypes from 'prop-types'

const Parametros = () => {
  return (<h1>Em construção.</h1>)
}

Parametros.propTypes = {
  instalacao: PropTypes.object.isRequired,
}

export default Parametros
