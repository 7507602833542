import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { VigenciaValor } from '../../../containers'

const ValoresRouter = () => (
  <Switch>
    <Route exact path='/gestao_bandeiras/valores' component={VigenciaValor.Index} />
    <Route exact path='/gestao_bandeiras/valores/new' component={VigenciaValor.Form} />
    <Route exact path='/gestao_bandeiras/valores/:id/edit' component={VigenciaValor.Form} />
    <Route exact path='/gestao_bandeiras/valores/:id' component={VigenciaValor.Show} />
  </Switch>
)

export default ValoresRouter
