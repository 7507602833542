import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_PLD_MEDIO = 'LOADING_PLD_MEDIO'
export function loadingPldMedio () {
  return {
    type: LOADING_PLD_MEDIO,
  }
}

export const LOADING_PLD_MEDIO_SUCCESS = 'LOADING_PLD_MEDIO_SUCCESS'
export function loadingPldMedioSuccess (pldMedio) {
  return {
    type: LOADING_PLD_MEDIO_SUCCESS,
    pldMedio,
  }
}

export const LOADING_PLD_MEDIO_FAILURE = 'LOADING_PLD_MEDIO_FAILURE'
export function loadingPldMedioFailure (error) {
  return {
    type: LOADING_PLD_MEDIO_FAILURE,
    error,
  }
}

const initialState = Map({})

export function fetchMultiplePldMedio (year) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingPldMedio())
      const multiplePldMedio = await callAPI(`/indicativos/medios?ano=${year}`)
      dispatch(loadingPldMedioSuccess(multiplePldMedio))
      return multiplePldMedio
    } catch (e) {
      dispatch(loadingPldMedioFailure(e))
      return false
    }
  }
}

export function updatePldMedio (pldMedio) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingPldMedio())
      const newPldMedio = await callAPI('/indicativos/medios', 'PUT', pldMedio)
      dispatch(loadingPldMedioSuccess(newPldMedio))
      return newPldMedio
    } catch (e) {
      dispatch(loadingPldMedioFailure(e))
      return false
    }
  }
}

export default function pldMedio (state = initialState, action) {
  switch (action.type) {

    case LOADING_PLD_MEDIO_SUCCESS : {
      const { pldMedio } = action
      state = Map({})
      pldMedio.forEach((singlePldMedio, index) => (
        state = state.merge({[index]: singlePldMedio})
      ))
      return state
    }

    default :
      return state
  }
}
