import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import get from 'lodash.get'
import { Grid, Header, List, Segment } from 'semantic-ui-react'
import { capitalize } from './utils/ChartUtils'

const Cabecalho = ({ data, fatura = false }) => {
  useEffect(() => {
    if (fatura && data)
      document.title = `${get(data, 'instalacao.apelido', '')} - ${fatura ? 'Fatura' : 'Relatório'} de Energia - ${capitalize(moment({ M: get(data, 'vigencia.mes', 1) - 1, y: get(data, 'vigencia.ano', 0) }).format('MMMM/YYYY'))}`
  }, [data, fatura])

  if (!data) return null

  return (
    <React.Fragment>
      <Grid.Column className='sixty-percent-width'>
        <Header as='h1' className='base-color' style={{
          fontSize: '3em',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textTransform: 'uppercase'
        }}>
          {get(data, 'instalacao.apelido', '...')}
          <Header.Subheader className='base-color-light'>{get(data, 'cliente.nome', '...')}</Header.Subheader>
        </Header>

        <div style={{ textTransform: 'uppercase' }}>
          <Header as='h3' className='base-color' style={{
            textTransform: 'uppercase',
            marginBottom: 0,
          }}>
            {fatura ? 'Fatura' : 'Relatório'} de Energia - {capitalize(moment({ M: get(data, 'vigencia.mes', 1) - 1, y: get(data, 'vigencia.ano', 0) }).format('MMMM/YYYY'))}
          </Header>
        </div>
      </Grid.Column>

      <Grid.Column className='forty-percent-width'>
        <Segment style={{
          border: '0px',
          boxShadow: 'none',
          backgroundColor: '#f4f4f4',
        }}>
          <Grid divided fluid>
            <Grid.Row>
              <Grid.Column width={8}>
                <List divided className='cabecalho'>
                  <List.Item>
                    <List.Content>
                      <List.Header>Distribuidora</List.Header>
                      {get(data, 'concessionaria.sigla', '...')}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>NUC</List.Header>
                      {get(data, 'nuc', '...')}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>Período de Leitura</List.Header>
                      De {moment(get(data, 'periodo.inicial')).format('DD/MM/YYYY')} a {moment(get(data, 'periodo.final')).format('DD/MM/YYYY')}
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={8}>
                <List divided className='cabecalho'>
                  <List.Item>
                    <List.Content>
                      <List.Header>Modalidade Tarifária</List.Header>
                      {get(data, 'enquadramento.modalidade', '...')}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>Subgrupo de Tensão</List.Header>
                      {get(data, 'subgrupo.nome', '...')}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>Tipo de Mercado</List.Header>
                      {get(data, 'mercado', '...')}
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Grid.Column>
    </React.Fragment>
  )
}

Cabecalho.propTypes = {
  data: PropTypes.object,
}

Cabecalho.defaultProps = {
  data: {},
}

export default Cabecalho
