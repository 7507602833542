import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Container } from 'semantic-ui-react'
import { ResponsiveBar } from '@nivo/bar'

import {
  COLORS,
  capitalize,
  formatAsRoundedPercentage,
  formatAsCurrency,
} from './utils/ChartUtils'

const fieldMapping = [
  {
    id: 'Consumo P',
    field: 'consumo_p',
    color: COLORS.ELECTRIC.BLUE,
  },
  {
    id: 'Consumo FP',
    field: 'consumo_fp',
    color: COLORS.ELECTRIC.LIGHT.BLUE,
  },
  {
    id: 'Consumo INT',
    field: 'consumo_int',
    color: COLORS.ELECTRIC.LIGHTER.BLUE,
  },
  {
    id: 'Consumo',
    field: 'consumo_na',
    color: COLORS.ELECTRIC.BLUE,
  },
  {
    id: 'Demanda P',
    field: 'demanda_p',
    color: COLORS.ELECTRIC.GREEN,
  },
  {
    id: 'Demanda FP',
    field: 'demanda_fp',
    color: COLORS.ELECTRIC.LIGHT.GREEN,
  },
  {
    id: 'Demanda',
    field: 'demanda_na',
    color: COLORS.ELECTRIC.GREEN,
  },
  {
    id: 'UFER',
    field: 'ufer',
    color: COLORS.ELECTRIC.LIGHT.RED,
  },
  {
    id: 'Outros',
    field: 'outros',
    color: COLORS.ELECTRIC.LIGHT.BASE,
  },
  {
    id: 'Gerador',
    field: 'gerador',
    color: COLORS.ELECTRIC.LIGHTER.BASE,
  },
]

const ComposicaoAnualChart = ({ data, height, showAnimations }) => {
  if (!data) return null

  const chartKeys = []

  const chartData = data.map(fatura => {
    return fieldMapping.reduce((acc, curr) => {
      const id = curr.id
      const value = fatura[curr.field][0]
      const percent = fatura[curr.field][1]

      if (!chartKeys.includes(id)) chartKeys.push(id)

      acc[id] = percent
      acc[`${id}Color`] = curr.color
      acc[`${id}Tooltip`] = value

      return acc
    }, {
      periodo: capitalize(moment({y: fatura.ano, M: fatura.mes - 1}).format('MMM/YY')),
    })
  }).reverse()

  return (
    <Container fluid>
      <div style={{height: `${height}px`}}>
        <ResponsiveBar
          data={chartData}
          keys={chartKeys}
          indexBy='periodo'
          margin={{
            'top': 0,
            'right': 130,
            'bottom': 30,
            'left': 60
          }}
          padding={0.15}
          layout='horizontal'
          colors='nivo'
          colorBy={({ id, data }) => data[`${id}Color`]}
          tooltip={({ id, data }) => formatAsCurrency(data[`${id}Tooltip`])}
          labelFormat={value => formatAsRoundedPercentage(value)}
          labelTextColor='#fff'
          axisBottom={{ format: v => `${v * 100.0}%` }}
          borderColor='inherit:darker(1.6)'
          enableGridX={false}
          enableGridY={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          animate={showAnimations}
          motionStiffness={90}
          motionDamping={15}
          legends={[
            {
              'dataFrom': 'keys',
              'anchor': 'bottom-right',
              'direction': 'column',
              'justify': false,
              'translateX': 120,
              'translateY': 0,
              'itemsSpacing': 2,
              'itemWidth': 100,
              'itemHeight': 20,
              'itemDirection': 'right-to-left',
              'itemOpacity': 0.85,
              'symbolSize': 20,
              'itemTextColor': COLORS.ELECTRIC.LIGHT.BASE,
              'effects': [
                {
                  'on': 'hover',
                  'style': {
                    'itemOpacity': 1
                  }
                }
              ],
            }
          ]}
          theme={{
            labels: {
              text: {
                fontWeight: 'bold',
              }
            },
            axis: {
              domain: {
                line: {
                  strokeWidth: 0,
                },
              },
              ticks: {
                line: {
                  stroke: COLORS.ELECTRIC.LIGHT.BASE,
                },
                text: {
                  fill: COLORS.ELECTRIC.LIGHT.BASE,
                }
              },
            },
            dots: {
              text: {
                fill: COLORS.ELECTRIC.LIGHT.BASE,
              }
            }
          }}
        />
      </div>
    </Container>
  )
}

ComposicaoAnualChart.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
  showAnimations: PropTypes.bool,
}

ComposicaoAnualChart.defaultProps = {
  height: 350,
  showAnimations: true,
}

export default ComposicaoAnualChart
