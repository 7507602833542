import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Button, Icon, Menu } from 'semantic-ui-react'

const FormToolbar = (props) => (
  <Menu secondary>
    <Menu.Menu position='right'>
      {!props.noBackButton && (
        <Menu.Item fitted>
          <Button icon
            type='button'
            labelPosition='left'
            disabled={props.loading}
            loading={props.loading}
            onClick={(e) => {
              e.preventDefault()
              props.history.goBack()
            }}>
            <Icon name='arrow left' />{' '}Voltar
          </Button>
        </Menu.Item>
      )}
      <Menu.Item fitted>
        <Button icon
          type='submit'
          color='green'
          disabled={props.loading}
          loading={props.loading}
          labelPosition='left'>
          <Icon name='check' />{' '}Salvar
        </Button>
      </Menu.Item>
    </Menu.Menu>
  </Menu>
)

FormToolbar.propTypes = {
  loading: PropTypes.bool,
  history: PropTypes.object,
  noBackButton: PropTypes.bool,
}

export default withRouter(FormToolbar)
