import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_CLASSE_CONSUMO = 'LOADING_CLASSE_CONSUMO'
export function loadingClasseConsumo () {
  return {
    type: LOADING_CLASSE_CONSUMO,
  }
}

export const LOADING_CLASSE_CONSUMO_SUCCESS = 'LOADING_CLASSE_CONSUMO_SUCCESS'
export function loadingClasseConsumoSuccess (classeConsumo) {
  return {
    type: LOADING_CLASSE_CONSUMO_SUCCESS,
    classeConsumo,
  }
}

export const LOADING_CLASSE_CONSUMO_FAILURE = 'LOADING_CLASSE_CONSUMO_FAILURE'
export function loadingClasseConsumoFailure (error) {
  return {
    type: LOADING_CLASSE_CONSUMO_FAILURE,
    error,
  }
}

export const DESTROYING_CLASSE_CONSUMO_SUCCESS = 'DESTROYING_CLASSE_CONSUMO_SUCCESS'
export function destroyingClasseConsumoSuccess (classeConsumoId) {
  return {
    type: DESTROYING_CLASSE_CONSUMO_SUCCESS,
    classeConsumoId,
  }
}

const initialState = Map({})

export function fetchMultipleClasseConsumo () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingClasseConsumo())
      const multipleClasseConsumo = await callAPI('/classes_consumo')
      dispatch(loadingClasseConsumoSuccess(multipleClasseConsumo))
      return multipleClasseConsumo
    } catch (e) {
      dispatch(loadingClasseConsumoFailure(e))
      return false
    }
  }
}

export function fetchClasseConsumo (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingClasseConsumo())
      const classeConsumo = await callAPI(`/classes_consumo/${id}`)
      dispatch(loadingClasseConsumoSuccess(classeConsumo))
      return classeConsumo
    } catch (e) {
      dispatch(loadingClasseConsumoFailure(e))
      return false
    }
  }
}

export function createClasseConsumo (classeConsumo) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingClasseConsumo())
      const newClasseConsumo = await callAPI('/classes_consumo', 'POST', classeConsumo)
      dispatch(loadingClasseConsumoSuccess(newClasseConsumo))
      return newClasseConsumo
    } catch (e) {
      dispatch(loadingClasseConsumoFailure(e))
      return false
    }
  }
}

export function updateClasseConsumo (id, classeConsumo) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingClasseConsumo())
      const newClasseConsumo = await callAPI(`/classes_consumo/${id}`, 'PUT', classeConsumo)
      dispatch(loadingClasseConsumoSuccess(newClasseConsumo))
      return newClasseConsumo
    } catch (e) {
      dispatch(loadingClasseConsumoFailure(e))
      return false
    }
  }
}

export function destroyClasseConsumo (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingClasseConsumo())
      await callAPI(`/classes_consumo/${id}`, 'DELETE')
      dispatch(destroyingClasseConsumoSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingClasseConsumoFailure(e))
      return false
    }
  }
}

export default function classeConsumo (state = initialState, action) {
  switch (action.type) {

    case LOADING_CLASSE_CONSUMO_SUCCESS : {
      const { classeConsumo } = action
      if (classeConsumo.constructor === Array) {
        state = initialState
        classeConsumo.forEach((classe) => (
          state = state.merge({[classe.id]: classe})
        ))
        return state
      }
      return state.merge({[classeConsumo.id]: classeConsumo})
    }

    case DESTROYING_CLASSE_CONSUMO_SUCCESS :
      return state.delete(action.classeConsumoId.toString())

    default :
      return state
  }
}
