import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'

const ExtraPedagioShow = (props) => {
  if (!props.extraPedagio) return (null)

  return (
    <CRUDShow noTitle padded={false}
      onEditResource={props.onEditResource}
      onDeleteResource={props.onDeleteResource}>
      <ul>
        <li>Descrição: {props.extraPedagio.descricao}</li>
        <li>Valor (R$): {props.extraPedagio.valor}</li>
      </ul>
    </CRUDShow>
  )
}

ExtraPedagioShow.propTypes = {
  faturaPedagio: PropTypes.object,
  extraPedagio: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
}

export default ExtraPedagioShow
