import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ClasseConsumoShow } from '../../components'
import * as classeConsumoActionCreators from '../../redux/modules/classeConsumo'

class ClasseConsumoShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    fetchClasseConsumo: PropTypes.func,
    history: PropTypes.object,
    destroyClasseConsumo: PropTypes.func,
  }

  state = {
    classeConsumo: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const classeConsumo = await this.props.fetchClasseConsumo(this.props.id)
      this.setState({ classeConsumo })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/classes_consumo/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/classes_consumo/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyClasseConsumo(id)
    this.props.history.push('/classes_consumo')
  }

  render () {
    return (
      <ClasseConsumoShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        classeConsumo={this.state.classeConsumo} />
    )
  }
}

function mapStateToProps ({classeConsumo}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      classeConsumo: classeConsumo.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...classeConsumoActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ClasseConsumoShowContainer)
