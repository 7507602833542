import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { VigenciaParametroForm } from '../../components'
import * as vigenciaParametroActionCreators from '../../redux/modules/vigenciaParametro'
import * as classeConsumoActionCreators from '../../redux/modules/classeConsumo'
import * as comercializadoraActionCreators from '../../redux/modules/comercializadora'
import * as concessionariaActionCreators from '../../redux/modules/concessionaria'
import * as enquadramentoActionCreators from '../../redux/modules/enquadramento'
import * as geradorActionCreators from '../../redux/modules/gerador'
import * as subgrupoActionCreators from '../../redux/modules/subgrupo'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import moment from 'moment'
import { maskPercent } from '../../utils/masks'
import { multipleTipoMercado } from '../../utils/tipoMercado'

const percentFields = [
  'icms',
  'icms_reduzido',
  'icms_credito',
  'perdas',
]

const dateFields = [
  'data_contrato_concessionaria',
  'data_inicio_geracao_distribuida',
  'data_migracao_livre',
]

class VigenciaParametroFormContainer extends Component {
  static propTypes = {
    createVigenciaParametro: PropTypes.func.isRequired,
    error: PropTypes.object,
    fetchVigenciaParametro: PropTypes.func.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    id: PropTypes.string,
    initialize: PropTypes.func.isRequired,
    instalacao: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    updateVigenciaParametro: PropTypes.func.isRequired,

    fetchMultipleClasseConsumo: PropTypes.func,
    fetchMultipleComercializadora: PropTypes.func,
    fetchMultipleConcessionaria: PropTypes.func,
    fetchMultipleEnquadramento: PropTypes.func,
    fetchMultipleGerador: PropTypes.func,
    fetchMultipleSubgrupo: PropTypes.func,
    fetchLastVigenciaParametro: PropTypes.func,

    multipleClasseConsumo: PropTypes.object,
    multipleComercializadora: PropTypes.object,
    multipleConcessionaria: PropTypes.object,
    multipleEnquadramento: PropTypes.object,
    multipleGerador: PropTypes.object,
    multipleSubgrupo: PropTypes.object,
    multipleTipoMercado: PropTypes.object,
  }

  async componentDidMount () {
    await Promise.all([
      this.props.fetchMultipleClasseConsumo(),
      this.props.fetchMultipleComercializadora(),
      this.props.fetchMultipleConcessionaria(),
      this.props.fetchMultipleEnquadramento(),
      this.props.fetchMultipleGerador(),
      this.props.fetchMultipleSubgrupo(),
    ])

    let vigencia_parametro = {
      instalacao_id: this.props.instalacao.id,
      starts_at: moment().startOf('month').format('YYYY-MM-DD'),
      parametro_calculo: {
        data_contrato_concessionaria: moment().startOf('month').format('YYYY-MM-DD'),
        data_inicio_geracao_distribuida: moment().startOf('month').format('YYYY-MM-DD'),
        data_migracao_livre: moment().startOf('month').format('YYYY-MM-DD'),
        icms: 0.0,
        icms_reduzido: 0.0,
        icms_credito: 0.0,
        perdas: 0.0,
        tipo: 'normal',
      }
    }

    if (this.props.id) {
      vigencia_parametro = await this.props.fetchVigenciaParametro(this.props.id)
    } else {
      const last_vigencia_parametro = await this.props.fetchLastVigenciaParametro(this.props.instalacao.id)

      if (last_vigencia_parametro) {
        vigencia_parametro = last_vigencia_parametro
        delete vigencia_parametro['id']
        delete vigencia_parametro.parametro_calculo['id']
      }
    }

    vigencia_parametro.starts_at = moment(vigencia_parametro.starts_at, 'YYYY-MM-DD').format('DD/MM/YYYY')

    const { parametro_calculo } = vigencia_parametro

    dateFields.forEach(param => {
      parametro_calculo[param] = moment(parametro_calculo[param], 'YYYY-MM-DD').format('DD/MM/YYYY')
    })

    percentFields.forEach(param => {
      parametro_calculo[param] = maskPercent.parse(parametro_calculo[param])
    })

    this.props.initialize('VigenciaParametroForm', { vigencia_parametro })
  }

  handleSubmitResource = async (vigenciaParametro) => {
    const instalacaoId = this.props.instalacao.id

    percentFields.forEach(param => {
      let value = maskPercent.unmask(vigenciaParametro.getIn(['vigencia_parametro', 'parametro_calculo', param]))
      vigenciaParametro = vigenciaParametro.setIn(['vigencia_parametro', 'parametro_calculo', param], value)
    })

    const parametros = vigenciaParametro.getIn(['vigencia_parametro', 'parametro_calculo'])
    vigenciaParametro = vigenciaParametro
      .setIn(['vigencia_parametro', 'parametro_calculo_attributes'], parametros)
      .deleteIn(['vigencia_parametro', 'parametro_calculo'])

    if (this.props.id) {
      if (await this.props.updateVigenciaParametro(this.props.id, vigenciaParametro)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/instalacoes/${instalacaoId}/parametros`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createVigenciaParametro(instalacaoId, vigenciaParametro)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push(`/instalacoes/${instalacaoId}/parametros`)
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <VigenciaParametroForm
        instalacao={this.props.instalacao}
        multipleClasseConsumo={this.props.multipleClasseConsumo}
        multipleComercializadora={this.props.multipleComercializadora}
        multipleConcessionaria={this.props.multipleConcessionaria}
        multipleEnquadramento={this.props.multipleEnquadramento}
        multipleGerador={this.props.multipleGerador}
        multipleSubgrupo={this.props.multipleSubgrupo}
        multipleTipoMercado={this.props.multipleTipoMercado}
        onSubmit={(vigenciaParametro) => this.handleSubmitResource(vigenciaParametro)}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({vigenciaParametro, subgrupo, enquadramento, classeConsumo, concessionaria, gerador, comercializadora, status, flashMessage}, {match}) {
  const { id } = match.params

  const isLoading = status.getIn(['vigenciaParametro', 'isLoading']) ||
    status.getIn(['classeConsumo', 'isLoading']) ||
    status.getIn(['comercializadora', 'isLoading']) ||
    status.getIn(['concessionaria', 'isLoading']) ||
    status.getIn(['enquadramento', 'isLoading']) ||
    status.getIn(['gerador', 'isLoading']) ||
    status.getIn(['subgrupo', 'isLoading'])

  if (id) {
    return {
      id,
      vigenciaParametro: vigenciaParametro.get('id'),
      error: status.getIn(['vigenciaParametro', 'error']),
      isLoading,
      shouldHideFlashMessage: flashMessage.get('isError'),
      multipleClasseConsumo: classeConsumo,
      multipleComercializadora: comercializadora,
      multipleConcessionaria: concessionaria,
      multipleEnquadramento: enquadramento,
      multipleGerador: gerador,
      multipleSubgrupo: subgrupo,
      multipleTipoMercado,
    }
  }
  return {
    error: status.getIn(['vigenciaParametro', 'error']),
    isLoading,
    shouldHideFlashMessage: flashMessage.get('isError'),
    multipleClasseConsumo: classeConsumo,
    multipleComercializadora: comercializadora,
    multipleConcessionaria: concessionaria,
    multipleEnquadramento: enquadramento,
    multipleGerador: gerador,
    multipleSubgrupo: subgrupo,
    multipleTipoMercado,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...vigenciaParametroActionCreators,
    ...classeConsumoActionCreators,
    ...comercializadoraActionCreators,
    ...concessionariaActionCreators,
    ...enquadramentoActionCreators,
    ...geradorActionCreators,
    ...subgrupoActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VigenciaParametroFormContainer))
