import React from 'react'
import PropTypes from 'prop-types'
import { Card, Icon } from 'semantic-ui-react'

const images = require.context('../../img/avatar', true)

const extraFields = [
  {
    key: 'email',
    icon: 'mail',
    hrefPrefix: 'mailto',
  },
  {
    key: 'telefone',
    icon: 'phone',
    hrefPrefix: 'tel',
  },
  {
    key: 'skype',
    icon: 'skype',
    hrefPrefix: 'skype',
  },
]

const Gestor = ({ gestor }) => {
  if (!gestor) return (null)
  const filtered = extraFields.filter(extra => gestor[extra.key])
  const extra = filtered.map((extra, index) => (
    <React.Fragment key={extra.key}>
      <a href={`${extra.hrefPrefix}:${gestor[extra.key]}`}>
        <Icon name={extra.icon} />{' '}
        {gestor[extra.key]}
      </a>
      {index < filtered.length - 1 ? <br /> : null}
    </React.Fragment>
  ))

  return (
    <Card fluid style={{
      boxShadow: 'none',
      backgroundColor: '#f4f4f4',
    }}>
      <img
        alt={gestor.nome}
        src={gestor.avatar
          ? images(`./${gestor.avatar}`)
          : images('./generico.png')} className='ui centered circular image gestor-avatar'
      />
      <Card.Content>
        <Card.Header>{gestor.nome} {gestor.sobrenome}</Card.Header>
        {gestor.cargo && <Card.Meta>{gestor.cargo}</Card.Meta>}
      </Card.Content>
      {extra && extra.length > 0 && <Card.Content extra>{extra}</Card.Content>}
    </Card>
  )
}

Gestor.propTypes = {
  gestor: PropTypes.object,
}

export default Gestor
