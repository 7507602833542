import Papa from 'papaparse'
import Encoding from 'encoding-japanese'

class CSVParser {
  static readFile = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = (error) => reject(error)
    reader.onabort = (abort) => reject(abort)
    reader.onload = () => {
      let codes = new Uint8Array(reader.result)
      let encoding = Encoding.detect(codes)
      let unicodeString = Encoding.convert(codes, {
        to: 'unicode',
        from: encoding === 'UTF8' ? encoding : 'unicode',
        type: 'string'
      })
      resolve(unicodeString)
    }
    reader.readAsArrayBuffer(file)
  })

  static parse = (file, callback) => new Promise((resolve, reject) => {
    CSVParser.readFile(file).then(
      fileContent => {
        try {
          let parsedData = Papa.parse(fileContent, {
            header: true,
            skipEmptyLines: true,
            encoding: 'unicode'
          })
          if (parsedData.errors.length > 0) reject(parsedData)
          if (callback) resolve(callback(parsedData))
          resolve(parsedData)
        }
        catch (exception) {
          reject(exception)
        }
      },
      reason => reject(reason)
    )
  })
}

export default CSVParser
