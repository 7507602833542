import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as pldSemanalActionCreators from '../../redux/modules/pldSemanal'
import * as pldSemanalSelectionActionCreators from '../../redux/modules/pldSemanalSelection'
import * as submercadoActionCreators from '../../redux/modules/submercado'
import * as dataImporterActionCreators from '../../redux/modules/dataImporter'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { PldSemanal } from '../../components/PldSemanal'
import { arrayToCSV } from '../../utils/arrayToCSV'
import CSVParser from '../../utils/csv/CSVParser'
import { maskCurrency } from '../../utils/masks'

class PldSemanalContainer extends Component {

  static propTypes = {
    history: PropTypes.object,
    multiplePldSemanal: PropTypes.object,
    multipleSubmercado: PropTypes.object,
    fetchMultiplePldSemanal: PropTypes.func,
    fetchMultipleSubmercado: PropTypes.func,
    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    isLoading: PropTypes.bool,
    updatePldSemanalSelection: PropTypes.func,
    updateImportData: PropTypes.func,

    year: PropTypes.number,
    month: PropTypes.number,
    week: PropTypes.number,
    years: PropTypes.array,
    months: PropTypes.array,
    weeks: PropTypes.array,
  }

  async componentDidMount () {
    this.props.fetchMultipleSubmercado()
    this.fetchData()
  }

  componentDidUpdate (prevProps) {
    const { year, month, week } = this.props
    if (prevProps.year !== year || prevProps.month !== month || prevProps.week !== week) {
      this.fetchData()
    }
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  fetchData = async () => {
    await this.props.fetchMultiplePldSemanal(
      this.props.year,
      this.props.month,
      this.props.week
    )
  }

  handleChange = (event) => {
    this.props.updatePldSemanalSelection(
      event.target.name,
      event.target.value,
    )
  }

  handleEditResource = () => {
    this.props.history.push('/indicativos/semanais/edit')
  }

  parserCallback = (parsedData) => {
    return parsedData.data.reduce((acc, curr) => {
      acc = acc.concat(this.getSubmercadoFromRow(curr, 'N'))
      acc = acc.concat(this.getSubmercadoFromRow(curr, 'NE'))
      acc = acc.concat(this.getSubmercadoFromRow(curr, 'S'))
      acc = acc.concat(this.getSubmercadoFromRow(curr, 'SE'))
      return acc
    }, [])
  }

  handleUploadCSV = (file) => {
    CSVParser.parse(file, this.parserCallback).then(
      data => {
        this.props.updateImportData('pldSemanal', data)
        this.props.history.push('/indicativos/semanais/upload')
      },
      () => {
        alert('Não foi possível importar o arquivo.')
      }
    )
  }

  getSubmercadoFromRow = (row, submercado) => {
    return {
      ano: row['Ano'],
      mes: row['Mês'],
      semana: row['Semana'],
      submercado_id: this.props.multipleSubmercado.find(s => s.get('nome') === submercado).get('id'),
      patamar_pesado: maskCurrency.unmask(row[`Pesado ${submercado}`]),
      patamar_medio: maskCurrency.unmask(row[`Médio ${submercado}`]),
      patamar_leve: maskCurrency.unmask(row[`Leve ${submercado}`]),
    }
  }

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multiplePldSemanal.toList().toJSON(),
      'pldSemanal',
      ['ano', 'mes', 'semana', 'submercado.nome', 'patamar_leve', 'patamar_medio', 'patamar_pesado']
    )
  }

  render () {
    return (
      <PldSemanal
        year={this.props.year}
        month={this.props.month}
        week={this.props.week}
        years={this.props.years}
        months={this.props.months}
        weeks={this.props.weeks}

        isLoading={this.props.isLoading}
        multiplePldSemanal={this.props.multiplePldSemanal}
        onChange={this.handleChange}
        onEditResource={this.handleEditResource}
        onExportToCSV={this.handleExportToCSV}
        onUploadCSV={this.handleUploadCSV}
      />
    )
  }
}

function mapStateToProps ({ pldSemanal, pldSemanalSelection, submercado, status, flashMessage }) {
  return {
    multiplePldSemanal: pldSemanal,
    error: status.getIn(['pldSemanal', 'error']),
    isLoading: status.getIn(['pldSemanal', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
    multipleSubmercado: submercado,

    year: pldSemanalSelection.get('year'),
    month: pldSemanalSelection.get('month'),
    week: pldSemanalSelection.get('week'),
    years: pldSemanalSelection.get('years'),
    months: pldSemanalSelection.get('months'),
    weeks: pldSemanalSelection.get('weeks'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...pldSemanalActionCreators,
    ...pldSemanalSelectionActionCreators,
    ...submercadoActionCreators,
    ...dataImporterActionCreators,
    ...flashMessageAcionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PldSemanalContainer)
