import { Map } from 'immutable'
import WeekHelper from '../../utils/WeekHelper'

export const UPDATE_PLD_SEMANAL_SELECTION = 'UPDATE_PLD_SEMANAL_SELECTION'
export function updatePldSemanalSelection(property, value) {
  return {
    type: UPDATE_PLD_SEMANAL_SELECTION,
    property,
    value,
  }
}

const initialState = Map({
  year: (new Date()).getFullYear(),
  month: (new Date()).getMonth() + 1,
  week: 1,
  years: function() {
    let arr = []
    const currentYear = (new Date()).getFullYear()
    for (let year = currentYear - 10; year <= currentYear; year++) {
      arr.push({
        key: `${year}`,
        text: `${year}`,
        value: year
      })
    }
    return arr
  }(),

  months: [
    { key: 'jan', text: 'Janeiro', value: 1 },
    { key: 'fev', text: 'Fevereiro', value: 2 },
    { key: 'mar', text: 'Março', value: 3 },
    { key: 'abr', text: 'Abril', value: 4 },
    { key: 'mai', text: 'Maio', value: 5 },
    { key: 'jun', text: 'Junho', value: 6 },
    { key: 'jul', text: 'Julho', value: 7 },
    { key: 'ago', text: 'Agosto', value: 8 },
    { key: 'set', text: 'Setembro', value: 9 },
    { key: 'out', text: 'Outubro', value: 10 },
    { key: 'nov', text: 'Novembro', value: 11 },
    { key: 'dez', text: 'Dezembro', value: 12 },
  ],

  weeks: WeekHelper.getWeeks((new Date()).getFullYear(), (new Date()).getMonth() + 1)
})

export default function pldSemanalSelection (state = initialState, action) {
  switch (action.type) {

    case UPDATE_PLD_SEMANAL_SELECTION : {
      const { property, value } = action

      switch (property) {
        case 'year':
          state = state.set('year', value)
          state = state.set('month', 1)
          state = state.set('week', 1)
          state = state.set('weeks', WeekHelper.getWeeks(value, 1))
          break

        case 'month':
          state = state.set('month', value)
          state = state.set('week', 1)
          state = state.set('weeks', WeekHelper.getWeeks(state.get('year'), value))
          break

        case 'week':
          state = state.set('week', value)
          break

        default:
          break
      }

      return state
    }

    default :
      return state
  }
}
