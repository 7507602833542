import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Contato } from '../../components'
import * as contatoActionCreators from '../../redux/modules/contato'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class ContatoContainer extends Component {

  static propTypes = {
    history: PropTypes.object,
    multipleContato: PropTypes.object,
    fetchMultipleContato: PropTypes.func,
    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    destroyContato: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleContato()
  }

  handleEditResource = (id) => {
    this.props.history.push(`/contatos/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyContato(id)
      ? this.props.showSuccessfulFlashMessage('', 'destroy')
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  handleSort = column => () => this.props.updateSort('contato', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('contato', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleContato.toList().toJSON(),
      'contato',
      ['id', 'nome', 'funcao.nome', 'email', 'telefone', 'celular', 'observacoes'],
    )
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <Contato
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        multipleContato={this.props.multipleContato}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({ contato, status, sort, filter }) {
  const sortColumn = sort.getIn(['contato', 'sortColumn']) || 'nome'
  const sortDirection = sort.getIn(['contato', 'sortDirection']) || 'ascending'
  const filterText = filter.getIn(['contato', 'filterText']) || ''

  let map = filterMap(contato, ['nome', 'funcao.nome', 'email', 'telefone', 'celular', 'observacoes'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleContato: map,
    sortColumn,
    sortDirection,
    filterText,
    error: status.getIn(['contato', 'error']),
    isLoading: status.getIn(['contato', 'isLoading']),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...flashMessageAcionCreators,
    ...contatoActionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ContatoContainer)
