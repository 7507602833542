import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Icon } from 'semantic-ui-react'

import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { TextAreaField } from 'react-semantic-redux-form'
import { Form, FormField } from 'semantic-ui-react'

import ViewModeContext from '../../containers/ViewModeContext/ViewModeContext'
import ViewMode from '../../utils/viewMode'

import MarkdownRenderer from './MarkdownRenderer'

const renderMethod = (mode) => ({
  [ViewMode.SHOW]: renderShow,
  [ViewMode.PRINT]: renderShow,
  [ViewMode.EDIT]: renderEdit,
})[mode]

const renderShow = props => {
  const { comment } = props
  return (<MarkdownRenderer source={comment} />)
}

renderShow.propTypes = {
  comment: PropTypes.string,
}

const renderEdit = (props) => {
  const { isLoading, isEditMode, onToggleMode, handleSubmit } = props

  if (!isEditMode) return (
    <React.Fragment>
      {renderShow(props)}
      <Button fluid
        color='yellow'
        onClick={onToggleMode}>
        <Icon name='edit' />Alterar Comentário
      </Button>
    </React.Fragment>
  )

  return (
    <Form onSubmit={handleSubmit} loading={isLoading}>
      <FormSection name='relatorio.cenarios_relatorios_attributes[0]'>
        <Form.Group>
          <FormField width={16}>
            <Field
              name='comment'
              component={TextAreaField}
              placeholder='...'
              autoHeight
            />
          </FormField>
        </Form.Group>
      </FormSection>
      <div style={{marginTop: '1em'}}>
        <Button
          color='default'
          onClick={onToggleMode}>
          <Icon name='cancel' /> Cancelar
        </Button>
        <Button color='green' type='submit' disabled={isLoading}>
          <Icon name='checkmark' /> Salvar
        </Button>
      </div>
    </Form>
  )
}

renderEdit.propTypes = {
  isLoading: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onToggleMode: PropTypes.func,
  handleSubmit: PropTypes.func,
}

const ComentarioSimulacao = props => {
  const { viewMode } = useContext(ViewModeContext)
  return (
    <Container fluid>
      {renderMethod(viewMode)(props)}
    </Container>
  )
}

ComentarioSimulacao.propTypes = {
  comment: PropTypes.string,
  isLoading: PropTypes.bool,
  print: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onToggleMode: PropTypes.func,
  handleSubmit: PropTypes.func,
}

ComentarioSimulacao.defaultProps = {
  comment: '',
}

export default reduxForm()(ComentarioSimulacao)
