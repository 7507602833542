import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { VigenciaBandeiraForm } from '../../components'
import * as vigenciaBandeiraActionCreators from '../../redux/modules/vigenciaBandeira'
import * as bandeiraActionCreators from '../../redux/modules/bandeira'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { mapToDropdown } from '../../utils/parse'
import moment from 'moment'

class VigenciaBandeiraFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    fetchVigenciaBandeira: PropTypes.func.isRequired,
    createVigenciaBandeira: PropTypes.func.isRequired,
    updateVigenciaBandeira: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
    bandeiraDropdown: PropTypes.array,
    fetchMultipleBandeira: PropTypes.func,
  }

  async componentDidMount () {
    await this.props.fetchMultipleBandeira()
    if (this.props.id) {
      const vigencia_bandeira = await this.props.fetchVigenciaBandeira(this.props.id)
      vigencia_bandeira.starts_at = moment(vigencia_bandeira.starts_at, 'YYYY-MM-DD').format('DD/MM/YYYY')
      this.props.initialize('VigenciaBandeiraForm', { vigencia_bandeira })
    } else {
      this.props.initialize('VigenciaBandeiraForm', {
        vigencia_bandeira: {
          starts_at: moment().startOf('month').format('DD/MM/YYYY'),
          bandeira: null,
        }
      })
    }
  }

  handleSubmitResource = async (vigenciaBandeira) => {
    if (this.props.id) {
      if (await this.props.updateVigenciaBandeira(this.props.id, vigenciaBandeira)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push('/gestao_bandeiras/vigencias')
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createVigenciaBandeira(vigenciaBandeira)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/gestao_bandeiras/vigencias')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <VigenciaBandeiraForm
        onSubmit={(vigenciaBandeira) => this.handleSubmitResource(vigenciaBandeira)}
        bandeiraDropdown={this.props.bandeiraDropdown}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({vigenciaBandeira, bandeira, status, flashMessage}, {match}) {
  const { id } = match.params
  const bandeiraDropdown = mapToDropdown(
    bandeira,
    e => e.get('id'),
    e => e.get('nome')
  )

  const isLoading = status.getIn(['vigenciaBandeira', 'isLoading']) || status.getIn(['bandeira', 'isLoading'])

  if (id) {
    return {
      id,
      vigenciaBandeira: vigenciaBandeira.get('id'),
      error: status.getIn(['vigenciaBandeira', 'error']),
      isLoading,
      shouldHideFlashMessage: flashMessage.get('isError'),
      bandeiraDropdown
    }
  }
  return {
    error: status.getIn(['vigenciaBandeira', 'error']),
    isLoading,
    shouldHideFlashMessage: flashMessage.get('isError'),
    bandeiraDropdown
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...vigenciaBandeiraActionCreators,
    ...bandeiraActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(VigenciaBandeiraFormContainer)
