import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { ParametroConcessionaria } from '../../../containers'

const Parametros = (props) => {
  const { concessionaria } = props
  return (
    <div>
      <Switch>
        <Route exact path='/concessionarias/:concessionariaId/parametros'
          render={({match}) => (
            <ParametroConcessionaria.Index
              concessionaria={concessionaria}
              match={match}
            />
          )}
        />
        <Route exact path='/concessionarias/:concessionariaId/parametros/new'
          render={({match}) => (
            <ParametroConcessionaria.Form
              concessionaria={concessionaria}
              match={match}
            />
          )}
        />
        <Route exact path='/concessionarias/:concessionariaId/parametros/:id/edit'
          render={({match}) => (
            <ParametroConcessionaria.Form
              concessionaria={concessionaria}
              match={match}
            />
          )}
        />
        <Route exact path='/concessionarias/:concessionariaId/parametros/:id'
          render={({match}) => (
            <ParametroConcessionaria.Show
              concessionaria={concessionaria}
              match={match}
            />
          )}
        />
      </Switch>
    </div>
  )
}

Parametros.propTypes = {
  concessionaria: PropTypes.object.isRequired,
}

export default Parametros
