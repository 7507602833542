import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { ServicoInstalacao } from '../../../containers'

const Servico = (props) => {
  const { instalacao } = props
  return (
    <div>
      <Switch>
        <Route
          exact path='/instalacoes/:instalacaoId/servicos'
          render={({match}) => (
            <ServicoInstalacao.Index
              instalacao={instalacao}
              match={match}
            />
          )}>
        </Route>
        <Route
          exact path='/instalacoes/:instalacaoId/servicos/new'
          render={({match}) => (
            <ServicoInstalacao.Form
              instalacao={instalacao}
              match={match}
            />
          )}
        />
        <Route
          exact path='/instalacoes/:instalacaoId/servicos/:id/edit'
          render={({match}) => (
            <ServicoInstalacao.Form
              instalacao={instalacao}
              match={match}
            />
          )}
        />
        <Route
          exact path='/instalacoes/:instalacaoId/servicos/:id'
          render={({match}) => (
            <ServicoInstalacao.Show
              instalacao={instalacao}
              match={match}
            />
          )}
        />
      </Switch>
    </div>
  )
}

Servico.propTypes = {
  instalacao: PropTypes.object.isRequired,
}

export default Servico
