import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'

const UfShow = (props) => {
  if (props.uf) {
    return (
      <CRUDShow
        title={props.uf.nome}
        icon='marker'
        onEditResource={() => props.onEditResource(props.uf.id)}
        onDeleteResource={() => props.onDeleteResource(props.uf.id)}>
        <ul>
          <li>Sigla: {props.uf.sigla}</li>
          <li>Nome: {props.uf.nome}</li>
          <li>Submercado: {props.uf.submercado ? (props.uf.submercado.nome) : (<em>N/A</em>)}</li>
        </ul>
      </CRUDShow>
    )
  }

  return (null)
}

UfShow.propTypes = {
  uf: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
}

export default UfShow
