import React from 'react'
import PropTypes from 'prop-types'
import { Label, Loader, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { CRUDIndex } from '../CRUD'
import { EditDeleteButtonContainer } from '../../containers'
import { formatDate } from '../../utils/formats'
import { maskFloat } from '../../utils/masks'

const VigenciaValor = (props) => {
  const { multipleBandeira } = props
  return (
    <CRUDIndex noTitle padded={false}
      onRedirectToNew={props.onRedirectToNew}
      onExportToCSV={props.onExportToCSV}
      filterText={props.filterText}
      handleFilter={props.handleFilter}
    >
      <Table sortable celled striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1} textAlign='center'
              onClick={props.handleSort('id')}
              sorted={props.handleSorted('id')}>#</Table.HeaderCell>
            <Table.HeaderCell width={2}
              onClick={props.handleSort('starts_at')}
              sorted={props.handleSorted('starts_at')}>Início da Vigência</Table.HeaderCell>
            {multipleBandeira.valueSeq().map((bandeira, index) => (
              <Table.HeaderCell key={index} textAlign='right'>{bandeira.get('nome')} (R$/kWh)</Table.HeaderCell>
            ))}
            <Table.HeaderCell width={1}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {props.isLoading && (
            <Table.Row>
              <Table.Cell colSpan={multipleBandeira.size + 3}>
                <Loader active={props.isLoading} inline='centered' />
              </Table.Cell>
            </Table.Row>
          )}
          {props.multipleVigenciaValor.valueSeq().map((vigenciaValor, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell textAlign='center'>
                  <Label as={Link} to={`/gestao_bandeiras/valores/${vigenciaValor.get('id')}`}>
                    {vigenciaValor.get('id')}
                  </Label>
                </Table.Cell>
                <Table.Cell>{formatDate(vigenciaValor.get('starts_at'))}</Table.Cell>
                {multipleBandeira.valueSeq().map((bandeira, index) => {
                  const found = vigenciaValor.get('valores_bandeiras').find(v => v.get('bandeira_id') === bandeira.get('id'))
                  return (
                    <Table.Cell key={index} textAlign='right'>
                      {found ? maskFloat.parse(found.get('valor'), 5) : '0,00000'}
                    </Table.Cell>
                  )
                })}
                <Table.Cell>
                  <EditDeleteButtonContainer
                    onEditResource={() => props.onEditResource(vigenciaValor.get('id'))}
                    onDeleteResource={() => props.onDeleteResource(vigenciaValor.get('id'))} />
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </CRUDIndex>
  )
}

VigenciaValor.propTypes = {
  onRedirectToNew: PropTypes.func,
  multipleVigenciaValor: PropTypes.object,
  multipleBandeira: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default VigenciaValor
