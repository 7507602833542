import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Bandeira } from '../../components'
import * as bandeiraActionCreators from '../../redux/modules/bandeira'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class BandeiraContainer extends Component {

  static propTypes = {
    history: PropTypes.object,
    multipleBandeira: PropTypes.object,
    fetchMultipleBandeira: PropTypes.func,
    destroyBandeira: PropTypes.func,
    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string
  }

  async componentDidMount () {
    await this.props.fetchMultipleBandeira()
  }

  handleRedirectToNew = () => {
    this.props.history.push('/gestao_bandeiras/bandeiras/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/gestao_bandeiras/bandeiras/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyBandeira(id)
      ? this.props.showSuccessfulFlashMessage('', 'destroy')
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  handleSort = column => () => this.props.updateSort('bandeira', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('bandeira', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleBandeira.toList().toJSON(),
      'bandeira',
      ['id', 'nome', 'descricao', 'cor', 'acrescimo'],
    )
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <Bandeira
        onRedirectToNew={this.handleRedirectToNew}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        multipleBandeira={this.props.multipleBandeira}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV}
        isLoading={this.props.isLoading}
      />
    )
  }
}

function mapStateToProps ({bandeira, status, sort, filter}) {
  const sortColumn = sort.getIn(['bandeira', 'sortColumn']) || 'nome'
  const sortDirection = sort.getIn(['bandeira', 'sortDirection']) || 'ascending'
  const filterText = filter.getIn(['bandeira', 'filterText']) || ''

  let map = filterMap(bandeira, ['nome', 'descricao'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleBandeira: map,
    error: status.getIn(['bandeira', 'error']),
    isLoading: status.getIn(['bandeira', 'isLoading']),
    sortColumn,
    sortDirection,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...flashMessageAcionCreators,
    ...bandeiraActionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BandeiraContainer))
