import React from 'react'
import ViewModeConsumer from '../containers/ViewModeContext/ViewModeContext'

const withViewMode = WrappedComponent => {
  const HOC = props => (
    <ViewModeConsumer>
      {context => <WrappedComponent {...props} {...context} />}
    </ViewModeConsumer>
  )

  return HOC
}

export default withViewMode
