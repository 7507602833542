import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_SUBGRUPO = 'LOADING_SUBGRUPO'
export function loadingSubgrupo () {
  return {
    type: LOADING_SUBGRUPO,
  }
}

export const LOADING_SUBGRUPO_SUCCESS = 'LOADING_SUBGRUPO_SUCCESS'
export function loadingSubgrupoSuccess (subgrupo) {
  return {
    type: LOADING_SUBGRUPO_SUCCESS,
    subgrupo,
  }
}

export const LOADING_SUBGRUPO_FAILURE = 'LOADING_SUBGRUPO_FAILURE'
export function loadingSubgrupoFailure (error) {
  return {
    type: LOADING_SUBGRUPO_FAILURE,
    error,
  }
}

export const DESTROYING_SUBGRUPO_SUCCESS = 'DESTROYING_SUBGRUPO_SUCCESS'
export function destroyingSubgrupoSuccess (subgrupoId) {
  return {
    type: DESTROYING_SUBGRUPO_SUCCESS,
    subgrupoId,
  }
}

const initialState = Map({})

export function fetchMultipleSubgrupo () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingSubgrupo())
      const multipleSubgrupo = await callAPI('/subgrupos')
      dispatch(loadingSubgrupoSuccess(multipleSubgrupo))
      return multipleSubgrupo
    } catch (e) {
      dispatch(loadingSubgrupoFailure(e))
      return false
    }
  }
}

export function fetchSubgrupo (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingSubgrupo())
      const subgrupo = await callAPI(`/subgrupos/${id}`)
      dispatch(loadingSubgrupoSuccess(subgrupo))
      return subgrupo
    } catch (e) {
      dispatch(loadingSubgrupoFailure(e))
      return false
    }
  }
}

export function createSubgrupo (subgrupo) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingSubgrupo())
      const newSubgrupo = await callAPI('/subgrupos', 'POST', subgrupo)
      dispatch(loadingSubgrupoSuccess(newSubgrupo))
      return newSubgrupo
    } catch (e) {
      dispatch(loadingSubgrupoFailure(e))
      return false
    }
  }
}

export function updateSubgrupo (id, subgrupo) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingSubgrupo())
      const newSubgrupo = await callAPI(`/subgrupos/${id}`, 'PUT', subgrupo)
      dispatch(loadingSubgrupoSuccess(newSubgrupo))
      return newSubgrupo
    } catch (e) {
      dispatch(loadingSubgrupoFailure(e))
      return false
    }
  }
}

export function destroySubgrupo (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingSubgrupo())
      await callAPI(`/subgrupos/${id}`, 'DELETE')
      dispatch(destroyingSubgrupoSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingSubgrupoFailure(e))
      return false
    }
  }
}

export default function subgrupo (state = initialState, action) {
  switch (action.type) {

    case LOADING_SUBGRUPO_SUCCESS : {
      const { subgrupo } = action
      if (subgrupo.constructor === Array) {
        state = initialState
        subgrupo.forEach((singleSubgrupo) => (
          state = state.merge({[singleSubgrupo.id]: singleSubgrupo})
        ))
        return state
      }
      return state.merge({[subgrupo.id]: subgrupo})
    }

    case DESTROYING_SUBGRUPO_SUCCESS :
      return state.delete(action.subgrupoId.toString())

    default :
      return state
  }
}
