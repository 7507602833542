import { Map } from 'immutable'

export const multipleTipoMercado = Map({
  0: Map({
    id: 0,
    nome: 'Livre'
  }),
  1: Map({
    id: 1,
    nome: 'Cativo'
  }),
})
