import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'
import get from 'lodash.get'
import classnames from 'classnames'

import PrintableHeader from './PrintableHeader'

import DadosPersonalizadosChart from './Graficos/DadosPersonalizadosChart'
import Simulacoes from './Graficos/Simulacoes'
import TabelaUFER from './Graficos/TabelaUFER'
import ResumoAnual from './Graficos/ResumoAnual'

import Capa from './Capa'
import Custos from './Custos'
import Demanda from './Demanda'
import Consumo from './Consumo'

import AnaliseContainer from '../../containers/Relatorio/AnaliseContainer'

import { ViewModeProvider } from '../../containers/ViewModeContext/ViewModeContext'
import ViewModes from '../../utils/viewMode'

const RenderRelatorio = (props) => {
  const { relatorio, gestor, viewMode } = props
  const { data, impostos } = relatorio
  const print = viewMode === ViewModes.PRINT
  const showAnimations = !print

  const printableClasses = classnames(
    'relatorio',
    'printable',
    showAnimations ? 'show-animations' : 'no-animation',
  )

  const simulacoes = get(data, 'graficos.simulacoes')

  return (
    <ViewModeProvider value={{ viewMode, relatorio }}>
      <Grid padded className={printableClasses} style={{backgroundColor: '#fff'}}>
        <Grid.Row className="print-only printableHeader" verticalAlign='top'>
          <Grid.Column floated='right' width={16}>
            <PrintableHeader />
          </Grid.Column>
        </Grid.Row>

        <Capa
          gestor={gestor}
          impostos={impostos}
          cabecalho={get(data, 'cabecalho')}
          indicadores={get(data, 'indicadores')}
          graficos={get(data, 'graficos')}
        />

        <AnaliseContainer
          field='resumo'
          help='Introdução/Resumo'
        />

        <Custos relatorio={relatorio} graficos={get(data, 'graficos')} />

        <Demanda
          anual={get(data, 'graficos.demanda_anual')}
          historico={get(data, 'graficos.demandas')}
          analise={get(data, 'graficos.analise_demanda')}
        />

        <Consumo
          historico={get(data, 'graficos.consumos')}
          total={get(data, 'graficos.consumo_total')}
        />

        {simulacoes && (
          <Grid.Row className='shrink'>
            <Grid.Column width={16}>
              <Simulacoes data={simulacoes} />
            </Grid.Column>
          </Grid.Row>
        )}

        {false && (
          <Grid.Row className='shrink'>
            <Grid.Column width={16}>
              <DadosPersonalizadosChart
                header='Séries de Dados Personalizados'
                subheader='Indicadores de produção, etc'
                data={get(data, 'graficos.producao')}
              />
            </Grid.Column>
          </Grid.Row>
        )}

        {get(data, 'graficos.tabela_ufer') && (
          <Grid.Row className='shrink'>
            <Grid.Column width={16}>
              <AnaliseContainer resetable
                field='texto_reativos'
                help='Histórico - Excedentes de Reativos (12 meses)'
              />
            </Grid.Column>
            <Grid.Column width={16}>
              <TabelaUFER data={get(data, 'graficos.tabela_ufer')} />
            </Grid.Column>
          </Grid.Row>
        )}

        <Grid.Row className='shrink'>
          <Grid.Column width={16}>
            <ResumoAnual
              header='Histórico - Resumo Geral (12 meses)'
              enquadramento={get(data, 'cabecalho.enquadramento.modalidade')}
              data={get(data, 'graficos.resumo_anual')}
              showAnimations={showAnimations}
            />
          </Grid.Column>
        </Grid.Row>

        <AnaliseContainer
          field='conclusao'
          help='Conclusão do Relatório'
        />
      </Grid>
    </ViewModeProvider>
  )
}

RenderRelatorio.propTypes = {
  relatorio: PropTypes.object,
  gestor: PropTypes.object,
  print: PropTypes.bool,
  viewMode: PropTypes.symbol,
}

export default RenderRelatorio
