import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import RenderPld from './RenderPld'

const RenderForm = ({ fields, loading }) => {
  return (
    <Table.Body>
      {fields.map((pld, index) => (
        <RenderPld
          key={index}
          pld={pld}
          loading={loading}
        />
      ))}
    </Table.Body>
  )
}

RenderForm.propTypes = {
  fields: PropTypes.object,
  loading: PropTypes.bool,
}

export default RenderForm
