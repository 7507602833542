import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_COMERCIALIZADORA = 'LOADING_COMERCIALIZADORA'
export function loadingComercializadora () {
  return {
    type: LOADING_COMERCIALIZADORA,
  }
}

export const LOADING_COMERCIALIZADORA_SUCCESS = 'LOADING_COMERCIALIZADORA_SUCCESS'
export function loadingComercializadoraSuccess (comercializadora) {
  return {
    type: LOADING_COMERCIALIZADORA_SUCCESS,
    comercializadora,
  }
}

export const LOADING_COMERCIALIZADORA_FAILURE = 'LOADING_COMERCIALIZADORA_FAILURE'
export function loadingComercializadoraFailure (error) {
  return {
    type: LOADING_COMERCIALIZADORA_FAILURE,
    error,
  }
}

export const DESTROYING_COMERCIALIZADORA_SUCCESS = 'DESTROYING_COMERCIALIZADORA_SUCCESS'
export function destroyingComercializadoraSuccess (comercializadoraId) {
  return {
    type: DESTROYING_COMERCIALIZADORA_SUCCESS,
    comercializadoraId,
  }
}

const initialState = Map({})

export function fetchMultipleComercializadora () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingComercializadora())
      const multipleComercializadora = await callAPI('/comercializadoras')
      dispatch(loadingComercializadoraSuccess(multipleComercializadora))
      return multipleComercializadora
    } catch (e) {
      dispatch(loadingComercializadoraFailure(e))
      return false
    }
  }
}

export function fetchComercializadora (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingComercializadora())
      const comercializadora = await callAPI(`/comercializadoras/${id}`)
      dispatch(loadingComercializadoraSuccess(comercializadora))
      return comercializadora
    } catch (e) {
      dispatch(loadingComercializadoraFailure(e))
      return false
    }
  }
}

export function createComercializadora (comercializadora) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingComercializadora())
      const newComercializadora = await callAPI('/comercializadoras', 'POST', comercializadora)
      dispatch(loadingComercializadoraSuccess(newComercializadora))
      return newComercializadora
    } catch (e) {
      dispatch(loadingComercializadoraFailure(e))
      return false
    }
  }
}

export function updateComercializadora (id, comercializadora) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingComercializadora())
      const newComercializadora = await callAPI(`/comercializadoras/${id}`, 'PUT', comercializadora)
      dispatch(loadingComercializadoraSuccess(newComercializadora))
      return newComercializadora
    } catch (e) {
      dispatch(loadingComercializadoraFailure(e))
      return false
    }
  }
}

export function destroyComercializadora (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingComercializadora())
      await callAPI(`/comercializadoras/${id}`, 'DELETE')
      dispatch(destroyingComercializadoraSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingComercializadoraFailure(e))
      return false
    }
  }
}

export default function comercializadora (state = initialState, action) {
  switch (action.type) {

    case LOADING_COMERCIALIZADORA_SUCCESS : {
      const { comercializadora } = action
      if (comercializadora.constructor === Array) {
        state = initialState
        comercializadora.forEach((singleComercializadora) => (
          state = state.merge({[singleComercializadora.id]: singleComercializadora})
        ))
        return state
      }
      return state.merge({[comercializadora.id]: comercializadora})
    }

    case DESTROYING_COMERCIALIZADORA_SUCCESS :
      return state.delete(action.comercializadoraId.toString())

    default :
      return state
  }
}
