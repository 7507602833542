const chartColors = [
  '#EF4136', // red
  '#F36F21', // orange
  '#FDB813', // yellow
  '#0074D9', // blue
  '#66C18C', // green
  '#4BA89E', // cyan
  '#1B2C3E', // navy
  '#F38588', // pink
]

const COLORS = {
  DEFAULT:   '#586994',
  PRIMARY:   '#3FA7D6',
  SECONDARY: '#66C18C',
  BOLD:      '#FE9774',
  NEUTRAL:   '#595F72',

  LIGHT: {
    DEFAULT:   '#949FBA',
    PRIMARY:   '#84C7E4',
    SECONDARY: '#9DD7B5',
    BOLD:      '#FEBCA6',
    NEUTRAL:   '#D1D3D8',
  },

  DARK: {
    DEFAULT:   '#49567A',
    PRIMARY:   '#3489B0',
    SECONDARY: '#549E73',
    BOLD:      '#EE6352',
    NEUTRAL:   '#494E5E',
  },

  DARKER: {
    DEFAULT:   '#BCC1CE',
    PRIMARY:   '#B5D4E2',
    SECONDARY: '#C0DBCC',
    BOLD:      '#EDCFC4',
    NEUTRAL:   '#BCBEC4',
  },

  ELECTRIC: {
    BASE:      '#371126',
    GREEN:     '#00A978',
    PURPLE:    '#9E1F6C',
    BLUE:      '#3D97BA',
    YELLOW:    '#F7A823',
    RED:       '#EF4136',

    LIGHT: {
      BASE:      '#694d5c',
      GREEN:     '#40bf9a',
      PURPLE:    '#b65791',
      BLUE:      '#6eb1cb',
      YELLOW:    '#f9be5a',
      RED:       '#f37168',
    },

    LIGHTER: {
      BASE:      '#afa0a8',
      GREEN:     '#99ddc9',
      PURPLE:    '#d8a5c4',
      BLUE:      '#b1d5e3',
      YELLOW:    '#fcdca7',
      RED:       '#f9b3af',
    },

    DARK: {
      BASE:      '#694d5c',
      GREEN:     '#007e35',
      PURPLE:    '#6e0023',
      BLUE:      '#006398',
      YELLOW:    '#f37d00',
      RED:       '#e70000',
    },
  }
}

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

const formatter = (
  decimals = 0,
  unit,
  unitPrefix = true,
  multiplier = 1,
) => value => {

  let numberFormatted = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals }).format(value * multiplier)

  if (unit) {
    numberFormatted = unitPrefix ? `${unit}${numberFormatted}` : `${numberFormatted}${unit}`
  }

  return numberFormatted
}

// 12345.678 => 12.345,68
const formatAsLocalizedNumberWithDecimals = (decimals = 2) => formatter(decimals)
// 12345.678 => 12.345,68
const formatAsLocalizedNumber = formatter(2)
// 12345.678 => 12.345
const formatAsLocalizedInteger = formatter(0)
// 12345.678 => R$ 12.345,68
const formatAsCurrency = formatter(2, 'R$ ')
// 12345.678 => R$ 12.346
const formatAsIndCurrency = formatter(0, 'R$ ')
// 0.123 => 12,30%
const formatAsPercentage = formatter(2, '%', false, 100)
// 0.123 => 12%
const formatAsRoundedPercentage = formatter(0, '%', false, 100)
// 12.34 => 12,34%
const formatAsPercentageCalculated = formatter(2, '%', false)
// 12.34 => 12%
const formatAsRoundedPercentageCalculated = formatter(0, '%', false)
// 1234.56 => 1.234 kW
const formatAsKW = formatter(0, ' kW', false)
// 1234.56 => 1.234 kWh
const formatAsKWH = formatter(0, ' kWh', false)
// 1234.56 => 1.234,56 kW
const formatAsUnit = (unit) => formatter(2, ` ${unit}`, false)
// 1234.56 => 1.234,56 kW
const formatAsUnitDecimals = (unit, decimals) => formatter(decimals, ` ${unit}`, false)
// 1234.56 => 1.234 kW
const formatAsIndicador = (unit, unitPrefix = false) => formatter(0, unit, unitPrefix)

export { capitalize }
export { chartColors }
export { COLORS }
export { formatAsCurrency }
export { formatAsIndCurrency }
export { formatAsIndicador }
export { formatAsKW }
export { formatAsKWH }
export { formatAsLocalizedInteger }
export { formatAsLocalizedNumber }
export { formatAsLocalizedNumberWithDecimals }
export { formatAsPercentage }
export { formatAsPercentageCalculated }
export { formatAsRoundedPercentage }
export { formatAsRoundedPercentageCalculated }
export { formatAsUnit }
export { formatAsUnitDecimals }
export { formatter }
