import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as contratoDemandaInstalacaoActionCreators from '../../redux/modules/contratoDemandaInstalacao'
import * as contratoDemandaInstalacaoSelectionActionCreators from '../../redux/modules/contratoDemandaInstalacaoSelection'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { ContratoDemandaInstalacao } from '../../components/ContratoDemandaInstalacao'
import { arrayToCSV } from '../../utils/arrayToCSV'

class ContratoDemandaInstalacaoContainer extends Component {

  static propTypes = {
    instalacao: PropTypes.object.isRequired,
    history: PropTypes.object,

    multipleContratoDemandaInstalacao: PropTypes.object,
    fetchMultipleContratoDemandaInstalacao: PropTypes.func,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,

    isLoading: PropTypes.bool,

    updateContratoDemandaInstalacaoSelection: PropTypes.func,

    year: PropTypes.number,
    years: PropTypes.array,
  }

  async componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate (prevProps) {
    const { year } = this.props
    if (prevProps.year !== year) {
      this.fetchData()
    }
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  fetchData = async () => {
    await this.props.fetchMultipleContratoDemandaInstalacao(
      this.props.instalacao.id,
      this.props.year,
    )
  }

  handleChange = (event) => {
    this.props.updateContratoDemandaInstalacaoSelection(
      event.target.name,
      event.target.value,
    )
  }

  handleEditResource = () => {
    let instalacaoId = this.props.instalacao.id
    this.props.history.push(`/instalacoes/${instalacaoId}/contratos_demanda/edit`)
  }

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleContratoDemandaInstalacao.toList().toJSON(),
      'contratoDemandaInstalacao',
      ['ano', 'mes', 'instalacao.nome', 'valor_p', 'valor_fp', 'valor_na']
    )
  }

  render () {
    return (
      <ContratoDemandaInstalacao
        year={this.props.year}
        years={this.props.years}
        isLoading={this.props.isLoading}
        multipleContratoDemandaInstalacao={this.props.multipleContratoDemandaInstalacao}
        onChange={this.handleChange}
        onEditResource={this.handleEditResource}
        onExportToCSV={this.handleExportToCSV}
      />
    )
  }
}

function mapStateToProps ({ contratoDemandaInstalacao, contratoDemandaInstalacaoSelection, status, flashMessage }) {
  return {
    multipleContratoDemandaInstalacao: contratoDemandaInstalacao,
    error: status.getIn(['contratoDemandaInstalacao', 'error']),
    isLoading: status.getIn(['contratoDemandaInstalacao', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),

    year: contratoDemandaInstalacaoSelection.get('year'),
    years: contratoDemandaInstalacaoSelection.get('years'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...contratoDemandaInstalacaoActionCreators,
    ...contratoDemandaInstalacaoSelectionActionCreators,
    ...flashMessageAcionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContratoDemandaInstalacaoContainer))
