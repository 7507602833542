import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ServicoInstalacao } from '../../components'
import * as servicoInstalacaoActionCreators from '../../redux/modules/servicoInstalacao'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class ServicoInstalacaoContainer extends Component {

  static propTypes = {
    instalacao: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,

    multipleServicoInstalacao: PropTypes.object.isRequired,
    fetchMultipleServicoInstalacao: PropTypes.func.isRequired,
    destroyServicoInstalacao: PropTypes.func.isRequired,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,

    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    const { instalacao } = this.props
    await this.props.fetchMultipleServicoInstalacao(instalacao.id)
  }

  handleRedirectToNew = (instalacaoId) => {
    this.props.history.push(`/instalacoes/${instalacaoId}/servicos/new`)
  }

  handleEditResource = (instalacaoId, id) => {
    this.props.history.push(`/instalacoes/${instalacaoId}/servicos/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyServicoInstalacao(id)
      ? this.props.showSuccessfulFlashMessage('', 'destroy')
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  handleSort = column => () => this.props.updateSort('servicoInstalacao', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('servicoInstalacao', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleServicoInstalacao.toList().toJSON(),
      'servicoInstalacao',
      [
        'id',
        'instalacao.nome',
        'servico.nome',
        'executado_em',
        'descricao',
      ],
    )
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <ServicoInstalacao
        instalacao={this.props.instalacao}
        multipleServicoInstalacao={this.props.multipleServicoInstalacao}
        onRedirectToNew={() => this.handleRedirectToNew(this.props.instalacao.id)}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV}
        isLoading={this.props.isLoading}
      />
    )
  }
}

function mapStateToProps ({ servicoInstalacao, status, sort, filter }, {instalacao}) {
  const sortColumn = sort.getIn(['servicoInstalacao', 'sortColumn']) || 'executado_em'
  const sortDirection = sort.getIn(['servicoInstalacao', 'sortDirection']) || 'ascending'
  const filterText = filter.getIn(['servicoInstalacao', 'filterText']) || ''

  let map = filterMap(servicoInstalacao, ['servico.nome', 'executado_em', 'descricao'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleServicoInstalacao: map.filter(t => t.get('instalacao_id') === instalacao.id),
    error: status.getIn(['servicoInstalacao', 'error']),
    isLoading: status.getIn(['servicoInstalacao', 'isLoading']),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...servicoInstalacaoActionCreators,
    ...flashMessageActionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ServicoInstalacaoContainer))
