import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form/immutable'
import { Button, Form, FormField, Grid, Icon } from 'semantic-ui-react'
import { SemanticInput, SemanticInputMask } from '../FormComponents'
import { required } from '../../utils/validations'
import { maskFloat } from '../../utils/masks'

const RenderExtrasPedagio = ({fields, loading }) => {
  return (
    <React.Fragment>
      <Grid.Row columns={1}>
        <Grid.Column className='customHeaderColumn'>
          <Button color='yellow' size='tiny' floated='right' icon
            onClick={() => fields.push()}>
            <Icon name='plus' />{' '} Adicionar Extra
          </Button>
        </Grid.Column>
      </Grid.Row>

      {fields.map((extraPedagio, index) => (
        <Grid.Row columns='2' key={index}>
          <Grid.Column>
            <FormField>
              <label htmlFor={`fatura_pedagio.${extraPedagio}.descricao`}>Descrição</label>
              <Field floated='left'
                name={`${extraPedagio}.descricao`}
                validate={[required]}
                component={SemanticInput}
                loading={loading}
              />
            </FormField>
          </Grid.Column>
          <Grid.Column>
            <Form.Group>
              <FormField style={{width: '100%'}}>
                <label htmlFor={`fatura_pedagio.${extraPedagio}.valor`}>Valor</label>
                <Field
                  name={`${extraPedagio}.valor`}
                  label='R$'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={loading}
                  mask={maskFloat.mask(2, true)}
                  placeholder={maskFloat.placeholder}
                />
              </FormField>
              <Form.Button label='&nbsp;' type='button' tabIndex='-1'
                floated='right' icon onClick={() => fields.remove(index)}>
                <Icon name='delete' />
              </Form.Button>
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
      ))}
    </React.Fragment>
  )
}

RenderExtrasPedagio.propTypes = {
  fields: PropTypes.object,
  loading: PropTypes.bool,
}

export default RenderExtrasPedagio
