import React from 'react'
import PropTypes from 'prop-types'
import { Card, List, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { CRUDIndex } from '../CRUD'

const Cliente = (props) => (
  <CRUDIndex
    title={props.title}
    icon='building outline'
    onRedirectToNew={props.onRedirectToNew}
    onExportToCSV={props.onExportToCSV}
    filterText={props.filterText}
    handleFilter={props.handleFilter}
  >
    <Loader inline='centered' active={props.isLoading} />

    <Card.Group style={{ marginTop: '0.5em' }}>
      {props.multipleCliente.valueSeq().map((empresa, index) => (
        <Card
          key={index}
          as={Link}
          to={`/${props.listPath}/${empresa.get('id')}`}
        >
          <Card.Content>
            <Card.Header>
              {empresa.get('sigla')}
            </Card.Header>
            <Card.Meta>
              {empresa.get('nome')}
            </Card.Meta>
            <Card.Description>
              <List>
                {empresa.get('cnpj') && (
                  <List.Item>
                    <List.Icon name='id card'/>
                    <List.Content>
                      <List.Header>CNPJ</List.Header>
                      <List.Description>{empresa.get('cnpj')}</List.Description>
                    </List.Content>
                  </List.Item>
                )}
              </List>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <List>
              <List.Item icon='marker' content={empresa.getIn(['uf', 'nome'])} />
            </List>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  </CRUDIndex>
)

Cliente.propTypes = {
  title: PropTypes.string.isRequired,
  listPath: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,

  onRedirectToNew: PropTypes.func,
  multipleCliente: PropTypes.object.isRequired,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
}

export default Cliente
