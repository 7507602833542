import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import { Link } from 'react-router-dom'
import { CRUDShow } from '../CRUD'
import { Grid, Icon, Menu, Loader } from 'semantic-ui-react'
import { Informacoes, Instalacoes, Contatos } from './panes'

const ClienteShow = (props) => {
  const { cliente, uf, pane } = props

  if (!cliente || !uf) return (
    <Loader active={props.isLoading}>Carregando...</Loader>
  )

  return (
    <CRUDShow noToolbar noMessage
      title={cliente.nome}
      icon='building outline'>
      <Grid>
        <Grid.Column width={3}>
          <Menu secondary vertical fluid size='huge'>
            <Menu.Item
              active={pane === 'informacoes'}
              as={Link}
              to={`/clientes/${cliente.id}`}
              key='cliente'>
              <Icon name='building outline' />
              Informações
            </Menu.Item>
            <Menu.Item
              active={pane === 'instalacoes'}
              as={Link}
              to={`/clientes/${cliente.id}/instalacoes`}
              key='instalacoes'>
              <Icon name='factory' />
              Instalações
            </Menu.Item>
            <Menu.Item
              active={pane === 'contatos'}
              as={Link}
              to={`/clientes/${cliente.id}/contatos`}
              key='contatos'>
              <Icon name='users' />
              Contatos
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column width={13}>
          <Switch>
            <Route
              exact path='/clientes/:id'
              render={() => (
                <Informacoes
                  cliente={cliente}
                  uf={uf}
                  onEditResource={props.onEditResource}
                  onDeleteResource={props.onDeleteResource}
                />
              )}
            />
            <Route
              exact path='/clientes/:id/instalacoes/:paneId?/:action?'
              render={() => (
                <Instalacoes
                  cliente={cliente} />
              )}
            />
            <Route
              exact path='/clientes/:id/contatos/:paneId?/:action?'
              render={() => (
                <Contatos
                  cliente={cliente} />
              )}
            />
          </Switch>
        </Grid.Column>
      </Grid>
    </CRUDShow>
  )
}

ClienteShow.propTypes = {
  cliente: PropTypes.object,
  uf: PropTypes.object,
  pane: PropTypes.string,
  isLoading: PropTypes.bool,
  onEditResource: PropTypes.func.isRequired,
  onDeleteResource: PropTypes.func.isRequired,
}

export default ClienteShow
