import React from 'react'
import PropTypes from 'prop-types'
import { Card, Checkbox, Dropdown, Grid, Icon, Label, Loader, Popup } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { CRUDIndex } from '../CRUD'
import Atrasada from './Atrasada'

const mercados = [
  {
    key: 'ambos',
    value: 'ambos',
    text: 'Ambos mercados',
  },
  {
    key: 'cativo',
    value: 'cativo',
    text: 'Apenas Cativo',
  },
  {
    key: 'livre',
    value: 'livre',
    text: 'Apenas Livre',
  },
]

const Instalacao = (props) => {
  return (
    <CRUDIndex
      noTitle={props.noTitle ? props.noTitle : undefined}
      padded={props.noPadded ? false : true}
      title={props.title}
      icon='factory'
      onRedirectToNew={props.onRedirectToNew}
      onExportToCSV={props.onExportToCSV}
      filterText={props.filterText}
      handleFilter={props.handleFilter}
      customSearchItems={[
        (
          <Dropdown selection
            key='tipo_mercado'
            options={mercados}
            value={props.tipoMercado}
            onChange={(e, data) => props.handleFilter('tipoMercado', data.value)} />
        ),
        (
          <Checkbox
            key='exibir_apenas_minhas_instalacoes'
            label='Apenas minhas instalações'
            checked={props.filterCheckbox}
            onChange={(e, { checked }) => props.handleFilter('checked', checked)} />
        ),
        (
          <Checkbox
            key='apenas_ativos'
            label='Apenas ativos'
            checked={props.apenasAtivos}
            onChange={(e, { checked }) => props.handleFilter('apenasAtivos', checked)} />
        ),
      ]}>

      <Loader inline='centered' active={props.isLoading} />

      <Card.Group style={{ marginTop: '0.5em' }}>
        {props.multipleInstalacao.valueSeq().map((empresa, index) => {
          let labelColor = ''

          switch (empresa.get('modalidade')) {
            case 'Azul':
              labelColor = 'blue'
              break
            case 'Verde':
              labelColor = 'green'
              break
            case 'Convencional':
              labelColor = 'brown'
              break
            default:
              labelColor = ''
          }

          const atrasada = empresa.get('atrasada') ? empresa.get('atrasada').toJS() : {
            atrasada: false,
            ano: null,
            mes: null,
            data: null,
          }

          return (
            <Card
              className={empresa.get('ativo') ? '' : 'egrid disabled'}
              key={index}
              as={Link}
              to={`/${props.listPath}/${empresa.get('id')}`}
            >
              <Card.Content>
                {empresa.get('invalidas') ? (
                  <Label color='red' floating>
                    {empresa.get('invalidas')}
                  </Label>
                ) : null}
                <Card.Header style={{ textOverflow: 'ellipsis', overflowX: 'hidden' }}>
                  {empresa.get('apelido')}
                </Card.Header>
                <Card.Meta>
                  {empresa.get('nome')}
                </Card.Meta>
                <Card.Description>
                  <Grid className='definitionList'>
                    <Grid.Row>
                      <Grid.Column width={5} className='header'>Cliente</Grid.Column>
                      <Grid.Column width={11}>{empresa.getIn(['cliente', 'sigla'])}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5} className='header'>NUC</Grid.Column>
                      <Grid.Column width={11}>{empresa.get('nuc')}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5} className='header'>Mercado</Grid.Column>
                      <Grid.Column width={11}>{empresa.get('tipo_mercado')}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={5} className='header'>CNPJ</Grid.Column>
                      <Grid.Column width={11}>{empresa.get('cnpj')}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        {!empresa.get('ativo') && (
                          <Popup wide='very' position='top right' flowing hoverable
                            trigger={<Label fluid size='medium' color='red'>INATIVO</Label>}>
                            A instalação encontra-se inativa.
                          </Popup>
                        )}
                        <Atrasada {...atrasada} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <Grid>
                  <Grid.Column floated='left' width={8}>
                    <Label size='medium'>
                      <Icon name='marker' />
                      {empresa.getIn(['uf', 'sigla'])}
                    </Label>
                  </Grid.Column>
                  <Grid.Column floated='right' width={8} textAlign='right'>
                    {empresa.get('modalidade') && (
                      <Label size='medium' color={labelColor}>
                        {empresa.get('modalidade')}
                        <Label.Detail>{empresa.get('subgrupo')}</Label.Detail>
                      </Label>
                    )}
                  </Grid.Column>
                </Grid>
              </Card.Content>
            </Card>
          )
        })}
      </Card.Group>
    </CRUDIndex>
  )
}

Instalacao.propTypes = {
  title: PropTypes.string.isRequired,
  noTitle: PropTypes.bool,
  noPadded: PropTypes.bool,
  listPath: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,

  onRedirectToNew: PropTypes.func,
  multipleInstalacao: PropTypes.object.isRequired,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  filterCheckbox: PropTypes.bool,
  apenasAtivos: PropTypes.bool,
  tipoMercado: PropTypes.bool,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
}

export default Instalacao
