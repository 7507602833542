import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_FATURA_ENERGIA = 'LOADING_FATURA_ENERGIA'
export function loadingFaturaEnergia () {
  return {
    type: LOADING_FATURA_ENERGIA,
  }
}

export const LOADING_FATURA_ENERGIA_SUCCESS = 'LOADING_FATURA_ENERGIA_SUCCESS'
export function loadingFaturaEnergiaSuccess (faturaEnergia) {
  return {
    type: LOADING_FATURA_ENERGIA_SUCCESS,
    faturaEnergia,
  }
}

export const LOADING_FATURA_ENERGIA_FAILURE = 'LOADING_FATURA_ENERGIA_FAILURE'
export function loadingFaturaEnergiaFailure (error) {
  return {
    type: LOADING_FATURA_ENERGIA_FAILURE,
    error,
  }
}

export const DESTROYING_FATURA_ENERGIA_SUCCESS = 'DESTROYING_FATURA_ENERGIA_SUCCESS'
export function destroyingFaturaEnergiaSuccess (faturaEnergiaId) {
  return {
    type: DESTROYING_FATURA_ENERGIA_SUCCESS,
    faturaEnergiaId,
  }
}

const initialState = Map({})

export function fetchMultipleFaturaEnergia () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFaturaEnergia())
      const multipleFaturaEnergia = await callAPI('/faturas_energia')
      dispatch(loadingFaturaEnergiaSuccess(multipleFaturaEnergia))
      return multipleFaturaEnergia
    } catch (e) {
      dispatch(loadingFaturaEnergiaFailure(e))
      return false
    }
  }
}

export function fetchFaturaEnergia (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFaturaEnergia())
      const faturaEnergia = await callAPI(`/faturas_energia/${id}`)
      dispatch(loadingFaturaEnergiaSuccess(faturaEnergia))
      return faturaEnergia
    } catch (e) {
      dispatch(loadingFaturaEnergiaFailure(e))
      return false
    }
  }
}

export function createFaturaEnergia (faturaEnergia) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFaturaEnergia())
      const newFaturaEnergia = await callAPI('/faturas_energia', 'POST', faturaEnergia)
      dispatch(loadingFaturaEnergiaSuccess(newFaturaEnergia))
      return newFaturaEnergia
    } catch (e) {
      dispatch(loadingFaturaEnergiaFailure(e))
      return false
    }
  }
}

export function updateFaturaEnergia (id, faturaEnergia) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFaturaEnergia())
      const newFaturaEnergia = await callAPI(`/faturas_energia/${id}`, 'PUT', faturaEnergia)
      dispatch(loadingFaturaEnergiaSuccess(newFaturaEnergia))
      return newFaturaEnergia
    } catch (e) {
      dispatch(loadingFaturaEnergiaFailure(e))
      return false
    }
  }
}

export function destroyFaturaEnergia (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFaturaEnergia())
      await callAPI(`/faturas_energia/${id}`, 'DELETE')
      dispatch(destroyingFaturaEnergiaSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingFaturaEnergiaFailure(e))
      return false
    }
  }
}

export default function faturaEnergia (state = initialState, action) {
  switch (action.type) {

    case LOADING_FATURA_ENERGIA_SUCCESS : {
      const { faturaEnergia } = action
      if (faturaEnergia.constructor === Array) {
        state = initialState
        faturaEnergia.forEach((singleFaturaEnergia) => (
          state = state.merge({[singleFaturaEnergia.id]: singleFaturaEnergia})
        ))
        return state
      }
      return state.merge({[faturaEnergia.id]: faturaEnergia})
    }

    case DESTROYING_FATURA_ENERGIA_SUCCESS :
      return state.delete(action.faturaEnergiaId.toString())

    default :
      return state
  }
}
