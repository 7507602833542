import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Grid, Header, Label, List, Segment } from 'semantic-ui-react'
import { CRUDShow } from '../CRUD'
import { formatDate } from '../../utils/formats'
import { maskPercent, maskFloat } from '../../utils/masks'

const TIPO_MERCADO_LIVRE = 0
const thumbs = (up) => `thumbs ${up ? 'up' : 'down'}`
const thumbColor = (up) => up ? 'green' : 'grey'

const CenarioShow = (props) => {
  if (!props.cenario) return (null)

  const { cenario } = props
  const { parametro_calculo } = cenario
  if (cenario) {
    return (
      <CRUDShow
        title={'Detalhe de Cenário'}
        titleAs={'h3'}
        icon='cogs'
        padded={false}
        isLoading={props.isLoading}
        onEditResource={() => props.onEditResource(props.cenario.id)}
        onDeleteResource={() => props.onDeleteResource(props.cenario.id)}>
        <Header as='h2' block content={props.cenario.nome} subheader={props.cenario.descricao} />
        <Segment className='customSegment'>
          <Grid widths='equal'>
            <Grid.Row columns={6}>
              <Grid.Column textAlign='right'><strong>Enquadramento</strong></Grid.Column>
              <Grid.Column>
                <Label as={Link} to={`/enquadramentos/${parametro_calculo.enquadramento.id}`}>
                  {parametro_calculo.enquadramento.modalidade}
                </Label>
              </Grid.Column>
              <Grid.Column textAlign='right'><strong>Subgrupo</strong></Grid.Column>
              <Grid.Column>
                <Label as={Link} to={`/subgrupos/${parametro_calculo.subgrupo.id}`}>
                  {parametro_calculo.subgrupo.nome}
                </Label>
              </Grid.Column>
              <Grid.Column textAlign='right'><strong>Classe de Consumo</strong></Grid.Column>
              <Grid.Column>
                <Label as={Link} to={`/classes_consumo/${parametro_calculo.classe_consumo.id}`}>
                  {parametro_calculo.classe_consumo.nome}
                </Label>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={6}>
              <Grid.Column textAlign='right'><strong>Concessionária</strong></Grid.Column>
              <Grid.Column>
                <Label as={Link} to={`/concessionarias/${parametro_calculo.concessionaria.id}`}>
                  {parametro_calculo.concessionaria.sigla}
                </Label>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment vertical>
          <Grid divided columns={3}>
            <Grid.Column>
              <List relaxed size='small' className='customList'>
                <List.Item>
                  <List.Content>
                    <List.Header>ICMS</List.Header>
                    <List.Description>{maskPercent.parse(parametro_calculo.icms)}%</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>ICMS Reduzido</List.Header>
                    <List.Description>{maskPercent.parse(parametro_calculo.icms_reduzido)}%</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>Crédito de ICMS</List.Header>
                    <List.Description>{maskPercent.parse(parametro_calculo.icms_credito)}%</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List relaxed size='small' className='customList'>
                <List.Item>
                  <List.Icon name={thumbs(parametro_calculo.liminar_demanda)} color={thumbColor(parametro_calculo.liminar_demanda)} size='large' />
                  <List.Content>
                    <List.Header>Liminar de Demanda</List.Header>
                    <List.Description>A instalação {parametro_calculo.liminar_demanda ? '' : 'não '}possui liminar de demanda.</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name={thumbs(parametro_calculo.devec)} color={thumbColor(parametro_calculo.devec)} size='large' />
                  <List.Content>
                    <List.Header>DEVEC/Substituição Tributária</List.Header>
                    <List.Description>A instalação {parametro_calculo.devec ? '' : 'não '}possui DEVEC/Substituição Tributária.</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name={thumbs(parametro_calculo.possui_gerador)} color={thumbColor(parametro_calculo.possui_gerador)} size='large' />
                  <List.Content>
                    <List.Header>Gerador</List.Header>
                    <List.Description>A instalação {parametro_calculo.possui_gerador ? '' : 'não '}possui gerador(es).</List.Description>
                    {parametro_calculo.possui_gerador && (
                      <List.List>
                        <List.Item>
                          <List.Content>
                            <List.Header>
                              <div className='labelListWrapper'>
                                <div className='labelWrapper'>
                                  <Label as={Link} to={`/geradores/${parametro_calculo.gerador.id}`}>
                                    {`${parametro_calculo.gerador.tipo} - ${parametro_calculo.gerador.marca} - ${parametro_calculo.gerador.modelo} - ${maskFloat.parse(parametro_calculo.gerador.potencia)} kVA`}
                                  </Label>
                                </div>
                              </div>
                            </List.Header>
                            <List.Description>{parametro_calculo.geradores} unidade{parametro_calculo.geradores > 1 ? 's' : ''}</List.Description>
                          </List.Content>
                        </List.Item>
                      </List.List>
                    )}
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name={thumbs(parametro_calculo.geracao_distribuida)} color={thumbColor(parametro_calculo.geracao_distribuida)} size='large' />
                  <List.Content>
                    <List.Header>Geração Distribuída</List.Header>
                    <List.Description>A instalação {parametro_calculo.geracao_distribuida ? '' : 'não '}possui geração distribuída.</List.Description>
                    {parametro_calculo.geracao_distribuida && (
                      <List.List>
                        <List.Item>
                          <List.Icon name='calendar' />
                          <List.Content>
                            <List.Header>
                              {formatDate(parametro_calculo.data_inicio_geracao_distribuida)}
                            </List.Header>
                            <List.Description>Data de Início (GD)</List.Description>
                          </List.Content>
                        </List.Item>
                      </List.List>
                    )}
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name={thumbs(parametro_calculo.possui_contrato_demanda)} color={thumbColor(parametro_calculo.possui_contrato_demanda)} size='large' />
                  <List.Content>
                    <List.Header>Contrato de Demanda</List.Header>
                    <List.Description>O cenário possui uma especificação de contrato de demanda.</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Header as='h3' block content={`Mercado ${parametro_calculo.tipo_mercado === TIPO_MERCADO_LIVRE ? 'Livre ' : 'Cativo'}`} subheader='Tipo de Mercado' />
              {parametro_calculo.tipo_mercado === TIPO_MERCADO_LIVRE && (
                <List relaxed size='small' className='customList'>
                  <List.Item>
                    <List.Icon name='info' />
                    <List.Content>
                      <List.Header>Dados do Mercado Livre</List.Header>
                      <List.List>
                        <List.Item>
                          <List.Icon name='calendar' />
                          <List.Content>
                            <List.Header>Data de Migração</List.Header>
                            <List.Description>{formatDate(parametro_calculo.data_migracao_livre)} </List.Description>
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Icon name='info' />
                          <List.Content>
                            <List.Header>Código CCEE</List.Header>
                            <List.Description>{parametro_calculo.codigo_ccee}</List.Description>
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Icon name='info' />
                          <List.Content>
                            <List.Header>Sigla CCEE</List.Header>
                            <List.Description>{parametro_calculo.sigla_ccee}</List.Description>
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Icon name='info' />
                          <List.Content>
                            <List.Header>Ponto de Medição CCEE</List.Header>
                            <List.Description>{parametro_calculo.ponto_medicao_ccee}</List.Description>
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Icon name='shopping cart' />
                          <List.Content>
                            <List.Header>Comercializadoras</List.Header>
                            <List.Description>
                              <div className='labelListWrapper'>
                                {parametro_calculo.comercializadoras.map((comercializadora, index) => (
                                  <div key={index} className='labelWrapper'>
                                    <Label key={index} as={Link} to={`/comercializadoras/${comercializadora.id}`}>
                                      {comercializadora.sigla}
                                    </Label>
                                  </div>
                                ))}
                              </div>
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      </List.List>
                    </List.Content>
                  </List.Item>
                </List>
              )}
            </Grid.Column>
          </Grid>
        </Segment>
      </CRUDShow>
    )
  }
}

CenarioShow.propTypes = {
  cenario: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default CenarioShow
