import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_USER = 'LOADING_USER'
export function loadingUser () {
  return {
    type: LOADING_USER,
  }
}

export const LOADING_USER_SUCCESS = 'LOADING_USER_SUCCESS'
export function loadingUserSuccess (user) {
  return {
    type: LOADING_USER_SUCCESS,
    user,
  }
}

export const LOADING_USER_FAILURE = 'LOADING_USER_FAILURE'
export function loadingUserFailure (error) {
  return {
    type: LOADING_USER_FAILURE,
    error,
  }
}

export const DESTROYING_USER_SUCCESS = 'DESTROYING_USER_SUCCESS'
export function destroyingUserSuccess (userId) {
  return {
    type: DESTROYING_USER_SUCCESS,
    userId,
  }
}

const initialState = Map({})

export function fetchMultipleUser (withRole = '') {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingUser())
      const multipleUser = await callAPI(`/users?with_role=${withRole}`)
      dispatch(loadingUserSuccess(multipleUser))
      return multipleUser
    } catch (e) {
      dispatch(loadingUserFailure(e))
      return false
    }
  }
}

export function fetchUser (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingUser())
      const user = await callAPI(`/users/${id}`)
      dispatch(loadingUserSuccess(user))
      return user
    } catch (e) {
      dispatch(loadingUserFailure(e))
      return false
    }
  }
}

export function createUser (user) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingUser())
      const newUser = await callAPI('/users', 'POST', user)
      dispatch(loadingUserSuccess(newUser))
      return newUser
    } catch (e) {
      dispatch(loadingUserFailure(e))
      return false
    }
  }
}

export function updateUser (id, user) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingUser())
      const newUser = await callAPI(`/users/${id}`, 'PUT', user)
      dispatch(loadingUserSuccess(newUser))
      return newUser
    } catch (e) {
      dispatch(loadingUserFailure(e))
      return false
    }
  }
}

export function destroyUser (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingUser())
      await callAPI(`/users/${id}`, 'DELETE')
      dispatch(destroyingUserSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingUserFailure(e))
      return false
    }
  }
}

export default function user (state = initialState, action) {
  switch (action.type) {

    case LOADING_USER_SUCCESS : {
      const { user } = action
      if (user.constructor === Array) {
        state = initialState
        user.forEach(t => (
          state = state.merge({[t.id]: t})
        ))
        return state
      }
      return state.merge({[user.id]: user})
    }

    case DESTROYING_USER_SUCCESS :
      return state.delete(action.userId.toString())

    default :
      return state
  }
}
