import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Message } from 'semantic-ui-react'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

class FlashMessageContainer extends Component {

  static propTypes = {
    hideFlashMessage: PropTypes.func,
    operation: PropTypes.string,
    error: PropTypes.bool,
    errorList: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    isVisible: PropTypes.bool,
  }

  handleDismiss = () => {
    this.props.hideFlashMessage()
  }

  render() {
    const { operation, error, errorList, isVisible } = this.props

    const messageData = {
      success: {
        create: 'Registro criado com sucesso.',
        update: 'Registro atualizado com sucesso.',
        destroy: 'Registro excluído com sucesso.',
        calculate: 'Fatura calculada com sucesso.',
        simulate: 'Cenários simulados com sucesso.',
      },

      error: {
        create: 'Não foi possível criar o registro.',
        update: 'Não foi possível atualizar o registro.',
        destroy: 'Não foi possível excluir o registro.',
        calculate: 'Não foi possível calcular a fatura.',
        simulate: 'Não foi possível simular os cenários.',
      }
    }

    if (isVisible) {
      let header = messageData[error ? 'error' : 'success'][operation]
      let errorMsg = []

      if (errorList && errorList.size > 0) {
        header += errorList.size === 1 ? ' O seguinte erro ocorreu:' : ' Os seguintes erros ocorreram:'
        errorMsg = errorList.toArray()
      }

      return (
        <Message
          style={{ marginTop: '1em' }}
          onDismiss={this.handleDismiss}
          positive={!error}
          negative={error}
          header={header}
          icon={error ? 'ban' : 'check'}
          list={errorMsg}
        />
      )
    }

    return (null)
  }
}

function mapStateToProps ({flashMessage}) {
  return {
    isVisible: flashMessage.get('isVisible'),
    operation: flashMessage.get('operation'),
    msg: flashMessage.get('msg'),
    error: flashMessage.get('isError'),
    errorList: flashMessage.get('errorList'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(flashMessageAcionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessageContainer)
