import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ConcessionariaShow } from '../../components'
import * as concessionariaActionCreators from '../../redux/modules/concessionaria'
import * as ufActionCreators from '../../redux/modules/uf'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

class ConcessionariaShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,

    fetchConcessionaria: PropTypes.func.isRequired,
    destroyConcessionaria: PropTypes.func.isRequired,

    fetchUf: PropTypes.func.isRequired,

    hideFlashMessage: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
  }

  state = {
    concessionaria: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const concessionaria = await this.props.fetchConcessionaria(this.props.id)
      const uf = await this.props.fetchUf(concessionaria.uf_id)
      this.setState({ concessionaria, uf })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/concessionarias/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/concessionarias/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyConcessionaria(id)
    this.props.history.push('/concessionarias')
  }

  getPane = (match) => {
    if (match.params.pane) return match.params.pane
    return 'informacoes'
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <ConcessionariaShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        concessionaria={this.state.concessionaria}
        uf={this.state.uf}
        pane={this.getPane(this.props.match)}
        isLoading={this.props.isLoading}
      />
    )
  }
}

function mapStateToProps ({concessionaria, uf, status}, {match}) {
  const { id } = match.params
  const isLoading = status.getIn(['concessionaria', 'isLoading']) || status.getIn(['uf', 'isLoading'])

  if (id) {
    return {
      id,
      isLoading,
      concessionaria: concessionaria.get('id'),
      uf: uf.get('id'),
    }
  }

  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...concessionariaActionCreators,
    ...ufActionCreators,
    ...flashMessageAcionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConcessionariaShowContainer))
