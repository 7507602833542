import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_CONTATO_CLIENTE = 'LOADING_CONTATO_CLIENTE'
export function loadingContatoCliente () {
  return {
    type: LOADING_CONTATO_CLIENTE,
  }
}

export const LOADING_CONTATO_CLIENTE_SUCCESS = 'LOADING_CONTATO_CLIENTE_SUCCESS'
export function loadingContatoClienteSuccess (contatoCliente) {
  return {
    type: LOADING_CONTATO_CLIENTE_SUCCESS,
    contatoCliente,
  }
}

export const LOADING_CONTATO_CLIENTE_FAILURE = 'LOADING_CONTATO_CLIENTE_FAILURE'
export function loadingContatoClienteFailure (error) {
  return {
    type: LOADING_CONTATO_CLIENTE_FAILURE,
    error,
  }
}

export const DESTROYING_CONTATO_CLIENTE_SUCCESS = 'DESTROYING_CONTATO_CLIENTE_SUCCESS'
export function destroyingContatoClienteSuccess (contatoClienteId) {
  return {
    type: DESTROYING_CONTATO_CLIENTE_SUCCESS,
    contatoClienteId,
  }
}

const initialState = Map({})

export function fetchMultipleContatoCliente (clienteId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoCliente())
      const multipleContatoCliente = await callAPI(`/clientes/${clienteId}/contatos`)
      dispatch(loadingContatoClienteSuccess(multipleContatoCliente))
      return multipleContatoCliente
    } catch (e) {
      dispatch(loadingContatoClienteFailure(e))
      return false
    }
  }
}

export function fetchContatoCliente (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoCliente())
      const contatoCliente = await callAPI(`/contatos/${id}`)
      dispatch(loadingContatoClienteSuccess(contatoCliente))
      return contatoCliente
    } catch (e) {
      dispatch(loadingContatoClienteFailure(e))
      return false
    }
  }
}

export function createContatoCliente (clienteId, contatoCliente) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoCliente())
      const newContatoCliente = await callAPI(`/clientes/${clienteId}/contatos`, 'POST', contatoCliente)
      dispatch(loadingContatoClienteSuccess(newContatoCliente))
      return newContatoCliente
    } catch (e) {
      dispatch(loadingContatoClienteFailure(e))
      return false
    }
  }
}

export function updateContatoCliente (id, contatoCliente) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoCliente())
      const newContatoCliente = await callAPI(`/contatos/${id}`, 'PUT', contatoCliente)
      dispatch(loadingContatoClienteSuccess(newContatoCliente))
      return newContatoCliente
    } catch (e) {
      dispatch(loadingContatoClienteFailure(e))
      return false
    }
  }
}

export function destroyContatoCliente (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContatoCliente())
      await callAPI(`/contatos/${id}`, 'DELETE')
      dispatch(destroyingContatoClienteSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingContatoClienteFailure(e))
      return false
    }
  }
}

export default function contatoCliente (state = initialState, action) {
  switch (action.type) {

    case LOADING_CONTATO_CLIENTE_SUCCESS : {
      const { contatoCliente } = action
      if (contatoCliente.constructor === Array) {
        state = initialState
        contatoCliente.forEach((c) => (
          state = state.merge({[c.id]: c})
        ))
        return state
      }
      return state.merge({[contatoCliente.id]: contatoCliente})
    }

    case DESTROYING_CONTATO_CLIENTE_SUCCESS :
      return state.delete(action.contatoClienteId.toString())

    default :
      return state
  }
}
