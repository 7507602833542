import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { ContratoEnergiaInstalacaoForm } from '../../components'
import * as contratoEnergiaInstalacaoActionCreators from '../../redux/modules/contratoEnergiaInstalacao'
import * as comercializadoraActionCreators from '../../redux/modules/comercializadora'
import * as fonteEnergiaActionCreators from '../../redux/modules/fonteEnergia'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import moment from 'moment'
import { maskFloat } from '../../utils/masks'

import { mapToDropdown } from '../../utils/parse'

const DECIMAL_DIGITS = 2

class ContratoEnergiaInstalacaoFormContainer extends Component {

  static propTypes = {
    instalacao: PropTypes.object.isRequired,
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,

    fetchContratoEnergiaInstalacao: PropTypes.func.isRequired,
    createContratoEnergiaInstalacao: PropTypes.func.isRequired,
    updateContratoEnergiaInstalacao: PropTypes.func.isRequired,

    comercializadoraDropdown: PropTypes.array.isRequired,
    fetchMultipleComercializadora: PropTypes.func,
    multipleComercializadora: PropTypes.object,

    fonteEnergiaDropdown: PropTypes.array.isRequired,
    fetchMultipleFonteEnergia: PropTypes.func,
    multipleFonteEnergia: PropTypes.object,

    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,

    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleComercializadora()
    await this.props.fetchMultipleFonteEnergia()

    let contrato_energia = {
      instalacao_id: this.props.instalacao.id,
      ano_inicial: moment().startOf('month').format('YYYY'),
      mes_inicial: moment().startOf('month').format('MM'),
      ano_final: moment().endOf('year').format('YYYY'),
      mes_final: moment().endOf('year').format('MM'),
      valores_energia: [],
    }

    if (this.props.id) {
      contrato_energia = await this.props.fetchContratoEnergiaInstalacao(this.props.id)
      contrato_energia.starts_at = moment(contrato_energia.starts_at, 'YYYY-MM-DD').format('DD/MM/YYYY')
      contrato_energia.valores_energia = contrato_energia.valores_energia.map(v => {
        v.valor = maskFloat.parse(v.valor, DECIMAL_DIGITS)
        v.proinfa = maskFloat.parse(v.proinfa, DECIMAL_DIGITS)
        v.preco = maskFloat.parse(v.preco, DECIMAL_DIGITS)
        v.modulacao = maskFloat.parse(v.modulacao, DECIMAL_DIGITS)
        v.sazonalizacao = maskFloat.parse(v.sazonalizacao, DECIMAL_DIGITS)
        v.flexibilidade = maskFloat.parse(v.flexibilidade, DECIMAL_DIGITS)
        return v
      })
    }

    this.props.initialize('ContratoEnergiaInstalacaoForm', { contrato_energia })
  }

  handleSubmitResource = async (contratoEnergiaInstalacao) => {
    const instalacaoId = this.props.instalacao.id

    let valores_energia = contratoEnergiaInstalacao
      .getIn(['contrato_energia', 'valores_energia_attributes'])
      .map(v => {
        v = v.set('valor', maskFloat.unmask(v.get('valor'), DECIMAL_DIGITS))
        v = v.set('proinfa', maskFloat.unmask(v.get('proinfa'), DECIMAL_DIGITS))
        v = v.set('preco', maskFloat.unmask(v.get('preco'), DECIMAL_DIGITS))
        v = v.set('modulacao', maskFloat.unmask(v.get('modulacao'), DECIMAL_DIGITS))
        v = v.set('sazonalizacao', maskFloat.unmask(v.get('sazonalizacao'), DECIMAL_DIGITS))
        v = v.set('flexibilidade', maskFloat.unmask(v.get('flexibilidade'), DECIMAL_DIGITS))
        return v
      })

    contratoEnergiaInstalacao = contratoEnergiaInstalacao.setIn(['contrato_energia', 'valores_energia_attributes'], valores_energia)

    if (this.props.id) {
      if (await this.props.updateContratoEnergiaInstalacao(this.props.id, contratoEnergiaInstalacao)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/instalacoes/${instalacaoId}/tarifas`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createContratoEnergiaInstalacao(instalacaoId, contratoEnergiaInstalacao)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push(`/instalacoes/${instalacaoId}/tarifas`)
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <ContratoEnergiaInstalacaoForm
        instalacao={this.props.instalacao}
        comercializadoraDropdown={this.props.comercializadoraDropdown}
        fonteEnergiaDropdown={this.props.fonteEnergiaDropdown}
        onSubmit={(contratoEnergiaInstalacao) => this.handleSubmitResource(contratoEnergiaInstalacao)}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({contratoEnergiaInstalacao, comercializadora, fonteEnergia, status, flashMessage}, {match}) {
  const { id } = match.params

  const comercializadoraDropdown = mapToDropdown(
    comercializadora,
    e => e.get('id'),
    e => e.get('sigla')
  )

  const fonteEnergiaDropdown = mapToDropdown(
    fonteEnergia,
    e => e.get('id'),
    e => e.get('nome')
  )

  const isLoading =
    status.getIn(['contratoEnergiaInstalacao', 'isLoading']) ||
    status.getIn(['comercializadora', 'isLoading']) ||
    status.getIn(['fonteEnergia', 'isLoading'])

  if (id) {
    return {
      id,
      contratoEnergiaInstalacao: contratoEnergiaInstalacao.get('id'),
      comercializadoraDropdown,
      fonteEnergiaDropdown,
      error: status.getIn(['contratoEnergiaInstalacao', 'error']),
      isLoading,
      shouldHideFlashMessage: flashMessage.get('isError'),
    }
  }
  return {
    comercializadoraDropdown,
    fonteEnergiaDropdown,
    error: status.getIn(['contratoEnergiaInstalacao', 'error']),
    isLoading,
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...contratoEnergiaInstalacaoActionCreators,
    ...comercializadoraActionCreators,
    ...fonteEnergiaActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContratoEnergiaInstalacaoFormContainer))
