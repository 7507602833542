import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Cliente } from '../../components'
import * as clienteActionCreators from '../../redux/modules/cliente'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class ClienteContainer extends Component {

  static propTypes = {
    fetchMultipleCliente: PropTypes.func.isRequired,
    destroyCliente: PropTypes.func.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    multipleCliente: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleCliente()
  }

  handleRedirectToNew = () => {
    this.props.history.push('/clientes/new')
  }

  handleShowResource = (id) => {
    this.props.history.push(`/clientes/${id}`)
  }

  handleEditResource = (id) => {
    this.props.history.push(`/clientes/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyCliente(id)
  }

  handleSort = column => () => this.props.updateSort('cliente', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('cliente', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleCliente.toList().toJSON(),
      'cliente',
      ['id', 'nome', 'sigla', 'cnpj'],
    )
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <Cliente
        title='Clientes'
        listPath='clientes'
        isLoading={this.props.isLoading}
        multipleCliente={this.props.multipleCliente}
        onRedirectToNew={this.handleRedirectToNew}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV} />
    )
  }
}

function mapStateToProps ({cliente, status, sort, filter}) {
  const sortColumn = sort.getIn(['cliente', 'sortColumn'])
  const sortDirection = sort.getIn(['cliente', 'sortDirection'])
  const filterText = filter.getIn(['cliente', 'filterText']) || ''

  let map = filterMap(cliente, ['nome', 'sigla', 'cnpj', 'uf.nome', 'uf.sigla'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleCliente: map,
    sortColumn,
    sortDirection,
    filterText,
    isLoading: status.getIn(['cliente', 'isLoading']),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...clienteActionCreators,
    ...flashMessageAcionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ClienteContainer)
