import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import { Link } from 'react-router-dom'
import { CRUDShow } from '../CRUD'
import { Divider, Grid, Icon, Loader, Menu } from 'semantic-ui-react'
import { Informacoes, Contatos, Parametros, VigenciasTarifas, VigenciasTributos } from './panes'

const ConcessionariaShow = (props) => {
  const { concessionaria, uf, pane, isLoading } = props

  if (!concessionaria || !uf) return (
    <Loader active={props.isLoading}>Carregando...</Loader>
  )

  return (
    <CRUDShow noToolbar noMessage
      title={concessionaria.nome}
      icon='building'>
      <Grid>
        <Grid.Column width={3}>
          <Menu secondary vertical fluid size='huge'>
            <Menu.Item
              active={pane === 'informacoes'}
              as={Link}
              to={`/concessionarias/${concessionaria.id}`}
              key='concessionaria'>
              <Icon name='building' />
              Informações
            </Menu.Item>
            <Menu.Item
              active={pane === 'parametros'}
              as={Link}
              to={`/concessionarias/${concessionaria.id}/parametros`}
              key='parametros'>
              <Icon name='cogs' />
              Parâmetros de Cálculo
            </Menu.Item>
            <Divider />
            <Menu.Item
              active={pane === 'tarifas'}
              as={Link}
              to={`/concessionarias/${concessionaria.id}/tarifas`}
              key='tarifas'>
              <Icon name='money' />
              Tarifas
            </Menu.Item>
            <Menu.Item
              active={pane === 'tributos'}
              as={Link}
              to={`/concessionarias/${concessionaria.id}/tributos`}
              key='tributos'>
              <Icon name='percent' />
              Tributos
            </Menu.Item>
            <Divider />
            <Menu.Item
              active={pane === 'contatos'}
              as={Link}
              to={`/concessionarias/${concessionaria.id}/contatos`}
              key='contatos'>
              <Icon name='users' />
              Contatos
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column width={13}>
          <Switch>
            <Route
              exact path='/concessionarias/:id'
              render={() => (
                <Informacoes
                  concessionaria={concessionaria}
                  uf={uf}
                  onEditResource={props.onEditResource}
                  onDeleteResource={props.onDeleteResource}
                  isLoading={isLoading}
                />
              )}
            />
            <Route
              exact path='/concessionarias/:id/parametros/:paneId?/:action?'
              render={() => (
                <Parametros concessionaria={concessionaria}/>
              )}
            />
            <Route
              exact path='/concessionarias/:id/tarifas/:paneId?/:action?'
              render={() => (
                <VigenciasTarifas concessionaria={concessionaria}/>
              )}
            />
            <Route
              exact path='/concessionarias/:id/tributos/:paneId?/:action?'
              render={() => (
                <VigenciasTributos concessionaria={concessionaria}/>
              )}
            />
            <Route
              exact path='/concessionarias/:id/contatos/:paneId?/:action?'
              render={() => (
                <Contatos concessionaria={concessionaria} />
              )}
            />
          </Switch>
        </Grid.Column>
      </Grid>
    </CRUDShow>
  )
}

ConcessionariaShow.propTypes = {
  concessionaria: PropTypes.object,
  uf: PropTypes.object,
  pane: PropTypes.string,
  onEditResource: PropTypes.func.isRequired,
  onDeleteResource: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

export default ConcessionariaShow
