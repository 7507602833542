import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticDropdown, SemanticInput, SemanticInputMask } from '../FormComponents'

import { required, maxLengthNome, maxLengthDescricao, fator } from '../../utils/validations'
import { maskCurrency } from '../../utils/masks'

const
  grupoOptions = [
    { key: 'a', text: 'A (Alta Tensão)', value: 0 },
    { key: 'b', text: 'B (Baixa Tensão)', value: 1 },
  ]

const SubgrupoForm = (props) => (
  <CRUDForm
    title='Subgrupo de Tensão'
    icon='table'
    {...props}>
    <FormSection name='subgrupo'>
      <Form.Group>
        <FormField width={6}>
          <label>Nome</label>
          <Field
            autoFocus
            name='nome'
            placeholder='Nome'
            validate={[required, maxLengthNome]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField width={10}>
          <label>Descrição</label>
          <Field
            name='descricao'
            placeholder='Descrição'
            validate={[required, maxLengthDescricao]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField width={8}>
          <label>Enquadramentos</label>
          <Field fluid multiple search selection size='large'
            name='enquadramento_ids'
            placeholder='Enquadramento'
            validate={[required]}
            options={props.enquadramentoDropdown}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
        <FormField width={4}>
          <label>Grupo</label>
          <Field fluid button floating labeled search icon='sort' className='icon'
            name='grupo'
            placeholder='Grupo'
            validate={[required]}
            options={grupoOptions}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
        <FormField width={4}>
          <label>Fator de Potência</label>
          <Field
            name='fator_de_potencia'
            label='%'
            validate={[required, fator]}
            component={SemanticInputMask}
            loading={props.loading}
            mask={maskCurrency.mask()}
            placeholder={maskCurrency.placeholder}
          />
        </FormField>
      </Form.Group>
    </FormSection>
  </CRUDForm>
)

SubgrupoForm.propTypes = {
  enquadramentoDropdown: PropTypes.array,
  loading: PropTypes.bool
}

export default reduxForm({form: 'SubgrupoForm'})(SubgrupoForm)
