import React from 'react'
import { Card, List } from 'semantic-ui-react'

const ClienteResumoListState = props => {
  const showPlaceholder = props.relatorios?.reports.length === 0
  return (
    <List>
      {(props.relatorios?.reports && !showPlaceholder) && props.relatorios.reports.map(rep => {
        let cor = rep.state === 'none' ? 'red' : 'green'
        return (
          <List.Item>
            <Card fluid color={cor} header={rep.instalacao} />
          </List.Item>
        )
      })}
      {showPlaceholder && (
        <List.Item>
          <Card fluid header="Nenhuma instalação encontrada."/>
        </List.Item>
      )}
    </List>
  )
}

export default ClienteResumoListState
