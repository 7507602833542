import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { GeradorShow } from '../../components'
import * as geradorActionCreators from '../../redux/modules/gerador'

class GeradorShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    fetchGerador: PropTypes.func,
    history: PropTypes.object,
    destroyGerador: PropTypes.func,
  }

  state = {
    gerador: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const gerador = await this.props.fetchGerador(this.props.id)
      this.setState({ gerador })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/geradores/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/geradores/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyGerador(id)
    this.props.history.push('/geradores')
  }

  render () {
    return (
      <GeradorShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        gerador={this.state.gerador} />
    )
  }
}

function mapStateToProps ({gerador}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      gerador: gerador.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...geradorActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(GeradorShowContainer)
