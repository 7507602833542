import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_CONCESSIONARIA = 'LOADING_CONCESSIONARIA'
export function loadingConcessionaria () {
  return {
    type: LOADING_CONCESSIONARIA,
  }
}

export const LOADING_CONCESSIONARIA_SUCCESS = 'LOADING_CONCESSIONARIA_SUCCESS'
export function loadingConcessionariaSuccess (concessionaria) {
  return {
    type: LOADING_CONCESSIONARIA_SUCCESS,
    concessionaria,
  }
}

export const LOADING_CONCESSIONARIA_FAILURE = 'LOADING_CONCESSIONARIA_FAILURE'
export function loadingConcessionariaFailure (error) {
  return {
    type: LOADING_CONCESSIONARIA_FAILURE,
    error,
  }
}

export const DESTROYING_CONCESSIONARIA_SUCCESS = 'DESTROYING_CONCESSIONARIA_SUCCESS'
export function destroyingConcessionariaSuccess (concessionariaId) {
  return {
    type: DESTROYING_CONCESSIONARIA_SUCCESS,
    concessionariaId,
  }
}

const initialState = Map({})

export function fetchMultipleConcessionaria () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingConcessionaria())
      const multipleConcessionaria = await callAPI('/concessionarias')
      dispatch(loadingConcessionariaSuccess(multipleConcessionaria))
      return multipleConcessionaria
    } catch (e) {
      dispatch(loadingConcessionariaFailure(e))
      return false
    }
  }
}

export function fetchConcessionaria (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingConcessionaria())
      const concessionaria = await callAPI(`/concessionarias/${id}`)
      dispatch(loadingConcessionariaSuccess(concessionaria))
      return concessionaria
    } catch (e) {
      dispatch(loadingConcessionariaFailure(e))
      return false
    }
  }
}

export function createConcessionaria (concessionaria) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingConcessionaria())
      const newConcessionaria = await callAPI('/concessionarias', 'POST', concessionaria)
      dispatch(loadingConcessionariaSuccess(newConcessionaria))
      return newConcessionaria
    } catch (e) {
      dispatch(loadingConcessionariaFailure(e))
      return false
    }
  }
}

export function updateConcessionaria (id, concessionaria) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingConcessionaria())
      const newConcessionaria = await callAPI(`/concessionarias/${id}`, 'PUT', concessionaria)
      dispatch(loadingConcessionariaSuccess(newConcessionaria))
      return newConcessionaria
    } catch (e) {
      dispatch(loadingConcessionariaFailure(e))
      return false
    }
  }
}

export function destroyConcessionaria (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingConcessionaria())
      await callAPI(`/concessionarias/${id}`, 'DELETE')
      dispatch(destroyingConcessionariaSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingConcessionariaFailure(e))
      return false
    }
  }
}

export default function concessionaria (state = initialState, action) {
  switch (action.type) {

    case LOADING_CONCESSIONARIA_SUCCESS : {
      const { concessionaria } = action
      if (concessionaria.constructor === Array) {
        state = initialState
        concessionaria.forEach((singleConcessionaria) => (
          state = state.merge({[singleConcessionaria.id]: singleConcessionaria})
        ))
        return state
      }
      return state.merge({[concessionaria.id]: concessionaria})
    }

    case DESTROYING_CONCESSIONARIA_SUCCESS :
      return state.delete(action.concessionariaId.toString())

    default :
      return state
  }
}
