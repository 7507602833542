import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import withBreadcrumbs from 'react-router-breadcrumbs-hoc'
import { Breadcrumb } from 'semantic-ui-react'
import ResourceBreadcrumb from './ResourceBreadcrumb'

const routes = [
  { path: '/', breadcrumb: 'Dashboard' },

  { path: '/gestao_bandeiras', breadcrumb: 'Gestão de Bandeiras' },

  { path: '/gestao_bandeiras/bandeiras', breadcrumb: 'Bandeiras' },
  { path: '/gestao_bandeiras/bandeiras/new', breadcrumb: 'Inclusão' },
  { path: '/gestao_bandeiras/bandeiras/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='bandeira' fieldName='nome' {...props} /> },
  { path: '/gestao_bandeiras/bandeiras/:id/edit', breadcrumb: 'Alteração' },

  { path: '/gestao_bandeiras/vigencias', breadcrumb: 'Vigências' },
  { path: '/gestao_bandeiras/vigencias/new', breadcrumb: 'Inclusão' },
  { path: '/gestao_bandeiras/vigencias/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='vigenciaBandeira' fieldName='id' {...props} /> },
  { path: '/gestao_bandeiras/vigencias/:id/edit', breadcrumb: 'Alteração' },

  { path: '/gestao_bandeiras/valores', breadcrumb: 'Valores' },
  { path: '/gestao_bandeiras/valores/new', breadcrumb: 'Inclusão' },
  { path: '/gestao_bandeiras/valores/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='vigenciaValor' fieldName='id' {...props} /> },
  { path: '/gestao_bandeiras/valores/:id/edit', breadcrumb: 'Alteração' },

  { path: '/classes_consumo', breadcrumb: 'Classes de Consumo' },
  { path: '/classes_consumo/new', breadcrumb: 'Inclusão' },
  { path: '/classes_consumo/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='classeConsumo' fieldName='nome' {...props} /> },
  { path: '/classes_consumo/:id/edit', breadcrumb: 'Alteração' },

  { path: '/clientes', breadcrumb: 'Clientes' },
  { path: '/clientes/new', breadcrumb: 'Inclusão' },
  { path: '/clientes/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='cliente' fieldName='sigla' {...props} /> },
  { path: '/clientes/:id/edit', breadcrumb: 'Alteração' },

  { path: '/clientes/:id/instalacoes', breadcrumb: 'Instalações' },
  { path: '/clientes/:id/instalacoes/new', breadcrumb: 'Inclusão' },
  { path: '/clientes/:cId/instalacoes/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='instalacaoCliente' fieldName='apelido' {...props} /> },
  { path: '/clientes/:cId/instalacoes/:id/edit', breadcrumb: 'Alteração' },

  { path: '/clientes/:id/contatos', breadcrumb: 'Contatos' },
  { path: '/clientes/:id/contatos/new', breadcrumb: 'Inclusão' },
  { path: '/clientes/:cId/contatos/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='contatoCliente' fieldName='nome' {...props} /> },
  { path: '/clientes/:cId/contatos/:id/edit', breadcrumb: 'Alteração' },

  { path: '/comercializadoras', breadcrumb: 'Comercializadoras' },
  { path: '/comercializadoras/new', breadcrumb: 'Inclusão' },
  { path: '/comercializadoras/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='comercializadora' fieldName='sigla' {...props} /> },
  { path: '/comercializadoras/:id/edit', breadcrumb: 'Alteração' },

  { path: '/comercializadoras/:id/contatos', breadcrumb: 'Contatos' },
  { path: '/comercializadoras/:id/contatos/new', breadcrumb: 'Inclusão' },
  { path: '/comercializadoras/:cId/contatos/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='contatoComercializadora' fieldName='nome' {...props} /> },
  { path: '/comercializadoras/:cId/contatos/:id/edit', breadcrumb: 'Alteração' },

  { path: '/concessionarias', breadcrumb: 'Concessionárias' },
  { path: '/concessionarias/new', breadcrumb: 'Inclusão' },
  { path: '/concessionarias/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='concessionaria' fieldName='sigla' {...props} /> },

  { path: '/concessionarias/:concessionariaId/parametros', breadcrumb: 'Parâmetros de Cálculo' },
  { path: '/concessionarias/:concessionariaId/parametros/new', breadcrumb: 'Inclusão' },
  { path: '/concessionarias/:concessionariaId/parametros/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='parametroConcessionaria' fieldName='id' {...props} /> },
  { path: '/concessionarias/:concessionariaId/parametros/:id/edit', breadcrumb: 'Alteração' },

  { path: '/concessionarias/:concessionariaId/tarifas', breadcrumb: 'Tarifas' },
  { path: '/concessionarias/:concessionariaId/tarifas/new', breadcrumb: 'Inclusão' },
  { path: '/concessionarias/:concessionariaId/tarifas/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='vigenciaTarifa' fieldName='id' {...props} /> },
  { path: '/concessionarias/:concessionariaId/tarifas/:id/edit', breadcrumb: 'Alteração' },

  { path: '/concessionarias/:concessionariaId/tributos', breadcrumb: 'Tributos' },
  { path: '/concessionarias/:concessionariaId/tributos/new', breadcrumb: 'Inclusão' },
  { path: '/concessionarias/:concessionariaId/tributos/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='vigenciaTributo' fieldName='id' {...props} /> },
  { path: '/concessionarias/:concessionariaId/tributos/:id/edit', breadcrumb: 'Alteração' },

  { path: '/concessionarias/:id/contatos', breadcrumb: 'Contatos' },
  { path: '/concessionarias/:id/contatos/new', breadcrumb: 'Inclusão' },
  { path: '/concessionarias/:cId/contatos/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='contatoConcessionaria' fieldName='nome' {...props} /> },
  { path: '/concessionarias/:cId/contatos/:id/edit', breadcrumb: 'Alteração' },

  { path: '/concessionarias/:id/edit', breadcrumb: 'Alteração' },

  { path: '/contatos', breadcrumb: 'Contatos' },
  { path: '/contatos/new', breadcrumb: 'Inclusão' },
  { path: '/contatos/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='contato' fieldName='nome' {...props} /> },
  { path: '/contatos/:id/edit', breadcrumb: 'Alteração' },

  { path: '/enquadramentos', breadcrumb: 'Enquadramentos' },
  { path: '/enquadramentos/new', breadcrumb: 'Inclusão' },
  { path: '/enquadramentos/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='enquadramento' fieldName='modalidade' {...props} /> },
  { path: '/enquadramentos/:id/edit', breadcrumb: 'Alteração' },

  { path: '/enquadramentos/:enquadramentoId/parametros', breadcrumb: 'Parâmetros de Cálculo' },
  { path: '/enquadramentos/:enquadramentoId/parametros/new', breadcrumb: 'Inclusão' },
  { path: '/enquadramentos/:enquadramentoId/parametros/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='parametroEnquadramento' fieldName='id' {...props} /> },
  { path: '/enquadramentos/:enquadramentoId/parametros/:id/edit', breadcrumb: 'Alteração' },

  { path: '/fontes_energia', breadcrumb: 'Fontes de Energia' },
  { path: '/fontes_energia/new', breadcrumb: 'Inclusão' },
  { path: '/fontes_energia/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='fonteEnergia' fieldName='nome' {...props} /> },
  { path: '/fontes_energia/:id/edit', breadcrumb: 'Alteração' },

  { path: '/funcoes', breadcrumb: 'Funções' },
  { path: '/funcoes/new', breadcrumb: 'Inclusão' },
  { path: '/funcoes/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='funcao' fieldName='nome' {...props} /> },
  { path: '/funcoes/:id/edit', breadcrumb: 'Alteração' },

  { path: '/geradores', breadcrumb: 'Geradores' },
  { path: '/geradores/new', breadcrumb: 'Inclusão' },
  { path: '/geradores/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='gerador' fieldName='tipo' {...props} /> },
  { path: '/geradores/:id/edit', breadcrumb: 'Alteração' },

  { path: '/icms', breadcrumb: 'ICMS' },
  { path: '/icms/new', breadcrumb: 'Inclusão' },
  { path: '/icms/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='icms' fieldName='id' {...props} /> },
  { path: '/icms/:id/edit', breadcrumb: 'Alteração' },

  { path: '/indicativos/medios', breadcrumb: 'PLD - Preço Médio (R$/MWh)' },
  { path: '/indicativos/medios/edit', breadcrumb: 'Alteração' },

  { path: '/indicativos/semanais', breadcrumb: 'PLD - Preços Semanais (R$/MWh)' },
  { path: '/indicativos/semanais/edit', breadcrumb: 'Alteração' },
  { path: '/indicativos/semanais/upload', breadcrumb: 'Upload' },

  { path: '/instalacoes', breadcrumb: 'Instalações' },
  { path: '/instalacoes/new', breadcrumb: 'Inclusão' },
  { path: '/instalacoes/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='instalacao' fieldName='apelido' {...props} /> },
  { path: '/instalacoes/:id/edit', breadcrumb: 'Alteração' },

  { path: '/instalacoes/:id/relatorios', breadcrumb: 'Relatórios' },
  { path: '/instalacoes/:id/relatorios/new', breadcrumb: 'Inclusão' },
  { path: '/instalacoes/:iId/relatorios/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='relatorio' fieldName='id' {...props} /> },
  { path: '/instalacoes/:iId/relatorios/:id/edit', breadcrumb: 'Alteração' },

  { path: '/instalacoes/:id/pedagio', breadcrumb: 'Faturas de Pedágio' },
  { path: '/instalacoes/:id/pedagio/new', breadcrumb: 'Inclusão' },
  { path: '/instalacoes/:iId/pedagio/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='faturaPedagio' fieldName='id' {...props} /> },
  { path: '/instalacoes/:iId/pedagio/:id/edit', breadcrumb: 'Alteração' },

  { path: '/instalacoes/:iId/pedagio/:pId/resultado', breadcrumb: 'Resultado' },
  { path: '/instalacoes/:iId/pedagio/:pId/resultado/comparar', breadcrumb: 'Comparar' },
  { path: '/instalacoes/:iId/pedagio/:pId/resultado/comparar/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='cenario' fieldName='id' {...props} /> },

  { path: '/instalacoes/:id/contatos', breadcrumb: 'Contatos' },
  { path: '/instalacoes/:id/contatos/new', breadcrumb: 'Inclusão' },
  { path: '/instalacoes/:iId/contatos/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='contatoInstalacao' fieldName='nome' {...props} /> },
  { path: '/instalacoes/:iId/contatos/:id/edit', breadcrumb: 'Alteração' },

  { path: '/instalacoes/:id/contratos_demanda', breadcrumb: 'Contratos de Demanda' },
  { path: '/instalacoes/:id/contratos_demanda/edit', breadcrumb: 'Alteração' },

  { path: '/instalacoes/:id/contratos_energia', breadcrumb: 'Contratos de Energia' },
  { path: '/instalacoes/:id/contratos_energia/new', breadcrumb: 'Inclusão' },
  { path: '/instalacoes/:iId/contratos_energia/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='contratoEnergiaInstalacao' fieldName='id' {...props} /> },
  { path: '/instalacoes/:iId/contratos_energia/:id/edit', breadcrumb: 'Alteração' },

  { path: '/faturas_energia', breadcrumb: 'Faturas de Energia' },
  { path: '/faturas_energia/new', breadcrumb: 'Inclusão' },
  { path: '/faturas_energia/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='faturaEnergia' fieldName='id' {...props} /> },
  { path: '/faturas_energia/:id/edit', breadcrumb: 'Alteração' },

  { path: '/tributos', breadcrumb: 'Tributos' },
  { path: '/tributos/new', breadcrumb: 'Inclusão' },
  { path: '/tributos/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='tributo' fieldName='nome' {...props} /> },
  { path: '/tributos/:id/edit', breadcrumb: 'Alteração' },

  { path: '/servicos', breadcrumb: 'Serviços Prestados' },
  { path: '/servicos/new', breadcrumb: 'Inclusão' },
  { path: '/servicos/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='servico' fieldName='nome' {...props} /> },
  { path: '/servicos/:id/edit', breadcrumb: 'Alteração' },

  { path: '/subgrupos', breadcrumb: 'Subgrupos de Tensão' },
  { path: '/subgrupos/new', breadcrumb: 'Inclusão' },
  { path: '/subgrupos/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='subgrupo' fieldName='nome' {...props} /> },
  { path: '/subgrupos/:id/edit', breadcrumb: 'Alteração' },

  { path: '/submercados', breadcrumb: 'Submercados' },
  { path: '/submercados/new', breadcrumb: 'Inclusão' },
  { path: '/submercados/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='submercado' fieldName='nome' {...props} /> },
  { path: '/submercados/:id/edit', breadcrumb: 'Alteração' },

  { path: '/uf', breadcrumb: 'Unidades Federativas' },
  { path: '/uf/new', breadcrumb: 'Inclusão' },
  { path: '/uf/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='uf' fieldName='sigla' {...props} /> },
  { path: '/uf/:id/edit', breadcrumb: 'Alteração' },

  { path: '/vigencias_bandeira', breadcrumb: 'Vigências de Bandeira' },
  { path: '/vigencias_bandeira/new', breadcrumb: 'Inclusão' },
  { path: '/vigencias_bandeira/:id', breadcrumb: props => <ResourceBreadcrumb resourceName='vigenciaBandeira' fieldName='id' {...props} /> },
  { path: '/vigencias_bandeira/:id/edit', breadcrumb: 'Alteração' },
]

const Breadcrumbs = ({ breadcrumbs }) => (
  <Breadcrumb>
    {breadcrumbs.map((breadcrumb, index) => {
      return (
        <span key={breadcrumb.key}>
          <Breadcrumb.Section as={Link} to={breadcrumb.props.match.url}>{breadcrumb}</Breadcrumb.Section>
          {index < breadcrumbs.length - 1 && (
            <Breadcrumb.Divider icon='right chevron' />
          )}
        </span>
      )
    })}
  </Breadcrumb>
)

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array
}

export default withBreadcrumbs(routes)(Breadcrumbs)
