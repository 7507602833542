import React from 'react'
import PropTypes from 'prop-types'

import { Popup, Table } from 'semantic-ui-react'
import { maskFloat } from '../../../utils/masks'

const Detalhe = ({ tipo, valor, tarifa, medicao, unidade }) => {
  const tipo_tarifa = {
    cru: 'crua',
    calculado: 'calculada',
  }
  return (
    <Popup wide='very' position='top right' flowing hoverable style={{fontFamily: 'monospace'}}
      trigger={<Table.Cell textAlign='right' style={{fontFamily: 'monospace'}}>{maskFloat.parse(valor.get(tipo))}</Table.Cell>}>
      {maskFloat.parse(medicao, 2)} {unidade}
      {' '}×{' '}
      {maskFloat.parse(tarifa.getIn(['proporcional', tipo_tarifa[tipo]]), 6)} R$/{unidade}
      {' '}={' '}
      R$ {maskFloat.parse(valor.get(tipo))}
    </Popup>
  )
}

Detalhe.propTypes = {
  tipo: PropTypes.string,
  valor: PropTypes.object,
  tarifa: PropTypes.object,
  medicao: PropTypes.number,
  unidade: PropTypes.string,
}

export default Detalhe
