import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput, SemanticInputMask, SemanticDropdown } from '../FormComponents'

import { required, phone, email, maxLengthNome } from '../../utils/validations'
import { maskPhone } from '../../utils/masks'

function ContatoForm (props) {
  return (
    <CRUDForm
      title='Contato'
      titleAs={props.titleAs}
      icon='users'
      padded={props.noPadded ? false : true}
      {...props}>
      <FormSection name='contato'>
        <Form.Group>
          <FormField width={6}>
            <label>Nome</label>
            <Field
              autoFocus
              name='nome'
              placeholder='Nome'
              validate={[required, maxLengthNome]}
              component={SemanticInput}
            />
          </FormField>

          <FormField width={6}>
            <label>E-mail</label>
            <Field
              name='email'
              placeholder='E-mail'
              validate={[email]}
              component={SemanticInput}
            />
          </FormField>
        </Form.Group>

        <Form.Group>
          <FormField width={6}>
            <label>Telefone</label>
            <Field
              name='telefone'
              placeholder={maskPhone.placeholder}
              validate={[phone]}
              component={SemanticInputMask}
              mask={maskPhone.mask}
            />
          </FormField>

          <FormField width={6}>
            <label>Celular</label>
            <Field
              name='celular'
              placeholder={maskPhone.placeholder}
              validate={[phone]}
              component={SemanticInputMask}
              mask={maskPhone.mask}
            />
          </FormField>
        </Form.Group>

        <Form.Group>
          <FormField width={12}>
            <label>Observações</label>
            <Field
              name='observacoes'
              placeholder='Observações'
              component={SemanticInput}
            />
          </FormField>
        </Form.Group>

        <Form.Group>
          <FormField width={6}>
            <label>Função</label>
            <Field fluid button floating labeled search icon='magic' className='icon'
              name='funcao_id'
              options={props.funcaoDropdown}
              validate={[required]}
              component={SemanticDropdown}
            />
          </FormField>
        </Form.Group>
      </FormSection>
    </CRUDForm>
  )
}

ContatoForm.propTypes = {
  titleAs: PropTypes.bool,
  noPadded: PropTypes.bool,
  funcaoDropdown: PropTypes.array,
}

export default reduxForm({form: 'ContatoForm'})(ContatoForm)
