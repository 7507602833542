import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'
import { formatDate } from '../../utils/formats'

const VigenciaBandeiraShow = (props) => {
  if (props.vigenciaBandeira) {
    return (
      <CRUDShow
        title={props.vigenciaBandeira.id}
        icon='calendar'
        padded={false}
        onEditResource={() => props.onEditResource(props.vigenciaBandeira.id)}
        onDeleteResource={() => props.onDeleteResource(props.vigenciaBandeira.id)}>
        <ul>
          <li>Início da Vigência: {formatDate(props.vigenciaBandeira.starts_at)}</li>
          <li>Bandeira: {props.vigenciaBandeira.bandeira.nome}</li>
        </ul>
      </CRUDShow>
    )
  }

  return (null)
}

VigenciaBandeiraShow.propTypes = {
  vigenciaBandeira: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
}

export default VigenciaBandeiraShow
