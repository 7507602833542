import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { UserMenu } from '../../components'
import { connect } from 'react-redux'
import { destroySession } from '../../redux/modules/session'

class UserMenuContainer extends Component {

  static propTypes = {
    session: PropTypes.object,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  }

  handleLogout = () => {
    this.props.dispatch(destroySession())
    this.props.history.push('/login')
  }

  render () {
    return (
      <UserMenu
        session={this.props.session}
        onLogout={() => this.handleLogout()}
      />
    )
  }
}

function mapStateToProps ({ session }) {
  return { session }
}

export default connect(mapStateToProps)(withRouter(UserMenuContainer))
