import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ComercializadoraShow } from '../../components'
import * as comercializadoraActionCreators from '../../redux/modules/comercializadora'
import * as ufActionCreators from '../../redux/modules/uf'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

class ComercializadoraShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,

    fetchComercializadora: PropTypes.func.isRequired,
    destroyComercializadora: PropTypes.func.isRequired,

    fetchUf: PropTypes.func.isRequired,

    hideFlashMessage: PropTypes.func.isRequired,
  }

  state = {
    comercializadora: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const comercializadora = await this.props.fetchComercializadora(this.props.id)
      const uf = await this.props.fetchUf(comercializadora.uf_id)
      this.setState({ comercializadora, uf })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/comercializadoras/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/comercializadoras/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyComercializadora(id)
    this.props.history.push('/comercializadoras')
  }

  getPane = (match) => {
    if (match.params.pane) return match.params.pane
    return 'informacoes'
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <ComercializadoraShow
        isLoading={this.props.isLoading}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        comercializadora={this.state.comercializadora}
        uf={this.state.uf}
        pane={this.getPane(this.props.match)}
      />
    )
  }
}

function mapStateToProps ({comercializadora, uf, status}, {match}) {
  const { id } = match.params
  const isLoading = status.getIn(['comercializadora', 'isLoading']) || status.getIn(['uf', 'isLoading'])
  if (id) {
    return {
      id,
      isLoading,
      comercializadora: comercializadora.get('id'),
      uf: uf.get('id')
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...comercializadoraActionCreators,
    ...ufActionCreators,
    ...flashMessageAcionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ComercializadoraShowContainer))
