import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { ContatoForm } from '../../components'
import * as contatoActionCreators from '../../redux/modules/contato'
import * as funcaoActionCreators from '../../redux/modules/funcao'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { mapToDropdown } from '../../utils/parse'

class ContatoFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    fetchContato: PropTypes.func.isRequired,
    createContato: PropTypes.func.isRequired,
    updateContato: PropTypes.func.isRequired,
    multipleFuncao: PropTypes.object.isRequired,
    fetchMultipleFuncao: PropTypes.func.isRequired,
    funcaoDropdown: PropTypes.array.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleFuncao()
    if (this.props.id) {
      const contato = await this.props.fetchContato(this.props.id)
      this.props.initialize('ContatoForm', {contato})
    }
  }

  handleSubmitResource = async (contato) => {
    if (this.props.id) {
      if (await this.props.updateContato(this.props.id, contato)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push('/contatos')
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createContato(contato)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/contatos')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <ContatoForm
        funcaoDropdown={this.props.funcaoDropdown}
        multipleFuncao={this.props.multipleFuncao}
        onSubmit={(contato) => this.handleSubmitResource(contato)}
        isLoading={this.props.isLoading} />
    )
  }

}

function mapStateToProps ({contato, funcao, status, flashMessage}, {match}) {
  const { id } = match.params
  const funcaoDropdown = mapToDropdown(
    funcao,
    e => e.get('id'),
    e => e.get('nome')
  )
  if (id) {
    return {
      id,
      contato: contato.get('id'),
      funcaoDropdown,
      error: status.getIn(['contato', 'error']),
      isLoading: status.getIn(['contato', 'isLoading']),
      shouldHideFlashMessage: flashMessage.get('isError'),
    }
  }
  return {
    funcaoDropdown,
    error: status.getIn(['contato', 'error']),
    isLoading: status.getIn(['contato', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...contatoActionCreators,
    ...funcaoActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ContatoFormContainer)
