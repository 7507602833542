import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import { Grid, Icon } from 'semantic-ui-react'

import {
  formatter,
  formatAsPercentage,
  formatAsIndicador,
  formatAsUnitDecimals,
  COLORS,
} from './utils/ChartUtils'
import Indicador from './utils/Indicador'

const DEFAULT_VALUE_COLOR = COLORS.ELECTRIC.BASE
const DEFAULT_VARIATION_COLOR = COLORS.ELECTRIC.LIGHT.BASE

const indicatorsList = [
  'consumo_p',
  'consumo_fp',
  'consumo_int',
  'consumo_na',
  'consumo_total',
  'demanda_p',
  'demanda_fp',
  'demanda_na',
  'ufer',
  'bandeira_inicial',
  'gerador',
  'mega_medio',
  'custo_mwh',
  'total',
]

const demandaP_FP_variationText = (value, variation) => {
  if (variation === 0 || variation === 1.0) return 'do contrato'
  if (variation < 1.0 && variation > 0) return 'abaixo do contrato'
  if (variation > 1.0) return 'acima do contrato'
}

const demandaP_FP_variationColor = (value, variation) => {
  if (variation <= 0.5) return COLORS.ELECTRIC.RED
  if (variation < 0.85) return COLORS.ELECTRIC.YELLOW
  if (variation > 1.0) return COLORS.ELECTRIC.RED
  return COLORS.ELECTRIC.GREEN
}

const ufer_valueColor = (value) => {
  if (value <= 0.0) return DEFAULT_VALUE_COLOR
  return COLORS.ELECTRIC.RED
}

const demandaP_FP_variationIcon = (value, variation) => {
  if (variation > 1.0) return 'caret up' // ^
  if (variation > 0 && variation < 1.0) return 'caret down' // v
}

const normalizedVariationValueFormatter = variation => formatAsPercentage(Math.abs(variation))
const absoluteVariationValueFormatter = variation => formatAsPercentage(Math.abs(1 - variation))

const indicatorVariationColor = (positive = COLORS.ELECTRIC.GREEN, negative = COLORS.ELECTRIC.RED) => (value, variation) => {
  if (variation > 0) return positive
  if (variation < 0) return negative
}

const indicatorVariationIcon = (value, variation) => {
  if (variation > 0) return 'caret up'
  if (variation < 0) return 'caret down'
}

const fieldsNameMapping = {
  demanda_p: {
    title: 'Demanda Ponta',
    field: 'demandas.ponta',
    valueFormatter: formatAsIndicador(' kW'),
    variationValueFormatter: absoluteVariationValueFormatter,
    variationText: demandaP_FP_variationText,
    variationIcon: demandaP_FP_variationIcon,
    variationColor: demandaP_FP_variationColor,
  },
  demanda_fp: {
    title: 'Demanda Fora Ponta',
    field: 'demandas.fora_ponta',
    valueFormatter: formatAsIndicador(' kW'),
    variationValueFormatter: absoluteVariationValueFormatter,
    variationText: demandaP_FP_variationText,
    variationIcon: demandaP_FP_variationIcon,
    variationColor: demandaP_FP_variationColor,
  },
  demanda_na: {
    title: 'Demanda',
    field: 'demandas.na',
    valueFormatter: formatAsIndicador(' kW'),
    variationValueFormatter: absoluteVariationValueFormatter,
    variationText: demandaP_FP_variationText,
    variationIcon: demandaP_FP_variationIcon,
    variationColor: demandaP_FP_variationColor,
  },
  consumo_p: {
    title: 'Consumo Ponta',
    field: 'consumos.ponta',
    valueFormatter: formatAsIndicador(' kWh'),
    variationValueFormatter: normalizedVariationValueFormatter,
    variationText: 'em relação ao ano anterior',
    variationIcon: indicatorVariationIcon,
    variationColor: DEFAULT_VARIATION_COLOR,
  },
  consumo_fp: {
    title: 'Consumo Fora Ponta',
    field: 'consumos.fora_ponta',
    valueFormatter: formatAsIndicador(' kWh'),
    variationValueFormatter: normalizedVariationValueFormatter,
    variationText: 'em relação ao ano anterior',
    variationIcon: indicatorVariationIcon,
    variationColor: DEFAULT_VARIATION_COLOR,
  },
  consumo_int: {
    title: 'Consumo Intermediário',
    field: 'consumos.int',
    valueFormatter: formatAsIndicador(' kWh'),
    variationValueFormatter: normalizedVariationValueFormatter,
    variationText: 'em relação ao ano anterior',
    variationIcon: indicatorVariationIcon,
    variationColor: DEFAULT_VARIATION_COLOR,
  },
  consumo_na: {
    title: 'Consumo',
    field: 'consumos.na',
    valueFormatter: formatAsIndicador(' kWh'),
    variationValueFormatter: normalizedVariationValueFormatter,
    variationText: 'em relação ao ano anterior',
    variationIcon: indicatorVariationIcon,
    variationColor: DEFAULT_VARIATION_COLOR,
  },
  consumo_total: {
    title: 'Consumo Total',
    valueFormatter: formatAsIndicador(' kWh'),
    variationValueFormatter: normalizedVariationValueFormatter,
    variationText: 'em relação ao ano anterior',
    variationIcon: indicatorVariationIcon,
    variationColor: DEFAULT_VARIATION_COLOR,
  },
  ufer: {
    title: 'Multas Reativos',
    valueFormatter: formatAsIndicador('R$ ', true),
    valueColor: ufer_valueColor,
    variationValueFormatter: normalizedVariationValueFormatter,
    variationText: 'em relação ao ano anterior',
    variationIcon: indicatorVariationIcon,
    variationColor: indicatorVariationColor(COLORS.ELECTRIC.RED, COLORS.ELECTRIC.GREEN),
  },
  total: {
    title: 'Total da Fatura',
    valueFormatter: formatAsIndicador('R$ ', true),
    variationValueFormatter: normalizedVariationValueFormatter,
    variationText: 'em relação ao ano anterior',
    variationIcon: indicatorVariationIcon,
    variationColor: DEFAULT_VARIATION_COLOR,
  },
  bandeira_inicial: {
    field: 'bandeiras',
    title: 'Bandeiras',
    valueFormatter: formatAsIndicador('R$ ', true),
    variationValueFormatter: formatAsPercentage,
    variationText: 'do total da fatura',
    icon: 'flag',
  },
  'mega_medio': {
    title: 'Megawatt-médio',
    valueFormatter: formatAsUnitDecimals(' MWm', 3),
    variationValueFormatter: normalizedVariationValueFormatter,
    variationText: 'em relação ao mês anterior',
    variationIcon: indicatorVariationIcon,
    variationColor: DEFAULT_VARIATION_COLOR,
  },
  custo_mwh: {
    title: 'Custo do MWh',
    valueFormatter: formatAsIndicador('R$ ', true),
    variationValueFormatter: normalizedVariationValueFormatter,
    variationText: 'em relação ao mês anterior',
    variationIcon: indicatorVariationIcon,
    variationColor: DEFAULT_VARIATION_COLOR,
  },
  gerador: {
    title: 'Gerador',
    valueFormatter: formatAsIndicador('R$ ', true),
    variationValueFormatter: formatAsPercentage,
    variationText: 'do custo total',
    variationIcon: indicatorVariationIcon,
    variationColor: DEFAULT_VARIATION_COLOR,
  },
}

const Indicadores = ({ data }) => {
  if (!data) return null

  const consumoEmMWH = get(data, 'consumo_total.realizado', 0.0) / 1000.0 > 100.0

  if (consumoEmMWH) {
    const consumos = [
      'consumo_p',
      'consumo_fp',
      'consumo_int',
      'consumo_na',
      'consumo_total'
    ]

    consumos.forEach(e => fieldsNameMapping[e].valueFormatter = formatter(0, ' MWh', false, 1 / 1000.0))
  }

  const indicators = indicatorsList.map(ind => {
    const indData = get(data, fieldsNameMapping[ind].field || ind, null)

    if (indData !== null) {
      const color = get(indData, 'cor', null)

      let icon

      if (Array.isArray(color)) {
        icon = color.map(c => fieldsNameMapping[ind].icon ?
          (<Icon size='large' name={fieldsNameMapping[ind].icon} style={{color: `${c}`, lineHeight: '0.9'}}/>) :
          null
        )
      } else {
        icon = fieldsNameMapping[ind].icon ?
          (<Icon size='large' name={fieldsNameMapping[ind].icon} style={{color: `${color}`, lineHeight: '0.9'}}/>) :
          null
      }

      const title = fieldsNameMapping[ind].title

      return (
        <Grid.Column key={ind} className='column-indicador'>
          <Indicador
            title={(typeof title === 'function') ? title(indData) : title}
            value={get(indData, 'realizado', 0)}
            valueFormatter={fieldsNameMapping[ind].valueFormatter}
            valueColor={fieldsNameMapping[ind].valueColor || DEFAULT_VALUE_COLOR}
            icon={icon}
            variation={get(indData, 'percentual', 0)}
            variationValueFormatter={fieldsNameMapping[ind].variationValueFormatter}
            variationText={fieldsNameMapping[ind].variationText}
            variationIcon={fieldsNameMapping[ind].variationIcon}
            variationColor={fieldsNameMapping[ind].variationColor}
            comparison={fieldsNameMapping[ind].comparison}
          />
        </Grid.Column>
      )
    } else {
      return null
    }
  })

  const customIndicators = (get(data, 'custom', [])).map(cust => {
    const title = get(cust, 'label', '')
    const value = get(cust, 'valor', 0)
    const unit = get(cust, 'unidade', '')

    const formattedValue = formatter(0, ',', '.', ` ${unit}`, false)(value)

    return (
      <Grid.Column key={title} className='column-indicador'>
        <Indicador
          title={title}
          value={formattedValue}
        />
      </Grid.Column>
    )
  })

  let indicatorsToShow = indicators.concat(customIndicators).filter(ind => ind !== null)
  if (indicatorsToShow.length > 10) indicatorsToShow = indicatorsToShow.filter(i => i.key !== 'mega_medio')

  return (
    <Grid.Row columns={5} style={{paddingBottom: 0}}>
      {indicatorsToShow}
    </Grid.Row>
  )
}

Indicadores.propTypes = {
  data: PropTypes.object,
}

export default Indicadores
