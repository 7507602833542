import React from 'react'
import PropTypes from 'prop-types'

const CustomLineDotLabelBox = props => {
  const {cx, cy, /*fill,*/ r, strokeWidth, stroke, positiveStroke, negativeStroke, value, formatter} = props
  if (!value) return (null)

  const customizedLabelWidth = 46
  const customizedLabelHeight = 20
  const customizedLabelPadding = r + 2
  const customizedLabelPosThresholdToggle = customizedLabelHeight + customizedLabelPadding
  const customizedLabelOffsetHeight = (customizedLabelHeight/2 + customizedLabelPadding) *
    (cy > customizedLabelPosThresholdToggle ? -1 : 1)

  const formattedText = formatter(value)
  const extraWidth = Math.max(0, formattedText.length - 6) * 5

  const strokeColor = (positiveStroke || negativeStroke) ? (value > 0 ? positiveStroke : negativeStroke) : stroke

  return (
    <g>
      <circle cx={cx} cy={cy} r={r} fill={strokeColor} stroke={strokeColor} strokeWidth={strokeWidth} />
      <rect
        x={cx - (customizedLabelWidth + extraWidth) / 2}
        y={cy - customizedLabelHeight/2 + customizedLabelOffsetHeight}
        width={customizedLabelWidth + extraWidth}
        height={customizedLabelHeight}
        fill={strokeColor}
        stroke={strokeColor}
        strokeWidth={strokeWidth} />
      <text
        x={cx}
        y={cy + customizedLabelOffsetHeight}
        fill='#fff'
        strokeWidth={0}
        textAnchor="middle"
        dominantBaseline="central"
        fontFamily="Roboto"
        fontSize="11"
      >
        {formattedText}
      </text>
    </g>
  )
}

CustomLineDotLabelBox.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  fill: PropTypes.string,
  r: PropTypes.number,
  stroke: PropTypes.string,
  positiveStroke: PropTypes.string,
  negativeStroke: PropTypes.string,
  strokeWidth: PropTypes.number,
  value: PropTypes.number,
  formatter: PropTypes.func,
}

CustomLineDotLabelBox.defaultProps = {
  cx: 0,
  cy: 0,
  fill: '#fff',
  r: 7,
  strokeWidth: 1,
  value: 0,
  formatter: v => v,
}

export default CustomLineDotLabelBox
