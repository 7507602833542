import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { VigenciaBandeira } from '../../components'
import * as vigenciaBandeiraActionCreators from '../../redux/modules/vigenciaBandeira'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'
import moment from 'moment'

class VigenciaBandeiraContainer extends Component {

  static propTypes = {
    history: PropTypes.object,
    multipleVigenciaBandeira: PropTypes.object,
    fetchMultipleVigenciaBandeira: PropTypes.func,
    destroyVigenciaBandeira: PropTypes.func,
    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string
  }

  async componentDidMount () {
    await this.props.fetchMultipleVigenciaBandeira()
  }

  handleRedirectToNew = () => {
    this.props.history.push('/gestao_bandeiras/vigencias/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/gestao_bandeiras/vigencias/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyVigenciaBandeira(id)
      ? this.props.showSuccessfulFlashMessage('', 'destroy')
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  handleSort = column => () => this.props.updateSort('vigenciaBandeira', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('vigenciaBandeira', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleVigenciaBandeira.toList().toJSON(),
      'vigenciaBandeira',
      [
        'id',
        {
          label: 'Início da Vigência',
          value: row => moment(row.starts_at, 'YYYY-MM-DD').format('DD/MM/YYYY')
        },
        {
          label: 'Bandeira',
          value: 'bandeira.nome'
        }
      ],
    )
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <VigenciaBandeira
        onRedirectToNew={this.handleRedirectToNew}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        multipleVigenciaBandeira={this.props.multipleVigenciaBandeira}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV}
        isLoading={this.props.isLoading}
      />
    )
  }
}

function mapStateToProps ({vigenciaBandeira, status, sort, filter}) {
  const sortColumn = sort.getIn(['vigenciaBandeira', 'sortColumn']) || 'nome'
  const sortDirection = sort.getIn(['vigenciaBandeira', 'sortDirection']) || 'ascending'
  const filterText = filter.getIn(['vigenciaBandeira', 'filterText']) || ''

  let map = filterMap(vigenciaBandeira, ['starts_at', 'bandeira.nome'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleVigenciaBandeira: map,
    error: status.getIn(['vigenciaBandeira', 'error']),
    isLoading: status.getIn(['vigenciaBandeira', 'isLoading']),
    sortColumn,
    sortDirection,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...flashMessageAcionCreators,
    ...vigenciaBandeiraActionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VigenciaBandeiraContainer))
