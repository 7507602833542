import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form/immutable'
import { Button, Form, FormField, Grid, Icon } from 'semantic-ui-react'
import { SemanticInput, SemanticDropdown } from '../FormComponents'
import { TextAreaField } from 'react-semantic-redux-form'
import { required } from '../../utils/validations'

const tipoDropdown = [
  { key: 1, text: 'Positivo', value: 1 },
  { key: 2, text: 'Alerta', value: 0 },
  { key: 3, text: 'Negativo', value: -1 },
]

const RenderRecados = ({fields, loading, onToggleMode, isLoading }) => {
  return (
    <Grid className='form-recados'>
      {fields.map((recado, index) => (
        <React.Fragment key={index}>
          <Grid.Row columns={3} key={index}>
            <Grid.Column width={4}>
              <FormField>
                <label htmlFor={`relatorio.${recado}.tipo`}>Tipo</label>
                <Field fluid button labeled icon='sort' className='icon'
                  name={`${recado}.tipo`}
                  validate={[required]}
                  options={tipoDropdown}
                  component={SemanticDropdown}
                />
              </FormField>
            </Grid.Column>
            <Grid.Column width={12}>
              <Form.Group>
                <FormField style={{width: '100%'}}>
                  <label htmlFor={`relatorio.${recado}.resumo`}>Resumo</label>
                  <Field floated='left'
                    name={`${recado}.resumo`}
                    validate={[required]}
                    component={SemanticInput}
                    loading={loading}
                  />
                </FormField>
                <Form.Button label='&nbsp;' type='button' tabIndex='-1'
                  floated='right' icon onClick={() => fields.remove(index)}>
                  <Icon name='delete' />
                </Form.Button>
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} key={index}>
            <Grid.Column width={16}>
              <FormField>
                <label htmlFor={`relatorio.${recado}.conteudo`}>Conteúdo</label>
                <Field floated='left'
                  name={`${recado}.conteudo`}
                  validate={[required]}
                  component={TextAreaField}
                  loading={loading}
                />
              </FormField>
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      ))}
      <Grid.Row columns={1}>
        <Grid.Column>
          <Button
            color='default'
            onClick={onToggleMode}>
            <Icon name='cancel' /> Cancelar
          </Button>
          <Button color='green' type='submit' disabled={isLoading}>
            <Icon name='checkmark' /> Salvar
          </Button>
          <Button color='yellow' floated='right' icon
            onClick={() => fields.push()}>
            <Icon name='plus' />{' '} Adicionar
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

RenderRecados.propTypes = {
  fields: PropTypes.object,
  loading: PropTypes.bool,
  onToggleMode: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default RenderRecados
