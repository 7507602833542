import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_VIGENCIA_TRIBUTO = 'LOADING_VIGENCIA_TRIBUTO'
export function loadingVigenciaTributo () {
  return {
    type: LOADING_VIGENCIA_TRIBUTO,
  }
}

export const LOADING_VIGENCIA_TRIBUTO_SUCCESS = 'LOADING_VIGENCIA_TRIBUTO_SUCCESS'
export function loadingVigenciaTributoSuccess (vigenciaTributo) {
  return {
    type: LOADING_VIGENCIA_TRIBUTO_SUCCESS,
    vigenciaTributo,
  }
}

export const LOADING_VIGENCIA_TRIBUTO_FAILURE = 'LOADING_VIGENCIA_TRIBUTO_FAILURE'
export function loadingVigenciaTributoFailure (error) {
  return {
    type: LOADING_VIGENCIA_TRIBUTO_FAILURE,
    error,
  }
}

export const DESTROYING_VIGENCIA_TRIBUTO_SUCCESS = 'DESTROYING_VIGENCIA_TRIBUTO_SUCCESS'
export function destroyingVigenciaTributoSuccess (vigenciaTributoId) {
  return {
    type: DESTROYING_VIGENCIA_TRIBUTO_SUCCESS,
    vigenciaTributoId,
  }
}

const initialState = Map({})

export function fetchMultipleVigenciaTributo (concessionariaId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaTributo())
      const multipleVigenciaTributo = await callAPI(`/concessionarias/${concessionariaId}/tributos`)
      dispatch(loadingVigenciaTributoSuccess(multipleVigenciaTributo))
      return multipleVigenciaTributo
    } catch (e) {
      dispatch(loadingVigenciaTributoFailure(e))
      return false
    }
  }
}

export function fetchVigenciaTributo (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaTributo())
      const vigenciaTributo = await callAPI(`/tributos/${id}`)
      dispatch(loadingVigenciaTributoSuccess(vigenciaTributo))
      return vigenciaTributo
    } catch (e) {
      dispatch(loadingVigenciaTributoFailure(e))
      return false
    }
  }
}

export function createVigenciaTributo (concessionariaId, vigenciaTributo) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaTributo())
      const newVigenciaTributo = await callAPI(`/concessionarias/${concessionariaId}/tributos`, 'POST', vigenciaTributo)
      dispatch(loadingVigenciaTributoSuccess(newVigenciaTributo))
      return newVigenciaTributo
    } catch (e) {
      dispatch(loadingVigenciaTributoFailure(e))
      return false
    }
  }
}

export function updateVigenciaTributo (id, vigenciaTributo) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaTributo())
      const newVigenciaTributo = await callAPI(`/tributos/${id}`, 'PUT', vigenciaTributo)
      dispatch(loadingVigenciaTributoSuccess(newVigenciaTributo))
      return newVigenciaTributo
    } catch (e) {
      dispatch(loadingVigenciaTributoFailure(e))
      return false
    }
  }
}

export function destroyVigenciaTributo (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaTributo())
      await callAPI(`/tributos/${id}`, 'DELETE')
      dispatch(destroyingVigenciaTributoSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingVigenciaTributoFailure(e))
      return false
    }
  }
}

export default function vigenciaTributo (state = initialState, action) {
  switch (action.type) {

    case LOADING_VIGENCIA_TRIBUTO_SUCCESS : {
      const { vigenciaTributo } = action
      if (vigenciaTributo.constructor === Array) {
        state = initialState
        vigenciaTributo.forEach((vigencia) => (
          state = state.merge({[vigencia.id]: vigencia})
        ))
        return state
      }
      return state.merge({[vigenciaTributo.id]: vigenciaTributo})
    }

    case DESTROYING_VIGENCIA_TRIBUTO_SUCCESS :
      return state.delete(action.vigenciaTributoId.toString())

    default :
      return state
  }
}
