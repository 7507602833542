import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_VIGENCIA_BANDEIRA = 'LOADING_VIGENCIA_BANDEIRA'
export function loadingVigenciaBandeira () {
  return {
    type: LOADING_VIGENCIA_BANDEIRA,
  }
}

export const LOADING_VIGENCIA_BANDEIRA_SUCCESS = 'LOADING_VIGENCIA_BANDEIRA_SUCCESS'
export function loadingVigenciaBandeiraSuccess (vigenciaBandeira) {
  return {
    type: LOADING_VIGENCIA_BANDEIRA_SUCCESS,
    vigenciaBandeira,
  }
}

export const LOADING_VIGENCIA_BANDEIRA_FAILURE = 'LOADING_VIGENCIA_BANDEIRA_FAILURE'
export function loadingVigenciaBandeiraFailure (error) {
  return {
    type: LOADING_VIGENCIA_BANDEIRA_FAILURE,
    error,
  }
}

export const DESTROYING_VIGENCIA_BANDEIRA_SUCCESS = 'DESTROYING_VIGENCIA_BANDEIRA_SUCCESS'
export function destroyingVigenciaBandeiraSuccess (vigenciaBandeiraId) {
  return {
    type: DESTROYING_VIGENCIA_BANDEIRA_SUCCESS,
    vigenciaBandeiraId,
  }
}

const initialState = Map({})

export function fetchMultipleVigenciaBandeira () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaBandeira())
      const multipleVigenciaBandeira = await callAPI('/gestao_bandeiras/vigencias')
      dispatch(loadingVigenciaBandeiraSuccess(multipleVigenciaBandeira))
      return multipleVigenciaBandeira
    } catch (e) {
      dispatch(loadingVigenciaBandeiraFailure(e))
      return false
    }
  }
}

export function fetchVigenciaBandeira (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaBandeira())
      const vigenciaBandeira = await callAPI(`/gestao_bandeiras/vigencias/${id}`)
      dispatch(loadingVigenciaBandeiraSuccess(vigenciaBandeira))
      return vigenciaBandeira
    } catch (e) {
      dispatch(loadingVigenciaBandeiraFailure(e))
      return false
    }
  }
}

export function createVigenciaBandeira (vigenciaBandeira) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaBandeira())
      const newVigenciaBandeira = await callAPI('/gestao_bandeiras/vigencias', 'POST', vigenciaBandeira)
      dispatch(loadingVigenciaBandeiraSuccess(newVigenciaBandeira))
      return newVigenciaBandeira
    } catch (e) {
      dispatch(loadingVigenciaBandeiraFailure(e))
      return false
    }
  }
}

export function updateVigenciaBandeira (id, vigenciaBandeira) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaBandeira())
      const newVigenciaBandeira = await callAPI(`/gestao_bandeiras/vigencias/${id}`, 'PUT', vigenciaBandeira)
      dispatch(loadingVigenciaBandeiraSuccess(newVigenciaBandeira))
      return newVigenciaBandeira
    } catch (e) {
      dispatch(loadingVigenciaBandeiraFailure(e))
      return false
    }
  }
}

export function destroyVigenciaBandeira (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaBandeira())
      await callAPI(`/gestao_bandeiras/vigencias/${id}`, 'DELETE')
      dispatch(destroyingVigenciaBandeiraSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingVigenciaBandeiraFailure(e))
      return false
    }
  }
}

export default function vigenciaBandeira (state = initialState, action) {
  switch (action.type) {

    case LOADING_VIGENCIA_BANDEIRA_SUCCESS : {
      const { vigenciaBandeira } = action
      if (vigenciaBandeira.constructor === Array) {
        state = initialState
        vigenciaBandeira.forEach((vigencia) => (
          state = state.merge({[vigencia.id]: vigencia})
        ))
        return state
      }
      return state.merge({[vigenciaBandeira.id]: vigenciaBandeira})
    }

    case DESTROYING_VIGENCIA_BANDEIRA_SUCCESS :
      return state.delete(action.vigenciaBandeiraId.toString())

    default :
      return state
  }
}
