import React from 'react'
import PropTypes from 'prop-types'
import { Label, Table, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { CRUDIndex } from '../CRUD'
import { EditDeleteButtonContainer } from '../../containers'
import { maskCurrency } from '../../utils/masks'

const grupoLabel = (grupo) => {
  switch (grupo) {
    case 0:
      return 'A (Alta Tensão)'
    case 1:
      return 'B (Baixa Tensão)'
    default:
      return 'Grupo Inválido'
  }
}

const Subgrupo = (props) => (
  <CRUDIndex
    title='Subgrupos de Tensão'
    icon='table'
    onRedirectToNew={props.onRedirectToNew}
    onExportToCSV={props.onExportToCSV}
    filterText={props.filterText}
    handleFilter={props.handleFilter}
  >
    <Table sortable celled striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1} textAlign='center'
            onClick={props.handleSort('id')}
            sorted={props.handleSorted('id')}>#</Table.HeaderCell>
          <Table.HeaderCell width={2}
            onClick={props.handleSort('nome')}
            sorted={props.handleSorted('nome')}>Nome</Table.HeaderCell>
          <Table.HeaderCell
            onClick={props.handleSort('descricao')}
            sorted={props.handleSorted('descricao')}>Descrição</Table.HeaderCell>
          <Table.HeaderCell
            onClick={props.handleSort('grupo')}
            sorted={props.handleSorted('grupo')}>Grupo</Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign='right'
            onClick={props.handleSort('fator_de_potencia')}
            sorted={props.handleSorted('fator_de_potencia')}>Fator de Potência</Table.HeaderCell>
          <Table.HeaderCell width={5}>Enquadramentos</Table.HeaderCell>
          <Table.HeaderCell width={1}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.isLoading && (
          <Table.Row>
            <Table.Cell colSpan='7'>
              <Loader active={props.isLoading} inline='centered' />
            </Table.Cell>
          </Table.Row>
        )}
        {props.multipleSubgrupo.valueSeq().map((subgrupo, index) => (
          <Table.Row key={index}>
            <Table.Cell textAlign='center'>
              <Label as={Link} to={`/subgrupos/${subgrupo.get('id')}`}>{subgrupo.get('id')}</Label>
            </Table.Cell>
            <Table.Cell>{subgrupo.get('nome')}</Table.Cell>
            <Table.Cell>{subgrupo.get('descricao')}</Table.Cell>
            <Table.Cell>{grupoLabel(subgrupo.get('grupo'))}</Table.Cell>
            <Table.Cell textAlign='right'>{maskCurrency.parse(subgrupo.get('fator_de_potencia'))}</Table.Cell>
            <Table.Cell>
              <div className='labelListWrapper'>
                {subgrupo.get('enquadramentos').map((enquadramento, index) => (
                  <div key={index} className='labelWrapper'>
                    <Label key={index} as={Link} to={`/enquadramentos/${enquadramento.get('id')}`}>
                      {enquadramento.get('modalidade')}
                    </Label>
                  </div>
                ))}
              </div>
            </Table.Cell>
            <Table.Cell textAlign='right'>
              <EditDeleteButtonContainer
                onEditResource={() => props.onEditResource(subgrupo.get('id'))}
                onDeleteResource={() => props.onDeleteResource(subgrupo.get('id'))} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </CRUDIndex>
)

Subgrupo.propTypes = {
  multipleSubgrupo: PropTypes.object,
  onRedirectToNew: PropTypes.func,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default Subgrupo
