const comparar = (original, simulado) => {

  const get = (origem, medicao, valor, tipo) => ({
    medicao: origem ? origem.getIn(['medicoes', medicao]) : 0.0,
    tarifa: origem ? origem.getIn(['tarifas', valor, 'proporcional', tipo === 'cru' ? 'crua' : 'calculada']) : 0.0,
    valor: origem ? origem.getIn(['valores', valor, tipo]) : 0.0,
  })

  const itemCalculado = (item, medicao, campo, unidade) => {
    return {
      [item]: {
        realizado_cru: { ...get(original, medicao, campo, 'cru'), unidade },
        realizado_calculado: { ...get(original, medicao, campo, 'calculado'), unidade },
        ...(simulado ? { simulado_cru: { ...get(simulado, medicao, campo, 'cru'), unidade }, } : {}),
        ...(simulado ? { simulado_calculado: { ...get(simulado, medicao, campo, 'calculado'), unidade }, } : {}),
      },
    }
  }

  return {
    consumo: {
      ponta: {
        ...itemCalculado('tusd', 'consumo_p', 'consumo_tusd_p', 'kWh'),
        ...itemCalculado('te', 'consumo_p', 'consumo_te_p', 'kWh'),
      },
      fora_ponta: {
        ...itemCalculado('tusd', 'consumo_fp', 'consumo_tusd_fp', 'kWh'),
        ...itemCalculado('te', 'consumo_fp', 'consumo_te_fp', 'kWh'),
      },
      int: {
        ...itemCalculado('tusd', 'consumo_int', 'consumo_tusd_int', 'kWh'),
        ...itemCalculado('te', 'consumo_int', 'consumo_te_int', 'kWh'),
      },
      na: {
        ...itemCalculado('tusd', 'consumo_na', 'consumo_tusd_na', 'kWh'),
        ...itemCalculado('te', 'consumo_na', 'consumo_te_na', 'kWh'),
      },
    },

    demanda: {
      ponta: {
        ...itemCalculado('tusd', 'demanda_p', 'demanda_p', 'kW'),
        ...itemCalculado('ultrapassagem', 'ultrapassagem_p', 'ultrapassagem_p', 'kW'),
        ...itemCalculado('isenta', 'demanda_isenta_p', 'demanda_isenta_p', 'kW'),
      },
      fora_ponta: {
        ...itemCalculado('tusd', 'demanda_fp', 'demanda_fp', 'kW'),
        ...itemCalculado('ultrapassagem', 'ultrapassagem_fp', 'ultrapassagem_fp', 'kW'),
        ...itemCalculado('isenta', 'demanda_isenta_fp', 'demanda_isenta_fp', 'kW'),
      },
      na: {
        ...itemCalculado('tusd', 'demanda_na', 'demanda_na', 'kW'),
        ...itemCalculado('ultrapassagem', 'ultrapassagem_na', 'ultrapassagem_na', 'kW'),
        ...itemCalculado('isenta', 'demanda_isenta_na', 'demanda_isenta_na', 'kW'),
      },
    },

    reativo: {
      ponta: {
        ...itemCalculado('dmcr', 'dmcr_p', 'dmcr_p', 'kvarh'),
        ...itemCalculado('ufer', 'ufer_p', 'ufer_p', 'kvarh'),
      },
      fora_ponta: {
        ...itemCalculado('dmcr', 'dmcr_fp', 'dmcr_fp', 'kvar'),
        ...itemCalculado('ufer', 'ufer_fp', 'ufer_fp', 'kvar'),
      },
      na: {
        ...itemCalculado('dmcr', 'dmcr_na', 'dmcr_na', 'kvar'),
      },
    },
  }
}

export default comparar
