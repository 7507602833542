import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'

const FonteEnergiaShow = (props) => {
  if (props.fonteEnergia) {
    return (
      <CRUDShow
        title={props.fonteEnergia.nome}
        icon='lightning'
        onEditResource={() => props.onEditResource(props.fonteEnergia.id)}
        onDeleteResource={() => props.onDeleteResource(props.fonteEnergia.id)}>
        <ul>
          <li>Nome: {props.fonteEnergia.nome}</li>
          <li>Descrição: {props.fonteEnergia.descricao}</li>
          <li>Incentivada: {props.fonteEnergia.incentivada ? 'Sim' : 'Não'}</li>
          <li>Incentivo: {props.fonteEnergia.incentivo}</li>
        </ul>
      </CRUDShow>
    )
  }

  return (null)
}

FonteEnergiaShow.propTypes = {
  fonteEnergia: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
}

export default FonteEnergiaShow
