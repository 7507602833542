import { fromJS, Map } from 'immutable'

export const UPDATE_FILTER = 'UPDATE_FILTER'
export function updateFilter(collection, name, value) {
  return {
    type: UPDATE_FILTER,
    collection,
    name,
    value
  }
}

const structure = {
  filterText: '',
}

const initialState = Map({})

export default function filter (state = initialState, action) {
  switch (action.type) {

    case UPDATE_FILTER : {
      const { collection, name, value } = action
      if (!state.has(collection)) state = state.set(collection, fromJS(structure))

      const currentValue = state.getIn([collection, name])
      if (value !== currentValue) {
        return state.setIn([collection, name], value)
      }

      return state
    }

    default :
      return state
  }
}
