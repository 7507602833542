import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_INSTALACAO = 'LOADING_INSTALACAO'
export function loadingInstalacao () {
  return {
    type: LOADING_INSTALACAO,
  }
}

export const LOADING_INSTALACAO_SUCCESS = 'LOADING_INSTALACAO_SUCCESS'
export function loadingInstalacaoSuccess (instalacao) {
  return {
    type: LOADING_INSTALACAO_SUCCESS,
    instalacao,
  }
}

export const LOADING_INSTALACAO_FAILURE = 'LOADING_INSTALACAO_FAILURE'
export function loadingInstalacaoFailure (error) {
  return {
    type: LOADING_INSTALACAO_FAILURE,
    error,
  }
}

export const DESTROYING_INSTALACAO_SUCCESS = 'DESTROYING_INSTALACAO_SUCCESS'
export function destroyingInstalacaoSuccess (instalacaoId) {
  return {
    type: DESTROYING_INSTALACAO_SUCCESS,
    instalacaoId,
  }
}

const initialState = Map({})

export function fetchMultipleInstalacao (userId, apenasAtivos, tipoMercado) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingInstalacao())
      const multipleInstalacao = await callAPI(`/instalacoes?user_id=${userId}&ativo=${apenasAtivos}&tipo_mercado=${tipoMercado}`)
      dispatch(loadingInstalacaoSuccess(multipleInstalacao))
      return multipleInstalacao
    } catch (e) {
      dispatch(loadingInstalacaoFailure(e))
      return false
    }
  }
}

export function fetchInstalacao (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingInstalacao())
      const instalacao = await callAPI(`/instalacoes/${id}`)
      dispatch(loadingInstalacaoSuccess(instalacao))
      return instalacao
    } catch (e) {
      dispatch(loadingInstalacaoFailure(e))
      return false
    }
  }
}

export function createInstalacao (instalacao) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingInstalacao())
      const newInstalacao = await callAPI('/instalacoes', 'POST', instalacao)
      dispatch(loadingInstalacaoSuccess(newInstalacao))
      return newInstalacao
    } catch (e) {
      dispatch(loadingInstalacaoFailure(e))
      return false
    }
  }
}

export function updateInstalacao (id, instalacao) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingInstalacao())
      const newInstalacao = await callAPI(`/instalacoes/${id}`, 'PUT', instalacao)
      dispatch(loadingInstalacaoSuccess(newInstalacao))
      return newInstalacao
    } catch (e) {
      dispatch(loadingInstalacaoFailure(e))
      return false
    }
  }
}

export function destroyInstalacao (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingInstalacao())
      await callAPI(`/instalacoes/${id}`, 'DELETE')
      dispatch(destroyingInstalacaoSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingInstalacaoFailure(e))
      return false
    }
  }
}

export default function instalacao (state = initialState, action) {
  switch (action.type) {

    case LOADING_INSTALACAO_SUCCESS : {
      const { instalacao } = action
      if (instalacao.constructor === Array) {
        state = initialState
        instalacao.forEach((singleInstalacao) => (
          state = state.merge({[singleInstalacao.id]: singleInstalacao})
        ))
        return state
      }
      return state.merge({[instalacao.id]: instalacao})
    }

    case DESTROYING_INSTALACAO_SUCCESS :
      return state.delete(action.instalacaoId.toString())

    default :
      return state
  }
}
