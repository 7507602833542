import React from 'react'

import { Table } from 'semantic-ui-react'
import { maskFloat } from '../../../utils/masks'

import Detalhe from './Detalhe'

const Linha = (field, valores, tarifas, medicoes) => {
  const campos = field.campos
  let totalCru = 0.0
  let totalCalculado = 0.0

  const render = (
    <React.Fragment>
      {campos.map((campo, index) => {
        const valor = valores.get(campo.campo)
        const tarifa = tarifas.get(campo.campo)
        const medicao = medicoes.get(campo.medicao)
        const unidade = campo.unidade

        if (!valor || !tarifa || !medicao || !unidade) return (null)

        const cru = valor.get('cru')
        const calculado = valor.get('calculado')

        totalCru += parseFloat(cru)
        totalCalculado += parseFloat(calculado)

        return (
          <Table.Row key={index}>
            <Table.Cell textAlign='right'>{campo.descricao}</Table.Cell>
            <Detalhe tipo='cru' valor={valor} tarifa={tarifa} medicao={medicao} unidade={unidade} />
            <Detalhe tipo='calculado' valor={valor} tarifa={tarifa} medicao={medicao} unidade={unidade} />
          </Table.Row>
        )
      })}
    </React.Fragment>
  )

  if (!totalCru && !totalCalculado) return {
    render: null,
    cru: 0.0,
    calculado: 0.0,
  }

  return {
    render,
    cru: totalCru,
    calculado: totalCalculado,
  }
}

const Posto = (field, valores, tarifas, medicoes, total = true) => {
  const linha = Linha(field, valores, tarifas, medicoes)

  if (!linha.render) return {
    render: null,
    cru: 0.0,
    calculado: 0.0,
  }

  const render = (
    <React.Fragment>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={4} textAlign='right'>{field.descricao}</Table.HeaderCell>
          <Table.HeaderCell width={6}></Table.HeaderCell>
          <Table.HeaderCell width={6}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {linha.render}
        {total && (
          <Table.Row className='pseudo-footer'>
            <Table.Cell textAlign='right'>Subtotal</Table.Cell>
            <Table.Cell textAlign='right' style={{fontFamily: 'monospace'}}>{maskFloat.parse(linha.cru.toFixed(2))}</Table.Cell>
            <Table.Cell textAlign='right' style={{fontFamily: 'monospace'}}>{maskFloat.parse(linha.calculado.toFixed(2))}</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Footer>
      </Table.Footer>
    </React.Fragment>
  )

  return {
    render,
    cru: linha.cru,
    calculado: linha.calculado,
  }
}

export default Posto
