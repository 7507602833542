import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'

const FuncaoShow = (props) => {
  if (props.funcao) {
    return (
      <CRUDShow
        title={props.funcao.nome}
        icon='suitcase'
        onEditResource={() => props.onEditResource(props.funcao.id)}
        onDeleteResource={() => props.onDeleteResource(props.funcao.id)}>
        <ul>
          <li>Nome: {props.funcao.nome}</li>
        </ul>
      </CRUDShow>
    )
  }
}

FuncaoShow.propTypes = {
  funcao: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
}

export default FuncaoShow
