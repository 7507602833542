import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { ConcessionariaForm } from '../../components'
import * as concessionariaActionCreators from '../../redux/modules/concessionaria'
import * as ufActionCreators from '../../redux/modules/uf'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { mapToDropdown } from '../../utils/parse'

class ConcessionariaFormContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,

    fetchConcessionaria: PropTypes.func.isRequired,
    createConcessionaria: PropTypes.func.isRequired,
    updateConcessionaria: PropTypes.func.isRequired,

    multipleUf: PropTypes.object,
    fetchMultipleUf: PropTypes.func.isRequired,
    ufDropdown: PropTypes.array.isRequired,

    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleUf()
    if (this.props.id) {
      const concessionaria = await this.props.fetchConcessionaria(this.props.id)
      this.props.initialize('ConcessionariaForm', {concessionaria})
    }
  }

  handleSubmitResource = async (concessionaria) => {
    if (this.props.id) {
      if (await this.props.updateConcessionaria(this.props.id, concessionaria)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/concessionarias/${this.props.id}`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createConcessionaria(concessionaria)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/concessionarias')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <ConcessionariaForm
        ufDropdown={this.props.ufDropdown}
        multipleUf={this.props.multipleUf}
        onSubmit={(concessionaria) => this.handleSubmitResource(concessionaria)}
        loading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({concessionaria, uf, status, flashMessage}, {match}) {
  const isLoading = status.getIn(['concessionaria', 'isLoading']) || status.getIn(['uf', 'isLoading'])
  const { id } = match.params

  const ufDropdown = mapToDropdown(
    uf,
    e => e.get('id'),
    e => `${e.get('sigla')} - ${e.get('nome')}`
  )

  if (id) {
    return {
      id,
      concessionaria: concessionaria.get('id'),
      ufDropdown,
      error: status.getIn(['concessionaria', 'error']),
      shouldHideFlashMessage: flashMessage.get('isError'),
      isLoading,
    }
  }
  return {
    ufDropdown,
    error: status.getIn(['concessionaria', 'error']),
    shouldHideFlashMessage: flashMessage.get('isError'),
    isLoading,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...concessionariaActionCreators,
    ...ufActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ConcessionariaFormContainer)
