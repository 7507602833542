import React from 'react'
import PropTypes from 'prop-types'

import { Icon, Loader, Message } from 'semantic-ui-react'
import { CRUDShow } from '../CRUD'
import TotalTable from './TotalTable'
import DataTable from './DataTable'
import SimpleTable from './SimpleTable'
import Gerador from './Gerador'
import Posto from './utils/Posto'

import Comparacao from './Comparacao'
import CenarioSelector from './CenarioSelector'

import { fromJS } from 'immutable'

const fieldsBandeira = [
  {
    descricao: 'Mês Inicial',
    campos: [
      { campo: 'consumo_mes_inicial', descricao: 'Bandeira Inicial', medicao: 'consumo_mes_inicial', unidade: 'kWh' }
    ],
  },
  {
    descricao: 'Mês Final',
    campos: [
      { campo: 'consumo_mes_final', descricao: 'Bandeira Final', medicao: 'consumo_mes_final', unidade: 'kWh' }
    ],
  },
]

const fieldsOutros = [
  {
    field: 'iluminacao_publica',
    descricao: 'Iluminação Pública',
  },
  {
    field: 'encargos_conexao',
    descricao: 'Encargos de Conexão',
  },
  {
    field: 'multas',
    descricao: 'Multas',
  },
  {
    field: 'juros',
    descricao: 'Juros',
  },
  {
    field: 'correcao_monetaria',
    descricao: 'Correção Monetária',
  },
]

const ResultadoShow = (props) => {
  const { resultado, faturaPedagio, onSimular, simulacao, multipleCenario } = props

  if (faturaPedagio.get('aasm_state') === 'historica') {
    return (
      <Message warning>
        <Message.Header>Atenção</Message.Header>
        <p>Faturas históricas não possuem resultados calculados.</p>
      </Message>
    )
  }

  if ([
    resultado,
    faturaPedagio,
  ].some(v => !v)) return (<Loader inline='centered' active={props.isLoading} />)

  const resultadoRealizado = resultado.get('data')
  const resultadoSimulado = simulacao && simulacao.result && fromJS(simulacao.result)

  const tarifas = resultado.getIn(['data', 'tarifas'])
  const medicoes = resultado.getIn(['data', 'medicoes'])
  const valores = resultado.getIn(['data', 'valores'])

  const fixos = resultado.getIn(['data', 'fixos'])
  const extras = resultado.getIn(['data', 'extras'])

  const gerador = resultado.getIn(['data', 'gerador'])

  // const impostos = resultado.getIn(['data', 'impostos'])
  // const info_demanda = medicoes.getIn(['info_demanda'])

  const meta = resultado.getIn(['data', 'meta'])
  const total = resultado.getIn(['data', 'total'])

  const totalSimulado = resultadoSimulado && resultadoSimulado.get('total')

  const bandeiraInicial = meta.getIn(['bandeira', 'inicial'])
  const bandeiraFinal = meta.getIn(['bandeira', 'final'])

  if (bandeiraInicial) {
    fieldsBandeira[0].campos[0].descricao = <span>{bandeiraInicial.get('bandeira')}{' '}<Icon name='flag' style={{color: bandeiraInicial.get('cor')}} /></span>
  }

  if (bandeiraFinal) {
    fieldsBandeira[1].campos[0].descricao = <span>{bandeiraFinal.get('bandeira')}{' '}<Icon name='flag' style={{color: bandeiraFinal.get('cor')}} /></span>
  }

  return (
    <CRUDShow noTitle noToolbar padded={false}>
      <CenarioSelector multipleCenario={multipleCenario} onSimular={onSimular} />
      <TotalTable title='Total da Fatura' color='rgba(0, 0, 0, 0.15)' total={total} simulado={totalSimulado} meta={meta} />
      <Comparacao realizado={resultadoRealizado} simulado={resultadoSimulado}  />
      <DataTable title='Bandeiras' color='rgba(0, 0, 0, 0.15)' data={fieldsBandeira.map(field => Posto(field, valores, tarifas, medicoes, false))} />
      <SimpleTable title='Outros' color='rgba(0, 0, 0, 0.15)' data={fieldsOutros
        .filter(field => parseFloat(fixos.get(field.field)))
        .map(field => {
          return {
            descricao: field.descricao,
            valor: fixos.get(field.field),
          }
        })
        .concat(extras.valueSeq().map(extra => {
          return {
            descricao: extra.get('descricao'),
            valor: extra.get('valor')
          }
        }).toArray())} />
      <Gerador title='Gerador' color='rgba(0, 0, 0, 0.15)' data={gerador} />
    </CRUDShow>
  )
}

ResultadoShow.propTypes = {
  resultado: PropTypes.object,
  faturaPedagio: PropTypes.object,
  simulacao: PropTypes.object,
  isLoading: PropTypes.bool,
  onSimular: PropTypes.func,
  multipleCenario: PropTypes.object,
}

export default ResultadoShow
