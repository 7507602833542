import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Header, Icon } from 'semantic-ui-react'
import { FlashMessageContainer } from '../../containers'
import PageLoader from '../PageLoader/PageLoader'
import Toolbar from '../../components/Toolbar/Toolbar'

const CRUDIndex = (props) => {
  return (
    <Grid padded={props.padded}>
      <Grid.Row>
        {!props.noTitle && (
          <Grid.Column width={16}>
            <Header as={props.titleAs} dividing>
              {props.icon && (<Icon name={props.icon} />)}
              <Header.Content>
                {props.title}
              </Header.Content>
            </Header>
          </Grid.Column>
        )}
        <Grid.Column width={16}>
          <Toolbar
            filterText={props.filterText}
            handleFilter={props.handleFilter}
            onRedirectToNew={props.onRedirectToNew}
            onExportToCSV={props.onExportToCSV}
            customToolbarItems={props.customToolbarItems}
            customSearchItems={props.customSearchItems}
          />
        </Grid.Column>
        <Grid.Column width={16}>
          <FlashMessageContainer />
        </Grid.Column>
        <Grid.Column width={16}>
          <PageLoader active={props.isLoading} />
          {props.children}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

CRUDIndex.defaultProps = {
  padded: true,
  titleAs: 'h1',
}

CRUDIndex.propTypes = {
  title: PropTypes.string,
  titleAs: PropTypes.string,
  icon: PropTypes.string,
  isLoading: PropTypes.bool,

  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),

  noTitle: PropTypes.bool,
  padded: PropTypes.bool,

  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onRedirectToNew: PropTypes.func,
  onExportToCSV: PropTypes.func,
  customToolbarItems: PropTypes.array,
  customSearchItems: PropTypes.array,
}

export default CRUDIndex
