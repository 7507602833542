import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_VIGENCIA_VALOR = 'LOADING_VIGENCIA_VALOR'
export function loadingVigenciaValor () {
  return {
    type: LOADING_VIGENCIA_VALOR,
  }
}

export const LOADING_VIGENCIA_VALOR_SUCCESS = 'LOADING_VIGENCIA_VALOR_SUCCESS'
export function loadingVigenciaValorSuccess (vigenciaValor) {
  return {
    type: LOADING_VIGENCIA_VALOR_SUCCESS,
    vigenciaValor,
  }
}

export const LOADING_VIGENCIA_VALOR_FAILURE = 'LOADING_VIGENCIA_VALOR_FAILURE'
export function loadingVigenciaValorFailure (error) {
  return {
    type: LOADING_VIGENCIA_VALOR_FAILURE,
    error,
  }
}

export const DESTROYING_VIGENCIA_VALOR_SUCCESS = 'DESTROYING_VIGENCIA_VALOR_SUCCESS'
export function destroyingVigenciaValorSuccess (vigenciaValorId) {
  return {
    type: DESTROYING_VIGENCIA_VALOR_SUCCESS,
    vigenciaValorId,
  }
}

const initialState = Map({})

export function fetchMultipleVigenciaValor () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaValor())
      const multipleVigenciaValor = await callAPI('/gestao_bandeiras/valores')
      dispatch(loadingVigenciaValorSuccess(multipleVigenciaValor))
      return multipleVigenciaValor
    } catch (e) {
      dispatch(loadingVigenciaValorFailure(e))
      return false
    }
  }
}

export function fetchVigenciaValor (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaValor())
      const vigenciaValor = await callAPI(`/gestao_bandeiras/valores/${id}`)
      dispatch(loadingVigenciaValorSuccess(vigenciaValor))
      return vigenciaValor
    } catch (e) {
      dispatch(loadingVigenciaValorFailure(e))
      return false
    }
  }
}

export function createVigenciaValor (vigenciaValor) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaValor())
      const newVigenciaValor = await callAPI('/gestao_bandeiras/valores', 'POST', vigenciaValor)
      dispatch(loadingVigenciaValorSuccess(newVigenciaValor))
      return newVigenciaValor
    } catch (e) {
      dispatch(loadingVigenciaValorFailure(e))
      return false
    }
  }
}

export function updateVigenciaValor (id, vigenciaValor) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaValor())
      const newVigenciaValor = await callAPI(`/gestao_bandeiras/valores/${id}`, 'PUT', vigenciaValor)
      dispatch(loadingVigenciaValorSuccess(newVigenciaValor))
      return newVigenciaValor
    } catch (e) {
      dispatch(loadingVigenciaValorFailure(e))
      return false
    }
  }
}

export function destroyVigenciaValor (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingVigenciaValor())
      await callAPI(`/gestao_bandeiras/valores/${id}`, 'DELETE')
      dispatch(destroyingVigenciaValorSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingVigenciaValorFailure(e))
      return false
    }
  }
}

export default function vigenciaValor (state = initialState, action) {
  switch (action.type) {

    case LOADING_VIGENCIA_VALOR_SUCCESS : {
      const { vigenciaValor } = action
      if (vigenciaValor.constructor === Array) {
        state = initialState
        vigenciaValor.forEach((vigencia) => (
          state = state.merge({[vigencia.id]: vigencia})
        ))
        return state
      }
      return state.merge({[vigenciaValor.id]: vigenciaValor})
    }

    case DESTROYING_VIGENCIA_VALOR_SUCCESS :
      return state.delete(action.vigenciaValorId.toString())

    default :
      return state
  }
}
