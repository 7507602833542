import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Label, Loader, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { CRUDIndex } from '../CRUD'
import { EditDeleteButtonContainer } from '../../containers'

const Bandeira = (props) => (
  <CRUDIndex noTitle padded={false}
    onRedirectToNew={props.onRedirectToNew}
    onExportToCSV={props.onExportToCSV}
    filterText={props.filterText}
    handleFilter={props.handleFilter}
  >
    <Table sortable celled striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1} textAlign='center'
            onClick={props.handleSort('id')}
            sorted={props.handleSorted('id')}>#</Table.HeaderCell>
          <Table.HeaderCell width={2}
            onClick={props.handleSort('nome')}
            sorted={props.handleSorted('nome')}>Bandeira</Table.HeaderCell>
          <Table.HeaderCell
            onClick={props.handleSort('descricao')}
            sorted={props.handleSorted('descricao')}>Descrição</Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign='center'
            onClick={props.handleSort('cor')}
            sorted={props.handleSorted('cor')}>Cor</Table.HeaderCell>
          <Table.HeaderCell width={1}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.isLoading && (
          <Table.Row>
            <Table.Cell colSpan='5'>
              <Loader active={props.isLoading} inline='centered' />
            </Table.Cell>
          </Table.Row>
        )}
        {props.multipleBandeira.valueSeq().map((bandeira, index) => (
          <Table.Row key={index}>
            <Table.Cell textAlign='center'>
              <Label as={Link} to={`/gestao_bandeiras/bandeiras/${bandeira.get('id')}`}>
                {bandeira.get('id')}
              </Label>
            </Table.Cell>
            <Table.Cell>{bandeira.get('nome')}</Table.Cell>
            <Table.Cell>{bandeira.get('descricao')}</Table.Cell>
            <Table.Cell textAlign='center'>
              <Icon name='flag' size='big' style={{color: bandeira.get('cor')}} />
            </Table.Cell>
            <Table.Cell>
              <EditDeleteButtonContainer
                onEditResource={() => props.onEditResource(bandeira.get('id'))}
                onDeleteResource={() => props.onDeleteResource(bandeira.get('id'))} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </CRUDIndex>
)

Bandeira.propTypes = {
  onRedirectToNew: PropTypes.func,
  multipleBandeira: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default Bandeira
