import React from 'react'
import PropTypes from 'prop-types'
import { Header, Table } from 'semantic-ui-react'
import { CRUDShow } from '../CRUD'
import { maskFloat } from '../../utils/masks'
import { formatDate } from '../../utils/formats'

const [ ALTA_TENSAO, BAIXA_TENSAO ]  = [ 0, 1 ]
const [ ROWSPAN_ALTA_TENSAO, ROWSPAN_BAIXA_TENSAO ]  = [ 2, 3 ]

const VigenciaTarifaShow = (props) => {
  if (props.vigenciaTarifa) {
    return (
      <CRUDShow
        title={'Detalhe de Tarifa'}
        titleAs={'h3'}
        icon='money'
        padded={false}
        isLoading={props.isLoading}
        onEditResource={() => props.onEditResource(props.vigenciaTarifa.id)}
        onDeleteResource={() => props.onDeleteResource(props.vigenciaTarifa.id)}>
        <Header as='h2' block content={formatDate(props.vigenciaTarifa.starts_at)} subheader='Início da Vigência' />
        <Table celled className='tableTarifa'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign='center' width={2} rowSpan={2}>Subgrupo</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={1} rowSpan={2}>Enquadramento</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={1} rowSpan={2}>Posto</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={6} rowSpan={1} colSpan={2}>TUSD</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={3} rowSpan={1}>TE</Table.HeaderCell>
              <Table.HeaderCell textAlign='center' width={3} rowSpan={1}>TR</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell textAlign='right'>R$/kW</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>R$/kWh</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>R$/kWh</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'>R$/kWh</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {props.vigenciaTarifa.valores_tarifas.map((valorTarifa, index) => {
            const subgrupo = props.multipleSubgrupo.get(`${valorTarifa.subgrupo_id}`)
            const enquadramento = props.multipleEnquadramento.get(`${valorTarifa.enquadramento_id}`)
            const grupo = parseInt(subgrupo.get('grupo'), 10)
            const rowSpan = grupo === ALTA_TENSAO ? ROWSPAN_ALTA_TENSAO : ROWSPAN_BAIXA_TENSAO

            let values = [
              parseFloat(valorTarifa.tusd_kw_p),
              parseFloat(valorTarifa.tusd_kwh_p),
              parseFloat(valorTarifa.te_kwh_p),
              parseFloat(valorTarifa.tr_kwh),
              parseFloat(valorTarifa.tusd_kw_fp),
              parseFloat(valorTarifa.tusd_kwh_fp),
              parseFloat(valorTarifa.te_kwh_fp),
              parseFloat(valorTarifa.tusd_kw_int),
              parseFloat(valorTarifa.tusd_kwh_int),
              parseFloat(valorTarifa.te_kwh_int),
            ]

            let classNames = [
              parseFloat(valorTarifa.tusd_kw_p) > 0.0 ? '' : 'fadeCell',
              parseFloat(valorTarifa.tusd_kwh_p) > 0.0 ? '' : 'fadeCell',
              parseFloat(valorTarifa.te_kwh_p) > 0.0 ? '' : 'fadeCell',
              parseFloat(valorTarifa.tr_kwh) > 0.0 ? '' : 'fadeCell',
              parseFloat(valorTarifa.tusd_kw_fp) > 0.0 ? '' : 'fadeCell',
              parseFloat(valorTarifa.tusd_kwh_fp) > 0.0 ? '' : 'fadeCell',
              parseFloat(valorTarifa.te_kwh_fp) > 0.0 ? '' : 'fadeCell',
              parseFloat(valorTarifa.tusd_kw_int) > 0.0 ? '' : 'fadeCell',
              parseFloat(valorTarifa.tusd_kwh_int) > 0.0 ? '' : 'fadeCell',
              parseFloat(valorTarifa.te_kwh_int) > 0.0 ? '' : 'fadeCell',
            ]

            const shouldShow = values.some(v => v > 0.0)

            if (!shouldShow) return (null)

            values = values.map(v => maskFloat.parse(v))

            return (
              <Table.Body key={index}>
                <Table.Row>
                  <Table.Cell textAlign='center' rowSpan={rowSpan}>{ subgrupo.get('nome') }</Table.Cell>
                  <Table.Cell textAlign='center' rowSpan={rowSpan}>{ enquadramento.get('modalidade') }</Table.Cell>
                  <Table.Cell textAlign='center' rowSpan={1}>P</Table.Cell>
                  <Table.Cell className={classNames[0]} textAlign='right' rowSpan={1}>{values[0]}</Table.Cell>
                  <Table.Cell className={classNames[1]} textAlign='right' rowSpan={1}>{values[1]}</Table.Cell>
                  <Table.Cell className={classNames[2]} textAlign='right' rowSpan={1}>{values[2]}</Table.Cell>
                  <Table.Cell className={classNames[3]} textAlign='right' rowSpan={rowSpan}>{values[3]}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign='center' rowSpan={1}>FP</Table.Cell>
                  <Table.Cell className={classNames[4]} textAlign='right' rowSpan={1}>{values[4]}</Table.Cell>
                  <Table.Cell className={classNames[5]} textAlign='right' rowSpan={1}>{values[5]}</Table.Cell>
                  <Table.Cell className={classNames[6]} textAlign='right' rowSpan={1}>{values[6]}</Table.Cell>
                </Table.Row>
                {grupo === BAIXA_TENSAO && (
                  <Table.Row>
                    <Table.Cell textAlign='center' rowSpan={1}>INT</Table.Cell>
                    <Table.Cell className={classNames[7]} textAlign='right' rowSpan={1}>{values[7]}</Table.Cell>
                    <Table.Cell className={classNames[8]} textAlign='right' rowSpan={1}>{values[8]}</Table.Cell>
                    <Table.Cell className={classNames[9]} textAlign='right' rowSpan={1}>{values[9]}</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            )
          })}
        </Table>
      </CRUDShow>
    )
  }

  return (null)
}

VigenciaTarifaShow.propTypes = {
  vigenciaTarifa: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  multipleSubgrupo: PropTypes.object,
  multipleEnquadramento: PropTypes.object,
  isLoading: PropTypes.bool,
}

export default VigenciaTarifaShow
