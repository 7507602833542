import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { TributoShow } from '../../components'
import * as tributoActionCreators from '../../redux/modules/tributo'

class TributoShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    fetchTributo: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    destroyTributo: PropTypes.func.isRequired,
  }

  state = {
    tributo: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const tributo = await this.props.fetchTributo(this.props.id)
      this.setState({ tributo })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/tributos/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/tributos/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyTributo(id)
    this.props.history.push('/tributos')
  }

  render () {
    return (
      <TributoShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        tributo={this.state.tributo} />
    )
  }
}

function mapStateToProps ({tributo}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      tributo: tributo.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...tributoActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TributoShowContainer)
