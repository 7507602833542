import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_CONTRATO_DEMANDA_INSTALACAO = 'LOADING_CONTRATO_DEMANDA_INSTALACAO'
export function loadingContratoDemandaInstalacao () {
  return {
    type: LOADING_CONTRATO_DEMANDA_INSTALACAO,
  }
}

export const LOADING_CONTRATO_DEMANDA_INSTALACAO_SUCCESS = 'LOADING_CONTRATO_DEMANDA_INSTALACAO_SUCCESS'
export function loadingContratoDemandaInstalacaoSuccess (contratoDemandaInstalacao) {
  return {
    type: LOADING_CONTRATO_DEMANDA_INSTALACAO_SUCCESS,
    contratoDemandaInstalacao,
  }
}

export const LOADING_CONTRATO_DEMANDA_INSTALACAO_FAILURE = 'LOADING_CONTRATO_DEMANDA_INSTALACAO_FAILURE'
export function loadingContratoDemandaInstalacaoFailure (error) {
  return {
    type: LOADING_CONTRATO_DEMANDA_INSTALACAO_FAILURE,
    error,
  }
}

const initialState = Map({})

export function fetchMultipleContratoDemandaInstalacao (instalacaoId, year) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContratoDemandaInstalacao())
      const multipleContratoDemandaInstalacao = await callAPI(`/instalacoes/${instalacaoId}/contratos_demanda?ano=${year}`)
      dispatch(loadingContratoDemandaInstalacaoSuccess(multipleContratoDemandaInstalacao))
      return multipleContratoDemandaInstalacao
    } catch (e) {
      dispatch(loadingContratoDemandaInstalacaoFailure(e))
      return false
    }
  }
}

export function updateContratoDemandaInstalacao (instalacaoId, contratoDemandaInstalacao) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingContratoDemandaInstalacao())
      const newContratoDemandaInstalacao = await callAPI(`/instalacoes/${instalacaoId}/contratos_demanda`, 'PUT', contratoDemandaInstalacao)
      dispatch(loadingContratoDemandaInstalacaoSuccess(newContratoDemandaInstalacao))
      return newContratoDemandaInstalacao
    } catch (e) {
      dispatch(loadingContratoDemandaInstalacaoFailure(e))
      return false
    }
  }
}

export default function contratoDemandaInstalacao (state = initialState, action) {
  switch (action.type) {

    case LOADING_CONTRATO_DEMANDA_INSTALACAO_SUCCESS : {
      const { contratoDemandaInstalacao } = action
      state = initialState
      contratoDemandaInstalacao.forEach((singleContratoDemandaInstalacao, index) => (
        state = state.merge({[index]: singleContratoDemandaInstalacao})
      ))
      return state
    }

    default :
      return state
  }
}
