import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'

const FaturaEnergiaShow = (props) => {
  if (props.faturaEnergia) {
    return (
      <CRUDShow
        title={props.faturaEnergia.id}
        icon='dollar'
        onEditResource={props.onEditResource}
        onDeleteResource={props.onDeleteResource}>
        <ul>
          <li>Consumo (kWh): {props.faturaEnergia.consumo}</li>
          <li>Perdas (%): {props.faturaEnergia.perdas}</li>
          <li>Valor Total (R$): {props.faturaEnergia.valor_total}</li>
          <li>Multas (R$): {props.faturaEnergia.multas}</li>
          <li>Juros (R$): {props.faturaEnergia.juros}</li>
          <li>Correção Monetária (R$): {props.faturaEnergia.correcao_monetaria}</li>
        </ul>
      </CRUDShow>
    )
  }

  return (null)
}

FaturaEnergiaShow.propTypes = {
  faturaEnergia: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
}

export default FaturaEnergiaShow
