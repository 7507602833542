import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, formValueSelector } from 'redux-form/immutable'

import { Table, Icon } from 'semantic-ui-react'
import { SemanticInputMask } from '../FormComponents'

import { required } from '../../utils/validations'
import { maskFloat } from '../../utils/masks'

const selector = formValueSelector('VigenciaValorForm')

let ValorBandeira = ({ valorBandeira, loading, bandeira_id, multipleBandeira }) => {
  const bandeira = multipleBandeira.get(`${bandeira_id}`)
  return (
    <Table.Row>
      <Table.Cell textAlign='right'>
        {bandeira.get('nome')}{' '}
      </Table.Cell>
      <Table.Cell textAlign='center'>
        <Icon name='flag' size='big' style={{color: bandeira.get('cor')}} />
      </Table.Cell>

      <Table.Cell>
        <Field fluid
          name={`${valorBandeira}.valor`}
          placeholder='0,0000'
          mask={maskFloat.mask(5)}
          validate={[required]}
          component={SemanticInputMask}
          loading={loading}
          label='R$/kWh'
        />
      </Table.Cell>
    </Table.Row>
  )
}

ValorBandeira = connect(
  (state, props) => {
    return ({
      bandeira_id: selector(state, `vigencia_valor.${props.valorBandeira}.bandeira_id`)
    })
  }
)(ValorBandeira)

function mapStateToProps ({ bandeira }) {
  return {
    multipleBandeira: bandeira,
  }
}

ValorBandeira.propTypes = {
  valorBandeira: PropTypes.string,
  loading: PropTypes.bool,
  bandeira_id: PropTypes.string,
  multipleBandeira: PropTypes.object,
}

export default connect(mapStateToProps)(ValorBandeira)
