import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, formValueSelector } from 'redux-form/immutable'

import { Table } from 'semantic-ui-react'
import { SemanticInputMask } from '../FormComponents'

import { required } from '../../utils/validations'
import { maskFloat } from '../../utils/masks'

const DECIMAL_DIGITS = 6
const selector = formValueSelector('VigenciaTributoForm')

let AliquotaTributo = ({ aliquotaTributo, tributo_id, multipleTributo, loading }) => {
  const tributo = multipleTributo.get(`${tributo_id}`)
  return (
    <Table.Row>
      <Table.Cell textAlign='right'>
        {tributo.get('nome')}{' '}
      </Table.Cell>

      <Table.Cell>
        <Field fluid
          name={`${aliquotaTributo}.aliquota`}
          placeholder='0,000000'
          mask={maskFloat.mask(DECIMAL_DIGITS)}
          validate={[required]}
          component={SemanticInputMask}
          loading={loading}
          label='%'
        />
      </Table.Cell>
    </Table.Row>
  )
}

AliquotaTributo = connect(
  (state, props) => {
    return ({
      tributo_id: selector(state, `vigencia_tributo.${props.aliquotaTributo}.tributo_id`)
    })
  }
)(AliquotaTributo)

function mapStateToProps ({ tributo }) {
  return {
    multipleTributo: tributo,
  }
}

AliquotaTributo.propTypes = {
  aliquotaTributo: PropTypes.string,
  loading: PropTypes.bool,
  tributo_id: PropTypes.string,
  multipleTributo: PropTypes.object,
}

export default connect(mapStateToProps)(AliquotaTributo)
