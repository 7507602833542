import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, FormSection, formValueSelector } from 'redux-form/immutable'
import { Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput, SemanticInputMask, SemanticCheckbox } from '../FormComponents'

import { required, maxLengthNome, maxLengthDescricao, percentage } from '../../utils/validations'
import { maskCurrency } from '../../utils/masks'

let FonteEnergiaForm = props => {
  const {
    temIncentivo
  } = props

  return (
    <CRUDForm
      title='Fonte de Energia'
      icon='lightning'
      {...props}>
      <FormSection name='fonte_energia'>
        <Form.Group>
          <FormField width={6}>
            <label>Nome</label>
            <Field
              autoFocus
              name='nome'
              placeholder='Nome'
              validate={[required, maxLengthNome]}
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>

          <FormField width={10}>
            <label>Descrição</label>
            <Field
              name='descricao'
              placeholder='Descrição'
              validate={[required, maxLengthDescricao]}
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>

        <Form.Group>
          <FormField width={6}>
            <label htmlFor="incentivada">Fonte de energia incentivada?</label>
            <Field
              name='incentivada'
              id='incentivada'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>

          {temIncentivo && (
            <FormField width={3}>
              <label>Incentivo</label>
              <Field
                name='incentivo'
                label='%'
                validate={[required, percentage]}
                component={SemanticInputMask}
                loading={props.loading}
                mask={maskCurrency.mask()}
                placeholder={maskCurrency.placeholder}
              />
            </FormField>
          )}
        </Form.Group>
      </FormSection>
    </CRUDForm>
  )
}

FonteEnergiaForm.propTypes = {
  temIncentivo: PropTypes.bool,
  loading: PropTypes.bool
}

FonteEnergiaForm = reduxForm({
  form: 'FonteEnergiaForm'
})(FonteEnergiaForm)

const selector = formValueSelector('FonteEnergiaForm')

FonteEnergiaForm = connect(state => {
  const temIncentivo = selector(state, 'fonte_energia.incentivada')

  return {
    temIncentivo
  }
})(FonteEnergiaForm)

export default FonteEnergiaForm
