import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import { EnquadramentoShow } from '../../components'
import * as enquadramentoActionCreators from '../../redux/modules/enquadramento'

class EnquadramentoShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    fetchEnquadramento: PropTypes.func,
    history: PropTypes.object,
    destroyEnquadramento: PropTypes.func,
    match: PropTypes.object.isRequired,
  }

  state = {
    enquadramento: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const enquadramento = await this.props.fetchEnquadramento(this.props.id)
      this.setState({ enquadramento })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/enquadramentos/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/enquadramentos/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyEnquadramento(id)
    this.props.history.push('/enquadramentos')
  }

  getPane = (match) => {
    if (match.params.pane) return match.params.pane
    return 'informacoes'
  }

  render () {
    return (
      <EnquadramentoShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        enquadramento={this.state.enquadramento}
        pane={this.getPane(this.props.match)}
      />
    )
  }
}

function mapStateToProps ({enquadramento}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      enquadramento: enquadramento.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...enquadramentoActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EnquadramentoShowContainer))
