import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { VigenciaTributoForm } from '../../components'
import * as vigenciaTributoActionCreators from '../../redux/modules/vigenciaTributo'
import * as tributoActionCreators from '../../redux/modules/tributo'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import moment from 'moment'
import { maskPercent } from '../../utils/masks'
import sortBy from 'sort-by'

const DECIMAL_DIGITS = 6

class VigenciaTributoFormContainer extends Component {
  static propTypes = {
    concessionaria: PropTypes.object.isRequired,
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    fetchVigenciaTributo: PropTypes.func.isRequired,
    createVigenciaTributo: PropTypes.func.isRequired,
    updateVigenciaTributo: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchMultipleTributo: PropTypes.func,
    multipleTributo: PropTypes.object,
  }

  async componentDidMount () {
    await this.props.fetchMultipleTributo()

    let vigencia_tributo = {
      concessionaria_id: this.props.concessionaria.id,
      starts_at: moment().startOf('month').format('DD/MM/YYYY'),
      aliquotas_tributos: [],
    }

    if (this.props.id) {
      vigencia_tributo = await this.props.fetchVigenciaTributo(this.props.id)
      vigencia_tributo.starts_at = moment(vigencia_tributo.starts_at, 'YYYY-MM-DD').format('DD/MM/YYYY')
      vigencia_tributo.aliquotas_tributos = vigencia_tributo.aliquotas_tributos.map(v => {
        v.aliquota = maskPercent.parse(v.aliquota, DECIMAL_DIGITS)
        return v
      })
    }

    let tributos = this.props.multipleTributo.toList().map(p => parseInt(p.get('id'), 10)).toJSON()
    tributos.forEach(tributo => {
      let found = vigencia_tributo.aliquotas_tributos.find(v => v.tributo_id === tributo)
      if (!found) {
        vigencia_tributo.aliquotas_tributos.push({
          vigencia_tributo_id: this.props.id,
          tributo_id: tributo,
          aliquota: '0,00'
        })
      }
    })

    vigencia_tributo['aliquotas_tributos_attributes'] = vigencia_tributo['aliquotas_tributos'].sort(sortBy('tributo_id'))
    delete vigencia_tributo['aliquotas_tributos']

    this.props.initialize('VigenciaTributoForm', { vigencia_tributo })
  }

  handleSubmitResource = async (vigenciaTributo) => {
    const concessionariaId = this.props.concessionaria.id

    let aliquotas = vigenciaTributo
      .getIn(['vigencia_tributo', 'aliquotas_tributos_attributes'])
      .map(v => v.set('aliquota', maskPercent.unmask(v.get('aliquota'), DECIMAL_DIGITS)))

    vigenciaTributo = vigenciaTributo.setIn(['vigencia_tributo', 'aliquotas_tributos_attributes'], aliquotas)

    if (this.props.id) {
      if (await this.props.updateVigenciaTributo(this.props.id, vigenciaTributo)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/concessionarias/${concessionariaId}/tributos`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createVigenciaTributo(concessionariaId, vigenciaTributo)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push(`/concessionarias/${concessionariaId}/tributos`)
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <VigenciaTributoForm
        concessionaria={this.props.concessionaria}
        onSubmit={(vigenciaTributo) => this.handleSubmitResource(vigenciaTributo)}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({vigenciaTributo, tributo, status, flashMessage}, {match}) {
  const { id } = match.params

  const isLoading = status.getIn(['vigenciaTributo', 'isLoading']) || status.getIn(['tributo', 'isLoading'])

  if (id) {
    return {
      id,
      vigenciaTributo: vigenciaTributo.get('id'),
      error: status.getIn(['vigenciaTributo', 'error']),
      isLoading,
      shouldHideFlashMessage: flashMessage.get('isError'),
      multipleTributo: tributo,
    }
  }
  return {
    error: status.getIn(['vigenciaTributo', 'error']),
    isLoading,
    shouldHideFlashMessage: flashMessage.get('isError'),
    multipleTributo: tributo,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...vigenciaTributoActionCreators,
    ...tributoActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VigenciaTributoFormContainer))
