import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { initialize } from 'redux-form/immutable'
import { change } from 'redux-form/immutable'
import { bindActionCreators } from 'redux'

import withViewMode from '../../utils/withViewMode'

import ComentarioSimulacao from '../../components/Relatorio/ComentarioSimulacao'
import * as relatorioActionCreators from '../../redux/modules/relatorio'

class AnaliseContainer extends Component {
  state = {
    editMode: false
  }

  static propTypes = {
    id: PropTypes.number,
    cenarioId: PropTypes.number,
    isLoading: PropTypes.bool,
    subheader: PropTypes.string,
    comment: PropTypes.string,
    print: PropTypes.bool,

    initialize: PropTypes.func,
    change: PropTypes.func,
    updateRelatorio: PropTypes.func,
  }

  async componentDidMount () {

    const {
      id,
      cenarioRelatorioId: cenario_relatorio_id,
      cenarioId: cenario_id,
      comment
    } = this.props

    let relatorio = {
      id,
      cenarios_relatorios_attributes: [{
        id: cenario_relatorio_id,
        cenario_id,
        comment
      }]
    }

    this.props.initialize(`Comentario_${cenario_id}`, { relatorio })
  }


  handleToggleMode = () => this.setState({ editMode: !this.state.editMode })

  handleSubmitResource = async (resource) => {
    if (await this.props.updateRelatorio(resource.getIn(['relatorio', 'id']), resource)) {
      this.handleToggleMode()
    } else {
      alert('Não foi possível salvar. Tente novamente.')
    }
  }

  render() {
    const { id, cenarioId, comment, print } = this.props

    return (
      <ComentarioSimulacao
        id={id}
        form={`Comentario_${cenarioId}`}
        comment={comment}
        print={print}
        isEditMode={this.state.editMode}
        onToggleMode={this.handleToggleMode}
        onSubmit={resource => this.handleSubmitResource(resource)}
        isLoading={this.props.isLoading}
      />
    )
  }
}

const mapStateToProps = ({ relatorio, status }, { relatorio: { id }, cenarioId }) => {

  const cenarios = relatorio.getIn([`${id}`, 'cenarios_relatorios'])
  const filtered = cenarios && cenarios.filter(t => t.get('relatorio_id') === Math.trunc(id) && t.get('cenario_id') === Math.trunc(cenarioId))
  const cenario = filtered && filtered.size > 0 && filtered.first()
  const cenarioRelatorioId = cenario && cenario.get('id', '')
  const comment = cenario && cenario.get('comment', '')
  
  return {
    isLoading: status.getIn(['relatorio', 'isLoading']),
    cenarioRelatorioId,
    comment
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...relatorioActionCreators,
    ...{change},
    ...{initialize},
  }, dispatch)
}

export default compose(
  withViewMode,
  connect(mapStateToProps, mapDispatchToProps),
)(AnaliseContainer)
