import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Concessionaria } from '../../components'
import * as concessionariaActionCreators from '../../redux/modules/concessionaria'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class ConcessionariaContainer extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,

    multipleConcessionaria: PropTypes.object.isRequired,
    fetchMultipleConcessionaria: PropTypes.func.isRequired,
    destroyConcessionaria: PropTypes.func.isRequired,

    hideFlashMessage: PropTypes.func.isRequired,

    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleConcessionaria()
  }

  handleRedirectToNew = () => {
    this.props.history.push('/concessionarias/new')
  }

  handleShowResource = (id) => {
    this.props.history.push(`/concessionarias/${id}`)
  }

  handleEditResource = (id) => {
    this.props.history.push(`/concessionarias/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyConcessionaria(id)
  }

  handleSort = column => () => this.props.updateSort('concessionaria', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('concessionaria', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleConcessionaria.toList().toJSON(),
      'concessionaria',
      ['id', 'nome', 'sigla', 'cnpj', 'dia', 'mes', 'uf.nome'],
    )
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <Concessionaria
        title='Concessionárias'
        listPath='concessionarias'
        multipleConcessionaria={this.props.multipleConcessionaria}
        onRedirectToNew={this.handleRedirectToNew}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({concessionaria, sort, filter, status}) {
  const sortColumn = sort.getIn(['concessionaria', 'sortColumn'])
  const sortDirection = sort.getIn(['concessionaria', 'sortDirection'])
  const filterText = filter.getIn(['concessionaria', 'filterText']) || ''

  let map = filterMap(concessionaria, ['nome', 'sigla', 'cnpj', 'uf.sigla', 'uf.nome'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleConcessionaria: map,
    sortColumn,
    sortDirection,
    filterText,
    isLoading: status.getIn(['concessionaria', 'isLoading']),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...concessionariaActionCreators,
    ...flashMessageAcionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ConcessionariaContainer)
