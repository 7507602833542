import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Header, Icon } from 'semantic-ui-react'
import { FlashMessageContainer } from '../../containers'
import ShowToolbar from '../../components/Toolbar/ShowToolbar'
import PageLoader from '../PageLoader/PageLoader'

const CRUDShow = (props) => (
  <Grid padded={props.padded}>
    <Grid.Row>
      {!props.noTitle && (
        <Grid.Column width={16} id='crud-show-title'>
          <Header as={props.titleAs} dividing>
            {props.icon ? <Icon size='massive' name={props.icon} /> : null}
            <Header.Content>
              {props.title}
              {props.subtitle ? <Header.Subheader>{props.subtitle}</Header.Subheader> : null}
            </Header.Content>
          </Header>
        </Grid.Column>
      )}
      {!props.noToolbar && (
        <Grid.Column width={16} id='crud-show-toolbar'>
          <ShowToolbar
            onEditResource={props.onEditResource}
            onDeleteResource={props.onDeleteResource}
            onPrintResource={props.onPrintResource}
            customToolbarItems={props.customToolbarItems}
            customButtonGroupItems={props.customButtonGroupItems}
          />
        </Grid.Column>
      )}
      {!props.noMessage && (
        <Grid.Column width={16} id='crud-show-message'>
          <FlashMessageContainer />
        </Grid.Column>
      )}
      <Grid.Column width={16} id='crud-show-content'>
        <PageLoader active={props.isLoading} />
        {props.children}
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

CRUDShow.defaultProps = {
  titleAs: 'h1',
  padded: true,
}

CRUDShow.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  titleAs: PropTypes.string,
  icon: PropTypes.string,
  isLoading: PropTypes.bool,

  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),

  noTitle: PropTypes.bool,

  noToolbar: PropTypes.bool,
  noMessage: PropTypes.bool,
  padded: PropTypes.bool,

  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  onPrintResource: PropTypes.func,

  customToolbarItems: PropTypes.array,
  customButtonGroupItems: PropTypes.array,
}

export default CRUDShow
