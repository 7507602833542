import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import { Field } from 'redux-form/immutable'
import { SemanticInputMask } from '../FormComponents'
import { maskCurrency } from '../../utils/masks'
import { required } from '../../utils/validations'

const RenderFormValoresP = ({ mes, fields }) => {
  return fields.map((valor_p, index) => (
    <Table.Cell key={index}>
      <Field fluid
        name={`${valor_p}.valor_p`}
        placeholder={maskCurrency.placeholder}
        mask={maskCurrency.mask()}
        validate={[required]}
        component={SemanticInputMask}
        label='kWh'
        tabIndex={mes}
      />
    </Table.Cell>
  ))
}

RenderFormValoresP.propTypes = {
  mes: PropTypes.number,
  fields: PropTypes.object,
  loading: PropTypes.bool,
}

export default RenderFormValoresP
