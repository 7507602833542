import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { VigenciaTributo } from '../../../containers'

const VigenciasTributos = (props) => {
  const { concessionaria } = props
  return (
    <div>
      <Switch>
        <Route
          exact path='/concessionarias/:concessionariaId/tributos'
          render={({match}) => (
            <VigenciaTributo.Index
              concessionaria={concessionaria}
              match={match}
            />
          )}>
        </Route>
        <Route
          exact path='/concessionarias/:concessionariaId/tributos/new'
          render={({match}) => (
            <VigenciaTributo.Form
              concessionaria={concessionaria}
              match={match}
            />
          )}
        />
        <Route
          exact path='/concessionarias/:concessionariaId/tributos/:id/edit'
          render={({match}) => (
            <VigenciaTributo.Form
              concessionaria={concessionaria}
              match={match}
            />
          )}
        />
        <Route
          exact path='/concessionarias/:concessionariaId/tributos/:id'
          render={({match}) => (
            <VigenciaTributo.Show
              concessionaria={concessionaria}
              match={match}
            />
          )}
        />
      </Switch>
    </div>
  )
}

VigenciasTributos.propTypes = {
  concessionaria: PropTypes.object.isRequired,
}

export default VigenciasTributos
