import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_PLD_SEMANAL = 'LOADING_PLD_SEMANAL'
export function loadingPldSemanal () {
  return {
    type: LOADING_PLD_SEMANAL,
  }
}

export const LOADING_PLD_SEMANAL_SUCCESS = 'LOADING_PLD_SEMANAL_SUCCESS'
export function loadingPldSemanalSuccess (pldSemanal) {
  return {
    type: LOADING_PLD_SEMANAL_SUCCESS,
    pldSemanal,
  }
}

export const LOADING_PLD_SEMANAL_FAILURE = 'LOADING_PLD_SEMANAL_FAILURE'
export function loadingPldSemanalFailure (error) {
  return {
    type: LOADING_PLD_SEMANAL_FAILURE,
    error,
  }
}

const initialState = Map({})

export function fetchMultiplePldSemanal (year, month, week) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingPldSemanal())
      const multiplePldSemanal = await callAPI(`/indicativos/semanais?ano=${year}&mes=${month}&semana=${week}`)
      dispatch(loadingPldSemanalSuccess(multiplePldSemanal))
      return multiplePldSemanal
    } catch (e) {
      dispatch(loadingPldSemanalFailure(e))
      return false
    }
  }
}

export function updatePldSemanal (pldSemanal) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingPldSemanal())
      const newPldSemanal = await callAPI('/indicativos/semanais', 'PUT', pldSemanal)
      dispatch(loadingPldSemanalSuccess(newPldSemanal))
      return newPldSemanal
    } catch (e) {
      dispatch(loadingPldSemanalFailure(e))
      return false
    }
  }
}

export default function pldSemanal (state = initialState, action) {
  switch (action.type) {

    case LOADING_PLD_SEMANAL_SUCCESS : {
      const { pldSemanal } = action
      state = Map({})
      pldSemanal.forEach((singlePldSemanal, index) => (
        state = state.merge({[index]: singlePldSemanal})
      ))
      return state
    }

    default :
      return state
  }
}
