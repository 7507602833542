import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput } from '../FormComponents'
import { required, maxLengthNome, maxLengthDescricao } from '../../utils/validations'

import ParametroCalculoSimulacaoForm from '../../components/ParametroCalculoForm/ParametroCalculoSimulacaoForm'

const CenarioForm = (props) => {
  return (
    <CRUDForm
      title='Parâmetros Técnicos'
      titleAs='h3'
      icon='cogs'
      padded={false}
      {...props}>
      <FormSection name='cenario'>
        <Form.Group>
          <FormField width={16}>
            <label htmlFor='nome'>Nome</label>
            <Field autoFocus
              name='nome'
              placeholder='Nome'
              component={SemanticInput}
              loading={props.loading}
              validate={[required, maxLengthNome]}
            />
          </FormField>
          <FormField width={16}>
            <label htmlFor='descricao'>Descrição</label>
            <Field autoFocus
              name='descricao'
              placeholder='Descrição'
              component={SemanticInput}
              loading={props.loading}
              validate={[required, maxLengthDescricao]}
            />
          </FormField>
        </Form.Group>
        <FormSection name='parametro_calculo'>
          <ParametroCalculoSimulacaoForm
            storeName='cenario'
            formName='CenarioForm'
            multipleClasseConsumo={props.multipleClasseConsumo}
            multipleComercializadora={props.multipleComercializadora}
            multipleConcessionaria={props.multipleConcessionaria}
            multipleEnquadramento={props.multipleEnquadramento}
            multipleGerador={props.multipleGerador}
            multipleSubgrupo={props.multipleSubgrupo}
            multipleTipoMercado={props.multipleTipoMercado}
          />
        </FormSection>
      </FormSection>
    </CRUDForm>
  )
}

CenarioForm.propTypes = {
  loading: PropTypes.bool,
  multipleClasseConsumo: PropTypes.object,
  multipleComercializadora: PropTypes.object,
  multipleConcessionaria: PropTypes.object,
  multipleEnquadramento: PropTypes.object,
  multipleGerador: PropTypes.object,
  multipleSubgrupo: PropTypes.object,
  multipleTipoMercado: PropTypes.object,
}

export default reduxForm({form: 'CenarioForm'})(CenarioForm)
