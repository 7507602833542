import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ContratoEnergiaInstalacaoShow } from '../../components'
import * as contratoEnergiaInstalacaoActionCreators from '../../redux/modules/contratoEnergiaInstalacao'
import * as comercializadoraActionCreators from '../../redux/modules/comercializadora'
import * as fonteEnergiaActionCreators from '../../redux/modules/fonteEnergia'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

class ContratoEnergiaInstalacaoShowContainer extends Component {

  static propTypes = {
    instalacao: PropTypes.object.isRequired,
    id: PropTypes.string,
    history: PropTypes.object,

    fetchContratoEnergiaInstalacao: PropTypes.func,
    destroyContratoEnergiaInstalacao: PropTypes.func,

    fetchMultipleComercializa: PropTypes.func,
    multipleComercializadora: PropTypes.object,

    fetchMultipleFonte: PropTypes.func,
    multipleFonteEnergia: PropTypes.object,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,

    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  state = {
    contratoEnergiaInstalacao: null
  }

  async componentDidMount () {
    await Promise.all([
      this.props.fetchMultipleComercializa(),
      this.props.fetchMultipleFonte(),
    ])

    if (this.props.id) {
      const contratoEnergiaInstalacao = await this.props.fetchContratoEnergiaInstalacao(this.props.id)
      this.setState({ contratoEnergiaInstalacao })
    }
  }

  handleEditResource = (instalacaoId, id) => {
    this.props.history.push(`/instalacoes/${instalacaoId}/tarifas/${id}/edit`)
  }

  handleDeleteResource = async (instalacaoId, id) => {
    if (await this.props.destroyContratoEnergiaInstalacao(id)) {
      this.props.showSuccessfulFlashMessage('', 'destroy')
      this.props.history.push(`/instalacoes/${instalacaoId}/tarifas`)
    } else {
      this.props.showErrorFlashMessage('', 'destroy', this.props.error)
    }
  }

  render () {
    return (
      <ContratoEnergiaInstalacaoShow
        isLoading={this.props.isLoading}
        instalacao={this.props.instalacao}
        multipleComercializadora={this.props.multipleComercializadora}
        multipleFonteEnergia={this.props.multipleFonteEnergia}
        onEditResource={() => this.handleEditResource(this.props.instalacao.id, this.state.contratoEnergiaInstalacao.id)}
        onDeleteResource={() => this.handleDeleteResource(this.props.instalacao.id, this.state.contratoEnergiaInstalacao.id)}
        contratoEnergiaInstalacao={this.state.contratoEnergiaInstalacao} />
    )
  }
}

function mapStateToProps ({contratoEnergiaInstalacao, comercializadora, fonteEnergia, status}, {match}) {
  const { id } = match.params
  const isLoading = status.getIn(['contratoEnergiaInstalacao', 'isLoading'])
                 || status.getIn(['comercializadora', 'isLoading'])
                 || status.getIn(['fonteEnergia', 'isLoading'])
  if (id) {
    return {
      id,
      contratoEnergiaInstalacao: contratoEnergiaInstalacao.get('id'),
      multipleComercializadora: comercializadora,
      multipleFonteEnergia: fonteEnergia,
      isLoading,
    }
  }
  return {
    multipleComercializadora: comercializadora,
    multipleFonteEnergia: fonteEnergia,
    isLoading,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...contratoEnergiaInstalacaoActionCreators,
    ...comercializadoraActionCreators,
    ...fonteEnergiaActionCreators,
    ...flashMessageActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContratoEnergiaInstalacaoShowContainer))
