import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Loader } from 'semantic-ui-react'
import Helmet from 'react-helmet'
import RenderRelatorio from '../../components/Relatorio/RenderRelatorio'

import moment from 'moment'
import { capitalize } from '../../components/Relatorio/Graficos/utils/ChartUtils'

import * as relatorioActionCreators from '../../redux/modules/relatorio'

import viewMode from '../../utils/viewMode'

const REACT_APP_COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME || 'Electric'

class RelatorioByToken extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    match: PropTypes.object.isRequired,
    fetchRelatorioByToken: PropTypes.func.isRequired,
  }

  state = {
    relatorio: {},
  }

  async componentDidMount() {
    const {
      match: {
        params: { token },
      },
    } = this.props
    const relatorio = await this.props.fetchRelatorioByToken(token)
    if (relatorio) this.setState({ relatorio })
  }

  render() {
    const { isLoading } = this.props
    const { relatorio } = this.state
    const { gestor } = relatorio

    if (isLoading || !relatorio || !Object.keys(relatorio).length) {
      return (
        <React.Fragment>
          <Helmet>
            <title>Carregando...</title>
          </Helmet>
          <Loader active={true}>Carregando...</Loader>
        </React.Fragment>
      )
    }

    const {
      mes,
      ano,
      data: {
        cabecalho: {
          instalacao: { apelido },
        },
      },
    } = relatorio

    return (
      <React.Fragment>
        <Helmet>
          <title>
            eGrid | {REACT_APP_COMPANY_NAME} - {apelido} -{' '}
            {capitalize(moment({ M: mes - 1, y: ano }).format('MMMM/YYYY'))}
          </title>
        </Helmet>
        <div style={{ backgroundColor: 'rgb(242, 242, 242)' }}>
          <div style={{ margin: '0 auto', width: '90em' }}>
            <RenderRelatorio
              relatorio={relatorio}
              gestor={gestor}
              viewMode={viewMode.SHOW}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ status }) => {
  return {
    isLoading:
      status.getIn(['relatorio', 'isLoading']) ||
      status.getIn(['session', 'isLoading']),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...relatorioActionCreators,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RelatorioByToken))
