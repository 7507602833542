import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput } from '../FormComponents'

import { required, maxLengthNome, maxLengthDescricao } from '../../utils/validations'

const ClasseConsumoForm = (props) => (
  <CRUDForm
    title='Classe de Consumo'
    icon='block layout'
    {...props}>
    <FormSection name='classe_consumo'>
      <Form.Group>
        <FormField width={6}>
          <label>Classe</label>
          <Field
            autoFocus
            name='nome'
            placeholder='Classe'
            validate={[required, maxLengthNome]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField width={10}>
          <label>Descrição</label>
          <Field
            name='descricao'
            placeholder='Descrição'
            validate={[required, maxLengthDescricao]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>
    </FormSection>
  </CRUDForm>
)

ClasseConsumoForm.propTypes = {
  loading: PropTypes.bool
}

export default reduxForm({form: 'ClasseConsumoForm'})(ClasseConsumoForm)
