import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import get from 'lodash/get'
import { Container, Divider } from 'semantic-ui-react'
import { ResponsiveLine } from '@nivo/line'

import {
  COLORS,
  capitalize,
  formatAsCurrency,
  formatAsIndicador,
} from './utils/ChartUtils'

const TotalAnualChart = ({ header, data, height, showAnimations }) => {
  if (!data) return null

  const chartData = [
    {
      id: 'Custo Total',
      data: data.map(fatura => ({
        x: capitalize(moment({y: fatura.ano, M: fatura.mes - 1}).format('MMM/YY')),
        y: get(fatura, 'total', 0.0)
      }))
    }
  ]

  return (
    <Container fluid>
      <Divider horizontal>{header}</Divider>
      <div style={{height: `${height}px`}}>
        <ResponsiveLine
          data={chartData}
          margin={{
            'top': 35,
            'right': 35,
            'bottom': 35,
            'left': 35
          }}
          xScale={{
            'type': 'point'
          }}
          yScale={{
            'type': 'linear',
            'stacked': true,
            'min': 'auto',
            'max': 'auto'
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            'orient': 'bottom',
            'tickSize': 5,
            'tickPadding': 10,
            'tickRotation': 0,
          }}
          axisLeft={false}
          enableGridX={false}
          enableGridY={false}
          lineWidth={3}
          dotSize={14}
          colors={[COLORS.ELECTRIC.GREEN]}
          dotColor='inherit'
          dotBorderWidth={0}
          enableDotLabel={true}
          dotLabel='y'
          dotLabelYOffset={-16}
          dotLabelFormat={value => formatAsIndicador('R$ ', true)(value)}
          tooltipFormat={value => formatAsCurrency(value)}
          animate={showAnimations}
          motionStiffness={90}
          motionDamping={15}
          theme={{
            axis: {
              domain: {
                line: {
                  strokeWidth: 0,
                },
              },
              ticks: {
                line: {
                  stroke: '#6A4459'
                },
                text: {
                  fill: '#6A4459'
                }
              },
            },
            dots: {
              text: {
                fill: '#6A4459'
              }
            }
          }}
        />
      </div>
    </Container>
  )
}

TotalAnualChart.propTypes = {
  header: PropTypes.string,
  data: PropTypes.array,
  info: PropTypes.object,
  height: PropTypes.number,
  showAnimations: PropTypes.bool,
}

TotalAnualChart.defaultProps = {
  header: '',
  info: {},
  height: 350,
  showAnimations: true,
}

export default TotalAnualChart
