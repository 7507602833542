import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticDropdown, DatePickerInput } from '../FormComponents'
import { required, dateValidator } from '../../utils/validations'

function VigenciaBandeiraForm (props) {
  return (
    <CRUDForm
      title='Vigências'
      icon='calendar'
      padded={false}
      {...props}>
      <FormSection name='vigencia_bandeira'>
        <Form.Group>
          <FormField width={3}>
            <label>Início da Vigência</label>
            <Field autoFocus
              name='starts_at'
              placeholder='Início da Vigência'
              validate={[required, dateValidator]}
              component={DatePickerInput}
              loading={props.loading}
            />
          </FormField>

          <FormField width={13}>
            <label htmlFor='bandeira_id'>Bandeira</label>
            <Field fluid button floating labeled search icon='flag' className='icon'
              name='bandeira_id'
              options={props.bandeiraDropdown}
              validate={[required]}
              component={SemanticDropdown}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
      </FormSection>
    </CRUDForm>
  )
}

VigenciaBandeiraForm.propTypes = {
  loading: PropTypes.bool,
  bandeiraDropdown: PropTypes.array,
}

export default reduxForm({form: 'VigenciaBandeiraForm'})(VigenciaBandeiraForm)
