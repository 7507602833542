import React from 'react'
import PropTypes from 'prop-types'
import { Card, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { CRUDIndex } from '../CRUD'

const Relatorio = (props) => {
  return (
    <CRUDIndex noTitle padded={false}
      onRedirectToNew={props.onRedirectToNew}
      onExportToCSV={props.onExportToCSV}
      filterText={props.filterText}
      handleFilter={props.handleFilter}
    >
      <Loader inline='centered' active={props.isLoading} />

      <Card.Group style={{ marginTop: '0.5em' }}>
        {props.multipleRelatorio.valueSeq().map((relatorio, index) => {
          return (
            <Card
              key={index}
              as={Link}
              to={`/instalacoes/${props.instalacao.id}/relatorios/${relatorio.get('id')}`}
            >
              <Card.Content>
                <Card.Header>
                  {relatorio.get('mes')}/{relatorio.get('ano')}
                </Card.Header>
              </Card.Content>
              <Card.Content extra textAlign='right'>
                Fatura #{relatorio.get('fatura_pedagio_id')}
              </Card.Content>
            </Card>
          )
        })}
      </Card.Group>
    </CRUDIndex>
  )
}

Relatorio.propTypes = {
  onRedirectToNew: PropTypes.func,
  multipleRelatorio: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
  instalacao: PropTypes.object,
}

export default Relatorio
