import React from 'react'
import PropTypes from 'prop-types'
import { Label, Table, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { CRUDIndex } from '../CRUD'
import { EditDeleteButtonContainer } from '../../containers'

const Cenario = (props) => {
  return (
    <CRUDIndex noTitle padded={false}
      onRedirectToNew={props.onRedirectToNew}
      onExportToCSV={props.onExportToCSV}
      filterText={props.filterText}
      handleFilter={props.handleFilter}
    >
      <Table sortable celled striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1} textAlign='center'
              onClick={props.handleSort('id')}
              sorted={props.handleSorted('id')}>#</Table.HeaderCell>
            <Table.HeaderCell width={3}
              onClick={props.handleSort('nome')}
              sorted={props.handleSorted('nome')}>Cenário</Table.HeaderCell>
            <Table.HeaderCell
              onClick={props.handleSort('descricao')}
              sorted={props.handleSorted('descricao')}>Descrição</Table.HeaderCell>
            <Table.HeaderCell
              onClick={props.handleSort('enquadramento.modalidade')}
              sorted={props.handleSorted('enquadramento.modalidade')}>Enquadramento</Table.HeaderCell>
            <Table.HeaderCell
              onClick={props.handleSort('subgrupo.nome')}
              sorted={props.handleSorted('subgrupo.nome')}>Subgrupo</Table.HeaderCell>
            <Table.HeaderCell
              onClick={props.handleSort('classe_consumo.nome')}
              sorted={props.handleSorted('classeConsumo.nome')}>Classe de Consumo</Table.HeaderCell>
            <Table.HeaderCell
              onClick={props.handleSort('concessionaria.sigla')}
              sorted={props.handleSorted('concessionaria.sigla')}>Concessionaria</Table.HeaderCell>
            <Table.HeaderCell
              onClick={props.handleSort('tipo_mercado')}
              sorted={props.handleSorted('tipo_mercado')}>Tipo de Mercado</Table.HeaderCell>
            <Table.HeaderCell width={1}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {props.isLoading && (
            <Table.Row>
              <Table.Cell colSpan={4}>
                <Loader active={props.isLoading} inline='centered' />
              </Table.Cell>
            </Table.Row>
          )}

          {props.multipleCenario.valueSeq().map((cenario, index) => {
            const enquadramento = cenario.getIn(['parametro_calculo', 'enquadramento'])
            const subgrupo = cenario.getIn(['parametro_calculo', 'subgrupo'])
            const classe_consumo = cenario.getIn(['parametro_calculo', 'classe_consumo'])
            const concessionaria = cenario.getIn(['parametro_calculo', 'concessionaria'])
            const tipo_mercado = cenario.getIn(['parametro_calculo', 'tipo_mercado'])

            return (
              <Table.Row key={index}>
                <Table.Cell textAlign='center'>
                  <Label as={Link} to={`/instalacoes/${cenario.get('instalacao_id')}/cenarios/${cenario.get('id')}`}>{cenario.get('id')}</Label>
                </Table.Cell>
                <Table.Cell>{cenario.get('nome')}</Table.Cell>
                <Table.Cell>{cenario.get('descricao')}</Table.Cell>
                <Table.Cell>
                  <div className='labelWrapper'>
                    <Label key={index} as={Link} to={`/enquadramentos/${enquadramento.get('id')}`}>
                      {enquadramento.get('modalidade')}
                    </Label>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className='labelWrapper'>
                    <Label key={index} as={Link} to={`/subgrupos/${subgrupo.get('id')}`}>
                      {subgrupo.get('nome')}
                    </Label>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className='labelWrapper'>
                    <Label key={index} as={Link} to={`/classes_consumo/${classe_consumo.get('id')}`}>
                      {classe_consumo.get('nome')}
                    </Label>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className='labelWrapper'>
                    <Label key={index} as={Link} to={`/concessionarias/${concessionaria.get('id')}`}>
                      {concessionaria.get('sigla')}
                    </Label>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  {props.multipleTipoMercado.getIn([`${tipo_mercado}`, 'nome'])}
                </Table.Cell>
                <Table.Cell>
                  <EditDeleteButtonContainer
                    onEditResource={() => props.onEditResource(cenario.get('instalacao_id'), cenario.get('id'))}
                    onDeleteResource={() => props.onDeleteResource(cenario.get('id'))} />
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </CRUDIndex>
  )
}

Cenario.propTypes = {
  onRedirectToNew: PropTypes.func,
  multipleCenario: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
  multipleCenarioCalculo: PropTypes.object,
  multipleTipoMercado: PropTypes.object,
}

export default Cenario
