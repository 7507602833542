import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Gerador } from '../../components'
import * as geradorActionCreators from '../../redux/modules/gerador'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class GeradorContainer extends Component {

  static propTypes = {
    history: PropTypes.object,
    multipleGerador: PropTypes.object,
    fetchMultipleGerador: PropTypes.func,
    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    destroyGerador: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleGerador()
  }

  handleRedirectToNew = () => {
    this.props.history.push('/geradores/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/geradores/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyGerador(id)
      ? this.props.showSuccessfulFlashMessage('', 'destroy')
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  handleSort = column => () => this.props.updateSort('gerador', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('gerador', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleGerador.toList().toJSON(),
      'gerador',
      [
        'id',
        'nome',
        'marca',
        'modelo',
        'eficiencia',
        'potencia',
        'potencia_ativa',
        'potencia_cv',
        'combustivel',
        'unidade_combustivel',
        'consumo_combustivel'
      ],
    )
  }

  render () {
    return (
      <Gerador
        onRedirectToNew={this.handleRedirectToNew}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        multipleGerador={this.props.multipleGerador}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({ gerador, status, sort, filter}) {
  const sortColumn = sort.getIn(['gerador', 'sortColumn']) || 'nome'
  const sortDirection = sort.getIn(['gerador', 'sortDirection']) || 'ascending'
  const filterText = filter.getIn(['gerador', 'filterText']) || ''

  let map = filterMap(gerador, ['nome', 'marca', 'modelo', 'eficiencia', 'potencia', 'combustivel', 'unidade_combustivel'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleGerador: map,
    sortColumn,
    sortDirection,
    filterText,
    error: status.getIn(['gerador', 'error']),
    isLoading: status.getIn(['gerador', 'isLoading']),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...geradorActionCreators,
    ...flashMessageAcionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(GeradorContainer)
