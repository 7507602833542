import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput } from '../FormComponents'

import { required, maxLengthNome } from '../../utils/validations'

const FuncaoForm = (props) => (
  <CRUDForm
    title='Função'
    icon='suitcase'
    {...props}>
    <FormSection name='funcao'>
      <FormField width={6}>
        <label>Nome</label>
        <Field
          autoFocus
          name='nome'
          placeholder='Nome'
          validate={[required, maxLengthNome]}
          component={SemanticInput}
          loading={props.loading}
        />
      </FormField>
    </FormSection>
  </CRUDForm>
)

FuncaoForm.propTypes = {
  loading: PropTypes.bool
}

export default reduxForm({form: 'FuncaoForm'})(FuncaoForm)
