import { Map } from 'immutable'
import * as API from '../../utils/API'

const initialState = Map({})

export const LOADING_SESSION = 'LOADING_SESSION'
export function loadingUser () {
  return {
    type: LOADING_SESSION,
  }
}

export const LOADING_SESSION_SUCCESS = 'LOADING_SESSION_SUCCESS'
export function loadingUserSuccess (user) {
  return {
    type: LOADING_SESSION_SUCCESS,
    user,
  }
}

export const LOADING_SESSION_FAILURE = 'LOADING_SESSION_FAILURE'
export function loadingUserFailure (error) {
  return {
    type: LOADING_SESSION_FAILURE,
    error,
  }
}

export const LOGOUT_SESSION = 'LOGOUT_SESSION'
export function logoutUser () {
  return {
    type: LOGOUT_SESSION,
  }
}

export function createSession (email, password) {
  return async function (dispatch, /* getState */) {
    try{
      dispatch(loadingUser())
      const user = await API.login(email, password)
      dispatch(loadingUserSuccess(user))
    } catch (e) {
      dispatch(loadingUserFailure(e))
    }
  }
}

export function loadUser (id) {
  return async function (dispatch, /* getState */) {
    try{
      dispatch(loadingUser())
      const user = await API.callAPI(`/users/${id}`)
      dispatch(loadingUserSuccess(user))
    } catch (e) {
      dispatch(loadingUserFailure(e))
    }
  }
}

export function destroySession () {
  return function (dispatch, /* getState */) {
    localStorage.removeItem('jwt')
    dispatch(logoutUser())
  }
}

export default function session (state = initialState, action) {
  switch (action.type) {
    case LOADING_SESSION_SUCCESS :
      return state.merge(action.user)

    case LOGOUT_SESSION :
      return initialState

    default :
      return state
  }
}
