import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ExtraPedagioShow } from '../../components'
import * as extraPedagioActionCreators from '../../redux/modules/extraPedagio'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

class ExtraPedagioShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    faturaPedagio: PropTypes.object.isRequired,

    fetchExtraPedagio: PropTypes.func.isRequired,
    destroyExtraPedagio: PropTypes.func.isRequired,

    fetchFaturaPedagio: PropTypes.func.isRequired,
    destroyFaturaPedagio: PropTypes.func.isRequired,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
  }

  state = {
    extraPedagio: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const extraPedagio = await this.props.fetchExtraPedagio(this.props.id)
      this.setState({ extraPedagio })
    }
  }

  handleEditResource = (faturaPedagioId, id) => {
    this.props.history.push(`/faturas/pedagio/${faturaPedagioId}/extras/${id}/edit`)
  }

  handleDeleteResource = async (faturaPedagioId, id) => {
    if (await this.props.destroyExtraPedagio(id)) {
      this.props.showSuccessfulFlashMessage('', 'destroy')
      this.props.history.push(`/faturas/pedagio/${faturaPedagioId}/extras`)
    } else {
      this.props.showErrorFlashMessage('', 'destroy', this.props.error)
    }
  }

  render () {
    return (
      <ExtraPedagioShow
        faturaPedagio={this.props.faturaPedagio}
        extraPedagio={this.state.extraPedagio}
        onEditResource={() => this.handleEditResource(this.props.faturaPedagio.id, this.state.extraPedagio.id)}
        onDeleteResource={() => this.handleDeleteResource(this.props.faturaPedagio.id, this.state.extraPedagio.id)} />
    )
  }
}

function mapStateToProps ({extraPedagio}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      extraPedagio: extraPedagio.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...extraPedagioActionCreators,
    ...flashMessageActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExtraPedagioShowContainer))
