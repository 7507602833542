import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_ENQUADRAMENTO = 'LOADING_ENQUADRAMENTO'
export function loadingEnquadramento () {
  return {
    type: LOADING_ENQUADRAMENTO,
  }
}

export const LOADING_ENQUADRAMENTO_SUCCESS = 'LOADING_ENQUADRAMENTO_SUCCESS'
export function loadingEnquadramentoSuccess (enquadramento) {
  return {
    type: LOADING_ENQUADRAMENTO_SUCCESS,
    enquadramento,
  }
}

export const LOADING_ENQUADRAMENTO_FAILURE = 'LOADING_ENQUADRAMENTO_FAILURE'
export function loadingEnquadramentoFailure (error) {
  return {
    type: LOADING_ENQUADRAMENTO_FAILURE,
    error,
  }
}

export const DESTROYING_ENQUADRAMENTO_SUCCESS = 'DESTROYING_ENQUADRAMENTO_SUCCESS'
export function destroyingEnquadramentoSuccess (enquadramentoId) {
  return {
    type: DESTROYING_ENQUADRAMENTO_SUCCESS,
    enquadramentoId,
  }
}

const initialState = Map({})

export function fetchMultipleEnquadramento () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingEnquadramento())
      const multipleEnquadramento = await callAPI('/enquadramentos')
      dispatch(loadingEnquadramentoSuccess(multipleEnquadramento))
      return multipleEnquadramento
    } catch (e) {
      dispatch(loadingEnquadramentoFailure(e))
      return false
    }
  }
}

export function fetchEnquadramento (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingEnquadramento())
      const enquadramento = await callAPI(`/enquadramentos/${id}`)
      dispatch(loadingEnquadramentoSuccess(enquadramento))
      return enquadramento
    } catch (e) {
      dispatch(loadingEnquadramentoFailure(e))
      return false
    }
  }
}

export function createEnquadramento (enquadramento) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingEnquadramento())
      const newEnquadramento = await callAPI('/enquadramentos', 'POST', enquadramento)
      dispatch(loadingEnquadramentoSuccess(newEnquadramento))
      return newEnquadramento
    } catch (e) {
      dispatch(loadingEnquadramentoFailure(e))
      return false
    }
  }
}

export function updateEnquadramento (id, enquadramento) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingEnquadramento())
      const newEnquadramento = await callAPI(`/enquadramentos/${id}`, 'PUT', enquadramento)
      dispatch(loadingEnquadramentoSuccess(newEnquadramento))
      return newEnquadramento
    } catch (e) {
      dispatch(loadingEnquadramentoFailure(e))
      return false
    }
  }
}

export function destroyEnquadramento (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingEnquadramento())
      await callAPI(`/enquadramentos/${id}`, 'DELETE')
      dispatch(destroyingEnquadramentoSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingEnquadramentoFailure(e))
      return false
    }
  }
}

export default function enquadramento (state = initialState, action) {
  switch (action.type) {

    case LOADING_ENQUADRAMENTO_SUCCESS : {
      const { enquadramento } = action
      if (enquadramento.constructor === Array) {
        state = initialState
        enquadramento.forEach((singleEnquadramento) => (
          state = state.merge({[singleEnquadramento.id]: singleEnquadramento})
        ))
        return state
      }
      return state.merge({[enquadramento.id]: enquadramento})
    }

    case DESTROYING_ENQUADRAMENTO_SUCCESS :
      return state.delete(action.enquadramentoId.toString())

    default :
      return state
  }
}
