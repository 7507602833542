import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import get from 'lodash.get'
import { Container } from 'semantic-ui-react'
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
} from 'recharts'
import {
  COLORS,
  capitalize,
  formatAsLocalizedNumber,
  formatAsIndicador,
  formatAsPercentageCalculated,
} from './utils/ChartUtils'
import CustomLineDotLabelBox from './utils/CustomLineDotLabelBox'
import LabelListInsideBottomCenterLabel from './utils/LabelListInsideBottomCenterLabel'

const customFormatter = v =>
  v && v > 0.0
    ? formatAsIndicador('R$ ', true)(v)
    : ''

const fieldsNameMapping = [
  {
    field: 'ponta',
    descricao: 'Demanda P',
    color: COLORS.ELECTRIC.GREEN,
  },
  {
    field: 'fora_ponta',
    descricao: 'Demanda FP',
    color: COLORS.ELECTRIC.LIGHT.GREEN,
  },
  {
    field: 'na',
    descricao: 'Demanda',
    color: COLORS.ELECTRIC.LIGHTER.GREEN,
  },
]

const ParticipacaoDemandaChart = ({ data, height, showAnimations }) => {
  if (!data) return null

  const chartKeys = []

  const preparedData = data.map(d => {
    const demanda = get(d, 'demanda', {})
    const prepared = Object.assign({}, demanda)
    prepared._labelFaturaMes = capitalize(moment({y: d.ano, M: d.mes - 1}).format('MMM/YY'))
    prepared._parsedPercentual = (get(demanda, 'percentual', 0) * 100)

    if (!chartKeys.includes('ponta') && get(demanda, 'ponta', 0)) chartKeys.push('ponta')
    if (!chartKeys.includes('fora_ponta') && get(demanda, 'fora_ponta', 0)) chartKeys.push('fora_ponta')
    if (!chartKeys.includes('na') && get(demanda, 'na', 0)) chartKeys.push('na')

    if (chartKeys.includes('ponta') || chartKeys.includes('fora_ponta')) {
      if (!prepared.na) prepared.na = 0.0
    }

    if (chartKeys.includes('na')) {
      if (!prepared.ponta) prepared.ponta = 0.0
      if (!prepared.fora_ponta) prepared.fora_ponta = 0.0
    }

    if (!prepared.ponta && !prepared.fora_ponta && !prepared.na) {
      prepared.na = 0.0
      prepared.ponta = 0.0
      prepared.fora_ponta = 0.0
    }

    return prepared
  })

  const series = fieldsNameMapping
    .filter(field => chartKeys.includes(field.field))
    .map((field, index) =>
      <Bar
        key={index}
        dataKey={field.field}
        yAxisId="left"
        fill={field.color}
        legendType="square"
        name={field.descricao}
        isAnimationActive={showAnimations}
      >
        <LabelList className='color-black' dataKey={field.field} position="center" angle={-90} formatter={customFormatter} content={LabelListInsideBottomCenterLabel}/>
      </Bar>
    )

  return (
    <Container fluid>
      <ResponsiveContainer height={height}>
        <ComposedChart data={preparedData} barGap={0}>
          <XAxis dataKey="_labelFaturaMes" type="category" interval={0} tickLine={false}/>
          <YAxis
            yAxisId="left"
            type="number"
            tickFormatter={value => formatAsIndicador('R$ ', true)(value)}
            padding={{ top: 10 }}
          />
          <YAxis
            yAxisId="right"
            type="number"
            tickFormatter={value => `${value}%`}
            orientation="right"
            width={40}
          />
          <Tooltip formatter={value => `R$ ${formatAsLocalizedNumber(value)}`}/>
          <Legend/>
          {series}
          <Line
            type="linear"
            dataKey="_parsedPercentual"
            yAxisId="right"
            stroke={COLORS.DEFAULT}
            strokeWidth={2.5}
            legendType="line"
            name='Participação no Total da Fatura'
            formatter={formatAsPercentageCalculated}
            dot={<CustomLineDotLabelBox formatter={formatAsPercentageCalculated} stroke={COLORS.DEFAULT} />}
            activeDot={false}
            isAnimationActive={showAnimations}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Container>
  )
}

ParticipacaoDemandaChart.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
  showAnimations: PropTypes.bool,
}

ParticipacaoDemandaChart.defaultProps = {
  height: 350,
  showAnimations: true,
}

export default ParticipacaoDemandaChart
