import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField, Divider } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput, SemanticInputMask, SemanticDropdown } from '../FormComponents'

import { required, cnpj, maxLengthSigla, maxLengthDescricao } from '../../utils/validations'
import { maskCNPJ } from '../../utils/masks'

const Comercializadora = (props) => (
  <CRUDForm
    title='Comercializadora'
    icon='shopping cart'
    {...props}>
    <FormSection name='comercializadora'>
      <Divider section horizontal>Dados Principais</Divider>

      <Form.Group>
        <FormField width={4}>
          <label htmlFor='sigla'>Sigla</label>
          <Field
            autoFocus
            name='sigla'
            placeholder='Sigla'
            validate={[required, maxLengthSigla]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField width={12}>
          <label htmlFor='nome'>Nome</label>
          <Field
            name='nome'
            placeholder='Nome'
            validate={[required, maxLengthDescricao]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>

      <Form.Group>
        <FormField width={4}>
          <label htmlFor='cnpj'>CNPJ</label>
          <Field
            name='cnpj'
            placeholder={maskCNPJ.placeholder}
            validate={[required, cnpj]}
            component={SemanticInputMask}
            mask={maskCNPJ.mask}
            loading={props.loading}
          />
        </FormField>
        <FormField width={12}>
          <label htmlFor='ccee'>Código CCEE</label>
          <Field
            name='ccee'
            placeholder='Código CCEE'
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>

      <Divider section horizontal>Endereço</Divider>

      <Form.Group>
        <FormField width={12}>
          <label htmlFor='endereco'>Endereço</label>
          <Field
            name='endereco'
            placeholder='Endereco'
            validate={[required]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField width={4}>
          <label htmlFor='cep'>CEP</label>
          <Field
            name='cep'
            placeholder='CEP'
            validate={[required]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>

      <Form.Group widths='equal'>
        <FormField>
          <label htmlFor='bairro'>Bairro</label>
          <Field
            name='bairro'
            placeholder='Bairro'
            validate={[required]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <label htmlFor='cidade'>Cidade</label>
          <Field
            name='cidade'
            placeholder='Cidade'
            validate={[required]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <label htmlFor='uf_id'>Unidade Federativa</label>
          <Field fluid button floating labeled search icon='marker' className='icon'
            name='uf_id'
            options={props.ufDropdown}
            validate={[required]}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>

      <Divider section horizontal>Dados Bancários</Divider>

      <Form.Group widths='equal'>
        <FormField>
          <label htmlFor='banco'>Banco</label>
          <Field
            name='banco'
            placeholder='Banco'
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <label htmlFor='agencia'>Agência</label>
          <Field
            name='agencia'
            placeholder='Agência'
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <label htmlFor='conta'>Conta Corrente</label>
          <Field
            name='conta'
            placeholder='Conta Corrente'
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>
    </FormSection>
  </CRUDForm>
)

Comercializadora.propTypes = {
  ufDropdown: PropTypes.array,
  loading: PropTypes.bool
}

export default reduxForm({form: 'ComercializadoraForm'})(Comercializadora)
