import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Form, Grid, Header, Icon, Loader, Menu, Table } from 'semantic-ui-react'
import { FlashMessageContainer } from '../../containers'
import { maskCurrency } from '../../utils/masks'
import { SemanticUploadButton } from '../../components/FormComponents'

const PldSemanal = (props) => {
  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16}>
          <Header as='h1' dividing>
            <Icon name='calendar outline' />
            <Header.Content>
              PLD - Preços Semanais (R$/MWh)
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width={16}>
          <Menu secondary>
            <Menu.Menu position='left'>
              <Menu.Item fitted>
                <Form as='div'>
                  <Form.Group inline className='customFormGroup'>
                    <Form.Field>
                      <label>Ano</label>
                      <Dropdown compact
                        onChange={(event, data) => props.onChange({
                          target: {
                            name: 'year',
                            value: data.value
                          }
                        })}
                        label='Ano'
                        options={props.years}
                        placeholder='Ano'
                        selection
                        value={props.year}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Mês</label>
                      <Dropdown compact
                        onChange={(event, data) => props.onChange({
                          target: {
                            name: 'month',
                            value: data.value
                          }
                        })}
                        label='Mês'
                        options={props.months}
                        placeholder='Mês'
                        selection
                        value={props.month}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Semana Operativa</label>
                      <Dropdown compact
                        onChange={(event, data) => props.onChange({
                          target: {
                            name: 'week',
                            value: data.value
                          }
                        })}
                        options={props.weeks}
                        placeholder='Semana'
                        selection
                        value={props.week}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu position='right'>
              <Menu.Item fitted>
                <Button onClick={props.onEditResource} color='yellow' icon
                  labelPosition='left'>
                  <Icon name='edit' />{' '}Editar
                </Button>
              </Menu.Item>
              <Menu.Item fitted>
                <Button onClick={props.onExportToCSV} animated='vertical'>
                  <Button.Content hidden>CSV</Button.Content>
                  <Button.Content visible>
                    <Icon name='download' />
                  </Button.Content>
                </Button>
              </Menu.Item>
              <Menu.Item fitted>
                <SemanticUploadButton onUpload={props.onUploadCSV} label='CSV' />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Grid.Column>
        <Grid.Column width={16} style={{ marginBottom: '1em' }}>
          <FlashMessageContainer />
        </Grid.Column>
        <Grid.Column width={16}>
          <Table celled striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign='right'>Submercado</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>Patamar Pesado (R$/MWh)</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>Patamar Médio (R$/MWh)</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>Patamar Leve (R$/MWh)</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {props.isLoading && (
                <Table.Row>
                  <Table.Cell colSpan='4'>
                    <Loader active={props.isLoading} inline='centered' />
                  </Table.Cell>
                </Table.Row>
              )}
              {props.multiplePldSemanal.valueSeq().map((pld, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell textAlign='right'>{pld.getIn(['submercado', 'descricao'])}</Table.Cell>
                    <Table.Cell textAlign='right'>{maskCurrency.parse(pld.get('patamar_pesado'))}</Table.Cell>
                    <Table.Cell textAlign='right'>{maskCurrency.parse(pld.get('patamar_medio'))}</Table.Cell>
                    <Table.Cell textAlign='right'>{maskCurrency.parse(pld.get('patamar_leve'))}</Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

PldSemanal.propTypes = {
  isLoading: PropTypes.bool,
  loading: PropTypes.bool,
  multiplePldSemanal: PropTypes.object,

  onEditResource: PropTypes.func,
  onExportToCSV: PropTypes.func,
  onUploadCSV: PropTypes.func,
  onChange: PropTypes.func,

  year: PropTypes.number,
  month: PropTypes.number,
  week: PropTypes.number,
  years: PropTypes.array,
  months: PropTypes.array,
  weeks: PropTypes.array,
}

export default PldSemanal
