import { Map } from 'immutable'

export const UPDATE_PLD_MEDIO_SELECTION = 'UPDATE_PLD_MEDIO_SELECTION'
export function updatePldMedioSelection(property, value) {
  return {
    type: UPDATE_PLD_MEDIO_SELECTION,
    property,
    value,
  }
}

const initialState = Map({
  year: (new Date()).getFullYear(),
  years: function() {
    let arr = []
    const currentYear = (new Date()).getFullYear()
    for (let year = currentYear; year >= 2003; year--) {
      arr.push({
        key: `${year}`,
        text: `${year}`,
        value: year
      })
    }
    return arr
  }(),
})

export default function pldMedioSelection (state = initialState, action) {
  switch (action.type) {

    case UPDATE_PLD_MEDIO_SELECTION : {
      const { property, value } = action

      switch (property) {
        case 'year':
          state = state.set('year', value)
          break

        default:
          break
      }

      return state
    }

    default :
      return state
  }
}
