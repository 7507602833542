import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import get from 'lodash.get'
import { Container } from 'semantic-ui-react'
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
} from 'recharts'
import {
  COLORS,
  capitalize,
  formatAsLocalizedNumber,
  formatAsCurrency,
  formatAsPercentageCalculated,
  formatAsIndicador,
} from './utils/ChartUtils'
import CustomLineDotLabelBox from './utils/CustomLineDotLabelBox'
import LabelListInsideBottomCenterLabel from './utils/LabelListInsideBottomCenterLabel'

const fieldsNameMapping = {
  tusd_consumo: 'TUSD Consumo',
  tusd_demanda: 'TUSD Demanda',
  percentual: 'Participação no Total da Fatura',
}

const fieldFormatterMapping = {
  tusd_consumo: formatAsCurrency,
  tusd_demanda: formatAsCurrency,
  percentual: formatAsPercentageCalculated,
}

const customFormatter = v =>
  v && v > 0.0
    ? formatAsIndicador('R$ ', true)(v)
    : ''

const ParticipacaoTUSDChart = ({ data, height, showAnimations }) => {
  if (!data) return null

  const preparedData = data.map(d => {
    const tusd = get(d, 'tusd', {})
    const prepared = Object.assign({}, tusd)
    prepared._labelFaturaMes = capitalize(moment({y: d.ano, M: d.mes - 1}).format('MMM/YY'))
    prepared._parsedPercentual = (get(tusd, 'percentual', 0) * 100)
    return prepared
  })

  return (
    <Container fluid>
      <ResponsiveContainer height={height}>
        <ComposedChart data={preparedData} barGap={0}>
          <XAxis dataKey="_labelFaturaMes" type="category" interval={0} tickLine={false}/>
          <YAxis
            yAxisId="left"
            type="number"
            tickFormatter={formatAsIndicador('R$ ', true)}
            padding={{ top: 10 }}
          />
          <YAxis
            yAxisId="right"
            type="number"
            tickFormatter={value => `${value}%`}
            stroke={COLORS.DARK.DEFAULT}
            orientation="right"
            width={40}
          />
          <Tooltip formatter={value => `${formatAsLocalizedNumber(value)} `}/>
          <Legend/>
          <Bar
            dataKey="tusd_consumo"
            yAxisId="left"
            fill={COLORS.ELECTRIC.LIGHT.BLUE}
            legendType="square"
            name={fieldsNameMapping['tusd_consumo']}
            formatter={fieldFormatterMapping['tusd_consumo']}
            isAnimationActive={showAnimations}
          >
            <LabelList className='color-black' dataKey="tusd_consumo" position="center" angle={-90} formatter={customFormatter} content={LabelListInsideBottomCenterLabel}/>
          </Bar>
          <Bar
            dataKey="tusd_demanda"
            yAxisId="left"
            fill={COLORS.ELECTRIC.LIGHT.GREEN}
            legendType="square"
            name={fieldsNameMapping['tusd_demanda']}
            formatter={fieldFormatterMapping['tusd_demanda']}
            isAnimationActive={showAnimations}
          >
            <LabelList  className='color-black' dataKey="tusd_demanda" position="center" angle={-90} formatter={customFormatter} content={LabelListInsideBottomCenterLabel}/>
          </Bar>
          <Line
            type="linear"
            dataKey="_parsedPercentual"
            yAxisId="right"
            stroke={COLORS.DEFAULT}
            legendType="line"
            name={fieldsNameMapping['percentual']}
            formatter={fieldFormatterMapping['percentual']}
            dot={<CustomLineDotLabelBox formatter={formatAsPercentageCalculated} fill="#fff" stroke={COLORS.DEFAULT} />}
            activeDot={false}
            isAnimationActive={showAnimations}
            strokeWidth={3}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Container>
  )
}

ParticipacaoTUSDChart.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
  showAnimations: PropTypes.bool,
}

ParticipacaoTUSDChart.defaultProps = {
  data: [],
  height: 350,
  showAnimations: true,
}

export default ParticipacaoTUSDChart
