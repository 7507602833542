import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Instalacao } from '../../components'
import * as instalacaoClienteActionCreators from '../../redux/modules/instalacaoCliente'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class InstalacaoClienteContainer extends Component {

  static propTypes = {
    cliente: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,

    multipleInstalacaoCliente: PropTypes.object.isRequired,
    fetchMultipleInstalacaoCliente: PropTypes.func.isRequired,
    destroyInstalacaoCliente: PropTypes.func.isRequired,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,

    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string,
    filterCheckbox: PropTypes.bool,
    apenasAtivos: PropTypes.bool,
    tipoMercado: PropTypes.string,
  }

  async componentDidMount() {
    this.refreshInstalacoes()
  }

  async componentDidUpdate(prevProps) {
    const shouldRefresh =
      prevProps.filterCheckbox !== this.props.filterCheckbox ||
      prevProps.apenasAtivos !== this.props.apenasAtivos ||
      prevProps.tipoMercado !== this.props.tipoMercado

    if (!shouldRefresh) return
    this.refreshInstalacoes()
  }

  refreshInstalacoes = async () => {
    const { cliente, filterCheckbox, apenasAtivos, tipoMercado } = this.props
    const userId = filterCheckbox ? JSON.parse(localStorage.getItem('jwt')).payload.sub : ''
    await this.props.fetchMultipleInstalacaoCliente(cliente.id, userId, apenasAtivos, tipoMercado)
  }

  handleRedirectToNew = (clienteId) => {
    this.props.history.push(`/clientes/${clienteId}/instalacoes/new`)
  }

  handleEditResource = (clienteId, id) => {
    this.props.history.push(`/clientes/${clienteId}/instalacoes/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyInstalacaoCliente(id)
      ? this.props.showSuccessfulFlashMessage('', 'destroy')
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  handleSort = column => () => this.props.updateSort('instalacaoCliente', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('instalacaoCliente', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleInstalacaoCliente.toList().toJSON(),
      'instalacaoCliente',
      ['id', 'sigla', 'nome', 'cnpj'],
    )
  }

  componentWillUnmount() {
    this.props.hideFlashMessage()
  }

  render() {
    return (
      <Instalacao noTitle noPadded
        title='Instalações'
        listPath='instalacoes'
        multipleInstalacao={this.props.multipleInstalacaoCliente}
        onRedirectToNew={() => this.handleRedirectToNew(this.props.cliente.id)}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        filterCheckbox={this.props.filterCheckbox}
        apenasAtivos={this.props.apenasAtivos}
        tipoMercado={this.props.tipoMercado}
        onExportToCSV={this.handleExportToCSV}
        isLoading={this.props.isLoading}
      />
    )
  }
}

function mapStateToProps({ instalacaoCliente, status, sort, filter }, { cliente }) {
  const sortColumn = sort.getIn(['instalacaoCliente', 'sortColumn']) || 'nome'
  const sortDirection = sort.getIn(['instalacaoCliente', 'sortDirection']) || 'ascending'
  const filterText = filter.getIn(['instalacaoCliente', 'filterText']) || ''

  const filterCheckbox = filter.getIn(['instalacaoCliente', 'checked'], true)
  const apenasAtivos = filter.getIn(['instalacaoCliente', 'apenasAtivos'], true)
  const tipoMercado = filter.getIn(['instalacaoCliente', 'tipoMercado'], 'ambos')
  const map = sortMap(filterMap(instalacaoCliente, ['apelido', 'nome', 'cnpj', 'uf.sigla', 'uf.nome', 'modalidade', 'subgrupo', 'tipo_mercado'], filterText), sortColumn, sortDirection)

  return {
    multipleInstalacaoCliente: map.filter(t => t.getIn(['cliente', 'id']) === cliente.id),
    sortColumn,
    sortDirection,
    filterText,
    filterCheckbox,
    apenasAtivos,
    tipoMercado,
    error: status.getIn(['instalacaoCliente', 'error']),
    isLoading: status.getIn(['instalacaoCliente', 'isLoading']),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...instalacaoClienteActionCreators,
    ...flashMessageActionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InstalacaoClienteContainer))
