import React from 'react'
import PropTypes from 'prop-types'
import { Table, Label, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { List } from 'immutable'

import { CRUDIndex } from '../CRUD'
import { EditDeleteButtonContainer } from '../../containers'
import { formatDate } from '../../utils/formats'

const VigenciaTarifa = props => {
  return (
    <CRUDIndex
      noTitle
      padded={false}
      onRedirectToNew={props.onRedirectToNew}
      onExportToCSV={props.onExportToCSV}
      filterText={props.filterText}
      handleFilter={props.handleFilter}>
      <Table sortable celled striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              width={1}
              textAlign='center'
              onClick={props.handleSort('id')}
              sorted={props.handleSorted('id')}>
              #
            </Table.HeaderCell>
            <Table.HeaderCell
              width={3}
              onClick={props.handleSort('starts_at')}
              sorted={props.handleSorted('starts_at')}>
              Início da Vigência
            </Table.HeaderCell>
            <Table.HeaderCell>Subgrupos</Table.HeaderCell>
            <Table.HeaderCell width={1}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {props.isLoading && (
            <Table.Row>
              <Table.Cell colSpan={4}>
                <Loader active={props.isLoading} inline='centered' />
              </Table.Cell>
            </Table.Row>
          )}

          {props.multipleVigenciaTarifa
            .valueSeq()
            .map((vigenciaTarifa, index) => {
              let subgruposList = List([])
              if (props.multipleSubgrupo.size > 0) {
                const filtered = vigenciaTarifa
                  .get('valores_tarifas')
                  .valueSeq()
                  .filter(vt => {
                    return [
                      parseFloat(vt.get('tusd_kw_p')),
                      parseFloat(vt.get('tusd_kwh_p')),
                      parseFloat(vt.get('te_kwh_p')),
                      parseFloat(vt.get('tusd_kw_fp')),
                      parseFloat(vt.get('tusd_kwh_fp')),
                      parseFloat(vt.get('te_kwh_fp')),
                      parseFloat(vt.get('tusd_kw_int')),
                      parseFloat(vt.get('tusd_kwh_int')),
                      parseFloat(vt.get('te_kwh_int')),
                      parseFloat(vt.get('tr_kwh')),
                    ].some(v => v > 0.0)
                  })

                subgruposList = filtered.reduce((acc, curr) => {
                  const found = acc.find(
                    s => s.get('id') === curr.get('subgrupo_id')
                  )
                  if (!found) {
                    acc = acc.push(
                      props.multipleSubgrupo.get(`${curr.get('subgrupo_id')}`)
                    )
                  }
                  return acc
                }, subgruposList)
              }

              return (
                <Table.Row key={index}>
                  <Table.Cell textAlign='center'>
                    <Label
                      as={Link}
                      to={`/concessionarias/${vigenciaTarifa.getIn([
                        'concessionaria',
                        'id',
                      ])}/tarifas/${vigenciaTarifa.get('id')}`}>
                      {vigenciaTarifa.get('id')}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    {formatDate(vigenciaTarifa.get('starts_at'))}
                  </Table.Cell>
                  <Table.Cell>
                    <div className='labelListWrapper'>
                      {subgruposList.valueSeq().map((subgrupo, index) => (
                        <div key={index} className='labelWrapper'>
                          <Label
                            key={index}
                            as={Link}
                            to={`/subgrupos/${subgrupo.get('id')}`}>
                            {subgrupo.get('nome')}
                          </Label>
                        </div>
                      ))}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <EditDeleteButtonContainer
                      onEditResource={() =>
                        props.onEditResource(
                          vigenciaTarifa.getIn(['concessionaria', 'id']),
                          vigenciaTarifa.get('id')
                        )
                      }
                      onDeleteResource={() =>
                        props.onDeleteResource(vigenciaTarifa.get('id'))
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
    </CRUDIndex>
  )
}

VigenciaTarifa.propTypes = {
  onRedirectToNew: PropTypes.func,
  multipleVigenciaTarifa: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
  multipleSubgrupo: PropTypes.object,
}

export default VigenciaTarifa
