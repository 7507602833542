import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { ComercializadoraForm } from '../../components'
import * as comercializadoraActionCreators from '../../redux/modules/comercializadora'
import * as ufActionCreators from '../../redux/modules/uf'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { mapToDropdown } from '../../utils/parse'

class ComercializadoraFormContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    fetchComercializadora: PropTypes.func.isRequired,
    createComercializadora: PropTypes.func.isRequired,
    updateComercializadora: PropTypes.func.isRequired,
    multipleUf: PropTypes.object,
    fetchMultipleUf: PropTypes.func.isRequired,
    ufDropdown: PropTypes.array.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleUf()
    if (this.props.id) {
      const comercializadora = await this.props.fetchComercializadora(this.props.id)
      this.props.initialize('ComercializadoraForm', {comercializadora})
    }
  }

  handleSubmitResource = async (comercializadora) => {
    if (this.props.id) {
      if (await this.props.updateComercializadora(this.props.id, comercializadora)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/comercializadoras/${this.props.id}`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createComercializadora(comercializadora)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/comercializadoras')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <ComercializadoraForm
        ufDropdown={this.props.ufDropdown}
        multipleUf={this.props.multipleUf}
        onSubmit={(comercializadora) => this.handleSubmitResource(comercializadora)}
        loading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({comercializadora, uf, status, flashMessage}, {match}) {
  const isLoading = status.getIn(['comercializadora', 'isLoading']) || status.getIn(['uf', 'isLoading'])
  const { id } = match.params
  const ufDropdown = mapToDropdown(
    uf,
    e => e.get('id'),
    e => `${e.get('sigla')} - ${e.get('nome')}`
  )

  if (id) {
    return {
      id,
      comercializadora: comercializadora.get('id'),
      ufDropdown,
      error: status.getIn(['comercializadora', 'error']),
      shouldHideFlashMessage: flashMessage.get('isError'),
      isLoading,
    }
  }
  return {
    ufDropdown,
    error: status.getIn(['comercializadora', 'error']),
    shouldHideFlashMessage: flashMessage.get('isError'),
    isLoading,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...comercializadoraActionCreators,
    ...ufActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ComercializadoraFormContainer)
