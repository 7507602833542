import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import ValorBandeira from './ValorBandeira'

const RenderValoresBandeiras = ({ fields, loading }) => {
  return (
    <Table.Body>
      { fields.map((valorBandeira, index) => (
        <ValorBandeira
          key={index}
          valorBandeira={valorBandeira}
          loading={loading}
        />
      ))}
    </Table.Body>
  )
}

RenderValoresBandeiras.propTypes = {
  fields: PropTypes.object,
  loading: PropTypes.bool,
}

export default RenderValoresBandeiras
