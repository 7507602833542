



import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField, Divider } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput, SemanticInputMask, SemanticDropdown } from '../FormComponents'

import { required, cnpj, cpf, maxLengthSigla, maxLengthDescricao } from '../../utils/validations'
import { maskCNPJ, maskCPF } from '../../utils/masks'

const Cliente = (props) => (
  <CRUDForm
    title='Cliente'
    icon='building outline'
    {...props}>
    <FormSection name='cliente'>
      <Divider section horizontal>Dados Principais</Divider>
      <Form.Group>
        <FormField width={4}>
          <label htmlFor='sigla'>Sigla</label>
          <Field
            autoFocus
            name='sigla'
            placeholder='Sigla'
            validate={[required, maxLengthSigla]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField width={12}>
          <label htmlFor='nome'>Nome/Razão Social</label>
          <Field
            name='nome'
            placeholder='Nome/Razão Social'
            validate={[required, maxLengthDescricao]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>

      <Form.Group widths='equal'>
        <FormField>
          <label htmlFor='pessoa'>Tipo</label>
          <Field fluid button floating labeled search icon='marker' className='icon'
            name='pessoa'
            options={props.pessoaDropdown}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>

        <FormField>
          <label htmlFor='cnpj'>{props.pessoa === 0 ? 'CNPJ' : 'CPF'}</label>
          <Field
            name='cnpj'
            placeholder={props.pessoa === 0 ? maskCNPJ.placeholder : maskCPF.placeholder}
            validate={[props.pessoa === 0 ? cnpj : cpf]}
            component={SemanticInputMask}
            mask={props.pessoa === 0 ? maskCNPJ.mask : maskCPF.mask}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <label htmlFor='ins_municipal'>Inscrição Municipal</label>
          <Field
            name='ins_municipal'
            placeholder='Inscrição Municipal'
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <label htmlFor='ins_estadual'>Inscrição Estadual</label>
          <Field
            name='ins_estadual'
            placeholder='Inscrição Estadual'
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>

      <Divider section horizontal>Endereço</Divider>

      <Form.Group>
        <FormField width={12}>
          <label htmlFor='endereco'>Endereço</label>
          <Field
            name='endereco'
            placeholder='Endereco'
            validate={[]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField width={4}>
          <label htmlFor='cep'>CEP</label>
          <Field
            name='cep'
            placeholder='CEP'
            validate={[]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>

      <Form.Group widths='equal'>
        <FormField>
          <label htmlFor='bairro'>Bairro</label>
          <Field
            name='bairro'
            placeholder='Bairro'
            validate={[]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <label htmlFor='cidade'>Cidade</label>
          <Field
            name='cidade'
            placeholder='Cidade'
            validate={[]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <label htmlFor='uf_id'>Unidade Federativa</label>
          <Field fluid button floating labeled search icon='marker' className='icon'
            name='uf_id'
            options={props.ufDropdown}
            validate={[]}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>

      <Divider section horizontal>Dados Bancários</Divider>

      <Form.Group widths='equal'>
        <FormField>
          <label htmlFor='banco'>Banco</label>
          <Field
            name='banco'
            placeholder='Banco'
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <label htmlFor='agencia'>Agência</label>
          <Field
            name='agencia'
            placeholder='Agência'
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
        <FormField>
          <label htmlFor='conta'>Conta Corrente</label>
          <Field
            name='conta'
            placeholder='Conta Corrente'
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>
    </FormSection>
  </CRUDForm>
)

Cliente.propTypes = {
  pessoa: PropTypes.number,
  pessoaDropdown: PropTypes.array,
  ufDropdown: PropTypes.array,
  loading: PropTypes.bool
}

export default reduxForm({form: 'ClienteForm'})(Cliente)
