import FileSaver from 'file-saver'
import { Parser } from 'json2csv'
import moment from 'moment'

export const arrayToCSV = (array, prefix, fields, unwind = []) => {
  let result
  try {
    const options = { delimiter: ';', fields, unwind }
    const parser = new Parser(options)
    result = parser.parse(array)
  } catch (err) {
    result = err
  }

  var blob = new Blob([result], {type: 'text/plain;charset=utf-8'})
  FileSaver.saveAs(blob, `${prefix}_${moment().format('YYYYMMDD_HHMMSS')}.csv`)
}
