import React from 'react'
import PropTypes from 'prop-types'

import { Table } from 'semantic-ui-react'
import { maskFloat } from '../../utils/masks'

const DataTable = ({ data, title, color }) => {

  let totalCru = 0.0
  let totalCalculado = 0.0

  const postos = data.map(posto => {
    totalCru += posto.cru
    totalCalculado += posto.calculado
    return posto.render
  })

  if (!totalCru && !totalCalculado) return (null)

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{backgroundColor: color}} textAlign='right'>{title}</Table.HeaderCell>
          <Table.HeaderCell style={{backgroundColor: color}} textAlign='right'>Sem Impostos</Table.HeaderCell>
          <Table.HeaderCell style={{backgroundColor: color}} textAlign='right'>Com Impostos</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      {postos}
      <Table.Footer>
        <Table.Row className='pseudo-total'>
          <Table.HeaderCell textAlign='right'>Total</Table.HeaderCell>
          <Table.HeaderCell textAlign='right' style={{fontFamily: 'monospace'}}>{maskFloat.parse(totalCru.toFixed(2))}</Table.HeaderCell>
          <Table.HeaderCell textAlign='right' style={{fontFamily: 'monospace'}}>{maskFloat.parse(totalCalculado.toFixed(2))}</Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  )
}

DataTable.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  data: PropTypes.object,
}

export default DataTable
