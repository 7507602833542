import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { SubmercadoShow } from '../../components'
import * as submercadoActionCreators from '../../redux/modules/submercado'

class SubmercadoShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    fetchSubmercado: PropTypes.func,
    history: PropTypes.object,
    destroySubmercado: PropTypes.func,
  }

  state = {
    submercado: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const submercado = await this.props.fetchSubmercado(this.props.id)
      this.setState({ submercado })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/submercados/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/submercados/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroySubmercado(id)
    this.props.history.push('submercados')
  }

  render () {
    return (
      <SubmercadoShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        submercado={this.state.submercado} />
    )
  }
}

function mapStateToProps ({submercado}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      submercado: submercado.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...submercadoActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmercadoShowContainer)
