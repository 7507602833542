import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { Label, Message, Icon, Popup } from 'semantic-ui-react'

import { maskFloat } from '../../utils/masks'
import { formatDate } from '../../utils/formats'

import moment from 'moment'

const CustomPopup = props => (
  <Popup
    inverted
    size='mini'
    position='bottom center'
    trigger={
      props.url ? (
        <Label as={Link} to={props.url} target='_blank'>
          {props.label}
        </Label>
      ) : (
        <Label>{props.label}</Label>
      )
    }
    header={props.header}
  />
)

CustomPopup.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  header: PropTypes.string,
}

const ResumoParametros = props => {
  const { vigenciaParametros, dataFinal, instalacao } = props
  const parametros = vigenciaParametros
    ? vigenciaParametros.get('parametro_calculo')
    : undefined

  if (!vigenciaParametros || !parametros)
    return (
      <Message
        header='Atenção'
        icon='warning circle'
        content={`Não foram localizados parâmetros técnicos da instalação para a data ${dataFinal.format(
          'DD/MM/YYYY'
        )}.`}
      />
    )

  return (
    <Message info icon>
      <Icon name='calculator' />
      <Message.Content>
        <Message.Header>
          Resumo dos Parâmetros da Instalação em{' '}
          {moment(dataFinal).format('DD/MM/YYYY')}
        </Message.Header>
        <div style={{ marginTop: '1em' }}>
          <CustomPopup
            url={`/instalacoes/${instalacao}/parametros/${vigenciaParametros.get(
              'id'
            )}`}
            label={formatDate(vigenciaParametros.get('starts_at'))}
            header='Início da Vigência'
          />

          <CustomPopup
            url={`/enquadramentos/${parametros.getIn(['enquadramento', 'id'])}`}
            label={parametros.getIn(['enquadramento', 'modalidade'])}
            header='Enquadramento'
          />

          <CustomPopup
            url={`/subgrupos/${parametros.getIn(['subgrupo', 'id'])}`}
            label={parametros.getIn(['subgrupo', 'nome'])}
            header='Subgrupo'
          />

          <CustomPopup
            url={`/classes_consumo/${parametros.getIn([
              'classe_consumo',
              'id',
            ])}`}
            label={parametros.getIn(['classe_consumo', 'nome'])}
            header='Classe de Consumo'
          />

          <CustomPopup
            url={`/concessionarias/${parametros.getIn([
              'concessionaria',
              'id',
            ])}`}
            label={parametros.getIn(['concessionaria', 'sigla'])}
            header='Concessionária'
          />

          {parametros.get('liminar_demanda') && (
            <CustomPopup
              label={'Liminar de Demanda'}
              header='Possui Liminar de Demanda'
            />
          )}

          {parametros.get('possui_gerador') && (
            <CustomPopup
              url={`/geradores/${parametros.getIn(['gerador', 'id'])}`}
              label={`${parametros.getIn([
                'gerador',
                'tipo',
              ])} - ${parametros.getIn([
                'gerador',
                'marca',
              ])} - ${parametros.getIn([
                'gerador',
                'modelo',
              ])} - ${maskFloat.parse(
                parametros.getIn(['gerador', 'potencia'])
              )} kVA`}
              header='Gerador'
            />
          )}
        </div>
      </Message.Content>
    </Message>
  )
}

ResumoParametros.propTypes = {
  dataFinal: PropTypes.object,
  instalacao: PropTypes.string,
  vigenciaParametros: PropTypes.object,
}

export default ResumoParametros
