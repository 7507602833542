import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { ContatoComercializadora } from '../../../containers'

const Contato = (props) => {
  const { comercializadora } = props
  return (
    <div>
      <Switch>
        <Route
          exact path='/comercializadoras/:comercializadoraId/contatos'
          render={({match}) => (
            <ContatoComercializadora.Index
              comercializadora={comercializadora}
              match={match}
            />
          )}>
        </Route>
        <Route
          exact path='/comercializadoras/:comercializadoraId/contatos/new'
          render={({match}) => (
            <ContatoComercializadora.Form
              comercializadora={comercializadora}
              match={match}
            />
          )}
        />
        <Route
          exact path='/comercializadoras/:comercializadoraId/contatos/:id/edit'
          render={({match}) => (
            <ContatoComercializadora.Form
              comercializadora={comercializadora}
              match={match}
            />
          )}
        />
        <Route
          exact path='/comercializadoras/:comercializadoraId/contatos/:id'
          render={({match}) => (
            <ContatoComercializadora.Show
              comercializadora={comercializadora}
              match={match}
            />
          )}
        />
      </Switch>
    </div>
  )
}

Contato.propTypes = {
  comercializadora: PropTypes.object.isRequired,
}

export default Contato
