import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'

const SubgrupoShow = (props) => {
  if (props.subgrupo) {
    return (
      <CRUDShow
        title={props.subgrupo.nome}
        icon='table'
        onEditResource={() => props.onEditResource(props.subgrupo.id)}
        onDeleteResource={() => props.onDeleteResource(props.subgrupo.id)}>
        <ul>
          <li>Nome: {props.subgrupo.nome}</li>
          <li>Descrição: {props.subgrupo.descricao}</li>
        </ul>
      </CRUDShow>
    )
  }

  return (null)
}

SubgrupoShow.propTypes = {
  subgrupo: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
}

export default SubgrupoShow
