import React from 'react'
import PropTypes from 'prop-types'

import { Divider, Grid, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { CRUDShow } from '../../CRUD'

const Informacoes = (props) => {
  const { cliente, uf } = props

  return (
    <CRUDShow noTitle padded={false}
      onEditResource={() => props.onEditResource(cliente.id)}
      onDeleteResource={() => props.onDeleteResource(cliente.id)}>
      <Grid columns='equal'>
        <Grid.Column width={16}>
          <Divider horizontal>Dados Principais</Divider>
        </Grid.Column>

        <Grid.Row>
          <Grid.Column textAlign='right' width={2}>
            <strong>Sigla</strong>
          </Grid.Column>
          <Grid.Column width={2}>
            {cliente.sigla}
          </Grid.Column>

          <Grid.Column textAlign='right' width={3}>
            <strong>Nome/Razão Social</strong>
          </Grid.Column>
          <Grid.Column width={9}>
            {cliente.nome}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column textAlign='right' width={2}>
            <strong>CNPJ</strong>
          </Grid.Column>
          <Grid.Column width={3}>
            {cliente.cnpj}
          </Grid.Column>

          <Grid.Column textAlign='right' width={2}>
            <strong>Insc. Municipal</strong>
          </Grid.Column>
          <Grid.Column width={3}>
            {cliente.ins_municipal}
          </Grid.Column>

          <Grid.Column textAlign='right' width={2}>
            <strong>Insc. Estadual</strong>
          </Grid.Column>
          <Grid.Column width={4}>
            {cliente.ins_estadual}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Divider horizontal>Endereço</Divider>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column textAlign='right' width={2}>
            <strong>Endereço</strong>
          </Grid.Column>
          <Grid.Column width={8}>
            {cliente.endereco}
          </Grid.Column>

          <Grid.Column textAlign='right' width={2}>
            <strong>CEP</strong>
          </Grid.Column>
          <Grid.Column width={4}>
            {cliente.cep}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column textAlign='right' width={2}>
            <strong>Bairro</strong>
          </Grid.Column>
          <Grid.Column width={2}>
            {cliente.bairro}
          </Grid.Column>

          <Grid.Column textAlign='right' width={3}>
            <strong>Cidade</strong>
          </Grid.Column>
          <Grid.Column width={4}>
            {cliente.cidade}
          </Grid.Column>

          <Grid.Column textAlign='right' width={1}>
            <strong>UF</strong>
          </Grid.Column>
          <Grid.Column width={4}>
            <Label as={Link} to={`/uf/${uf.id}`}>
              {uf.sigla}
            </Label>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Divider horizontal>Dados Bancários</Divider>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column textAlign='right' width={2}>
            <strong>Banco</strong>
          </Grid.Column>
          <Grid.Column width={2}>
            {cliente.banco}
          </Grid.Column>

          <Grid.Column textAlign='right' width={3}>
            <strong>Agência</strong>
          </Grid.Column>
          <Grid.Column width={4}>
            {cliente.agencia}
          </Grid.Column>

          <Grid.Column textAlign='right' width={1}>
            <strong>Conta</strong>
          </Grid.Column>
          <Grid.Column width={4}>
            {cliente.conta}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Divider horizontal>Dados Adicionais</Divider>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column textAlign='right' width={2}>
            <em>Criado em</em><br />
            <em>Atualizado em</em>
          </Grid.Column>
          <Grid.Column>
            {cliente.created_at}<br/>
            {cliente.updated_at}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </CRUDShow>
  )
}

Informacoes.propTypes = {
  uf: PropTypes.object,
  cliente: PropTypes.object,
  onEditResource: PropTypes.func.isRequired,
  onDeleteResource: PropTypes.func.isRequired
}

export default Informacoes
