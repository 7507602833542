import React from 'react'
import PropTypes from 'prop-types'
import { Card, List, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { CRUDIndex } from '../CRUD'

function getMesNome (mes) {
  switch (mes) {
    case 1: return 'Janeiro'
    case 2: return 'Fevereiro'
    case 3: return 'Março'
    case 4: return 'Abril'
    case 5: return 'Maio'
    case 6: return 'Junho'
    case 7: return 'Julho'
    case 8: return 'Agosto'
    case 9: return 'Setembro'
    case 10: return 'Outubro'
    case 11: return 'Novembro'
    case 12: return 'Dezembro'
    default: return ''
  }
}

const Concessionaria = (props) => (
  <CRUDIndex
    title='Concessionárias'
    icon='building'
    onRedirectToNew={props.onRedirectToNew}
    onExportToCSV={props.onExportToCSV}
    filterText={props.filterText}
    handleFilter={props.handleFilter}>
    <Loader inline='centered' active={props.isLoading} />

    <Card.Group style={{ marginTop: '0.5em' }}>
      {props.multipleConcessionaria.valueSeq().map((concessionaria, index) => (
        <Card
          key={index}
          as={Link}
          to={`/concessionarias/${concessionaria.get('id')}`}
          label={{ as: 'a', color: 'blue', content: 'Food', icon: 'spoon', ribbon: true }}
        >
          <Card.Content>
            <Card.Header>
              {concessionaria.get('sigla')}
            </Card.Header>
            <Card.Meta>
              {concessionaria.get('nome')}
            </Card.Meta>
            <Card.Description>
              <List>
                <List.Item>
                  <List.Icon name='calendar'/>
                  <List.Content>
                    <List.Header>Reajuste</List.Header>
                    <List.Description>{concessionaria.get('dia')}/{getMesNome(concessionaria.get('mes'))}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name='id card'/>
                  <List.Content>
                    <List.Header>CNPJ</List.Header>
                    <List.Description>{concessionaria.get('cnpj')}</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <List>
              <List.Item icon='marker' content={concessionaria.getIn(['uf', 'nome'])} />
            </List>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  </CRUDIndex>
)

Concessionaria.propTypes = {
  onRedirectToNew: PropTypes.func.isRequired,
  multipleConcessionaria: PropTypes.object.isRequired,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default Concessionaria
