import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, FormSection, formValueSelector } from 'redux-form/immutable'
import { Grid, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInputMask, SemanticCheckbox, SemanticDropdown } from '../FormComponents'

import { required, percentage } from '../../utils/validations'
import { maskCurrency } from '../../utils/masks'

let FaturaEnergiaForm = props => {
  const {
    temMCP,
    temOutros
  } = props

  return (
    <CRUDForm
      title='Fatura de Energia'
      icon='dollar'
      {...props}>
      <FormSection name='fatura_energia'>
        <Grid columns={3} divided>
          <Grid.Row>
            <Grid.Column>
              <FormField>
                <label htmlFor='contrato_energia_id'>Contrato de Energia</label>
                <Field fluid button floating labeled search icon='file text' className='icon'
                  autoFocus
                  name='contrato_energia_id'
                  options={props.contratoEnergiaDropdown}
                  validate={[required]}
                  component={SemanticDropdown}
                  loading={props.loading}
                />
              </FormField>

              <FormField>
                <label>Consumo</label>
                <Field
                  name='consumo'
                  label='kWh'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskCurrency.mask()}
                  placeholder={maskCurrency.placeholder}
                />
              </FormField>

              <FormField>
                <label>Perdas</label>
                <Field
                  name='perdas'
                  label='%'
                  validate={[required, percentage]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskCurrency.mask()}
                  placeholder={maskCurrency.placeholder}
                />
              </FormField>

              <FormField>
                <label>Valor Total</label>
                <Field
                  name='valor_total'
                  label='R$'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskCurrency.mask()}
                  placeholder={maskCurrency.placeholder}
                />
              </FormField>

              <FormField>
                <label>Multas</label>
                <Field
                  name='multas'
                  label='R$'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskCurrency.mask()}
                  placeholder={maskCurrency.placeholder}
                />
              </FormField>

              <FormField>
                <label>Juros</label>
                <Field
                  name='juros'
                  label='R$'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskCurrency.mask()}
                  placeholder={maskCurrency.placeholder}
                />
              </FormField>

              <FormField>
                <label>Correção Monetária</label>
                <Field
                  name='correcao_monetaria'
                  label='R$'
                  validate={[required]}
                  component={SemanticInputMask}
                  loading={props.loading}
                  mask={maskCurrency.mask()}
                  placeholder={maskCurrency.placeholder}
                />
              </FormField>
            </Grid.Column>

            <Grid.Column>
              <FormField>
                <label htmlFor="mcp">MCP</label>
                <Field
                  name='mcp'
                  id='mcp'
                  component={SemanticCheckbox}
                  loading={props.loading}
                />
              </FormField>

              {temMCP && (
                <div>
                  <FormField>
                    <label>Consumo Negociado</label>
                    <Field
                      name='mcp_consumo_negociado'
                      label='kWh'
                      validate={[required]}
                      component={SemanticInputMask}
                      loading={props.loading}
                      mask={maskCurrency.mask()}
                      placeholder={maskCurrency.placeholder}
                    />
                  </FormField>

                  <FormField>
                    <label>Spread</label>
                    <Field
                      name='mcp_spread'
                      label='R$'
                      validate={[required]}
                      component={SemanticInputMask}
                      loading={props.loading}
                      mask={maskCurrency.mask()}
                      placeholder={maskCurrency.placeholder}
                    />
                  </FormField>

                  <FormField>
                    <label htmlFor='mcp_comercializadora_id'>Comercializadora</label>
                    <Field fluid button floating labeled search icon='shopping cart' className='icon'
                      name='mcp_comercializadora_id'
                      options={props.comercializadoraDropdown}
                      validate={[required]}
                      component={SemanticDropdown}
                      loading={props.loading}
                    />
                  </FormField>

                  <FormField>
                    <label>Multas</label>
                    <Field
                      name='mcp_multas'
                      label='R$'
                      validate={[required]}
                      component={SemanticInputMask}
                      loading={props.loading}
                      mask={maskCurrency.mask()}
                      placeholder={maskCurrency.placeholder}
                    />
                  </FormField>

                  <FormField>
                    <label>Juros</label>
                    <Field
                      name='mcp_juros'
                      label='R$'
                      validate={[required]}
                      component={SemanticInputMask}
                      loading={props.loading}
                      mask={maskCurrency.mask()}
                      placeholder={maskCurrency.placeholder}
                    />
                  </FormField>

                  <FormField>
                    <label>Correção Monetária</label>
                    <Field
                      name='mcp_correcao_monetaria'
                      label='R$'
                      validate={[required]}
                      component={SemanticInputMask}
                      loading={props.loading}
                      mask={maskCurrency.mask()}
                      placeholder={maskCurrency.placeholder}
                    />
                  </FormField>
                </div>
              )}
            </Grid.Column>

            <Grid.Column>
              <FormField>
                <label htmlFor="outros">Outros</label>
                <Field
                  name='outros'
                  id='outros'
                  component={SemanticCheckbox}
                  loading={props.loading}
                />
              </FormField>

              {temOutros && (
                <div>
                  <FormField>
                    <label>Consumo Negociado</label>
                    <Field
                      name='outros_consumo_negociado'
                      label='kWh'
                      validate={[required]}
                      component={SemanticInputMask}
                      loading={props.loading}
                      mask={maskCurrency.mask()}
                      placeholder={maskCurrency.placeholder}
                    />
                  </FormField>

                  <FormField>
                    <label>Spread</label>
                    <Field
                      name='outros_spread'
                      label='R$'
                      validate={[required]}
                      component={SemanticInputMask}
                      loading={props.loading}
                      mask={maskCurrency.mask()}
                      placeholder={maskCurrency.placeholder}
                    />
                  </FormField>

                  <FormField>
                    <label htmlFor='outros_comercializadora_id'>Comercializadora</label>
                    <Field fluid button floating labeled search icon='shopping cart' className='icon'
                      name='outros_comercializadora_id'
                      options={props.comercializadoraDropdown}
                      validate={[required]}
                      component={SemanticDropdown}
                      loading={props.loading}
                    />
                  </FormField>

                  <FormField>
                    <label>Multas</label>
                    <Field
                      name='outros_multas'
                      label='R$'
                      validate={[required]}
                      component={SemanticInputMask}
                      loading={props.loading}
                      mask={maskCurrency.mask()}
                      placeholder={maskCurrency.placeholder}
                    />
                  </FormField>

                  <FormField>
                    <label>Juros</label>
                    <Field
                      name='outros_juros'
                      label='R$'
                      validate={[required]}
                      component={SemanticInputMask}
                      loading={props.loading}
                      mask={maskCurrency.mask()}
                      placeholder={maskCurrency.placeholder}
                    />
                  </FormField>

                  <FormField>
                    <label>Correção Monetária</label>
                    <Field
                      name='outros_correcao_monetaria'
                      label='R$'
                      validate={[required]}
                      component={SemanticInputMask}
                      loading={props.loading}
                      mask={maskCurrency.mask()}
                      placeholder={maskCurrency.placeholder}
                    />
                  </FormField>
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </FormSection>
    </CRUDForm>
  )
}

FaturaEnergiaForm.propTypes = {
  temMCP: PropTypes.bool,
  temOutros: PropTypes.bool,
  contratoEnergiaDropdown: PropTypes.array,
  comercializadoraDropdown: PropTypes.array,
  loading: PropTypes.bool
}

FaturaEnergiaForm = reduxForm({
  form: 'FaturaEnergiaForm'
})(FaturaEnergiaForm)

const selector = formValueSelector('FaturaEnergiaForm')

FaturaEnergiaForm = connect(state => {
  const temMCP = selector(state, 'fatura_energia.mcp')
  const temOutros = selector(state, 'fatura_energia.outros')

  return {
    temMCP,
    temOutros
  }
})(FaturaEnergiaForm)

export default FaturaEnergiaForm
