import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { FonteEnergiaShow } from '../../components'
import * as fonteEnergiaActionCreators from '../../redux/modules/fonteEnergia'

class FonteEnergiaShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    fetchFonteEnergia: PropTypes.func,
    history: PropTypes.object,
    destroyFonteEnergia: PropTypes.func,
  }

  state = {
    fonteEnergia: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const fonteEnergia = await this.props.fetchFonteEnergia(this.props.id)
      this.setState({ fonteEnergia })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/fontes_energia/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/fontes_energia/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyFonteEnergia(id)
    this.props.history.push('/fontes_energia')
  }

  render () {
    return (
      <FonteEnergiaShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        fonteEnergia={this.state.fonteEnergia} />
    )
  }
}

function mapStateToProps ({fonteEnergia}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      fonteEnergia: fonteEnergia.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...fonteEnergiaActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FonteEnergiaShowContainer)
