import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Label } from 'semantic-ui-react'

import { getColor, getIcon, getText } from './StateUtils'

const FaturaStateLabel = ({ state, size }) => {

  const stateColor = getColor(state)
  const stateIcon = getIcon(state)
  const stateText = getText(state)

  return (
    <Label size={size} color={stateColor}>
      <Icon name={stateIcon} /> {stateText}
    </Label>
  )
}

FaturaStateLabel.propTypes = {
  state: PropTypes.string,
  size: PropTypes.string,
}

FaturaStateLabel.defaultProps = {
  state: 'rascunho',
  size: 'medium',
}



export default FaturaStateLabel
