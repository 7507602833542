import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { UfShow } from '../../components'
import * as ufActionCreators from '../../redux/modules/uf'

class UfShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    fetchUf: PropTypes.func,
    history: PropTypes.object,
    destroyUf: PropTypes.func,
  }

  state = {
    uf: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const uf = await this.props.fetchUf(this.props.id)
      this.setState({ uf })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/uf/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/uf/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyUf(id)
    this.props.history.push('/uf')
  }

  render () {
    return (
      <UfShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        uf={this.state.uf} />
    )
  }
}

function mapStateToProps ({uf}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      uf: uf.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...ufActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UfShowContainer)
