import { fromJS, Map } from 'immutable'

export const UPDATE_SORT = 'UPDATE_SORT'
export function updateSort(collection, sortColumn) {
  return {
    type: UPDATE_SORT,
    collection,
    sortColumn
  }
}

const structure = {
  sortColumn: 'id',
  sortDirection: 'ascending'
}

const initialState = Map({})

export default function sort (state = initialState, action) {
  switch (action.type) {

    case UPDATE_SORT : {
      const { collection, sortColumn } = action
      if (!state.has(collection)) state = state.set(collection, fromJS(structure))

      const currentSortColumn = state.getIn([collection, 'sortColumn'])
      const currentSortDirection = state.getIn([collection, 'sortDirection'])

      if (sortColumn !== currentSortColumn) {
        return state
          .setIn([collection, 'sortColumn'], sortColumn)
          .setIn([collection, 'sortDirection'], 'ascending')
      }

      const sortDirection = currentSortDirection === 'ascending' ? 'descending' : 'ascending'
      return state.setIn([collection, 'sortDirection'], sortDirection)
    }

    default :
      return state
  }
}
