import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_FONTE_ENERGIA = 'LOADING_FONTE_ENERGIA'
export function loadingFonteEnergia () {
  return {
    type: LOADING_FONTE_ENERGIA,
  }
}

export const LOADING_FONTE_ENERGIA_SUCCESS = 'LOADING_FONTE_ENERGIA_SUCCESS'
export function loadingFonteEnergiaSuccess (fonteEnergia) {
  return {
    type: LOADING_FONTE_ENERGIA_SUCCESS,
    fonteEnergia,
  }
}

export const LOADING_FONTE_ENERGIA_FAILURE = 'LOADING_FONTE_ENERGIA_FAILURE'
export function loadingFonteEnergiaFailure (error) {
  return {
    type: LOADING_FONTE_ENERGIA_FAILURE,
    error,
  }
}

export const DESTROYING_FONTE_ENERGIA_SUCCESS = 'DESTROYING_FONTE_ENERGIA_SUCCESS'
export function destroyingFonteEnergiaSuccess (fonteEnergiaId) {
  return {
    type: DESTROYING_FONTE_ENERGIA_SUCCESS,
    fonteEnergiaId,
  }
}

const initialState = Map({})

export function fetchMultipleFonteEnergia () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFonteEnergia())
      const multipleFonteEnergia = await callAPI('/fontes_energia')
      dispatch(loadingFonteEnergiaSuccess(multipleFonteEnergia))
      return multipleFonteEnergia
    } catch (e) {
      dispatch(loadingFonteEnergiaFailure(e))
      return false
    }
  }
}

export function fetchFonteEnergia (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFonteEnergia())
      const fonteEnergia = await callAPI(`/fontes_energia/${id}`)
      dispatch(loadingFonteEnergiaSuccess(fonteEnergia))
      return fonteEnergia
    } catch (e) {
      dispatch(loadingFonteEnergiaFailure(e))
      return false
    }
  }
}

export function createFonteEnergia (fonteEnergia) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFonteEnergia())
      const newFonteEnergia = await callAPI('/fontes_energia', 'POST', fonteEnergia)
      dispatch(loadingFonteEnergiaSuccess(newFonteEnergia))
      return newFonteEnergia
    } catch (e) {
      dispatch(loadingFonteEnergiaFailure(e))
      return false
    }
  }
}

export function updateFonteEnergia (id, fonteEnergia) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFonteEnergia())
      const newFonteEnergia = await callAPI(`/fontes_energia/${id}`, 'PUT', fonteEnergia)
      dispatch(loadingFonteEnergiaSuccess(newFonteEnergia))
      return newFonteEnergia
    } catch (e) {
      dispatch(loadingFonteEnergiaFailure(e))
      return false
    }
  }
}

export function destroyFonteEnergia (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingFonteEnergia())
      await callAPI(`/fontes_energia/${id}`, 'DELETE')
      dispatch(destroyingFonteEnergiaSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingFonteEnergiaFailure(e))
      return false
    }
  }
}

export default function fonteEnergia (state = initialState, action) {
  switch (action.type) {

    case LOADING_FONTE_ENERGIA_SUCCESS : {
      const { fonteEnergia } = action
      if (fonteEnergia.constructor === Array) {
        state = initialState
        fonteEnergia.forEach((fonte) => (
          state = state.merge({[fonte.id]: fonte})
        ))
        return state
      }
      return state.merge({[fonteEnergia.id]: fonteEnergia})
    }

    case DESTROYING_FONTE_ENERGIA_SUCCESS :
      return state.delete(action.fonteEnergiaId.toString())

    default :
      return state
  }
}
