import React from 'react'
import ReactDOM from 'react-dom'

import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import * as reducers from './redux/modules'
import { reducer as formReducer } from 'redux-form/immutable'

import moment from 'moment'
import ptBr from 'moment/locale/pt-br'

import './semantic/dist/semantic.min.css'
import 'react-datepicker/dist/react-datepicker.css'

import App from './App'

// adding the pt-br language to use in moment
moment.updateLocale('pt-br', ptBr)

const store = createStore(
  combineReducers({...reducers, form: formReducer}),
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
