import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

import { DESTROYING_INSTALACAO_SUCCESS } from './instalacao'

export const LOADING_INSTALACAO_CLIENTE = 'LOADING_INSTALACAO_CLIENTE'
export function loadingInstalacaoCliente () {
  return {
    type: LOADING_INSTALACAO_CLIENTE,
  }
}

export const LOADING_INSTALACAO_CLIENTE_SUCCESS = 'LOADING_INSTALACAO_CLIENTE_SUCCESS'
export function loadingInstalacaoClienteSuccess (instalacaoCliente) {
  return {
    type: LOADING_INSTALACAO_CLIENTE_SUCCESS,
    instalacaoCliente,
  }
}

export const LOADING_INSTALACAO_CLIENTE_FAILURE = 'LOADING_INSTALACAO_CLIENTE_FAILURE'
export function loadingInstalacaoClienteFailure (error) {
  return {
    type: LOADING_INSTALACAO_CLIENTE_FAILURE,
    error,
  }
}

export const DESTROYING_INSTALACAO_CLIENTE_SUCCESS = 'DESTROYING_INSTALACAO_CLIENTE_SUCCESS'
export function destroyingInstalacaoClienteSuccess (instalacaoClienteId) {
  return {
    type: DESTROYING_INSTALACAO_CLIENTE_SUCCESS,
    instalacaoClienteId,
  }
}

const initialState = Map({})

export function fetchMultipleInstalacaoCliente (clienteId, userId, apenasAtivos, tipoMercado) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingInstalacaoCliente())
      const multipleInstalacaoCliente = await callAPI(`/clientes/${clienteId}/instalacoes?user_id=${userId}&ativo=${apenasAtivos}&tipo_mercado=${tipoMercado}`)
      dispatch(loadingInstalacaoClienteSuccess(multipleInstalacaoCliente))
      return multipleInstalacaoCliente
    } catch (e) {
      dispatch(loadingInstalacaoClienteFailure(e))
      return false
    }
  }
}

export function fetchInstalacaoCliente (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingInstalacaoCliente())
      const instalacaoCliente = await callAPI(`/instalacoes/${id}`)
      dispatch(loadingInstalacaoClienteSuccess(instalacaoCliente))
      return instalacaoCliente
    } catch (e) {
      dispatch(loadingInstalacaoClienteFailure(e))
      return false
    }
  }
}

export function createInstalacaoCliente (clienteId, instalacaoCliente) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingInstalacaoCliente())
      const newInstalacaoCliente = await callAPI(`/clientes/${clienteId}/instalacoes`, 'POST', instalacaoCliente)
      dispatch(loadingInstalacaoClienteSuccess(newInstalacaoCliente))
      return newInstalacaoCliente
    } catch (e) {
      dispatch(loadingInstalacaoClienteFailure(e))
      return false
    }
  }
}

export function updateInstalacaoCliente (id, instalacaoCliente) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingInstalacaoCliente())
      const newInstalacaoCliente = await callAPI(`/instalacoes/${id}`, 'PUT', instalacaoCliente)
      dispatch(loadingInstalacaoClienteSuccess(newInstalacaoCliente))
      return newInstalacaoCliente
    } catch (e) {
      dispatch(loadingInstalacaoClienteFailure(e))
      return false
    }
  }
}

export function destroyInstalacaoCliente (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingInstalacaoCliente())
      await callAPI(`/instalacoes/${id}`, 'DELETE')
      dispatch(destroyingInstalacaoClienteSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingInstalacaoClienteFailure(e))
      return false
    }
  }
}

export default function instalacaoCliente (state = initialState, action) {
  switch (action.type) {

    case LOADING_INSTALACAO_CLIENTE_SUCCESS : {
      const { instalacaoCliente } = action
      if (instalacaoCliente.constructor === Array) {
        state = initialState
        instalacaoCliente.forEach((c) => (
          state = state.merge({[c.id]: c})
        ))
        return state
      }
      return state.merge({[instalacaoCliente.id]: instalacaoCliente})
    }

    case DESTROYING_INSTALACAO_CLIENTE_SUCCESS :
      return state.delete(action.instalacaoClienteId.toString())

    case DESTROYING_INSTALACAO_SUCCESS :
      return state.delete(action.instalacaoId.toString())

    default :
      return state
  }
}
