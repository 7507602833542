import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { FuncaoShow } from '../../components'
import * as funcaoActionCreators from '../../redux/modules/funcao'

class FuncaoShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    fetchFuncao: PropTypes.func,
    history: PropTypes.object,
    destroyFuncao: PropTypes.func,
  }

  state = {
    funcao: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const funcao = await this.props.fetchFuncao(this.props.id)
      this.setState({ funcao })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/funcoes/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/funcoes/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyFuncao(id)
    this.props.history.push('/funcoes')
  }

  render () {
    return (
      <FuncaoShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        funcao={this.state.funcao} />
    )
  }
}

function mapStateToProps ({funcao}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      funcao: funcao.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...funcaoActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FuncaoShowContainer)
