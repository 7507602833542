import moment from 'moment'

// Formats data from the store before adding it to the input
export const formatCurrency = (currency) => {
  if (currency !== undefined && typeof currency === 'string' && currency.indexOf('R$') < 0) {
    const parts = currency.split('.')
    // decimal are one digit long. pad to 2
    if (parts.length > 1 && parts[parts.length - 1].length === 1) {
      parts[parts.length - 1] += '0'
    }
    return parts.join(',')
  } else if (currency !== undefined && typeof currency === 'number') {
    return '' + currency
  }
  return currency
}

export const formatDate = (date) => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
