import React from 'react'
import PropTypes from 'prop-types'

// Returns an svg text box with the formatted value inside.
// Text will be aligned on the bottom and center of the bar, but inside
const LabelListInsideBottomCenterLabel = props => {
  return (
    <text
      x={props.x - props.height + props.offset}
      y={props.y + props.width / 2}
      width={props.width}
      height={props.height}
      transform={`rotate(${props.angle}, ${props.x}, ${props.y})`}
      alignmentBaseline="middle"
      textAnchor="start"
      className={props.className}
    >
      {props.formatter(props.value)}
    </text>
  )
}

LabelListInsideBottomCenterLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  offset: PropTypes.number,
  fill: PropTypes.string,
  angle: PropTypes.number,
  formatter: PropTypes.func,
  value: PropTypes.number,
}

LabelListInsideBottomCenterLabel.defaultProps = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  offset: 0,
  fill: '#000',
  angle: 0,
  formatter: v => v,
  value: 0,
}

export default LabelListInsideBottomCenterLabel
