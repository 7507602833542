import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { CenarioShow } from '../../components'
import * as cenarioActionCreators from '../../redux/modules/cenario'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

class CenarioShowContainer extends Component {

  static propTypes = {
    instalacao: PropTypes.object.isRequired,

    id: PropTypes.string,
    history: PropTypes.object,
    fetchCenario: PropTypes.func,
    destroyCenario: PropTypes.func,
    isLoading: PropTypes.bool,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
  }

  state = {
    cenario: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const cenario = await this.props.fetchCenario(this.props.id)
      this.setState({ cenario })
    }
  }

  handleEditResource = (instalacaoId, id) => {
    this.props.history.push(`/instalacoes/${instalacaoId}/cenarios/${id}/edit`)
  }

  handleDeleteResource = async (instalacaoId, id) => {
    if (await this.props.destroyCenario(id)) {
      this.props.showSuccessfulFlashMessage('', 'destroy')
      this.props.history.push(`/instalacoes/${instalacaoId}/cenarios`)
    } else {
      this.props.showErrorFlashMessage('', 'destroy', this.props.error)
    }
  }

  render () {
    return (
      <CenarioShow
        isLoading={this.props.isLoading}
        instalacao={this.props.instalacao}
        onEditResource={() => this.handleEditResource(this.props.instalacao.id, this.state.cenario.id)}
        onDeleteResource={() => this.handleDeleteResource(this.props.instalacao.id, this.state.cenario.id)}
        cenario={this.state.cenario} />
    )
  }
}

function mapStateToProps ({cenario, status}, {match}) {
  const { id } = match.params
  const isLoading = status.getIn(['cenario', 'isLoading'])
  if (id) {
    return {
      id,
      cenario: cenario.get('id'),
      isLoading,
    }
  }
  return {
    isLoading,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...cenarioActionCreators,
    ...flashMessageActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CenarioShowContainer))
