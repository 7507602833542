import { Map } from 'immutable'
import { callAPI } from '../../utils/API'
import toQueryString from '../../utils/toQueryString'

export const LOADING_RESUMO = 'LOADING_RESUMO'
export function loadingResumo () {
  return {
    type: LOADING_RESUMO,
  }
}

export const LOADING_RESUMO_SUCCESS = 'LOADING_RESUMO_SUCCESS'
export function loadingResumoSuccess (resumo) {
  return {
    type: LOADING_RESUMO_SUCCESS,
    resumo,
  }
}

export const LOADING_RESUMO_FAILURE = 'LOADING_RESUMO_FAILURE'
export function loadingResumoFailure (error) {
  return {
    type: LOADING_RESUMO_FAILURE,
    error,
  }
}

const initialState = Map({})

export function fetchMultipleResumo () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingResumo())
      const multipleResumo = await callAPI('/painel_resumo/resumo')
      dispatch(loadingResumoSuccess(multipleResumo))
      return multipleResumo
    } catch (e) {
      dispatch(loadingResumoFailure(e))
      return false
    }
  }
}

export function fetchResumos (payload) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingResumo())
      const multipleResumo = await callAPI(`/painel_resumo/resumo${toQueryString(payload)}`)
      dispatch(loadingResumoSuccess(multipleResumo))
      return multipleResumo
    } catch (e) {
      console.log(e)
      dispatch(loadingResumoFailure(e))
      return false
    }
  }
}

export function fetchResumoCliente (payload) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingResumo())
      const multipleResumo = await callAPI(`/painel_resumo/resumo_cliente${toQueryString(payload)}`)
      dispatch(loadingResumoSuccess(multipleResumo))
      return multipleResumo
    } catch (e) {
      console.log(e)
      dispatch(loadingResumoFailure(e))
      return false
    }
  }
}

export function fetchResumoInstalacao (payload) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingResumo())
      const multipleResumo = await callAPI(`/painel_resumo/resumo_instalacao${toQueryString(payload)}`)
      dispatch(loadingResumoSuccess(multipleResumo))
      return multipleResumo
    } catch (e) {
      console.log(e)
      dispatch(loadingResumoFailure(e))
      return false
    }
  }
}

export default function resumo (state = initialState, action) {
  switch (action.type) {

    case LOADING_RESUMO_SUCCESS : {
      const { resumo } = action
      if (resumo.constructor === Array) {
        state = initialState
        resumo.forEach((singleResumo) => (
          state = state.merge({[singleResumo.id]: singleResumo})
        ))
        return state
      }
      return state.merge({[resumo.id]: resumo})
    }

    default :
      return state
  }
}
