import { Map } from 'immutable'

export const SHOW_SUCCESSFUL_FLASH_MESSAGE = 'SHOW_SUCCESSFUL_FLASH_MESSAGE'
export function showSuccessfulFlashMessage (msg, operation) {
  return {
    type: SHOW_SUCCESSFUL_FLASH_MESSAGE,
    msg,
    operation,
  }
}

export const SHOW_ERROR_FLASH_MESSAGE = 'SHOW_ERROR_FLASH_MESSAGE'
export function showErrorFlashMessage (msg, operation, errorList) {
  return {
    type: SHOW_ERROR_FLASH_MESSAGE,
    msg,
    operation,
    errorList,
  }
}

export const HIDE_FLASH_MESSAGE = 'HIDE_FLASH_MESSAGE'
export function hideFlashMessage () {
  return {
    type: HIDE_FLASH_MESSAGE,
  }
}

const initialState = Map({
  msg: '',
  operation: '',
  isVisible: false,
  isError: false,
  errorList: null,
})

export default function flashMessage (state = initialState, action) {
  switch (action.type) {

    case SHOW_SUCCESSFUL_FLASH_MESSAGE :
      return state.merge({
        msg: action.msg,
        operation: action.operation,
        isVisible: true,
        isError: false,
        errorList: null,
      })

    case SHOW_ERROR_FLASH_MESSAGE :
      return state.merge({
        operation: action.operation,
        msg: action.msg,
        isVisible: true,
        isError: true,
        errorList: action.errorList,
      })

    case HIDE_FLASH_MESSAGE :
      return initialState

    default :
      return state
  }
}
