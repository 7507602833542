import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, Header, FormField, Icon, Label } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { DatePickerInput } from '../FormComponents'
import {
  SemanticInput,
  SemanticDropdown,
  SemanticCheckbox,
  SemanticInputMask
} from '../FormComponents'
import { required, number, dateValidator } from '../../utils/validations'
import { maskFloat } from '../../utils/masks'

import { mapToDropdown } from '../../utils/parse'

const ParametroConcessionariaForm = props => {
  const baseCalculoDropdown = mapToDropdown(
    props.multipleBaseCalculo,
    e => e.get('id'),
    e => e.get('nome')
  )
  const calculoTributoDropdown = mapToDropdown(
    props.multipleCalculoTributo,
    e => e.get('id'),
    e => e.get('nome')
  )
  const calculoBandeiraDropdown = mapToDropdown(
    props.multipleCalculoBandeira,
    e => e.get('id'),
    e => e.get('nome')
  )
  const calculoCreditoSubvencaoDropdown = mapToDropdown(
    props.multipleCalculoCreditoSubvencao,
    e => e.get('id'),
    e => e.get('nome')
  )

  return (
    <CRUDForm
      title='Parâmetros de Cálculo'
      titleAs='h3'
      icon='cogs'
      padded={false}
      {...props}>
      <FormSection name='parametro_concessionaria'>
        <Form.Group>
          <FormField width={16}>
            <Header as='h3' block style={{ marginTop: '1em' }}>
              <label>Início da Vigência</label>
              <Field
                autoFocus
                name='starts_at'
                placeholder='Início da Vigência'
                validate={[required, dateValidator]}
                component={DatePickerInput}
                loading={props.loading}
              />
            </Header>
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <label htmlFor='decimal_places'>Casas decimais</label>
            <Field
              name='decimal_places'
              placeholder='0'
              validate={[required, number]}
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <label htmlFor='base_calculo_icms'>Base de Cálculo ICMS</label>
            <Field
              fluid
              button
              floating
              labeled
              search
              icon='sort'
              className='icon'
              name='base_calculo_icms'
              placeholder='Selecione'
              validate={[required]}
              options={baseCalculoDropdown}
              component={SemanticDropdown}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <label htmlFor='base_calculo_pis_cofins'>
              Base de Cálculo PIS/COFINS
            </label>
            <Field
              fluid
              button
              floating
              labeled
              search
              icon='sort'
              className='icon'
              name='base_calculo_pis_cofins'
              placeholder='Selecione'
              validate={[required]}
              options={baseCalculoDropdown}
              component={SemanticDropdown}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <label htmlFor='aplicacao_tributos'>Tributos</label>
            <Field
              fluid
              button
              floating
              labeled
              search
              icon='sort'
              className='icon'
              name='aplicacao_tributos'
              placeholder='Selecione'
              validate={[required]}
              options={calculoTributoDropdown}
              component={SemanticDropdown}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <label htmlFor='calculo_bandeira'>Bandeiras</label>
            <Field
              fluid
              button
              floating
              labeled
              search
              icon='sort'
              className='icon'
              name='calculo_bandeira'
              placeholder='Selecione'
              validate={[required]}
              options={calculoBandeiraDropdown}
              component={SemanticDropdown}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <label htmlFor='credito_subvencao'>Crédito da Subvenção TUSD</label>
            <Field
              fluid
              button
              floating
              labeled
              search
              icon='sort'
              className='icon'
              name='credito_subvencao'
              placeholder='Selecione'
              validate={[required]}
              options={calculoCreditoSubvencaoDropdown}
              component={SemanticDropdown}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <label htmlFor='valor_conta_covid'>Valor Conta Covid</label>
            <Field
              fluid
              name='valor_conta_covid'
              placeholder={maskFloat.placeholder}
              mask={maskFloat.mask(2, true)}
              component={SemanticInput}
              loading={props.loading}
              disabled={true}
              label='R$'
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <Field
              name='calcular_subvencao'
              id='calcular_subvencao'
              label='Calcular Subvenção Tributária'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <Field
              name='subvencao_base_icms'
              id='subvencao_base_icms'
              label='Na subvenção, calcular base do ICMS separada da base PIS/COFINS'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <Field
              name='isenta_icms_demanda_sobra'
              id='isenta_icms_demanda_sobra'
              label='Isenção de ICMS para demanda contratada não utilizada'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <Field
              name='usar_bandeira_na_te_acl'
              id='usar_bandeira_na_te_acl'
              label='Considerar bandeira no cálculo da tarifa de energia no ACL'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <Field
              name='calcular_icms_sobre_desconto'
              id='calcular_icms_sobre_desconto'
              label='Calcular ICMS sobre desconto no ACL (apenas se UF aderiu ao convênio 77)'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <Field
              name='separar_icms_calculo'
              id='separar_icms_calculo'
              label='Separar ICMS do cálculo de PIS/COFINS'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <Field
              name='excluir_icms_tusd'
              id='excluir_icms_tusd'
              label='Excluir ICMS sobre a TUSD/TUST'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <Field
              name='devec_fora_fatura'
              id='devec_fora_fatura'
              label='Aplicar cobrança de DEVEC fora da fatura de pedágio'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <Field
              name='excluir_pis_cofins_covid'
              id='excluir_pis_cofins_covid'
              label='Excluir PIS e COFINS da cobrança da conta covid'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <Field
              name='excluir_icms_covid'
              id='excluir_icms_covid'
              label='Excluir ICMS da cobrança da conta covid'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <Field
              name='excluir_icms_escassez_hidrica'
              id='excluir_icms_escassez_hidrica'
              label='Excluir ICMS para escassez hídrica'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <Label>
            <Icon name='exclamation circle' /> PARÂMETROS EXCLUSIVOS PARA CLASSE RURAL
          </Label>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <Field
              name='aplicar_icms_todos_pontos'
              id='aplicar_icms_todos_pontos'
              label='Aplicar ICMS em todos os pontos'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <Field
              name='cobrar_ultrapassagem_demanda'
              id='cobrar_ultrapassagem_demanda'
              label='Cobrar ultrapassagem de demanda'
              component={SemanticCheckbox}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
        <Form.Group>
          <FormField width={16}>
            <label htmlFor='decimal_places'>Desconto da Tarifa do Horário Reservado</label>
             <Field
              name='desconto_horario_reservado'
              placeholder='0,000000'
              mask={maskFloat.mask(6)}
              validate={[required]}
              component={SemanticInputMask}
              label='%'
              style={{ width: '30%'}}
            />
          </FormField>
        </Form.Group>
      </FormSection>
    </CRUDForm>
  )
}

ParametroConcessionariaForm.propTypes = {
  loading: PropTypes.bool,
  multipleBaseCalculo: PropTypes.object,
  multipleCalculoTributo: PropTypes.object,
  multipleCalculoBandeira: PropTypes.object,
  multipleCalculoCreditoSubvencao: PropTypes.object,
}

export default reduxForm({ form: 'ParametroConcessionariaForm' })(
  ParametroConcessionariaForm
)
