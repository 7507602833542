import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Divider, Table } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'

const rendererPropTypes = {
  children: PropTypes.node,
}

const HeadingRenderer = props => <Divider horizontal>{props.children}</Divider>
HeadingRenderer.propTypes = rendererPropTypes

const ParagraphRenderer = props => <p style={{textAlign: 'justify'}}>{props.children}</p>
ParagraphRenderer.propTypes = rendererPropTypes

const TableRenderer = props => <Table celled>{props.children}</Table>
TableRenderer.propTypes = rendererPropTypes

const TableHeadRenderer = props => <Table.Header>{props.children}</Table.Header>
TableHeadRenderer.propTypes = rendererPropTypes

const TableBodyRenderer = props => <Table.Body>{props.children}</Table.Body>
TableBodyRenderer.propTypes = rendererPropTypes

const TableRowRenderer = props => <Table.Row>{props.children}</Table.Row>
TableRowRenderer.propTypes = rendererPropTypes

const TableCellRenderer = props => props.isHeader
  ? <Table.HeaderCell>{props.children}</Table.HeaderCell>
  : <Table.Cell>{props.children}</Table.Cell>

TableCellRenderer.propTypes = {
  isHeader: PropTypes.bool,
  ...{rendererPropTypes}
}

const MarkdownRenderer = ({ source, className }) => {

  return (
    <ReactMarkdown
      className={classNames('markdown-container', 'base-color-light', className)}
      source={source}
      renderers={{
        heading: HeadingRenderer,
        paragraph: ParagraphRenderer,
        table: TableRenderer,
        tableHead: TableHeadRenderer,
        tableBody: TableBodyRenderer,
        tableRow: TableRowRenderer,
        tableCell: TableCellRenderer,
      }} />
  )
}

MarkdownRenderer.propTypes = {
  source: PropTypes.string,
  className: PropTypes.string,
}

MarkdownRenderer.defaultProps = {
  source: '',
}

export default MarkdownRenderer
