import React, { Component } from 'react'
import { Error } from '../../components'

class ErrorContainer extends Component {
  render () {
    return (
      <Error type={this.props.type} />
    )
  }
}

export default ErrorContainer
