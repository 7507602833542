import React from 'react'
import PropTypes from 'prop-types'
import { Label, List, Table, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { CRUDIndex } from '../CRUD'
import { EditDeleteButtonContainer } from '../../containers'

const Contato = (props) => (
  <CRUDIndex
    title='Contatos'
    icon='users'
    onRedirectToNew={props.onRedirectToNew}
    onExportToCSV={props.onExportToCSV}
    filterText={props.filterText}
    handleFilter={props.handleFilter}>
    <Table sortable celled striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1} textAlign='center'
            onClick={props.handleSort('id')}
            sorted={props.handleSorted('id')}>#</Table.HeaderCell>
          <Table.HeaderCell width={3}
            onClick={props.handleSort('nome')}
            sorted={props.handleSorted('nome')}>Nome</Table.HeaderCell>
          <Table.HeaderCell width={2}
            onClick={props.handleSort('funcao.nome')}
            sorted={props.handleSorted('funcao.nome')}>Função</Table.HeaderCell>
          <Table.HeaderCell width={3}>Contato</Table.HeaderCell>
          <Table.HeaderCell
            onClick={props.handleSort('observacoes')}
            sorted={props.handleSorted('observacoes')}>Observações</Table.HeaderCell>
          <Table.HeaderCell width={1}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.isLoading && (
          <Table.Row>
            <Table.Cell colSpan='6'>
              <Loader active={props.isLoading} inline='centered' />
            </Table.Cell>
          </Table.Row>
        )}
        {props.multipleContato.valueSeq().map((contato, index) => (
          <Table.Row key={index}>
            <Table.Cell textAlign='center'>
              <Label as={Link} to={`/contatos/${contato.get('id')}`}>{contato.get('id')}</Label>
            </Table.Cell>
            <Table.Cell>{contato.get('nome')}</Table.Cell>
            <Table.Cell>
              <Label as={Link} to={`/funcoes/${contato.getIn(['funcao', 'id'])}`}>
                {contato.getIn(['funcao', 'nome'])}
              </Label>
            </Table.Cell>
            <Table.Cell>
              <List>
                {contato.get('email') && (
                  <List.Item>
                    <List.Icon name='mail' />
                    <List.Content>
                      <a href={`mailto:${contato.get('email')}`}>{contato.get('email')}</a>
                    </List.Content>
                  </List.Item>
                )}
                {contato.get('celular') && (
                  <List.Item>
                    <List.Icon name='mobile' />
                    <List.Content>{contato.get('celular')}</List.Content>
                  </List.Item>
                )}
                {contato.get('telefone') && (
                  <List.Item>
                    <List.Icon name='phone' />
                    <List.Content>
                      {contato.get('telefone')}
                    </List.Content>
                  </List.Item>
                )}
              </List>
            </Table.Cell>
            <Table.Cell>{contato.get('observacoes')}</Table.Cell>
            <Table.Cell>
              <EditDeleteButtonContainer
                onEditResource={() => props.onEditResource(contato.get('id'))}
                onDeleteResource={() => props.onDeleteResource(contato.get('id'))} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>

    </Table>
  </CRUDIndex>
)

Contato.propTypes = {
  onRedirectToNew: PropTypes.func,
  multipleContato: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default Contato
