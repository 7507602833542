import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ParametroConcessionaria } from '../../components'
import * as parametroConcessionariaActionCreators from '../../redux/modules/parametroConcessionaria'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'

class ParametroConcessionariaContainer extends Component {
  static propTypes = {
    concessionaria: PropTypes.object.isRequired,

    destroyParametroConcessionaria: PropTypes.func,
    copyParametroConcessionaria: PropTypes.func,
    fetchMultipleParametroConcessionaria: PropTypes.func,
    multipleParametroConcessionaria: PropTypes.object,

    error: PropTypes.array,
    history: PropTypes.object,
    isLoading: PropTypes.bool,

    hideFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    showSuccessfulFlashMessage: PropTypes.func,

    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateSort: PropTypes.func.isRequired,

    filterText: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
  }

  async componentDidMount() {
    const { concessionaria } = this.props
    await Promise.all([
      this.props.fetchMultipleParametroConcessionaria(concessionaria.id),
    ])
  }

  handleRedirectToNew = concessionariaId => {
    this.props.history.push(
      `/concessionarias/${concessionariaId}/parametros/new`
    )
  }

  handleEditResource = (concessionariaId, id) => {
    this.props.history.push(
      `/concessionarias/${concessionariaId}/parametros/${id}/edit`
    )
  }

  handleDeleteResource = async id => {
    ;(await this.props.destroyParametroConcessionaria(id))
      ? this.props.showSuccessfulFlashMessage('', 'destroy')
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  handleCopyResource = async id => {
    const concessionariaId = this.props.concessionaria.id
    const novoParametro = await this.props.copyParametroConcessionaria(id)
    if (novoParametro) {
      this.props.showSuccessfulFlashMessage('', 'create')
      this.props.history.push(
        `/concessionarias/${concessionariaId}/parametros/${novoParametro.id}/edit`
      )
    } else {
      this.props.showErrorFlashMessage('', 'copy', this.props.error)
    }
  }

  handleSort = column => () =>
    this.props.updateSort('parametroConcessionaria', column)

  handleSorted = column =>
    this.props.sortColumn === column ? this.props.sortDirection : null

  handleFilter = (name, value) =>
    this.props.updateFilter('parametroConcessionaria', name, value)

  componentWillUnmount() {
    this.props.hideFlashMessage()
  }

  render() {
    return (
      <ParametroConcessionaria
        concessionaria={this.props.concessionaria}
        onRedirectToNew={() =>
          this.handleRedirectToNew(this.props.concessionaria.id)
        }
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        onCopyResource={this.handleCopyResource}
        multipleParametroConcessionaria={
          this.props.multipleParametroConcessionaria
        }
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        isLoading={this.props.isLoading}
      />
    )
  }
}

function mapStateToProps(
  { parametroConcessionaria, status, sort, filter },
  { concessionaria }
) {
  const sortColumn =
    sort.getIn(['parametroConcessionaria', 'sortColumn']) || 'starts_at'
  const sortDirection =
    sort.getIn(['parametroConcessionaria', 'sortDirection']) || 'ascending'
  const filterText =
    filter.getIn(['parametroConcessionaria', 'filterText']) || ''

  let map = filterMap(parametroConcessionaria, ['starts_at'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleParametroConcessionaria: map.filter(
      t => t.get('concessionaria_id') === concessionaria.id
    ),
    error: status.getIn(['parametroConcessionaria', 'error']),
    isLoading: status.getIn(['parametroConcessionaria', 'isLoading']),
    sortColumn,
    sortDirection,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...flashMessageAcionCreators,
      ...parametroConcessionariaActionCreators,
      ...sortActionCreators,
      ...filterActionCreators,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ParametroConcessionariaContainer))
