import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { VigenciaValorForm } from '../../components'
import * as vigenciaValorActionCreators from '../../redux/modules/vigenciaValor'
import * as bandeiraActionCreators from '../../redux/modules/bandeira'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { maskFloat } from '../../utils/masks'
import { mapToDropdown } from '../../utils/parse'
import moment from 'moment'
import sortBy from 'sort-by'

class VigenciaValorFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    fetchVigenciaValor: PropTypes.func.isRequired,
    createVigenciaValor: PropTypes.func.isRequired,
    updateVigenciaValor: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
    bandeiraDropdown: PropTypes.array,
    fetchMultipleBandeira: PropTypes.func,
    multipleBandeira: PropTypes.object,
  }

  async componentDidMount () {
    await this.props.fetchMultipleBandeira()

    let vigencia_valor = {
      starts_at: moment().startOf('month').format('DD/MM/YYYY'),
      valores_bandeiras: [],
    }

    if (this.props.id) {
      vigencia_valor = await this.props.fetchVigenciaValor(this.props.id)
      vigencia_valor.starts_at = moment(vigencia_valor.starts_at, 'YYYY-MM-DD').format('DD/MM/YYYY')

      vigencia_valor.valores_bandeiras = vigencia_valor.valores_bandeiras.map(v => {
        v.valor = maskFloat.parse(v.valor, 5)
        return v
      })
    }

    let bandeiras = this.props.multipleBandeira.toList().map(p => parseInt(p.get('id'), 10)).toJSON()
    bandeiras.forEach(bandeira => {
      let found = vigencia_valor.valores_bandeiras.find(v => v.bandeira_id === bandeira)
      if (!found) {
        vigencia_valor.valores_bandeiras.push({
          vigencia_valor_id: this.props.id,
          bandeira_id: bandeira,
          valor: '0,00000'
        })
      }
    })

    vigencia_valor['valores_bandeiras_attributes'] = vigencia_valor['valores_bandeiras'].sort(sortBy('bandeira_id'))
    delete vigencia_valor['valores_bandeiras']

    this.props.initialize('VigenciaValorForm', { vigencia_valor })
  }

  handleSubmitResource = async (vigenciaValor) => {
    let valores = vigenciaValor
      .getIn(['vigencia_valor', 'valores_bandeiras_attributes'])
      .map(v => v.set('valor', maskFloat.unmask(v.get('valor'), 5)))

    vigenciaValor = vigenciaValor.setIn(['vigencia_valor', 'valores_bandeiras_attributes'], valores)

    if (this.props.id) {
      if (await this.props.updateVigenciaValor(this.props.id, vigenciaValor)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push('/gestao_bandeiras/valores')
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createVigenciaValor(vigenciaValor)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/gestao_bandeiras/valores')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <VigenciaValorForm
        onSubmit={(vigenciaValor) => this.handleSubmitResource(vigenciaValor)}
        bandeiraDropdown={this.props.bandeiraDropdown}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({vigenciaValor, bandeira, status, flashMessage}, {match}) {
  const { id } = match.params
  const bandeiraDropdown = mapToDropdown(
    bandeira,
    e => e.get('id'),
    e => e.get('nome')
  )

  const isLoading = status.getIn(['vigenciaValor', 'isLoading']) || status.getIn(['bandeira', 'isLoading'])

  if (id) {
    return {
      id,
      vigenciaValor: vigenciaValor.get('id'),
      error: status.getIn(['vigenciaValor', 'error']),
      isLoading,
      shouldHideFlashMessage: flashMessage.get('isError'),
      multipleBandeira: bandeira,
      bandeiraDropdown
    }
  }
  return {
    error: status.getIn(['vigenciaValor', 'error']),
    isLoading,
    shouldHideFlashMessage: flashMessage.get('isError'),
    multipleBandeira: bandeira,
    bandeiraDropdown
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...vigenciaValorActionCreators,
    ...bandeiraActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(VigenciaValorFormContainer)
