import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { PldSemanalForm } from '../../components'
import * as pldSemanalActionCreators from '../../redux/modules/pldSemanal'
import * as pldSemanalSelectionActionCreators from '../../redux/modules/pldSemanalSelection'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { maskCurrency } from '../../utils/masks'

class PldSemanalFormContainer extends Component {

  static propTypes = {
    history: PropTypes.object,
    fetchMultiplePldSemanal: PropTypes.func,
    updatePldSemanal: PropTypes.func,
    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
    initialize: PropTypes.func,
    updatePldSemanalSelection: PropTypes.func,

    year: PropTypes.number,
    month: PropTypes.number,
    week: PropTypes.number,
    years: PropTypes.array,
    months: PropTypes.array,
    weeks: PropTypes.array,
  }

  async componentDidMount () {
    this.fetchData()
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  componentDidUpdate (prevProps) {
    const { year, month, week } = this.props
    if (prevProps.year !== year || prevProps.month !== month || prevProps.week !== week) {
      this.fetchData()
    }
  }

  handleChange = (event) => {
    this.props.updatePldSemanalSelection(
      event.target.name,
      event.target.value,
    )
  }

  handleSubmitResource = async (pldSemanal) => {
    let batch = []

    pldSemanal.first().valueSeq().forEach(pld => {
      let p = {}
      p.pld_semanal = {
        ano: pld.get('ano'),
        mes: pld.get('mes'),
        semana: pld.get('semana'),
        submercado_id: pld.get('submercado_id'),
        patamar_pesado: maskCurrency.unmask(pld.get('patamar_pesado')),
        patamar_medio: maskCurrency.unmask(pld.get('patamar_medio')),
        patamar_leve: maskCurrency.unmask(pld.get('patamar_leve')),
      }
      batch.push(p)
    })

    if (await this.props.updatePldSemanal({ batch })) {
      this.props.showSuccessfulFlashMessage('', 'update')
      this.props.history.push('/indicativos/semanais')
    } else {
      this.props.showErrorFlashMessage('', 'update', this.props.error)
    }
  }

  fetchData = async () => {
    let pldSemanal = await this.props.fetchMultiplePldSemanal(
      this.props.year,
      this.props.month,
      this.props.week
    )

    pldSemanal = pldSemanal.map(pld => {
      pld.patamar_pesado = 'R$ ' + maskCurrency.parse(pld.patamar_pesado)
      pld.patamar_medio = 'R$ ' + maskCurrency.parse(pld.patamar_medio)
      pld.patamar_leve = 'R$ ' + maskCurrency.parse(pld.patamar_leve)
      return pld
    })

    this.props.initialize('PldSemanalForm', { pldSemanal })
  }

  render () {
    return (
      <PldSemanalForm
        year={this.props.year}
        month={this.props.month}
        week={this.props.week}
        years={this.props.years}
        months={this.props.months}
        weeks={this.props.weeks}
        history={this.props.history}
        isLoading={this.props.isLoading}
        handleChange={this.handleChange}
        onSubmit={(pldSemanal) => this.handleSubmitResource(pldSemanal)}
      />
    )
  }
}

function mapStateToProps ({ pldSemanalSelection, status, flashMessage }) {
  return {
    error: status.getIn(['pldSemanal', 'error']),
    isLoading: status.getIn(['pldSemanal', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),

    year: pldSemanalSelection.get('year'),
    month: pldSemanalSelection.get('month'),
    week: pldSemanalSelection.get('week'),
    years: pldSemanalSelection.get('years'),
    months: pldSemanalSelection.get('months'),
    weeks: pldSemanalSelection.get('weeks'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...pldSemanalActionCreators,
    ...pldSemanalSelectionActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PldSemanalFormContainer)
