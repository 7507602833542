import React from 'react'
import PropTypes from 'prop-types'
import { CRUDShow } from '../CRUD'

const EnquadramentoShow = (props) => {
  if (props.enquadramento) {
    return (
      <CRUDShow
        title={props.enquadramento.modalidade}
        icon='block layout'
        onEditResource={() => props.onEditResource(props.enquadramento.id)}
        onDeleteResource={() => props.onDeleteResource(props.enquadramento.id)}>
        <ul>
          <li>Modalidade: {props.enquadramento.modalidade}</li>
        </ul>
      </CRUDShow>
    )
  }

  return (null)
}

EnquadramentoShow.propTypes = {
  enquadramento: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
}

export default EnquadramentoShow
