import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Button, Icon, Menu } from 'semantic-ui-react'
import { EditDeleteButtonContainer } from '../../containers'

const ShowToolbar = (props) => (
  <Menu secondary>
    <Menu.Menu position='right'>
      <Menu.Item fitted>
        <Button icon labelPosition='left'
          onClick={(e) => {
            e.preventDefault()
            props.history.goBack()
          }}>
          <Icon name='arrow left' />{' '}Voltar
        </Button>
      </Menu.Item>
      {props.customToolbarItems && props.customToolbarItems.map((item, index) => (
        <Menu.Item key={index} fitted>
          {item()}
        </Menu.Item>
      ))}
      {(props.onEditResource || props.onDeleteResource || props.onPrintResource) && (
        <Menu.Item fitted>
          <EditDeleteButtonContainer
            onEditResource={props.onEditResource}
            onDeleteResource={props.onDeleteResource}
            onPrintResource={props.onPrintResource}
            customButtonGroupItems={props.customButtonGroupItems}
          />
        </Menu.Item>
      )}
      {props.onExportToCSV && (
        <Menu.Item fitted>
          <Button onClick={() => props.onExportToCSV()} animated='vertical'>
            <Button.Content hidden>CSV</Button.Content>
            <Button.Content visible>
              <Icon name='download' />
            </Button.Content>
          </Button>
        </Menu.Item>
      )}
    </Menu.Menu>
  </Menu>
)

ShowToolbar.propTypes = {
  history: PropTypes.object.isRequired,
  onExportToCSV: PropTypes.func,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  onPrintResource: PropTypes.func,
  customToolbarItems: PropTypes.array,
  customButtonGroupItems: PropTypes.array,
}

export default withRouter(ShowToolbar)
