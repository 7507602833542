import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput, SemanticDropdown } from '../FormComponents'

import { required, alpha, maxLengthEstado, maxLengthNome } from '../../utils/validations'

const UfForm = (props) => (
  <CRUDForm
    title='Unidade Federativa'
    icon='marker'
    {...props}>
    <FormSection name='uf'>
      <Form.Group>
        <FormField width={6}>
          <label>Nome</label>
          <Field
            autoFocus
            name='nome'
            placeholder='Nome'
            validate={[required, maxLengthNome]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>

        <FormField width={2}>
          <label>Sigla</label>
          <Field
            name='sigla'
            placeholder='Sigla'
            validate={[required, alpha, maxLengthEstado]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>

      <Form.Group>
        <FormField width={6}>
          <label>Submercado</label>
          <Field fluid button floating labeled search icon='plug' className='icon'
            name='submercado_id'
            validate={[]}
            options={props.submercadoDropdown}
            component={SemanticDropdown}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>
    </FormSection>
  </CRUDForm>
)

UfForm.propTypes = {
  submercadoDropdown: PropTypes.array,
  loading: PropTypes.bool
}

export default reduxForm({form: 'UfForm'})(UfForm)
