import React from 'react'

import { Menu } from 'semantic-ui-react'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import UserMenuContainer from '../../containers/UserMenu/UserMenuContainer'

const TopNavbar = () => {
  return (
    <Menu
      borderless
      secondary
      stackable
      style={{margin: '10px', borderRadius: '3px', background: 'rgb(230, 230, 230)'}}
    >
      <Menu.Item>
        <Breadcrumbs />
      </Menu.Item>

      <UserMenuContainer />
    </Menu>
  )
}

export default TopNavbar
