import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form/immutable'

import { ClienteForm } from '../../components'
import * as clienteActionCreators from '../../redux/modules/cliente'
import * as ufActionCreators from '../../redux/modules/uf'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { mapToDropdown } from '../../utils/parse'

const selector = formValueSelector('ClienteForm')

class ClienteFormContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    fetchCliente: PropTypes.func.isRequired,
    createCliente: PropTypes.func.isRequired,
    updateCliente: PropTypes.func.isRequired,
    multipleUf: PropTypes.object,
    fetchMultipleUf: PropTypes.func.isRequired,
    pessoa: PropTypes.number,
    pessoaDropdown: PropTypes.array.isRequired,
    ufDropdown: PropTypes.array.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleUf()
    if (this.props.id) {
      const cliente = await this.props.fetchCliente(this.props.id)
      this.props.initialize('ClienteForm', {cliente})
    } else {
      this.props.initialize('ClienteForm', { cliente: { pessoa: 0 }})
    }
  }

  handleSubmitResource = async (cliente) => {
    if (this.props.id) {
      if (await this.props.updateCliente(this.props.id, cliente)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/clientes/${this.props.id}`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createCliente(cliente)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/clientes')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <ClienteForm
        pessoa={this.props.pessoa}
        pessoaDropdown={this.props.pessoaDropdown}
        ufDropdown={this.props.ufDropdown}
        multipleUf={this.props.multipleUf}
        onSubmit={(cliente) => this.handleSubmitResource(cliente)}
        loading={this.props.isLoading} />
    )
  }
}

function mapStateToProps (state, {match}) {
  const {cliente, uf, status, flashMessage} = state
  const isLoading = status.getIn(['cliente', 'isLoading']) || status.getIn(['uf', 'isLoading'])
  const { id } = match.params
  const ufDropdown = mapToDropdown(
    uf,
    e => e.get('id'),
    e => `${e.get('sigla')} - ${e.get('nome')}`
  )

  const pessoa = selector(state, 'cliente.pessoa')

  const pessoaDropdown = [
    {
      key: 'juridica',
      value: 0,
      text: 'Pessoa Jurídica',
    },
    {
      key: 'fisica',
      value: 1,
      text: 'Pessoa Física',
    },
  ]

  if (id) {
    return {
      id,
      cliente: cliente.get('id'),
      pessoa,
      ufDropdown,
      pessoaDropdown,
      error: status.getIn(['cliente', 'error']),
      shouldHideFlashMessage: flashMessage.get('isError'),
      isLoading,
    }
  }
  return {
    pessoa,
    ufDropdown,
    pessoaDropdown,
    error: status.getIn(['cliente', 'error']),
    shouldHideFlashMessage: flashMessage.get('isError'),
    isLoading,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...clienteActionCreators,
    ...ufActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ClienteFormContainer)
