import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, formValueSelector } from 'redux-form/immutable'

import { Table } from 'semantic-ui-react'
import { SemanticInputMask } from '../FormComponents'

import { required } from '../../utils/validations'
import { maskCurrency } from '../../utils/masks'

const selector = formValueSelector('PldSemanalForm')

let RenderPld = ({ pld, loading, submercado_descricao }) =>
  <Table.Row>
    <Table.Cell textAlign='right'>
      {submercado_descricao}
    </Table.Cell>

    <Table.Cell>
      <Field fluid
        name={`${pld}.patamar_pesado`}
        placeholder={maskCurrency.placeholder}
        mask={maskCurrency.mask()}
        validate={[required]}
        component={SemanticInputMask}
        loading={loading}
        label='R$/MWh'
      />
    </Table.Cell>

    <Table.Cell>
      <Field fluid
        name={`${pld}.patamar_medio`}
        placeholder={maskCurrency.placeholder}
        mask={maskCurrency.mask()}
        validate={[required]}
        component={SemanticInputMask}
        loading={loading}
        label='R$/MWh'
      />
    </Table.Cell>

    <Table.Cell>
      <Field fluid
        name={`${pld}.patamar_leve`}
        placeholder={maskCurrency.placeholder}
        mask={maskCurrency.mask()}
        validate={[required]}
        component={SemanticInputMask}
        loading={loading}
        label='R$/MWh'
      />
    </Table.Cell>
  </Table.Row>

RenderPld = connect(
  (state, props) => {
    return ({
      submercado_descricao: selector(state, `${props.pld}.submercado`).get('descricao')
    })
  }
)(RenderPld)

RenderPld.propTypes = {
  pld: PropTypes.string,
  loading: PropTypes.bool,
  submercado_descricao: PropTypes.string,
}

export default RenderPld
