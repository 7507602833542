import React from 'react'
import PropTypes from 'prop-types'
import { Card, List, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { CRUDIndex } from '../CRUD'

const Comercializadora = (props) => (
  <CRUDIndex
    title='Comercializadoras'
    icon='shopping cart'
    onRedirectToNew={props.onRedirectToNew}
    onExportToCSV={props.onExportToCSV}
    filterText={props.filterText}
    handleFilter={props.handleFilter}
  >
    <Loader inline='centered' active={props.isLoading} />

    <Card.Group style={{ marginTop: '0.5em' }}>
      {props.multipleComercializadora.valueSeq().map((comercializadora, index) => (
        <Card
          key={index}
          as={Link}
          to={`/comercializadoras/${comercializadora.get('id')}`}
          label={{ as: 'a', color: 'blue', content: 'Food', icon: 'spoon', ribbon: true }}
        >
          <Card.Content>
            <Card.Header>
              {comercializadora.get('sigla')}
            </Card.Header>
            <Card.Meta>
              {comercializadora.get('nome')}
            </Card.Meta>
            <Card.Description>
              <List>
                <List.Item>
                  <List.Icon name='id card'/>
                  <List.Content>
                    <List.Header>CNPJ</List.Header>
                    <List.Description>{comercializadora.get('cnpj')}</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <List>
              <List.Item icon='marker' content={comercializadora.getIn(['uf', 'nome'])} />
            </List>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  </CRUDIndex>
)

Comercializadora.propTypes = {
  onRedirectToNew: PropTypes.func.isRequired,
  multipleComercializadora: PropTypes.object.isRequired,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default Comercializadora
