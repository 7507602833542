import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { ContratoDemandaInstalacao } from '../../../containers'

const ContratoDemanda = (props) => {
  const { instalacao } = props
  return (
    <div>
      <Switch>
        <Route
          exact path='/instalacoes/:instalacaoId/contratos_demanda'
          render={({match}) => (
            <ContratoDemandaInstalacao.Index
              instalacao={instalacao}
              match={match}
            />
          )}>
        </Route>
        <Route
          exact path='/instalacoes/:instalacaoId/contratos_demanda/edit'
          render={({match}) => (
            <ContratoDemandaInstalacao.Form
              instalacao={instalacao}
              match={match}
            />
          )}
        />
      </Switch>
    </div>
  )
}

ContratoDemanda.propTypes = {
  instalacao: PropTypes.object.isRequired,
}

export default ContratoDemanda
