import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ContatoEmpresa } from '../../components'
import * as contatoClienteActionCreators from '../../redux/modules/contatoCliente'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class ContatoClienteContainer extends Component {

  static propTypes = {
    cliente: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    multipleContatoCliente: PropTypes.object.isRequired,
    fetchMultipleContatoCliente: PropTypes.func.isRequired,
    destroyContatoCliente: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    const { cliente } = this.props
    await this.props.fetchMultipleContatoCliente(cliente.id)
  }

  handleRedirectToNew = (clienteId) => {
    this.props.history.push(`/clientes/${clienteId}/contatos/new`)
  }

  handleEditResource = (clienteId, id) => {
    this.props.history.push(`/clientes/${clienteId}/contatos/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyContatoCliente(id)
      ? this.props.showSuccessfulFlashMessage('', 'destroy')
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  handleSort = column => () => this.props.updateSort('contatoCliente', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('contatoCliente', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleContatoCliente.toList().toJSON(),
      'contatoCliente',
      [
        'id',
        'nome',
        'funcao.nome',
        'email',
        'telefone',
        'celular',
        'observacoes',
      ],
    )
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <ContatoEmpresa
        empresaTypePath='clientes'
        empresa={this.props.cliente}
        multipleContatoEmpresa={this.props.multipleContatoCliente}
        onRedirectToNew={() => this.handleRedirectToNew(this.props.cliente.id)}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV}
        isLoading={this.props.isLoading}
      />
    )
  }
}

function mapStateToProps ({ contatoCliente, status, sort, filter }, {cliente}) {
  const sortColumn = sort.getIn(['contatoCliente', 'sortColumn']) || 'nome'
  const sortDirection = sort.getIn(['contatoCliente', 'sortDirection']) || 'ascending'
  const filterText = filter.getIn(['contatoCliente', 'filterText']) || ''

  let map = filterMap(contatoCliente, ['nome', 'funcao.nome', 'email', 'telefone', 'celular', 'observacoes'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleContatoCliente: map.filter(t => t.get('clientes_ids').includes(cliente.id)),
    sortColumn,
    sortDirection,
    filterText,
    error: status.getIn(['contatoCliente', 'error']),
    isLoading: status.getIn(['contatoCliente', 'isLoading']),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...contatoClienteActionCreators,
    ...flashMessageActionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContatoClienteContainer))
