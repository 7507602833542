import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput, SemanticInputMask } from '../FormComponents'

import { required, maxLengthNome, fator } from '../../utils/validations'
import { maskCurrency } from '../../utils/masks'

const GeradorForm = (props) => (
  <CRUDForm
    title='Gerador'
    icon='rocket'
    {...props}>
    <FormSection name='gerador'>
      <Form.Group>
        <FormField width={6}>
          <label>Tipo</label>
          <Field
            autoFocus
            name='tipo'
            placeholder='Tipo'
            validate={[required, maxLengthNome]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>

      <Form.Group>
        <FormField width={6}>
          <label>Marca</label>
          <Field
            name='marca'
            placeholder='Marca'
            validate={[required, maxLengthNome]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>

        <FormField width={6}>
          <label>Modelo</label>
          <Field
            name='modelo'
            placeholder='Modelo'
            validate={[required, maxLengthNome]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>
      </Form.Group>

      <Form.Group>
        <FormField width={3}>
          <label>Eficiência</label>
          <Field
            name='eficiencia'
            validate={[required, fator]}
            component={SemanticInputMask}
            loading={props.loading}
            mask={maskCurrency.mask()}
            placeholder={maskCurrency.placeholder}
          />
        </FormField>

        <FormField width={3}>
          <label>Potência</label>
          <Field
            name='potencia'
            label='kVA'
            validate={[required]}
            component={SemanticInputMask}
            loading={props.loading}
            mask={maskCurrency.mask()}
            placeholder={maskCurrency.placeholder}
          />
        </FormField>

        <FormField width={3}>
          <label>Potência Ativa</label>
          <Field
            name='potencia_ativa'
            label='kW'
            validate={[required]}
            component={SemanticInputMask}
            loading={props.loading}
            mask={maskCurrency.mask()}
            placeholder={maskCurrency.placeholder}
          />
        </FormField>

        <FormField width={3}>
          <label>Potência (CV)</label>
          <Field
            name='potencia_cv'
            label='cv'
            validate={[required]}
            component={SemanticInputMask}
            loading={props.loading}
            mask={maskCurrency.mask()}
            placeholder={maskCurrency.placeholder}
          />
        </FormField>

        <FormField width={3}>
          <label>Combustível</label>
          <Field
            name='combustivel'
            placeholder='Combustível'
            validate={[required, maxLengthNome]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>

        <FormField width={3}>
          <label>Unidade Combustível</label>
          <Field
            name='unidade_combustivel'
            placeholder='Unidade Combustível'
            validate={[required, maxLengthNome]}
            component={SemanticInput}
            loading={props.loading}
          />
        </FormField>

        <FormField width={3}>
          <label>Consumo de Combustível/Hora</label>
          <Field
            name='consumo_combustivel'
            validate={[required]}
            component={SemanticInputMask}
            loading={props.loading}
            mask={maskCurrency.mask()}
            placeholder={maskCurrency.placeholder}
          />
        </FormField>
      </Form.Group>
    </FormSection>
  </CRUDForm>
)

GeradorForm.propTypes = {
  loading: PropTypes.bool
}

export default reduxForm({form: 'GeradorForm'})(GeradorForm)
