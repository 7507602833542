import React from "react";
import PainelResumoContext from "./painel-resumo-context";

const defaultPainelState = {
  isLoading: false,
  selectedCard: -1,
  mercado: 'ambos',
  mes: new Date().getMonth()+1,
  ano: new Date().getFullYear(),
  clientesFiltrados: [],
  setIsLoading: () => {},
  setSelectedCard: () => {},
  setMercado: () => {},
  setMes: () => {},
  setAno: () => {},
  setClientesFiltrados: () => {},
}

const painelReducer = (state, action) => {
  switch(action.type){
    case "IS_LOADING":
      return {...state, isLoading: action.isLoading};
    case "SELECTED_CARD":
      return {...state, selectedCard: action.selectedCard};
    case "MERCADO":
      return {...state, mercado: action.mercado};
    case "MES":
      return {...state, mes: action.mes};
    case "ANO":
      return {...state, ano: action.ano};
    case "CLIENTES_FILTRADOS":
      return {...state, clientesFiltrados: action.clientesFiltrados};
    default:
      return defaultPainelState;
  }
}

const PainelResumoProvider = props => {
  const [PainelState, dispatch] = React.useReducer(painelReducer, defaultPainelState)

  const setIsLoadingHandler = value => {
    dispatch({type: "IS_LOADING", isLoading: value})
  }

  const setSelectedCardHandler = value => {
    dispatch({type: "SELECTED_CARD", selectedCard: value})
  }

  const setMercadoHandler = value => {
    dispatch({type: "MERCADO", mercado: value})
  }

  const setMesHandler = value => {
    dispatch({type: "MES", mes: value})
  }

  const setAnoHandler = value => {
    dispatch({type: "ANO", ano: value})
  }

  const setClientesFiltradosHandler = value => {
    dispatch({type: "CLIENTES_FILTRADOS", clientesFiltrados: value})
  }

  const painelContext = {
    isLoading: PainelState.isLoading,
    selectedCard: PainelState.selectedCard, 
    mercado: PainelState.mercado,
    mes: PainelState.mes,
    ano: PainelState.ano,
    clientesFiltrados: PainelState.clientesFiltrados,
    setIsLoading: setIsLoadingHandler,
    setSelectedCard: setSelectedCardHandler,
    setMercado: setMercadoHandler,
    setMes: setMesHandler,
    setAno: setAnoHandler,
    setClientesFiltrados: setClientesFiltradosHandler,
  }

  return (
    <PainelResumoContext.Provider value={painelContext}>
      {props.children}
    </PainelResumoContext.Provider>
  )
}

export default PainelResumoProvider
