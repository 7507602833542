import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { FaturaEnergiaShow } from '../../components'
import * as faturaEnergiaActionCreators from '../../redux/modules/faturaEnergia'
import * as contratoEnergiaInstalacaoActionCreators from '../../redux/modules/contratoEnergiaInstalacao'
import * as comercializadoraActionCreators from '../../redux/modules/comercializadora'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

class FaturaEnergiaShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,

    fetchFaturaEnergia: PropTypes.func.isRequired,
    destroyFaturaEnergia: PropTypes.func.isRequired,

    fetchContratoEnergia: PropTypes.func.isRequired,
    fetchComercializadora: PropTypes.func.isRequired,

    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  state = {
    faturaEnergia: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const faturaEnergia = await this.props.fetchFaturaEnergia(this.props.id)
      const contratoEnergia = await this.props.fetchContratoEnergia(faturaEnergia.contratoEnergia_id)
      const comercializadora = await this.props.fetchComercializadora(faturaEnergia.comercializadora_id)
      this.setState({ faturaEnergia, contratoEnergia, comercializadora })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/faturas_energia/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/faturas_energia/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyFaturaEnergia(id)
    this.props.history.push('/faturas_energia')
  }

  render () {
    return (
      <FaturaEnergiaShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        faturaEnergia={this.state.faturaEnergia}
        contratoEnergia={this.state.contratoEnergia}
        comercializadora={this.state.comercializadora}
      />
    )
  }
}

function mapStateToProps ({faturaEnergia, contratoEnergia, comercializadora}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      faturaEnergia: faturaEnergia.get('id'),
      contratoEnergia: contratoEnergia.get('id'),
      comercializadora: comercializadora.get('id')
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...faturaEnergiaActionCreators,
    ...contratoEnergiaInstalacaoActionCreators,
    ...comercializadoraActionCreators,
    ...flashMessageAcionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FaturaEnergiaShowContainer))
