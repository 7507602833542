import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Container } from 'semantic-ui-react'
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
} from 'recharts'
import {
  COLORS,
  capitalize,
  formatAsLocalizedInteger,
  formatAsIndicador,
  formatAsKWH,
} from './utils/ChartUtils'
import LabelListInsideBottomCenterLabel from './utils/LabelListInsideBottomCenterLabel'

const fieldsNameMapping = {
  retrasado: '36 meses',
  passado: '24 meses',
  atual: '12 meses',
  media: 'Média dos últimos 12 meses',
}

const HistoricoConsumoChart = ({ data, height, showAnimations }) => {
  if (!data) return null

  let ano
  const preparedData = data.dados.reverse().map(d => {
    if (!ano || d.ano > ano) ano = d.ano
    const prepared = Object.assign({}, d)
    prepared._labelFaturaMes = capitalize(moment({y: d.ano, M: d.mes - 1}).format('MMMM'))
    prepared._parsedMedia = data.media
    return prepared
  })

  ano = ano || moment().year()


  const temPassado = preparedData.some(r => r.passado > 0.0)
  const temRetrasado = preparedData.some(r => r.retrasado > 0.0)

  return (
    <Container fluid>
      <ResponsiveContainer height={height}>
        <ComposedChart data={preparedData} barGap={0}>
          <XAxis dataKey="_labelFaturaMes" type="category" interval={0}/>
          <YAxis type="number" tickFormatter={formatAsKWH} padding={{top: 10}}/>
          <Tooltip formatter={formatAsLocalizedInteger}/>
          <Legend/>
          {temRetrasado && (
            <Bar
              dataKey="retrasado"
              fill={COLORS.ELECTRIC.LIGHTER.BLUE}
              legendType="square"
              name={fieldsNameMapping['retrasado']}
              unit=" kWh"
              isAnimationActive={showAnimations}
            >
              <LabelList className='color-black' dataKey="retrasado" angle={-90} formatter={formatAsIndicador(' kWh')} content={LabelListInsideBottomCenterLabel}/>
            </Bar>
          )}

          {temPassado && (
            <Bar
              dataKey="passado"
              fill={COLORS.ELECTRIC.LIGHT.BLUE}
              legendType="square"
              name={fieldsNameMapping['passado']}
              unit=" kWh"
              isAnimationActive={showAnimations}
            >
              <LabelList className='color-black' dataKey="passado" angle={-90} formatter={formatAsIndicador(' kWh')} content={LabelListInsideBottomCenterLabel}/>
            </Bar>
          )}


          <Bar
            dataKey="atual"
            fill={COLORS.ELECTRIC.BLUE}
            legendType="square"
            name={fieldsNameMapping['atual']}
            unit=" kWh"
            isAnimationActive={showAnimations}
          >
            <LabelList className='color-black' dataKey="atual" angle={-90} formatter={formatAsIndicador(' kWh')} content={LabelListInsideBottomCenterLabel}/>
          </Bar>

          <Line
            dataKey="_parsedMedia"
            stroke={COLORS.ELECTRIC.LIGHT.RED}
            strokeWidth={2}
            legendType="line"
            name={fieldsNameMapping['media']}
            dot={false}
            unit=" kWh"
            isAnimationActive={showAnimations}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Container>
  )
}

HistoricoConsumoChart.propTypes = {
  data: PropTypes.object,
  height: PropTypes.number,
  showAnimations: PropTypes.bool,
}

HistoricoConsumoChart.defaultProps = {
  height: 350,
  showAnimations: true,
}

export default HistoricoConsumoChart
