import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { PainelResumo } from '../../components'
import PainelResumoProvider from '../../components/PainelResumo/painel-resumo-provider'
import * as resumoActionCreators from '../../redux/modules/painelResumo'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

class PainelResumoContainer extends Component {

  static propTypes = {
    fetchMultipleResumo: PropTypes.func.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      clientes: [],
    }
  }

  async componentDidMount () {
    this.props.fetchMultipleResumo().then(result => {
      this.setState({ clientes: result })
    })
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <PainelResumoProvider>
        <PainelResumo
          title='Painel de Resumo'
          listPath='resumos'
          multipleResumo={this.state}/>
      </PainelResumoProvider>
    )
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...resumoActionCreators,
    ...flashMessageAcionCreators,
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(PainelResumoContainer)
