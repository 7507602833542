import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form/immutable'

import { InstalacaoForm } from '../../components'
import * as instalacaoClienteActionCreators from '../../redux/modules/instalacaoCliente'
import * as ufActionCreators from '../../redux/modules/uf'
import * as userActionCreators from '../../redux/modules/user'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

import { mapToDropdown } from '../../utils/parse'

const selector = formValueSelector('InstalacaoForm')

class InstalacaoClienteFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    initialize: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    cliente: PropTypes.object,

    fetchInstalacaoCliente: PropTypes.func.isRequired,
    createInstalacaoCliente: PropTypes.func.isRequired,
    updateInstalacaoCliente: PropTypes.func.isRequired,

    ufDropdown: PropTypes.array.isRequired,
    fetchMultipleUf: PropTypes.func.isRequired,

    userDropdown: PropTypes.array.isRequired,
    fetchMultipleUser: PropTypes.func.isRequired,

    pessoa: PropTypes.number,
    pessoaDropdown: PropTypes.array.isRequired,

    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,

    error: PropTypes.object,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await Promise.all([
      this.props.fetchMultipleUf(),
      this.props.fetchMultipleUser('analista')
    ])

    if (this.props.id) {
      const instalacao = await this.props.fetchInstalacaoCliente(this.props.id)
      this.props.initialize('InstalacaoForm', {instalacao})
    } else {
      this.props.initialize('InstalacaoForm', { instalacao: { pessoa: 0 } })
    }
  }

  handleSubmitResource = async (instalacaoCliente) => {
    const clienteId = this.props.cliente.id

    if (this.props.id) {
      if (await this.props.updateInstalacaoCliente(this.props.id, instalacaoCliente)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/clientes/${clienteId}/instalacoes`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createInstalacaoCliente(clienteId, instalacaoCliente)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push(`/clientes/${clienteId}/instalacoes`)
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <InstalacaoForm titleAs='h3' noPadded
        pessoa={this.props.pessoa}
        pessoaDropdown={this.props.pessoaDropdown}
        ufDropdown={this.props.ufDropdown}
        userDropdown={this.props.userDropdown}
        onSubmit={(instalacaoCliente) => this.handleSubmitResource(instalacaoCliente)}
        isLoading={this.props.isLoading}
      />
    )
  }
}

function mapStateToProps (state, {match}) {
  const {instalacaoCliente, uf, user, status, flashMessage} = state
  const { id } = match.params

  const ufDropdown = mapToDropdown(
    uf,
    e => e.get('id'),
    e => `${e.get('sigla')} - ${e.get('nome')}`
  )

  const userDropdown = mapToDropdown(
    user,
    e => e.get('id'),
    e => e.get('email')
  )

  const pessoa = selector(state, 'instalacao.pessoa')

  const pessoaDropdown = [
    {
      key: 'juridica',
      value: 0,
      text: 'Pessoa Jurídica',
    },
    {
      key: 'fisica',
      value: 1,
      text: 'Pessoa Física',
    },
  ]

  const isLoading = status.getIn(['instalacaoCliente', 'isLoading'])
    || status.getIn(['uf', 'isLoading'])
    || status.getIn(['user', 'isLoading'])

  if (id) {
    return {
      id,
      instalacaoCliente: instalacaoCliente.get('id'),
      ufDropdown,
      userDropdown,
      pessoa,
      pessoaDropdown,
      error: status.getIn(['instalacaoCliente', 'error']),
      shouldHideFlashMessage: flashMessage.get('isError'),
      isLoading,
    }
  }
  return {
    ufDropdown,
    userDropdown,
    pessoa,
    pessoaDropdown,
    error: status.getIn(['instalacaoCliente', 'error']),
    shouldHideFlashMessage: flashMessage.get('isError'),
    isLoading,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...instalacaoClienteActionCreators,
    ...ufActionCreators,
    ...userActionCreators,
    ...flashMessageActionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InstalacaoClienteFormContainer))
