import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_ICMS = 'LOADING_ICMS'
export function loadingIcms () {
  return {
    type: LOADING_ICMS,
  }
}

export const LOADING_ICMS_SUCCESS = 'LOADING_ICMS_SUCCESS'
export function loadingIcmsSuccess (icms) {
  return {
    type: LOADING_ICMS_SUCCESS,
    icms,
  }
}

export const LOADING_ICMS_FAILURE = 'LOADING_ICMS_FAILURE'
export function loadingIcmsFailure (error) {
  return {
    type: LOADING_ICMS_FAILURE,
    error,
  }
}

export const DESTROYING_ICMS_SUCCESS = 'DESTROYING_ICMS_SUCCESS'
export function destroyingIcmsSuccess (icmsId) {
  return {
    type: DESTROYING_ICMS_SUCCESS,
    icmsId,
  }
}

const initialState = Map({})

export function fetchMultipleIcms () {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingIcms())
      const multipleIcms = await callAPI('/icms')
      dispatch(loadingIcmsSuccess(multipleIcms))
      return multipleIcms
    } catch (e) {
      dispatch(loadingIcmsFailure(e))
      return false
    }
  }
}

export function fetchIcms (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingIcms())
      const icms = await callAPI(`/icms/${id}`)
      dispatch(loadingIcmsSuccess(icms))
      return icms
    } catch (e) {
      dispatch(loadingIcmsFailure(e))
      return false
    }
  }
}

export function createIcms (icms) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingIcms())
      const newIcms = await callAPI('/icms', 'POST', icms)
      dispatch(loadingIcmsSuccess(newIcms))
      return newIcms
    } catch (e) {
      dispatch(loadingIcmsFailure(e))
      return false
    }
  }
}

export function updateIcms (id, icms) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingIcms())
      const newIcms = await callAPI(`/icms/${id}`, 'PUT', icms)
      dispatch(loadingIcmsSuccess(newIcms))
      return newIcms
    } catch (e) {
      dispatch(loadingIcmsFailure(e))
      return false
    }
  }
}

export function destroyIcms (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingIcms())
      await callAPI(`/icms/${id}`, 'DELETE')
      dispatch(destroyingIcmsSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingIcmsFailure(e))
      return false
    }
  }
}

export default function icms (state = initialState, action) {
  switch (action.type) {

    case LOADING_ICMS_SUCCESS : {
      const { icms } = action
      if (icms.constructor === Array) {
        state = initialState
        icms.forEach((singleIcms) => (
          state = state.merge({[singleIcms.id]: singleIcms})
        ))
        return state
      }
      return state.merge({[icms.id]: icms})
    }

    case DESTROYING_ICMS_SUCCESS :
      return state.delete(action.icmsId.toString())

    default :
      return state
  }
}
