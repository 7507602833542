import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Grid, List, Divider, Segment } from 'semantic-ui-react'

import ViewModeContext from '../../containers/ViewModeContext/ViewModeContext'
import ViewMode from '../../utils/viewMode'

import Gestor from './Gestor'
import RecadosForm from './RecadosForm'
import MarkdownRenderer from './MarkdownRenderer'
import { COLORS } from './Graficos/utils/ChartUtils'

const getIcon = tipo => tipo === 1
  ? 'thumbs up'
  : tipo === -1
    ? 'thumbs down'
    : 'warning sign'

const getColor = tipo => tipo === 1
  ? COLORS.ELECTRIC.GREEN
  : tipo === -1
    ? COLORS.ELECTRIC.RED
    : COLORS.ELECTRIC.YELLOW

const renderMethod = (mode) => ({
  [ViewMode.SHOW]: renderShow,
  [ViewMode.PRINT]: renderShow,
  [ViewMode.EDIT]: renderEdit,
})[mode]

const renderShow = props => {
  const { recados } = props

  return recados && recados.map((recado, index) => {
    const tipo = recado.get('tipo')
    const resumo = recado.get('resumo')
    const conteudo = recado.get('conteudo')
    return (
      <List.Item key={index} style={{ pageBreakInside: 'avoid' }}>
        <List.Icon name={getIcon(tipo)} size='large' style={{ color: getColor(tipo) }} verticalAlign='top' />
        <List.Content verticalAlign='middle'>
          <List.Header style={{ color: getColor(tipo) }}>{resumo}</List.Header>
          <MarkdownRenderer source={conteudo} className='recado' />
        </List.Content>
      </List.Item>
    )
  })
}

const renderEdit = props => {
  const { isEditMode, onToggleMode, onSubmit, isLoading } = props

  if (!isEditMode) return (
    <React.Fragment>
      {renderShow(props)}
      <Button fluid
        color='yellow'
        onClick={onToggleMode}>
        <Icon name='edit' />Alterar
      </Button>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <List.Item inset>
        <List.Content verticalAlign='middle' textAlign='center'>
          <RecadosForm
            isLoading={isLoading}
            onToggleMode={onToggleMode}
            onSubmit={onSubmit}
          />
        </List.Content>
      </List.Item>
    </React.Fragment>
  )
}

renderEdit.propTypes = {
  isEditMode: PropTypes.bool,
  onToggleMode: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
}

const Recados = props => {
  const { viewMode } = useContext(ViewModeContext)
  const { gestor } = props
  return (
    <Grid.Row className='recadosGestor' style={{ pageBreakInside: 'unset' }}>
      <Grid.Column width={4}>
        <Gestor gestor={gestor} />
      </Grid.Column>
      <Grid.Column width={12} style={{ paddingLeft: 0 }} stretched>
        <Segment style={{
          backgroundColor: '#f4f4f4',
          boxShadow: 'none',
          border: '0px',
        }}>
          <List relaxed className='egrid-recados-gestor'>
            <List.Item inset>
              <List.Content verticalAlign='middle' textAlign='center'>
                <Divider horizontal style={{margin: '0px'}}>Recados do seu gestor</Divider>
              </List.Content>
            </List.Item>
            <List.Item style={{ pageBreakInside: 'avoid', color: COLORS.ELECTRIC.LIGHT.BASE }}>
              <List.Content verticalAlign='middle'>
                <List.Header style={{color: COLORS.ELECTRIC.LIGHT.BASE}}>Sobre o relatório</List.Header>
                <p style={{ fontSize: '0.8em', textAlign: 'justify', color: COLORS.ELECTRIC.LIGHT.BASE }}>
                  Este relatório mensal consiste na validação dos parâmetros técnicos e financeiros apresentados na sua fatura de energia.
                  Além da certificação dos valores faturados, por meio de reprocessamento dos dados, apresentamos aqui as principais orientações e observações para uso mais racional e eficiente da energia em sua unidade.
                </p>
              </List.Content>
            </List.Item>
            {renderMethod(viewMode)(props)}
          </List>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  )
}

Recados.propTypes = {
  recados: PropTypes.array,
  isLoading: PropTypes.bool,
  print: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  gestor: PropTypes.object,
}

export default Recados
