import { Map } from 'immutable'
import { callAPI } from '../../utils/API'

export const LOADING_VALOR_ENERGIA = 'LOADING_VALOR_ENERGIA'
export function loadingValorEnergia () {
  return {
    type: LOADING_VALOR_ENERGIA,
  }
}

export const LOADING_VALOR_ENERGIA_SUCCESS = 'LOADING_VALOR_ENERGIA_SUCCESS'
export function loadingValorEnergiaSuccess (valorEnergia) {
  return {
    type: LOADING_VALOR_ENERGIA_SUCCESS,
    valorEnergia,
  }
}

export const LOADING_VALOR_ENERGIA_FAILURE = 'LOADING_VALOR_ENERGIA_FAILURE'
export function loadingValorEnergiaFailure (error) {
  return {
    type: LOADING_VALOR_ENERGIA_FAILURE,
    error,
  }
}

export const DESTROYING_VALOR_ENERGIA_SUCCESS = 'DESTROYING_VALOR_ENERGIA_SUCCESS'
export function destroyingValorEnergiaSuccess (valorEnergiaId) {
  return {
    type: DESTROYING_VALOR_ENERGIA_SUCCESS,
    valorEnergiaId,
  }
}

const initialState = Map({})

export function fetchMultipleValorEnergia (contratoEnergiaId) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingValorEnergia())
      const multipleValorEnergia = await callAPI(`/contratos_energia/${contratoEnergiaId}/valores_energia`)
      dispatch(loadingValorEnergiaSuccess(multipleValorEnergia))
      return multipleValorEnergia
    } catch (e) {
      dispatch(loadingValorEnergiaFailure(e))
      return false
    }
  }
}

export function fetchValorEnergia (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingValorEnergia())
      const valorEnergia = await callAPI(`/valores_energia/${id}`)
      dispatch(loadingValorEnergiaSuccess(valorEnergia))
      return valorEnergia
    } catch (e) {
      dispatch(loadingValorEnergiaFailure(e))
      return false
    }
  }
}

export function createValorEnergia (contratoEnergiaId, valorEnergia) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingValorEnergia())
      const newValorEnergia = await callAPI(`/contratos_energia/${contratoEnergiaId}/valores_energia`, 'POST', valorEnergia)
      dispatch(loadingValorEnergiaSuccess(newValorEnergia))
      return newValorEnergia
    } catch (e) {
      dispatch(loadingValorEnergiaFailure(e))
      return false
    }
  }
}

export function updateValorEnergia (id, valorEnergia) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingValorEnergia())
      const newValorEnergia = await callAPI(`/valores_energia/${id}`, 'PUT', valorEnergia)
      dispatch(loadingValorEnergiaSuccess(newValorEnergia))
      return newValorEnergia
    } catch (e) {
      dispatch(loadingValorEnergiaFailure(e))
      return false
    }
  }
}

export function destroyValorEnergia (id) {
  return async function (dispatch, /* getState */) {
    try {
      dispatch(loadingValorEnergia())
      await callAPI(`/valores_energia/${id}`, 'DELETE')
      dispatch(destroyingValorEnergiaSuccess(id))
      return true
    } catch (e) {
      dispatch(loadingValorEnergiaFailure(e))
      return false
    }
  }
}

export default function valorEnergia (state = initialState, action) {
  switch (action.type) {

    case LOADING_VALOR_ENERGIA_SUCCESS : {

      const { valorEnergia } = action
      if (valorEnergia.constructor === Array) {
        state = initialState
        valorEnergia.forEach((singleValorEnergia) => (
          state = state.merge({[singleValorEnergia.id]: singleValorEnergia})
        ))
        return state
      }
      return state.merge({[valorEnergia.id]: valorEnergia})
    }

    case DESTROYING_VALOR_ENERGIA_SUCCESS :
      return state.delete(action.valorEnergiaId.toString())

    default :
      return state
  }
}
