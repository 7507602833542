import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { FonteEnergiaForm } from '../../components'
import * as fonteEnergiaActionCreators from '../../redux/modules/fonteEnergia'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { maskCurrency } from '../../utils/masks'

class FonteEnergiaFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,

    fetchFonteEnergia: PropTypes.func.isRequired,
    createFonteEnergia: PropTypes.func.isRequired,
    updateFonteEnergia: PropTypes.func.isRequired,

    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,

    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    if (this.props.id) {
      const fonte_energia = await this.props.fetchFonteEnergia(this.props.id)
      fonte_energia.incentivo = maskCurrency.parse(Number(fonte_energia.incentivo) * 100)
      this.props.initialize('FonteEnergiaForm', {fonte_energia})
    }
  }

  handleSubmitResource = async (fonteEnergia) => {
    if (fonteEnergia.getIn(['fonte_energia', 'incentivada'])) {
      let incentivo = maskCurrency.unmask(fonteEnergia.getIn(['fonte_energia', 'incentivo']))
      fonteEnergia = fonteEnergia.setIn(['fonte_energia', 'incentivo'], incentivo / 100)
    } else {
      fonteEnergia = fonteEnergia.setIn(['fonte_energia', 'incentivo'], 0)
    }

    if (this.props.id) {
      if (await this.props.updateFonteEnergia(this.props.id, fonteEnergia)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push('/fontes_energia')
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createFonteEnergia(fonteEnergia)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/fontes_energia')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <FonteEnergiaForm
        onSubmit={(fonteEnergia) => this.handleSubmitResource(fonteEnergia)}
        isLoading={this.props.isLoading} />
    )
  }
}

function mapStateToProps ({fonteEnergia, status, flashMessage}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      fonteEnergia: fonteEnergia.get('id'),
      error: status.getIn(['fonteEnergia', 'error']),
      isLoading: status.getIn(['fonteEnergia', 'isLoading']),
      shouldHideFlashMessage: flashMessage.get('isError'),
    }
  }
  return {
    error: status.getIn(['fonteEnergia', 'error']),
    isLoading: status.getIn(['fonteEnergia', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...fonteEnergiaActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FonteEnergiaFormContainer)
