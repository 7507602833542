import React from 'react'
import PropTypes from 'prop-types'
import { Table, Label, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { CRUDIndex } from '../CRUD'
import { EditDeleteButtonContainer } from '../../containers'

const ClasseConsumo = (props) => (
  <CRUDIndex
    title='Classes de Consumo'
    icon='block layout'
    onRedirectToNew={props.onRedirectToNew}
    onExportToCSV={props.onExportToCSV}
    filterText={props.filterText}
    handleFilter={props.handleFilter}
  >
    <Table sortable celled striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1} textAlign='center'
            onClick={props.handleSort('id')}
            sorted={props.handleSorted('id')}>#</Table.HeaderCell>
          <Table.HeaderCell width={3}
            onClick={props.handleSort('nome')}
            sorted={props.handleSorted('nome')}>Classe</Table.HeaderCell>
          <Table.HeaderCell
            onClick={props.handleSort('descricao')}
            sorted={props.handleSorted('descricao')}>Descrição</Table.HeaderCell>
          <Table.HeaderCell width={1}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.isLoading && (
          <Table.Row>
            <Table.Cell colSpan='4'>
              <Loader active={props.isLoading} inline='centered' />
            </Table.Cell>
          </Table.Row>
        )}
        {props.multipleClasseConsumo.valueSeq().map((classeConsumo, index) => (
          <Table.Row key={index}>
            <Table.Cell textAlign='center'>
              <Label as={Link} to={`/classes_consumo/${classeConsumo.get('id')}`}>{classeConsumo.get('id')}</Label>
            </Table.Cell>
            <Table.Cell>{classeConsumo.get('nome')}</Table.Cell>
            <Table.Cell>{classeConsumo.get('descricao')}</Table.Cell>
            <Table.Cell textAlign='right'>
              <EditDeleteButtonContainer
                onEditResource={() => props.onEditResource(classeConsumo.get('id'))}
                onDeleteResource={() => props.onDeleteResource(classeConsumo.get('id'))} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </CRUDIndex>
)

ClasseConsumo.propTypes = {
  onRedirectToNew: PropTypes.func,
  multipleClasseConsumo: PropTypes.object,
  onEditResource: PropTypes.func,
  onDeleteResource: PropTypes.func,
  handleSort: PropTypes.func,
  handleSorted: PropTypes.func,
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onExportToCSV: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default ClasseConsumo
