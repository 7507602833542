import moment from 'moment'

export default class WeekHelper {
  static firstDayInMonth = (date, offset = 0) => new Date(date.getFullYear(), date.getMonth() + offset, 1)
  static lastDayInMonth = (date, offset = 0) => new Date(date.getFullYear(), date.getMonth() + 1 + offset, 0)

  static currentMonth = (base_date) => [
    WeekHelper.firstDayInMonth(new Date(base_date)),
    WeekHelper.lastDayInMonth(new Date(base_date)),
  ]

  static nextMonth = (base_date) => [
    WeekHelper.firstDayInMonth(new Date(base_date), 1),
    WeekHelper.lastDayInMonth(new Date(base_date), 1),
  ]

  static previousMonth = (base_date) => [
    WeekHelper.firstDayInMonth(new Date(base_date), -1),
    WeekHelper.lastDayInMonth(new Date(base_date), -1),
  ]

  static rangeIncludes(range, day) {
    const [ start, end ] = range
    return start <= day && end >= day
  }

  static rangesOverlap(a, b) {
    const [ startA, endA ] = a
    const [ startB, endB ] = b
    return startA <= endB && endA >= startB
  }

  static format(date) {
    return moment(date).format('DD/MM/YYYY')
  }

  static lastSaturdayFrom(base_date) {
    let last_saturday = new Date(base_date)
    if (base_date.getDay() === 6) return base_date
    last_saturday.setDate(last_saturday.getDate() - (base_date.getDay() + 1))
    return last_saturday
  }

  static nextFridayFrom(base_date) {
    let next_friday = new Date(base_date)
    if (base_date.getDay() === 5) return base_date
    next_friday.setDate(next_friday.getDate() + base_date.getDay())
    return next_friday
  }

  static getWeek(year, month, week) {
    let base_date = WeekHelper.lastSaturdayFrom(new Date(year, month - 1, 1))
    let weeks_to_add = week - 1
    let first_day = new Date(base_date)
    first_day.setDate(first_day.getDate() + 7 * weeks_to_add)
    let last_day = WeekHelper.nextFridayFrom(first_day)
    return [ first_day, last_day ]
  }

  static isWeekValid(year, month, week) {
    let base_date = new Date(year, month - 1, 1)
    let week_range = WeekHelper.getWeek(year, month, week)
    let current_month = WeekHelper.currentMonth(base_date)

    const [ beginning_of_month ] = current_month
    if (week === 1 && WeekHelper.rangeIncludes(week_range, beginning_of_month)) return true
    if (WeekHelper.rangesOverlap(week_range, WeekHelper.nextMonth(base_date))) return false
    if (WeekHelper.rangesOverlap(week_range, WeekHelper.previousMonth(base_date))) return false
    return true
  }

  static getWeeks(year, month) {
    let weeks = [], week = 1
    while (WeekHelper.isWeekValid(year, month, week)) {
      const [ start, end ] = WeekHelper.getWeek(year, month, week)
      weeks.push({
        key: week + '',
        text: `#${week} - ${WeekHelper.format(start)} a ${WeekHelper.format(end)}`,
        value: week
      })
      week++
    }
    return weeks
  }
}
