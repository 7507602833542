import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import { UfForm } from '../../components'
import * as ufActionCreators from '../../redux/modules/uf'
import * as submercadoActionCreators from '../../redux/modules/submercado'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { mapToDropdown } from '../../utils/parse'

class UfFormContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    initialize: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    fetchUf: PropTypes.func.isRequired,
    createUf: PropTypes.func.isRequired,
    updateUf: PropTypes.func.isRequired,
    multipleSubmercado: PropTypes.object,
    submercadoDropdown: PropTypes.array.isRequired,
    fetchMultipleSubmercado: PropTypes.func.isRequired,
    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    await this.props.fetchMultipleSubmercado()

    if (this.props.id) {
      const uf = await this.props.fetchUf(this.props.id)
      uf.submercado_id = uf.submercado.id
      this.props.initialize('UfForm', {uf})
    }
  }

  handleSubmitResource = async (uf) => {
    if (this.props.id) {
      if (this.props.updateUf(this.props.id, uf)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push('/uf')
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createUf(uf)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push('/uf')
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <UfForm
        submercadoDropdown={this.props.submercadoDropdown}
        multipleSubmercado={this.props.multipleSubmercado}
        onSubmit={(uf) => this.handleSubmitResource(uf)}
        isLoading={this.props.isLoading} />
    )
  }

}

function mapStateToProps ({uf, submercado, status, flashMessage}, {match}) {
  const { id } = match.params
  const submercadoDropdown = mapToDropdown(
    submercado,
    e => e.get('id'),
    e => e.get('nome'),
    true
  )

  const isLoading = status.getIn(['uf', 'isLoading'])
    || status.getIn(['submercado', 'isLoading'])

  if (id) {
    return {
      id,
      uf: uf.get('id'),
      error: status.getIn(['uf', 'error']),
      isLoading,
      shouldHideFlashMessage: flashMessage.get('isError'),
      submercadoDropdown,
    }
  }
  return {
    submercadoDropdown,
    error: status.getIn(['uf', 'error']),
    isLoading: status.getIn(['uf', 'isLoading']),
    shouldHideFlashMessage: flashMessage.get('isError'),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...ufActionCreators,
    ...submercadoActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UfFormContainer)
