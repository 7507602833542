import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, formValueSelector } from 'redux-form/immutable'

import { Table } from 'semantic-ui-react'
import { SemanticInputMask } from '../FormComponents'

import { required } from '../../utils/validations'
import { maskFloat } from '../../utils/masks'

const selector = formValueSelector('VigenciaTarifaForm')

const DECIMAL_DIGITS = 6
const [ ALTA_TENSAO, BAIXA_TENSAO ]  = [ 0, 1 ]
const [ ROWSPAN_ALTA_TENSAO, ROWSPAN_BAIXA_TENSAO ]  = [ 2, 3 ]

let ValorTarifa = ({ valorTarifa, loading, subgrupo_id, enquadramento_id, multipleSubgrupo, multipleEnquadramento }) => {
  const subgrupo = multipleSubgrupo.get(`${subgrupo_id}`)
  const enquadramento = multipleEnquadramento.get(`${enquadramento_id}`)
  const grupo = parseInt(subgrupo.get('grupo'), 10)
  const rowSpan = grupo === ALTA_TENSAO ? ROWSPAN_ALTA_TENSAO : ROWSPAN_BAIXA_TENSAO
  return (
    <Table.Body>
      <Table.Row>
        <Table.Cell textAlign='center' rowSpan={rowSpan}>{ subgrupo.get('nome') }</Table.Cell>
        <Table.Cell textAlign='center' rowSpan={rowSpan}>{ enquadramento.get('modalidade') }</Table.Cell>
        <Table.Cell textAlign='center' rowSpan={1}>P</Table.Cell>
        <Table.Cell textAlign='right' rowSpan={1}>
          <Field fluid
            name={`${valorTarifa}.tusd_kw_p`}
            placeholder='0,000000'
            mask={maskFloat.mask(DECIMAL_DIGITS)}
            validate={[required]}
            component={SemanticInputMask}
            loading={loading}
            label='R$/kW'
          />
        </Table.Cell>
        <Table.Cell textAlign='right' rowSpan={1}>
          <Field fluid
            name={`${valorTarifa}.tusd_kwh_p`}
            placeholder='0,000000'
            mask={maskFloat.mask(DECIMAL_DIGITS)}
            validate={[required]}
            component={SemanticInputMask}
            loading={loading}
            label='R$/kWh'
          />
        </Table.Cell>
        <Table.Cell textAlign='right' rowSpan={1}>
          <Field fluid
            name={`${valorTarifa}.te_kwh_p`}
            placeholder='0,000000'
            mask={maskFloat.mask(DECIMAL_DIGITS)}
            validate={[required]}
            component={SemanticInputMask}
            loading={loading}
            label='R$/kWh'
          />
        </Table.Cell>
        <Table.Cell textAlign='right' rowSpan={rowSpan}>
          <Field fluid
            name={`${valorTarifa}.tr_kwh`}
            placeholder='0,000000'
            mask={maskFloat.mask(DECIMAL_DIGITS)}
            validate={[required]}
            component={SemanticInputMask}
            loading={loading}
            label='R$/kWh'
          />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell textAlign='center' rowSpan={1}>FP</Table.Cell>
        <Table.Cell textAlign='right' rowSpan={1}>
          <Field fluid
            name={`${valorTarifa}.tusd_kw_fp`}
            placeholder='0,000000'
            mask={maskFloat.mask(DECIMAL_DIGITS)}
            validate={[required]}
            component={SemanticInputMask}
            loading={loading}
            label='R$/kW'
          />
        </Table.Cell>
        <Table.Cell textAlign='right' rowSpan={1}>
          <Field fluid
            name={`${valorTarifa}.tusd_kwh_fp`}
            placeholder='0,000000'
            mask={maskFloat.mask(DECIMAL_DIGITS)}
            validate={[required]}
            component={SemanticInputMask}
            loading={loading}
            label='R$/kWh'
          />
        </Table.Cell>
        <Table.Cell textAlign='right' rowSpan={1}>
          <Field fluid
            name={`${valorTarifa}.te_kwh_fp`}
            placeholder='0,000000'
            mask={maskFloat.mask(DECIMAL_DIGITS)}
            validate={[required]}
            component={SemanticInputMask}
            loading={loading}
            label='R$/kWh'
          />
        </Table.Cell>
      </Table.Row>
      {grupo === BAIXA_TENSAO && (
        <Table.Row>
          <Table.Cell textAlign='center' rowSpan={1}>INT</Table.Cell>
          <Table.Cell textAlign='right' rowSpan={1}>
            <Field fluid
              name={`${valorTarifa}.tusd_kw_int`}
              placeholder='0,000000'
              mask={maskFloat.mask(DECIMAL_DIGITS)}
              validate={[required]}
              component={SemanticInputMask}
              loading={loading}
              label='R$/kW'
            />
          </Table.Cell>
          <Table.Cell textAlign='right' rowSpan={1}>
            <Field fluid
              name={`${valorTarifa}.tusd_kwh_int`}
              placeholder='0,000000'
              mask={maskFloat.mask(DECIMAL_DIGITS)}
              validate={[required]}
              component={SemanticInputMask}
              loading={loading}
              label='R$/kWh'
            />
          </Table.Cell>
          <Table.Cell textAlign='right' rowSpan={1}>
            <Field fluid
              name={`${valorTarifa}.te_kwh_int`}
              placeholder='0,000000'
              mask={maskFloat.mask(DECIMAL_DIGITS)}
              validate={[required]}
              component={SemanticInputMask}
              loading={loading}
              label='R$/kWh'
            />
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  )
}

ValorTarifa = connect(
  (state, props) => {
    return ({
      subgrupo_id: selector(state, `vigencia_tarifa.${props.valorTarifa}.subgrupo_id`),
      enquadramento_id: selector(state, `vigencia_tarifa.${props.valorTarifa}.enquadramento_id`)
    })
  }
)(ValorTarifa)

function mapStateToProps ({ subgrupo, enquadramento }) {
  return {
    multipleSubgrupo: subgrupo,
    multipleEnquadramento: enquadramento,
  }
}

ValorTarifa.propTypes = {
  valorTarifa: PropTypes.string,
  loading: PropTypes.bool,
  subgrupo_id: PropTypes.number,
  enquadramento_id: PropTypes.number,
  multipleSubgrupo: PropTypes.object,
  multipleEnquadramento: PropTypes.object,
}

export default connect(mapStateToProps)(ValorTarifa)
