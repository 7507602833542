import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { formValueSelector } from 'redux-form/immutable'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { change } from 'redux-form/immutable'

import { List, fromJS } from 'immutable'
import moment from 'moment'

import { FaturaPedagioForm } from '../../components'
import * as faturaPedagioActionCreators from '../../redux/modules/faturaPedagio'
import * as vigenciaParametroActionCreators from '../../redux/modules/vigenciaParametro'
import * as tributoActionCreators from '../../redux/modules/tributo'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

import { maskFloat, maskPercent } from '../../utils/masks'

const DECIMAL_DIGITS = 6

const selector = formValueSelector('FaturaPedagioForm')

const decimalFields = [
  'consumo_p',
  'consumo_fp',
  'consumo_na',
  'consumo_int',
  'demanda_p',
  'demanda_fp',
  'demanda_na',
  'ufer_p',
  'ufer_fp',
  'dmcr_p',
  'dmcr_fp',
  'dmcr_na',
  'iluminacao_publica',
  'encargos_conexao',
  'valor_total',
  'multas',
  'juros',
  'correcao_monetaria',
  'consumo_mensal_combustivel',
  'custo_total_combustivel',
  'consumo_mes_inicial',
  'consumo_mes_final',
  'valor_bandeira_inicial',
  'valor_bandeira_final',
]

class FaturaPedagioFormContainer extends Component {

  static propTypes = {
    id: PropTypes.string,
    instalacao: PropTypes.object,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,

    fetchFaturaPedagio: PropTypes.func.isRequired,
    createFaturaPedagio: PropTypes.func.isRequired,
    updateFaturaPedagio: PropTypes.func.isRequired,

    lastVigenciaParametro: PropTypes.object,
    fetchLastVigenciaParametro: PropTypes.func,
    instalacaoId: PropTypes.number,
    dataInicial: PropTypes.object,
    dataFinal: PropTypes.object,
    consumoTotal: PropTypes.string,
    possuiTributos: PropTypes.string,

    fetchMultipleTributo: PropTypes.func.isRequired,

    showSuccessfulFlashMessage: PropTypes.func.isRequired,
    showErrorFlashMessage: PropTypes.func.isRequired,
    hideFlashMessage: PropTypes.func.isRequired,
    shouldHideFlashMessage: PropTypes.bool.isRequired,
    error: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    if (this.props.id) {
      const [ fatura_pedagio, multipleTributo ] = await Promise.all([
        this.props.fetchFaturaPedagio(this.props.id),
        this.props.fetchMultipleTributo(),
      ])

      decimalFields.forEach(param => {
        fatura_pedagio[param] = maskFloat.parse(Number(fatura_pedagio[param]))
      })

      fatura_pedagio.extras_pedagio_attributes = fatura_pedagio.extras_pedagio_attributes.map(extra => {
        extra['valor'] = maskFloat.parse(Number(extra['valor']))
        return extra
      })

      fatura_pedagio.extras_pedagio_before = fatura_pedagio.extras_pedagio_attributes

      fatura_pedagio.tributos_fatura = fatura_pedagio.tributos_fatura.map(tributo => {
        tributo.aliquota = maskPercent.parse(tributo.aliquota, DECIMAL_DIGITS)
        return tributo
      })


      if (fatura_pedagio.tributos_fatura.length === 0) {
        fatura_pedagio.tributos_fatura = multipleTributo.map(tributo => ({
          tributo_id: tributo.id,
          aliquota: '0,00',
        }))
      }

      this.props.initialize('FaturaPedagioForm', { fatura_pedagio })
    } else {
      const [ parametro, multipleTributo ] = await Promise.all([
        this.props.fetchLastVigenciaParametro(this.props.instalacao.id, moment().format('YYYY-MM-DD')),
        this.props.fetchMultipleTributo(),
      ])

      this.props.initialize('FaturaPedagioForm', {
        fatura_pedagio: {
          instalacao_id: this.props.instalacao.id,
          ano: moment().format('YYYY'),
          mes: (new Date()).getMonth() + 1,
          data_inicial: moment().subtract(30, 'days'),
          data_final: moment(),
          possui_tributos: false,
          tributos_fatura: multipleTributo.map(tributo => ({
            tributo_id: tributo.id,
            aliquota: '0,00',
          }))
        }
      })

      this.setVigenciaParametro(parametro)
    }
  }

  isDateChanged = (current, previous) => {
    if (!current || !previous || !moment.isMoment(current) || !moment.isMoment(previous)) return false

    current = moment(current)
    previous = moment(previous)

    if (!current.isValid() || !previous.isValid()) return false

    return current.format('YYYY-MM-DD') !== previous.format('YYYY-MM-DD')
  }

  isConsumoTotalChanged = (current, previous) => {
    return current && previous !== '0,00' && previous !== current
  }

  async componentDidUpdate(prevProps) {
    const { instalacaoId, dataInicial, dataFinal, consumoTotal } = this.props

    const dataInicialChanged = this.isDateChanged(dataInicial, prevProps.dataInicial)
    const dataFinalChanged = this.isDateChanged(dataFinal, prevProps.dataFinal)

    const consumoTotalChanged = this.isConsumoTotalChanged(consumoTotal, prevProps.consumoTotal)
    if (dataInicialChanged || dataFinalChanged || consumoTotalChanged) {
      this.updateConsumoBandeira(dataInicial, dataFinal, consumoTotal)
    }

    if (dataFinalChanged) {
      const parametro = await this.props.fetchLastVigenciaParametro(instalacaoId, moment(dataFinal).format('YYYY-MM-DD'))
      this.setVigenciaParametro(parametro)
    }
  }

  setVigenciaParametro = (parametro) => {
    this.props.change('FaturaPedagioForm', 'fatura_pedagio.vigencia_parametro_id', parametro && parametro.id ? parametro.id : null)
    this.props.change('FaturaPedagioForm', 'fatura_pedagio.vigencia_parametro', fromJS(parametro))
  }

  updateConsumoBandeira = (dataInicial, dataFinal, consumoTotal) => {
    const consumos = this.calculateRatio(dataInicial, dataFinal, maskFloat.unmask(consumoTotal))
    this.props.change('FaturaPedagioForm', 'fatura_pedagio.consumo_mes_inicial', maskFloat.parse(consumos[0]))
    this.props.change('FaturaPedagioForm', 'fatura_pedagio.consumo_mes_final', maskFloat.parse(consumos[1]))
  }

  calculateRatio = (startsAt, endsAt, total) => {
    const startOfFirstMonth = moment(startsAt).startOf('month')
    const startOfSecondMonth = moment(endsAt).startOf('month')
    const totalDays = endsAt.diff(startsAt, 'days')

    if (startOfFirstMonth.isSame(startOfSecondMonth, 'day') || totalDays === 0) {
      return [0, total]
    }

    const endOfFirstMonth = moment(startsAt).endOf('month')
    const daysDuringFirstMonth = endOfFirstMonth.diff(startsAt, 'days')

    const ratio = daysDuringFirstMonth / totalDays
    const totalFirstMonth = Math.round(100 * total * ratio) / 100
    const totalSecondMonth = Math.round(100 * (total - totalFirstMonth)) / 100

    return [
      totalFirstMonth,
      totalSecondMonth
    ]
  }

  handleSubmitResource = async (faturaPedagio) => {
    decimalFields.forEach(param => {
      const maskedValue = faturaPedagio.getIn(['fatura_pedagio', param])
      if (!maskedValue) return
      let value = maskFloat.unmask(maskedValue)
      faturaPedagio = faturaPedagio.setIn(['fatura_pedagio', param], value)
    })

    let extras = faturaPedagio
      .getIn(['fatura_pedagio', 'extras_pedagio_attributes'], List([]))
      .map(e => {
        const maskedValue = e.get('valor')
        if (!maskedValue) return e
        let value = maskFloat.unmask(maskedValue)
        return e.set('valor', value)
      })

    const extrasToDestroy = faturaPedagio
      .getIn(['fatura_pedagio', 'extras_pedagio_before'], List([]))
      .filterNot(e => {
        const id = e.get('id')
        return extras.find(e => e.get('id') === id)
      })

    extrasToDestroy.forEach(e => extras = extras.push(e.set('_destroy', true)))

    let tributos = faturaPedagio
      .getIn(['fatura_pedagio', 'tributos_fatura'])
      .map(a => {
        const maskedValue = a.get('aliquota')
        if (!maskedValue) return a
        let value = maskPercent.unmask(maskedValue, DECIMAL_DIGITS)
        return a.set('aliquota', value)
      })

    faturaPedagio = faturaPedagio
      .setIn(['fatura_pedagio', 'extras_pedagio_attributes'], extras)
      .setIn(['fatura_pedagio', 'tributos_fatura_attributes'], tributos)

    if (this.props.id) {
      if (await this.props.updateFaturaPedagio(this.props.id, faturaPedagio)) {
        this.props.showSuccessfulFlashMessage('', 'update')
        this.props.history.push(`/instalacoes/${this.props.instalacaoId}/pedagio/${this.props.id}`)
      } else {
        this.props.showErrorFlashMessage('', 'update', this.props.error)
      }
    } else {
      if (await this.props.createFaturaPedagio(this.props.instalacao.id, faturaPedagio)) {
        this.props.showSuccessfulFlashMessage('', 'create')
        this.props.history.push(`/instalacoes/${this.props.instalacao.id}/pedagio`)
      } else {
        this.props.showErrorFlashMessage('', 'create', this.props.error)
      }
    }
  }

  componentWillUnmount () {
    if (this.props.shouldHideFlashMessage)
      this.props.hideFlashMessage()
  }

  render () {
    return (
      <FaturaPedagioForm
        consumoTotal={this.props.consumoTotal}
        dataInicial={this.props.dataInicial}
        dataFinal={this.props.dataFinal}
        lastVigenciaParametro={this.props.lastVigenciaParametro}
        possuiTributos={this.props.possuiTributos}
        onSubmit={(faturaPedagio) => this.handleSubmitResource(faturaPedagio)}
        loading={this.props.isLoading} />
    )
  }
}

function mapStateToProps (state, { match }) {
  const { faturaPedagio, status, flashMessage } = state
  const isLoading =
    status.getIn(['faturaPedagio', 'isLoading']) ||
    status.getIn(['tributo', 'isLoading']) ||
    status.getIn(['vigenciaParametro', 'isLoading'])

  const { id } = match.params

  const possuiTributos = selector(state, 'fatura_pedagio.possui_tributos')

  const instalacaoId = selector(state, 'fatura_pedagio.instalacao_id')
  const dataInicial = selector(state, 'fatura_pedagio.data_inicial')
  const dataFinal = selector(state, 'fatura_pedagio.data_final')

  const consumoPonta = maskFloat.unmask(selector(state, 'fatura_pedagio.consumo_p') || '0,00')
  const consumoForaPonta = maskFloat.unmask(selector(state, 'fatura_pedagio.consumo_fp') || '0,00')
  const consumoNA = maskFloat.unmask(selector(state, 'fatura_pedagio.consumo_na') || '0,00')
  const consumoIntermediario = maskFloat.unmask(selector(state, 'fatura_pedagio.consumo_int') || '0,00')
  const consumoTotal = maskFloat.parse(parseFloat((consumoPonta + consumoForaPonta + consumoNA + consumoIntermediario).toFixed(2)))

  const lastVigenciaParametro = selector(state, 'fatura_pedagio.vigencia_parametro')

  if (id) {
    return {
      id,
      faturaPedagio: faturaPedagio.get('id'),
      instalacaoId,
      dataInicial,
      dataFinal,
      lastVigenciaParametro,
      error: status.getIn(['faturaPedagio', 'error']),
      shouldHideFlashMessage: flashMessage.get('isError'),
      isLoading,
      consumoTotal,
      possuiTributos,
    }
  }
  return {
    instalacaoId,
    dataInicial,
    dataFinal,
    lastVigenciaParametro,
    error: status.getIn(['faturaPedagio', 'error']),
    shouldHideFlashMessage: flashMessage.get('isError'),
    isLoading,
    consumoTotal,
    possuiTributos,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...faturaPedagioActionCreators,
    ...vigenciaParametroActionCreators,
    ...tributoActionCreators,
    ...flashMessageAcionCreators,
    ...{initialize},
    ...{change},
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FaturaPedagioFormContainer))
