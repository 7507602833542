import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Input, Menu } from 'semantic-ui-react'

const Toolbar = (props) => (
  <Menu secondary>
    <Menu.Menu position='left'>
      {props.handleFilter && (
        <Menu.Item fitted>
          <Input
            icon='search'
            placeholder='Pesquisar'
            type='text'
            value={props.filterText}
            onChange={(event) => props.handleFilter('filterText', event.target.value)}
          />
        </Menu.Item>
      )}
      {props.customSearchItems && props.customSearchItems.map((item, index) => (
        <Menu.Item key={index} fitted>
          {item}
        </Menu.Item>
      ))}
    </Menu.Menu>
    <Menu.Menu position='right'>
      {props.onRedirectToNew && (
        <Menu.Item fitted>
          <Button color='green' icon
            onClick={props.onRedirectToNew}
            labelPosition='left'>
            <Icon name='plus' />{' '}Adicionar
          </Button>
        </Menu.Item>
      )}
      {props.customToolbarItems && props.customToolbarItems.map((Component, index) => (
        <Menu.Item key={index} fitted>
          <Component />
        </Menu.Item>
      ))}
      {props.onExportToCSV && (
        <Menu.Item fitted>
          <Button onClick={() => props.onExportToCSV()} animated='vertical'>
            <Button.Content hidden>CSV</Button.Content>
            <Button.Content visible>
              <Icon name='download' />
            </Button.Content>
          </Button>
        </Menu.Item>
      )}
    </Menu.Menu>
  </Menu>
)

Toolbar.propTypes = {
  filterText: PropTypes.string,
  handleFilter: PropTypes.func,
  onRedirectToNew: PropTypes.func,
  onExportToCSV: PropTypes.func,
  customToolbarItems: PropTypes.array,
  customSearchItems: PropTypes.array,
}

export default Toolbar
