import React from 'react'
import PropTypes from 'prop-types'

import { Table } from 'semantic-ui-react'
import { maskFloat } from '../../utils/masks'
import Postos from '../../utils/enum/postos'
import Modalidades from '../../utils/enum/modalidades'
import get from 'lodash.get'

const postosConsumo = (posto) => ({
  [Modalidades.AZUL]: [ Postos.PONTA, Postos.FORA_PONTA ],
  [Modalidades.VERDE]: [ Postos.PONTA, Postos.FORA_PONTA ],
  [Modalidades.BRANCA]: [ Postos.PONTA, Postos.FORA_PONTA, Postos.INTERMEDIARIO ],
  [Modalidades.CONVENCIONAL]: [ Postos.NA ],
})[posto]

const postosDemanda = (posto) => ({
  [Modalidades.AZUL]: [ Postos.PONTA, Postos.FORA_PONTA ],
  [Modalidades.VERDE]: [ Postos.PONTA, Postos.FORA_PONTA ],
  [Modalidades.BRANCA]: [],
  [Modalidades.CONVENCIONAL]: [],
})[posto]

const postosReativos = [ Postos.PONTA, Postos.FORA_PONTA ]

const fieldMapping = {
  consumo: {
    p: { field: 'consumo_p', unit: 'kWh' },
    fp: { field: 'consumo_fp', unit: 'kWh' },
    int: { field: 'consumo_int', unit: 'kWh' },
    na: { field: 'consumo_na', unit: 'kWh' },
  },
  demanda: {
    p: { field: 'demanda_p_lida', unit: 'kW' },
    fp: { field: 'demanda_fp_lida', unit: 'kW' },
    na: { field: 'demanda_na_lida', unit: 'kW' },
  },
  ufer: {
    p: { field: 'ufer_p', unit: 'kvarh' },
    fp: { field: 'ufer_fp', unit: 'kvarh' },
  },
  dmcr: {
    p: { field: 'dmcr_p_lido', unit: 'kvar' },
    fp: { field: 'dmcr_fp_lido', unit: 'kvar' },
  },
}

const labelPosto = (posto) => ({
  [Postos.PONTA]: 'Ponta',
  [Postos.FORA_PONTA]: 'Fora-Ponta',
  [Postos.INT]: 'Intermediário',
  [Postos.NA]: 'Sem Posto Horário',
})[posto]

const fetchMedicoes = (label, prefix, medicoes, postos, base) => {
  const values = postos.map(posto => {
    let value = null
    if (base.find(p => p === posto)) {
      const field = get(fieldMapping, `${prefix}.${posto}`)
      value = `${maskFloat.parse(medicoes.get(field.field))} ${field.unit}`
    }
    return value
  })

  if (values.every(v => !v)) return null
  return [ label, ...values ]
}

const fetchContratoDemanda = (label, infoDemanda, postos, base, modalidade = 'Azul') => {
  const values = postos.map(posto => {
    let value = null
    if (base.find(p => p === posto)) {
      const rawValue = modalidade === 'Verde'
        ? infoDemanda.getIn(['na', 'contrato'])
        : infoDemanda.getIn([posto, 'contrato'])
      value = `${maskFloat.parse(rawValue)} kW`
    }
    return value
  })

  if (values.every(v => !v)) return null
  return [ label, ...values ]
}

const ParametrosTecnicos = ({ color, modalidade, medicoes, infoDemanda }) => {
  const pc = postosConsumo(Modalidades[modalidade.toUpperCase()])
  const pd = postosDemanda(Modalidades[modalidade.toUpperCase()])
  const pr = postosReativos

  const postos = [...new Set([
    ...pc, ...pd, ...pr,
  ])]

  const width = 12 / postos.length

  const columns = [ '', ...postos ]

  const rows = [
    fetchMedicoes('Consumo', 'consumo', medicoes, postos, pc),
    fetchMedicoes('Demanda', 'demanda', medicoes, postos, pd),
    fetchContratoDemanda('Contrato de Demanda', infoDemanda, postos, pd, modalidade),
    fetchMedicoes('UFER', 'ufer', medicoes, postos, pr),
    fetchMedicoes('DMCR', 'dmcr', medicoes, postos, pr),
  ]

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ backgroundColor: color }} width={4} textAlign='right'></Table.HeaderCell>
          <Table.HeaderCell colSpan={4} style={{ backgroundColor: color }} width={12} textAlign='center'>Parâmetros Técnicos</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          {columns.map(column => (
            <Table.HeaderCell key={column} width={width} textAlign='right'>{labelPosto(column)}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rows.map(row => row ? (
          <Table.Row key={row}>
            {row.map(cell => (
              <Table.Cell key={cell} width={width} textAlign='right'>{cell}</Table.Cell>
            ))}
          </Table.Row>
        ) : null)}
      </Table.Body>
    </Table>
  )
}

ParametrosTecnicos.propTypes = {
  medicoes: PropTypes.object,
  infoDemanda: PropTypes.object,
}

export default ParametrosTecnicos
