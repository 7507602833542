import { Map } from 'immutable'

const [ TODOS, CONSUMO, DEMANDA ] = [ 0, 1, 2 ]
const [ P_FP, NA, P_FP_INT, NENHUM ] = [ 0, 1, 2, 3 ]

export const multipleTipoTarifa = Map({
  [P_FP]: Map({
    id: P_FP,
    nome: 'Ponta e Fora-Ponta'
  }),
  [NA]: Map({
    id: NA,
    nome: 'Não diferencia horários'
  }),
  [P_FP_INT]: Map({
    id: P_FP_INT,
    nome: 'Ponta, Fora-Ponta e Intermediário'
  }),
  [NENHUM]: Map({
    id: NENHUM,
    nome: 'Não utilizada'
  }),
})

export const getPostos = (enquadramento, subgrupo, tipo = TODOS) => {
  const grupo = getGrupo(subgrupo)

  const tarifaConsumo = getTarifa(multipleTipoTarifa, getTipoTarifa(enquadramento, grupo, 'consumo'))
  const tarifaDemanda = getTarifa(multipleTipoTarifa, getTipoTarifa(enquadramento, grupo, 'demanda'))

  const consumo = testarPostos((tipo === TODOS || tipo === CONSUMO) ? tarifaConsumo : NENHUM)
  const demanda = testarPostos((tipo === TODOS || tipo === DEMANDA) ? tarifaDemanda : NENHUM)

  const postos = []

  if (consumo.P || demanda.P) postos.push({ sigla: 'p', descricao: 'P', extenso: 'Ponta' })
  if (consumo.INT || demanda.INT) postos.push({ sigla: 'int', descricao: 'INT', extenso: 'Intermediário' })
  if (consumo.FP || demanda.FP) postos.push({ sigla: 'fp', descricao: 'FP', extenso: 'Fora-Ponta' })
  if (consumo.NA || demanda.NA) postos.push({ sigla: 'na', descricao: 'NA', extenso: '' })

  return postos
}

const getGrupo = (subgrupo) => {
  const [ ALTA_TENSAO ]  = [ 0, 1 ]
  const [ GRUPO_AT, GRUPO_BT ]  = [ 'a', 'b' ]
  return (parseInt(subgrupo.get('grupo'), 10) === ALTA_TENSAO) ? GRUPO_AT : GRUPO_BT
}

const getTipoTarifa = (enquadramento, grupo, tipo) => enquadramento.get(`${tipo}_grupo_${grupo}`)

const getTarifa = (tipoTarifa, tipo) => tipoTarifa.getIn([`${tipo}`, 'id'])

const testarPostos = (tarifa) => {
  let ponta = false
  let foraPonta = false
  let naoAplica = false
  let intermediario = false

  switch(tarifa) {
    case P_FP_INT:
      intermediario = true
      // fall through
    case P_FP:
      ponta = true
      foraPonta = true
      break
    case NA:
      naoAplica = true
      break
    default:
  }

  return {
    P: ponta,
    FP: foraPonta,
    NA: naoAplica,
    INT: intermediario,
  }
}
