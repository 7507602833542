import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FormSection } from 'redux-form/immutable'
import { Form, FormField, Divider } from 'semantic-ui-react'

import { CRUDForm } from '../CRUD'
import { SemanticInput, SemanticInputMask, SemanticDropdown } from '../FormComponents'

import { required, cnpj, cpf, maxLengthSigla, maxLengthDescricao } from '../../utils/validations'
import { maskCNPJ, maskCPF } from '../../utils/masks'

const [ PESSOA_JURIDICA, PESSOA_FISICA ] = [ 0, 1 ] // eslint-disable-line no-unused-vars

const InstalacaoForm = props => {
  const ativoOptions = [
    {key: 'Sim', value: true, text: 'Sim'},
    {key: 'Não', value: false, text: 'Não'},
  ]

  return (
    <CRUDForm
      title='Instalação'
      titleAs={props.titleAs}
      icon='factory'
      padded={props.noPadded ? false : true}
      {...props}>
      <FormSection name='instalacao'>

        <Divider section horizontal>Dados Principais</Divider>

        <Form.Group>
          <FormField width={4}>
            <label htmlFor='user_id'>Gestor</label>
            <Field fluid button floating labeled search icon='user' className='icon'
              autoFocus
              name='user_id'
              options={props.userDropdown}
              validate={[required]}
              component={SemanticDropdown}
              loading={props.loading}
              placeholder='Selecione'
            />
          </FormField>
          <FormField width={4}>
            <label htmlFor='apelido'>Apelido</label>
            <Field
              name='apelido'
              placeholder='Apelido'
              validate={[required, maxLengthSigla]}
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
          <FormField width={8}>
            <label htmlFor='nome'>Nome/Razão Social</label>
            <Field
              name='nome'
              placeholder='Nome/Razão Social'
              validate={[required, maxLengthDescricao]}
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
          <FormField>
            <label htmlFor='ativo'>Ativo</label>
            <Field fluid button floating labeled className='icon'
              name='ativo'
              options={ativoOptions}
              component={SemanticDropdown}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>

        <Form.Group widths='equal'>
          <FormField>
            <label htmlFor='pessoa'>Tipo</label>
            <Field fluid button floating labeled search icon='marker' className='icon'
              name='pessoa'
              options={props.pessoaDropdown}
              component={SemanticDropdown}
              loading={props.loading}
            />
          </FormField>

          <FormField>
            <label htmlFor='cnpj'>{props.pessoa === PESSOA_FISICA ? 'CPF' : 'CNPJ'}</label>
            <Field
              name='cnpj'
              placeholder={props.pessoa === PESSOA_FISICA ? maskCPF.placeholder : maskCNPJ.placeholder}
              validate={[props.pessoa === PESSOA_FISICA ? cpf : cnpj]}
              component={SemanticInputMask}
              mask={props.pessoa === PESSOA_FISICA ? maskCPF.mask : maskCNPJ.mask}
              loading={props.loading}
            />
          </FormField>

          <FormField>
            <label htmlFor='ins_municipal'>Inscrição Municipal</label>
            <Field
              name='ins_municipal'
              placeholder='Inscrição Municipal'
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
          <FormField>
            <label htmlFor='ins_estadual'>Inscrição Estadual</label>
            <Field
              name='ins_estadual'
              placeholder='Inscrição Estadual'
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>

          <FormField>
            <label htmlFor='unidade_id'>Unidade ACL</label>
            <Field fluid button floating labeled search icon='user' className='icon'
              autoFocus
              name='unidade_id'
              options={props.unidadeDropdown}
              validate={[]}
              component={SemanticDropdown}
              loading={props.loading}
              placeholder='Selecione'
            />
          </FormField>
        </Form.Group>

        <Divider section horizontal>Endereço</Divider>

        <Form.Group>
          <FormField width={12}>
            <label htmlFor='endereco'>Endereço</label>
            <Field
              name='endereco'
              placeholder='Endereco'
              validate={[required]}
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
          <FormField width={4}>
            <label htmlFor='cep'>CEP</label>
            <Field
              name='cep'
              placeholder='CEP'
              validate={[required]}
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>

        <Form.Group widths='equal'>
          <FormField>
            <label htmlFor='bairro'>Bairro</label>
            <Field
              name='bairro'
              placeholder='Bairro'
              validate={[required]}
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
          <FormField>
            <label htmlFor='cidade'>Cidade</label>
            <Field
              name='cidade'
              placeholder='Cidade'
              validate={[required]}
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
          <FormField>
            <label htmlFor='uf_id'>Unidade Federativa</label>
            <Field fluid button floating labeled search icon='marker' className='icon'
              name='uf_id'
              options={props.ufDropdown}
              validate={[required]}
              component={SemanticDropdown}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>

        <Divider section horizontal>Dados Bancários</Divider>

        <Form.Group widths='equal'>
          <FormField>
            <label htmlFor='banco'>Banco</label>
            <Field
              name='banco'
              placeholder='Banco'
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
          <FormField>
            <label htmlFor='agencia'>Agência</label>
            <Field
              name='agencia'
              placeholder='Agência'
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
          <FormField>
            <label htmlFor='conta'>Conta Corrente</label>
            <Field
              name='conta'
              placeholder='Conta Corrente'
              component={SemanticInput}
              loading={props.loading}
            />
          </FormField>
        </Form.Group>
      </FormSection>
    </CRUDForm>
  )
}

InstalacaoForm.propTypes = {
  showLivre: PropTypes.bool,
  showGerador: PropTypes.bool,
  showGeracao: PropTypes.bool,
  titleAs: PropTypes.bool,
  noPadded: PropTypes.bool,
  userDropdown: PropTypes.array,
  ufDropdown: PropTypes.array,
  unidadeDropdown: PropTypes.array,
  loading: PropTypes.bool,
  pessoa: PropTypes.number,
  pessoaDropdown: PropTypes.array,
}

export default reduxForm({form: 'InstalacaoForm'})(InstalacaoForm)
