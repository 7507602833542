import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { FaturaPedagio } from '../../../containers'

const FaturasPedagio = (props) => {
  const { instalacao } = props
  return (
    <div>
      <Switch>
        <Route exact path='/instalacoes/:instalacaoId/pedagio'
          render={({match}) => (
            <FaturaPedagio.Index
              instalacao={instalacao}
              match={match}
            />
          )}>
        </Route>
        <Route exact path='/instalacoes/:instalacaoId/pedagio/new'
          render={({match}) => (
            <FaturaPedagio.Form
              instalacao={instalacao}
              match={match}
            />
          )}
        />
        <Route exact path='/instalacoes/:instalacaoId/pedagio/:id/edit'
          render={({match}) => (
            <FaturaPedagio.Form
              instalacao={instalacao}
              match={match}
            />
          )}
        />
        <Route path='/instalacoes/:instalacaoId/pedagio/:id/:pane?/:paneId?/:action?'
          render={({match}) => (
            <FaturaPedagio.Show
              instalacao={instalacao}
              match={match}
            />
          )}
        />
      </Switch>
    </div>
  )
}

FaturasPedagio.propTypes = {
  instalacao: PropTypes.object.isRequired,
}

export default FaturasPedagio
