import React, { Component } from "react";

import { Accordion, Menu, Image, Icon, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";

import logo from "../../img/logo_white.svg";

class AppMenu extends Component {
  constructor(props) {
    super(props);
    // initiates the menu with the selected route active
    // need to refactor this component to have access to the accordion that stores the page
    //   so it can load opened by default
    this.state = {
      activeItem: window.location.pathname.split("/")[1],
      activeIndex: -1,
    };
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeItem, activeIndex } = this.state;
    const roles = this.props.session.get("roles").toArray();
    const isClient = roles.includes("cliente");

    return (
      <Accordion
        as={Menu}
        fixed="left"
        inverted
        size="huge"
        stackable
        vertical
        style={{
          top: "0px",
          bottom: "0px",
          left: "0px",
          width: "260px",
          background: "rgb(27, 44, 62)",
          overflowY: "auto",
        }}
      >
        <Menu.Item>
          <Image
            src={logo}
            centered
            style={{ marginTop: "10px", width: "180px" }}
          />
        </Menu.Item>

        <Menu.Item
          as={Link}
          to="/"
          name="home"
          active={activeItem === "home"}
          onClick={this.handleItemClick}
        >
          <Icon name="dashboard" />
          Dashboard
        </Menu.Item>
        {!isClient && (
          <Menu.Item>
            <Accordion.Title
              active={activeIndex === 0}
              content="Cadastros"
              index={0}
              onClick={this.handleClick}
            />
            <Accordion.Content active={activeIndex === 0}>
              <Menu.Menu style={{ marginTop: "10px", fontSize: "1.1em" }}>
                <Menu.Item
                  as={Link}
                  to="/gestao_bandeiras"
                  name="gestao_bandeiras"
                  active={activeItem === "gestao_bandeiras"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="flag" />
                  Bandeiras
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to="/enquadramentos"
                  name="enquadramentos"
                  active={activeItem === "enquadramentos"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="object group" />
                  Enquadramentos
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to="/subgrupos"
                  name="subgrupos"
                  active={activeItem === "subgrupos"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="table" />
                  Subgrupos de Tensão
                </Menu.Item>

                <Divider />

                <Menu.Item
                  as={Link}
                  to="/submercados"
                  name="submercados"
                  active={activeItem === "submercados"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="map" />
                  Submercados
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to="/uf"
                  name="uf"
                  active={activeItem === "uf"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="marker" />
                  Unidades Federativas
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to="/classes_consumo"
                  name="classes_consumo"
                  active={activeItem === "classes_consumo"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="block layout" />
                  Classes de Consumo
                </Menu.Item>

                <Divider />

                <Menu.Item
                  as={Link}
                  to="/tributos"
                  name="tributos"
                  active={activeItem === "tributos"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="percent" />
                  Tributos
                </Menu.Item>

                <Divider />

                <Menu.Item
                  as={Link}
                  to="/geradores"
                  name="geradores"
                  active={activeItem === "geradores"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="rocket" />
                  Geradores
                </Menu.Item>

                <Divider />

                <Menu.Item
                  as={Link}
                  to="/contatos"
                  name="contatos"
                  active={activeItem === "contatos"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="users" />
                  Contatos
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to="/funcoes"
                  name="funcoes"
                  active={activeItem === "funcoes"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="suitcase" />
                  Funções
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to="/servicos"
                  name="servicos"
                  active={activeItem === "servicos"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="wrench" />
                  Serviços Prestados
                </Menu.Item>
              </Menu.Menu>
            </Accordion.Content>
          </Menu.Item>
        )}

        {!isClient && (
          <Menu.Item>
            <Accordion.Title
              active={activeIndex === 1}
              content="Gestão de Negócios"
              index={1}
              onClick={this.handleClick}
            />
            <Accordion.Content active={activeIndex === 1}>
              <Menu.Menu style={{ marginTop: "10px", fontSize: "1.1em" }}>
                <Menu.Item
                  as={Link}
                  to="/concessionarias"
                  name="concessionarias"
                  active={activeItem === "concessionarias"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="building" />
                  Concessionárias
                </Menu.Item>
                <Divider />
                <Menu.Item
                  as={Link}
                  to="/clientes"
                  name="clientes"
                  active={activeItem === "clientes"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="building outline" />
                  Clientes
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to="/instalacoes"
                  name="instalacoes"
                  active={activeItem === "instalacoes"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="factory" />
                  Instalações
                </Menu.Item>
                <Divider />
                <Menu.Item
                  as={Link}
                  to="/resumos"
                  name="resumos"
                  active={activeItem === "resumos"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="clipboard outline" />
                  Painel de Resumo
                </Menu.Item>
              </Menu.Menu>
            </Accordion.Content>
          </Menu.Item>
        )}

        {!isClient && (
          <Menu.Item>
            <Accordion.Title
              active={activeIndex === 2}
              content="Indicativos"
              index={2}
              onClick={this.handleClick}
            />
            <Accordion.Content active={activeIndex === 2}>
              <Menu.Menu style={{ marginTop: "10px", fontSize: "1.1em" }}>
                <Menu.Item
                  as={Link}
                  to="/indicativos/medios"
                  name="medios"
                  active={activeItem === "medios"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="calendar outline" />
                  Médios
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to="/indicativos/semanais"
                  name="semanais"
                  active={activeItem === "semanais"}
                  onClick={this.handleItemClick}
                >
                  <Icon name="calendar outline" />
                  Semanais
                </Menu.Item>
              </Menu.Menu>
            </Accordion.Content>
          </Menu.Item>
        )}
      </Accordion>
    );
  }
}

export default AppMenu;
