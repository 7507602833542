import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import { withRouter } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import Cabecalho from './Graficos/Cabecalho'
import Indicadores from './Graficos/Indicadores'
import Impostos from './Graficos/Impostos'

import FinanceiroChart from './Graficos/FinanceiroChart'
import ComposicaoFaturaChart from './Graficos/ComposicaoFaturaChart'
import TotalAnualChart from './Graficos/TotalAnualChart'

import RecadosContainer from '../../containers/Relatorio/RecadosContainer'

const Capa = ({ cabecalho, indicadores, graficos, gestor, match, impostos }) => {

  const print = match.url.endsWith('/print')
  const showAnimations = !print

  const total_anual = get(graficos, 'total_anual')
  const composicao_fatura = get(graficos, 'composicao_fatura')
  const financeiro = get(graficos, 'financeiro')

  return (
    <React.Fragment>
      <Grid.Row className='cabecalho' stretched>
        <Cabecalho data={cabecalho} impostos={impostos} />
      </Grid.Row>

      <Indicadores data={indicadores} />

      <Grid.Row className='shrink' style={{paddingTop: 0, paddingBottom: 0}}>
        <Grid.Column textAlign='right'>
          <Impostos impostos={impostos} />
        </Grid.Column>
      </Grid.Row>

      {total_anual && (
        <Grid.Row className='shrink' style={{paddingTop: 0}}>
          <Grid.Column width={16}>
            <TotalAnualChart
              header='Total da Fatura (12 meses)'
              data={total_anual}
              showAnimations={showAnimations}
              height={150}
            />
          </Grid.Column>
        </Grid.Row>
      )}

      <Grid.Row className='shrink'>
        <Grid.Column width={8}>
          <ComposicaoFaturaChart
            header='Composição dos Custos do Mês Atual'
            data={composicao_fatura}
            showAnimations={showAnimations}
            height={250}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <FinanceiroChart
            header='Comparação com o Mês Anterior'
            data={financeiro}
            showAnimations={showAnimations}
            height={250}
          />
        </Grid.Column>
      </Grid.Row>

      <RecadosContainer gestor={gestor} />
    </React.Fragment>
  )
}

Capa.propTypes = {
  cabecalho: PropTypes.object,
  indicadores: PropTypes.object,
  graficos: PropTypes.object,
  gestor: PropTypes.object,
  impostos: PropTypes.bool,
  match: PropTypes.object,
}

export default withRouter(Capa)
