import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ExtraPedagio } from '../../components'
import * as extraPedagioActionCreators from '../../redux/modules/extraPedagio'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'
import * as sortActionCreators from '../../redux/modules/sort'
import * as filterActionCreators from '../../redux/modules/filter'

import { filterMap, sortMap } from '../../utils/mapTools'
import { arrayToCSV } from '../../utils/arrayToCSV'

class ExtraPedagioContainer extends Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    faturaPedagio: PropTypes.object.isRequired,

    multipleExtraPedagio: PropTypes.object.isRequired,
    fetchMultipleExtraPedagio: PropTypes.func.isRequired,
    destroyExtraPedagio: PropTypes.func.isRequired,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,

    updateSort: PropTypes.func.isRequired,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    updateFilter: PropTypes.func.isRequired,
    filterText: PropTypes.string,
    isLoading: PropTypes.bool,
  }

  async componentDidMount () {
    const { faturaPedagio } = this.props
    await this.props.fetchMultipleExtraPedagio(faturaPedagio.id)
  }

  handleRedirectToNew = (faturaPedagioId) => {
    this.props.history.push(`/faturas/pedagio/${faturaPedagioId}/extras/new`)
  }

  handleEditResource = (faturaPedagioId, id) => {
    this.props.history.push(`/faturas/pedagio/${faturaPedagioId}/extras/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyExtraPedagio(id)
      ? this.props.showSuccessfulFlashMessage('', 'destroy')
      : this.props.showErrorFlashMessage('', 'destroy', this.props.error)
  }

  handleSort = column => () => this.props.updateSort('extraPedagio', column)

  handleSorted = column => this.props.sortColumn === column
    ? this.props.sortDirection
    : null

  handleFilter = (name, value) => this.props.updateFilter('extraPedagio', name, value)

  handleExportToCSV = () => {
    arrayToCSV(
      this.props.multipleExtraPedagio.toList().toJSON(),
      'extraPedagio',
      [
        'id',
        'concessionaria.sigla',
        'instalacao.sigla',
        'fatura_pedagio.id',
        'descricao',
        'valor',
      ],
    )
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <ExtraPedagio
        faturaPedagio={this.props.faturaPedagio}
        multipleExtraPedagio={this.props.multipleExtraPedagio}
        onRedirectToNew={() => this.handleRedirectToNew(this.props.faturaPedagio.id)}
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        handleSort={this.handleSort}
        handleSorted={this.handleSorted}
        handleFilter={this.handleFilter}
        filterText={this.props.filterText}
        onExportToCSV={this.handleExportToCSV}
        isLoading={this.props.isLoading}
      />
    )
  }
}

function mapStateToProps ({ extraPedagio, status, sort, filter }, {faturaPedagio}) {
  const sortColumn = sort.getIn(['extraPedagio', 'sortColumn']) || 'executado_em'
  const sortDirection = sort.getIn(['extraPedagio', 'sortDirection']) || 'ascending'
  const filterText = filter.getIn(['extraPedagio', 'filterText']) || ''

  let map = filterMap(extraPedagio, ['concessionaria.sigla', 'instalacao.sigla', 'fatura_pedagio.id', 'descricao', 'valor'], filterText)
  map = sortMap(map, sortColumn, sortDirection)

  return {
    multipleExtraPedagio: map.filter(t => t.get('fatura_pedagio_id') === faturaPedagio.id),
    error: status.getIn(['extraPedagio', 'error']),
    isLoading: status.getIn(['extraPedagio', 'isLoading']),
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...extraPedagioActionCreators,
    ...flashMessageActionCreators,
    ...sortActionCreators,
    ...filterActionCreators,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExtraPedagioContainer))
