import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { InstalacaoShow } from '../../components'
import * as instalacaoActionCreators from '../../redux/modules/instalacao'
import * as ufActionCreators from '../../redux/modules/uf'
import * as flashMessageAcionCreators from '../../redux/modules/flashMessage'

class InstalacaoShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,

    fetchInstalacao: PropTypes.func.isRequired,
    destroyInstalacao: PropTypes.func.isRequired,

    fetchUf: PropTypes.func.isRequired,

    hideFlashMessage: PropTypes.func.isRequired,
    hideMenu: PropTypes.bool,
  }

  state = {
    instalacao: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const instalacao = await this.props.fetchInstalacao(this.props.id)
      const uf = await this.props.fetchUf(instalacao.uf_id)
      this.setState({ instalacao, uf })
    }
  }

  handleRedirectToNew = () => {
    this.props.history.push('/instalacoes/new')
  }

  handleEditResource = (id) => {
    this.props.history.push(`/instalacoes/${id}/edit`)
  }

  handleDeleteResource = async (id) => {
    await this.props.destroyInstalacao(id)
    this.props.history.push('/instalacoes')
  }

  getPane = (match) => {
    if (match.params.pane) return match.params.pane
    return 'informacoes'
  }

  componentWillUnmount () {
    this.props.hideFlashMessage()
  }

  render () {
    return (
      <InstalacaoShow
        onEditResource={this.handleEditResource}
        onDeleteResource={this.handleDeleteResource}
        instalacao={this.state.instalacao}
        uf={this.state.uf}
        pane={this.getPane(this.props.match)}
        isLoading={this.props.isLoading}
        hideMenu={this.props.hideMenu}
      />
    )
  }
}

function mapStateToProps (store, props) {
  const {instalacao, uf, status} = store
  const {match} = props

  const { id } = match.params
  if (id) {
    return {
      id,
      instalacao: instalacao.get('id'),
      uf: uf.get('id'),
      isLoading: status.getIn(['instalacao', 'isLoading']) || status.getIn(['uf', 'isLoading'])
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...instalacaoActionCreators,
    ...ufActionCreators,
    ...flashMessageAcionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InstalacaoShowContainer))
