import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ContatoShow } from '../../components'
import * as contatoConcessionariaActionCreators from '../../redux/modules/contatoConcessionaria'
import * as flashMessageActionCreators from '../../redux/modules/flashMessage'

class ContatoConcessionariaShowContainer extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    concessionaria: PropTypes.object.isRequired,
    fetchContatoConcessionaria: PropTypes.func.isRequired,
    destroyContatoConcessionaria: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,

    showSuccessfulFlashMessage: PropTypes.func,
    showErrorFlashMessage: PropTypes.func,
    hideFlashMessage: PropTypes.func,
    error: PropTypes.array,
  }

  state = {
    contatoConcessionaria: null
  }

  async componentDidMount () {
    if (this.props.id) {
      const contatoConcessionaria = await this.props.fetchContatoConcessionaria(this.props.id)
      this.setState({ contatoConcessionaria })
    }
  }

  handleEditResource = (concessionariaId, id) => {
    this.props.history.push(`/concessionarias/${concessionariaId}/contatos/${id}/edit`)
  }

  handleDeleteResource = async (concessionariaId, id) => {
    if (await this.props.destroyContatoConcessionaria(id)) {
      this.props.showSuccessfulFlashMessage('', 'destroy')
      this.props.history.push(`/concessionarias/${concessionariaId}/contatos`)
    } else {
      this.props.showErrorFlashMessage('', 'destroy', this.props.error)
    }
  }

  render () {
    return (
      <ContatoShow noTitle
        concessionaria={this.props.concessionaria}
        onEditResource={() => this.handleEditResource(this.props.concessionaria.id, this.state.contatoConcessionaria.id)}
        onDeleteResource={() => this.handleDeleteResource(this.props.concessionaria.id, this.state.contatoConcessionaria.id)}
        contato={this.state.contatoConcessionaria} />
    )
  }
}

function mapStateToProps ({contatoConcessionaria}, {match}) {
  const { id } = match.params
  if (id) {
    return {
      id,
      contatoConcessionaria: contatoConcessionaria.get('id'),
    }
  }
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    ...contatoConcessionariaActionCreators,
    ...flashMessageActionCreators
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContatoConcessionariaShowContainer))
