import React from 'react'
import PropTypes from 'prop-types'

import { CRUDShow } from '../CRUD'

const TributoShow = (props)  => {
  if (props.tributo) {
    return (
      <CRUDShow
        title={props.tributo.nome}
        icon='percent'
        onEditResource={() => props.onEditResource(props.tributo.id)}
        onDeleteResource={() => props.onDeleteResource(props.tributo.id)}>
      </CRUDShow>
    )
  }

  return (null)
}

TributoShow.propTypes = {
  tributo: PropTypes.object.isRequired,
  onEditResource: PropTypes.func.isRequired,
  onDeleteResource: PropTypes.func.isRequired,
}

export default TributoShow
