import React from 'react'
import PropTypes from 'prop-types'
import ValorEnergia from './ValorEnergia'

const RenderValoresEnergia = ({ fields, loading }) => {
  return fields.map((valorEnergia, index) => (
    <ValorEnergia
      key={index}
      valorEnergia={valorEnergia}
      loading={loading}
    />
  ))
}

RenderValoresEnergia.propTypes = {
  fields: PropTypes.object,
  loading: PropTypes.bool,
}

export default RenderValoresEnergia
